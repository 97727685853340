export const planningTypes = [
  { id: "fullApplication", name: "Full Application" },
  { id: "dischargeOfConditions", name: "Discharge of Conditions" },
  { id: "outline", name: "Outline" },
  { id: "gpdr", name: "Prior Approval" },
  { id: "reservedMatters", name: "Reserved Matters" },
  { id: "tpo", name: "Work to Trees" },
  { id: "listedBuildings", name: "Listed Buildings" },
  { id: "eia", name: "EIA" },
  { id: "changeOfUse", name: "Change of Use" },
  { id: "uncategorised", name: "Uncategorised" },
];
