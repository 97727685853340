import { ModalV2 } from "react-migration/components/ModalV2/ModalV2";
import { useTranslation } from "react-migration/lib/i18n/useTranslation";

type OverdueInvoiceModalProps = {
  closeModal: () => void;
};
export const OverdueInvoiceModal = ({ closeModal }: OverdueInvoiceModalProps) => {
  const { t } = useTranslation();

  return (
    <ModalV2.Root defaultOpen={true} onOpenChange={() => closeModal()}>
      <ModalV2.Body
        title={t("nav.overdue_invoice_modal.looks_like_you_have_an_overdue_invoice")}
        className="!atlas-z-[1000]"
      >
        <div className="atlas-text-sm atlas-leading-normal" data-testid="overdue-invoice-modal">
          <p>
            {t(
              "nav.overdue_invoice_modal.make_a_payment_now_to_ensure_your_landinsight_account_remains_active"
            )}
          </p>
          <p className="atlas-font-semibold">{t("nav.overdue_invoice_modal.already-paid")}</p>
          <p>{t("nav.overdue_invoice_modal.processed_payment")}</p>
          <p>
            {t("nav.overdue_invoice_modal.contact_message")}{" "}
            <a
              href={t("nav.overdue_invoice_modal.billing_email")}
              target="_blank"
              rel="noopener noreferrer"
            >
              {t("nav.overdue_invoice_modal.billing_email")}
            </a>
          </p>
        </div>
      </ModalV2.Body>
    </ModalV2.Root>
  );
};

export default OverdueInvoiceModal;
