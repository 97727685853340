import { useMemo } from "react";
import { scaleOrdinal } from "d3-scale";
import { isDefined } from "react-migration/lib/util/isDefined";
import { Color, rgb } from "d3-color";
import { SingleDesignation } from "react-migration/domains/constraints/typings/applicationTypes/SingleDesignation";

const DEMOGRAPHICS_COLOR_BLUE = rgb("#60a5fa");
const DEMOGRAPHICS_COLOR_ORANGE = rgb("#f97316");

export function useDemographicsColourAccessor(
  outputArea: SingleDesignation,
  comparableDesignation?: SingleDesignation
) {
  return useMemo(() => {
    const outputAreaIds = [outputArea.id, comparableDesignation?.id].filter(isDefined);

    return scaleOrdinal<Color>()
      .domain(outputAreaIds)
      .range([DEMOGRAPHICS_COLOR_BLUE, DEMOGRAPHICS_COLOR_ORANGE])
      .unknown(rgb("#999"));
  }, [outputArea, comparableDesignation]);
}
