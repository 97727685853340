import { PropsWithChildren } from "react";
import { CountPill } from "react-migration/components/CountPill";
import { isDefined } from "react-migration/lib/util/isDefined";

type SubSectionHeaderProps = PropsWithChildren<{
  tooltip?: string;
  count?: number;
}>;

export function SubSectionHeader({ count, children }: SubSectionHeaderProps) {
  return (
    <div className="atlas-flex atlas-items-baseline atlas-gap-x-1">
      <h1 className="atlas-text-xs atlas-font-normal atlas-text-content-tertiary atlas-pt-1 atlas-pb-0">
        {children}
      </h1>
      {isDefined(count) && <CountPill count={count} />}
    </div>
  );
}
