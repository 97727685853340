import { Workbox, WorkboxLifecycleWaitingEvent } from "workbox-window";
import { ENVIRONMENT } from "./util/environment";
import { datadogRum } from "@datadog/browser-rum";

let lastChecked = Date.now();
let updatedServiceWorkerVersion = "";
const MINIMUM_INTERVAL = 1000 * 60 * 30;
const SERVICE_WORKER_SUPPORTED = "ServiceWorker" in window;

export function init() {
  if (SERVICE_WORKER_SUPPORTED && ENVIRONMENT.APP_ENV === "production") {
    // WARNING: this is dependent on document.baseURI being tset. It currently ir bug will need changing if thati changed in future
    const wb = new Workbox("./service-worker.js");
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const showSkipWaitingPrompt = async (_event: WorkboxLifecycleWaitingEvent) => {
      // Assuming the user accepted the update, set up a listener
      // that will reload the page as soon as the previously waiting
      // service worker has taken control.
      wb.addEventListener("controlling", () => {
        // At this point, reloading will ensure that the current
        // tab is loaded under the control of the new service worker.
        // Depending on your web app, you may want to auto-save or
        // persist transient state before triggering the reload.
        //   window.location.reload();
        console.log("app updated in background");
        datadogRum.addAction("app-updated-in-background");
      });

      // When `event.wasWaitingBeforeRegister` is true, a previously
      // updated service worker is still waiting.
      // You may want to customize the UI prompt accordingly.

      // This code assumes your app has a promptForUpdate() method,
      // which returns true if the user wants to update.
      // Implementing this is app-specific; some examples are:
      // https://open-ui.org/components/alert.research or
      // https://open-ui.org/components/toast.research
      // const updateAccepted = await promptForUpdate();

      // at the moment we don't need to conditionally allow updates - it is only used for caching so just go straight to skipWaiting
      // if (updateAccepted) {
      wb.messageSkipWaiting();
      // }
    };

    wb.addEventListener("waiting", (event) => {
      showSkipWaitingPrompt(event);
    });

    wb.register();

    navigator.serviceWorker.addEventListener("message", ({ data }) => {
      if (data.type === "serviceworker-activate") {
        updatedServiceWorkerVersion = data.payload.version;
      }
    });
  }
}

export function checkForServiceWorkerUpdate() {
  const now = Date.now();
  if (now - lastChecked > MINIMUM_INTERVAL && SERVICE_WORKER_SUPPORTED) {
    lastChecked = now;
    window.requestIdleCallback(() => {
      window.navigator.serviceWorker.getRegistration().then((sw) => {
        sw?.update();
      });
    });
  }
}

export function reloadIfUpdateIsAvailable() {
  if (
    SERVICE_WORKER_SUPPORTED &&
    updatedServiceWorkerVersion &&
    updatedServiceWorkerVersion !== ENVIRONMENT.APP_VERSION
  ) {
    location.reload();
  }
}
