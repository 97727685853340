import { SitesListSitesQuery } from "@gql/li-api/graphql";
import { proxy } from "valtio/vanilla";
import { SkipTracingParcel } from "../../typings/SkipTracingParcel";
import { StageId } from "../../typings/Stage";
import { CreateSkipTraceOrderStep } from "../../typings/SkipTraceOrderStep";

export type SkipTracingStore = {
  selectedStageId: StageId | null;
  batchName: string;
  parcels: SkipTracingParcel[];
  parcelsLoading: boolean;
  totalSiteCount: SitesListSitesQuery["sites"]["totalCount"];
  selectedParcelIds: Record<string, boolean>;
  validationError: string | null;
  currentStep: CreateSkipTraceOrderStep;
};

export const defaultValues: SkipTracingStore = {
  selectedStageId: null,
  batchName: "",
  parcels: [],
  parcelsLoading: false,
  totalSiteCount: 0,
  selectedParcelIds: {},
  validationError: null,
  currentStep: CreateSkipTraceOrderStep.SelectSites,
};

export const skipTracingStore = proxy<SkipTracingStore>(defaultValues);

// It would be good to have this reset the store dynamically without explicitly resetting each property
// but TypeScript makes it impossible to assign the given keys to an object dynamically
export function resetSkipTracingStore(): void {
  skipTracingStore.selectedStageId = defaultValues.selectedStageId;
  skipTracingStore.batchName = defaultValues.batchName;
  skipTracingStore.parcels = defaultValues.parcels;
  skipTracingStore.parcelsLoading = defaultValues.parcelsLoading;
  skipTracingStore.totalSiteCount = defaultValues.totalSiteCount;
  skipTracingStore.selectedParcelIds = defaultValues.selectedParcelIds;
  skipTracingStore.validationError = defaultValues.validationError;
  skipTracingStore.currentStep = defaultValues.currentStep;
}
