import { useRef, useState } from "react";
import { useEventListener, useOnClickOutside } from "usehooks-ts";

import { Icon, LabelItem, TextInput } from "@landtechnologies/components";

import { Button } from "../Button";
import { Tag, TagColor } from "../Tag";

type TagRowProps = {
  label: LabelItem;
  onRowClick: () => void;
  onDelete: () => void;
  onUpdateLabel: ({ id, name }: { id: string; name: string }) => void;
};
export const TagSelectItem = ({ label, onRowClick, onDelete, onUpdateLabel }: TagRowProps) => {
  const [editMode, setEditMode] = useState(false);
  const [editLabelName, setEditLabelName] = useState(label.name);
  const ref = useRef(null);
  useOnClickOutside(ref, () => {
    setEditLabelName(label.name);
    setEditMode(false);
  });

  const inputRef = useRef(null);

  useEventListener(
    "keyup",
    (e) => {
      if (inputRef.current) {
        if (e.key === "Enter") {
          if (label.name !== editLabelName) onUpdateLabel({ id: label.id, name: editLabelName });
        }
      }
    },
    inputRef
  );

  if (editMode) {
    return (
      <div ref={ref}>
        <TextInput
          ref={inputRef}
          value={editLabelName}
          onChange={(e) => setEditLabelName(e.target.value)}
        />
      </div>
    );
  }

  return (
    <div
      data-testid="label-row"
      className="atlas-flex atlas-justify-between atlas-cursor-pointer atlas-text-neutral-800"
    >
      <button onClick={onRowClick}>
        <Tag key={label.id} tag={label.name} color={TagColor.DEFAULT} />
      </button>
      <div className="atlas-flex atlas-gap-2">
        <Button
          data-testid="edit-label"
          variant="ghost"
          className="atlas-w-[16px] atlas-h-[16px]"
          onClick={() => setEditMode(true)}
        >
          <Icon
            size="sm"
            icon="icon-lt-edit-2-line atlas-text-neutral-500"
            extraClasses="atlas-pointer-events-none"
          />
        </Button>
        <Button
          data-testid="delete-label"
          variant="ghost"
          className="atlas-w-[16px] atlas-h-[16px]"
          onClick={onDelete}
        >
          <Icon
            size="sm"
            icon="icon-lt-delete-bin-7-line atlas-text-neutral-500"
            extraClasses="atlas-pointer-events-none"
          />
        </Button>
      </div>
    </div>
  );
};
