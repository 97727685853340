import { groupBy, mapValues } from "lodash";
import { UtilityFeature } from "../../types";
import { useMemo } from "react";
import { useTranslation } from "react-migration/lib/i18n/useTranslation";
import { Accordion } from "@landtechnologies/components";
import cn from "clsx";
import { SelectionType, SelectionFeature } from "src/js/stores/map/store";
import { useUtilitiesLayerTypeContext } from "../../UtilitiesContext";
import {
  logUtilitiesReportEvent,
  ViewType,
} from "react-migration/domains/sites/components/SiteCard/ReportSidebar/logUtilitiesEvent";

interface UtilitiesListProps {
  features: UtilityFeature[];
  setDetailSelection(selection: SelectionFeature | undefined): void;
}

export const UtilitiesList = ({ features, setDetailSelection }: UtilitiesListProps) => {
  const { expandedCategory, setExpandedCategory } = useUtilitiesLayerTypeContext();

  const { t } = useTranslation();
  const groupedFeatures = useMemo(() => {
    const featuresGroupedByCategory = groupBy(
      features,
      (feature: UtilityFeature) => feature.properties.Category
    );
    return mapValues(featuresGroupedByCategory, (features: UtilityFeature[]) => ({
      providers: groupBy(features, (feature: UtilityFeature) => feature.properties.Provider),
      colour: features[0].properties.fill ?? features[0].properties.stroke,
    }));
  }, [features]);

  const onCategoryExpanded = (category: string) => {
    setExpandedCategory(category);
    logUtilitiesReportEvent({
      name: "Utilities Report - Line Clicked/Drop Down Clicked",
      context: {
        viewType: ViewType.MAP,
        type: category,
      },
    });
  };

  return (
    <div data-testid="utilities-list">
      <Accordion
        value={expandedCategory}
        onValueChange={onCategoryExpanded}
        type="single"
        className="atlas-flex atlas-flex-col atlas-border atlas-border-border-divider atlas-rounded atlas-divide-border-divider atlas-divide-y atlas-m-2"
        collapsible
      >
        {Object.entries(groupedFeatures).map(([categoryName, categoryData]) => (
          <Accordion.Item key={categoryName} value={categoryName}>
            <Accordion.Trigger asChild>
              <div className="atlas-flex atlas-p-3 atlas-justify-between atlas-items-center">
                <div className="atlas-flex atlas-gap-2 atlas-items-center">
                  <div
                    className="atlas-h-6 atlas-w-6 atlas-rounded"
                    style={{ backgroundColor: categoryData.colour }}
                  />
                  <div className="atlas-font-semibold" data-testid="utility-list-category">
                    {categoryName}
                  </div>
                </div>
                <div className="atlas-flex atlas-gap-1 atlas-items-center">
                  <div className="atlas-flex atlas-gap-1 atlas-h-6 atlas-items-center atlas-bg-red-500 atlas-text-white atlas-text-xs atlas-rounded-full atlas-px-3">
                    <div className="atlas-font-bold">
                      {Object.entries(categoryData.providers).length}
                    </div>
                    <div>{t("utilities.affected")}</div>
                  </div>
                  <i
                    className={cn("atlas-text-2xl", {
                      "icon-lt-arrow-up-s-line": categoryName === expandedCategory,
                      "icon-lt-arrow-down-s-line": categoryName !== expandedCategory,
                    })}
                  />
                </div>
              </div>
            </Accordion.Trigger>
            <Accordion.Content className="atlas-bg-white">
              <UtilityProvidersList
                utilitiesGroupedByProvider={categoryData.providers}
                categoryColor={categoryData.colour}
                setDetailSelection={setDetailSelection}
              />
            </Accordion.Content>
          </Accordion.Item>
        ))}
      </Accordion>
    </div>
  );
};

interface UtilityProvidersListProps {
  utilitiesGroupedByProvider: Record<string, UtilityFeature[]>;
  categoryColor: string;
  setDetailSelection(selection: SelectionFeature | undefined): void;
}

const UtilityProvidersList = ({
  utilitiesGroupedByProvider,
  categoryColor,
  setDetailSelection,
}: UtilityProvidersListProps) => {
  const { expandedProvider, setExpandedProvider } = useUtilitiesLayerTypeContext();

  return (
    <div data-testid="utilities-providers-list">
      <Accordion
        value={expandedProvider}
        onValueChange={setExpandedProvider}
        type="single"
        className="atlas-flex atlas-flex-col atlas-divide-border-divider atlas-divide-y"
        collapsible
      >
        {Object.entries(utilitiesGroupedByProvider).map(([providerName, utilityFeatures]) => (
          <Accordion.Item key={providerName} value={providerName}>
            <Accordion.Trigger asChild>
              <div className="atlas-flex atlas-justify-between atlas-items-center atlas-py-3 atlas-pr-3 atlas-pl-5">
                <div className="atlas-flex atlas-gap-2 atlas-items-center">
                  <div
                    className="atlas-h-6 atlas-w-6 atlas-rounded"
                    style={{ backgroundColor: categoryColor }}
                  />
                  <span
                    className="atlas-font-semibold atlas-text-content-link"
                    data-testid="utilities-provider-name"
                  >
                    {providerName} ({utilityFeatures.length})
                  </span>
                </div>
                <i
                  className={cn("atlas-text-2xl", {
                    "icon-lt-arrow-up-s-line": providerName === expandedProvider,
                    "icon-lt-arrow-down-s-line": providerName !== expandedProvider,
                  })}
                />
              </div>
            </Accordion.Trigger>
            <Accordion.Content className="atlas-bg-white">
              {utilityFeatures.map((utility) => (
                <UtilityListItem
                  key={utility.properties.UniqueID}
                  utility={utility}
                  setDetailSelection={setDetailSelection}
                />
              ))}
            </Accordion.Content>
          </Accordion.Item>
        ))}
      </Accordion>
    </div>
  );
};

interface UtilityListItemProps {
  utility: UtilityFeature;
  setDetailSelection(selection: SelectionFeature | undefined): void;
}

function UtilityListItem({
  utility: { properties },
  setDetailSelection,
}: Readonly<UtilityListItemProps>) {
  const itemText = [properties.UniqueID, properties.Provider].join(" ");
  return (
    <button
      key={properties.UniqueID}
      className="atlas-flex atlas-items-center atlas-gap-2 atlas-px-6 atlas-pr-3 atlas-py-3 atlas-text-content-link hover:atlas-text-content-link-hover atlas-w-full"
      onClick={() =>
        setDetailSelection({
          type: SelectionType.UTILITY_REPORT,
          id: properties.UniqueID,
        })
      }
    >
      <span
        className="atlas-h-4 atlas-w-4 atlas-rounded atlas-shrink-0"
        style={{
          backgroundColor: properties.fill ?? properties.stroke,
        }}
      />
      <span
        className="atlas-text-left atlas-truncate"
        title={itemText}
        data-testid="utilities-list-item-title"
      >
        {itemText}
      </span>
    </button>
  );
}
