import { ApolloError, FetchResult, useMutation } from "@apollo/client";

import CREATE_LABEL from "../graphql/createLabel.gql";
import { routedClient } from "react-migration/lib/persistence/apollo/routedClient";

export interface CreateLabelResponse {
  _id: string;
  name: string;
}

export interface CreateLabelVariables {
  labelCreation: {
    name: string;
  };
}

export const useCreateLabel = () => {
  const [createLabel, { loading, error }] = useMutation<CreateLabelResponse>(CREATE_LABEL, {
    client: routedClient,
  });

  const createLabelMutation = async (
    variables: CreateLabelVariables
  ): Promise<FetchResult<CreateLabelResponse>> => {
    try {
      const result = await createLabel({ variables, refetchQueries: ["allSiteLabels"] });
      return result;
    } catch (error) {
      console.error(`Error removing label: ${error}`);
      throw error as ApolloError;
    }
  };

  return { createLabelMutation, loading, error };
};
