import { useMemo } from "react";

import { LabelItem } from "@landtechnologies/components";

import { Tags } from "../Tags";
import { TagColor } from "../Tag";
import { AddTagButton, PopoverSide } from "./AddTagButton";

export interface TagsSelectionProps {
  selectedLabels: LabelItem[];
  allLabels: LabelItem[];
  noLabelsText: string;
  labelExistsText: string;
  searchInputPlaceHolder: string;
  onRemoveLabel: (id: string) => void;
  onAddLabel: (item: LabelItem) => void;
  onArchiveLabel: (item: LabelItem) => void;
  onUpdateLabel: ({ id, name }: { id: string; name: string }) => void;
  onCreateLabel: (name: string) => void;
  truncate?: boolean;
  popoverSide?: PopoverSide;
}

export function TagsSelect({
  selectedLabels,
  allLabels,
  noLabelsText,
  labelExistsText,
  searchInputPlaceHolder,
  onRemoveLabel,
  onAddLabel,
  onArchiveLabel,
  onUpdateLabel,
  onCreateLabel,
  truncate,
  popoverSide = "bottom",
}: TagsSelectionProps) {
  const suggestedLabels = useMemo(
    () =>
      allLabels.filter(
        (label) => !selectedLabels.find((assignedLabel) => label.id === assignedLabel.id)
      ),
    [allLabels, selectedLabels]
  );

  return (
    <div className="atlas-flex atlas-items-center atlas-gap-2 atlas-relative atlas-py-0.5">
      <div className="atlas-flex atlas-gap-1 atlas-overflow-y-auto">
        <Tags
          tags={selectedLabels.map((label) => ({
            id: label.id,
            tag: label.name,
            color: TagColor.DEFAULT,
            onClose: (e) => {
              e.stopPropagation();
              onRemoveLabel(label.id);
            },
          }))}
          placeholder={noLabelsText}
          truncate={truncate}
          truncateCount={3}
        />
      </div>
      <div className="atlas-flex atlas-items-center">
        <AddTagButton
          title={noLabelsText}
          allLabels={allLabels}
          suggestedLabels={suggestedLabels}
          selectedLabels={selectedLabels}
          onAddLabel={onAddLabel}
          onArchiveLabel={onArchiveLabel}
          onUpdateLabel={onUpdateLabel}
          onCreateLabel={onCreateLabel}
          labelExistsText={labelExistsText}
          searchInputPlaceHolder={searchInputPlaceHolder}
          popoverSide={popoverSide}
        />
      </div>
    </div>
  );
}
