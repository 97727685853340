import { logEvent } from "react-migration/lib/util/logEvent";

export enum ViewType {
  MAP = "Map",
  SITE_CARD = "Site Card",
}

type UTILITIES_REPORT_EVENTS =
  | { name: "Utilities Report - View Info Clicked" }
  | { name: "Utilities Report - Credit Payment Button Clicked" }
  | { name: "Utilities Report - View Sample Report Button Clicked" }
  | { name: "Utilities Report - See Demo Button Clicked" }
  | { name: "Utilities Report - Balance Top Up Clicked" }
  | { name: "Utilities Report - Confirm Payment Button Clicked" }
  | { name: "Utilities Report - Cancel Payment Button Clicked" }
  | { name: "Utilities Report - Close Payment Button Clicked" }
  | {
      name: "Utilities Report - Download Report Clicked";
      context: {
        viewType: ViewType;
      };
    }
  | {
      name: "Utilities Report - Line Clicked/Drop Down Clicked";
      context: {
        viewType: ViewType;
        type: string;
      };
    };

export function logUtilitiesReportEvent(event: UTILITIES_REPORT_EVENTS) {
  if ("context" in event) {
    logEvent(event.name, event.context);
  } else {
    logEvent(event.name, {});
  }
}
