import { QueryHookOptions, useQuery } from "@apollo/client";
import DESIGNATIONS_SUMMARY_BY_GEOMETRY from "react-migration/domains/constraints/apollo/designationsSummaryByGeometry.gql";
import { SOURCE_STATUSES } from "react-migration/domains/constraints/constants";
import { Nullable } from "src/js/types/Nullable";
import { cleanTypename } from "react-migration/lib/util/cleanTypename";
import { LandTechEndpoints, routedClient } from "react-migration/lib/persistence/apollo";
import { SelectionGeometry } from "src/js/stores/map/store";
import { useMemo } from "react";

type UseDesignationsByGeometryArgs = {
  geometry: Nullable<SelectionGeometry> | null;
  rootCategories: string[];
  bufferMeters?: number;
  queryOptions?: QueryHookOptions;
};

export function useDesignationsSummaryByGeometry({
  geometry,
  rootCategories,
  bufferMeters,
  queryOptions = {},
}: UseDesignationsByGeometryArgs) {
  const cleanGeometry = useMemo(() => cleanTypename(geometry), [geometry]);

  const { data, loading } = useQuery<{ designationsSummaryByGeometry: string }>(
    DESIGNATIONS_SUMMARY_BY_GEOMETRY,
    {
      variables: {
        geometry: cleanGeometry,
        dataStatuses: ["live"],

        // For the considerations query we have custom code to style by rag_status.
        // TODO: make this dynamic when the bundle concept is app wide.
        attributeKeys: ["rag_status"],
        sourceStatuses: SOURCE_STATUSES.map((ss) => ss.id),
        categories: rootCategories,
        bufferMeters,
      },
      client: routedClient,
      context: {
        endpoint: LandTechEndpoints.Constraints,
      },
      skip: !geometry,
      ...queryOptions,
    }
  );

  return { summary: data?.designationsSummaryByGeometry, summaryLoading: loading };
}
