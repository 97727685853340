import { ISourcingToolSearchFilter } from "react-migration/domains/search/typings/SourcingToolSpecificFilterTypes";
import { SearchType } from "react-migration/domains/search/constants/searchTypes";

export const GreyBeltTypeFilter: ISourcingToolSearchFilter = {
  id: "grey-belt-type-id",
  type: "checkbox",
  field: "sidebar.react.search_sidebar.grey_belt_type",
  must: "MATCH_ANY",
  mustOptions: ["MATCH_ANY", "MATCH_NONE"],
  updateFilterDialogIsVisible: false,
  searchTypes: [SearchType.SOURCING_TOOL_UK],
  retainValuesOrder: true,
  values: [
    { key: "grey_belt_land_most_favourable", name: "Most favourable", checked: true },
    { key: "grey_belt_land_more_favourable", name: "More favourable", checked: true },
    { key: "grey_belt_land_average_favourability", name: "Average favourability", checked: true },
    { key: "grey_belt_land_less_favourable", name: "Less favourable", checked: true },
    { key: "grey_belt_land_least_favourable", name: "Least favourable", checked: true },
  ],
};
