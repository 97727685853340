/* eslint-disable react/display-name */
import meanBy from "lodash/meanBy";
import { ForwardedRef, forwardRef, useEffect, useState } from "react";
import {
  Comparable,
  useComparablePointSearch,
} from "react-migration/domains/comparables/hooks/useComparablePointSearch";
import {
  measurementString,
  measurementValue,
  unitString,
} from "react-migration/lib/util/conversion";
import { useTranslation } from "react-migration/lib/i18n/useTranslation";
import { logEvent } from "react-migration/lib/util/logEvent";
import Feature from "src/js/stores/user/Feature";
import { getMeasurementSystemPreference } from "src/js/stores/user/actions/getSetting";
import hasFeature from "src/js/stores/user/actions/hasFeature";
import { MeasurementSystem } from "src/js/stores/user/store";

import { Button, Table } from "@landtechnologies/components";
import { SiteOverview } from "../../typings/Site";

type PricedAndEstimatedAndFloorAreaComp = Omit<
  Comparable,
  "total_floor_area" | "price" | "est_price"
> & {
  total_floor_area: number;
  price: number;
  est_price: number;
};
type ComparablesReportProps = {
  site: SiteOverview;
  onLoaded?: (isLoaded: boolean) => void;
};

export const ComparablesReport = forwardRef(
  (
    { site, onLoaded = () => false }: ComparablesReportProps,
    comparablesReportRef: ForwardedRef<HTMLDivElement>
  ) => {
    const { t } = useTranslation();
    const { comparables, loading } = useComparablePointSearch(site.location);

    useEffect(() => {
      if (!loading) {
        onLoaded(true);
      }
    }, [loading, onLoaded]);

    const [hiddenComparableIds, setHiddenComparableIds] = useState<string[]>([]);
    const isImperial = getMeasurementSystemPreference() === MeasurementSystem.IMPERIAL;
    const isUSUser = hasFeature(Feature.usAccess); // Fudge for £ vs $, ideally have a unitType on the row data itself.

    const filteredComparables = comparables?.filter(
      (comparable) => !hiddenComparableIds.includes(comparable.address)
    );

    const avgSoldPrice = meanBy(
      filteredComparables?.filter(
        (comparable) => comparable.price && comparable.total_floor_area
      ) as PricedAndEstimatedAndFloorAreaComp[],
      (comparable) =>
        comparable.price / measurementValue(comparable.total_floor_area, "m2", isImperial)
    );
    const avgMarketValue = meanBy(
      filteredComparables?.filter(
        (comparable) => comparable.est_price && comparable.total_floor_area
      ) as PricedAndEstimatedAndFloorAreaComp[],
      (comparable) =>
        comparable.est_price / measurementValue(comparable.total_floor_area, "m2", isImperial)
    );

    return (
      <div
        data-testid="comparables-report"
        className="atlas-mb-4 print:atlas-mt-4 print:atlas-break-before-page"
        ref={comparablesReportRef}
      >
        <div className="atlas-mb-4">
          <h2 className="atlas-mb-2">{t("sites.card.site_report.comparables")}</h2>
          <div>
            {t("sites.card.site_report.comparables.avg_sold_price")} ({isUSUser ? "$" : "£"}/
            {unitString("m2", isImperial)}): {kFormat(avgSoldPrice)}
          </div>
          <div>
            {t("sites.card.site_report.comparables.avg_market_value")} ({isUSUser ? "$" : "£"}/
            {unitString("m2", isImperial)}): {kFormat(avgMarketValue)}
          </div>
        </div>
        <div className="atlas-mb-1 atlas-italic atlas-text-neutral-500">
          {t("sites.card.site_report.comparables.comparables_with_nr_of_site_boundary", {
            distance: measurementString(500, "m", isImperial),
          })}
        </div>
        <Table<Comparable, "address">
          size="small"
          primaryKey="address"
          columns={[
            {
              label: t("sites.card.site_report.comparables.address"),
              render: ({ address }) => (
                <span className="print:atlas-whitespace-pre-line print:!atlas-w-[250px]">
                  {address}
                </span>
              ),
              grow: true,
            },
            {
              label: t("sites.card.site_report.comparables.date_sold"),
              render: (row) => new Date(row.date_of_transfer).toLocaleDateString(),
              grow: true,
            },
            {
              label: t("sites.card.site_report.comparables.sold_price", {
                price: isUSUser ? "$" : "£",
              }),
              render: ({ price }) => kFormat(price),
              grow: true,
            },
            {
              label: t("sites.card.site_report.comparables.est_market_value", {
                currency: isUSUser ? "$" : "£",
              }),
              render: ({ est_price }) => (est_price ? kFormat(est_price) : undefined),
              grow: true,
            },
            {
              label: t("sites.card.site_report.comparables.new_build"),
              render: ({ is_new_property }) => (is_new_property ? "Yes" : "No"),
              grow: true,
            },
            {
              label: t("sites.card.site_report.comparables.property_type"),
              rowKey: "category",
              grow: true,
            },
            {
              label: t("sites.card.site_report.comparables.est_bedrooms"),
              render: ({ est_bedrooms }) => est_bedrooms?.join("-"),
              grow: true,
            },
            {
              label: t("sites.card.site_report.comparables.floor_area", {
                area: unitString("m2", isImperial),
              }),
              render: ({ total_floor_area }) =>
                total_floor_area
                  ? measurementString(total_floor_area, "m2", isImperial, false)
                  : undefined,
              grow: true,
            },
            {
              label: t("sites.card.site_report.comparables.sold_price", {
                price: `${isUSUser ? "$" : "£"}/${unitString("m2", isImperial)}`,
              }),
              render: ({ price, total_floor_area }) =>
                price && total_floor_area
                  ? kFormat(price / measurementValue(total_floor_area, "m2", isImperial))
                  : undefined,
              grow: true,
            },
            {
              label: t("sites.card.site_report.comparables.market_value", {
                value: `${isUSUser ? "$" : "£"}/${unitString("m2", isImperial)}`,
              }),
              render: ({ est_price, total_floor_area }) =>
                est_price && total_floor_area
                  ? kFormat(est_price / measurementValue(total_floor_area, "m2", isImperial))
                  : undefined,
              grow: true,
            },
            { label: t("sites.card.site_report.comparables.tenure"), rowKey: "tenure", grow: true },
            {
              label: "#",
              grow: true,
              render: (row) => (
                <Button.Danger
                  onClick={() => {
                    logEvent("Site Report - Delete Row - clicked", { row_id: row.address });
                    setHiddenComparableIds([...hiddenComparableIds, row.address]);
                  }}
                  className="atlas-pl-2 atlas-pr-2 print:atlas-hidden"
                >
                  <i className="icon-lt-delete-bin-line atlas-text-sm atlas-m-0"></i>
                </Button.Danger>
              ),
            },
          ]}
          rows={filteredComparables || []}
          tableClassName="atlas-block atlas-overflow-x-scroll print:atlas-table print:atlas-table-fixed print:atlas-overflow-hidden"
        />
      </div>
    );
  }
);

const kFormat = (value: number) => {
  return Math.abs(value) > 999
    ? ((Math.sign(value) * Math.abs(value)) / 1000).toFixed(1) + "k"
    : (Math.sign(value) * Math.abs(value)).toFixed(1);
};
