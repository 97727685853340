import { logEvent } from "react-migration/lib/util/logEvent";
import { LetterStep } from "src/js/stores/letter/store";
import Feature from "src/js/stores/user/Feature";
import hasFeature from "src/js/stores/user/actions/hasFeature";

enum LetterLocation {
  US = "US",
  UK = "UK",
}

export function logLetterEvent(event: LetterEvent) {
  const usAccess = hasFeature(Feature.usAccess);
  const defaultContext = {
    location: usAccess ? LetterLocation.US : LetterLocation.UK,
  };
  if ("context" in event) {
    logEvent(event.name, { ...defaultContext, ...event.context });
  } else {
    logEvent(event.name, defaultContext);
  }
}

export enum LetterEventName {
  // Select Recipients Step
  LETTERS_FORM_SELECT_RECIPIENTS_STEP_RECIPIENT_TOGGLED = "Letters Form - Select Recipients Step - Recipient Toggled",
  LETTERS_FORM_SELECT_RECIPIENTS_STEP_ALL_RECIPIENTS_TOGGLED = "Letters Form - Select Recipients Step - All Recipients Toggled",
  LETTERS_FORM_SELECT_RECIPIENTS_STEP_CREATE_LETTER_BUTTON_CLICKED = "Letters Form - Select Recipients Step - Create Letter Button Clicked",
  LETTERS_FORM_SELECT_RECIPIENTS_STEP_BACK_BUTTON_CLICKED = "Letters Form - Select Recipients Step - Back Button Clicked",
  // General Letter Form Events
  LETTERS_FORM_SAVE_AND_CLOSE_CLICKED = "Letters Form - Save And Close Clicked",
  LETTERS_FORM_BACK_TO_SITES_CLICKED = "Letters Form - Back To Sites Clicked",
  LETTERS_FORM_LETTER_TITLE_UPDATED = "Letters Form - Letter Title Updated",
  // Create Letter Step
  LETTERS_FORM_CREATE_LETTER_STEP_PREVIEW_LETTER_BUTTON_CLICKED = "Letters Form - Create Letter Step - Preview Letter Button Clicked",
  LETTERS_FORM_CREATE_LETTER_STEP_COMPANY_LOGO_UPLOADED = "Letters Form - Create Letter Step - Company Logo Uploaded",
  LETTERS_FORM_CREATE_LETTER_STEP_SIGNATURE_UPLOADED = "Letters Form - Create Letter Step - Signature Uploaded",
  LETTERS_FORM_CREATE_LETTER_STEP_ADD_NEW_PAGE_CLICKED = "Letters Form - Create Letter Step - Add New Page Clicked",
  LETTERS_FORM_CREATE_LETTER_STEP_PAGE_DELETED = "Letters Form - Create Letter Step - Page Deleted",

  LETTERS_FORM_CREATE_LETTER_STEP_SATELLITE_IMAGE_INSERTED = "Letters Form - Create Letter Step - Satellite Image Inserted",
  LETTERS_FORM_CREATE_LETTER_STEP_BACK_BUTTON_CLICKED = "Letters Form - Create Letter Step - Back Button Clicked",
  // Preview Step
  LETTERS_FORM_PREVIEW_STEP_RECIPIENT_DETAILS_UPDATED = "Letters Form - Preview Step - Recipient Details Updated",
  LETTERS_FORM_PREVIEW_STEP_FINISH_CLICKED = "Letters Form - Preview Step - Finish Clicked",
  LETTERS_FORM_PREVIEW_STEP_CONFIRM_AND_SEND_CLICKED = "Letters Form - Preview Step - Confirm and send Clicked",
  LETTERS_FORM_PREVIEW_STEP_BACK_BUTTON_CLICKED = "Letters Form - Preview Step - Back Button Clicked",
  // Confirm and Send Step
  LETTERS_FORM_CONFIRM_AND_SEND_STEP_CONFIRM_AND_SEND_BUTTON_CLICKED = "Letters Form - Confirm and Send Step - Confirm and Send Button Clicked",
  LETTERS_FORM_CONFIRM_AND_SEND_STEP_CONFIRM_AND_DOWNLOAD_BUTTON_CLICKED = "Letters Form - Confirm and Send Step - Confirm and Download Button Clicked",
  LETTERS_FORM_CONFIRM_AND_SEND_STEP_BACK_BUTTON_CLICKED = "Letters Form - Confirm and Send Step - Back Button Clicked",
  LETTERS_FORM_CONFIRM_AND_SEND_STEP_SEND_VIA_LANDINSIGHT_OPTION_CLICKED = "Letters Form - Confirm and Send Step - Send via LandInsight option clicked",
  LETTERS_FORM_CONFIRM_AND_SEND_STEP_DOWNLOAD_OPTION_CLICKED = "Letters Form - Confirm and Send Step - Download option clicked",
  // Order Confirmed Page
  LETTERS_FORM_ORDER_CONFIRMED_PAGE_BACK_TO_SITES_BUTTON_CLICKED = "Letters Form - Order Confirmed Page - Back To Sites Button Clicked",
  // Finish and Send Step
  LETTERS_FORM_FINISH_AND_SEND_STEP_SENT_SUCCESSFULLY = "Letters Form - Confirm and Send Step - Sent successfully",
  LETTERS_FORM_FINISH_AND_SEND_STEP_SEND_FAILED = "Letters Form - Confirm and Send Step - Send failed",
  // Finish and Download Step
  LETTERS_FORM_FINISH_STEP_GO_TO_SAVED_SITES_BUTTON_CLICKED = "Letters Form - Finish Step - Go To Saved Sites Button Clicked",
  LETTERS_FORM_FINISH_STEP_VIEW_LETTERS_BUTTON_CLICKED = "Letters Form - Finish Step - View Letters Button Clicked",
  LETTERS_FORM_FINISH_STEP_VIEW_LETTER_CAMPAIGNS_BUTTON_CLICKED = "Letters Form - Finish Step - View Letter Campaigns Button Clicked",
  LETTERS_FORM_FINISH_AND_DOWNLOAD_STEP_DOWNLOAD_MANUALLY_CLICKED = "Letters Form - Finish And Download Step - Download Manually Clicked",
  LETTERS_FORM_FINISH_AND_DOWNLOAD_STEP_DOWNLOAD_FAILED = "Letters Form - Finish And Download Step - Download Failed",
  // Letter Batches
  LETTER_BATCHES_TABLE_DOWNLOAD_MENU_BUTTON_CLICKED = "Letter Batches Table - Download Menu Button Clicked",
  LETTER_BATCHES_TABLE_DOWNLOAD_SUCCESSFUL = "Letter Batches Table - Download Successful",
  LETTER_BATCHES_TABLE_DOWNLOAD_FAILED = "Letter Batches Table - Download Failed",
}

export enum LetterFormSteps {
  SELECT_RECIPIENTS = "Select Recipients",
  CREATE_LETTER = "Create Letter",
  PREVIEW = "Preview",
  FINISH_AND_DOWNLOAD = "Finish and Download",
  CONFIRM_AND_SEND = "Confirm and Send",
  ORDER_CONFIRMED = "Order Confirmed",
}

export function mapToLetterFormStepStringEnum(step: LetterStep) {
  const map = {
    [LetterStep.TemplateEditor]: LetterFormSteps.CREATE_LETTER,
    [LetterStep.FinishAndDownload]: LetterFormSteps.FINISH_AND_DOWNLOAD,
    [LetterStep.PreviewLetter]: LetterFormSteps.PREVIEW,
    [LetterStep.SelectRecipients]: LetterFormSteps.SELECT_RECIPIENTS,
    [LetterStep.ConfirmAndSend]: LetterFormSteps.CONFIRM_AND_SEND,
    [LetterStep.OrderConfirmed]: LetterFormSteps.ORDER_CONFIRMED,
  };

  return map[step];
}

type LetterEvent =
  | { name: LetterEventName.LETTERS_FORM_SELECT_RECIPIENTS_STEP_RECIPIENT_TOGGLED }
  | { name: LetterEventName.LETTERS_FORM_SELECT_RECIPIENTS_STEP_ALL_RECIPIENTS_TOGGLED }
  | { name: LetterEventName.LETTERS_FORM_SELECT_RECIPIENTS_STEP_CREATE_LETTER_BUTTON_CLICKED }
  | { name: LetterEventName.LETTERS_FORM_SELECT_RECIPIENTS_STEP_BACK_BUTTON_CLICKED }
  | {
      name: LetterEventName.LETTERS_FORM_SAVE_AND_CLOSE_CLICKED;
      context: {
        step: LetterFormSteps;
      };
    }
  | {
      name: LetterEventName.LETTERS_FORM_BACK_TO_SITES_CLICKED;
      context: {
        step: LetterFormSteps;
      };
    }
  | {
      name: LetterEventName.LETTERS_FORM_LETTER_TITLE_UPDATED;
      context: {
        step: LetterFormSteps;
      };
    }
  | { name: LetterEventName.LETTERS_FORM_CREATE_LETTER_STEP_PREVIEW_LETTER_BUTTON_CLICKED }
  | { name: LetterEventName.LETTERS_FORM_CREATE_LETTER_STEP_COMPANY_LOGO_UPLOADED }
  | { name: LetterEventName.LETTERS_FORM_CREATE_LETTER_STEP_SIGNATURE_UPLOADED }
  | { name: LetterEventName.LETTERS_FORM_CREATE_LETTER_STEP_ADD_NEW_PAGE_CLICKED }
  | { name: LetterEventName.LETTERS_FORM_CREATE_LETTER_STEP_PAGE_DELETED }
  | { name: LetterEventName.LETTERS_FORM_CREATE_LETTER_STEP_SATELLITE_IMAGE_INSERTED }
  | { name: LetterEventName.LETTERS_FORM_CREATE_LETTER_STEP_BACK_BUTTON_CLICKED }
  | { name: LetterEventName.LETTERS_FORM_PREVIEW_STEP_RECIPIENT_DETAILS_UPDATED }
  | { name: LetterEventName.LETTERS_FORM_PREVIEW_STEP_CONFIRM_AND_SEND_CLICKED }
  | { name: LetterEventName.LETTERS_FORM_PREVIEW_STEP_FINISH_CLICKED }
  | { name: LetterEventName.LETTERS_FORM_PREVIEW_STEP_BACK_BUTTON_CLICKED }
  | { name: LetterEventName.LETTERS_FORM_CONFIRM_AND_SEND_STEP_CONFIRM_AND_SEND_BUTTON_CLICKED }
  | { name: LetterEventName.LETTERS_FORM_CONFIRM_AND_SEND_STEP_CONFIRM_AND_DOWNLOAD_BUTTON_CLICKED }
  | { name: LetterEventName.LETTERS_FORM_CONFIRM_AND_SEND_STEP_BACK_BUTTON_CLICKED }
  | { name: LetterEventName.LETTERS_FORM_CONFIRM_AND_SEND_STEP_SEND_VIA_LANDINSIGHT_OPTION_CLICKED }
  | { name: LetterEventName.LETTERS_FORM_CONFIRM_AND_SEND_STEP_DOWNLOAD_OPTION_CLICKED }
  | {
      name: LetterEventName.LETTERS_FORM_FINISH_AND_SEND_STEP_SENT_SUCCESSFULLY;
      context: {
        letterCount: number;
        siteCount: number;
      };
    }
  | {
      name: LetterEventName.LETTERS_FORM_FINISH_AND_SEND_STEP_SEND_FAILED;
      context: {
        letterCount: number;
        siteCount: number;
      };
    }
  | { name: LetterEventName.LETTERS_FORM_FINISH_STEP_GO_TO_SAVED_SITES_BUTTON_CLICKED }
  | { name: LetterEventName.LETTERS_FORM_FINISH_STEP_VIEW_LETTERS_BUTTON_CLICKED }
  | { name: LetterEventName.LETTERS_FORM_FINISH_STEP_VIEW_LETTER_CAMPAIGNS_BUTTON_CLICKED }
  | { name: LetterEventName.LETTERS_FORM_FINISH_AND_DOWNLOAD_STEP_DOWNLOAD_MANUALLY_CLICKED }
  | { name: LetterEventName.LETTERS_FORM_FINISH_AND_DOWNLOAD_STEP_DOWNLOAD_FAILED }
  | { name: LetterEventName.LETTERS_FORM_ORDER_CONFIRMED_PAGE_BACK_TO_SITES_BUTTON_CLICKED }
  | {
      name: LetterEventName.LETTER_BATCHES_TABLE_DOWNLOAD_MENU_BUTTON_CLICKED;
      context: {
        pdfCount: number;
      };
    }
  | {
      name: LetterEventName.LETTER_BATCHES_TABLE_DOWNLOAD_SUCCESSFUL;
      context: {
        pdfCount: number;
      };
    }
  | {
      name: LetterEventName.LETTER_BATCHES_TABLE_DOWNLOAD_FAILED;
      context: {
        pdfCount: number;
      };
    };
