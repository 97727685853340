import VueRouter from "vue-router";
import { checkUser, logNavigation } from "./guards";
import { DashboardRoutes } from "./routes/dashboard";
import { SitesRoutes } from "./routes/sites";
import { AccountRoutes } from "./routes/account";
import { PublicRoutes } from "./routes/public";
import { MapRoutes } from "./routes/map";
import { ENVIRONMENT } from "../util/environment";
import ROUTES from "./routes";
import { userStore } from "../stores/user/store";
import { checkForServiceWorkerUpdate, reloadIfUpdateIsAvailable } from "../service-worker-window";
import { Dictionary } from "vue-router/types/router";

const router = new VueRouter({
  base: ENVIRONMENT.BASE_URL,
  mode: "history",
  routes: [
    ...DashboardRoutes,
    ...SitesRoutes,
    ...AccountRoutes,
    ...PublicRoutes,
    ...MapRoutes,
    {
      path: "*",
      redirect: "/map",
    },
  ],
});

router.beforeEach((to, from, next) => {
  const { user } = userStore;
  const userCheck = checkUser({ user, routeName: to.name });
  checkForServiceWorkerUpdate();
  if (!userCheck.authenticated) {
    next({
      name: ROUTES.LOGOUT,
      params: { error: userCheck.error || "" },
    });
  } else {
    logNavigation(to);
    next();
  }
});

router.afterEach((to, from) => {
  if (from.fullPath !== "/" && to.fullPath.split("/")[1] !== from.fullPath.split("/")[1]) {
    reloadIfUpdateIsAvailable();
  }
});

type WithoutRefreshParams = {
  name?: string;
  params?: Dictionary<string>;
  query?: Dictionary<string>;
};

const redirectWithoutMapRefresh = ({ name, params, query }: WithoutRefreshParams) => {
  const { href } = router.resolve({
    name,
    params,
    query,
  });
  window.history.pushState({}, "", href);
};

export { router, redirectWithoutMapRefresh };
