import { useTranslation } from "react-migration/lib/i18n/useTranslation";

export const Header = () => {
  const { t } = useTranslation();
  return (
    <div className="atlas-pb-6">
      <h2 className="atlas-font-semibold atlas-text-2xl" data-testid="header-title">
        {t("dashboard.topup.landtech_balance")}
      </h2>
      <p className="atlas-text-base atlas-text-neutral-600" data-testid="header-subtitle">
        {t("dashboard.topup.balance_subtitle")}
      </p>
    </div>
  );
};
