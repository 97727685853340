import classNames from "classnames";
import { letterStore } from "src/js/stores/letter/store";
import { useSnapshot } from "valtio";

export const SecondPageContent = () => {
  const {
    letter: { bodyTextPage2 },
  } = useSnapshot(letterStore);

  return (
    <div data-testid="letter-page2-body" className="atlas-mt-4">
      <div
        className={classNames("atlas-mt-4", "atlas-whitespace-pre-wrap", "atlas-break-words")}
        data-testid="letter-page2-body-content"
      >
        {bodyTextPage2}
      </div>
    </div>
  );
};
