import { ChangeEvent, useCallback, useEffect, useMemo, useState } from "react";
import { EditIcon, TextInput } from "@landtechnologies/components";

import { updateBatchName } from "../../store/actions/updateBatchName";
import { useTranslation } from "react-migration/lib/i18n/useTranslation";
import { useBatchName } from "../../store/hooks/useBatchName";
import { useParcels } from "../../store/hooks/useParcels";
import { useSelectedParcelIds } from "../../store/hooks/useSelectedParcelIds";
import { useTotalSiteCount } from "../../store/hooks/useTotalSiteCount";
import logSiteCardEvent from "src/js/view/sites/logSiteCardEvent";

export const SkipTraceOrderTableHeader = () => {
  const { t } = useTranslation();

  const storedOrderName = useBatchName();
  const parcels = useParcels();
  const selectedParcelIds = useSelectedParcelIds();
  const totalSiteCount = useTotalSiteCount();

  const [orderName, setOrderName] = useState(storedOrderName);

  useEffect(() => {
    setOrderName(storedOrderName);
  }, [storedOrderName]);

  const selectedParcelsCount = useMemo(() => {
    return Object.keys(selectedParcelIds).filter((key) => selectedParcelIds[key]).length;
  }, [selectedParcelIds]);

  const orderNameChangeHandler = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setOrderName(e.target.value);
  }, []);

  const orderNameInputBlurHandler = useCallback(() => {
    updateBatchName(orderName);
    logSiteCardEvent({ name: "Skip Trace - Batch Sites Table Named" });
  }, [orderName]);

  return (
    <div className="atlas-flex-row atlas-p-4" data-testid="skip-trace-order-table-header">
      <div className="atlas-flex atlas-flex-col atlas-gap-2">
        <div className="atlas-w-80">
          <TextInput
            dataTestId="skip-trace-name-input"
            placeholder={t("sites.skip_tracing.order_table.enter_batch_name")}
            IconLeft={<EditIcon />}
            value={orderName}
            size="medium"
            onChange={orderNameChangeHandler}
            onBlur={orderNameInputBlurHandler}
          />
        </div>
        <span
          className="atlas-flex atlas-text-xs"
          data-testid="skip-trace-order-table-header-subtitile"
        >
          <span>
            {t("sites.skip_tracing.order_table_header.info_subtitle_parcels", {
              selectedParcelsCount,
              totalParcelsCount: parcels.length,
            })}
          </span>{" "}
          •{" "}
          {t("sites.skip_tracing.order_table_header.info_subtitle_site_count", {
            totalSiteCount,
          })}
        </span>
      </div>
    </div>
  );
};
