import { useLazyQuery } from "@apollo/client";
import { useEffect, useRef, useState, useCallback } from "react";
import GET_SITE_LETTER_IMAGE_UPLOAD_URL from "react-migration/domains/sites/apollo/sites-api/queries/getSiteLetterImageUploadUrl.gql";
import SITE_LETTER_IMAGE_SCAN_STATUS from "react-migration/domains/sites/apollo/sites-api/queries/siteLetterImageAntivirusScanStatus.gql";
import SITE_LETTER_IMAGE_DOWNLOAD_URL from "react-migration/domains/sites/apollo/sites-api/queries/siteLetterImageDownloadUrl.gql";
import {
  LandTechEndpoints,
  routedClient,
} from "react-migration/lib/persistence/apollo/routedClient";
import { useUploadImageToS3 } from "react-migration/domains/sites/hooks/useUploadImageToS3";
import { ImageUploadStatus, UploadStatus } from "./ImageUploadStatus";
import { AntivirusScanStatuses } from "react-migration/domains/sites/card/Attachments/components/Attachment/graphql/hooks/usePollingSiteAttachmentAntivirusScanStatus";
import {
  LetterEventName,
  logLetterEvent,
} from "react-migration/domains/sites/letters/helpers/logLetterEvent";
import { ControllerRenderProps } from "react-hook-form";
import { TemplateFormData } from "../TemplateFormDataSchema";
import {
  ImageUpload,
  ImageUploadHandle,
} from "react-migration/domains/sites/letters/components/ImageUpload";
import { SiteLetterImageDownloadUrlResponse } from "react-migration/domains/sites/typings/apollo";

interface SiteLetterImageUploadProps {
  field:
    | ControllerRenderProps<TemplateFormData, "logo">
    | ControllerRenderProps<TemplateFormData, "signature">;
  onUploadStateChange: (isUploading: boolean) => void;
  "data-testid"?: string;
}

type SiteLetterImageAntivirusScanStatusResponse = {
  siteLetterImage: {
    antivirusScanStatus: string;
  };
};

type SiteLetterImageUploadUrl = {
  getSiteLetterImageUploadUrl: {
    signedUploadUrl: string;
    s3ObjectKey: string;
  };
};

export const SiteLetterImageUpload = ({
  field,
  onUploadStateChange,
  "data-testid": dataTestId,
}: SiteLetterImageUploadProps) => {
  const dropzoneRef = useRef<ImageUploadHandle>(null);

  const [uploadStatus, setUploadStatus] = useState(UploadStatus.Initialised);
  const [file, setFile] = useState<File>();
  const [signedUploadUrl, setSignedUploadUrl] = useState("");
  const [s3ObjectKey, setS3ObjectKey] = useState("");

  const [
    getSignedUrl,
    {
      data: signedUrlData,
      error: signedUrlError,
      refetch: refetchSignedUrl,
      called: signedUrlCalled,
    },
  ] = useLazyQuery<SiteLetterImageUploadUrl>(GET_SITE_LETTER_IMAGE_UPLOAD_URL, {
    client: routedClient,
    context: {
      endpoint: LandTechEndpoints.Sites,
    },
    fetchPolicy: "network-only",
  });

  const { uploadImage, error: uploadError, isDone: isFileUploaded } = useUploadImageToS3();

  const [getAntivirusStatus, { data: antivirusStatusData, stopPolling, startPolling }] =
    useLazyQuery<SiteLetterImageAntivirusScanStatusResponse>(SITE_LETTER_IMAGE_SCAN_STATUS, {
      variables: { s3ObjectKey },
      context: {
        endpoint: LandTechEndpoints.Sites,
      },
      client: routedClient,
    });

  const [getDownloadUrl, { data: downloadUrlData, error: downloadUrlError }] =
    useLazyQuery<SiteLetterImageDownloadUrlResponse>(SITE_LETTER_IMAGE_DOWNLOAD_URL, {
      variables: { s3ObjectKey },
      context: { endpoint: LandTechEndpoints.Sites },
      client: routedClient,
    });

  useEffect(() => {
    switch (uploadStatus) {
      case UploadStatus.GettingSignedUrl:
        if (signedUrlError) {
          setUploadStatus(UploadStatus.Error);
        } else if (signedUrlData) {
          const {
            getSiteLetterImageUploadUrl: { s3ObjectKey, signedUploadUrl },
          } = signedUrlData;
          setS3ObjectKey(s3ObjectKey);
          setSignedUploadUrl(signedUploadUrl);
          setUploadStatus(UploadStatus.Uploading);
        }
        break;
      case UploadStatus.Uploading:
        if (uploadError) {
          setUploadStatus(UploadStatus.Error);
        } else if (isFileUploaded) {
          setUploadStatus(UploadStatus.Scanning);
        }
        break;
      case UploadStatus.Scanning:
        if (downloadUrlError) {
          setUploadStatus(UploadStatus.Error);
          return;
        }
        if (antivirusStatusData && downloadUrlData) {
          const {
            siteLetterImage: { antivirusScanStatus },
          } = antivirusStatusData;
          const {
            siteLetterImage: { signedDownloadUrl },
          } = downloadUrlData;
          if (antivirusScanStatus === AntivirusScanStatuses.IN_PROGRESS) {
            return;
          }
          stopPolling();
          onUploadStateChange(false);
          switch (antivirusScanStatus) {
            case AntivirusScanStatuses.ERROR:
              setUploadStatus(UploadStatus.Error);
              break;
            case AntivirusScanStatuses.INFECTED:
              setUploadStatus(UploadStatus.Infected);
              break;
            case AntivirusScanStatuses.CLEAN:
              setUploadStatus(UploadStatus.Clean);
              switch (field.name) {
                case "logo":
                  logLetterEvent({
                    name: LetterEventName.LETTERS_FORM_CREATE_LETTER_STEP_COMPANY_LOGO_UPLOADED,
                  });
                  break;
                case "signature":
                  logLetterEvent({
                    name: LetterEventName.LETTERS_FORM_CREATE_LETTER_STEP_SIGNATURE_UPLOADED,
                  });
              }
              field.onChange({
                file: file as File,
                url: signedDownloadUrl,
                s3ObjectKey,
              });
              break;
          }
        }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    signedUrlData,
    signedUrlError,
    uploadError,
    isFileUploaded,
    antivirusStatusData,
    downloadUrlData,
    downloadUrlError,
  ]);

  useEffect(() => {
    switch (uploadStatus) {
      case UploadStatus.GettingSignedUrl:
        signedUrlCalled ? refetchSignedUrl() : getSignedUrl();
        break;
      case UploadStatus.Uploading:
        if (file && signedUploadUrl) {
          uploadImage(file, signedUploadUrl);
        }
        break;
      case UploadStatus.Scanning:
        getAntivirusStatus();
        startPolling(1500);
        getDownloadUrl();
        break;
      case UploadStatus.Error:
      case UploadStatus.Infected:
        setTimeout(() => {
          dropzoneRef.current?.clear();
          setUploadStatus(UploadStatus.Initialised);
        }, 1500);
        break;
      case UploadStatus.Clean:
        setTimeout(() => {
          setUploadStatus(UploadStatus.Initialised);
        }, 1500);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uploadStatus, signedUploadUrl, file]);

  const onDrop = useCallback(
    (file?: File) => {
      onUploadStateChange(true);
      setUploadStatus(UploadStatus.GettingSignedUrl);
      setFile(file);
    },
    [onUploadStateChange, setUploadStatus, setFile]
  );

  return (
    <div className="atlas-relative" data-testid={dataTestId}>
      <ImageUpload onUpload={onDrop} file={field.value?.file} />
      <ImageUploadStatus uploadStatus={uploadStatus} />
    </div>
  );
};
