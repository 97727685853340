import { navigationStore } from "../store";

export const setShowTopupModal = (v: boolean) => {
  navigationStore.showTopupModal = v;
};

export const setShowUpgradeModal = (v: boolean) => {
  navigationStore.showUpgradeModal = v;
};

export const setShowTrialEndingModal = (v: boolean) => {
  navigationStore.showTrialEndingModal = v;
};

export const setShowOverdueInvoiceModal = (v: boolean) => {
  navigationStore.showOverdueInvoiceModal = v;
};

export const setShowMultiDeviceBlockModal = (v: boolean) => {
  navigationStore.showMultiDeviceBlockModal = v;
};

export const setShowImpersonationBanner = (v: boolean) => {
  navigationStore.showImpersonationBanner = v;
};

export const setShowOverdueInvoiceBanner = (v: boolean) => {
  navigationStore.showOverdueInvoiceBanner = v;
};
