import { EditableSiteDetails } from "react-migration/domains/sites/components/SiteCard/EditableSiteDetails";
import type { LayerTypeSelectionDetailViewProps } from "../../types";

export function SiteSelectionDetailView({
  selection,
  clearSelection,
}: LayerTypeSelectionDetailViewProps) {
  if (!selection.id) return null;

  return <EditableSiteDetails key={selection.id} siteId={selection.id} onDelete={clearSelection} />;
}
