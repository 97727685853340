import { GenericLayer } from "../../types";
import { PlanningFilters } from "./Context/hooks";

export type PlanningApplicationsLayer = GenericLayer<PlanningLayerConfig>;
export type PlanningLayerConfig = {
  localStorageKey: string;
  initialFilterState?: Partial<PlanningFilters>;
};

export const PLANNING_APPS_LAYER_TYPE_ID = "PLANNING_APPLICATIONS";
