import { ApolloError, FetchResult, useMutation } from "@apollo/client";

import UPDATE_LABEL from "../graphql/updateLabel.gql";
import { routedClient } from "react-migration/lib/persistence/apollo/routedClient";

export interface UpdateLabelResponse {
  updateLabel: {
    _id: string;
    name: string;
  };
}

export interface UpdateLabelVariables {
  _id: string;
  labelUpdate: {
    name: string;
  };
}

export const useUpdateLabel = () => {
  const [updateLabel, { loading, error }] = useMutation<UpdateLabelResponse>(UPDATE_LABEL, {
    client: routedClient,
  });

  const updateLabelMutation = async (
    variables: UpdateLabelVariables
  ): Promise<FetchResult<UpdateLabelResponse>> => {
    try {
      console.log(variables);
      const result = await updateLabel({
        variables,
        refetchQueries: ["allSiteLabels"],
      });
      // Handle the result if needed
      return result;
    } catch (error) {
      // Handle the error if needed
      console.log(`Error removing label: ${error}`);
      throw error as ApolloError;
    }
  };

  return { updateLabelMutation, loading, error };
};
