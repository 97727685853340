import clsx from "clsx";
import { useMemo } from "react";
import { ScaleLinear } from "d3-scale";
import { Color } from "deck.gl";

interface ScaleLinearLegendProps {
  scaleLinear: ScaleLinear<Color, Color, Color>;
  formatter?: (val: number) => string | undefined;
  tickOverrides?: string[];
  className?: string;
}

const RESOLUTION = 50;

export function ScaleLinearLegend({
  scaleLinear,
  formatter,
  tickOverrides,
  className = "",
}: ScaleLinearLegendProps) {
  const { gradient, ticks: defaultTicks } = useMemo(() => {
    const domain = scaleLinear.domain();

    const lowest = domain[0];
    const highest = domain[domain.length - 1];

    const slope = (highest - lowest) / (RESOLUTION - 1);

    const gradient = [...new Array(RESOLUTION)]
      .map((_, i) => slope * i + lowest)
      .map((v, i) => {
        const [r, g, b] = scaleLinear(v);

        return `rgb(${r}, ${g}, ${b}) ${i * (100 / (RESOLUTION - 1))}%`;
      })
      .join(", ");

    return {
      gradient,
      ticks: [domain[0], (domain[0] + domain[domain.length - 1]) / 2, domain[domain.length - 1]],
    } as const;
  }, [scaleLinear]);

  const ticks = tickOverrides || defaultTicks.map((tick) => (formatter ? formatter(tick) : tick));

  return (
    <div className={clsx("atlas-flex atlas-flex-col atlas-gap-2", className)}>
      <div
        className="atlas-h-3 atlas-w-full"
        style={{
          background: `linear-gradient(90deg, ${gradient})`,
        }}
      />
      <ul className="atlas-relative atlas-flex atlas-justify-between atlas-list-none atlas-text-xs atlas-border-l atlas-border-r atlas-border-neutral-300 atlas-px-2">
        {ticks.map((tick) => (
          <li key={tick}>{tick}</li>
        ))}
      </ul>
    </div>
  );
}
