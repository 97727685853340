import { forwardRef, HTMLAttributes, PropsWithChildren, ReactNode } from "react";
import cn from "clsx";
import { twMerge } from "tailwind-merge";
import { ClipboardCopyButton } from "@landtechnologies/components";

interface GridProps extends PropsWithChildren {
  columns?: number;
}

function Grid({ children, columns = 2 }: GridProps) {
  return (
    <div
      className={cn("atlas-grid atlas-gap-2", {
        "atlas-grid-cols-1": columns === 1,
        "atlas-grid-cols-2": columns === 2,
        "atlas-grid-cols-3": columns === 3,
      })}
    >
      {children}
    </div>
  );
}

interface CellProps extends PropsWithChildren {
  variant?: "default" | "ghost";
  title?: string;
  titleTail?: ReactNode;
  copyString?: string;
}

function Cell({ variant = "default", title, titleTail, children, copyString }: CellProps) {
  return (
    <div
      className={cn("atlas-relative atlas-group", {
        "atlas-px-2 atlas-py-1 atlas-overflow-hidden atlas-rounded atlas-bg-blueGrey-50  atlas-border atlas-border-blueGrey-200":
          variant === "default",
      })}
    >
      {title && (
        <div className="atlas-flex atlas-gap-1 atlas-items-center atlas-text-left">
          <div className="atlas-text-blueGrey-950/50 atlas-text-xs">{title}</div>
          {titleTail}
        </div>
      )}
      <div className={cn("atlas-text-left atlas-w-full", { "atlas-pr-10": !!copyString })}>
        {children}
      </div>
      {copyString && (
        <div className="atlas-hidden group-hover:atlas-block atlas-absolute atlas-right-2 atlas-top-1/2 -atlas-translate-y-1/2">
          <ClipboardCopyButton text={copyString} roundedLeft roundedRight />
        </div>
      )}
    </div>
  );
}

function Badge({ children }: PropsWithChildren) {
  return (
    <div className="atlas-bg-blueGrey-200 atlas-text-blueGrey-950/50 atlas-text-xs atlas-rounded-sm atlas-px-1">
      {children}
    </div>
  );
}

const Button = forwardRef<HTMLButtonElement, HTMLAttributes<HTMLButtonElement>>(
  ({ className, ...props }, ref) => (
    <button
      ref={ref}
      className={twMerge(
        className,
        "atlas-w-full atlas-text-left atlas-cursor-pointer atlas-text-platformBlue-600 hover:atlas-text-platformBlue-700 active:atlas-text-platformBlue-800"
      )}
      {...props}
    />
  )
);

Button.displayName = "DataPanel.Button";

export const DataPanel = { Grid, Cell, Button, Tails: { Badge } };
