import { PlanningApplicationReference } from "../../types";
import { useMemo } from "react";
import { SelectionFeature, SelectionType } from "src/js/stores/map/store";

type TitlePart = Text | Anchor | Break;

type Text = {
  content: string;
  type: "text";
};

type Break = {
  content: string;
  type: "break";
};

type Anchor = {
  content: string;
  planningId: string;
  type: "anchor";
};

type TitleWithLinksProps = {
  title: string;
  references: PlanningApplicationReference[];
  setDetailSelection?: (selection: SelectionFeature) => void;
};

export const TitleWithLinks = ({ title, references, setDetailSelection }: TitleWithLinksProps) => {
  const parts = useMemo(() => {
    const sortedReferences = sortReferences(references);
    return splitTitleIntoParts(title, sortedReferences, []);
  }, [references, title]);
  return (
    <>
      {parts.map((part, index) => {
        return part.type == "anchor" ? (
          <a
            key={index}
            className="atlas-cursor-pointer"
            onClick={() =>
              setDetailSelection &&
              setDetailSelection({
                type: SelectionType.PLANNING_APPLICATION,
                id: part.planningId,
              })
            }
          >
            {part.content}
          </a>
        ) : (
          part.content && <span key={index}> {part.content} </span>
        );
      })}
    </>
  );
};

export const splitTitleIntoParts = (
  title: string,
  references: PlanningApplicationReference[],
  parts: TitlePart[] = [],
  index = 0
): TitlePart[] => {
  if (references.length === 0) {
    return [...parts, { content: title.slice(index), type: "text" }];
  }

  const [currentReference, ...rest] = references;
  const textBeforeRef = title.slice(index, currentReference.indexInTitle);

  const updatedArray = [
    ...parts,
    { content: textBeforeRef, type: "text" } as Text,
    {
      content: currentReference.originalRef,
      planningId: currentReference.planningId,
      type: "anchor",
    } as Anchor,
  ];

  const newIndex = currentReference.indexInTitle + currentReference.originalRef.length;

  return splitTitleIntoParts(title, rest, updatedArray, newIndex);
};

const sortReferences = (references: PlanningApplicationReference[]) => {
  // Define a custom comparison function to sort based on the indexInTitle property
  const compareReferences = (a: PlanningApplicationReference, b: PlanningApplicationReference) => {
    return a.indexInTitle - b.indexInTitle;
  };

  // Use the sort method with the custom comparison function
  const sortedReferences = references.slice(); // Create a copy of the original array to avoid mutation
  sortedReferences.sort(compareReferences);

  return sortedReferences;
};

export default TitleWithLinks;
