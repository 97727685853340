import React from "react";

export const LockIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="14" fill="none" viewBox="0 0 12 15">
      <path
        fill="currentColor"
        d="M2 5.333v-.667a4 4 0 118 0v.667h1.333a.667.667 0 01.667.666v8a.667.667 0 01-.667.667H.667A.667.667 0 010 13.999V6a.667.667 0 01.667-.666H2zm8.667 1.333H1.333v6.667h9.334V6.666zm-5.334 3.821a1.333 1.333 0 111.334 0V12H5.333v-1.512zm-2-5.154h5.334v-.667a2.667 2.667 0 10-5.334 0v.667z"
      ></path>
    </svg>
  );
};
