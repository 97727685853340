import Feature from "src/js/stores/user/Feature";
import { ConstraintsCategory } from "../../Constraints/types";
import { ConstraintsLayerType } from "../layer_types/ConstraintsLayerType";
import { Layer } from "../types";

export const IRREPLACEABLE_HABITATS: Layer = {
  id: "irreplacable_habitats",
  layerType: ConstraintsLayerType,
  title: "Irreplaceable Habitats",
  requiredFeatureAccess: [Feature.planningPolicyLayer],
  toggleable: true,
  description:
    "Recorded habitats that are classified as irreplaceable under Biodiversity Net Gain guidance",
  hideByDefault: true,
  layerConfig: {
    rootCategory: ConstraintsCategory.HABITATS,
    categorySchema: [
      { key: "ancient_tree_inventory" },
      { key: "spartina_saltmarsh_swards" },
      { key: "mediterranean_saltmarsh_scrub" },
      { key: "blanket_bog" },
      { key: "lowland_fens" },
      { key: "limestone_pavements" },
      { key: "coastal_sand_dunes" },
      { key: "ancient_woodland" },
    ],
  },
};
