import { TreeStructure } from "react-migration/components/CheckboxTree";
import { Category } from "react-migration/domains/constraints/typings/baseTypes/Category";
import { useTranslation } from "react-migration/lib/i18n/useTranslation";
import { isDefined } from "react-migration/lib/util/isDefined";
import { CheckboxSchema } from "react-migration/layouts/map/Multilayer/layer_types/ConstraintsLayerType";

/** This function converts a Category structure into a TreeStructure ready for
 * pruning. */
function categoryToTreeRecursive(category: Category): TreeStructure {
  const { categories, ...c } = category;

  return {
    ...c,
    ...(categories?.length
      ? { childNodes: categories.map(categoryToTreeRecursive) }
      : { childNodes: [] }),
  };
}

export function pruneTree(
  unprunedTree: Category,
  categorySchema: CheckboxSchema[] = [],
  { t, checkKey }: ReturnType<typeof useTranslation>
) {
  const categoryIds = categorySchema.map(({ key }) => key);

  function recursivePruneTree(tree: TreeStructure): TreeStructure | null {
    const response: TreeStructure = {
      ...tree,
      display_name: checkKey(`constraints.categories.${tree.id}`)
        ? t(`constraints.categories.${tree.id}`)
        : tree.display_name,
    };

    if (categoryIds.includes(tree.id)) {
      return response;
    }

    const filteredNodes = (tree.childNodes ?? []).map(recursivePruneTree).filter(isDefined);

    if (filteredNodes.length) return { ...response, childNodes: filteredNodes };

    return null;
  }

  return recursivePruneTree(categoryToTreeRecursive(unprunedTree));
}

export function getCategoryIdRecursive(tree: TreeStructure): string[] {
  return [
    tree.id,
    ...(tree.childNodes || []).flatMap((treeNode) => getCategoryIdRecursive(treeNode)),
  ];
}

export function getDefaultVisibleCategoriesRecursive(
  tree: TreeStructure,
  checkboxSchema: CheckboxSchema[]
): string[] {
  // Positive default value for tree node
  if (
    checkboxSchema.find((checkbox) => {
      // If there's no default value, it should resolve to true
      const defaultTrue = checkbox.defaultValue ?? true;

      return defaultTrue && checkbox.key === tree.id;
    })
  ) {
    return [tree.id, ...(tree.childNodes || []).flatMap(getCategoryIdRecursive)];
  }

  return tree.childNodes
    ? tree.childNodes.flatMap((treeNode) =>
        getDefaultVisibleCategoriesRecursive(treeNode, checkboxSchema)
      )
    : [];
}
