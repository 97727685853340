import { useCallback } from "react";
import { useLocalStorage } from "react-migration/lib/persistence/state/useLocalStorage";

export function usePersistentToggleState(
  persistenceKey: string,
  initialState: string[] = []
): [(id: string) => boolean, (id: string, value: boolean) => void] {
  const [toggledItems, setToggledItems] = useLocalStorage<string[]>(persistenceKey, initialState);

  const setState = useCallback(
    (id: string, value: boolean) => {
      setToggledItems((currentState) => [
        ...currentState.filter((i) => i !== id),
        ...(value ? [id] : []),
      ]);
    },
    [setToggledItems]
  );

  const getState = useCallback(
    (id: string) => {
      return toggledItems.includes(id);
    },
    [toggledItems]
  );
  return [getState, setState];
}
