// WARNING - these types are just a guess from the JS code that getDefaultSearch was ported from
export interface SearchGroupDetails {
  id: string;
  name: string;
  isAggregate: boolean;
  orSet: null | Group[] | string;
}
export interface Group {
  id?: string;
  value?: unknown;
  field?: unknown;
  matchType?: string;
  search_group_details?: SearchGroupDetails;
  and?: Group[];
  or?: Group[];
}

export interface Search {
  composite_terms: Group;
  parcel_size_range: {
    from: number | null;
    to: number | null;
  };
  proprietorship_added_date_range: {
    from: string | null;
    to: string | null;
  };
  lease_start_date_range: {
    from: string | null;
    to: string | null;
  };
  lease_end_date_range: {
    from: string | null;
    to: string | null;
  };
  geo_filter: unknown | null;
  search_in_subsidiaries: boolean;
}

export function getDefaultSearch(): Search {
  return {
    composite_terms: {
      and: [],
    },
    parcel_size_range: {
      from: null,
      to: null,
    },
    proprietorship_added_date_range: {
      from: null,
      to: null,
    },
    lease_start_date_range: {
      from: null,
      to: null,
    },
    lease_end_date_range: {
      from: null,
      to: null,
    },
    geo_filter: null,
    search_in_subsidiaries: true,
  };
}
