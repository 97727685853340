import { ISourcingToolSearchFilter } from "react-migration/domains/search/typings/SourcingToolSpecificFilterTypes";
import { SearchType } from "react-migration/domains/search/constants/searchTypes";

const startYear = new Date(`1900-01-01T00:00:00.000Z`);
const endOfYear = new Date(`${new Date().getFullYear()}-12-31T23:59:59.999Z`);

export const YearBuiltFilter: ISourcingToolSearchFilter = {
  id: "year-built",
  type: "date-range",
  field: "sidebar.react.search_sidebar.year_built",
  range: [null, null],
  granularity: "year",
  rangeLimit: [startYear, endOfYear],
  updateFilterDialogIsVisible: false,
  searchTypes: [SearchType.SOURCING_TOOL_US_FL],
};
