import { HTMLProps } from "react";

import { ArrowRight } from "../../../Icons";

const CardWrapper = ({ children, ...rest }: HTMLProps<HTMLDivElement>) => {
  return (
    <div
      className="atlas-flex atlas-flex-col atlas-gap-1 atlas-bg-white atlas-rounded-lg atlas-border-2 atlas-border-neutral-3 atlas-p-2"
      {...rest}
    >
      {children}
    </div>
  );
};

const CardTitle = ({ children, ...rest }: HTMLProps<HTMLDivElement>) => {
  return (
    <div
      className="atlas-flex atlas-flex-row atlas-items-center atlas-mb-1 atlas-text-platform-700 atlas-text-sm atlas-gap-1 atlas-cursor-pointer"
      {...rest}
    >
      {children} <ArrowRight />
    </div>
  );
};

const CardColumns = ({ children, ...rest }: HTMLProps<HTMLDivElement>) => {
  return (
    <div className="atlas-flex atlas-flex-row atlas-gap-2" {...rest}>
      {children}
    </div>
  );
};

const CardColumn = ({ children, ...rest }: HTMLProps<HTMLDivElement>) => {
  return (
    <div className="atlas-flex atlas-flex-col atlas-grow" {...rest}>
      {children}
    </div>
  );
};

const CardFooter = ({ children, ...rest }: HTMLProps<HTMLDivElement>) => {
  return (
    <div className="atlas-flex atlas-flex-row atlas-justify-between atlas-mt-2" {...rest}>
      {children}
    </div>
  );
};

const CardStatHeader = ({ children, ...rest }: HTMLProps<HTMLDivElement>) => {
  return (
    <div className="atlas-text-xs" {...rest}>
      {children}
    </div>
  );
};

const CardStat = ({ children, ...rest }: HTMLProps<HTMLDivElement>) => {
  return (
    <div className="atlas-text-xl atlas-font-bold" {...rest}>
      {children}
    </div>
  );
};

export const Card = {
  Wrapper: CardWrapper,
  Title: CardTitle,
  Columns: CardColumns,
  Column: CardColumn,
  Footer: CardFooter,
  StatHeader: CardStatHeader,
  Stat: CardStat,
};
