import { ScatterplotLayer, ScatterplotLayerProps } from "@deck.gl/layers";
import { MaskExtension } from "@deck.gl/extensions";
import Feature from "src/js/stores/user/Feature";
import { getPosition } from "./comparablesLayerUtil";
import { CorePriceModes } from "react-migration/domains/comparables/typings/PriceModes";
import { ComparableTile } from "@landtechnologies/comparables-services";
import getPermissions from "src/js/stores/user/actions/getPermissions";
import hasBetaFeature from "src/js/stores/user/actions/hasBetaFeature";

export function pricePerUnitLayers(
  priceMode: CorePriceModes,
  _distribution: number[],
  data: ComparableTile[],
  getSubLayerProps: (i: number) => ScatterplotLayerProps<ComparableTile>
) {
  const geofenceGeometries = getPermissions()?.geofencesGeometries;
  const maskExtensions = [] as MaskExtension[];
  let maskId: string | undefined;

  if (geofenceGeometries?.length && !hasBetaFeature(Feature.disableGeofence)) {
    maskExtensions.push(new MaskExtension());
    maskId = "Geofence";
  }
  const visible = /price_per/.test(priceMode) === false;
  return [
    new ScatterplotLayer<ComparableTile>({
      ...getSubLayerProps(-1),
      extensions: maskExtensions,
      // @ts-expect-error maskId is missing
      maskId,
      pickable: true,
      data,
      opacity: 0.8,
      stroked: true,
      filled: true,
      visible,
      lineWidthUnits: "pixels",
      lineWidthMaxPixels: 1,
      lineWidthMinPixels: 1,
      lineWidthScale: 1,
      radiusUnits: "pixels",
      radiusScale: 1,
      radiusMinPixels: 1,
      radiusMaxPixels: 12,
      getPosition,
      getRadius: 12,
      getFillColor: [132, 204, 21],
      getLineColor: [255, 255, 255],
      getLineWidth: 1,
      updateTriggers: {
        visible,
      },
    }),
  ];
}
