enum Feature {
  // Application Access
  hivebriteAccess = "hivebriteAccess",
  landFundAccess = "landFundAccess",
  landInsightAccess = "landInsightAccess",

  // Alerts
  ownershipChangeAlerts = "ownershipChangeAlerts", // UK - unlimited
  planningAlerts = "planningAlerts", // UK - unlimited

  // General
  sitesRoute = "sitesRoute", // Generic - free
  mapRoute = "mapRoute", // Generic - free
  dashboardRoute = "dashboardRoute", // Generic - free
  upgradeButton = "upgradeButton", // Generic - free
  ukLocationSearch = "ukLocationSearch", // UK - free
  usAccess = "usAccess", // US - free
  usLocationSearch = "usLocationSearch", // US - free

  // Map
  drawings = "drawings", // Generic - starter
  showLayers = "showLayers", // Generic - starter
  showSubNavigationBar = "showSubNavigationBar", // Generic - starter
  comparablesCalculator = "comparablesCalculator", // Generic - unlimited
  unlimitedOwnershipClicks = "unlimitedOwnershipClicks", // Generic - unlimited
  unlimitedPlanningClicks = "unlimitedPlanningClicks", // UK - pro
  planningFilters = "planningFilters", // UK - pro
  ultimateOwner = "ultimateOwner", // UK - unlimited
  zooplaLightAccess = "zooplaLightAccess", // UK - unlimited

  // Assessment
  detailsTab = "detailsTab", // Generic - free
  planningHistoryTab = "planningHistoryTab", // UK - starter
  policiesTab = "policiesTab", // UK - starter
  environmentalTab = "environmentalTab", // UK - starter
  compsTab = "compsTab", // UK - starter
  powerTab = "powerTab", // UK - starter
  permitsTabUS = "permitsTabUS", // US - starter
  policiesTabUS = "policiesTabUS", // US - starter
  environmentalTabUS = "environmentalTabUS", // US - starter
  compsTabUS = "compsTabUS", // US - starter
  utilitiesTabUS = "utilitiesTabUS", // US - starter

  // Map layers
  sitesLayer = "sitesLayer", // Generic - starter
  ownershipLayer = "ownershipLayer", // Generic - starter
  pricepaidLayer = "pricepaidLayer", // Generic - starter
  comparablesPpSqftMode = "comparablesPpSqftMode", // Generic - pro
  ownershipBundle = "ownershipBundle", // UK - starter
  planningLayer = "planningLayer", // UK - starter
  propertyInformationLayer = "propertyInformationLayer", // UK - starter
  amenitiesBundle = "amenitiesBundle", // UK - unlimited
  planningPolicyLayer = "planningPolicyLayer", // UK - unlimited
  powerLayer = "powerLayer", // UK - unlimited
  amenitiesLayerUS = "amenitiesLayerUS", // US - starter
  buildingPermits = "buildingPermits", // US - starter
  demographics = "demographics", // US - starter
  flumLayer = "flumLayer", // US - starter
  geologyLayer = "geologyLayer", // US - starter
  hazardsLayer = "hazardsLayer", // US - starter
  isochroneLayer = "isochroneLayer", // US - starter
  usSitePlanApplicationsLayer = "usSitePlanApplicationsLayer", // US - starter
  utilitiesLayerUS = "utilitiesLayerUS", // US - starter
  zoningLayer = "zoningLayer", // US - starter
  listedBuildingsStandaloneLayer = "listedBuildingsStandaloneLayer", // UK - starter/pro, but NOT unlimited

  // Search
  multiFilterSearch = "multiFilterSearch", // Generic - unlimited
  ownershipSearch = "ownershipSearch", // Generic - unlimited
  sourcingToolCSVExport = "sourcingToolCSVExport", // Generic - unlimited
  planningSearch = "planningSearch", // UK - unlimited
  propertySearch = "propertySearch", // UK - unlimited

  // Sites
  siteCardActivity = "siteCardActivity", // Generic - starter
  siteDetailsEditSite = "siteDetailsEditSite", // Generic - starter
  siteReport = "siteReport", // Generic - starter
  bulkLetterSending = "bulkLetterSending", // Generic - unlimited
  letterSending = "letterSending", // Generic - unlimited
  ownershipCSVExport = "ownershipCSVExport", // Generic - unlimited
  documentPurchase = "documentPurchase", // UK - starter
  premiumSiteCard = "premiumSiteCard", // UK - starter
  physicalSend = "physicalSend", // Generic - unlimited
  siteUtilitiesReports = "siteUtilitiesReports", // UK - unlimited
  ultimateSiteCard = "ultimateSiteCard", // UK - unlimited
  usComparablesAccess = "usComparablesAccess", // US - unlimited
  usOwnershipAccess = "usOwnershipAccess", // US - unlimited
  skipTracing = "skipTracing", // US - starter

  // Addons
  zooplaCompsCalculatorExport = "zooplaCompsCalculatorExport", // UK - LI-zoopla-addon
  zooplaFullAccess = "zooplaFullAccess", // UK - LI-zoopla-addon
  constraintsAdminLayer = "constraintsAdminLayer", // UK - Constraints-admin

  // Beta
  aiSummaryBeta = "aiSummaryBeta",
  betaMapUILayer = "betaMapUILayer",
  commercialSearch = "commercialSearch",
  compsCalcDownloadFilter = "compsCalcDownloadFilter",
  contactsBeta = "contactsBeta",
  disableDatadogRum = "disableDatadogRum",
  disableGeofence = "disableGeofence",
  disableUseDevicePixels = "disableUseDevicePixels",
  integrationsDashboardBeta = "integrationsDashboardBeta",
  mobileFullAccess = "mobileFullAccess",
  OwnerPortfolioDev = "OwnerPortfolioDev",
  protectedAreasAiSummaryBeta = "protectedAreasAiSummaryBeta",
  rezoningApplications = "rezoningApplications",
  TURTLES = "TURTLES",
  XDOG = "XDOG",
  siteReportV2 = "siteReportV2",
}

export default Feature;
