import { PropsWithChildren } from "react";
import { ProgressBar } from "@landtechnologies/components";

export interface FooterProps extends PropsWithChildren {
  percentage?: number;
  dataTestId?: string;
}

export const Footer = ({ percentage = 0, dataTestId, children }: FooterProps) => {
  return (
    <div data-testid={dataTestId ?? "letters-footer"} className="atlas-sticky atlas-top-[100vh]">
      <ProgressBar percentage={percentage} />
      <div className="atlas-w-full atlas-bg-background-white atlas-px-6 atlas-flex atlas-flex-row atlas-my-2.5">
        {children}
      </div>
    </div>
  );
};
