import { FC, useEffect } from "react";
import { Dropdown, DropdownItem } from "@landtechnologies/components";

import { useTranslation } from "react-migration/lib/i18n/useTranslation";
import {
  PerAreaPriceModes,
  PerUnitPriceModes,
  PriceModes,
  ZooplaPricePerUnitModes,
  ZooplaPricePerAreaModes,
} from "../../../typings/PriceModes";
import { PriceTypes } from "../../../typings/PriceTypes";

export interface PriceModeProps {
  priceMode: PriceModes;
  priceType: PriceTypes;
  setPriceMode: (priceMode: PriceModes) => void;
  zooplaLicense?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  eventTracking?: (values: { filterName: string; filterState: any }) => void;
}

export const PriceMode: FC<PriceModeProps> = ({
  priceMode,
  priceType,
  setPriceMode,
  zooplaLicense,
  eventTracking,
}) => {
  const { t } = useTranslation();

  const PRICE_TEXT = t("comparables.map.sidebar.filters.mode.price.price");
  const EST_PRICE_TEXT = t("comparables.map.sidebar.filters.mode.price.est_price");
  const ZOOPLA_RENTAL_ESTIMATE_TEXT = t(
    "comparables.map.sidebar.filters.mode.price.zoopla_rental_estimate"
  );
  const ZOOPLA_VALUATION_TEXT = t("comparables.map.sidebar.filters.mode.price.zoopla_valuation");

  useEffect(() => {
    const otherPriceType =
      priceType === PriceTypes.PER_AREA ? PriceTypes.PER_UNIT : PriceTypes.PER_AREA;
    const defaultTypes = {
      [PriceTypes.PER_AREA]: PerAreaPriceModes.PPSM,
      [PriceTypes.PER_UNIT]: PerUnitPriceModes.PRICE,
    };
    const availableTypes: Array<Record<PriceTypes, PriceModes>> = [
      {
        [PriceTypes.PER_AREA]: PerAreaPriceModes.PPSM,
        [PriceTypes.PER_UNIT]: PerUnitPriceModes.PRICE,
      },
      {
        [PriceTypes.PER_AREA]: PerAreaPriceModes.EPPSM,
        [PriceTypes.PER_UNIT]: PerUnitPriceModes.EP,
      },
      {
        [PriceTypes.PER_AREA]: ZooplaPricePerAreaModes.RENT_ESTIMATE,
        [PriceTypes.PER_UNIT]: ZooplaPricePerUnitModes.RENT_ESTIMATE,
      },
      {
        [PriceTypes.PER_AREA]: ZooplaPricePerAreaModes.VAL_ESTIMATE,
        [PriceTypes.PER_UNIT]: ZooplaPricePerUnitModes.VAL_ESTIMATE,
      },
    ];
    if (!availableTypes.some((type) => type[priceType] === priceMode)) {
      setPriceMode(
        availableTypes.find((type) => type[otherPriceType] === priceMode)?.[priceType] ||
          defaultTypes[priceType]
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [priceType]);
  const dropdownOptions: DropdownItem<PriceModes>[] = [
    {
      label: PRICE_TEXT,
      value: priceType === PriceTypes.PER_UNIT ? PerUnitPriceModes.PRICE : PerAreaPriceModes.PPSM,
    },
    {
      label: EST_PRICE_TEXT,
      value: priceType === PriceTypes.PER_UNIT ? PerUnitPriceModes.EP : PerAreaPriceModes.EPPSM,
    },
  ];

  const dropdownZooplaOptions: DropdownItem<PriceModes>[] = [
    {
      label: ZOOPLA_RENTAL_ESTIMATE_TEXT,
      value:
        priceType === PriceTypes.PER_UNIT
          ? ZooplaPricePerUnitModes.RENT_ESTIMATE
          : ZooplaPricePerAreaModes.RENT_ESTIMATE,
    },
    {
      label: ZOOPLA_VALUATION_TEXT,
      value:
        priceType === PriceTypes.PER_UNIT
          ? ZooplaPricePerUnitModes.VAL_ESTIMATE
          : ZooplaPricePerAreaModes.VAL_ESTIMATE,
    },
  ];

  return (
    <div data-testid="price-mode" className="atlas-flex atlas-justify-between">
      <h4 className="atlas-text-sm atlas-font-medium atlas-my-auto atlas-flex-1 atlas-text-left">
        {t("comparables.map.sidebar.filters.mode.price.title")}
      </h4>
      <Dropdown<PriceModes>
        value={priceMode}
        placeholder=""
        dataTestId="price-mode-dropdown"
        name="priceMode"
        classNameContainer="atlas-min-w-[126px]"
        handleValueChange={(opt) => {
          eventTracking?.({ filterName: "priceMode", filterState: opt });
          setPriceMode(opt.value);
        }}
        options={zooplaLicense ? [...dropdownOptions, ...dropdownZooplaOptions] : dropdownOptions}
      />
    </div>
  );
};
