import hasFeature from "src/js/stores/user/actions/hasFeature";
import Feature from "src/js/stores/user/Feature";
import { useTranslation } from "react-migration/lib/i18n/useTranslation";

export function ComparablesUKDataAttribution() {
  const { t } = useTranslation();

  if (hasFeature(Feature.usAccess)) return null;

  return (
    <div className="atlas-py-1 atlas-px-3 atlas-text-xs md:atlas-text-sm atlas-text-neutral-400">
      <span>{t("comparables.map.sidebar.value.record.source")}</span>
      <span className="atlas-font-semibold atlas-text-neutral-500">
        {t("comparables.map.sidebar.value.record.sources")}
      </span>
    </div>
  );
}
