import { useSnapshot } from "valtio";

import { letterStore } from "src/js/stores/letter/store";
import { Recipient } from "../../typings/Recipient";

export function useActiveSiteBoundaryImageUrl() {
  const { recipients, activeRecipientId, siteBoundaryImageUrlBySiteId } = useSnapshot(letterStore);

  const activeRecipient =
    recipients?.find((recipient) => recipient._id === activeRecipientId) ?? ({} as Recipient);

  return { siteBoundaryImageUrl: siteBoundaryImageUrlBySiteId[activeRecipient?.siteId] };
}
