export type MultiTextSearchFilterId = "zone-codes-id" | "planning-applications-multi-text-filter-id";

export enum MultiTextFilterMust {
  MATCH_ALL = "MATCH_ALL",
  MATCH_NONE = "MATCH_NONE",
}

export type MultiTextFilterMustDTO = keyof typeof MultiTextFilterMust;

export interface IMultiTextFilter {
  id: MultiTextSearchFilterId;
  searchTags: string[];
  must: MultiTextFilterMust;
  type: "multi-text-search";
}

export type MultiTextFilterDTO = Omit<IMultiTextFilter, "must"> & {
  must: MultiTextFilterMustDTO;
};
