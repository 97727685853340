import pick from "lodash/pick";

export function isNew(site) {
  return !site._id;
}

export function canConvertToGeoJSON(site) {
  return !isNew(site) && site.geometry;
}

export function toGeoJSON(site) {
  return {
    type: "Feature",
    id: site._id,
    geometry: site.geometry,
    properties: pick(site, ["title", "notes"]),
  };
}

export function siteShareLink(site) {
  return `${document.baseURI}${siteShareRelativeLink(site)}`;
}

export function siteShareRelativeLink(site) {
  return `public/map/?siteId=${site._id}`;
}

export function findTidbitInList(needle, list, needToFilterOnType = true) {
  list = needToFilterOnType ? list.filter((t) => t.__typename === needle.__typename) : list;
  switch (needle.__typename) {
    case "SiteTidbitPlanningRecord":
      return list.find(
        (x) =>
          x.planning_record.gss_code === needle.planning_record.gss_code &&
          x.planning_record.ref === needle.planning_record.ref
      );
    case "SiteTidbitOwnershipRecord":
      return list.find(
        (x) =>
          x.ownership_record.title.title_no === needle.ownership_record.title.title_no &&
          (x.ownership_record.company && x.ownership_record.company.name) ==
            (needle.ownership_record.company && needle.ownership_record.company.name) && // Important: note double equals..to ensure equality of undefined and null
          x.ownership_record.title.tenure == needle.ownership_record.title.tenure
      );
    case "SiteTidbitComparableTransaction":
      return list.find(
        (x) =>
          (x.comparable_transaction || x.pricepaid_record).transaction_id ===
          needle.comparable_transaction.transaction_id
      );
    case "SiteTidbitPricepaidRecord":
      return list.find(
        (x) =>
          (x.pricepaid_record || x.comparable_transaction).transaction_id ===
          needle.pricepaid_record.transaction_id
      );
    case "SiteTidbitAttachment":
      return list.find((x) => x.attachment._id === needle.attachment._id);
    case "SiteTidbitTitleDocument":
      return list.find((x) => x.title_document._id === needle.title_document._id);
    case "SiteTidbitOSMapPrint":
      return list.find((x) => x.os_map_print._id === needle.os_map_print._id);
    case "SiteTidbitComment":
      return list.find((x) => x._id === needle._id);
    default:
      throw new Error(`Could not find tidbit for __typename: ${needle.__typename}!!`);
  }
}

export function defaultTidbit() {
  return {
    is_pinned: false,
    is_hidden: false,
    children: [],
  };
}

export function getAttachmentLink(tidbit) {
  if (tidbit.__typename === "SiteTidbitAttachment") {
    return tidbit.attachment && tidbit.attachment.signed_url && tidbit.attachment.signed_url.url;
  } else if (tidbit.__typename === "SiteTidbitTitleDocument") {
    return tidbit.title_document.url;
  } else if (tidbit.__typename === "SiteTidbitOSMapPrint") {
    return tidbit.os_map_print.url;
  } else {
    throw new Error(`Could not get download link for type: ${tidbit.__typename}`);
  }
}

export function getAttachmentName(tidbit) {
  if (tidbit.__typename === "SiteTidbitAttachment") {
    return tidbit.file_name;
  } else if (tidbit.__typename === "SiteTidbitTitleDocument") {
    const doc = tidbit.title_document;
    if (doc.__typename === "TitlePlanDocument") {
      return `${doc.title_no} Title Plan`;
    } else if (doc.__typename === "TitleRegisterDocument") {
      return `${doc.title_no} Title Register`;
    }
  } else if (tidbit.__typename === "SiteTidbitOSMapPrint") {
    return `1:${tidbit.os_map_print.scale} Map Print`;
  } else {
    throw new Error(`Could not get doc name for type: ${tidbit.__typename}`);
  }
}

export function forceDownload(blob, filename) {
  const a = document.createElement("a");
  a.download = filename;
  a.href = blob;
  // For Firefox https://stackoverflow.com/a/32226068
  document.body.appendChild(a);
  a.click();
  a.remove();
}

export function downloadResource(file) {
  const url = getAttachmentLink(file);
  let filename = getAttachmentName(file);
  if (!filename) filename = url.split("\\").pop().split("/").pop();
  fetch(url, {
    headers: new Headers({
      Origin: location.origin,
    }),
    mode: "cors",
  })
    .then((response) => response.blob())
    .then((blob) => {
      const blobUrl = window.URL.createObjectURL(blob);
      forceDownload(blobUrl, filename);
    })
    .catch((e) => console.error(e));
}
