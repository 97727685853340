import {
  Accordion,
  Checkbox,
  CheckboxState as C,
  DisabledBorderColor,
  DisabledTextContentColor,
  Theme,
  Tooltip,
} from "@landtechnologies/components";

import { useState } from "react";
import { useTranslation } from "react-migration/lib/i18n/useTranslation";
import {
  PropertyTypeOption,
  gatherConditions,
  gatheredConditionsSelectedState,
} from "react-migration/domains/comparables/util/PropertyTypes";
import { emptyArray } from "../../MapLayer/ComparablesLayer/comparablesLayerUtil";
import { DCSCondition } from "react-migration/domains/comparables/typings/Comparables";

const asKey = (item: DCSCondition) =>
  `${item.designation || ""}_${item.category || ""}_${item.subcategory || ""}`;

export interface NestedOptionsProps {
  depth?: number;
  deselect?: (options: DCSCondition[]) => void;
  disabled?: boolean;
  option: PropertyTypeOption;
  openedAccordions: string[];
  selectedOptions: DCSCondition[];
  select?: (options: DCSCondition[]) => void;
  theme?: Theme;
}

export const NestedOptions = ({
  depth = 0,
  deselect,
  disabled,
  option,
  openedAccordions,
  selectedOptions,
  select,
  theme = Theme.Light,
}: NestedOptionsProps) => {
  const { t } = useTranslation();
  const allConditions = gatherConditions(option);
  const selectState = gatheredConditionsSelectedState(allConditions, selectedOptions);
  const [theseOpenedAccordions, setTheseOpenedAccordions] = useState<string[]>([]);
  const level = ["designation", "category", "subcategory"][depth];

  const id = asKey(option);
  const singular = !option.subTypes?.length;
  const label = (
    <label
      className={`atlas-pl-1 atlas-flex-0 atlas-font-semibold${
        disabled ? ` ${DisabledTextContentColor[theme]} ${DisabledBorderColor[theme]}` : ""
      }`}
    >
      {t(option.i18nCode)}
      {option.tooltipCode ? (
        <Tooltip.Wrapper>
          <Tooltip.Content side="right" theme={Theme.Light}>
            <Tooltip.Description>
              <div className={"atlas-ml-2 atlas-font-large atlas-px-1 atlas-w-48"}>
                {t(option.tooltipCode)}
              </div>
            </Tooltip.Description>
          </Tooltip.Content>
          <Tooltip.Trigger>
            <i className="icon-lt-information-line atlas-ml-2" />
          </Tooltip.Trigger>
        </Tooltip.Wrapper>
      ) : null}
    </label>
  );
  return (
    <>
      <Accordion.Item
        value={id}
        className={`atlas-flex atlas-flex-col ${depth > 0 ? "atlas-pl-6" : ""}${
          level === "subcategory" ? " atlas-w-1/2" : ""
        }`}
        key={id}
      >
        <div
          className={`atlas-flex atlas-items-center atlas-flex-1${
            disabled ? "" : " hover:atlas-backdrop-brightness-90"
          } atlas-p-1`}
        >
          <Checkbox
            disabled={disabled}
            id={id}
            value={selectState}
            onChange={() => {
              if (selectState === C.CHECKED) {
                deselect?.(allConditions);
              } else {
                select?.(allConditions);
              }
            }}
            theme={theme}
          />
          {singular ? (
            label
          ) : (
            <div className="atlas-flex-1">
              <Accordion.Trigger
                disabled={disabled}
                className="atlas-flex atlas-justify-end !atlas-bg-transparent !hover:atlas-bg-transparent atlas-items-center"
              >
                <div className="atlas-flex-1 atlas-text-left">{label}</div>
                <div
                  className="atlas-rounded atlas-color-dark atlas-pointer-events-none atlas-flex atlas-items-center"
                  aria-hidden="true"
                >
                  <i
                    className={`icon-lt-arrow-up-s-line atlas-text-lg atlas-duration-150${
                      openedAccordions.some((item) => item === id) ? "" : " atlas-rotate-180"
                    }`}
                  />
                </div>
              </Accordion.Trigger>
            </div>
          )}
        </div>
        {singular ? null : (
          <Accordion.Content>
            <Accordion
              type="multiple"
              disabled={disabled}
              onValueChange={(value) => setTheseOpenedAccordions(value)}
              className={`atlas-flex ${
                level === "subcategory" ? "atlas-flex-row atlas-flex-wrap" : "atlas-flex-col"
              }`}
            >
              {(option.subTypes ?? []).map((option) => {
                return (
                  <NestedOptions
                    disabled={disabled}
                    key={asKey(option)}
                    depth={depth + 1}
                    deselect={deselect}
                    openedAccordions={disabled ? emptyArray : theseOpenedAccordions}
                    option={option}
                    selectedOptions={selectedOptions}
                    select={select}
                    theme={theme}
                  />
                );
              })}
            </Accordion>
          </Accordion.Content>
        )}
      </Accordion.Item>
    </>
  );
};
