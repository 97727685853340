import sortBy from "lodash/sortBy";
import { Title } from "../../typings/Title";
import { Icon } from "@landtechnologies/components";
import { useTranslation } from "react-migration/lib/i18n/useTranslation";

type USUnitListProps = {
  units: Title[];
  action: (title?: Title) => void;
};

export const USUnitList = ({ units, action }: USUnitListProps) => (
  <>
    {sortByAddress(units).map((unit: Title) => (
      <UnitListItem key={unit.id} unit={unit} action={action} />
    ))}
  </>
);

export const sortByAddress = (units: Title[]) => {
  return sortBy(units, (unit) => {
    if (Array.isArray(unit.addresses) && unit.addresses.length > 0) {
      return unit.addresses[0];
    }
    return null;
  });
};

type UnitListItemProps = {
  unit: Title;
  action: (unitInfo?: Title) => void;
};

export function UnitListItem({ unit, action }: UnitListItemProps) {
  const { t } = useTranslation();
  const firstAddress = !!unit.addresses?.length && unit.addresses[0];
  const addressText = firstAddress || t("ownership.react.map.sidebar.unit_info.no_address");

  return (
    <div
      data-testid="unit-list-item"
      onClick={() => action(unit)}
      className="atlas-flex atlas-justify-between atlas-px-2 atlas-py-2 atlas-gap-x-1 hover:atlas-bg-neutral-100"
      role="button"
    >
      <div className="atlas-flex atlas-flex-col atlas-items-start atlas-overflow-hidden">
        <div
          className="atlas-text-sm atlas-font-semibold atlas-text-content-link atlas-truncate atlas-max-w-full"
          title={addressText}
        >
          {addressText}
        </div>
        <div className="atlas-text-content-tertiary atlas-text-xs">{unit.parcel_identifier}</div>
      </div>
      <div className="atlas-text-content-primary">
        <Icon icon="icon-lt-arrow-right-s-line" />
      </div>
    </div>
  );
}
