/* eslint-disable react/display-name */
import { Button, Table } from "@landtechnologies/components";
import { useEffect, useState, forwardRef, ForwardedRef } from "react";
import { SiteOverview } from "react-migration/domains/sites/typings/Site";
import { useTranslation } from "react-migration/lib/i18n/useTranslation";
import { logEvent } from "react-migration/lib/util/logEvent";

import * as _ from "lodash";
import { usePlanningApplicationsByGeometry } from "react-migration/layouts/map/Multilayer/layer_types/PlanningApplicationsLayerType/SelectionSidebar/usePlanningApplicationsByGeometry";
import { PlanningApplication } from "react-migration/domains/planning/types";

type PlanningReportProps = {
  site: SiteOverview;
  onLoaded?: (isLoaded: boolean) => void;
};
export const PlanningReport = forwardRef(
  (
    { site, onLoaded = () => false }: PlanningReportProps,
    planningReportRef: ForwardedRef<HTMLDivElement>
  ) => {
    const { t } = useTranslation();
    const { data: planningApplications, loading } = usePlanningApplicationsByGeometry(
      site.geometry
    );

    useEffect(() => {
      if (!loading) {
        onLoaded(true);
      }
    }, [loading, onLoaded]);

    const [hiddenPlanningApplicationIds, setHiddenPlanningApplicationIds] = useState<string[]>([]);

    const renderCell = (value?: string) => (
      <span className="print:atlas-whitespace-pre-line print:atlas-w-[250px]">{value}</span>
    );

    return (
      <div
        className="atlas-mb-10 print:atlas-mt-4"
        data-testid="planning-report"
        ref={planningReportRef}
      >
        <h2 className="atlas-mb-2">{t("sites.card.site_report.planning")}</h2>
        <div className="atlas-mb-4">
          {t("sites.card.site_report.planning.estimated_maximum_dwellings_planned_for_site")}:{" "}
          {_.max(
            planningApplications?.map((planningApplication) => planningApplication.num_dwellings)
          )}
        </div>
        <div className="atlas-mb-1 atlas-italic atlas-text-neutral-500">
          {t(
            "sites.card.site_report.planning.planning_applications_by_classification_and_distance"
          )}
        </div>
        <div className="atlas-mb-4">
          <TableSummary planningApplications={planningApplications ?? []} />
        </div>
        {(planningApplications?.length ?? 0) > 0 && (
          <>
            <div
              className={`atlas-mb-1 atlas-italic atlas-text-neutral-500 ${
                planningApplications && planningApplications?.length > 4
                  ? "print:atlas-break-before-page"
                  : ""
              }`}
            >
              {t("sites.card.site_report.planning.planning_applications_on_the_site")}
            </div>
            <Table
              size="small"
              primaryKey="ref"
              columns={[
                {
                  label: t("sites.card.site_report.planning.reference"),
                  rowKey: "ref",
                  grow: true,
                },
                {
                  label: t("sites.card.site_report.planning.address"),
                  render: ({ address }) => renderCell(address),
                  grow: true,
                },
                {
                  label: t("sites.card.site_report.planning.description"),
                  render: ({ title }) => renderCell(title),
                  grow: true,
                },
                {
                  label: t("sites.card.site_report.planning.type"),
                  rowKey: "types_derived",
                  grow: true,
                },
                {
                  label: t("sites.card.site_report.planning.decision"),
                  render: (row) =>
                    renderCell(
                      row.decision +
                        (row.appeal_decision ? ` (Appeal: ${row.appeal_decision})` : "")
                    ),
                  grow: true,
                },
                {
                  label: t("sites.card.site_report.planning.classification"),
                  grow: true,
                  render: (row) =>
                    classifications.find(
                      (classification) => row.classification === classification.id
                    )?.display_name,
                },
                {
                  label: t("sites.card.site_report.planning.decision_date"),
                  render: (row) => new Date(row.decision_date as string).toLocaleDateString(),
                  grow: true,
                },
                {
                  label: t("sites.card.site_report.planning.status"),
                  rowKey: "status_derived",
                  grow: true,
                },
                {
                  label: "#",
                  grow: true,
                  render: (row) => (
                    <Button.Danger
                      onClick={() => {
                        logEvent("Site Report - Delete Row - clicked", {
                          row_id: row.ref as string,
                        });
                        setHiddenPlanningApplicationIds([
                          ...hiddenPlanningApplicationIds,
                          row.ref as string,
                        ]);
                      }}
                      className="atlas-pl-2 atlas-pr-2 print:atlas-hidden"
                      data-testid={`delete-planning-application-row-button`}
                    >
                      <i className="icon-lt-delete-bin-line atlas-text-sm atlas-m-0"></i>
                    </Button.Danger>
                  ),
                },
              ]}
              rows={_.orderBy(
                planningApplications.filter(
                  ({ ref }) => !hiddenPlanningApplicationIds.includes(ref)
                ),
                (application) => application.decision_date,
                "desc"
              )}
              tableClassName="atlas-block atlas-overflow-x-scroll print:atlas-table print:atlas-table-fixed print:atlas-overflow-hidden"
            />
          </>
        )}
      </div>
    );
  }
);

export const TableSummary = ({
  planningApplications,
}: {
  planningApplications: PlanningApplication[];
  isImperial?: boolean;
}) => {
  const { t } = useTranslation();
  const groupedPlanningApplications = classifications?.map((classification) => {
    const onSite = planningApplications?.filter(
      (planningApplication) => planningApplication.classification === classification.id
    );

    return {
      classification: classification.display_name,
      onSite: onSite.length,
    };
  });

  return (
    <Table
      size="small"
      primaryKey="classification"
      columns={[
        {
          label: t("sites.card.site_report.planning.classification"),
          render: (row) => toTitleCase(row.classification.replace("_", " ")),
        },
        { label: t("sites.card.site_report.planning.within_site"), rowKey: "onSite" },
        // TODO: include within 1-50m column when hook supports it
      ]}
      rows={groupedPlanningApplications}
    />
  );
};

const classifications = [
  { id: "RESIDENTIAL", display_name: "Residential" },
  { id: "COMMERCIAL", display_name: "Commercial" },
  { id: "MIXED_USE", display_name: "Mixed Use" },
  { id: "OTHER", display_name: "Other" },
  { id: "UNKNOWN", display_name: "Unknown" },
];

export const toTitleCase = (str: string) => {
  return str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substring(1).toLowerCase();
  });
};
