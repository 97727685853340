import Feature from "src/js/stores/user/Feature";
import { ConstraintsCategory } from "../../Constraints/types";
import { ConstraintsLayerType } from "../layer_types/ConstraintsLayerType";
import { Layer } from "../types";

export const BIODIVERSITY_IMPROVEMENT: Layer = {
  id: ConstraintsCategory.BIODIVERSITY_IMPROVEMENT,
  layerType: ConstraintsLayerType,
  title: "Biodiversity Improvement Areas",
  requiredFeatureAccess: [Feature.planningPolicyLayer],
  toggleable: true,
  description:
    "Areas identified for nature improvement or where nature improvement projects are underway",
  hideByDefault: true,
  layerConfig: {
    rootCategory: ConstraintsCategory.BIODIVERSITY_IMPROVEMENT,
    categorySchema: [
      { key: "habitat_networks_fragmentation_action_zone" },
      { key: "habitat_networks_habitat_restoration" },
      { key: "habitat_networks_enhancement_zone_1" },
      { key: "habitat_networks_enhancement_zone_2" },
      { key: "habitat_networks_expansion_zone" },
      { key: "habitat_networks_restorable_habitat" },
      { key: "nature_recovery_projects" },
    ],
  },
};
