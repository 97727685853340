import { RecordType } from "react-migration/domains/comparables/typings/Record";
import { Translation } from "react-migration/lib/typings";

export interface NewBuildTitleProps {
  t: Translation;
}
export const newBuildTitle = ({ t }: NewBuildTitleProps) =>
  t("comparables.map.sidebar.value.record.new.title");

export interface NewBuildValueProps {
  t: Translation;
  record: RecordType;
}
export const newBuildValue = ({ t, record }: NewBuildValueProps) => {
  if (record.country !== "GB") {
    return undefined;
  }
  return record.is_new_property
    ? t("comparables.map.sidebar.value.record.new.value.true")
    : t("comparables.map.sidebar.value.record.new.value.false");
};
