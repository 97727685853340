import { Loading } from "@landtechnologies/components";
import classNames from "classnames";
import { useTranslation } from "react-migration/lib/i18n/useTranslation";

export enum UploadStatus {
  Initialised,
  GettingSignedUrl,
  Uploading,
  Scanning,
  Clean,
  Infected,
  Error,
}

type ImageUploadStatusProps = {
  uploadStatus: UploadStatus;
};

export const ImageUploadStatus = ({ uploadStatus }: ImageUploadStatusProps) => {
  const { t } = useTranslation();
  const renderStatusIcon = () => {
    switch (uploadStatus) {
      case UploadStatus.GettingSignedUrl:
      case UploadStatus.Uploading:
      case UploadStatus.Scanning:
        return (
          <div className="atlas-mb-2">
            <Loading />
          </div>
        );
      case UploadStatus.Clean:
        return (
          <i
            className={classNames(
              "icon-lt-checkbox-circle-line",
              "atlas-text-content-positive",
              "atlas-text-3xl"
            )}
            data-testid="checkbox-icon"
          />
        );
      case UploadStatus.Error:
      case UploadStatus.Infected:
        return (
          <i
            className={classNames(
              "icon-lt-error-warning-line",
              "atlas-text-content-negative",
              "atlas-text-3xl"
            )}
            data-testid="error-icon"
          />
        );
    }
  };
  const getStatusText = () => {
    switch (uploadStatus) {
      case UploadStatus.GettingSignedUrl:
      case UploadStatus.Uploading:
      case UploadStatus.Scanning:
        return t("sites.letters.uploading_image");
      case UploadStatus.Clean:
        return t("sites.letters.image_uploaded");
      case UploadStatus.Infected:
        return t("sites.letters.upload_failed_virus_detected");
      case UploadStatus.Error:
        return t("sites.letters.something_went_wrong_please_try_again");
    }
  };
  if (uploadStatus === UploadStatus.Initialised) {
    return null;
  }
  return (
    <div
      className={classNames(
        "atlas-absolute",
        "atlas-left-2",
        "atlas-top-2",
        "atlas-bottom-2",
        "atlas-right-2",
        "atlas-bg-white",
        "atlas-opacity-90",
        "atlas-flex",
        "atlas-flex-col",
        "atlas-items-center",
        "atlas-justify-center",
        "atlas-text-normal-600"
      )}
      data-testid="image-upload-status"
    >
      {renderStatusIcon()}
      <span className={classNames("atlas-italic", "atlas-mt-2")}>{getStatusText()}</span>
    </div>
  );
};
