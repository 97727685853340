import { Credits } from "./components/Credits";
import { Header } from "./components/Header";
import { Usage } from "./components/Usage";

export const Topup = () => {
  return (
    <>
      <Header />
      <Credits />
      <Usage />
    </>
  );
};
