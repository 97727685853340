import { Polygon } from "@turf/helpers";
import { MultiPolygon } from "geojson";
import type { LayerTypeSelectionDetailViewProps } from "react-migration/layouts/map/Multilayer/types";
import { LoadingPanel } from "./LoadingPanel";
import { useMemo } from "react";
import { OwnerDetailPage } from "./OwnerDetailPage";
import { useTitlesWithOwnerNamesQuery } from "../hooks/useTitlesWithOwnerNamesQuery";
import { useSelectedOwner } from "./useSelectedOwner";

export function OwnerSelectionDetailView({
  selection,
  setDetailSelection,
}: LayerTypeSelectionDetailViewProps) {
  const selectedOwnerName = selection.id;

  const { titlesWithOwnerNames, loading: titlesByLocationLoading } = useTitlesWithOwnerNamesQuery(
    selection.feature?.geometry as Polygon | MultiPolygon
  );

  const selectedOwnerTitles = useMemo(
    () =>
      titlesWithOwnerNames.filter(
        (title) => selectedOwnerName && title.ownerNames?.includes(selectedOwnerName)
      ),
    [selectedOwnerName, titlesWithOwnerNames]
  );

  // Pick owners from first title via `selectedOwnerTitles[0].owners`
  const selectedOwner = useSelectedOwner(selectedOwnerTitles[0]?.owners, selectedOwnerName);

  // If we buy a title and the selected owner is Unknown, we will no longer have
  // any titles match and they will need to re-select the new owner.
  if (selectedOwnerTitles.length == 0) {
    setDetailSelection?.(undefined);
  }

  if (titlesByLocationLoading) return <LoadingPanel />;

  return (
    <OwnerDetailPage
      selectedOwner={selectedOwner}
      titles={selectedOwnerTitles}
      selectedTitleNumber={selection.titleNumber}
    />
  );
}
