import Feature from "src/js/stores/user/Feature";
import { IsochroneLayerType } from "../layer_types/IsochroneLayerType";
import { Layer } from "../types";

export const ISOCHRONE_LAYER: Layer = {
  id: "ISOCHRONE",
  layerType: IsochroneLayerType,
  title: "Distance & Travel",
  requiredFeatureAccess: [Feature.isochroneLayer],
  toggleable: true,
  description: "This layer shows travel time from your selection",
  label: { color: "BLUE", translationKey: "map.explore.label.beta" },
  hideByDefault: true,
};
