import { useEffect, useRef, useState } from "react";
import * as React from "react";
import classNames from "classnames";
import hasFeature from "src/js/stores/user/actions/hasFeature";
import Feature from "src/js/stores/user/Feature";
import { letterStore } from "src/js/stores/letter/store";
import { useSnapshot } from "valtio";
import { Heading } from "./LetterParts/Heading";
import { MainContent } from "./LetterParts/MainContent";
import { Signature } from "./LetterParts/Signature";
import { Footer } from "./LetterParts/Footer";

interface FirstPageProps {
  onHeightChange?: (isLong: boolean) => void;
  showValues?: boolean;
}

const MAX_HEIGHT_A4 = 1123;
const MAX_HEIGHT_LETTER = 1056;

export const FirstPage: React.FC<FirstPageProps> = ({ onHeightChange, showValues }) => {
  const usAccess = hasFeature(Feature.usAccess);
  const { letter } = useSnapshot(letterStore);

  const [height, setHeight] = useState<number>();
  const letterPage = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const maxHeight = usAccess ? MAX_HEIGHT_LETTER : MAX_HEIGHT_A4;
    const newHeight = letterPage.current?.clientHeight;
    if (newHeight && newHeight !== height) {
      setHeight(newHeight);
      onHeightChange?.(newHeight > maxHeight);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [letter, usAccess]);

  return (
    <div
      className={classNames(
        "atlas-bg-white",
        "atlas-mx-auto",
        "atlas-drop-shadow-md",
        "atlas-font-['Arial']",
        "atlas-text-[11pt]",
        "atlas-leading-[15pt]",
        usAccess ? "atlas-p-[0.68in]" : "atlas-p-[20mm]",
        !usAccess && "atlas-pl-[22mm]",
        usAccess ? "atlas-min-h-letter" : "atlas-min-h-a4",
        usAccess ? "atlas-w-letter" : "atlas-w-a4"
      )}
      data-testid="letter-preview-first-page"
      ref={letterPage}
    >
      <Heading showValues={showValues} />
      <MainContent showValues={showValues} />
      {letter.signaturePage === 1 && <Signature showValues={showValues} />}
      <Footer />
    </div>
  );
};
