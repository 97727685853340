import { useTranslation } from "react-migration/lib/i18n/useTranslation";
import { RefObject, useMemo, useState } from "react";
import { DetailedList, Loading } from "@landtechnologies/components";
import { useUserAddressesQuery } from "./hooks/useUserAddressesQuery";
import { useCreateUserAddressMutation } from "./hooks/useCreateUserAddressMutation";
import { useUpdateUserAddressMutation } from "./hooks/useUpdateUserAddressMutation";
import { FormFieldsAddress } from "./validationSchema/addressSchema";
import { AddressRow } from "./components/AddressRow";
import { AddressForm } from "./components/AddressForm";
import { AddressConfirmationSwitch } from "./components/AddressConfirmationSwitch";
export const YourAddresses = ({
  inModal,
  onAddressChange,
  addressFormRef,
}: {
  inModal?: boolean;
  onAddressChange?: () => void;
  addressFormRef?: RefObject<HTMLDivElement>;
}) => {
  const { t } = useTranslation();
  const [expandedId, setExpandedId] = useState("");
  const { data, loading } = useUserAddressesQuery();
  const [createUserAddress, { loading: createLoading }] = useCreateUserAddressMutation();
  const [updateUserAddress, { loading: updateLoading }] = useUpdateUserAddressMutation();
  const defaultId = useMemo(() => {
    const defaultAddress = data?.userAddresses.find((address) => address.is_default);
    return defaultAddress?._id;
  }, [data]);

  const handleAddAddress = async ({
    city,
    country,
    name,
    line1,
    line2,
    postcode,
  }: FormFieldsAddress) => {
    await createUserAddress({
      variables: {
        address: {
          city,
          country,
          receiver_name: name,
          line1,
          line2,
          postcode,
        },
        as_default: true,
      },
    });
    setExpandedId("");
    onAddressChange && onAddressChange();
  };

  const handleEditAddress = (
    { city, country, name, line1, line2, postcode }: FormFieldsAddress,
    id: string
  ) => {
    updateUserAddress({
      variables: {
        address: {
          city,
          country,
          receiver_name: name,
          line1,
          line2,
          postcode,
        },
        as_default: true,
        address_id: id,
      },
    });
    setExpandedId("");
    onAddressChange && onAddressChange();
  };

  const handleRowClick = async (rowId: string | undefined) => {
    if (rowId === "new") {
      setExpandedId("new");
    } else {
      if (rowId) {
        await updateUserAddress({
          variables: {
            as_default: true,
            address_id: rowId,
          },
        });
      }
      setExpandedId("");
    }
  };

  return (
    <div data-testid="your-address">
      {!inModal && (
        <h2 className="atlas-text-sm atlas-font-medium md:atlas-text-3xl atlas-mb-6">
          {t("dashboard.your_address.your_addresses")}
        </h2>
      )}
      <div className="atlas-text-neutral-600 atlas-mb-4">
        {t("dashboard.your_address.description")}
      </div>
      {loading || !data ? (
        <Loading />
      ) : (
        <div className="atlas-ml-4">
          <DetailedList
            activeId={defaultId}
            expandedId={expandedId}
            onRowClick={handleRowClick}
            rows={[
              ...data.userAddresses.map((address) => ({
                id: address._id,
                content: (
                  <AddressRow
                    address={address}
                    editAction={(e) => {
                      setExpandedId(address._id);
                      e.stopPropagation();
                    }}
                  />
                ),
                expandedContent: (
                  <AddressForm
                    onSubmit={(values) => handleEditAddress(values, address._id)}
                    onCancel={() => setExpandedId("")}
                    address={address}
                    loading={updateLoading}
                    addressFormRef={addressFormRef}
                  />
                ),
              })),
              {
                id: "new",
                content: (
                  <div data-testid="new-address" className="atlas-font-semibold">
                    {t("dashboard.addresses.add_new_address")}
                  </div>
                ),
                expandedContent: (
                  <AddressForm
                    onSubmit={handleAddAddress}
                    onCancel={() => {
                      setExpandedId("");
                    }}
                    loading={createLoading}
                    addressFormRef={addressFormRef}
                  />
                ),
              },
            ]}
          />
        </div>
      )}
      <AddressConfirmationSwitch />
      <div className="atlas-text-neutral-600 atlas-mb-4 atlas-pt-4">
        {t("components.edit_address.see_our_privacy_policy", {
          privacyPolicyLink: (
            <a href="https://land.tech/legal#privacy-policy" target="_blank" rel="noreferrer">
              {t("components.edit_address.privacy_policy")}
            </a>
          ),
        })}
      </div>
    </div>
  );
};
