import { SiteFeature } from "src/js/stores/siteGeometry/store";
import { isPolygonOrMultiPolygon } from "../../geometry_utilities";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function isSiteBoundary(feature: any): feature is SiteFeature {
  const hasValidGeometry = isPolygonOrMultiPolygon(feature?.geometry);
  const hasValidProperties = feature?.properties?.color && feature?.properties.title;

  return hasValidGeometry && hasValidProperties;
}
