import Feature from "src/js/stores/user/Feature";
import { SitesLayerType } from "../layer_types/SitesLayerType";
import { Layer } from "../types";

export const SITES_LAYER_ID = "sites";

export const SITES_LAYER: Layer = {
  id: SITES_LAYER_ID,
  layerType: SitesLayerType,
  title: "Saved Sites",
  collapsed: true,
  toggleable: true,
  description: "Sites that you have saved",
  requiredFeatureAccess: [Feature.sitesLayer],
};
