import { createContext, useContext, useEffect, useMemo, useState } from "react";
import { LayerTypeProviderProps } from "../../types";
import { UtilityFeature } from "./types";

export interface UtilitiesLayerTypeContextProps {
  expandedCategory?: string;
  setExpandedCategory(category: string | undefined): void;
  utilitiesFeatures?: UtilityFeature[];
  expandedProvider?: string;
  setExpandedProvider(provider: string | undefined): void;
}

import { useSelectionUtilityData } from "./useSelectionUtilityData";

const UtilitiesLayerTypeContext = createContext({} as UtilitiesLayerTypeContextProps);

export const useUtilitiesLayerTypeContext = () => useContext(UtilitiesLayerTypeContext);

export function UtilitiesLayerTypeProvider({ children, selection }: LayerTypeProviderProps) {
  const [expandedCategory, setExpandedCategory] = useState<string>();
  const [expandedProvider, setExpandedProvider] = useState<string>();
  const [utilitiesFeatures, setUtilitiesFeatures] = useState<UtilityFeature[]>();

  const selectionUtilitiesFeatures = useSelectionUtilityData(selection);

  useEffect(() => {
    setExpandedCategory(undefined);
    setUtilitiesFeatures(undefined);
  }, [selection]);

  useEffect(() => {
    if (JSON.stringify(utilitiesFeatures) !== JSON.stringify(selectionUtilitiesFeatures)) {
      setUtilitiesFeatures(selectionUtilitiesFeatures);
    }
  }, [selectionUtilitiesFeatures, utilitiesFeatures]);

  const providerState = useMemo(() => {
    return {
      expandedCategory,
      setExpandedCategory,
      utilitiesFeatures,
      expandedProvider,
      setExpandedProvider,
    };
  }, [expandedCategory, expandedProvider, utilitiesFeatures, setExpandedProvider]);

  return (
    <UtilitiesLayerTypeContext.Provider value={providerState}>
      {children}
    </UtilitiesLayerTypeContext.Provider>
  );
}
