/**
 * The rhs value of this enum must match one of the source "layers" in the sites
 * resolver (`landinsight-api/lib/sites/persistent/resolver.js`).
 */
export enum SaveSiteLayer {
  Comparables = "pricepaid",
  PricePaid = "pricepaid",
  Constraints = "constraints",
  Planning = "planning",
  Ownership = "ownership",
  PropertyInformation = "propertyInformation",
  Annotations = "annotations",
  Alerts = "alerts",
}
