import { Button } from "react-migration/components/Button";

import hasFeature from "src/js/stores/user/actions/hasFeature";
import Feature from "src/js/stores/user/Feature";
import { useTranslation } from "react-migration/lib/i18n/useTranslation";

import { setShowUpgradeModal } from "src/js/stores/navigation/actions";

export const UpgradeButton = () => {
  const { t } = useTranslation();

  if (!hasFeature(Feature.upgradeButton)) {
    return null;
  }

  return (
    <Button
      variant="primary"
      data-testid="upgrade-button"
      onClick={() => setShowUpgradeModal(true)}
      tailIcon="lt-arrow-right-s-line"
    >
      {t("nav.nav.upgrade")}
    </Button>
  );
};
