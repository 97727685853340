import hasFeature from "src/js/stores/user/actions/hasFeature";
import Feature from "src/js/stores/user/Feature";
import { userStore } from "src/js/stores/user/store";

type SupportedLocale = "en-US" | "en" | "en-GB";

export type Currency = "GBP" | "USD";

type Unit = "meter" | "kilometer" | "foot" | "mile";

interface FormatToCurrency {
  amount: number;
  currency?: Currency;
  locale?: SupportedLocale;
}

export const formatToCurrency = ({
  amount,
  currency,
  locale,
}: FormatToCurrency): string | undefined => {
  let formattedNumber;

  try {
    formattedNumber = new Intl.NumberFormat(locale ?? userStore.locale, {
      style: "currency",
      currency: currency || (locale && getCurrencyFromLocale(locale)),
    }).format(amount);
  } catch (e) {
    console.error(e);
  }

  return formattedNumber;
};

const getCurrencyFromLocale = (locale: SupportedLocale): Currency => {
  if (locale === "en-US") {
    return "USD";
  } else if (locale === "en-GB") {
    return "GBP";
  } else {
    return "GBP";
  }
};

export const getCurrencyFromLicense = (): Currency => {
  return hasFeature(Feature.usAccess) ? "USD" : "GBP";
};

export const formatToUnit = (amount: number, unit: Unit, locale?: string): string => {
  let formattedNumber = "";

  try {
    formattedNumber = new Intl.NumberFormat(locale ?? userStore.locale, {
      style: "unit",
      maximumFractionDigits: 2,
      unit,
    }).format(amount);
  } catch (e) {
    console.error(e);
  }

  return formattedNumber;
};

export const formatToPrecision = (amount: number, precision = 2) => {
  return Number(Math.round(parseFloat(amount + "e" + precision)) + "e-" + precision);
};

export const format = (amount: number, locale?: string): string | undefined => {
  let formattedNumber;

  try {
    formattedNumber = new Intl.NumberFormat(locale ?? userStore.locale, {
      maximumFractionDigits: 2,
    }).format(amount);
  } catch (e) {
    console.error(e);
  }

  return formattedNumber;
};

/**
 * To display the values in different formats according to their magnitue - units, thousands, millions
 */
export const modifiers: Array<{
  test: (x: number) => boolean;
  modifier: (x: number) => number;
  suffix: "" | "K" | "M";
}> = [
  {
    test: (x) => x >= 1e3 && x < 1e6,
    modifier: (x) => Number((x / 1e3).toFixed(2)),
    suffix: "K",
  },
  {
    test: (x) => x < 1e3 && x >= 1e2,
    modifier: (x) => Number(x.toFixed(1)),
    suffix: "",
  },
  {
    test: (x) => x < 1e2 && x >= 1e1,
    modifier: (x) => Number(x.toFixed(2)),
    suffix: "",
  },
  {
    test: (x) => x < 1e1,
    modifier: (x) => Number(x.toFixed(3)),
    suffix: "",
  },
  {
    test: (x) => x >= 1e6,
    modifier: (x) => Number((x / 1e6).toFixed(2)),
    suffix: "M",
  },
];
