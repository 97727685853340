import clsx from "clsx";
import { ComponentProps } from "react";

export const Logo = ({ className, ...rest }: ComponentProps<"svg">) => {
  return (
    <svg
      width="87"
      height="87"
      viewBox="0 0 87 87"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={clsx(
        "atlas-block atlas-w-9 atlas-h-9 atlas-m-2 md:atlas-mr-6 md:atlas-ml-4 atlas-flex-none",
        className
      )}
      {...rest}
    >
      <path d="M0 58.0331L28.9669 57.9336V86.9004L0 58.0331Z" fill="#012552" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M57.9337 28.9669V0L28.9668 28.9669V57.9338L57.9337 28.9669ZM86.9004 29.0663L87 58.0332L57.9336 87V57.9336L86.9004 29.0663Z"
        fill="#862BDE"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M28.9669 28.9669H0L28.9669 0H57.9338L28.9669 28.9669ZM86.9005 29.0663L57.9337 57.9337H28.9668L57.9337 28.9668L86.9005 29.0663Z"
        fill="#AB6BE8"
      />
    </svg>
  );
};
