import type { RecursiveTree } from "../types";

export type CollapsibleItem =
  | {
      isCollapsible: true;
      collapsed: boolean;
      setCollapsed: (value: boolean) => void;
    }
  | {
      isCollapsible: false;
    };

export interface CollapsibleTreeRecursiveProps {
  getCollapsed: (id: string) => boolean;
  setCollapsed: (id: string, value: boolean) => void;
}

export function collapsibleTreeRecursive<U>(
  tree: RecursiveTree<U>,
  props: CollapsibleTreeRecursiveProps
): RecursiveTree<CollapsibleItem & U> {
  const { getCollapsed, setCollapsed } = props;
  const childNodes = tree.childNodes.map((n) => collapsibleTreeRecursive(n, props));

  const isLeafNode = !childNodes.length;

  const item = {
    ...tree,
    childNodes,
  };

  return isLeafNode
    ? { ...item, isCollapsible: false }
    : {
        ...item,
        isCollapsible: true,
        collapsed: getCollapsed(item.id),
        setCollapsed: (v) => setCollapsed(item.id, v),
      };
}
