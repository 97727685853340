export const AlertIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" fill="none" viewBox="0 0 25 24">
      <g clipPath="url(#clip0_182_12102)">
        <path
          fill="#03053D"
          d="M22.5 20h-20v-2h1v-6.969C3.5 6.043 7.53 2 12.5 2s9 4.043 9 9.031V18h1v2zm-17-2h14v-6.969C19.5 7.148 16.366 4 12.5 4s-7 3.148-7 7.031V18zm4.5 3h5a2.5 2.5 0 01-5 0z"
        ></path>
      </g>
      <defs>
        <clipPath id="clip0_182_12102">
          <path fill="#fff" d="M0 0H24V24H0z" transform="translate(.5)"></path>
        </clipPath>
      </defs>
    </svg>
  );
};
