import { Icon } from "@landtechnologies/components";
import centerOfMass from "@turf/center-of-mass";
import clsx from "clsx";
import { HTMLProps, useMemo, useState } from "react";
import { Button } from "react-migration/components/Button";
import { logEventV2 } from "react-migration/lib/util/logEventV2";
import { SelectionFeature } from "src/js/stores/map/store";

type FeatureVotingProps = HTMLProps<HTMLDivElement> & {
  /** The name of the feature, used for the mixpanel event */
  featureName: string;
  /** The message to display alongside the the voting buttons */
  message: string;
  selection: SelectionFeature;
};

export const FeatureVoting = ({
  featureName,
  message,
  className,
  selection,
}: FeatureVotingProps) => {
  const [voted, setVoted] = useState("");

  const mapLatLng = useMemo(() => {
    const geometry = selection.feature?.geometry ?? undefined;
    if (!geometry) {
      return;
    }
    return centerOfMass(geometry).geometry.coordinates;
  }, [selection.feature?.geometry]);

  return (
    <div className={clsx("atlas-flex atlas-items-center atlas-grow", className)}>
      <Icon size="sm" icon="icon-lt-information-line atlas-text-platform-500" />
      <div className="atlas-pl-2 atlas-text-xs atlas-grow">{message}</div>
      <div className="atlas-flex atlas-gap-x-2">
        <Button
          variant="tertiary"
          disabled={voted !== ""}
          onClick={() => {
            setVoted("up");
            logEventV2({
              name: "Feature vote",
              properties: {
                featureName: featureName,
                vote: "Thumbs Up",
                selectionType: selection.type,
                selectionId: selection.id,
                mapLatLng,
              },
            });
          }}
          leadIcon="lt-thumb-up-line"
          className={clsx(
            voted === "up" && "disabled:!atlas-bg-positive-7 disabled:!atlas-text-white",
            voted === "down" && "disabled:!atlas-text-neutral-4"
          )}
        />
        <Button
          variant="tertiary"
          disabled={voted !== ""}
          onClick={() => {
            setVoted("down");
            logEventV2({
              name: "Feature vote",
              properties: {
                featureName: featureName,
                vote: "Thumbs Down",
                selectionType: selection.type,
                selectionId: selection.id,
                mapLatLng,
              },
            });
          }}
          leadIcon="lt-thumb-down-line"
          className={clsx(
            voted === "down" && "disabled:!atlas-bg-negative-7 disabled:!atlas-text-white",
            voted === "up" && "disabled:!atlas-text-neutral-4"
          )}
        />
      </div>
    </div>
  );
};
