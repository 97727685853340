import { useCallback } from "react";
import { useSnapshot } from "valtio";
import { refreshSiteGeometryStore } from "src/js/stores/siteGeometry/actions/refreshSiteGeometryStore";
import { mapStore } from "src/js/stores/map/store";
import { useSiteStages } from "../../hooks/useSiteStages";
import { SiteOverview } from "../../typings/Site";
import { SiteStage } from "../../typings/SiteStage";
import { useUpdateSiteMutation } from "../SaveSiteButton/useUpdateSiteMutation/useUpdateSiteMutation";
import {
  SiteStageSelect as SiteStageSelectVisual,
  SiteStageSelectProps as SiteStageSelectVisualProps,
  SiteStageSelectrTriggerType,
} from "./SiteStageSelect";

type SiteStageSelectProps = Pick<SiteStageSelectVisualProps, "triggerType"> & {
  site?: SiteOverview;
  onStartedSaving?(): void;
  onCompleted?(): void;
  onError?(): void;
};

const EMPTY_SITE_STAGES: SiteStage[] = [];

export const SiteStageSelect = ({
  site,
  triggerType,
  onStartedSaving,
  onCompleted,
  onError,
}: SiteStageSelectProps) => {
  const { stages, loading: loadingStages } = useSiteStages();
  const [updateSite, { loading: saving }] = useUpdateSiteMutation();
  const { googleMap } = useSnapshot(mapStore);

  const onCompletedHandler = useCallback(() => {
    onCompleted?.();
    googleMap && refreshSiteGeometryStore(googleMap);
  }, [googleMap, onCompleted]);

  return (
    <SiteStageSelectVisual
      triggerType={triggerType}
      disabled={loadingStages || saving}
      onChange={(value) => {
        if (!site) return;
        onStartedSaving?.();

        updateSite({
          variables: { updateData: { _id: site._id, _stage: value } },
          onCompleted: onCompletedHandler,
          onError: onError,
        });
      }}
      value={site?._stage._id}
      stages={stages ?? EMPTY_SITE_STAGES}
    />
  );
};

SiteStageSelect.TriggerType = SiteStageSelectrTriggerType;
