import type { LayerTypeSelectionDetailViewProps } from "react-migration/layouts/map/Multilayer/types";
import { UKOwnershipDetailPage } from "./UKOwnershipDetailPage";
import { USOwnershipDetailPage } from "./USOwnershipDetailPage";
import hasFeature from "src/js/stores/user/actions/hasFeature";
import Feature from "src/js/stores/user/Feature";

export function OwnershipSelectionDetailView({
  selection,
  setDetailSelection,
}: LayerTypeSelectionDetailViewProps) {
  const isUSUser = hasFeature(Feature.usAccess);
  const hasUnlimitedOwnershipClicks = hasFeature(Feature.unlimitedOwnershipClicks);

  return (
    <div>
      {isUSUser ? (
        <USOwnershipDetailPage selection={selection} />
      ) : (
        <UKOwnershipDetailPage
          selection={selection}
          selectedTitleNumber={selection.id}
          setDetailSelection={setDetailSelection}
          hasUnlimitedOwnershipClicks={hasUnlimitedOwnershipClicks}
        />
      )}
    </div>
  );
}
