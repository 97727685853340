import Feature from "src/js/stores/user/Feature";
import { ConstraintsCategory } from "../../Constraints/types";
import { ConstraintsLayerType } from "../layer_types/ConstraintsLayerType";
import { Layer } from "../types";

export const ENERGY_PROJECTS: Layer = {
  id: ConstraintsCategory.ENERGY_PROJECTS,
  layerType: ConstraintsLayerType,
  title: "Energy Projects",
  requiredFeatureAccess: [Feature.powerLayer],
  toggleable: true,
  description: "Projects relating to energy generation, storage, and supply",
  hideByDefault: true,
  layerConfig: {
    rootCategory: ConstraintsCategory.ENERGY_PROJECTS,
    categorySchema: [
      { key: "repd_solar" },
      { key: "repd_wind" },
      { key: "repd_battery" },
      { key: "repd_other" },
    ],
  },
};
