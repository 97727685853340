import { MultiPolygon, Polygon } from "@turf/helpers";

export type LatLngCoordinates = {
  lat: number;
  lng: number;
};

/**
 * Given either a polygon or a multi-polygon, convert the polygon coordinates
 * from GeoJson’s position standard (which is an array of tuples for lat long:
 * [long, lat][]) into an array of objects {lat: x, log: x}[] which we use for
 * geospatial querying in the Landinsight API
 */
export function convertPositionsToLatLngCoordinates(
  polygon: Polygon | MultiPolygon
): LatLngCoordinates[] | undefined {
  if (polygon.type === "MultiPolygon") {
    const toSinglePolygons = polygon.coordinates.map((positions) => positions[0]);
    const latLngPoints = toSinglePolygons.map((positions) =>
      positions.map((position) => ({
        lat: position[1],
        lng: position[0],
      }))
    );

    return latLngPoints.flat();
  } else if (polygon.type === "Polygon") {
    return polygon.coordinates[0].map((position) => ({
      lat: position[1],
      lng: position[0],
    }));
  } else {
    console.error("Passed polygon variable is not of a polygon type");
  }
}
