import axios from "axios";
import { useState } from "react";

export function useUploadImageToS3() {
  const [progress, setProgress] = useState(0);
  const [isDone, setIsDone] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const uploadImage = (image: File, presignedUrl: string) =>
    axios
      .put(presignedUrl, image, {
        headers: {
          "Content-Type": image.type,
        },
        onUploadProgress: (e) => {
          setProgress(e.loaded / e.total);
        },
      })
      .catch((err) => {
        setError(err);
      })
      .finally(() => {
        setIsDone(true);
      });
  return { isDone, progress, error, uploadImage };
}
