export enum SearchType {
  PORTFOLIO = "portfolio",
  SOURCING_TOOL_UK = "sourcing-tool-uk",
  SOURCING_TOOL_US_FL = "sourcing-tool-us-fl",
}

export const sourcingToolSearchTypes = [
  SearchType.SOURCING_TOOL_UK,
  SearchType.SOURCING_TOOL_US_FL,
];
export const searchTypeSet = new Set(Object.values(SearchType));

export const savableSearchTypes = new Set([
  SearchType.SOURCING_TOOL_UK,
  SearchType.SOURCING_TOOL_US_FL,
]);
export const visibleFilterFormTypes = new Set([SearchType.PORTFOLIO]);
