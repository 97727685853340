import VueRouter from "vue-router";
// eslint-disable-next-line no-unused-vars
import { localStorageKey, MapStore, SelectionIdentifier } from "./store";
import { captureException } from "../../util/monitoring";

export const getMapPathName = (settings: MapStore["settings"]) => {
  const parts = settings.publicApp ? ["public"] : [];

  parts.push("map");
  parts.push([settings.zoom, settings.mapX, settings.mapY].join(","));

  return parts.join("/");
};

export const getPathParameters = (router: VueRouter) => {
  const x = router.currentRoute.params.x;
  const y = router.currentRoute.params.y;
  const z = router.currentRoute.params.z;
  const siteId = router.currentRoute.query.siteId || null;
  const publicApp = router.currentRoute.name === "publicMap";
  return {
    siteId,
    ...(!isNaN(parseInt(z, 10)) && { zoom: parseInt(z, 10) }),
    ...(!isNaN(parseFloat(x)) && { mapX: parseFloat(x) }),
    ...(!isNaN(parseFloat(y)) && { mapY: parseFloat(y) }),
    ...{ publicApp },
  };
};

export const getSettingsFromLocalStorage = (): MapStore["settings"] | undefined => {
  try {
    return JSON.parse(window.localStorage.getItem(localStorageKey) || "{}");
  } catch (err) {
    captureException(err);
    window.localStorage.removeItem(localStorageKey);
    return undefined;
  }
};

export const getLastLogoutReason = () => {
  try {
    const lastLogoutReason = localStorage.getItem("lastLogoutReason");
    localStorage.removeItem("lastLogoutReason");
    return lastLogoutReason;
  } catch (err) {
    captureException(err);
    return undefined;
  }
};

export function getMapCenterClamped(googleMap: google.maps.Map) {
  return new google.maps.LatLng(googleMap.getCenter()?.toJSON() ?? { lat: 53, lng: 0 });
}

export function isMatchingSelection(
  selectionA: SelectionIdentifier,
  selectionB: SelectionIdentifier
) {
  return selectionA.id === selectionB.id && selectionA.type === selectionB.type;
}
