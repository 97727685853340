import clsx from "clsx";
import { useState, useEffect } from "react";
import { useAnimatedText } from "react-migration/lib/util/useAnimatedText";
import { SummaryType } from "./SummaryType";

type SummaryLineType = {
  /** Determines whether to render a title, subtitle or text */
  type: SummaryType["type"];
  /** The string of text to render */
  content: string;
  href?: string;
  /** Number of ms to delay rendering the text.
   * Used so multiple SummaryLine components can be rendered in order to simulate a single paragraph of text being typed out
   * */
  delay: number;
  /** Number of ms to render the content over */
  renderDuration: number;
};

export const SummaryLine = ({ type, content, href, delay, renderDuration }: SummaryLineType) => {
  const [show, setShow] = useState(false);

  // Delays rendering the text by the delay prop value
  useEffect(() => {
    const timer = setTimeout(() => setShow(true), delay);
    return () => {
      clearTimeout(timer);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!show) return null;

  if (type === "break") {
    return <div className="atlas-mb-2"></div>;
  }

  return (
    <div
      className={clsx(
        type === "title" && "atlas-block atlas-text-sm atlas-font-semibold atlas-py-2",
        type === "subtitle" && "atlas-block atlas-font-medium atlas-py-1",
        type === "text" && "atlas-inline atlas-mb-2",
        type === "anchor" && "atlas-inline atlas-mb-2"
      )}
    >
      {type === "anchor" ? (
        // We leveraging the href here to allow the upper component access to the planningId
        <a
          href={href}
          data-href={href}
          onClick={(e) => e.preventDefault()}
          className="atlas-cursor-pointer"
        >
          <AnimatedText text={content} duration={renderDuration / 1000} />
        </a>
      ) : (
        <AnimatedText text={content} duration={renderDuration / 1000} />
      )}
    </div>
  );
};

const AnimatedText = ({ text, duration }: { text: string; duration: number }) => {
  return <>{useAnimatedText({ text, duration })}</>;
};
