import { ENVIRONMENT } from "../../../util/environment";
import { captureException } from "../../../util/monitoring";

import { userStore } from "../store";

const saveUser = async () => {
  userStore.isSaving = true;

  const url = `${ENVIRONMENT.API_URL}/user`;

  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(userStore.user),
  };

  try {
    await fetch(url, options);
  } catch (err) {
    console.error("Error saving user:", err);

    captureException(err);

    throw err;
  } finally {
    userStore.isSaving = false;
  }
};

export default saveUser;
