import { Title } from "../typings/Title";
import { sortBy } from "lodash";

export function getMainPlotOrFirstUnit(titles: Title[]): Title | undefined {
  const sortedTitles = sortBy(titles, (t) => t.addresses?.[0]);
  const mainPlot = sortedTitles.find(
    (title: Title) => title.is_main_plot && title.polygons?.length
  );
  return mainPlot || sortedTitles.find((title) => !!title.title_no) || sortedTitles[0];
}
