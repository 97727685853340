import { useQuery } from "@apollo/client";
import GET_PLANNING_ALERTS_SUBSCRIPTION from "../apollo/queries/planningAlertSubscriptions.gql";
import {
  LandTechEndpoints,
  routedClient,
} from "react-migration/lib/persistence/apollo/routedClient";
import { PlanningAlertsSubscriptionResponse } from "../../typings/PlanningAlertsSubscriptionResponse";
type UsePlanningAlertSubscriptionQueryParams = {
  emailed_at: string | null;
  cursor: string | null;
  limit: number;
};

export const usePlanningAlertSubscriptionQuery = (
  args: UsePlanningAlertSubscriptionQueryParams
) => {
  return useQuery<PlanningAlertsSubscriptionResponse>(GET_PLANNING_ALERTS_SUBSCRIPTION, {
    client: routedClient,
    variables: args,
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    context: {
      endpoint: LandTechEndpoints.PlanningService,
    },
  });
};
