import { useTranslation } from "react-migration/lib/i18n/useTranslation";
export const ImpersonationBanner = () => {
  const { t } = useTranslation();
  return (
    <div
      id="impersonation-banner"
      className="atlas-bg-warning-5 atlas-absolute atlas-bottom-0 atlas-left-0 atlas-p-2 atlas-w-full atlas-z-[9999] atlas-text-warning-9"
      data-testid="overdue-invoice-banner"
    >
      <div className="atlas-flex atlas-text-center atlas-items-center">
        <div className="atlas-flex-1">
          <strong> {t("nav.you_are_running_in_impersonation_mode")} </strong>-{" "}
          {t("nav.logout_to_return_to_your_normal_account")}
        </div>
      </div>
    </div>
  );
};
