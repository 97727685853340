import clsx from "clsx";

import { useTranslation } from "react-migration/lib/i18n/useTranslation";
import { PurchaseSucceeded } from "./status/PurchaseSucceeded";
import { PurchasePending } from "./status/PurchasePending";
import { InsufficientCredit } from "./status/InsufficientCredit";
import { PurchaseDisabled } from "./status/PurchaseDisabled";
import { GenericError } from "./status/GenericError";
import { OutOfHours } from "./status/OutOfHours";
import { PurchaseError } from "./status/PurchaseError";
import { isCurrentTimeBetween7amAnd11pm, isWeekend } from "../../util/Time";

export type TransactionStatusProps = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  requestError?: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  transaction: any;
};

enum TransactionState {
  SUCCESS = "SUCCESS",
  PENDING = "PENDING",
  FAILURE = "FAILURE",
}

export type PurchaseErrors =
  | "insufficient_balance"
  | "purchase_disabled"
  | "pending_transaction"
  | "out_of_hours"
  | "unknown_error"
  | "not_immediately_available"
  | "invalid_credentials";

export function TransactionStatus({ requestError, transaction }: TransactionStatusProps) {
  const { t } = useTranslation();

  const hasFailed = requestError || !["pending", "success"].includes(transaction?.state?.status);
  const isPending = !hasFailed && ["pending"].includes(transaction?.state?.status);
  const hasSucceeded = !hasFailed && ["success"].includes(transaction?.state?.status);
  const state = hasSucceeded
    ? TransactionState.SUCCESS
    : isPending
    ? TransactionState.PENDING
    : TransactionState.FAILURE;

  const iconClasses = {
    [TransactionState.FAILURE]: "atlas-bg-[url(/public/img/icons/unsuccessful-icon.svg)]",
    [TransactionState.PENDING]: "atlas-bg-[url(/public/img/icons/pending-icon.svg)]",
    [TransactionState.SUCCESS]: "atlas-bg-[url(/public/img/icons/payment-accepted.svg)]",
  };

  const errorCode = requestError
    ? requestError.message || requestError.responseJSON?.message
    : "unknown_error";

  const StatusPage = () => {
    if (state === TransactionState.SUCCESS) {
      return (
        <PurchaseSucceeded physical={transaction?.state?.type === "title_doc_success_physical"} />
      );
    } else if (state === TransactionState.PENDING || errorCode === "pending_transaction") {
      return <PurchasePending expectedProcessDate={transaction?.state?.expected_response_time} />;
    } else {
      if (errorCode === "insufficient_balance") {
        return <InsufficientCredit />;
      } else if (errorCode === "purchase_disabled") {
        return <PurchaseDisabled />;
      } else if (errorCode === "invalid_credentials") {
        return <GenericError message={t("title_purchase.error.credentials.message")} />;
      } else if (errorCode === "not_immediately_available") {
        return <GenericError message={t("title_purchase.error.hmlr.message")} />;
      } else if (errorCode === "out_of_hours" || isWeekend() || !isCurrentTimeBetween7amAnd11pm()) {
        return <OutOfHours />;
      } else {
        return <PurchaseError stateType={transaction?.state?.type} />;
      }
    }
  };

  return (
    <div className="atlas-flex atlas-flex-col atlas-items-center" data-testid="transaction-status">
      <div
        className={clsx(
          "atlas-bg-center atlas-bg-cover atlas-w-14 atlas-h-14 atlas-mb-8",
          iconClasses[state]
        )}
      />
      <div className="atlas-text-center">
        <StatusPage />
      </div>
    </div>
  );
}
