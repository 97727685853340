import { getSelectedGeometry } from "src/js/stores/drawing/actions/getSelectedGeometry";
import { CircleSpec } from "./AnnotationSpec/CircleSpec";
import { PolygonSpec } from "./AnnotationSpec/PolygonSpec";
import { RulerSpec } from "./AnnotationSpec/LineSpec";
import { RectangleSpec } from "./AnnotationSpec/RectangleSpec";
import { MultiSelectSpec } from "./AnnotationSpec/MultiSelectSpec";
import { TextBoxSpec } from "./AnnotationSpec/TextBoxSpec";
import { getMultiSelected } from "src/js/stores/drawing/actions/getMultiSelected";
import { getEditableDrawing } from "src/js/stores/drawing/actions/getEditableDrawing";
import { Button, Icon } from "@landtechnologies/components";
import { setSelectedGeometry } from "src/js/stores/drawing/actions/setSelectedGeometry";
import { deleteDrawingBulk } from "src/js/stores/drawing/actions/deleteDrawingBulk";
import { setMultiSelected } from "src/js/stores/drawing/actions/setMultiSelected";
import { triggerDelete } from "src/js/stores/drawing/actions/triggerDelete";
import { PointSpec } from "./AnnotationSpec/PointSpec";
import { duplicateGeometry } from "src/js/stores/drawing/actions/duplicateGeometry";
import { useSnapshot } from "valtio/react";
import { mapStore } from "src/js/stores/map/store";

export function DrawingsSelectionView() {
  const {
    settings: { zoom },
  } = useSnapshot(mapStore);
  const selectedDrawing = getSelectedGeometry();
  const editableDrawing = getEditableDrawing();
  const multiSelection = getMultiSelected();

  const drawing = selectedDrawing || editableDrawing;
  const shape = drawing?.properties.shape;
  const shouldRenderSingleSelection = drawing && !multiSelection;

  const onConfirmDelete = () => {
    if (multiSelection) {
      deleteDrawingBulk(multiSelection);

      setMultiSelected(null);
      return;
    }

    selectedDrawing && triggerDelete(selectedDrawing.id);
  };

  const duplicate = () => {
    selectedDrawing && duplicateGeometry(selectedDrawing.id, zoom);
  };

  if (!drawing && !multiSelection) return null;

  return (
    <>
      {shouldRenderSingleSelection && (
        <div className="atlas-bg-white atlas-p-2 atlas-flex atlas-flex-col atlas-gap-5">
          <div className="atlas-flex atlas-justify-between atlas-items-center">
            <h1 className="atlas-text-base atlas-font-extralight atlas-text-content-secondary">
              Details
            </h1>
            <div className="atlas-flex atlas-justify-between atlas-gap-2">
              {editableDrawing ? (
                <Button.Primary
                  className="atlas-h-8"
                  onClick={() => setSelectedGeometry(drawing.id)}
                >
                  Done
                </Button.Primary>
              ) : (
                <Button.Secondary
                  title="Duplicate"
                  size="small"
                  className="atlas-w-8 atlas-h-8"
                  onClick={duplicate}
                >
                  <Icon icon="icon-lt-file-copy-line " size="sm" cursor="pointer" />
                </Button.Secondary>
              )}
              <Button.Secondary
                title="Delete"
                size="small"
                className="atlas-w-8 atlas-h-8"
                onClick={onConfirmDelete}
              >
                <Icon icon="icon-lt-delete-bin-line" size="sm" cursor="pointer" />
              </Button.Secondary>
            </div>
          </div>
          {shape === "Circle" && <CircleSpec drawing={drawing!} />}
          {shape === "Polygon" && <PolygonSpec drawing={drawing!} />}
          {(shape === "Ruler" || shape === "Polyline") && <RulerSpec drawing={drawing!} />}
          {shape === "Rectangle" && <RectangleSpec drawing={drawing!} />}
          {shape === "TextBox" && <TextBoxSpec drawing={drawing!} />}
          {shape === "Point" && <PointSpec drawing={drawing!} />}
        </div>
      )}
      {!shouldRenderSingleSelection && (
        <div className="atlas-bg-white atlas-p-2 atlas-flex-col atlas-gap-3">
          <div className="atlas-flex atlas-justify-between">
            <h1 className="atlas-text-base atlas-font-extralight atlas-text-content-secondary">
              Details
            </h1>
            <Button.Secondary
              title="Delete"
              size="small"
              className="atlas-min-w-12 atlas-h-8"
              onClick={onConfirmDelete}
            >
              <div className="atlas-mr-1">Delete all</div>
              <Icon icon="icon-lt-delete-bin-line" size="sm" cursor="pointer" />
            </Button.Secondary>
          </div>
          <MultiSelectSpec multiSelection={multiSelection} />
        </div>
      )}
    </>
  );
}
