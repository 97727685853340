import { Icon, Section } from "@landtechnologies/components";
import { Dispatch, SetStateAction } from "react";
import { DesignationHatch } from "react-migration/domains/constraints/components/Hatch";
import { ListSearchInput } from "react-migration/domains/constraints/components/ListSearchInput";
import { SingleDesignation } from "react-migration/domains/constraints/typings/applicationTypes/SingleDesignation";
import { SourceStatus } from "../SourceStatus";
import { sortDesignationsByName, sortDesignationsBySubCategory } from "./sortDesignations";
import { getDesignationFillColour, getDesignationLineColour } from "../../style/accessors";
import {
  DesignationSubTitle,
  DesignationTitle,
} from "react-migration/layouts/designations/Designation/Designation";
import { logEventV2 } from "react-migration/lib/util/logEventV2";

export interface DesignationsProps {
  filterable: boolean;
  designations: SingleDesignation[];
  setSelectedDesignation: Dispatch<SingleDesignation["id"]>;
  filterString: string;
  setFilterString: Dispatch<SetStateAction<string>>;
  truncateSubtitle: boolean;
  cardName: string;
}

export function Designations({
  filterable,
  designations,
  setSelectedDesignation,
  setFilterString,
  filterString,
  truncateSubtitle,
  cardName,
}: DesignationsProps) {
  const designationList = [...designations]
    .sort(sortDesignationsByName)
    .sort(sortDesignationsBySubCategory);

  return (
    <div className="atlas-space-y-3 atlas-shrink-0 atlas-rounded" data-testid="designations-body">
      <Section bodyClasses="atlas-flex atlas-flex-col atlas-text-sm atlas-rounded">
        {filterable && <ListSearchInput value={filterString} onChange={setFilterString} />}
        <div className="atlas-p-2 atlas-pt-0">
          <div className="atlas-overflow-hidden atlas-rounded atlas-border atlas-border-border-divider atlas-divide-y atlas-divide-border-divider atlas-bg-white atlas-drop-shadow-sm empty:atlas-hidden">
            {designationList.map((designation) => {
              return (
                <button
                  key={designation.id}
                  className="atlas-flex atlas-w-full atlas-items-center atlas-text-left atlas-overflow-hidden atlas-cursor-pointer hover:atlas-bg-neutral-100"
                  onClick={() => {
                    setSelectedDesignation(designation.id);
                    logEventV2({
                      name: "Workbench card click",
                      properties: {
                        cardName: cardName,
                        action: "goTo",
                        clickDetail: designation.display_name as string,
                      },
                    });
                  }}
                  // A global `border-style: none` is applied to buttons. This
                  // is to show divider lines between designation items.
                  style={{ borderStyle: "inherit" }}
                >
                  <div className="atlas-flex atlas-flex-col atlas-flex-grow atlas-p-2 atlas-gap-2 atlas-min-w-0">
                    <div className="atlas-flex atlas-items-start">
                      <div className="atlas-mr-2">
                        <DesignationHatch
                          id={designation.sub_category_id}
                          fillColorOverride={getDesignationFillColour(designation)}
                          lineColorOverride={getDesignationLineColour(designation)}
                        />
                      </div>
                      <div className="atlas-flex atlas-flex-col atlas-gap-1 atlas-grow atlas-min-w-0">
                        <div className="atlas-flex atlas-justify-between atlas-items-start">
                          <div className="atlas-text-content-link focus:atlas-text-content-link-hover hover:atlas-text-content-link-hover atlas-font-bold atlas-text-left">
                            <DesignationTitle designation={designation} />
                          </div>

                          <SourceStatus status={designation.source.status} />
                        </div>

                        <DesignationSubTitle
                          designation={designation}
                          truncate={truncateSubtitle}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="atlas-flex-shrink-0 atlas-text-neutral-500">
                    <Icon icon="icon-lt-arrow-right-s-line" />
                  </div>
                </button>
              );
            })}
          </div>
        </div>
      </Section>
    </div>
  );
}
