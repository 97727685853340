import hasFeature from "src/js/stores/user/actions/hasFeature";
import hasBetaFeature from "src/js/stores/user/actions/hasBetaFeature";
import curry from "ramda/src/curry";
import { ISearchFilter } from "../typings/Filters";

export const isAnAvailableFilter = curry(function isAnAvailableFilter(
  currentSearchType: string,
  filter: ISearchFilter
) {
  return (
    (filter.include ?? []).every((feature) => hasFeature(feature) || hasBetaFeature(feature)) &&
    (!filter.searchTypes?.length ||
      filter.searchTypes.some((searchType) => searchType === currentSearchType)) &&
    !(filter.exclude ?? []).some((feature) => hasFeature(feature) || hasBetaFeature(feature))
  );
});
