import { MeasurementSystem, userStore } from "../store";

export const getMeasurementSystemPreference: () => MeasurementSystem = () => {
  const usersUnit = userStore.user.settings.unit;
  if (!usersUnit) return MeasurementSystem.IMPERIAL;
  return usersUnit;
};

export const getHideTitleDocPurchaseAddressPrompt = () =>
  userStore.user.settings.hideTitleDocPurchaseAddressPrompt;

export const getSitesDisplayMode = () => userStore.user.settings.sitesDisplayMode || "stages";

export const getDownloadCompsWithoutFloorArea = () =>
  userStore.user.settings.downloadCompsWithoutFloorArea;

export const getOwnershipAlertsSubscription = () => userStore.user.settings.receiveOwnershipAlerts;

export const getShowHiddenSitesOnPipeline = () => userStore.user.settings.showHiddenSitesOnPipeline;
