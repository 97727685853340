import {
  SinglePersonRequestInput,
  useCreateSkipTraceOrderMutation,
} from "@gql/federated-api/graphql";
import { resetSkipTracingStore, skipTracingStore } from "./store/store";

import { useEffect, useMemo, useState } from "react";

import ROUTES from "src/js/router/routes";
import classNames from "classnames";
import { parseAddress } from "../helpers/addressParser";
import { router } from "src/js/router/react-router";
import { SkipTracingSitesQuery, useSkipTracingSitesQuery } from "@gql/li-api/graphql";
import { CreateSkipTraceHeader } from "./components/CreateSkipTraceHeader";
import { SkipTraceOrderTable } from "./components/SkipTraceOrderTable/SkipTraceOrderTable";
import { ConfirmOrder } from "./components/SkipTraceSummary/ConfirmOrder";
import { OrderConfirmationSuccessPage } from "./components/SkipTraceSummary/OrderConfirmationSuccessPage";
import { DNCModal } from "./components/SkipTraceSummary/DNCModal";
import { SkipTracingParcel } from "../typings/SkipTracingParcel";
import { useBatchName } from "./store/hooks/useBatchName";
import { useSelectedParcelIds } from "./store/hooks/useSelectedParcelIds";
import { useSelectedStageId } from "./store/hooks/useSelectedStageId";
import { routedClient, LandTechEndpoints } from "react-migration/lib/persistence/apollo";
import { ErrorPage } from "./components/SkipTraceSummary/ErrorPage";
import { SelectSitesStepFooter } from "./components/SkipTraceSelectSitesStepFooter";
import { useSkipTraceCurrentStep } from "./store/hooks/useSkipTraceCurrentStep";
import { SummaryStepFooter } from "./components/SkipTraceSummary/SummaryStepFooter";
import { updateCurrentStep } from "./store/actions/updateCurrentStep";
import { CreateSkipTraceOrderStep } from "../typings/SkipTraceOrderStep";
import { SkipTraceOrderPlacedPage } from "./components/SkipTraceOrderPlacedPage";

const SkipTraceStepFooter = ({
  onConfirmAndProcessButtonClicked,
}: {
  onConfirmAndProcessButtonClicked: () => void;
}) => {
  const currentStep = useSkipTraceCurrentStep();

  switch (currentStep) {
    case CreateSkipTraceOrderStep.SelectSites:
      return <SelectSitesStepFooter />;
    case CreateSkipTraceOrderStep.Summary:
      return (
        <SummaryStepFooter onConfirmAndProcessButtonClicked={onConfirmAndProcessButtonClicked} />
      );
    default:
      return null;
  }
};

export function CreateSkipTracePage() {
  const [isDNCModalOpen, setIsDNCModalOpen] = useState(false);

  const selectedStageId = useSelectedStageId();
  const batchName = useBatchName();
  const selectedParcelIds = useSelectedParcelIds();
  const currentStep = useSkipTraceCurrentStep();

  const sitesResponse = useSkipTracingSitesQuery({
    client: routedClient,
    variables: {
      stageIds: [selectedStageId],
      first: 100,
    },
    fetchPolicy: "cache-and-network",
    nextFetchPolicy: "cache-first",
  });

  const sites: SkipTracingSitesQuery["sites"]["edges"][0]["node"][] = useMemo(
    () => sitesResponse.data?.sites.edges.map(({ node }) => node) ?? [],
    [sitesResponse.data?.sites.edges]
  );

  const parsedParcels = useMemo(() => {
    const parcelIds = new Set();
    const parcels = sites.reduce((result, { title, address, titles }) => {
      titles?.forEach(({ parcel_identifier, owners }) => {
        if (parcelIds.has(parcel_identifier)) {
          return;
        }
        parcelIds.add(parcel_identifier);
        const ownerNames = owners?.reduce((result, owner) => {
          if (owner.person?.name) {
            result.push(owner.person.name);
          }
          if (owner.company?.name) {
            result.push(owner.company.name);
          }
          return result;
        }, [] as string[]);
        result.push({
          siteName: title ?? "",
          address: address ?? "",
          parcelNumber: parcel_identifier ?? "",
          ownerNames,
        });
      });
      return result;
    }, [] as SkipTracingParcel[]);
    return parcels ?? [];
  }, [sites]);

  useEffect(() => {
    skipTracingStore.parcels = parsedParcels;
    skipTracingStore.selectedParcelIds = parsedParcels.reduce((result, parcel) => {
      if (parcel.address) {
        result[parcel.parcelNumber] = true;
      }
      return result;
    }, {} as Record<string, boolean>);
  }, [parsedParcels]);

  useEffect(() => {
    skipTracingStore.parcelsLoading = sitesResponse.loading;
  }, [sitesResponse.loading]);

  useEffect(() => {
    skipTracingStore.totalSiteCount = sitesResponse.data?.sites.totalCount ?? 0;
  }, [sitesResponse.data?.sites.totalCount]);

  const onBackToSitesClicked = () => {
    resetSkipTracingStore();
    router.push({ name: ROUTES.SITES });
  };

  useEffect(() => {
    if (!selectedStageId) {
      router.push({ name: ROUTES.SITES });
    }
  }, [selectedStageId]);

  const [createSkipTraceOrder, { loading: createSkipTraceOrderInProgress }] =
    useCreateSkipTraceOrderMutation({
      client: routedClient,
      context: {
        endpoint: LandTechEndpoints.Gateway,
      },
      onCompleted() {
        updateCurrentStep(CreateSkipTraceOrderStep.OrderConfirmed);
      },
      onError() {
        updateCurrentStep(CreateSkipTraceOrderStep.OrderError);
      },
    });

  useEffect(() => {
    skipTracingStore.parcelsLoading = createSkipTraceOrderInProgress;
  }, [createSkipTraceOrderInProgress]);

  const onAcceptButtonClicked = async () => {
    setIsDNCModalOpen(false);
    const selectedParcelIdsArray = Object.keys(selectedParcelIds).filter(
      (id) => selectedParcelIds[id]
    );
    const personRequests: SinglePersonRequestInput[] = parsedParcels
      .filter(({ parcelNumber }) => selectedParcelIds[parcelNumber])
      .map((parcel) => {
        const { addressLine1, postcode } = parseAddress(parcel.address);
        return {
          propertyAddress: {
            street: addressLine1,
            zip: postcode,
          },
          apn: parcel.parcelNumber,
        };
      });
    updateCurrentStep(CreateSkipTraceOrderStep.OrderPlaced);
    createSkipTraceOrder({
      variables: {
        orderName: batchName || "Unnamed batch",
        siteIds: sites
          .filter(({ titles }) =>
            titles?.some(({ parcel_identifier }) => {
              if (!parcel_identifier) return false;
              return selectedParcelIdsArray.includes(parcel_identifier);
            })
          )
          .map(({ _id }) => _id),
        personRequests,
      },
    });
  };

  const SkipTraceContent = () => {
    switch (currentStep) {
      case CreateSkipTraceOrderStep.SelectSites:
        return <SkipTraceOrderTable />;
      case CreateSkipTraceOrderStep.Summary:
        return <ConfirmOrder />;
      case CreateSkipTraceOrderStep.OrderPlaced:
        return <SkipTraceOrderPlacedPage />;
      case CreateSkipTraceOrderStep.OrderConfirmed:
        return <OrderConfirmationSuccessPage />;
      case CreateSkipTraceOrderStep.OrderError:
        return <ErrorPage />;
      default:
        return null;
    }
  };

  return (
    <div
      data-testid="create-skip-trace"
      className={classNames("atlas-flex", "atlas-flex-col", "atlas-w-full", "atlas-h-full")}
    >
      <CreateSkipTraceHeader
        currentStep={currentStep}
        onBackToSitesClicked={onBackToSitesClicked}
      />
      <div
        className={classNames(
          "atlas-flex",
          "atlas-flex-col",
          "atlas-h-full",
          currentStep === CreateSkipTraceOrderStep.SelectSites && "atlas-p-5"
        )}
        data-testid="create-skip-trace-content"
      >
        {isDNCModalOpen && (
          <DNCModal
            onCancelClicked={() => setIsDNCModalOpen(false)}
            onAcceptClicked={onAcceptButtonClicked}
          />
        )}
        <SkipTraceContent />
      </div>

      <SkipTraceStepFooter onConfirmAndProcessButtonClicked={() => setIsDNCModalOpen(true)} />
    </div>
  );
}
