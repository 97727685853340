import { useTranslation } from "react-migration/lib/i18n/useTranslation";
import { logEvent } from "react-migration/lib/util/logEvent";
import { Intercom } from "src/js/util/intercom";

export type PurchaseErrorProps = {
  stateType?: string;
};

export function PurchaseError({ stateType }: PurchaseErrorProps) {
  const { t } = useTranslation();

  async function openHelp() {
    await logEvent("Open Intercom Widget", { from: "failed title purchase" });
    Intercom("show");
  }

  function getFailureReason() {
    const failureTypeToReasonMap = {
      title_doc_failure_pending_applications_will_notify: t(
        "title_purchase.error.purchase_error.pending"
      ),
      title_doc_failure_postal_delivery_not_available: t(
        "title_purchase.error.purchase_error.not_computerised"
      ),
      title_doc_failure_pending_first_registration: t(
        "title_purchase.error.purchase_error.registration"
      ),
      title_doc_failure_pending_applications: t("title_purchase.error.purchase_error.pending"),
      title_doc_failure_title_closed: t("title_purchase.error.purchase_error.closed"),
      title_doc_failure_system_error: t("title_purchase.error.purchase_error.system_error"),
      title_doc_failure_not_computerised: t("title_purchase.error.purchase_error.not_computerised"),
      title_doc_failure_unknown: t("title_purchase.error.purchase_error.unknown"),
    };
    if (stateType == undefined) return failureTypeToReasonMap.title_doc_failure_unknown;
    return (
      failureTypeToReasonMap[stateType as keyof typeof failureTypeToReasonMap] ||
      failureTypeToReasonMap.title_doc_failure_unknown
    );
  }

  return (
    <div data-testid="purchase-error" className="atlas-flex atlas-flex-col atlas-gap-4">
      <strong>{t("title_purchase.error.generic.title")}</strong>
      <p>
        {t("title_purchase.error.purchase_error.message", {
          failureReason: getFailureReason(),
        })}
      </p>
      <p>{t("title_purchase.error.purchase_error.not_charged")}</p>

      {getFailureReason() === "An unknown error occurred" && (
        <p>
          {t("title_purchase.error.purchase_error.support", {
            contactSupport: (
              <span className="atlas-cursor-pointer atlas-text-blue-600" onClick={openHelp}>
                {t("title_purchase.error.purchase_error.contact")}
              </span>
            ),
          })}
        </p>
      )}
    </div>
  );
}
