import { Section, SkeletonLoading } from "@landtechnologies/components";
import { SOURCE_STATUSES } from "react-migration/domains/constraints/constants";
import { useDesignationById } from "react-migration/domains/constraints/designation/hooks/useDesignationById";
import type { SingleDesignation } from "react-migration/domains/constraints/typings/applicationTypes/SingleDesignation";
import { useTranslation } from "react-migration/lib/i18n/useTranslation";
import { Attributes } from "./Attributes";
import isString from "lodash/isString";
import cn from "clsx";

interface DesignationTitleProps {
  designation: SingleDesignation;
}

export function DesignationTitle({ designation }: DesignationTitleProps) {
  const { t } = useTranslation();
  const title =
    designation.display_name ??
    designation.sub_category?.display_name ??
    t("domains.constraints.unknown");

  return <div className="atlas-font-bold">{title}</div>;
}

interface DesignationSubTitleProps extends DesignationTitleProps {
  truncate?: boolean;
}

export function DesignationSubTitle({ designation, truncate = false }: DesignationSubTitleProps) {
  const { t } = useTranslation();
  const categoryOrDefault = (designation: SingleDesignation) =>
    designation.sub_category?.display_name ?? t("domains.constraints.unknown");

  const value = [
    designation.display_name && categoryOrDefault(designation),
    designation.source.authority.name,
    new Date(designation.source.adoption_date).toLocaleDateString(),
  ]
    .filter(isString)
    .join(", ");

  return (
    <div className={cn("atlas-text-neutral-500", { "atlas-truncate": truncate })} title={value}>
      {value}
    </div>
  );
}

export interface DesignationProps {
  designationId?: string;
}

export function Designation({ designationId }: DesignationProps) {
  const { designation, ...designationQuery } = useDesignationById(designationId);

  if (designationQuery.loading || !designation) {
    return (
      <div className="atlas-bg-white atlas-overflow-auto">
        <div
          className="atlas-relative atlas-min-h-[250px] atlas-m-2"
          data-testid="designation-body-loading"
        >
          <SkeletonLoading rows={3} showPadding={false} />
        </div>
      </div>
    );
  }

  return <DesignationDetails designation={designation} />;
}

export interface DesignationDetailsProps {
  designation: SingleDesignation;
}

export function DesignationDetails({ designation }: DesignationDetailsProps) {
  const { t } = useTranslation();

  const designationDocuments = designation.designation_document_sections
    .filter((x) => x.document_section.document.url)
    .map(({ document_section: { document, pages } }) => ({
      id: document.id,
      name: document.document_name,
      pages: pages,
      url: `${document.url}#page=${pages[0]}`,
    }));

  const sourceDocuments = (designation.source.documents || []).filter(
    (x) => !x.is_reference && !designationDocuments.some((y) => y.id === x.id)
  );

  if (
    !designation.designation_attributes.length &&
    !designationDocuments.length &&
    !sourceDocuments.length
  ) {
    return null;
  }

  return (
    <div className="atlas-flex atlas-flex-col atlas-gap-3" data-testid="designation-body">
      {!!designation.designation_attributes.length && (
        <Section bodyClasses="atlas-flex atlas-flex-col atlas-px-3 atlas-py-2 atlas-gap-2 atlas-text-sm">
          <Attributes
            attributes={designation.designation_attributes}
            categoryId={designation.sub_category_id}
          />
        </Section>
      )}
      {!!designationDocuments.length && (
        <Section bodyClasses="atlas-flex atlas-flex-col atlas-px-3 atlas-py-2 atlas-gap-2 atlas-text-sm">
          <h1 className="atlas-font-bold atlas-text-sm atlas-text-content-secondary atlas-block atlas-break-words atlas-my-1">
            {t("domains.constraints.documents")}
          </h1>
          {designationDocuments.map(({ id, url, name, pages }) => {
            // Document URLs are poorly formed with .core_strategy or no file extensions, avoid generic path extraction and only extract what is required.
            const extension = url.includes(".csv") ? ".csv" : url.includes(".pdf") ? ".pdf" : "";
            return (
              <DocumentLink
                key={id}
                href={url}
                content={`View ${name || t("domains.constraints.documents.placeholder_name")} ${
                  pages?.length && pages[0] !== 1 ? `(p ${pages.join("-")})` : ""
                } ${!!extension ? `(${extension})` : ""}`}
              />
            );
          })}
        </Section>
      )}

      {!!sourceDocuments.length && (
        <Section bodyClasses="atlas-flex atlas-flex-col atlas-px-3 atlas-py-2 atlas-gap-2 atlas-text-sm">
          <h1 className="atlas-font-bold atlas-text-sm atlas-text-content-secondary atlas-block atlas-break-words atlas-my-1">
            {[
              designation.source.authority.name,
              SOURCE_STATUSES.find(({ id }) => id === designation.source.status)?.display_name,
              new Date(designation.source.adoption_date).getFullYear(),
            ].join(" ")}
          </h1>
          {sourceDocuments.map(({ id, url, document_key, document_name }) => (
            <DocumentLink
              key={id}
              href={url}
              title={document_key}
              content={document_name || document_key}
            />
          ))}
        </Section>
      )}
    </div>
  );
}

interface DocumentLinkProps {
  href: string;
  title?: string;
  content: string;
}

function DocumentLink({ href, title, content }: DocumentLinkProps) {
  return (
    <div>
      <a
        className="atlas-text-blue-600 atlas-no-underline atlas-text-sm hover:atlas-no-underline"
        href={href}
        target="_blank"
        rel="noreferrer"
        title={title}
      >
        <span className="atlas-flex-shrink-0 atlas-pr-2 atlas-underline">{content}</span>
        <i className="icon-lt-external-link-line atlas-text-md atlas-flex-shrink-0 "></i>
      </a>
    </div>
  );
}
