import Vue from "vue";
import VueRouter from "vue-router";
import { MapStore } from "./stores/map/store";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
declare global {
  interface Window {
    APP: Vue;
    Cypress?: unknown;
    navigateToMapLocation?: (
      zoom?: number,
      lat?: number,
      lng?: number,
      query?: Record<string, string>
    ) => void;
    navigateToSearchLocation?: (
      zoom?: number,
      lat?: number,
      lng?: number,
      query?: Record<string, string>
    ) => void;
    mapStore?: MapStore;
    setLocale?: (locale: string) => void;
  }
}

export default function setupTestHelpers(router: VueRouter, mapStore: MapStore) {
  if (!window.Cypress) {
    return;
  }
  /*
  When doing functional/e2e tests we expose this method to provide testing code global access to
  page navigation code that doesn't require a full page load which is horrendously slow in CI e2e tests
  */
  window.mapStore = mapStore;

  window.setLocale = (locale: string) => {
    window.APP.$i18n.locale = locale;
  };

  window.navigateToMapLocation = (
    zoom = 15,
    lat = 0,
    lng = 0,
    query: Record<string, string> = {}
  ) => {
    if (!mapStore.googleMap) {
      return router.push({
        path: `map`,
        params: {
          z: String(zoom),
          x: String(lat),
          y: String(lng),
        },
        query,
      });
    }

    const center = mapStore.googleMap?.getCenter();

    if (center?.lat() !== lat || center?.lng() !== lng) {
      mapStore.googleMap?.panTo(new google.maps.LatLng(lat, lng));
    }

    if (mapStore.googleMap?.getZoom() !== zoom) {
      mapStore.googleMap?.setZoom(zoom);
    }

    const allKeys = Array.from(
      new Set([...Object.keys(router.currentRoute.query || {}), ...Object.keys(query)])
    );

    if (allKeys.some((key) => (router.currentRoute.query || {})[key] !== query[key])) {
      router.push({ query });
    }
  };

  window.navigateToSearchLocation = (
    zoom = 15,
    lat = 0,
    lng = 0,
    query: Record<string, string> = {}
  ) => {
    if (!mapStore.googleMap) {
      return router.push({
        path: `legacy-search`,
        params: {
          z: String(zoom),
          x: String(lat),
          y: String(lng),
        },
        query,
      });
    }

    const center = mapStore.googleMap?.getCenter();

    if (center?.lat() !== lat || center?.lng() !== lng) {
      mapStore.googleMap?.panTo(new google.maps.LatLng(lat, lng));
    }

    if (mapStore.googleMap?.getZoom() !== zoom) {
      mapStore.googleMap?.setZoom(zoom);
    }

    const allKeys = Array.from(
      new Set([...Object.keys(router.currentRoute.query || {}), ...Object.keys(query)])
    );

    if (allKeys.some((key) => (router.currentRoute.query || {})[key] !== query[key])) {
      router.push({ query });
    }
  };
}
