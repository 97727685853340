import { snapshot, subscribe } from "valtio/vanilla";
import { localStorageKey, SiteStore, siteStore } from "../store";

// NOTE __ob__ is added by Vue for reactivity
type MapStoreSettings = SiteStore["siteReportConfiguration"] & { __ob__?: unknown };

export const syncSiteReportConfigurationToLocalStorage = () => {
  const siteReportConfiguration = snapshot<MapStoreSettings>(siteStore.siteReportConfiguration);

  window.localStorage.setItem(localStorageKey, JSON.stringify(siteReportConfiguration));
};

export const syncStoreToLocalStorage = () => {
  const siteReportConfiguration = window.localStorage.getItem(localStorageKey);

  if (siteReportConfiguration) {
    siteStore.siteReportConfiguration = JSON.parse(siteReportConfiguration);
  }

  subscribe(siteStore, syncSiteReportConfigurationToLocalStorage);
};
