import { Button } from "@landtechnologies/components";
import classNames from "classnames";
import { useTranslation } from "react-migration/lib/i18n/useTranslation";
import { resetSkipTracingStore } from "../../store/store";
import { router } from "src/js/router/react-router";
import ROUTES from "src/js/router/routes";

export const ErrorPage = () => {
  const { t } = useTranslation();

  const onBackToSitesClicked = async () => {
    await router.push({ name: ROUTES.SITES });
    resetSkipTracingStore();
  };

  return (
    <div
      className={classNames(
        "atlas-h-full",
        "atlas-w-full",
        "atlas-flex",
        "atlas-flex-col",
        "atlas-items-center",
        "atlas-justify-center"
      )}
      data-testid="skip-tracing-error-page"
    >
      <div
        className={classNames(
          "atlas-flex",
          "atlas-w-20",
          "atlas-h-20",
          "atlas-border",
          "atlas-justify-center",
          "atlas-items-center",
          "atlas-rounded-full",
          "atlas-border-background-negative-hover",
          "atlas-bg-background-negative-hover",
          "atlas-drop-shadow-lg",
          "atlas-drop-shadow-background-content-subtle"
        )}
      >
        <i
          className={classNames(
            "icon-lt-close-line",
            "atlas-text-6xl",
            "atlas-flex",
            "atlas-text-white"
          )}
        />
      </div>
      <span
        className={classNames(
          "atlas-my-4",
          "atlas-text-content",
          "atlas-font-semibold",
          "atlas-text-xl",
          "atlas-leading-7"
        )}
      >
        {t("sites.skip_tracing.error.something_went_wrong")}
      </span>
      <div
        className={classNames("atlas-text-base", "atlas-w-[420px]", "atlas-text-center")}
        data-testid="extended-error-message"
      >
        <p className="atlas-text-content-secondary">
          {t("sites.skip_tracing.error.main_error_message")}
        </p>

        <span className="atlas-text-content-secondary">
          {t("sites.skip_tracing.error.if_there_is_still_a_problem_please_contact")}
        </span>
        <a
          href="mailto: support@land.tech?subject=Skip Tracing"
          target="_blank"
          rel="noreferrer"
          className="atlas-whitespace-nowrap"
        >
          {t("sites.skip_tracing.error.customer_support")}
        </a>
      </div>
      <Button.Primary className="atlas-mt-4" onClick={onBackToSitesClicked}>
        {t("sites.skip_tracing.back_to_sites")}
      </Button.Primary>
    </div>
  );
};
