import { useTranslation } from "react-migration/lib/i18n/useTranslation";
import { useRedirectIfNoAccess } from "./hooks/useRedirectIfNoAccess";
import { AlertsCard } from "./components/AlertsCard";

export const OwnershipAlerts = () => {
  useRedirectIfNoAccess();
  const { t } = useTranslation();

  return (
    <div data-testid="ownership-alerts">
      <h2 className="atlas-text-sm atlas-font-medium md:atlas-text-3xl atlas-mb-6">
        {t("dashboard.ownership_alerts.ownership_alerts")}
      </h2>
      <div className="atlas-text-base atlas-mb-4">
        <span>{t("dashboard.ownership_alerts.ownership_info")}</span>
        <a
          href="https://support.land.tech/en/articles/3778042-how-do-i-get-ownership-alerts"
          target="_blank"
          rel="noopener noreferrer"
        >
          {t("dashboard.ownership_alerts.learn_more")}
        </a>
      </div>
      <AlertsCard />
    </div>
  );
};
