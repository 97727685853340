import Feature from "src/js/stores/user/Feature";
import { ConstraintsCategory } from "../../Constraints/types";
import { ConstraintsLayerType } from "../layer_types/ConstraintsLayerType";
import { Layer } from "../types";

export const US_AMENITIES: Layer = {
  id: ConstraintsCategory.US_AMENITIES,
  layerType: ConstraintsLayerType,
  hideByDefault: true,
  description: "Amenities in the local area",
  title: "Amenities",
  requiredFeatureAccess: [Feature.amenitiesLayerUS],
  toggleable: true,
  layerConfig: {
    rootCategory: ConstraintsCategory.US_AMENITIES,
    categorySchema: [
      { key: "us_schools_elementary" },
      { key: "us_schools_middle" },
      { key: "us_schools_high" },
      { key: "us_schools_combination" },
      { key: "us_schools_other" },
      { key: "us_schools_private_elementary" },
      { key: "us_schools_private_secondary" },
      { key: "us_schools_private_combined" },
    ],
  },
};
