import { useQuery } from "@apollo/client";
import { useMemo, useRef } from "react";
import { LandTechEndpoints, routedClient } from "react-migration/lib/persistence/apollo";
import ZOOPLA_LISTINGS_BY_UPRN from "../apollo/zooplaListingsByUPRN.gql";
import { ZooplaListingsDTO } from "../typings/Zoopla";

interface ZooplaListingsHookResponse {
  uprn?: string;
  loading: boolean;
  data?: ZooplaListingsDTO;
}

interface ZooplaListingsResult {
  zooplaListingsByUPRN: ZooplaListingsDTO;
}

export function useZooplaListingsByUPRN({
  uprn,
  skip,
}: {
  uprn?: string;
  skip?: boolean;
}): ZooplaListingsHookResponse {
  const { loading, data, error } = useQuery<ZooplaListingsResult>(ZOOPLA_LISTINGS_BY_UPRN, {
    variables: {
      uprn,
    },
    client: routedClient,
    skip: !uprn || skip,
    context: {
      endpoint: LandTechEndpoints.Gateway,
    },
  });

  const lastResult = useRef(data?.zooplaListingsByUPRN);

  if (data !== lastResult.current) {
    lastResult.current = {
      ...data?.zooplaListingsByUPRN,
      uprn,
    };
  }

  return useMemo(
    () => ({
      loading,
      data: lastResult.current?.uprn === uprn ? lastResult.current : undefined,
      uprn,
      error,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [lastResult.current, loading]
  );
}
