import { SiteStagesQueryData } from "react-migration/domains/sites/typings/SiteStage";
import { routedClient as client } from "react-migration/lib/persistence/apollo";
import { keyBy } from "lodash";
import siteStages from "react-migration/domains/sites/apollo/li-api/queries/siteStages.gql";
import { useMemo } from "react";
import { useQuery } from "@apollo/client";

export const useSiteStages = () => {
  const { data, ...rest } = useQuery<SiteStagesQueryData>(siteStages, {
    client,
    fetchPolicy: "cache-and-network",
  });
  return { stages: data?.siteStages, ...rest };
};

export const useSiteStagesById = () => {
  const { stages } = useSiteStages();
  return useMemo(() => (stages ? keyBy(stages, "_id") : {}), [stages]);
};

interface SiteStageOption {
  id: string;
  name: string;
  color: string;
}

export function useSiteStagesOptions(): SiteStageOption[] {
  const { stages } = useSiteStages();
  return useMemo(
    () =>
      stages?.map((stage) => ({
        id: stage._id,
        name: stage.name,
        color: stage.color,
      })) ?? [],
    [stages]
  );
}
