import { useCallback } from "react";
import { LayerTypeControlPageProps } from "../../types";
import { usePlanningAuthorityLayerTypeContext } from "./PlanningAuthorityContext";
import { NumericInput, Switch } from "@landtechnologies/components";
import clsx from "clsx";
import { ConstraintLayerConfig } from "../ConstraintsLayerType";
import { useLayerStore } from "../ConstraintsLayerType/ConstraintsContext/store";
import { DesignationHatch } from "react-migration/domains/constraints/components/Hatch";

import { ModalV2 } from "react-migration/components/ModalV2/ModalV2";
import { GenericCategoryFilter } from "react-migration/layouts/map/Constraints/GenericCategoryFilter";
import { ConstraintsCategory } from "react-migration/layouts/map/Constraints/types";
import { useControlTracking } from "../../ControlTrackingContext";

export function PlanningAuthorityControlPage({ layer, disabled }: LayerTypeControlPageProps) {
  const { debouncedTrackControlChange } = useControlTracking();

  const {
    presumptionInFavourFilter,
    localPlanAgeFilter,
    housingLandSupplyFilter,
    housingDeliveryTestFilter,
    setPresumptionInFavourFilter,
    setLocalPlanAgeFilter,
    setHousingLandSupplyFilter,
    setHousingDeliveryTestFilter,
    constraintsStore,
    dispatch,
  } = usePlanningAuthorityLayerTypeContext();

  const handlePresumptionInFavourChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      debouncedTrackControlChange({
        filterName: "presumptionInFavour",
        filterState: event.target.checked,
      });
      setPresumptionInFavourFilter(event.target.checked);
    },
    [setPresumptionInFavourFilter, debouncedTrackControlChange]
  );

  const handleLocalPlanAgeChange = useCallback(
    (localPlanAge: number) => {
      debouncedTrackControlChange({ filterName: "localPlanAge", filterState: localPlanAge });
      setLocalPlanAgeFilter(localPlanAge);
    },
    [setLocalPlanAgeFilter, debouncedTrackControlChange]
  );

  const handleHousingLandSupplyChange = useCallback(
    (housingLandSupply: number) => {
      debouncedTrackControlChange({
        filterName: "housingLandSupply",
        filterState: housingLandSupply,
      });
      setHousingLandSupplyFilter(housingLandSupply);
    },
    [setHousingLandSupplyFilter, debouncedTrackControlChange]
  );

  const handleHousingDeliveryTestChange = useCallback(
    (housingDeliveryTest: number) => {
      debouncedTrackControlChange({
        filterName: "housingDeliveryTest",
        filterState: housingDeliveryTest,
      });
      setHousingDeliveryTestFilter(housingDeliveryTest);
    },
    [setHousingDeliveryTestFilter, debouncedTrackControlChange]
  );

  const [layerState, layerActions, layerSelectors] = useLayerStore(layer.id, {
    constraintsStore,
    dispatch,
  });

  const keyModal = (
    <ModalV2.Root>
      <ModalV2.Trigger>
        <i className="icon-lt-question-line atlas-text-lg atlas-text-content-tertiary" />
      </ModalV2.Trigger>
      <ModalV2.Body title="Presumption in favour">
        <div className="atlas-flex atlas-between">
          <div className="atlas-p-3">
            <div className="atlas-flex">
              <div className="atlas-w-9 atlas-h-4 atlas-bg-[#0D9488]" />
              <div className="atlas-ml-2">Presumption in favour is likely to apply</div>
            </div>
            <div className="atlas-flex">
              <div className="atlas-w-9 atlas-h-4 atlas-bg-[#F97316]" />
              <div className="atlas-ml-2">Presumption in favour is unlikely to apply</div>
            </div>
            <div className="atlas-flex">
              <div className="atlas-w-9 atlas-h-4 atlas-bg-[#808080]" />
              <div className="atlas-ml-2">Presumption in favour status is unknown</div>
            </div>
          </div>
        </div>
      </ModalV2.Body>
    </ModalV2.Root>
  );

  const categorySchema = (layer.layerConfig as ConstraintLayerConfig).categorySchema;

  return (
    <>
      <GenericCategoryFilter
        id={ConstraintsCategory.LOCAL_PLANNING_AUTHORITY}
        disabled={disabled}
        categorySchema={categorySchema}
        visibleCategoryIds={layerState.visibleCategories}
        categoryTree={layerSelectors.getCategoryTree()}
        setVisibleCategories={layerActions.setVisibleCategories}
        Hatch={DesignationHatch}
      />
      <div
        className={clsx("atlas-text-base atlas-py-3", {
          "atlas-opacity-50": disabled,
        })}
      >
        Filters
      </div>
      <div className="atlas-flex atlas-justify-between atlas-items-center atlas-mb-2">
        <div
          className={clsx("atlas-font-semibold atlas-flex atlas-items-center atlas-gap-x-2", {
            "atlas-opacity-50": disabled,
          })}
        >
          <span>Presumption in favour</span>
          {keyModal}
        </div>
        <div className="atlas-flex atlas-items-center atlas-gap-2">
          <Switch
            label="Presumption in favour"
            id="presumption-in-favour"
            disabled={disabled}
            onToggle={handlePresumptionInFavourChange}
            value={presumptionInFavourFilter}
          />
        </div>
      </div>

      <div className="atlas-flex atlas-justify-between atlas-items-center atlas-mb-2">
        <div
          className={clsx("atlas-font-semibold", {
            "atlas-opacity-50": disabled,
          })}
        >
          Local plans older than
        </div>
        <div className="atlas-flex atlas-items-center atlas-gap-2">
          <NumericInput
            className="atlas-max-w-[80px]"
            size="small"
            value={localPlanAgeFilter}
            minValue={0}
            maxValue={10}
            onChange={handleLocalPlanAgeChange}
            isDisabled={disabled}
            placeholder="any"
          />
          <div className="atlas-opacity-50">years</div>
        </div>
      </div>

      <div className="atlas-flex atlas-justify-between atlas-items-center atlas-mb-2">
        <div
          className={clsx("atlas-font-semibold", {
            "atlas-opacity-50": disabled,
          })}
        >
          Years of housing land supply
        </div>
        <div className="atlas-flex atlas-items-center atlas-gap-2">
          <NumericInput
            className="atlas-max-w-[80px]"
            size="small"
            value={housingLandSupplyFilter}
            minValue={0}
            maxValue={10}
            onChange={handleHousingLandSupplyChange}
            isDisabled={disabled}
            placeholder="any"
          />
          <div className="atlas-opacity-50">years</div>
        </div>
      </div>

      <div className="atlas-flex atlas-justify-between atlas-items-center atlas-mb-2">
        <div
          className={clsx("atlas-font-semibold", {
            "atlas-opacity-50": disabled,
          })}
        >
          Housing delivery test
        </div>
        <div className="atlas-flex atlas-items-center atlas-gap-2">
          <NumericInput
            className="atlas-max-w-[80px]"
            size="small"
            value={housingDeliveryTestFilter}
            minValue={0}
            maxValue={1000}
            onChange={handleHousingDeliveryTestChange}
            isDisabled={disabled}
            placeholder="any"
          />
          <div className="atlas-opacity-50 atlas-pr-6">%</div>
        </div>
      </div>
    </>
  );
}
