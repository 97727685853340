import { CSVLink } from "react-csv";
import { dateIsoString } from "src/js/util/dates";
import { logEvent } from "react-migration/lib/util/logEvent";

import { useOwnershipViewsQuery } from "../hooks/useOwnershipViewsQuery";

const PER_PAGE = 500;

export interface OwnershipViewsProps {
  fromDate: string;
}

export const OwnershipViews = ({ fromDate }: OwnershipViewsProps) => {
  const { data, error, loading } = useOwnershipViewsQuery({
    page: 0,
    perPage: PER_PAGE,
    fromDate,
  });

  const downloadOwnershipClicksCSV = () => {
    logEvent("Dashboard ownership clicks CSV export", {
      results: data?.ownershipViews?.nodes.length,
    });
  };

  const csvData = data?.ownershipViews?.nodes?.map((r) => ({
    "clicked on": r.created,
    title: r.title,
  }));

  return (
    !loading &&
    !error && (
      <CSVLink
        onClick={() => downloadOwnershipClicksCSV()}
        data={csvData || []}
        data-testid="ownership-views-csv-export"
        filename={`LandInsight Ownership Views Export (${dateIsoString(Date.now())}).csv`}
      >
        <i className="icon-lt-download-2-line" />
      </CSVLink>
    )
  );
};
