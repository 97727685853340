import { Color } from "deck.gl";

/**
 * @param color Deck.gl RGBAColor array
 * @param alpha Alpha value from 0 to 1 (will be multiplied by 255 for you)
 * @returns Deck.gl RGBAColor array
 */
export function setRGBAAlpha(color: Color, alpha: number): Color {
  return [color[0], color[1], color[2], alpha * 255];
}

/**
 * @param color Deck.gl RGBAColor array
 * @param alpha Alpha value from 0 to 1 (will be multiplied by 255 for you)
 * @returns Deck.gl RGBAColor array
 */
export function multRGBAAlpha(color: Color, alpha: number): Color {
  return [color[0], color[1], color[2], alpha * (color[3] ?? 255)];
}
