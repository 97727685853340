import type { LayerType, SelectionHandler } from "../../types";
import { DrawingsLayerTypeProvider } from "./DrawingsContext";
import { DrawingsDetailHeader } from "./DrawingsDetailHeader";
import { DrawingsMapLayer } from "./DrawingsMapLayer";
import { DrawingsSelectionTitle } from "./DrawingsSelectionTitle";
import { DrawingsSelectionView } from "./DrawingsSelectionView";

export const DrawingsLayerType: LayerType = {
  MapLayer: DrawingsMapLayer,
  Provider: DrawingsLayerTypeProvider,
};

export const AnnotationsSelectionHandler: SelectionHandler = {
  SelectionTitle: DrawingsSelectionTitle,
  SelectionHeader: DrawingsDetailHeader,
  SelectionDetailView: DrawingsSelectionView,
};
