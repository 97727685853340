export const animationVariants = {
  open: {
    y: 0,
    opacity: 1,
  },
  collapsed: {
    y: "-1%",
    opacity: 0,
  },
};
