import clsx from "clsx";
import { FC, PropsWithChildren } from "react";
import { useTranslation } from "react-migration/lib/i18n/useTranslation";

export interface DetailsListItemProps extends PropsWithChildren {
  label?: string;
}

const DetailsListItem: FC<DetailsListItemProps> = ({ label, children }) => {
  const { t, checkKey } = useTranslation();
  const labelText = label && checkKey(label) ? t(label) : label;

  return (
    <li className="atlas-grid atlas-grid-cols-6 atlas-gap-2">
      <div className="atlas-col-span-2 atlas-font-semibold atlas-text-xs atlas-text-content-secondary atlas-m-0 atlas-block atlas-break-words">
        {labelText}
      </div>
      <div className="atlas-col-span-4 atlas-text-xs atlas-text-content-secondary atlas-break-words">
        {children}
      </div>
    </li>
  );
};

export const DetailsList: FC<PropsWithChildren<{ className?: string }>> & {
  Item: typeof DetailsListItem;
} = ({ children, className }) => (
  <ul className={clsx("atlas-flex atlas-flex-col atlas-gap-3 atlas-list-none", className)}>
    {children}
  </ul>
);

DetailsList.Item = DetailsListItem;
