import Feature from "src/js/stores/user/Feature";
import { ConstraintsCategory } from "../../Constraints/types";
import { ConstraintsLayerType } from "../layer_types/ConstraintsLayerType";
import { Layer } from "../types";

export const LAND_HUB_LAYER: Layer = {
  id: ConstraintsCategory.OTHER_OPPORTUNITIES,
  layerType: ConstraintsLayerType,
  title: "Land Hub (Homes England)",
  requiredFeatureAccess: [Feature.planningPolicyLayer],
  toggleable: true,
  description: "Sites available for disposal through Homes England",
  hideByDefault: true,
  layerConfig: {
    rootCategory: ConstraintsCategory.OTHER_OPPORTUNITIES,
    categorySchema: [
      { key: "land_hub_pipeline" },
      { key: "land_hub_on_market" },
      { key: "land_hub_sold_stc" },
    ],
  },
};
