import { ISourcingToolSearchFilter } from "react-migration/domains/search/typings/SourcingToolSpecificFilterTypes";
import { SearchType } from "react-migration/domains/search/constants/searchTypes";

export const LeaseStartDateFilter: ISourcingToolSearchFilter = {
  id: "lease-start-date",
  type: "date-range",
  field: "sidebar.react.search_sidebar.lease_start_date",
  range: [null, null],
  granularity: "month",
  updateFilterDialogIsVisible: false,
  searchTypes: [SearchType.SOURCING_TOOL_UK],
};
