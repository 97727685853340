import { HTMLProps } from "react";

export interface ZooplaRecordSubItemProps extends HTMLProps<HTMLDivElement> {
  title: string;
}
export const ZooplaRecordSubItem = ({
  title,
  className,
  children,
  style,
}: ZooplaRecordSubItemProps) => {
  return (
    <div className={className} data-testid="zoopla-record-sub-item" style={style}>
      <span>{title}: </span>
      <span className="atlas-pl-1 atlas-font-bold">{children}</span>
    </div>
  );
};
