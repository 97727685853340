import { useCallback, useEffect, useMemo, useRef } from "react";

import { useAddLabelMutation } from "../SaveSiteButton/useAddLabel";
import { useArchiveLabel } from "../SaveSiteButton/useArchiveLabel";
import { useCreateLabel } from "../SaveSiteButton/useCreateLabel";
import { useRemoveLabelMutation } from "../SaveSiteButton/useRemoveLabel";
import { useUpdateLabel } from "../SaveSiteButton/useUpdateLabel";
import { useAllSiteLabelsQuery } from "@gql/li-api/graphql";
import { routedClient } from "react-migration/lib/persistence/apollo/routedClient";
import { z } from "zod";

const LabelsSchema = z.array(z.object({ _id: z.string(), name: z.string() })).optional();

interface UseLabelsProps {
  siteId: string;
  startedSaving?(): void;
  stoppedSaving?(action: string): void;
}

/**
 * A hook which wraps apollo queries and mutations for site labels for convenience
 *
 * Mutations here are idempotent in the backend and can be called safely multiple times
 */
export const useLabels = ({ siteId, startedSaving, stoppedSaving }: UseLabelsProps) => {
  const activeActionRef = useRef("");

  const allLabelsResponse = useAllSiteLabelsQuery({
    client: routedClient,
  });

  const allLabels = useMemo(() => {
    const sanitisedLabels = LabelsSchema.parse(allLabelsResponse.data?.siteLabels) ?? [];

    const labels = sanitisedLabels.map((label) => {
      return { id: label._id, name: label.name };
    });

    return labels;
  }, [allLabelsResponse]);

  const { addLabelMutation, loading: addLabelLoading } = useAddLabelMutation();
  const { removeLabelMutation, loading: removeLabelLoading } = useRemoveLabelMutation();
  const { createLabelMutation: createLabel, loading: createLabelLoading } = useCreateLabel();
  const { updateLabelMutation: updateLabel, loading: updateLabelLoading } = useUpdateLabel();
  const { archiveLabelMutation: archiveLabel, loading: archiveLabelLoading } = useArchiveLabel();

  useEffect(() => {
    if (addLabelLoading) {
      activeActionRef.current = "add";
    } else if (removeLabelLoading) {
      activeActionRef.current = "remove";
    } else if (createLabelLoading) {
      activeActionRef.current = "create";
    } else if (updateLabelLoading) {
      activeActionRef.current = "update";
    } else if (archiveLabelLoading) {
      activeActionRef.current = "archive";
    } else if (activeActionRef.current) {
      stoppedSaving?.(activeActionRef.current);
      activeActionRef.current = "";
    }

    if (
      addLabelLoading ||
      removeLabelLoading ||
      createLabelLoading ||
      updateLabelLoading ||
      archiveLabelLoading
    ) {
      startedSaving?.();
    }
  }, [
    addLabelLoading,
    removeLabelLoading,
    createLabelLoading,
    updateLabelLoading,
    archiveLabelLoading,
    startedSaving,
    stoppedSaving,
  ]);

  const addLabel = (labelId: string) => {
    addLabelMutation({ siteId, labelId });
  };

  const removeLabel = useCallback(
    (labelId: string) => {
      removeLabelMutation({ siteId, labelId });
    },
    [siteId, removeLabelMutation]
  );

  return {
    /** The full set of site labels availble to the team */
    allLabels,
    addLabel,
    removeLabel,
    createLabel,
    updateLabel,
    archiveLabel,
  };
};
