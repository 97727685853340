import { NotAvailable, getValueAndLogMissingField } from "./ZooplaSection";
import { ZooplaRecordSubItem } from "../ZooplaRecordSubItem";
import { ZooplaField, ZooplaSubField } from "react-migration/domains/comparables/typings/Zoopla";

export function ZooplaRecordSubFields({
  subfields,
  parentField,
}: Readonly<{
  subfields: ZooplaSubField[];
  parentField: ZooplaField;
}>) {
  const valueCount = subfields.filter(({ value }) => value !== null);

  if (valueCount.length === 0) {
    getValueAndLogMissingField({ ...parentField, value: null });
    return NotAvailable;
  }

  return (
    <>
      {subfields.map((subField: ZooplaSubField) => (
        <ZooplaRecordSubItem
          title={subField.title}
          key={subField.title}
          className={
            subfields?.length > 3 ? "atlas-inline-block atlas-w-1/2" : "atlas-flex atlas-flex-row"
          }
        >
          {getValueAndLogMissingField(subField, parentField)}
        </ZooplaRecordSubItem>
      ))}
    </>
  );
}
