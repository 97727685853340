import type { LayerTypeSelectionSidebarProps } from "../../../types";
import { OwnershipSelectionSidebarUK } from "./OwnershipSelectionSidebarUK";
import { OwnershipSelectionSidebarUS } from "./OwnershipSelectionSidebarUS";
import { SelectionType } from "src/js/stores/map/store";
import { getAccount } from "react-migration/domains/dashboard/topup/hooks/useAccountQuery";
import { useEffect } from "react";
import { logEventV2 } from "react-migration/lib/util/logEventV2";
import hasFeature from "src/js/stores/user/actions/hasFeature";
import Feature from "src/js/stores/user/Feature";

const logOwnershipClicks = () => {
  getAccount().then((account) => {
    const { accountWithViews } = account;
    logEventV2({
      name: "Ownership Views Run Out",
      properties: {
        views: accountWithViews.ownership_views.used,
        cap: accountWithViews.ownership_views.cap,
        hasHitCap: accountWithViews.ownership_views.used >= accountWithViews.ownership_views.cap,
      },
    });
  });
};

export function OwnershipSelectionSidebar(props: LayerTypeSelectionSidebarProps) {
  const isUSUser = hasFeature(Feature.usAccess);
  const hasUnlimitedOwnershipClicks = hasFeature(Feature.unlimitedOwnershipClicks);

  useEffect(() => {
    !hasUnlimitedOwnershipClicks && logOwnershipClicks();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isUSUser) {
    return <OwnershipSelectionSidebarUS {...props} />;
  } else {
    if (
      props.selection.type !== SelectionType.OWNERSHIP_TITLE &&
      (hasUnlimitedOwnershipClicks || props.selection.type === SelectionType.SITE)
    ) {
      // In the UK we show a custom owner list in the title-detial view, so don't show it again for title selections.
      return <OwnershipSelectionSidebarUK {...props} />;
    }
  }

  return null;
}
