import { memo, useCallback } from "react";

import type { SiteOverview } from "../../typings/Site";
import { AvatarsSelect } from "../../../../components/AvatarsSelect";

import { useUpdateSiteMutation } from "../SaveSiteButton/useUpdateSiteMutation/useUpdateSiteMutation";
import { useSiteAssigneeOptions } from "../../hooks/useSiteAssigneeOptions";

export interface EditableSiteAssigneeProps {
  site: Pick<SiteOverview, "_id" | "assignees">;
  truncateCount: number;
  startedSaving?(): void;
  stoppedSaving?({
    source,
    success,
    action,
  }: {
    source: string;
    success: boolean;
    action: string;
  }): void;
}

export const EditableSiteAssignees = memo(
  ({
    site: { _id: siteId, assignees },
    truncateCount,
    startedSaving,
    stoppedSaving,
  }: EditableSiteAssigneeProps) => {
    const handleStopSaving = useCallback(
      (action: string, success: boolean) => {
        stoppedSaving?.({
          source: "Assignees",
          success: success,
          action,
        });
      },
      [stoppedSaving]
    );

    const allAssignees = useSiteAssigneeOptions();
    const [updateSite, { loading: saving }] = useUpdateSiteMutation();

    return (
      <AvatarsSelect
        selectedAvatars={assignees}
        allAvatars={allAssignees}
        disabled={saving}
        onChange={(assigneeIds) => {
          startedSaving?.();
          updateSite({
            variables: { updateData: { _id: siteId, assignees: assigneeIds } },
            onCompleted: () => handleStopSaving("update", true),
            onError: () => handleStopSaving("update", false),
          });
        }}
        placeholder="Assign users"
        truncateCount={truncateCount}
      />
    );
  }
);

EditableSiteAssignees.displayName = "EditableSiteAssignees";
