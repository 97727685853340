import Feature from "src/js/stores/user/Feature";
import { ConstraintsCategory } from "../../Constraints/types";
import { ConstraintsLayerType } from "../layer_types/ConstraintsLayerType";
import { Layer } from "../types";

export const AGRICULTURAL_LAND: Layer = {
  id: ConstraintsCategory.AGRICULTURAL_LAND,
  layerType: ConstraintsLayerType,
  title: "Agricultural Land Classification",
  requiredFeatureAccess: [Feature.powerLayer, Feature.planningPolicyLayer],
  toggleable: true,
  description: "Gradings of land for agricultural use could limit potential development",
  hideByDefault: true,
  layerConfig: {
    rootCategory: ConstraintsCategory.AGRICULTURAL_LAND,
    categorySchema: [
      { key: "al_grade_1" },
      { key: "al_grade_2" },
      { key: "al_grade_3a" },
      { key: "al_grade_3" },
      { key: "al_grade_3b" },
      { key: "al_grade_4" },
      { key: "al_grade_5" },
      { key: "al_urban" },
      { key: "al_non_agricultural" },
      { key: "al_exclusion" },
      { key: "al_non-graded" },
      { key: "al_not_surveyed" },
      { key: "al_other" },
      { key: "al_scot_grade_1" },
      { key: "al_scot_grade_2" },
      { key: "al_scot_grade_3_1" },
      { key: "al_scot_grade_3_2" },
      { key: "al_scot_grade_4" },
      { key: "al_scot_grade_5" },
      { key: "al_scot_grade_6" },
      { key: "al_scot_grade_7" },
      { key: "al_scot_grade_888" },
      { key: "al_scot_grade_999" },
      { key: "al_scot_grade_9500" },
    ],
  },
};
