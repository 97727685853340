import { mapStore as state } from "../../stores/map/store";
import * as methods from "../../stores/map/actions/updateDeckSettings";
import { createDeckGLInstance, initDeckglInstance, destroyDeckInstance } from "./deckInstance";
import { throwError } from "./utils";
import { Layer } from "@deck.gl/core";

const shareState = (ctx, debug) => {
  if (ctx.$options.deckgl) {
    createDeckGLInstance(methods.onClickHandler);

    ctx.$options.computed = ctx.$options.computed || {};
    ctx.$options.methods = ctx.$options.methods || {};
    if (debug) {
      // Register all store values as computed properties
      Object.keys(state).forEach((key) => {
        if (!ctx.$options.computed[key]) {
          ctx.$options.computed[key] = {
            get: () => state[key],
            set: (value) => {
              state[key] = value;
            },
          };
        }
      });
    }

    // Add all methods for deckgl to the component
    Object.keys(methods).forEach((fnc) => {
      if (!ctx.$options.methods[fnc]) {
        ctx.$options.methods[fnc] = methods[fnc];
      }
    });

    // Add a method
    if (!ctx.$options.methods.updateLayer) {
      ctx.$options.methods.updateLayer = function () {
        if (this.$options.deckgl.layers) {
          this.$options.deckgl.layers.call(this).forEach((layer) => {
            methods.upsertDeckLayer({ ...layer });
          });
        }
      };
    }
  }
};

// Load in all the layers and the click listeners
const initData = (ctx) => {
  const options = ctx.$options;
  const settings = options.deckgl;

  if (settings) {
    if (settings.layers) {
      settings.layers.call(ctx).forEach((layer) => {
        if (!layer.key) throwError("Please provide a key for your layer");
        if (!layer.layer || !(layer.layer instanceof Layer))
          throwError("Please provide a deck.gl layer instance");
        methods.addDeckLayer(layer);
      });
    }
    if (settings.clickCallbacks) {
      settings.clickCallbacks.call(ctx).forEach((c) => {
        methods.addClickListener(c);
      });
    }
  }
};

export default {
  /**
   * Install deckGl plugin
   *
   * @param {Vue} Vue
   * @param {Object} options
   */
  install: (vue, options = { debug: false }) => {
    vue.prototype.destroyDeckGl = destroyDeckInstance;
    vue.destroyDeckGl = destroyDeckInstance;

    vue.mixin({
      beforeCreate() {
        if (!this.$options.deckgl) return;

        shareState(this, options.debug);
      },
      created() {
        if (!this.$options.deckgl) return;

        initData(this);
        const map = state.googleMap;

        if (!map && !this.map) {
          console.error("Deck.Gl - Please provide a basemap");
        } else {
          initDeckglInstance(map ? map : this.map);
          state.deckInitialised = true;
        }
      },
      destroyed() {
        if (!this.$options.deckgl) return;

        const settings = this.$options.deckgl;

        settings.layers?.call(this).forEach((l) => {
          methods.removeDeckLayer(l.key);
        });
      },
    });

    vue.$deckgl = {
      ...state,
      ...methods,
    };

    vue.prototype.$deckgl = {
      ...state,
      ...methods,
    };
  },
};
