import { SingleDesignation } from "react-migration/domains/constraints/typings/applicationTypes/SingleDesignation";
import { LayerState } from "./store";
import { DesignationFeatureProps } from "react-migration/domains/constraints/components/ConstraintLayer/DesignationLayer";

export const apiDesignationIsVisible = (
  designation: SingleDesignation,
  layerState: LayerState | undefined
) => {
  const { visibleCategories, hiddenStatuses, hiddenDesignationAttributes } = layerState || {};

  if (!visibleCategories?.includes(designation.sub_category_id!)) return false;

  return (
    !hiddenStatuses?.includes(designation.source.status!) &&
    (!designation.designation_attributes ||
      !designation.designation_attributes?.some((a) =>
        hiddenDesignationAttributes?.includes(a.value!)
      ))
  );
};

export const tileDesignationIsVisible = (
  designation: DesignationFeatureProps,
  layerState: LayerState | undefined
) => {
  const { visibleCategories, hiddenStatuses, hiddenDesignationAttributes } = layerState || {};

  if (!visibleCategories?.includes(designation.sub_category_id)) return false;

  return (
    !hiddenStatuses?.includes(designation.status!) &&
    (!designation.designation_attributes ||
      !designation.designation_attributes.some((a) =>
        hiddenDesignationAttributes?.includes(a.value!)
      ))
  );
};
