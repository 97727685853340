/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/ban-ts-comment */

import type { DefaultProps } from "@deck.gl/core";
import type { LayerProps } from "@deck.gl/core/dist/types/layer-props";
import { GeoJsonLayer, GeoJsonLayerProps, SolidPolygonLayerProps } from "@deck.gl/layers";

import {
  defaultProps,
  GradientFeature,
  GradientPolygonLayer,
  GradientPolygonLayerProps,
} from "./GradientPolygonLayer";

import {
  GradientScatterplotLayer,
  GradientScatterplotLayerProps,
} from "./GradientScatterplotLayer";

const POLYGON_FILL_SUBLAYER_ID = "polygons-fill";
const POLYGON_STROKE_SUBLAYER_ID = "polygons-stroke";
const CIRCLE_SUBLAYER_ID = "points-circle";

export type GradientGeoJsonLayerProps<D> = GeoJsonLayerProps<D> &
  Pick<GradientScatterplotLayerProps<D>, "getPointGradientStop"> &
  Omit<GradientPolygonLayerProps<D>, keyof SolidPolygonLayerProps<D>> & { polygonOpacity?: number };

export class GradientGeoJsonLayer<
  D extends GradientFeature,
  P extends GradientGeoJsonLayerProps<D> = GradientGeoJsonLayerProps<D>
> extends GeoJsonLayer<D["properties"], P> {
  static readonly componentName = "GradientGeoJsonLayer";
  static readonly defaultProps = defaultProps as DefaultProps<GeoJsonLayerProps>;

  constructor(props: P, ...otherProps: P[]) {
    // @ts-expect-error there's some partial vs. required type here that are difficult to reconcile
    super(props, ...otherProps, {
      _subLayerProps: {
        [POLYGON_FILL_SUBLAYER_ID]: {
          type: GradientPolygonLayer,
          getGradientStopColor: props.getGradientStopColor,
          getPolygonBounds: props.getPolygonBounds,
          opacity: props.polygonOpacity || 1,
        },
        [POLYGON_STROKE_SUBLAYER_ID]: {
          opacity: props.polygonOpacity || 1,
        },
        [CIRCLE_SUBLAYER_ID]: {
          type: GradientScatterplotLayer,
          getPointGradientStop: props.getPointGradientStop,
        },
      },
    } as unknown as P);
  }

  public getSubLayerProps(props: any = {}): any {
    let subLayerProps = super.getSubLayerProps(props);

    if (props.id === POLYGON_FILL_SUBLAYER_ID) {
      subLayerProps = {
        ...subLayerProps,
        getGradientStopColor: getSublayerAccessor("getGradientStopColor", subLayerProps),
        getPolygonBounds: getSublayerAccessor("getPolygonBounds", subLayerProps),
        updateTriggers: {
          ...subLayerProps.updateTriggers,
          getGradientStopColor: this.props.updateTriggers.getGradientStopColor,
        },
      };
    } else if (props.id === CIRCLE_SUBLAYER_ID) {
      subLayerProps = {
        ...subLayerProps,
        getPointGradientStop: getSublayerAccessor("getPointGradientStop", subLayerProps),
        updateTriggers: {
          ...subLayerProps.updateTriggers,
          getPointGradientStop: this.props.updateTriggers.getPointGradientStop,
        },
      } as LayerProps;
    }

    return subLayerProps;
  }
}

type WrappedSublayerAccessor = (d: { __source: { object: GradientFeature } }) => unknown;
// Deck.gl doesn't seem to want to unwrap the datums when calling some custom
// sublayer accessors, this is a workaround. It wraps the accessor - passed by
// propName - in a function which unpacks the datum for you.
function getSublayerAccessor(propName: string, sublayerProps: LayerProps) {
  // @ts-ignore these are available on LayerProps, just not typed
  const bareAccessor = sublayerProps[propName] || sublayerProps.type.defaultProps[propName].value;

  return typeof bareAccessor === "function"
    ? // @ts-expect-error shhhhhhh
      (d) => bareAccessor(d.__source.object) as WrappedSublayerAccessor
    : bareAccessor;
}
