import { ChangeEvent, useCallback } from "react";
import clsx from "clsx";
import { useTranslation } from "react-migration/lib/i18n/useTranslation";
import { LayerTypeControlPageProps } from "../../types";
import { useIsochroneLayerTypeContext } from "./IsochroneContext";
import { RoutingTime, RoutingType } from "./types";
import { useControlTracking } from "../../ControlTrackingContext";

export function IsochroneControlPage({ disabled }: LayerTypeControlPageProps) {
  const { t } = useTranslation();
  const { trackControlChange } = useControlTracking();

  const { routingType, setRoutingType, routingTime, setRoutingTime, fetching } =
    useIsochroneLayerTypeContext();

  const setRoutingTypeHandler = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      trackControlChange({ filterName: "routingType", filterState: e.currentTarget.value });
      setRoutingType(e.currentTarget.value as RoutingType);
    },
    [setRoutingType, trackControlChange]
  );

  const setRoutingTimeHandler = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      trackControlChange({ filterName: "routingTime", filterState: e.currentTarget.value });
      setRoutingTime(e.currentTarget.value as RoutingTime);
    },
    [setRoutingTime, trackControlChange]
  );

  return (
    <article
      className={clsx(
        "atlas-p-2 atlas-rounded atlas-bg-white atlas-flex atlas-flex-col atlas-gap-y-1",
        disabled && "atlas-pointer-events-none atlas-grayscale"
      )}
    >
      <section
        className={clsx("atlas-flex atlas-flex-col atlas-gap-y-1", disabled && "atlas-opacity-50")}
      >
        <h1 className="atlas-text-sm atlas-text-content-primary">
          {t("distance_and_travel.control_page.routing_type.header")}
        </h1>
        <div className="atlas-flex atlas-gap-x-1">
          {Object.values(RoutingType).map((type) => (
            <div key={type} className="atlas-pl-2 atlas-flex atlas-items-center atlas-gap-x-1">
              <input
                type="radio"
                disabled={disabled || fetching}
                id={type}
                name={type}
                value={type}
                checked={type === routingType}
                onChange={setRoutingTypeHandler}
              />
              <label htmlFor={type} className="atlas-text-content-secondary atlas-text-sm">
                {t(`distance_and_travel.control_page.routing_type.label.${type}`)}
              </label>
            </div>
          ))}
        </div>
      </section>

      <section
        className={clsx("atlas-flex atlas-flex-col atlas-gap-y-1", disabled && "atlas-opacity-50")}
      >
        <h1 className="atlas-text-sm atlas-text-content-primary">
          {t("distance_and_travel.control_page.routing_time.header")}
        </h1>
        <div className="atlas-flex atlas-gap-x-1">
          {Object.values(RoutingTime).map((time) => (
            <div
              key={time}
              className="atlas-pl-2 atlas-flex atlas-items-center atlas-gap-x-1 atlas-capitalize"
            >
              <input
                type="radio"
                disabled={disabled || fetching}
                id={time}
                name={time}
                value={time}
                checked={time === routingTime}
                onChange={setRoutingTimeHandler}
              />
              <label htmlFor={time} className="atlas-text-content-secondary atlas-text-sm">
                {t(`distance_and_travel.control_page.routing_time.label.${time}`)}
              </label>
            </div>
          ))}
        </div>
      </section>
      {fetching && <div className="atlas-absolute atlas-inset-0 atlas-cursor-wait" />}
    </article>
  );
}
