import isString from "lodash/isString";
import omit from "lodash/omit";
import pick from "lodash/pick";
import cloneDeep from "lodash/cloneDeep";
import ObjUtils from "util/object_util";
import SearchUtil from "util/search";

export function esSortToGraphQLEsSort(esSortList) {
  if (!esSortList) return null;
  return esSortList.map((esSort) => {
    if (isString(esSort)) {
      return {
        fieldName: esSort,
      };
    } else {
      const key = Object.keys(esSort)[0];
      const body = esSort[key];

      if (isString(body)) {
        return {
          fieldName: key,
          sortType: {
            order: body,
          },
        };
      } else if (key === "_geo_distance") {
        const fieldName = Object.keys(omit(body, "order", "unit", "mode", "distance_type"))[0];
        return {
          fieldName,
          sortType: {
            order: body.order,
            _geo_distance: Object.assign(pick(body, "unit", "mode", "distance_type"), {
              location: body[fieldName],
            }),
          },
        };
      } else {
        return {
          fieldName: key,
          sortType: pick(body, "order", "missing"),
        };
      }
    }
  });
}

export function searchToEsSearchInput(search) {
  let santitizedSearch = omit(cloneDeep(search), "_id", "created", "modified");
  santitizedSearch = ObjUtils.omitDeep(santitizedSearch, "__typename");
  if (santitizedSearch.geo_filter) {
    const typeToKeyMap = {
      BoundingBox: "bounding_box_geo_filter",
      Polygon: "poly_geo_filter",
      Radius: "radius_geo_filter",
    };
    const geoFilterKey = typeToKeyMap[SearchUtil.getGeoFilterType(santitizedSearch.geo_filter)];
    santitizedSearch[geoFilterKey] = santitizedSearch.geo_filter;
  }
  if (santitizedSearch.parcel_size_range) {
    santitizedSearch.parcel_size_range = {
      from: parseInt(santitizedSearch.parcel_size_range?.from),
      to: parseInt(santitizedSearch.parcel_size_range?.to),
    };
  }
  if (santitizedSearch.total_area_range) {
    santitizedSearch.total_area_range = {
      from: parseInt(santitizedSearch.total_area_range?.from),
      to: parseInt(santitizedSearch.total_area_range?.to),
    };
  }

  delete santitizedSearch.geo_filter;
  return santitizedSearch;
}
