import { createContext, useContext, useMemo } from "react";
import { Layer, LayerTypeProviderProps } from "../../../types";
import { useConstraintsStore } from "./store";
import type { ConstraintsStore, ConstraintsStoreDispatch } from "./store";
import { ConstraintsLayer } from "../types";
import { CONSTRAINTS_LAYER_TYPE_ID } from "../constants";

export const ConstraintsLayerTypeContext = createContext({} as ConstraintsLayerTypeContextProps);

export const useConstraintsLayerTypeContext = () => useContext(ConstraintsLayerTypeContext);

const isConstraintsLayerType = (l: Layer): l is ConstraintsLayer =>
  l.layerType.id === CONSTRAINTS_LAYER_TYPE_ID;

interface ConstraintsLayerTypeContextProps {
  constraintsStore: ConstraintsStore;
  dispatch: ConstraintsStoreDispatch;
}

export function ConstraintsLayerTypeProvider({
  id,
  children,
  layers,
  isWorkbench,
}: LayerTypeProviderProps) {
  const LOCAL_STORAGE_KEY = `${id}-constraints-bundle`;
  const constraintsLayers = useMemo(
    () =>
      layers
        .filter(isConstraintsLayerType)
        .map((layer) => (isWorkbench ? applyDefaultVisibility(layer, true) : layer)),
    [isWorkbench, layers]
  );
  const [constraintsStore, dispatch] = useConstraintsStore(LOCAL_STORAGE_KEY, constraintsLayers);

  return (
    <ConstraintsLayerTypeContext.Provider
      value={{
        dispatch,
        constraintsStore,
      }}
    >
      {children}
    </ConstraintsLayerTypeContext.Provider>
  );
}

function applyDefaultVisibility(layer: ConstraintsLayer, value: boolean) {
  if (!layer.layerConfig) return layer;

  return {
    ...layer,
    layerConfig: {
      ...layer.layerConfig,
      categorySchema: layer.layerConfig.categorySchema.map((category) => ({
        ...category,
        defaultValue: value,
      })),
    },
  };
}
