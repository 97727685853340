import { useTranslation } from "react-migration/lib/i18n/useTranslation";
import { PlanningAlert } from "../../typings/PlanningAlert";
import { BackIcon } from "../icons/BackIcon";
import { handleRedirectToAlerts } from "../util/redirects";

type BackToAlertsProps = {
  planningAlert: PlanningAlert;
};

export const BackToAlerts = ({ planningAlert }: BackToAlertsProps) => {
  const { t } = useTranslation();
  return (
    <div className="atlas-mb-4 atlas-flex atlas-items-center">
      <BackIcon />
      <span
        onClick={() => handleRedirectToAlerts(planningAlert)}
        className="atlas-font-semibold atlas-text-blue-600 atlas-cursor-pointer"
      >
        {t("dashboard.planning_alerts.back_to_all_alerts")}
      </span>
    </div>
  );
};
