import { ConstraintsCategory } from "react-migration/layouts/map/Constraints/types";
import type { GenericLayer } from "../../types";
import { TupleOf } from "react-migration/lib/util/TupleOf";

export type CheckboxSchema = { key: string; showChildren?: boolean; defaultValue?: boolean };

type AttributeFilterOption = { value: string; display_name?: string };

export type AttributeFilter = {
  attribute: string;
  options: AttributeFilterOption[];
};

export type ZoomPointScale = {
  zoom: TupleOf<number, 2>;
  pointScale: TupleOf<number, 2>;
};

export type CollisionFilter = {
  maxZoom?: number;
  /**
   * Maps designation feature properties to a numeric value to decide which feature should be displayed when they overlap.
   * Features have all matching values added together and must be in the -1000 - 1000 range once calculated. If no matching value is
   * available for any path in the map it defaults to a value of 0 for that item in the map
   */
  collisionPriorityMap?: Record<string, number | Record<string, number | Record<string, number>>>;
  collisionGroup?: string;
  collisionTestProps?: Record<string, unknown>;
};

export type ConstraintLayerConfig = {
  rootCategory: ConstraintsCategory;
  categorySchema: CheckboxSchema[];
  attributeFilters?: AttributeFilter[];
  statusSchema?: CheckboxSchema[];
  showConstraintLabels?: boolean;
  disableHatch?: boolean;
  showTitle?: boolean;
  minZoom?: number;
  maxZoom?: number;
  dedupePoints?: boolean;
  filterAreaByZoom?: boolean;
  zoomPointScale?: ZoomPointScale;
  intersectionThresholdMeters?: number;
  simplifyProfile?: SimplifyProfile;
  layerRoots?: string[];
  collisionFilter?: CollisionFilter;
};

export type ConstraintsLayer = GenericLayer<ConstraintLayerConfig>;

export enum SimplifyProfile {
  LOW = "LOW",
  HIGH = "HIGH",
}
