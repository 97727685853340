export const ValuationTypeMapping = {
  "Val Present": "Valuation",
  "Val After": "Valuation",
  "Purchase Price": "Sale",
  "Asking Price (Capital)": "Asking Price",
  "Asking Price (Rental)": "Asking Price",
  AskingPrice: "Asking Price",
  Sale: "Sale",
  Valuation: "Valuation",
  "Portfolio Data": "Valuation",
  "": "",
  Desktops: "Valuation",
  "Drive Bys": "Valuation",
  "Assessed Rent": "Valuation",
  "Achieved Rent": "Valuation",
};
