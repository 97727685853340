import {
  AreaPriceModes,
  PerAreaPriceModes,
  PriceModes,
  ZooplaPricePerAreaModes,
} from "../typings/PriceModes";

export function isPerAreaMode(x: PriceModes): x is AreaPriceModes {
  return (
    x === PerAreaPriceModes.EPPSM ||
    x === PerAreaPriceModes.PPSM ||
    x === ZooplaPricePerAreaModes.RENT_ASKING ||
    x === ZooplaPricePerAreaModes.RENT_ESTIMATE ||
    x === ZooplaPricePerAreaModes.VAL_ESTIMATE ||
    x === ZooplaPricePerAreaModes.VAL_LAST
  );
}
