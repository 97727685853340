import { useErrorBoundary } from "react-error-boundary";
import { useEffect, useState } from "react";
import {
  LayerTypeSelectionSidebarHeaderProps,
  LayerTypeSelectionSidebarProps,
} from "../../../types";
import { PlanningApplication, RawApplication } from "react-migration/domains/planning/types";
import { SelectionFeature, SelectionType } from "src/js/stores/map/store";
import { CollapsibleConsiderationsCard } from "../../../Bundle/Workbench/CollapsibleConsiderationsCard";
import { useSelectionSidebarLoading } from "../../../Bundle/useSelectionSidebarLoadingState";
import { PlanningApplicationsByAddress } from "../PlanningApplicationsByAddress";
import { PlanningApplicationsSummary } from "../PlanningApplicationsSummary";
import { usePlanningApplicationListBySelection } from "./usePlanningApplicationListBySelection";
import { useBetaFeatureFlag } from "react-migration/lib/user/useFeatureFlag";
import Feature from "src/js/stores/user/Feature";
import { useTranslation } from "react-migration/lib/i18n/useTranslation";
import { Icon } from "@landtechnologies/components";
import { SummaryGenerate } from "../PlanningApplicationsSummary/components/SummaryGenerate";
export type FilterableApplication = RawApplication & {
  _id: string;
};

export function PlanningApplicationSelectionSidebar({
  layer,
  selection,
  setDetailSelection,
  onLoadingChange,
  cardExpanded,
}: LayerTypeSelectionSidebarProps) {
  const hasAiSummaryBeta = useBetaFeatureFlag(Feature.aiSummaryBeta);
  const { showBoundary, resetBoundary } = useErrorBoundary();
  const { filteredPlanningApplications, loading, error } = usePlanningApplicationListBySelection(
    selection,
    { useLayerFilters: false }
  );

  useSelectionSidebarLoading(onLoadingChange, loading);

  useEffect(() => {
    if (error) showBoundary(error);

    return () => resetBoundary();
  }, [error, resetBoundary, showBoundary]);

  if (!filteredPlanningApplications.length) return null;

  return (
    <div key={selection.id} className="atlas-flex atlas-flex-col atlas-gap-y-2">
      {hasAiSummaryBeta && (
        <AISummaryCard
          selection={selection}
          title={layer.title}
          referenceApplications={filteredPlanningApplications}
          setDetailSelection={setDetailSelection}
        />
      )}
      <PlanningApplicationsCard
        layer={layer}
        applications={filteredPlanningApplications}
        setDetailSelection={setDetailSelection}
        onLoadingChange={onLoadingChange}
        cardExpanded={cardExpanded}
        onSelection={() => {
          return undefined;
        }}
      />
    </div>
  );
}

const AISummaryCard = ({
  selection,
  title,
  referenceApplications,
  setDetailSelection,
}: {
  selection: SelectionFeature;
  title: string;
  referenceApplications: PlanningApplication[];
  setDetailSelection: (selection: SelectionFeature) => void;
}) => {
  const { t } = useTranslation();
  const [showSummary, setShowSummary] = useState(false);

  return (
    <CollapsibleConsiderationsCard
      heading={
        <>
          <Icon size="md" icon="icon-lt-sparkling-line atlas-text-platform-500" />
          <h1 className="atlas-text-sm atlas-font-semibold atlas-text-content-primary">
            {t("planning.ai.title")}
          </h1>
        </>
      }
      name={"layer.title"}
      defaultExpanded={true}
    >
      {showSummary ? (
        <PlanningApplicationsSummary
          selection={selection}
          referenceApplications={referenceApplications}
          setDetailSelection={setDetailSelection}
        />
      ) : (
        <SummaryGenerate setShowSummary={setShowSummary} cardName={title} />
      )}
    </CollapsibleConsiderationsCard>
  );
};

type PlanningApplicationsCardProps = Omit<LayerTypeSelectionSidebarProps, "selection"> & {
  applications: PlanningApplication[];
};

const PlanningApplicationsCard = ({
  layer,
  applications,
  setDetailSelection,
  cardExpanded,
}: PlanningApplicationsCardProps) => (
  <CollapsibleConsiderationsCard
    heading={layer.title}
    name={layer.title}
    count={applications?.length}
    defaultExpanded={cardExpanded}
  >
    <div className="atlas-p-2">
      <PlanningApplicationsByAddress
        planningApplications={applications}
        setDetailSelection={setDetailSelection}
      />
    </div>
  </CollapsibleConsiderationsCard>
);

function PlanningApplicationSelectionSidebarHeader({
  selection,
  defaultTitle,
}: LayerTypeSelectionSidebarHeaderProps) {
  const title =
    selection.type === SelectionType.PLANNING_APPLICATION ? `Other ${defaultTitle}` : defaultTitle;
  return <>{title}</>;
}

PlanningApplicationSelectionSidebar.Header = PlanningApplicationSelectionSidebarHeader;
