import ADD_LABEL from "../graphql/addLabel.gql";
import { ApolloError, FetchResult, useMutation } from "@apollo/client";
import { SiteLabel } from "react-migration/domains/sites/typings/Site";
import { routedClient } from "react-migration/lib/persistence/apollo/routedClient";

interface AddLabelResponse {
  site: {
    _id: string;
    site_labels: SiteLabel[];
  };
}

interface AddLabelVariables {
  siteId: string;
  labelId: string;
}

export const useAddLabelMutation = () => {
  const [addLabel, { loading, error }] = useMutation<AddLabelResponse>(ADD_LABEL, {
    client: routedClient,
  });

  const addLabelMutation = async (
    variables: AddLabelVariables
  ): Promise<FetchResult<AddLabelResponse>> => {
    try {
      const result = await addLabel({ variables });
      return result;
    } catch (error) {
      console.log(`Error adding label: ${error}`);
      throw error as ApolloError;
    }
  };

  return { addLabelMutation, loading, error };
};
