import { RecordType } from "react-migration/domains/comparables/typings/Record";
import { formatDate } from "react-migration/domains/comparables/util/Dates";
import { Currency, formatToCurrency } from "react-migration/lib/util/numberFormat";
import { SupportedLocale } from "src/js/util/dates";
import { Translation } from "react-migration/lib/typings";

export interface SaleTitleProps {
  t: Translation;
}
export const saleTitle = ({ t }: SaleTitleProps) =>
  t("comparables.map.sidebar.value.record.sale.title");

export interface SaleValueProps {
  t: Translation;
  record: RecordType;
  locale: SupportedLocale;
}
export const saleValue = ({ t, record, locale }: SaleValueProps) =>
  t("comparables.map.sidebar.value.record.sale.value", {
    price:
      formatToCurrency({
        amount: record.price,
        locale,
        currency: record.currency as Currency,
      }) || "-",
    date: record.date_of_transfer ? formatDate(record.date_of_transfer) : "-",
  });
