<template>
  <react-wrapper :component="YourAddresses" />
</template>

<script>
import ReactWrapper from "view/wrappers/react-wrapper";
import { YourAddresses } from "react-migration/domains/dashboard/yourAddresses/YourAddresses";

export default {
  components: {
    ReactWrapper,
  },
  computed: {
    YourAddresses() {
      return YourAddresses;
    },
  },
};
</script>
