import { useQuery } from "@apollo/client";
import classNames from "classnames";

import { withSkeletonLoading } from "@landtechnologies/components";
import { formatToCurrency } from "react-migration/lib/util/numberFormat";
import {
  LandTechEndpoints,
  routedClientWithoutTypenames,
} from "react-migration/lib/persistence/apollo/routedClient";
import { useSnapshot } from "valtio";
import { useTranslation } from "react-migration/lib/i18n/useTranslation";

import SITE_LETTER_PAYMENT_SUMMARY from "react-migration/domains/sites/apollo/sites-api/queries/siteLetterPaymentSummary.gql";

import { ProductDetails } from "./components/ProductDetails";
import { SummaryDivider } from "./components/SummaryDivider";
import { useEffect } from "react";
import { letterStore } from "src/js/stores/letter/store";
import { SiteLetterPaymentSummaryResponseData } from "react-migration/domains/sites/typings/apollo";

interface PhysicalSendSummarySectionProps {
  onTotalCostCalculated?: (totalCost: number) => void;
}

export const PhysicalSendSummarySection: React.FC<PhysicalSendSummarySectionProps> = ({
  onTotalCostCalculated,
}) => {
  const { selectedRecipientIds } = useSnapshot(letterStore);
  const quantityOfLetters = selectedRecipientIds?.length ?? 1;

  const { loading, data, error } = useQuery<SiteLetterPaymentSummaryResponseData>(
    SITE_LETTER_PAYMENT_SUMMARY,
    {
      client: routedClientWithoutTypenames,
      variables: { siteLetterPaymentSummaryInput: { quantity: quantityOfLetters } },
      context: {
        endpoint: LandTechEndpoints.Sites,
      },
    }
  );

  const paymentSummary = data?.siteLetterPaymentSummary;

  useEffect(() => {
    if (paymentSummary) {
      onTotalCostCalculated?.(paymentSummary.total);
    }
  }, [paymentSummary, onTotalCostCalculated]);

  if (loading === true) {
    return <LoadingSkeleton />;
  } else if (error || !paymentSummary) {
    return <ErrorDisplay />;
  } else {
    const asCurrency = (number: number) => {
      const formatted =
        formatToCurrency({
          amount: number / 100,
          currency: paymentSummary.currency,
        }) || "";

      return formatted;
    };

    const props = {
      siteLetterUnitPricePostTax: asCurrency(paymentSummary.siteLetterUnitPricePostTax),
      quantity: String(paymentSummary.quantity),
      taxRate: String(paymentSummary.taxRate),
      taxTotal: asCurrency(paymentSummary.taxTotal),
      total: asCurrency(paymentSummary.total),
      subtotal: asCurrency(paymentSummary.subtotal),
    };
    return <PhysicalSendSummarySectionView {...props} />;
  }
};

type PhysicalSendSummarySectionViewProps = {
  quantity: string;
  siteLetterUnitPricePostTax: string;
  subtotal: string;
  taxTotal: string;
  taxRate: string;
  total: string;
};

function PhysicalSendSummarySectionView({
  quantity,
  siteLetterUnitPricePostTax,
  subtotal,
  taxRate,
  taxTotal,
  total,
}: PhysicalSendSummarySectionViewProps) {
  const { t } = useTranslation();

  const rowClassnames = ["atlas-flex", "atlas-justify-between", "atlas-font-normal", "atlas-mt-4"];
  const labelClassnames = ["atlas-flex", "atlas-text-content-secondary"];
  const amountClassnames = ["atlas-flex", "atlas-text-content-primary"];

  return (
    <div
      className={classNames("atlas-p-4", "atlas-bg-white", "atlas-rounded-b-lg")}
      data-testid="site-letter-payment-summary-summary-section"
    >
      <ProductDetails quantity={quantity} unitPricePerLetter={siteLetterUnitPricePostTax} />

      <SummaryDivider />

      <div className={classNames(rowClassnames)}>
        <span className={classNames(labelClassnames)}>
          {t("sites.letters.subtotal_before_tax")}
        </span>
        <span className={classNames(amountClassnames)}>{subtotal}</span>
      </div>

      <div className={classNames(rowClassnames)}>
        <span className={classNames(labelClassnames)} data-testid="tax-rate">
          {t("sites.letters.tax_rate", { taxRate })}
        </span>
        <span className={classNames(amountClassnames)}>{taxTotal}</span>
      </div>

      <SummaryDivider />

      <div
        className={classNames(
          "atlas-mt-4",
          "atlas-flex",
          "atlas-justify-between",
          "atlas-font-semibold",
          "atlas-text-base",
          "atlas-leading-6",
          "atlas-text-content-primary"
        )}
      >
        <span className="atlas-flex">{t("sites.letters.total_due")}</span>
        <span className="atlas-flex">{total}</span>
      </div>
    </div>
  );
}

function ErrorDisplay() {
  const errorProps = {
    quantity: "-",
    siteLetterUnitPricePostTax: "-",
    subtotal: "-",
    taxTotal: "-",
    taxRate: "-",
    total: "-",
  };

  return <PhysicalSendSummarySectionView {...errorProps} />;
}

function LoadingSkeleton() {
  const SkeletonPhysicalSendSummarySection = withSkeletonLoading(PhysicalSendSummarySectionView, {
    rows: 3,
  });

  const loadingProps = {
    quantity: "",
    siteLetterUnitPricePostTax: "",
    subtotal: "",
    taxTotal: "",
    taxRate: "",
    total: "",
  };

  return <SkeletonPhysicalSendSummarySection {...loadingProps} />;
}
