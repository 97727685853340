import { useTranslation } from "react-migration/lib/i18n/useTranslation";
import { FinalStepWrapper } from "./FinalStepWrapper";
import classNames from "classnames";
import { Button } from "@landtechnologies/components";

type LetterErrorProps = {
  onBackClick: () => void;
  backButtonText?: string;
};

export const LetterError = ({ onBackClick, backButtonText }: LetterErrorProps) => {
  const { t } = useTranslation();
  return (
    <FinalStepWrapper>
      <div
        className={classNames(
          "atlas-flex",
          "atlas-w-20",
          "atlas-h-20",
          "atlas-border",
          "atlas-justify-center",
          "atlas-items-center",
          "atlas-rounded-full",
          "atlas-border-background-negative-hover",
          "atlas-bg-background-negative-hover",
          "atlas-drop-shadow-lg",
          "atlas-drop-shadow-background-content-subtle"
        )}
      >
        <i
          className={classNames(
            "icon-lt-close-line",
            "atlas-text-6xl",
            "atlas-flex",
            "atlas-text-white"
          )}
        />
      </div>
      <span
        className={classNames(
          "atlas-my-4",
          "atlas-text-content",
          "atlas-font-semibold",
          "atlas-text-xl",
          "atlas-leading-7"
        )}
      >
        {t("sites.letters.error.title")}
      </span>
      <div className={classNames("atlas-text-base", "atlas-w-[400px]", "atlas-text-center")}>
        <span className="atlas-text-content-secondary" data-testid="extended-error-message">
          {t("sites.letters.error.message")}
        </span>
        <a href="mailto: support@land.tech?subject=Letter Sending" target="_blank" rel="noreferrer">
          {t("sites.letters.customer_support")}
        </a>
      </div>
      <Button.Primary className="atlas-mt-4" onClick={onBackClick}>
        {backButtonText ?? t("sites.letters.back_to_site")}
      </Button.Primary>
    </FinalStepWrapper>
  );
};
