import { ISourcingToolSearchFilter } from "react-migration/domains/search/typings/SourcingToolSpecificFilterTypes";
import { SearchType } from "react-migration/domains/search/constants/searchTypes";

export const LastSaleDateFilter: ISourcingToolSearchFilter = {
  id: "last-sale-date",
  type: "number-range",
  rangeType: "basic",
  field: "sidebar.react.search_sidebar.last_sale_date",
  range: [null, null],
  rangeLimit: [0, 100],
  updateFilterDialogIsVisible: false,
  searchTypes: [SearchType.SOURCING_TOOL_US_FL],
  startInputPlaceholder: "0 Years",
  endInputPlaceholder: "100 Years",
  units: "Years",
  displayMessages: {
    pluralisation: "s",
    suffix: "year",
  },
};
