import { getSelectedGeometry } from "src/js/stores/drawing/actions/getSelectedGeometry";
import { SpecHeader } from "./AnnotationSpec/SpecHeader";
import { getMultiSelected } from "src/js/stores/drawing/actions/getMultiSelected";
import { getEditableDrawing } from "src/js/stores/drawing/actions/getEditableDrawing";
import { SaveSiteButton } from "react-migration/domains/sites/components/SaveSiteButton/SaveSiteButton";
import { SaveSiteLayer } from "react-migration/domains/sites/typings/SaveSiteLayer";
import { Polygon } from "geojson";
import { CreatedSite } from "react-migration/domains/sites/components/SaveSiteButton/useSaveSiteMutation/useSaveSiteMutation";
import { SelectionGeometry, SelectionType } from "src/js/stores/map/store";
import { LayerTypeSelectionHeaderProps } from "../../types";
import { feature } from "@turf/helpers";
import { deleteDrawing } from "src/js/stores/drawing/actions/deleteDrawing";

export function DrawingsDetailHeader({ setSelection }: LayerTypeSelectionHeaderProps) {
  const selectedDrawing = getSelectedGeometry();
  const editableDrawing = getEditableDrawing();
  const multiSelection = getMultiSelected();

  const drawing = selectedDrawing || editableDrawing;
  const shape = multiSelection ? "MultiSelect" : drawing?.properties.shape;

  const onConvertToSite = (createdSites: CreatedSite[]) => {
    drawing && deleteDrawing(drawing.id);

    createdSites.length &&
      setSelection({
        id: createdSites[0]._id as string,
        type: SelectionType.SITE,
        feature: feature(createdSites[0].geometry as SelectionGeometry),
      });
  };

  if (!shape) return null;

  return (
    <div className="atlas-flex atlas-flex-row atlas-justify-between atlas-items-stretch atlas-grow">
      <div className="atlas-text-sm atlas-font-semibold atlas-text-content-primary">
        <SpecHeader shape={shape} />
      </div>
      {drawing && shape === "Polygon" && !editableDrawing && (
        <div className="">
          <SaveSiteButton
            text="Save as Site"
            source="SelectionView"
            layer={SaveSiteLayer.Annotations}
            siteGeometry={drawing.geometry as Polygon}
            data-testid="save-site-button"
            siteMatchType="EXACT"
            handleSaveSites={(createdSites) => onConvertToSite(createdSites)}
          />
        </div>
      )}
    </div>
  );
}
