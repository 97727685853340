import { useEffect, useState } from "react";
import { GeoJsonLayer } from "deck.gl";
import { useMapLayer } from "react-migration/lib/map/useMapLayer";

import { LayerTypeMapLayerProps } from "../../../types";
import { useIsochroneLayerTypeContext } from "../IsochroneContext";
import { IsochronicFeature, IsochronicFeatureProperties, RoutingTime } from "../types";
import { getIsochroneAPIUrl, colourScale } from "./accessors";
import { LINE_COLOUR } from "./constants";

export function IsochroneMapLayer({ selection, visible }: LayerTypeMapLayerProps) {
  const [isochrone, setIsochrone] = useState<IsochronicFeature[]>();
  const { routingType, routingTime, setFetching } = useIsochroneLayerTypeContext();

  useEffect(() => {
    if (!selection) {
      setIsochrone(undefined);
      return;
    }

    if (!visible) return;

    const url = getIsochroneAPIUrl(selection, routingType);
    const abortController = new AbortController();

    async function fetchIsochrone() {
      setFetching(true);

      try {
        const response = await fetch(url, {
          referrerPolicy: "origin",
          signal: abortController.signal,
        });
        const data = await response.json();
        setIsochrone(data?.features || []);
      } catch (e: unknown) {
        if ((e as Error).name !== "AbortError") {
          console.error("Failed to fetch Isochrone from Mapbox API:", e);
        }
      } finally {
        setFetching(false);
      }
    }

    fetchIsochrone();

    return () => abortController.abort();
  }, [selection, visible, routingType, setFetching]);

  useMapLayer(
    () => ({
      id: "ISOCHRONE",
      layer: new GeoJsonLayer<IsochronicFeatureProperties>({
        data: isochrone?.filter((d) => {
          if (routingTime === RoutingTime.All) return true;
          return d.properties.contour === Number(routingTime);
        }),
        filled: true,
        stroked: true,
        getFillColor: (d) => colourScale(d.properties.contour),
        getLineColor: LINE_COLOUR,
        lineWidthUnits: "pixels",
        getLineWidth: 3,
        visible,
      }),
    }),
    [isochrone, routingTime, visible]
  );

  return null;
}
