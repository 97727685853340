import { Button, Checkbox, CheckboxState, Icon, Tooltip } from "@landtechnologies/components";
import { CSVLink } from "react-csv";
import { useTranslation } from "react-migration/lib/i18n/useTranslation";
import { logEvent } from "react-migration/lib/util/logEvent";
import { useSnapshot } from "valtio";
import { dateIsoString } from "src/js/util/dates";
import { useMemo } from "react";
import { useComparablesLayerContext } from "./ComparableLayerContext/useComparableLayerContext";
import { isZoopla } from "react-migration/domains/comparables/util/isZoopla";
import Feature from "src/js/stores/user/Feature";
import { CountPill } from "react-migration/components/CountPill";
import { comparablesStore } from "src/js/stores/comparables";
import { setIncNoFloorArea } from "src/js/stores/comparables/actions";
import { getMeasurementSystemPreference } from "src/js/stores/user/actions/getSetting";
import hasBetaFeature from "src/js/stores/user/actions/hasBetaFeature";
import { getTransformer } from "react-migration/domains/comparables/util/BuildCSV";

interface ComprarablesSelectionSidebarHeaderProps {
  count?: number;
}

export function ComparablesSelectionSidebarHeader({
  count,
}: ComprarablesSelectionSidebarHeaderProps) {
  const { t } = useTranslation();
  const { transactions, zooplaTransactions } = useComparablesLayerContext();
  const { priceMode, incNoFloorArea } = useSnapshot(comparablesStore);
  const unitPreference = getMeasurementSystemPreference();
  const isZooplaData = isZoopla(priceMode);
  const isTurtleUser = hasBetaFeature(Feature.TURTLES);

  const downloadCSV = () => {
    logEvent("Comparables Side bar header CSV export", {
      results: transactions.length,
      includeNoFloorArea: incNoFloorArea,
      hometrack: isZooplaData,
      priceMode,
    });
  };

  const floorAreaTransactions = useMemo(() => {
    return {
      zoopla: zooplaTransactions.filter((row) =>
        incNoFloorArea ? true : Number(row.attributes.floorArea) > 0
      ),
      landtech: transactions.filter((row) =>
        incNoFloorArea ? true : Number(row.total_floor_area) > 0
      ),
    };
  }, [zooplaTransactions, transactions, incNoFloorArea]);

  const csvTransactionsLinkData = useMemo(() => {
    const { zoopla, landtech } = floorAreaTransactions;
    const allTransactions = [...landtech, ...zoopla];
    const transformer = getTransformer(priceMode);
    const data = transformer({
      t,
      unitPreference,
      // @ts-expect-error can't handle the polymorphism
      transactions: allTransactions,
    });

    return data;
  }, [floorAreaTransactions, t, unitPreference, priceMode]);

  return (
    <div className="atlas-flex atlas-justify-between">
      <div className="atlas-flex atlas-gap-x-1 atlas-items-center">
        <h1 className="atlas-self-center atlas-text-content-primary atlas-text-sm atlas-font-semibold">
          {t("comparables.header")}
        </h1>
        <CountPill count={count} />
      </div>
      {isTurtleUser && (
        <Tooltip.Wrapper>
          <Tooltip.Trigger>
            <Button.Secondary data-testid="csv-export-button">
              {t("sidebar.react.search_sidebar.csv")}
              <Icon
                icon="icon-lt-download-2-line"
                size="sm"
                cursor="pointer"
                extraClasses="atlas-pl-1"
              />
            </Button.Secondary>
          </Tooltip.Trigger>
          <Tooltip.Content align="center" side="bottom" size="sm" showArrow={true}>
            <div className="atlas-flex">
              <div className="atlas-flex atlas-gap-2 atlas-min-w-50">
                <Checkbox
                  id="includeFloorArea"
                  value={incNoFloorArea ? CheckboxState.CHECKED : CheckboxState.EMPTY}
                  onChange={() => setIncNoFloorArea(!incNoFloorArea)}
                />
                <span className="atlas-font-semibold atlas-text-xs atlas-text-content-secondary atlas-m-0 atlas-block">
                  {t("comparables.calculator.results.floorarea")}
                </span>
              </div>
              <CSVLink
                data={csvTransactionsLinkData}
                data-testid="csv-export"
                filename={`LandInsight Comparables Export (${dateIsoString(Date.now())}).csv`}
              >
                <Button.Primary
                  size="small"
                  onClick={() => downloadCSV()}
                  disabled={!csvTransactionsLinkData.length}
                >
                  Download CSV
                </Button.Primary>
              </CSVLink>
            </div>
          </Tooltip.Content>
        </Tooltip.Wrapper>
      )}
    </div>
  );
}
