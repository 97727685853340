import { Letter } from "src/js/stores/letter/store";

import { Recipient } from "../typings/Recipient";
import { SiteLetterInput } from "../typings/Letter";
import { PaperFormat } from "../typings/PaperFormat";
import hasFeature from "src/js/stores/user/actions/hasFeature";
import Feature from "src/js/stores/user/Feature";
import { AvailableCountryCodes } from "../typings/CountryCode";

export type TemplateInput = {
  letter: Letter;
  letterName: string;
  pageCount: number;
  siteBoundaryImage?: string;
};

export function toCreateLetterContentDTO(
  { letter, letterName, pageCount, siteBoundaryImage }: TemplateInput,
  recipient: Recipient,
  options?: {
    hideRecipientAddress?: boolean;
    paperFormat?: PaperFormat;
  }
): SiteLetterInput {
  const usAccess = hasFeature(Feature.usAccess);
  const defaultPaperFormat = usAccess ? PaperFormat.US_LETTER : PaperFormat.A4;
  const defaultCountryCode = usAccess
    ? AvailableCountryCodes.UNITED_STATES
    : AvailableCountryCodes.UNITED_KINGDOM;
  return {
    letter,
    letterName,
    pageCount,
    siteBoundaryImage,
    recipient: {
      ...recipient,
      // explicitly setting region here as it is required for backend to process the letter
      region: recipient.region ?? "",
      title: usAccess ? undefined : recipient.title,
      countryCode: recipient.countryCode ?? defaultCountryCode,
    },
    options: {
      ...options,
      paperFormat: options?.paperFormat ?? defaultPaperFormat,
    },
  };
}
