import type { Color } from "deck.gl";
import { scaleSymlog, scaleLinear } from "d3-scale";
import { PlanningApplicationFeature } from "react-migration/domains/planning/types";
import {
  checkIsCommercial,
  checkIsMinor,
  checkIsMixedUse,
  checkIsOther,
  checkIsResidential,
} from "./planningFilters";
import { COLOR_DOMAIN_RANGE_DEFAULT, RADIUS_DOMAIN_SIZE, RADIUS_RANGE_PX } from "./constants";

export const zScale = scaleLinear().domain(RADIUS_RANGE_PX).range([-1, 1]).clamp(true);
export const colorScaleResidential = scaleSymlog<[number, number, number, number]>()
  .domain(COLOR_DOMAIN_RANGE_DEFAULT)
  .range([
    [229, 140, 161, 255],
    [255, 54, 101, 255],
  ])
  .clamp(true);

export const colorScaleCommercial = scaleSymlog<[number, number, number, number]>()
  .domain(COLOR_DOMAIN_RANGE_DEFAULT)
  .range([
    [170, 140, 229, 255],
    [104, 28, 255, 255],
  ])
  .clamp(true);

export function lineColour(d: PlanningApplicationFeature, selectedIds: string[] = []): Color {
  return selectedIds.includes(d.properties.id) ? [153, 202, 60] : [255, 255, 255];
}

export function lineWidth(d: PlanningApplicationFeature, selectedIds: string[] = []): number {
  return selectedIds.includes(d.properties.id) ? 4 : 2;
}

export function fillColourDots(d: PlanningApplicationFeature): Color {
  if (checkIsResidential(d)) {
    return colorScaleResidential(d.properties.date_received);
  } else if (checkIsMixedUse(d)) {
    return colorScaleResidential(d.properties.date_received);
  } else if (checkIsCommercial(d)) {
    return colorScaleCommercial(d.properties.date_received);
  } else {
    return [49, 179, 223, 255];
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function convertDatetoEpochDate(date: any) {
  return isNaN(date) ? Math.floor(new Date(date).valueOf()) : date;
}

export function getFillColour(d: PlanningApplicationFeature): Color {
  const date_received = convertDatetoEpochDate(d.properties.date_received);
  if (checkIsResidential(d)) {
    return colorScaleResidential(date_received);
  } else if (checkIsMixedUse(d)) {
    return [255, 54, 101, 255];
  } else if (checkIsCommercial(d)) {
    return colorScaleCommercial(date_received);
  } else {
    return [49, 179, 223, 255];
  }
}

export function getGradientStop(d: PlanningApplicationFeature): Color {
  if (checkIsMixedUse(d)) {
    return colorScaleCommercial(d.properties.date_received);
  }

  return [] as unknown as Color;
}

const residentialRadiusScale = scaleLinear()
  .domain(RADIUS_DOMAIN_SIZE)
  .range(RADIUS_RANGE_PX)
  .clamp(true);

export function getPointRadius(d: PlanningApplicationFeature) {
  if (checkIsOther(d)) {
    return RADIUS_RANGE_PX[0];
  } else if (checkIsMixedUse(d)) {
    return RADIUS_RANGE_PX[2];
  } else if (checkIsCommercial(d)) {
    return checkIsMinor(d) ? RADIUS_RANGE_PX[0] : RADIUS_RANGE_PX[1];
  } else {
    return residentialRadiusScale(d.properties.size ?? 0);
  }
}
