import { useMemo, useState } from "react";
import { useTranslation } from "react-migration/lib/i18n/useTranslation";
import { useMapLayer } from "react-migration/lib/map/useMapLayer";

import { PickingInfo, Color } from "deck.gl";
import { color } from "d3-color";
import { isSiteBoundary } from "./utils";
import { ClusterFeature } from "./types";
import { SITES_MAP_LAYER_ID } from "./constants";
import { LayerTypeMapLayerProps } from "../../types";
import { SitesLayer } from ".";
import { useFeatureIsVisible } from "./useFeatureIsVisible";
import { useSnapshot } from "valtio";
import { siteGeometryStore } from "src/js/stores/siteGeometry/store";
import { SitesGeoJsonLayer } from "./SitesGeoJsonLayer";

const TRANSPARENT: Color = [0, 0, 0, 0];
const CLUSTER_COLOR: Color = [252, 254, 7];

export function SitesMapLayer({ zOrder, layer, detailSelection, visible }: LayerTypeMapLayerProps) {
  const { t } = useTranslation();
  const [hoverInfo, setHoverInfo] = useState<PickingInfo<ClusterFeature>>();
  const featureIsVisible = useFeatureIsVisible(detailSelection);

  const captureInternalPolygonClicks =
    (layer as SitesLayer).layerConfig?.captureInternalPolygonClicks ?? true;
  const { clusters: clusterFeatures, siteFeatures } = useSnapshot(siteGeometryStore);

  const data = useMemo(
    () => [...siteFeatures, ...clusterFeatures].filter(featureIsVisible),
    [featureIsVisible, siteFeatures, clusterFeatures]
  );

  useMapLayer(
    () => ({
      layer: new SitesGeoJsonLayer({
        id: SITES_MAP_LAYER_ID,
        // @ts-expect-error data is valid
        data,
        visible,
        filled: captureInternalPolygonClicks,
        getFillColor: (feature) => {
          if (isSiteBoundary(feature)) return TRANSPARENT;

          // is cluster
          return CLUSTER_COLOR;
        },

        stroked: true,
        getLineColor: (feature) => {
          if (!isSiteBoundary(feature)) return CLUSTER_COLOR;

          const { r, g, b } = color(feature.properties.color)!.rgb();
          return [r, g, b];
        },
        getLineWidth: 3,
        lineWidthUnits: "pixels",
        getPointRadius: 7,
        pickable: true,
        pointRadiusUnits: "pixels",
        onHover: (info) => {
          if (!info.object || isSiteBoundary(info.object)) {
            setHoverInfo(undefined);
          } else {
            setHoverInfo(info as PickingInfo<ClusterFeature>);
          }
        },
      }),
      zOrder,
    }),
    [data, visible, captureInternalPolygonClicks, zOrder]
  );

  if (!hoverInfo) return null;

  return (
    <div
      className="atlas-absolute atlas-z-10 atlas-pointer-events-none atlas-bg-white atlas-rounded atlas-p-2 atlas-text-xs"
      style={{
        left: hoverInfo.x,
        top: hoverInfo.y,
      }}
    >
      {
        // @ts-expect-error object is valid
        hoverInfo.object.properties?.count
          ? // @ts-expect-error object is valid
            t("sites.sites_in_cluster", { count: hoverInfo.object.properties?.count })
          : t("sites.sites_cluster")
      }
    </div>
  );
}
