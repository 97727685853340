import { RecordType } from "react-migration/domains/comparables/typings/Record";
import { Translation } from "react-migration/lib/typings";

export interface BedroomCountTitleProps {
  t: Translation;
}
export const bedroomCountTitle = ({ t }: BedroomCountTitleProps) =>
  t("comparables.map.sidebar.value.record.bedrooms.title");

export interface BedroomCountTooltipProps {
  t: Translation;
  record: RecordType;
}
export const bedroomCountTooltip = ({ t, record }: BedroomCountTooltipProps) =>
  record.country === "GB" ? t("comparables.map.sidebar.value.record.bedrooms.tooltip") : undefined;

export interface BedroomCountValueProps {
  t: Translation;
  record: RecordType;
}
export const bedroomCountValue = ({ t, record }: BedroomCountValueProps) => {
  if (record.num_bedrooms) {
    return record.num_bedrooms === 0
      ? t("comparables.map.sidebar.value.record.bedrooms.value.studio")
      : `${record.num_bedrooms}`;
  }
  if (record.est_bedrooms && record.est_bedrooms.length) {
    return `${record.est_bedrooms.join(" - ")}*`;
  }
  return undefined;
};
