import { RecordType } from "react-migration/domains/comparables/typings/Record";
import { Translation } from "react-migration/lib/typings";
import { Currency, formatToCurrency } from "react-migration/lib/util/numberFormat";
import { SupportedLocale } from "src/js/util/dates";

export interface MarketTitleProps {
  t: Translation;
}
export const marketTitle = ({ t }: MarketTitleProps) =>
  t("comparables.map.sidebar.value.record.value.title");

export interface MarketTooltipProps {
  t: Translation;
  record: RecordType;
}
export const marketTooltip = ({ t, record }: MarketTooltipProps) =>
  record.country === "GB" ? t("comparables.map.sidebar.value.record.value.tooltip") : undefined;

export interface MarketValueProps {
  record: RecordType;
  locale: SupportedLocale;
}
export const marketValue = ({ record, locale }: MarketValueProps) => {
  if (!record.est_price) {
    return undefined;
  }
  return formatToCurrency({
    amount: record.est_price || 0,
    locale,
    currency: record.currency as Currency,
  });
};
