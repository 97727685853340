import { useCallback, useEffect, useId } from "react";
import {
  addClickListener,
  removeClickListener,
} from "src/js/stores/map/actions/updateDeckSettings";
import type { DeckGLClickHandler } from "src/js/stores/map/store";

/**
 * Use to bind top level `deck.gl` click handlers which will exist for the
 * lifecycle of the calling component.
 * @param {function} callback - The callback itself.
 * @param {any[]} deps - List of dependenecies of the callback.
 */
export function useMapCallback(callback: DeckGLClickHandler, deps?: unknown[]) {
  const key = "useMapCallback-" + useId();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const memoizedCallback = useCallback(callback, deps || []);

  useEffect(() => {
    addClickListener({ key, callback: memoizedCallback });
    return () => removeClickListener(key);
  }, [key, memoizedCallback]);
}
