import { z } from "zod";
import { validatePostcode } from "../../letters/helpers/postcodeValidator";
import { isRegionValid } from "../../letters/helpers/isRegionValid";

const FileSchema = z
  .object({
    file: z.custom<File>(),
    url: z.string(),
    s3ObjectKey: z.string(),
  })
  .optional();

const checkValidPhoneNumber = (value: string) => value === "" || /^[\d+()\s-]+$/.test(value);

export const TemplateValidationSchema = z
  .object({
    logo: FileSchema,
    greeting: z.string().nonempty("form.error.required"),
    bodyText: z.string().nonempty("form.error.required"),
    bodyTextPage2: z.string().optional(),
    showSiteBoundary: z.boolean(),
    vatRegistrationNumber: z.string().optional(),
    companyRegistrationNumber: z.string().optional(),
    senderAddressLine1: z.string().nonempty("form.error.required"),
    senderCity: z.string().nonempty("form.error.required"),
    senderName: z.string().nonempty("form.error.required"),
    senderPostcode: z
      .string()
      .refine(validatePostcode, "form.error.provide_valid_postcode")
      .optional(),
    senderProvince: z.string().refine(isRegionValid, "form.error.required"),
    signOff: z.string().optional(),
    signaturePage: z.number(),
    signature: FileSchema,
    contactName: z.string().nonempty("form.error.provide_name").optional(),
    contactEmail: z
      .string()
      .email("form.error.provide_valid_email")
      .optional()
      .or(z.literal(""))
      .optional(),
    contactPhone: z
      .string()
      .refine(checkValidPhoneNumber, "form.error.provide_valid_phone")
      .optional(),
  })
  .refine(({ signOff, signaturePage }) => signaturePage === 0 || signOff, {
    message: "form.error.provide_sign_off",
    path: ["signOff"],
  });

export type TemplateFormData = z.infer<typeof TemplateValidationSchema>;
