import { SelectionFeature } from "src/js/stores/map/store";
import { SubSectionHeader } from "react-migration/components/SubSectionHeader/SubSectionHeader";
import { useComparablesLayerContext } from "./ComparableLayerContext/useComparableLayerContext";
import { useTransactionsAtSelection } from "./useTransactionsAtSelection";
import { TransactionsAccordion } from "./TransactionsAccordion";

interface OtherComparablesSectionProps {
  selection: SelectionFeature;
  onSelection?: (selection: SelectionFeature | undefined) => void;
}

export function OtherComparablesSection({ selection, onSelection }: OtherComparablesSectionProps) {
  const { distribution } = useComparablesLayerContext();

  const { includedResults, loading, selectRecord, priceMode } = useTransactionsAtSelection(
    selection,
    onSelection
  );

  if (loading || !includedResults?.length) return null;

  return (
    <section className="atlas-flex atlas-flex-col atlas-gap-y-2">
      <div className="atlas-px-3">
        <SubSectionHeader count={loading ? undefined : includedResults.length}>
          Other transactions
        </SubSectionHeader>
      </div>
      <div className="atlas-px-1 atlas-pb-1">
        <TransactionsAccordion
          includedResults={includedResults}
          loading={loading}
          setSelectedRecord={selectRecord}
          priceMode={priceMode}
          distribution={distribution.data}
        />
      </div>
    </section>
  );
}
