import { RouteConfig } from "vue-router";
import ROUTES from "../routes";
import Feature from "src/js/stores/user/Feature";
import { searchStore } from "src/js/stores/search/store";
import { BundleId } from "src/js/stores/map/store";
import { setCurrentSearchType } from "src/js/stores/search/actions/setCurrentSearchType";
import { tryParseFiltersFromQueryString } from "react-migration/domains/search/util/tryParseFiltersFromQueryString";
import hasFeature from "src/js/stores/user/actions/hasFeature";
import hasBetaFeature from "src/js/stores/user/actions/hasBetaFeature";
import { setActiveTab } from "src/js/stores/search/actions/setActiveTab";

export const MapRoutes: Array<RouteConfig> = [
  {
    path: "/map/search/:z?,:lat?,:lng?/:searchType?",
    name: ROUTES.SEARCH,
    beforeEnter: (to, _from, next) => {
      if (hasFeature(Feature.multiFilterSearch)) {
        if (!to.params.lat || !to.params.lng) {
          return next({
            name: ROUTES.SEARCH,
            params: {
              z: to.params.z,
              lat: to.params.lat || to.params.x,
              lng: to.params.lng || to.params.y,
              searchType: to.params.searchType,
            },
            query: to.query,
            replace: true,
          });
        }

        // this whole if statement can be removed on release of PORTFOLIO as we want to allow searchType
        // to be empty as this is when we show the search selection options
        if (!hasBetaFeature(Feature.TURTLES) && !to.params.searchType) {
          return next({
            name: ROUTES.SEARCH,
            params: {
              z: to.params.z,
              lat: to.params.lat || to.params.x,
              lng: to.params.lng || to.params.y,
              searchType: hasFeature(Feature.usAccess)
                ? BundleId.SOURCING_TOOL_US_FL
                : BundleId.SOURCING_TOOL_UK,
            },
            query: to.query,
            replace: true,
          });
        }

        if (to.params.searchType && searchStore.currentSearchType !== to.params.searchType) {
          const selectedFilters = tryParseFiltersFromQueryString(
            to.query?.filters as string | undefined
          );
          setCurrentSearchType(
            to.params.searchType,
            selectedFilters ? { selectedFilters } : undefined
          );
        }

        if (typeof to.query?.tab === "string" && to.query.tab !== searchStore.activeTab) {
          setActiveTab(to.query.tab as Parameters<typeof setActiveTab>[0]);
        }
        next();
      } else {
        next({ name: ROUTES.MAP });
      }
    },
    component: () =>
      import(
        /* webpackChunkName: 'search' */
        /* webpackPreload: true */
        "../../view/wrappers/search/search.vue"
      ),
  },
  {
    path: "/map/:z?,:x?,:y?",
    component: () =>
      import(
        /* webpackChunkName: 'map' */
        /* webpackPreload: true */
        "../../view/map/map.vue"
      ),
    name: ROUTES.MAP,
  },
  {
    path: "/map",
    redirect: { name: ROUTES.MAP },
  },
  {
    path: "/legacy-search/:z?,:x?,:y?",
    component: () =>
      import(
        /* webpackChunkName: 'legacySearch' */
        /* webpackPreload: true */
        "../../view/map/map.vue"
      ),
    name: ROUTES.LEGACY_SEARCH,
  },
  {
    path: "/legacy-search",
    redirect: { name: ROUTES.LEGACY_SEARCH },
  },
  {
    path: "/search/:z?,:x?,:y?/:searchType?",
    name: ROUTES.SOURCING_TOOL_OLD_ROUTE,
    beforeEnter: (to, _from, next) => {
      if (hasFeature(Feature.multiFilterSearch)) {
        next({
          name: ROUTES.SEARCH,
          params: {
            z: to.params.z,
            lat: to.params.x,
            lng: to.params.y,
            searchType: to.params.searchType ?? "sourcing-tool",
          },
          replace: true,
        });
      } else {
        next({ name: ROUTES.MAP });
      }
    },
    component: () =>
      import(
        /* webpackChunkName: 'search' */
        /* webpackPreload: true */
        "../../view/wrappers/search/search.vue"
      ),
  },
];
