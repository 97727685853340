import { z } from "zod";
import { Nullable } from "src/js/types/Nullable";
import {
  MultiPolygonSchema,
  PointSchema,
  PolygonSchema,
} from "react-migration/lib/typings/GeometrySchema";
import type { Position } from "@turf/helpers";

export enum PlanningClassification {
  RESIDENTIAL = "RESIDENTIAL",
  COMMERCIAL = "COMMERCIAL",
  MIXED_USE = "MIXED_USE",
  OTHER = "OTHER",
  UNKNOWN = "UNKNOWN",
}

export enum TypesDerived {
  FULL_APPLICATION = "Full Application",
  OUTLINE = "Outline",
  DISCHARGE_OF_CONDITIONS = "Discharge of Conditions",
  GPDR = "GPDR",
  RESERVED_MATTERS = "Reserved Matters",
  TPO = "TPO",
  LISTED_BUILDING = "Listed Building",
  EIA = "EIA",
  CHANGE_OF_USE = "Change of Use",
}

export enum StatusDerived {
  APPROVED = "Approved",
  REJECTED = "Rejected",
  WITHDRAWN = "Withdrawn",
  UNKNOWN = "Unknown",
  PENDING = "Pending",
}

export enum AppealDecision {
  DISMISSED = "Dismissed",
  ALLOWED = "Allowed",
  NOTICE_UPHELD = "Notice upheld",
  NOTICE_VARIED_AND_UPHELD = "Notice varied and upheld",
  UNKNOWN = "Unknown",
  SPLIT_DECISION = "Split Decision",
  PLANNING_PERMISSION_GRANTED = "Planning permission granted",
  QUASHED_ON_LEGAL_GROUNDS = "Quashed On Legal Grounds",
  APPEAL_WITHDRAWN = "Appeal Withdrawn",
  INVALID = "Invalid",
  ALLOWED_WITH_CONDITIONS = "Allowed with Conditions",
  ALLOWED_IN_PART = "Allowed in part",
  TURNED_AWAY = "Turned Away",
  NO_DECISION_CODE_ASSOCIATED = "No Decision Code Associated",
  NOTICE_QUASHED = "Notice Quashed",
}

type ISODateString = string;

export interface RawApplication {
  _id: string;
  gss_code: string;
  id: string;
  ref: string;
  address: string;
  title: string;
  location: {
    coordinates: [number, number];
  };
  types_derived: TypesDerived[];
  size: number;
  classification: PlanningClassification;
  is_minor?: boolean;
  date_received: ISODateString;
  references: {
    planningId: string;
    originalRef?: string | null;
    calculatedRef?: string | null;
    indexInTitle?: number | null;
  }[];
  isReferenced?: string[] | null;
  status_derived: Nullable<PlanningStatus>;
  appeal_case_number: string | null;
  appeal_decision: string | null;
  appeal_type_of_appeal: string | null;
  appeal_type_reason: string | null;
  appeal_application_ref: string | null;
  appeal_planning_authority_name: string | null;
  appeal_site_address: string | null;
  appeal_received_date: string | null;
  appeal_development_type: string | null;
  appeal_inspector_name: string | null;
  appeal_appellant: string | null;
  appeal_updated_at: string | null;
}

export const ReferenceSchema = z.object({
  planningId: z.string(),
  originalRef: z.string(),
  calculatedRef: z.string(),
  indexInTitle: z.number(),
});

/** Note: This is not reflective of the whole response object & is a snapshot of
 * the values required at the time of writing. */
export const PlanningApplicationSchema = z.object({
  id: z.string(),
  address: z.string(),
  gss_code: z.string(),
  ref: z.string(),
  title: z.string(),
  num_dwellings: z.number(),
  date_received: z.string(),
  created: z.string().optional(),
  types_derived: z.nativeEnum(TypesDerived).array(),
  found_num_dwellings: z.boolean().optional().nullable(),
  classification: z.nativeEnum(PlanningClassification),
  is_minor: z.boolean().optional(),
  size: z.number(),
  postcode: z.string().nullable().optional(),
  uprn: z.string().nullable().optional(),
  local_id: z.string().nullable().optional(),
  agent_name: z.string().nullable(),
  applicant_name: z.string().nullable(),
  decision: z.string().nullable(),
  status: z.string().nullable(),
  status_derived: z.nativeEnum(StatusDerived).nullable(),
  commercial_category: z.string().optional().nullable(),
  date_valid: z.string().optional().nullable(),
  decision_date: z.string().nullable(),
  updated: z.string().nullable(),
  agent_address: z.string().nullable(),
  references: ReferenceSchema.array(),
  tags: z.string().array().optional().nullable(),
  url: z.string().nullable(),
  location: PointSchema.optional().nullable(),
  boundary: PolygonSchema.or(MultiPolygonSchema).optional().nullable(),
  isReferenced: z.string().array(),
  appeal_case_number: z.string().nullable(),
  appeal_type_of_appeal: z.string().nullable(),
  appeal_type_reason: z.string().nullable(),
  appeal_application_ref: z.string().nullable(),
  appeal_planning_authority_name: z.string().nullable(),
  appeal_site_address: z.string().nullable(),
  appeal_decision: z.nativeEnum(AppealDecision).nullable(),
  appeal_decision_date: z.string().nullable(),
  appeal_received_date: z.string().nullable(),
  appeal_development_type: z.string().nullable(),
  appeal_inspector_name: z.string().nullable(),
  appeal_appellant: z.string().nullable(),
  appeal_updated_at: z.string().nullable(),
  other: z.any().nullable(), // TODO: What should the type of this be?
});

export const AddressGroupSchema = z.object({
  address: z.string(),
  applications: PlanningApplicationSchema.array(),
  classifications: z.set(z.nativeEnum(PlanningClassification)),
});

export type AddressGroup = z.infer<typeof AddressGroupSchema>;
export type PlanningApplication = z.infer<typeof PlanningApplicationSchema>;
export type PlanningApplicationReference = z.infer<typeof ReferenceSchema>;

export type PlanningStatus = "Approved" | "Unknown" | "Withdrawn" | "Rejected" | "Pending";

export const planningStatuses: { id: PlanningStatus; name: string }[] = [
  { id: "Approved", name: "Approved" },
  { id: "Withdrawn", name: "Withdrawn" },
  { id: "Pending", name: "Pending" },
  { id: "Rejected", name: "Rejected" },
  { id: "Unknown", name: "Unknown" },
];

export enum AppealStatus {
  PERMITTED = "permitted",
  DISMISSED = "dismissed",
  WITHDRAWN = "withdrawn",
  UNKNOWN = "unknown",
}

export type PlanningFilterKey =
  | "fullApplication"
  | "dischargeOfConditions"
  | "outline"
  | "gpdr"
  | "reservedMatters"
  | "tpo"
  | "listedBuildings"
  | "eia"
  | "changeOfUse"
  | "uncategorised";

export type PlanningTypeFlagKey =
  | "is_full_application"
  | "is_outline"
  | "is_discharge_of_conditions"
  | "is_gpdr"
  | "is_reserved_matters"
  | "is_tpo"
  | "is_listed_building"
  | "is_eia"
  | "is_change_of_use"
  | "is_uncategorised";

export type PlanningTypesFilters = Record<PlanningFilterKey, boolean>;

export interface ClassAndTypeFilters {
  showCommercial: boolean;
  showResidential: boolean;
  showOther: boolean;
  showMinor: boolean;
  maxYear: number;
  minSize: number;
  planningTypes: PlanningTypesFilters;
  appToHide: string;
  visiblePlanningApplicationIds: string[];
  enabledStatuses: PlanningStatus[];
}

export type PlanningApplicationFeatureProps = {
  id: string;
  classification: `${PlanningClassification}`;
  date_received: number;
  size: number;
  is_minor: boolean;
  ref: string;
  extent: string;
  status_derived: Nullable<PlanningStatus>;
} & Record<PlanningTypeFlagKey, boolean>;

export enum PlanningLayers {
  BOUNDARIES = "boundaries",
  DOTS = "dots",
}

export type PlanningApplicationFeature = GeoJSON.Feature<
  GeoJSON.Point | GeoJSON.Polygon | GeoJSON.MultiPolygon,
  PlanningApplicationFeatureProps
> & {
  properties: {
    layerName: `${PlanningLayers}`; // Added to features by deck.gl
    has_boundary: boolean;
  };
  geometry: {
    /**
     * for some reason the official GeoJSON Point feature has an arbitrary
     * length array defined for `coordinates`, this doesn't conform to Deck.gl's
     * Position2D or Position3D.
     */
    coordinates: [number, number] | Position[][];
  };
};

export type PlanningApplicationsSummary = {
  rankedSummaries: PlanningApplicationsSummariesRanked[];
};

export type PlanningApplicationsSummariesRanked = {
  rank: number;
  rankTitle?: string;
  classifiedSummaries: PlanningApplicationsSummariesClassified[];
};

export type PlanningApplicationsSummariesClassified = {
  classification: string;
  applicationsSummaries: string[];
};

export const rankTitleMap: { [rank: number]: string } = {
  1: "Primary Applications",
  2: "Secondary Applications",
  3: "Further Applications",
};
