import Feature from "src/js/stores/user/Feature";
import { ConstraintsCategory } from "../../Constraints/types";
import { ConstraintsLayerType } from "../layer_types/ConstraintsLayerType";
import { Layer } from "../types";

export const OTHER_PROTECTED_AREAS: Layer = {
  id: "other_protected_areas",
  layerType: ConstraintsLayerType,
  title: "Other Protected Areas",
  requiredFeatureAccess: [Feature.planningPolicyLayer],
  toggleable: true,
  description:
    "Although not classified as irreplaceable, these areas will still represent a significant constraint for development or opportunity for biodiversity enhancement",
  hideByDefault: true,
  layerConfig: {
    rootCategory: ConstraintsCategory.PROTECTED_AREAS,
    categorySchema: [
      { key: "national_nature_reserve" },
      { key: "ramsar_site" },
      { key: "special_area_of_conservation" },
      { key: "special_protection_area" },
      { key: "site_of_local_importance_for_nature_conservation_(linc)" },
      { key: "borough_importance_for_nature_conservation_(binc)" },
      { key: "area_of_metropolitan_importance_for_nature_conservation" },
      { key: "local_nature_reserve" },
      { key: "site_of_special_scientific_interest_(sssi)" },
      { key: "wildlife_corridor" },
    ],
  },
};
