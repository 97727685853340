import enGB from "../../../js/locales/en-GB";
import enUS from "../../../js/locales/en-US";
import { createIntl, createIntlCache } from "react-intl";
import { userStore } from "src/js/stores/user/store";
import { Locale } from "../../../js/locales";

export const messages: Record<Locale, Record<string, string>> = {
  en: enGB,
  "en-GB": enGB,
  "en-US": enUS,
};

const cache = createIntlCache();

export const intl = createIntl(
  {
    locale: userStore.locale,
    messages: messages[userStore.locale],
  },
  cache
);

export const generateNewIntl = () =>
  createIntl({ locale: userStore.locale, messages: messages[userStore.locale] }, cache);
