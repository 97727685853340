import type { PickingInfo } from "deck.gl";
import { feature } from "@turf/helpers";
import { OwnershipFeature } from "react-migration/domains/ownership/typings/Feature";
import { getTitleGeometryByTitleNo } from "react-migration/domains/ownership/apollo/getTitleGeometryByTitleNo";
import { generateMergedPolygon } from "react-migration/lib/util/generateMergedPolygon";
import { SelectionType } from "src/js/stores/map/store";
import { logNoGeometryError, logNoTitleNumberError } from "./logUtils";

export const titleClickResolverUK = async (pickInfo: PickingInfo<OwnershipFeature>) => {
  // pickInfo.object is verified as non-null by isOwnershipFeature prior to calling this function
  const object = pickInfo.object!;
  if (!object.properties.title_no) {
    logNoTitleNumberError("titleClickResolverUK", pickInfo);
    return null;
  }

  const { title_no: titleNumber } = object.properties;
  const title = await getTitleGeometryByTitleNo(titleNumber);

  if (!title?.polygons?.length) {
    logNoGeometryError("titleClickResolverUK", pickInfo, titleNumber);
    return null;
  }

  const mergedPolygons = generateMergedPolygon(title?.polygons.map((g) => g.geometry));

  return {
    type: SelectionType.OWNERSHIP_TITLE,
    id: titleNumber,
    feature: feature(mergedPolygons),
  };
};
