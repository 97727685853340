import { ISourcingToolSearchFilter } from "react-migration/domains/search/typings/SourcingToolSpecificFilterTypes";
import { SearchType } from "react-migration/domains/search/constants/searchTypes";

export const WetlandsUSFilter: ISourcingToolSearchFilter = {
  id: "us-wetlands-id",
  type: "checkbox",
  field: "sidebar.react.search_sidebar.us_wetlands",
  must: "MATCH_NONE",
  mustOptions: ["MATCH_ANY", "MATCH_NONE"],
  updateFilterDialogIsVisible: false,
  searchTypes: [SearchType.SOURCING_TOOL_US_FL],
  values: [
    { name: "Estuarine & Marine Deepwater", key: "estuarine_and_marine_deepwater" },
    { name: "Estuarine & Marine Wetland", key: "estuarine_and_marine_wetland" },
    { name: "Freshwater Emergent Wetland", key: "freshwater_emergent_wetland" },
    { name: "Freshwater Forested/Shrub Wetland", key: "freshwater_forested_or_shrub_wetland" },
    { name: "Freshwater Pond", key: "freshwater_pond" },
    { name: "Lake", key: "lake" },
    { name: "Riverine", key: "riverine" },
    { name: "Other", key: "other" },
  ],
};
