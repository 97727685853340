//Formulas rearraged from:
//http://www.ctbuh.org/HighRiseInfo/TallestDatabase/Criteria/HeightCalculator/tabid/1007/language/en-GB/Default.aspx

const lowerTermCommercial = 3.9 + 3.9 / 20;
const lowerTermResidential = 3.1;
const lowerTermUnknown = 3.5 + 2.625 / 25;

const sanityCheck = function (numberFloors) {
  numberFloors = Math.round(numberFloors);
  if (numberFloors < 1) {
    numberFloors = 1;
  }
  return numberFloors;
};

export default {
  PROPERTY_TYPES: {
    RESIDENTIAL: "residential",
    COMMERCIAL: "commercial",
    UNKNOWN: "unknown",
  },
  getPropertyTypeFromClassificationCode: function (classificationCode) {
    if (classificationCode[0] == "C") {
      return this.PROPERTY_TYPES.COMMERCIAL;
    } else if (classificationCode[0] == "R") {
      return this.PROPERTY_TYPES.RESIDENTIAL;
    } else {
      return this.PROPERTY_TYPES.UNKNOWN;
    }
  },
  getGetFloorsFromHeightCommercialBuilding: function (heightToRoofBase, heightToRoofTop) {
    return sanityCheck((heightToRoofTop - 11.7) / lowerTermCommercial);
  },
  getGetFloorsFromHeightResidentialBuilding: function (heightToRoofBase, heightToRoofTop) {
    if (heightToRoofBase < 100) {
      return sanityCheck((heightToRoofBase - 1.1) / 3.0);
    } else {
      return sanityCheck((heightToRoofTop - 7.75) / lowerTermResidential);
    }
  },
  getGetFloorsFromHeightUnknownBuildingType: function (heightToRoofBase, heightToRoofTop) {
    return sanityCheck((heightToRoofTop - 9.625) / lowerTermUnknown);
  },
  getGetFloorsFromHeight: function (heightToRoofBase, heightToRoofTop, properyType) {
    if (properyType == this.PROPERTY_TYPES.COMMERCIAL) {
      return this.getGetFloorsFromHeightCommercialBuilding(heightToRoofBase, heightToRoofTop);
    } else if (properyType == this.PROPERTY_TYPES.RESIDENTIAL) {
      return this.getGetFloorsFromHeightResidentialBuilding(heightToRoofBase, heightToRoofTop);
    } else if (properyType == this.PROPERTY_TYPES.UNKNOWN) {
      return this.getGetFloorsFromHeightUnknownBuildingType(heightToRoofBase, heightToRoofTop);
    } else {
      throw "Invalid property type: " + properyType;
    }
  },
};
