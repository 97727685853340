import { useTranslation } from "react-migration/lib/i18n/useTranslation";
import { Button } from "@landtechnologies/components";
import { router } from "src/js/router/react-router";
import ROUTES from "src/js/router/routes";
import { Transaction } from "../../typings/TransactionsResponse";
import { formatDescription, formatTransactionURL } from "../util/formatters";
import { formatDate } from "react-migration/domains/comparables/util/Dates";

type DescriptionCellProps = { transaction: Transaction };

export const DescriptionCell = ({ transaction }: DescriptionCellProps) => {
  const { t } = useTranslation();
  const transactionUrl = formatTransactionURL(transaction.purchase);

  const handleZoomToDocument = () => {
    const document = transaction.purchase;
    if (!document?.location?.coordinates) return;
    const coordinates = document.location.coordinates;
    router.push({
      name: ROUTES.MAP,
      params: {
        z: "19",
        x: String(coordinates[1]),
        y: String(coordinates[0]),
      },
      query: {
        sidebartype: "ownership",
        clicklatlng: JSON.stringify({
          lat: coordinates[1],
          lng: coordinates[0],
        }),
        zoom: "19",
      },
    });
  };

  return (
    <div className="atlas-min-w-[400px] atlas-flex atlas-justify-between">
      {transactionUrl ? (
        <a href={transactionUrl} target="_blank" rel="noreferrer">
          {formatDescription(transaction)}
        </a>
      ) : (
        <span>{formatDescription(transaction)}</span>
      )}
      {transaction.state.status === "pending" && (
        <span>
          {t("dashboard.transactions.status_pending")} -{" "}
          {transaction.state?.expected_response_time && (
            <>
              {t("dashboard.transactions.expected", {
                date: formatDate(transaction.state.expected_response_time),
              })}
            </>
          )}
        </span>
      )}
      {transaction.state.status === "failure" && (
        <span>{t("dashboard.transactions.status_failure")}</span>
      )}
      {transaction.purchase?.location && (
        <Button.Secondary size="small" className="atlas-ml-2" onClick={handleZoomToDocument}>
          {t("dashboard.transactions.view_on_map")}
        </Button.Secondary>
      )}
    </div>
  );
};
