import { FC } from "react";
import { Theme, DeprecatedTooltip } from "@landtechnologies/components";

import { useTranslation } from "react-migration/lib/i18n/useTranslation";
import { Button } from "react-migration/components/Button";

export interface CalculatorButtonProps {
  openCalculator: () => void;
  hasLicense: boolean;
}

export const CalculatorButton: FC<CalculatorButtonProps> = ({ openCalculator, hasLicense }) => {
  const { t } = useTranslation();

  const openCalc = () => {
    openCalculator();
  };

  return (
    <div
      className="atlas-mt-1 atlas-flex atlas-flex-col atlas-items-center"
      data-testid="open-button"
    >
      {!hasLicense && (
        <DeprecatedTooltip
          theme={Theme.Dark}
          placement="top"
          description={t("comparables.map.sidebar.filters.calculator.tooltip")}
          className="atlas-flex atlas-flex-col atlas-align-middle"
        >
          <Button disabled={true}>
            <i className="icon-lt-calculator-line atlas-mr-1" />
            {t("comparables.map.sidebar.filters.calculator")}
          </Button>
        </DeprecatedTooltip>
      )}
      {hasLicense && (
        <Button onClick={openCalc} data-testid="comparables-calculator">
          <span className="atlas-text-xs md:atlas-text-sm">
            <i className="icon-lt-calculator-line atlas-mr-1" />
            {t("comparables.map.sidebar.filters.calculator")}
          </span>
        </Button>
      )}
    </div>
  );
};
