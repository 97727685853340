import { AttributeComparisonSummaryProps } from "./types";
import { AttributeComparisonSummarySection } from "./AttributeComparisonSummarySection";
import { HorizontalBarChart } from "./charts/HorizontalBarChart";
import { useMemo } from "react";
import { useDemographicsColourAccessor } from "./charts/colorUtils";
import * as ATTRS from "./OutputAreaAttributesUS";
import { AttributeSummaryTable } from "./charts/AttributeSummaryTable";
import { hasAnyRequiredAttribute } from "./utils";

export function AttributeComparisonSummaryUS({
  outputArea,
  comparableDesignation,
}: AttributeComparisonSummaryProps) {
  const colorAccessor = useDemographicsColourAccessor(outputArea, comparableDesignation);

  const sections = useMemo(
    () => ({
      employment: hasAnyRequiredAttribute(ATTRS.UNEMPLOYMENT_ATTRS, outputArea),
      income: hasAnyRequiredAttribute(ATTRS.INCOME_ATTRS, outputArea),
      population: hasAnyRequiredAttribute(ATTRS.POPULATION_ATTRS, outputArea),
      age: hasAnyRequiredAttribute(ATTRS.AGE_ATTRS, outputArea),
      housing: hasAnyRequiredAttribute(ATTRS.HOUSING_UNITS_ATTRS, outputArea),
      households: hasAnyRequiredAttribute(ATTRS.HOUSEHOLD_ATTRS, outputArea),
    }),
    [outputArea]
  );

  return (
    <>
      {sections.employment && (
        <AttributeComparisonSummarySection
          heading="Unemployment"
          designation={outputArea}
          comparableDesignation={comparableDesignation}
          headlineAttribute={ATTRS.UNEMPLOYMENT_ATTRS[0]}
        >
          <AttributeSummaryTable
            attributes={ATTRS.UNEMPLOYMENT_ATTRS}
            designation={outputArea}
            comparativeDesignation={comparableDesignation}
            colorAccessor={colorAccessor}
          />
        </AttributeComparisonSummarySection>
      )}
      {sections.income && (
        <AttributeComparisonSummarySection
          heading="Income"
          designation={outputArea}
          comparableDesignation={comparableDesignation}
          headlineAttribute={ATTRS.INCOME_ATTRS[0]}
        >
          <AttributeSummaryTable
            attributes={ATTRS.INCOME_ATTRS}
            designation={outputArea}
            comparativeDesignation={comparableDesignation}
            colorAccessor={colorAccessor}
          />
        </AttributeComparisonSummarySection>
      )}

      {sections.population && (
        <AttributeComparisonSummarySection
          heading="Population"
          designation={outputArea}
          comparableDesignation={comparableDesignation}
          headlineAttribute={ATTRS.POPULATION_ATTRS[0]}
        >
          <AttributeSummaryTable
            attributes={ATTRS.POPULATION_ATTRS}
            designation={outputArea}
            comparativeDesignation={comparableDesignation}
            colorAccessor={colorAccessor}
          />
        </AttributeComparisonSummarySection>
      )}

      {sections.age && (
        <AttributeComparisonSummarySection
          heading="Age"
          designation={outputArea}
          comparableDesignation={comparableDesignation}
          headlineAttribute={ATTRS.AGE_ATTRS[0]}
        >
          <AttributeSummaryTable
            attributes={ATTRS.AGE_ATTRS}
            designation={outputArea}
            comparativeDesignation={comparableDesignation}
            colorAccessor={colorAccessor}
          />
          <HorizontalBarChart
            designation={outputArea}
            comparativeDesignation={comparableDesignation}
            attributes={ATTRS.AGE_ATTRS.slice(1)}
            colorAccessor={colorAccessor}
          />
        </AttributeComparisonSummarySection>
      )}

      {sections.housing && (
        <AttributeComparisonSummarySection
          heading="Housing"
          designation={outputArea}
          comparableDesignation={comparableDesignation}
          headlineAttribute={ATTRS.HOUSING_UNITS_ATTRS[0]}
        >
          <AttributeSummaryTable
            attributes={ATTRS.HOUSING_UNITS_ATTRS}
            designation={outputArea}
            comparativeDesignation={comparableDesignation}
            colorAccessor={colorAccessor}
          />
        </AttributeComparisonSummarySection>
      )}

      {sections.households && (
        <AttributeComparisonSummarySection
          heading="Households"
          designation={outputArea}
          comparableDesignation={comparableDesignation}
          headlineAttribute={ATTRS.HOUSEHOLD_ATTRS[0]}
        >
          <AttributeSummaryTable
            attributes={ATTRS.HOUSEHOLD_ATTRS}
            designation={outputArea}
            comparativeDesignation={comparableDesignation}
            colorAccessor={colorAccessor}
          />
        </AttributeComparisonSummarySection>
      )}
    </>
  );
}
