import { Button } from "@landtechnologies/components";
import { useTranslation } from "react-migration/lib/i18n/useTranslation";
import { router } from "src/js/router/react-router";
import ROUTES from "src/js/router/routes";

type SiteHeaderProps = {
  _id: string;
  results?: number | undefined;
  title: string;
};

export const SiteHeader = ({ _id, results, title }: SiteHeaderProps) => {
  const { t } = useTranslation();
  const handleRedirectToSite = () => {
    router.push({
      name: ROUTES.SITES,
      query: {
        card_page: "summary",
        site_id: _id,
      },
    });
  };

  return (
    <div className="atlas-flex atlas-justify-between atlas-items-center atlas-pb-2">
      <div className="atlas-flex atlas-items-baseline atlas-gap-2">
        <h3 className="atlas-text-base atlas-text-neutral-800 atlas-font-semibold">{title}</h3>
        <span className="atlas-flex atlas-gap-1 atlas-text-neutral-600">
          <span className="atlas-font-semibold">{results}</span>
          <span>{t("dashboard.planning_alerts.results")}</span>
        </span>
      </div>
      <div className="atlas-min-w-[130px] atlas-flex atlas-justify-end">
        <Button.Secondary onClick={handleRedirectToSite}>
          {t("dashboard.planning_alerts.go_to_site_btn")}
        </Button.Secondary>
      </div>
    </div>
  );
};
