/* eslint-disable react/display-name */
import { useCallback, useEffect, useMemo, useState, useRef, forwardRef, ForwardedRef } from "react";

import { CATEGORY_STYLES } from "react-migration/domains/constraints/designation/style/StyleMap";
import { ConstraintsCategory } from "react-migration/layouts/map/Constraints/types";
import { DesignationsReportSection } from "./DesignationsReportSection";
import Feature from "src/js/stores/user/Feature";
import { ReportSection } from "./Sections";
import { SiteOverview } from "react-migration/domains/sites/typings/Site";
import { chain } from "lodash";
import hasFeature from "src/js/stores/user/actions/hasFeature";
import { useDesignationsByGeometry } from "react-migration/domains/constraints/designation/hooks/useDesignationsByGeometry";
import { useTranslation } from "react-migration/lib/i18n/useTranslation";

const featureCategories = [
  {
    feature: Feature.planningPolicyLayer,
    sectionKey: ReportSection.DESIGNATIONS,
    categories: [
      ConstraintsCategory.POLICY,
      ConstraintsCategory.AGRICULTURAL_LAND,
      ConstraintsCategory.SHLAA,
    ],
  },
  {
    id: "greyBelt",
    feature: Feature.planningPolicyLayer,
    sectionKey: ReportSection.DESIGNATIONS,
    categories: [ConstraintsCategory.GREY_BELT],
    heading: "Grey Belt",
  },
  // Basic users get all policy when they shouldn't currently, we are maintaining this behaviour here to increase uptake.
  // Eventually give all users full site report or just a summary, talk with Product.
  {
    feature: Feature.siteReport,
    sectionKey: ReportSection.DESIGNATIONS,
    categories: [ConstraintsCategory.POLICY],
  },
  {
    feature: Feature.powerLayer,
    sectionKey: ReportSection.POWER,
    categories: [ConstraintsCategory.POWER, ConstraintsCategory.ENERGY_PROJECTS],
  },
  {
    feature: Feature.usAccess,
    sectionKey: ReportSection.ZONING,
    categories: [ConstraintsCategory.ZONING],
  },
  {
    feature: Feature.usAccess,
    sectionKey: ReportSection.HAZARDS,
    categories: [ConstraintsCategory.HAZARDS],
  },
  {
    feature: Feature.usAccess,
    sectionKey: ReportSection.DEMOGRAPHICS,
    categories: [ConstraintsCategory.US_DEMOGRAPHICS],
  },
  {
    feature: Feature.usAccess,
    sectionKey: ReportSection.PERMITS,
    categories: [ConstraintsCategory.BUILDING_PERMITS],
  },
];

type DesignationsReportProps = {
  site: SiteOverview;
  visibleSections: ReportSection[];
  onLoaded: (isLoaded: boolean) => void;
};

export const DesignationsReport = forwardRef(
  (
    { site, visibleSections, onLoaded = () => false }: DesignationsReportProps,
    designationsReportRef: ForwardedRef<HTMLDivElement>
  ) => {
    const { t } = useTranslation();
    const [loadedMaps, setLoadedMaps] = useState<string[]>([]);
    const isPrintView = useMemo(() => {
      const params = new URLSearchParams(document.location.search);
      return Boolean(params.get("isPrint"));
    }, []);

    const designationRefs = useRef<HTMLDivElement[]>([]);

    const categoriesToInclude = useMemo(
      () => [
        ...new Set(
          featureCategories
            .filter(
              (featureCategory) =>
                hasFeature(featureCategory.feature) &&
                visibleSections.includes(featureCategory.sectionKey)
            )
            .map((featureCategory) => featureCategory.categories)
            .flat()
        ),
      ],
      [visibleSections]
    );

    const { designations } = useDesignationsByGeometry({
      geometry: site && { type: site.geometry.type, coordinates: site.geometry.coordinates },
      rootCategories: categoriesToInclude,
      /** This more closely matches the behaviour in the app - specifically for
       * datasets such as "grey belt". We walk the geometry in by a meter to
       * prevent false positives with neighbouring geospatial data.  */
      bufferMeters: -1,
      queryOptions: { fetchPolicy: isPrintView ? "no-cache" : "cache-and-network" },
    });

    const groupedDesignations = useMemo(() => {
      const filteredDesignations =
        designations?.filter(
          (designation) =>
            designation.sub_category_id &&
            Object.keys(CATEGORY_STYLES).includes(designation.sub_category_id)
        ) || [];
      return chain(filteredDesignations)
        .groupBy((designation) => designation.sub_category_id)
        .map((designationGroup) => ({
          category: designationGroup[0].sub_category,
          designations: designationGroup,
        }))
        .value();
    }, [designations]);

    const onMapLoaded = useCallback(
      (isLoaded: boolean, categoryId: string) => {
        if (isLoaded && !loadedMaps.includes(categoryId)) {
          setLoadedMaps((prev) => [...prev, categoryId]);
          if (isPrintView) {
            const designationElementIndex = designationRefs.current.findIndex(
              (designation) => designation.id === categoryId
            );
            designationRefs.current[designationElementIndex].scrollIntoView();
          }
        }
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [loadedMaps, setLoadedMaps]
    );

    useEffect(() => {
      if (loadedMaps.length > 0 && loadedMaps.length === groupedDesignations.length) {
        onLoaded(true);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loadedMaps, groupedDesignations]);

    return (
      <div
        data-testid="designations-report"
        ref={designationsReportRef}
        className="atlas-mb-10 print:atlas-mt-4 print:atlas-break-before-page"
      >
        <h2 className="atlas-mb-4">{t("sites.card.site_report.considerations")}</h2>
        {groupedDesignations.map(({ category, designations }, index) => {
          if (!category) {
            return null;
          } else {
            return (
              <div
                ref={(el: HTMLDivElement) => (designationRefs.current[index] = el)}
                id={category?.id}
                key={category?.id}
              >
                <DesignationsReportSection
                  designations={designations}
                  category={category}
                  site={site}
                  onMapLoaded={onMapLoaded}
                />
              </div>
            );
          }
        })}
      </div>
    );
  }
);
