import { RecordType } from "react-migration/domains/comparables/typings/Record";
import { Translation } from "react-migration/lib/typings";

export interface PropertyTypeTitleProps {
  t: Translation;
}
export const propertyTypeTitle = ({ t }: PropertyTypeTitleProps) =>
  t("comparables.map.sidebar.value.record.type.title");

export interface PropertyTypeValueProps {
  t: Translation;
  record: RecordType;
}
export const propertyTypeValue = ({ t, record }: PropertyTypeValueProps) => {
  const designationCode = `comparables.designation.${record.designation.toLowerCase()}`;
  const categoryCode = `comparables.category.${record.category.toLowerCase()}`;
  const subcategoryCode = `comparables.subcategory.${record.sub_category.toLowerCase()}`;
  const designationText = t(designationCode);
  const categoryText = t(categoryCode);
  const subcategoryText = t(subcategoryCode);
  const output = (
    record.country == "US" ? [designationText] : [categoryText, subcategoryText]
  ).filter((text) => /^comparables\./.test(text) === false);
  if (output.length) {
    return output.join(" / ");
  }
  return t("comparables.unknown");
};
