/* eslint-disable react/jsx-no-target-blank */
import { FC } from "react";
import { Button, Theme, DeprecatedTooltip } from "@landtechnologies/components";

import { useTranslation } from "react-migration/lib/i18n/useTranslation";
import { logEvent } from "react-migration/lib/util/logEvent";

import {
  PriceModes,
  ZooplaPricePerAreaModes,
  ZooplaPricePerUnitModes,
} from "../../../typings/PriceModes";
import { RecordType } from "../../../typings/Record";
import { epcSearchURL, saleAdvertURL } from "react-migration/domains/comparables/util/Links";

export interface RecordActionsProps {
  record: RecordType;
  priceMode: PriceModes;
}

export const RecordActions: FC<RecordActionsProps> = ({ record, priceMode }) => {
  const { t } = useTranslation();

  return (
    <div className="atlas-flex atlas-mt-2 atlas-justify-end" data-testid="record-actions">
      {shouldRenderSaleAdvert() && (
        <div data-testid="find-sale">
          <DeprecatedTooltip
            theme={Theme.Dark}
            placement="top"
            description={t("comparables.map.sidebar.value.advert.tooltip")}
          >
            <a href={saleAdvertURL({ address: record.address })} target="_blank">
              <Button.Primary onClick={() => logEvent("Comparables Find Sale", { priceMode })}>
                <span className="atlas-text-xs md:atlas-text-sm">
                  {t("comparables.map.sidebar.value.advert")}
                </span>
                <i className="icon-lt-external-link-line atlas-text-sm md:atlas-text-lg atlas-pl-1"></i>
              </Button.Primary>
            </a>
          </DeprecatedTooltip>
        </div>
      )}
      {record.country === "GB" ? (
        <div className="atlas-ml-2" data-testid="find-epc">
          <DeprecatedTooltip
            theme={Theme.Dark}
            placement="top"
            description={t("comparables.map.sidebar.value.epc.tooltip")}
          >
            <a href={epcSearchURL(record)} target="_blank">
              <Button.Primary onClick={() => logEvent("Comparables Find EPC", { priceMode })}>
                <span className="atlas-text-xs md:atlas-text-sm">
                  {t("comparables.map.sidebar.value.epc")}
                </span>
                <i className="icon-lt-external-link-line atlas-text-sm md:atlas-text-lg atlas-pl-1"></i>
              </Button.Primary>
            </a>
          </DeprecatedTooltip>
        </div>
      ) : null}
    </div>
  );

  function shouldRenderSaleAdvert() {
    return (
      priceMode !== ZooplaPricePerUnitModes.RENT_ESTIMATE &&
      priceMode !== ZooplaPricePerUnitModes.RENT_ASKING &&
      priceMode !== ZooplaPricePerAreaModes.RENT_ESTIMATE &&
      priceMode !== ZooplaPricePerAreaModes.RENT_ASKING
    );
  }
};
