import { createPortal } from "react-dom";
import { Avatar, DropdownMenu, DropdownMenuSize } from "@landtechnologies/components";

import Feature from "src/js/stores/user/Feature";
import { Intercom } from "src/js/util/intercom";
import { ENVIRONMENT } from "src/js/util/environment";
import { refreshUser } from "src/js/util/user";
import { useSnapshot } from "valtio";
import { useTranslation } from "react-migration/lib/i18n/useTranslation";
import { useAccountQuery } from "react-migration/domains/dashboard/topup/hooks/useAccountQuery";
import { userStore } from "src/js/stores/user/store";
import hasFeature from "src/js/stores/user/actions/hasFeature";

import { formatToCurrency } from "react-migration/lib/util/numberFormat";
import { logEvent } from "react-migration/lib/util/logEvent";
import { Button } from "react-migration/components/Button";

import {
  BalanceUKIcon,
  BalanceUSIcon,
  BillingIcon,
  ChatIcon,
  ContactIcon,
  LogoutIcon,
  PersonIcon,
} from "../Icons";
import { setShowTopupModal } from "src/js/stores/navigation/actions";

export const UserMenu = () => {
  const { t } = useTranslation();
  const { user } = useSnapshot(userStore);
  const { data: accountData } = useAccountQuery();

  const openTopupModal = () => {
    setShowTopupModal(true);
    logEvent("Top Up Started", { location: "modal" });
  };

  return (
    <DropdownMenu.Provider size={DropdownMenuSize.AUTO}>
      <DropdownMenu.Trigger
        data-testid="usermenu-trigger-element"
        align={DropdownMenu.TriggerAlignment["BOTTOM"]}
      >
        <Avatar
          initials={`${user?.firstname?.[0]}${user?.surname?.[0]}`.toUpperCase()}
          className="!atlas-rounded-full !atlas-w-8 !atlas-h-8 !atlas-text-sm atlas-relative"
        />
      </DropdownMenu.Trigger>
      {createPortal(
        <DropdownMenu.Content className="atlas-min-w-[242px]">
          <DropdownMenu.ItemText>
            <div
              data-testid="your-account"
              className="atlas-flex atlas-flex-row atlas-items-center atlas-flex-1"
            >
              <Avatar
                initials={`${user?.firstname?.[0]}${user?.surname?.[0]}`.toUpperCase()}
                className="atlas-flex-none atlas-mr-2 !atlas-rounded-full"
              />
              <div className="atlas-flex atlas-grow">
                <div className="atlas-flex atlas-flex-col">
                  <span className="atlas-text-sm">{`${user.firstname} ${user.surname}`}</span>
                  <span className="atlas-text-xs atlas-capitalize atlas-text-neutral-5">
                    {`${user.plan_name} Plan`}
                  </span>
                </div>
              </div>
              <div className="atlas-flex-none">
                <PersonIcon />
              </div>
            </div>
          </DropdownMenu.ItemText>
          <DropdownMenu.ItemLink
            href={`${ENVIRONMENT.ACCOUNTS_SERVICE_PRIVATE_API_URL}/customer-portal`}
            data-testid="logout-element"
            className="atlas-flex atlas-items-center atlas-gap-2"
          >
            <BillingIcon />
            <span className="atlas-text-sm">{t("nav.nav.billing")}</span>
          </DropdownMenu.ItemLink>
          <DropdownMenu.Item
            data-testid="logout-element"
            className="atlas-flex atlas-items-center atlas-gap-2"
            disabled={!hasFeature(Feature.documentPurchase) && !hasFeature(Feature.usAccess)}
            onClick={() => openTopupModal()}
          >
            {hasFeature(Feature.usAccess) ? <BalanceUSIcon /> : <BalanceUKIcon />}
            <span className="atlas-text-sm">{t("nav.nav.balance")}</span>
            <span className="atlas-text-sm">
              {(accountData?.accountWithViews?.land_reg_credit_pounds || 0) < 0 && "-"}
              {`${formatToCurrency({
                amount: accountData?.accountWithViews.land_reg_credit_pounds || 0,
                currency: hasFeature(Feature.usAccess) ? "USD" : "GBP",
              })}`}
            </span>
          </DropdownMenu.Item>
          <DropdownMenu.Separator />
          <DropdownMenu.ItemText>
            <div
              data-testid="your-account"
              className="atlas-flex atlas-flex-row atlas-items-center atlas-flex-1"
            >
              <div className="atlas-flex atlas-grow">
                <div className="atlas-flex atlas-flex-col">
                  <span className="atlas-text-sm">{t("nav.nav.support")}</span>
                  <span className="atlas-text-sm atlas-capitalize atlas-text-neutral-5">
                    {hasFeature(Feature.usAccess) ? "calum@land.tech" : "0203 086 7855"}
                  </span>
                </div>
              </div>
              <div className="atlas-flex-none">
                <ContactIcon />
              </div>
            </div>
          </DropdownMenu.ItemText>
          <DropdownMenu.Item
            data-testid="logout-element"
            className="atlas-flex atlas-items-center atlas-gap-2"
            onClick={() => Intercom("show")}
          >
            <ChatIcon />
            <span className="atlas-text-sm">{t("nav.nav.chat")}</span>
          </DropdownMenu.Item>

          <DropdownMenu.Separator />
          <DropdownMenu.ItemLink
            href={ENVIRONMENT.LOGOUT_URL}
            data-testid="logout-element"
            className="atlas-flex atlas-items-center atlas-gap-2"
          >
            <LogoutIcon />
            <span className="atlas-text-sm">{t("nav.nav.logout")}</span>
          </DropdownMenu.ItemLink>
          {ENVIRONMENT.APP_ENV === "local" && (
            <DropdownMenu.ItemLink
              data-testid="refresh-user"
              className="atlas-flex atlas-items-center atlas-gap-2 atlas-cursor-pointer"
              onClick={() => refreshUser()}
            >
              <PersonIcon />
              <span className="atlas-text-sm">Refresh User</span>
            </DropdownMenu.ItemLink>
          )}
        </DropdownMenu.Content>,
        document.body
      )}
    </DropdownMenu.Provider>
  );
};
