import coreAttributes from "./core.json";
import sidebarAttributes from "./sidebar.json";
import legendAttributes from "./legend.json";
import calculatorAttributes from "./calculator.json";

export default {
  ...coreAttributes,
  ...sidebarAttributes,
  ...legendAttributes,
  ...calculatorAttributes,
};
