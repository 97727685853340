import { NavigationStepType, StepStatus } from "../types";

export const PurchaseTitleModalNavigation = ({ steps }: { steps: NavigationStepType[] }) => {
  return (
    <ol className="atlas-list-none atlas-border atlas-border-border-divider atlas-divide-y atlas-divide-border-divide atlas-rounded-md md:atlas-flex md:atlas-divide-y-0">
      {steps.map((step, stepIdx) => (
        <li key={step.name} className="atlas-relative md:atlas-flex md:atlas-flex-1">
          {step.status === StepStatus.Complete && <StepComplete step={step} />}
          {step.status === StepStatus.Current && <StepCurrent step={step} />}
          {step.status === StepStatus.Upcoming && <StepUpcoming step={step} />}
          {stepIdx !== steps.length - 1 ? <ArrowSeparator /> : null}
        </li>
      ))}
    </ol>
  );
};

const StepComplete = ({ step }: { step: NavigationStepType }) => {
  return (
    <div
      onClick={step.onClick}
      className="atlas-flex atlas-items-center atlas-w-full atlas-group atlas-cursor-pointer"
    >
      <span className="atlas-flex atlas-items-center atlas-px-3 atlas-py-2 atlas-text-sm atlas-font-medium">
        <span className="atlas-flex atlas-items-center atlas-justify-center atlas-flex-shrink-0 atlas-w-10 atlas-h-10 atlas-bg-background-action atlas-rounded-full group-hover:atlas-bg-background-action-hover">
          <div className="atlas-w-6 atlas-h-6 atlas-text-white">
            <TickIcon />
          </div>
        </span>
        <span className="atlas-ml-4 atlas-text-sm atlas-font-medium atlas-text-content-disabled">
          {step.name}
        </span>
      </span>
    </div>
  );
};
const StepCurrent = ({ step }: { step: NavigationStepType }) => {
  return (
    <div
      onClick={() => step.onClick}
      className="atlas-flex atlas-items-center atlas-px-3 atlas-py-2 atlas-text-sm atlas-font-medium atlas-cursor-pointer"
      aria-current="step"
    >
      <span className="atlas-flex atlas-items-center atlas-justify-center atlas-flex-shrink-0 atlas-w-10 atlas-h-10 atlas-border-2 atlas-border-border-action-hover atlas-rounded-full">
        <span className="atlas-text-border-action-hover">{step.id}</span>
      </span>
      <span className="atlas-ml-4 atlas-text-sm atlas-font-medium atlas-text-border-action-hover">
        {step.name}
      </span>
    </div>
  );
};
const StepUpcoming = ({ step }: { step: NavigationStepType }) => {
  return (
    <div onClick={() => step.onClick} className="atlas-flex atlas-items-center atlas-group">
      <span className="atlas-flex atlas-items-center atlas-px-3 atlas-py-2 atlas-text-sm atlas-font-medium atlas-cursor-pointer">
        <span className="atlas-flex atlas-items-center atlas-justify-center atlas-flex-shrink-0 atlas-w-10 atlas-h-10 atlas-border-2 atlas-border-content-placeholder atlas-rounded-full group-hover:atlas-border-content-disabled">
          <span className="atlas-text-content-placeholder group-hover:atlas-text-content-disabled">
            {step.id}
          </span>
        </span>
        <span className="atlas-ml-4 atlas-text-sm atlas-font-medium atlas-text-content-placeholder group-hover:atlas-text-content-disabled">
          {step.name}
        </span>
      </span>
    </div>
  );
};

const ArrowSeparator = () => {
  return (
    <div
      className="atlas-absolute atlas-top-0 atlas-right-0 atlas-hidden atlas-w-5 atlas-h-full md:atlas-block"
      aria-hidden="true"
    >
      <svg
        className="atlas-w-full atlas-h-full atlas-text-border-divider"
        viewBox="0 0 22 80"
        fill="none"
        preserveAspectRatio="none"
      >
        <path
          d="M0 -2L20 40L0 82"
          vectorEffect="non-scaling-stroke"
          stroke="currentcolor"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  );
};

const TickIcon = () => {
  return (
    <svg
      className="atlas-h-6 atlas-w-6 atlas-text-white"
      viewBox="0 0 24 24"
      fill="currentColor"
      aria-hidden="true"
    >
      <path
        fillRule="evenodd"
        d="M19.916 4.626a.75.75 0 01.208 1.04l-9 13.5a.75.75 0 01-1.154.114l-6-6a.75.75 0 011.06-1.06l5.353 5.353 8.493-12.739a.75.75 0 011.04-.208z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
};
