import { Designation } from "react-migration/layouts/designations/Designation";
import { Truncated } from "../../Bundle/Workbench/Truncated";
import type { LayerTypeSelectionDetailViewProps } from "../../types";

export function DesignationSelectionDetailView({
  selection,
  setDetailSelection,
  isSummary,
}: LayerTypeSelectionDetailViewProps) {
  const handleSeeMoreInfo = () => {
    setDetailSelection && setDetailSelection(selection);
  };

  return (
    <Truncated
      onSeeMoreInfo={() => handleSeeMoreInfo && handleSeeMoreInfo()}
      isExpanded={!isSummary}
    >
      <Designation designationId={selection.id} />
    </Truncated>
  );
}
