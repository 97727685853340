import { useCallback, useEffect, useMemo, useState } from "react";
import { useLocalStorage } from "usehooks-ts";
import { SiteReportStoredLogo } from "react-migration/domains/sites/typings/SiteReportStoredLogo";
import { convertFileToBase64 } from "react-migration/lib/util/convertFileToBase64";
import { convertBase64ToFile } from "react-migration/lib/util/convertBase64ToFile";
import { Nullable } from "src/js/types/Nullable";
import * as z from "zod";

const MAX_FILE_SIZE = 1024 * 1024 * 5;
const ACCEPTED_IMAGE_MIME_TYPES = ["image/jpeg", "image/jpg", "image/png"];

const logoSchema = z
  .any()
  .refine((file) => file.size <= MAX_FILE_SIZE, `Max image size is 5MB.`)
  .refine(
    (file) => ACCEPTED_IMAGE_MIME_TYPES.includes(file.type),
    "Only .jpg, .jpeg and .png formats are supported."
  );

export function useSiteReportLogo(siteId: string) {
  const logoStorageKey = useMemo(() => siteId + "_logo", [siteId]);

  const [logo, setLogo] = useLocalStorage<Nullable<SiteReportStoredLogo>>(logoStorageKey, null);
  const [error, setError] = useState<string>();

  const logoFile = useMemo(() => logo && convertBase64ToFile(logo.data, logo.fileName), [logo]);

  const handleLogoUpload = useCallback(
    async (logoFile?: File) => {
      const parseLogoFile = logoSchema.safeParse(logoFile);

      if (parseLogoFile.success === false) {
        setError(parseLogoFile.error.errors.map((error) => error.message).join(", "));
      } else {
        const conversionResult = await convertFileToBase64(parseLogoFile.data);
        setLogo(conversionResult);
      }
    },
    [setLogo]
  );

  useEffect(() => {
    let timeout: NodeJS.Timeout;

    if (error) {
      timeout = setTimeout(() => setError(undefined), 5000);
    }

    return () => timeout && clearTimeout(timeout);
  }, [error]);

  return { logoFile, logo, error, handleLogoUpload };
}
