import classNames from "classnames";
import { ReactNode } from "react";

const frameBorderClass = [
  "atlas-flex",
  "atlas-flex-col",
  "atlas-grow",
  "atlas-border",
  "atlas-border-background-darker",
  "atlas-rounded-lg",
  "atlas-overflow-hidden",
];

const sectionHeaderClass = [
  "atlas-px-4",
  "atlas-py-3",
  "atlas-flex",
  "atlas-items-center",
  "atlas-border-b",
  "atlas-border-background-darker",
  "atlas-rounded-t-lg",
  "atlas-bg-background-white",
  "atlas-justify-between",
];

const titleClass = ["atlas-font-semibold", "atlas-text-base", "atlas-leading-6"];

type FormProps = {
  title?: string;
  headerItem?: ReactNode;
  children: ReactNode;
  footerItem?: ReactNode;
};

type PreviewProps = {
  title?: string;
  headerItem?: ReactNode;
  children: ReactNode;
};

const EditorFormContainer = ({ title, headerItem, children, footerItem }: FormProps) => {
  return (
    <div
      className={classNames("atlas-max-w-[500px]", "atlas-min-w-[500px]", frameBorderClass)}
      data-testid="container-form-wrapper"
    >
      <div className={classNames(sectionHeaderClass)} data-testid="container-form-header">
        <span className={classNames(titleClass)}>{title}</span>
        {headerItem}
      </div>
      <div
        className={classNames("atlas-overflow-auto", "atlas-h-full")}
        data-testid="container-form-body"
      >
        {children}
      </div>
      {!!footerItem && (
        <footer
          className={classNames(
            "atlas-flex",
            "atlas-justify-end",
            "atlas-border-t",
            "atlas-border-background-darker",
            "atlas-bg-background-white",
            "atlas-p-2"
          )}
        >
          {footerItem}
        </footer>
      )}
    </div>
  );
};

const EditorPreviewContainer = ({ title, headerItem, children }: PreviewProps) => {
  return (
    <div
      className={classNames("atlas-w-fit", frameBorderClass)}
      data-testid="container-preview-wrapper"
    >
      <div className={classNames(sectionHeaderClass)} data-testid="container-preview-header">
        <span className={classNames(titleClass)}>{title}</span>
        {headerItem}
      </div>
      <div className="atlas-grow atlas-overflow-auto" data-testid="container-preview-body">
        {children}
      </div>
    </div>
  );
};

const EditorContainer = ({ children }: { children: ReactNode[] }) => {
  return (
    <div
      className={classNames(
        "atlas-flex",
        "atlas-p-6",
        "atlas-w-full",
        "atlas-h-full",
        "atlas-gap-x-6",
        "atlas-bg-background-light"
      )}
      data-testid="editor-container"
    >
      {children}
    </div>
  );
};

EditorContainer.Form = EditorFormContainer;
EditorContainer.Preview = EditorPreviewContainer;

export default EditorContainer;
