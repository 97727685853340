import { useSnapshot } from "valtio";
import { router } from "src/js/router/react-router";
import ROUTES from "src/js/router/routes";
import { clearStore as clearLetterStore } from "src/js/stores/letter/actions";

import { useLetterCreator } from "../../../hooks/useLetterCreator/useLetterCreator";
import { LetterEventName, logLetterEvent } from "../../helpers/logLetterEvent";
import { siteStore } from "src/js/stores/site/store";
import { letterStore } from "src/js/stores/letter/store";
import { LetterLoading } from "../../components/FinalStep/LetterLoading";
import { LetterError } from "../../components/FinalStep/LetterError";
import { LetterSuccess } from "../../components/FinalStep/LetterSuccess";

export const DownloadLetter = () => {
  const { siteCardId } = useSnapshot(siteStore);
  const { selectedStageId } = useSnapshot(letterStore);

  const { loading, error } = useLetterCreator();

  const handleNavigateToLettersTab = () => {
    logLetterEvent({
      name: LetterEventName.LETTERS_FORM_FINISH_STEP_VIEW_LETTERS_BUTTON_CLICKED,
    });
    clearLetterStore();
    router.push({
      name: ROUTES.SITES,
      ...(siteCardId && {
        query: {
          card_page: "letters",
          site_id: siteCardId,
        },
      }),
    });
  };

  const handleNavigateToLetterBatches = () => {
    logLetterEvent({
      name: LetterEventName.LETTERS_FORM_FINISH_STEP_VIEW_LETTER_CAMPAIGNS_BUTTON_CLICKED,
    });
    clearLetterStore();
    router.push({
      name: ROUTES.LETTERS_BATCHES,
    });
  };

  const handleGoToSites = () => {
    logLetterEvent({
      name: LetterEventName.LETTERS_FORM_FINISH_STEP_GO_TO_SAVED_SITES_BUTTON_CLICKED,
    });
    clearLetterStore();
    router.push({
      name: ROUTES.SITES,
    });
  };

  if (loading) {
    return <LetterLoading />;
  } else if (error) {
    return (
      <LetterError onBackClick={selectedStageId ? handleGoToSites : handleNavigateToLettersTab} />
    );
  } else {
    return (
      <LetterSuccess
        onViewLettersClick={
          selectedStageId ? handleNavigateToLetterBatches : handleNavigateToLettersTab
        }
        onGoToSitesClick={handleGoToSites}
      />
    );
  }
};
