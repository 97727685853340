import { TransactionPurchaseType } from "react-migration/domains/ownership/typings/Transaction";

export enum PurchaseLocations {
  SiteCard = "SiteCard",
  Map = "Map",
}

export enum PurchaseType {
  Purchase = "purchase",
  Repurchase = "repurchase",
}

export enum PaymentTypes {
  Balance = "BALANCE",
  HMLR = "HMLR_ACCOUNT",
}

export type PurchaseTitleModalProps = {
  title: string;
  titlesToPurchase: string[];
  onCancel: () => void;
  transactionPurchaseType: TransactionPurchaseType;
  location: PurchaseLocations;
  purchaseType?: PurchaseType;
};

export enum StepStatus {
  Current = "current",
  Complete = "complete",
  Upcoming = "upcoming",
}

export type NavigationStepType = {
  id: number;
  name: string;
  status: StepStatus;
  onClick: () => void;
};
