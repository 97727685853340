import { RouteConfig } from "vue-router";
import ROUTES from "../routes";
import OwnershipAlertsWrapper from "view/wrappers/dashboard/ownership_alerts_wrapper.vue";
import PlanningAlertsWrapper from "view/wrappers/dashboard/planning_alerts_wrapper.vue";
import PlanningAlertDetailsWrapper from "view/wrappers/dashboard/planning_alert_details_wrapper.vue";
import TransactionsWrapper from "view/wrappers/dashboard/transactions_wrapper.vue";
import DocumentsWrapper from "view/wrappers/dashboard/documents_wrapper.vue";
import TopupWrapper from "view/wrappers/dashboard/topup_wrapper.vue";
import YourAddressWrapper from "view/wrappers/dashboard/your_address_wrapper.vue";
import IntegrationsWrapper from "view/wrappers/dashboard/integrations_wrapper.vue";

export const DashboardRoutes: Array<RouteConfig> = [
  {
    path: "/dashboard",
    component: () =>
      import(
        /* webpackChunkName: 'dashboard' */
        /* webpackPrefetch: true */
        "../../view/wrappers/dashboard/dashboard.vue"
      ),
    children: [
      {
        path: "",
        name: ROUTES.DASHBOARD,
        redirect: {
          name: ROUTES.DASHBOARD_TOPUP,
        },
      },
      // TODO: remove casting, currently required due to PlanningAlerts using Tab/Tabs that are js rather than vue
      {
        path: "planning",
        component: PlanningAlertsWrapper,
        name: ROUTES.DASHBOARD_PLANNING_ALERTS,
      },
      {
        path: "planning/:alert_id",
        component: PlanningAlertDetailsWrapper,
        name: ROUTES.DASHBOARD_PLANNING_ALERT_DETAILS,
      },
      {
        path: "ownership",
        component: OwnershipAlertsWrapper,
        name: ROUTES.DASHBOARD_OWNERSHIP_ALERTS,
      },
      {
        path: "documents",
        component: DocumentsWrapper,
        name: ROUTES.DASHBOARD_DOCUMENTS,
      },
      {
        path: "transactions",
        component: TransactionsWrapper,
        name: ROUTES.DASHBOARD_TRANSACTIONS,
      },
      {
        path: "topup",
        component: TopupWrapper,
        name: ROUTES.DASHBOARD_TOPUP,
      },
      {
        path: "your-address",
        component: YourAddressWrapper,
        name: ROUTES.DASHBOARD_YOUR_ADDRESS,
      },
      {
        path: "integrations",
        component: IntegrationsWrapper,
        name: ROUTES.DASHBOARD_INTEGRATIONS,
      },
    ],
  },
  {
    path: "/topup",
    redirect: {
      name: ROUTES.DASHBOARD_TOPUP,
    },
  },
];
