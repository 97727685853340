<template>
  <react-wrapper :component="Transactions" />
</template>

<script>
import ReactWrapper from "view/wrappers/react-wrapper";
import { Transactions } from "react-migration/domains/dashboard/transactions/Transactions";

export default {
  components: {
    ReactWrapper,
  },
  computed: {
    Transactions() {
      return Transactions;
    },
  },
};
</script>
