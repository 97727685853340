import type { Feature, Polygon } from "geojson";

export type IsochronicFeatureProperties = {
  contour: number;
};
export type IsochronicFeature = Feature<Polygon, IsochronicFeatureProperties>;

export enum RoutingType {
  Walking = "walking",
  Cycling = "cycling",
  Driving = "driving",
  DrivingTraffic = "driving-traffic",
}

export enum RoutingTime {
  M15 = "15",
  M30 = "30",
  M45 = "45",
  M60 = "60",
  All = "All",
}
