import { ISearchFilter } from "react-migration/domains/search/typings/Filters";
import { filters } from "../components/FilterList/SearchFilters";

export function tryParseFiltersFromQueryString(encodedFilters?: string) {
  try {
    const decodedFilter = atob(encodedFilters ?? "");
    const selectedPartials = JSON.parse(decodedFilter) as Partial<ISearchFilter>[];
    return selectedPartials.map((partial) => {
      const defaults = filters.find((filter) => filter.id === partial.id);
      return {
        ...defaults,
        ...partial,
      } as ISearchFilter;
    });
  } catch (e) {
    return undefined;
  }
}
