import { ISourcingToolSearchFilter } from "react-migration/domains/search/typings/SourcingToolSpecificFilterTypes";
import { SearchType } from "react-migration/domains/search/constants/searchTypes";

export const AdditionalOpportunitiesFilter: ISourcingToolSearchFilter = {
  id: "additional-opportunities-id",
  type: "checkbox",
  field: "sidebar.react.search_sidebar.additional_opportunities",
  must: "MATCH_ANY",
  mustOptions: ["MATCH_ANY", "MATCH_NONE"],
  mustIsConfigurable: false,
  updateFilterDialogIsVisible: false,
  values: [
    {
      key: "declassified_green_belt",
      name: "Declassified Green Belt",
      checked: true,
    },
  ],
  searchTypes: [SearchType.SOURCING_TOOL_UK],
};
