import SITE_LABEL_OPTIONS from "react-migration/domains/sites/apollo/li-api/queries/siteLabelOptions.gql";
import { routedClient } from "react-migration/lib/persistence/apollo";
import { useMemo } from "react";
import { useQuery } from "@apollo/client";

export interface SiteLabelOption {
  id: string;
  name: string;
}

interface SiteLabelOptions {
  siteLabelOptions: {
    _id: string;
    name: string;
  }[];
}

export function useSiteLabelOptions(): SiteLabelOption[] {
  const { data } = useQuery<SiteLabelOptions>(SITE_LABEL_OPTIONS, {
    client: routedClient,
  });

  const siteLabelOptions = useMemo(
    () =>
      data?.siteLabelOptions?.map((labelOption) => ({
        ...labelOption,
        id: labelOption._id,
      })) || [],
    [data?.siteLabelOptions]
  );

  return siteLabelOptions;
}
