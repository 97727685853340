import { SelectionHandler } from "../../types";
import { HometrackComparableSelectionDetailView } from "./HometrackComparableSelectionDetailView";
import { LandTechComparableSelectionDetailView } from "./LandTechComparableSelectionDetailView";
import { ComparableSelectionHeader } from "./ComparableSelectionHeader";
import { ComparablesSelectionTitle } from "./ComparablesSelectionTitle";

export const LandTechComparableSelectionHandler: SelectionHandler = {
  SelectionTitle: ComparablesSelectionTitle,
  SelectionHeader: ComparableSelectionHeader,
  SelectionDetailView: LandTechComparableSelectionDetailView,
};

export const HometrackComparableSelectionHandler: SelectionHandler = {
  SelectionTitle: ComparablesSelectionTitle,
  SelectionHeader: ComparableSelectionHeader,
  SelectionDetailView: HometrackComparableSelectionDetailView,
};
