import { createStoreWrapper } from "@landtechnologies/drawings-frontend";
import { proxy } from "valtio/vanilla";

export type DrawingStore = {
  // eslint-disable-next-line no-undef
  reduxStore?: null | ReturnType<typeof createStoreWrapper>;
  initialised: boolean;
  drawingsOverlay?: google.maps.MapType | null;
};

export const drawingStore = proxy<DrawingStore>({
  initialised: false,
  reduxStore: null,
  drawingsOverlay: null,
});

export const resetDrawingStore = () => {
  drawingStore.initialised = false;
  drawingStore.reduxStore = null;
};
