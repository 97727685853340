import { useTranslation } from "react-migration/lib/i18n/useTranslation";

export const GenericError = ({ message }: { message: string }) => {
  const { t } = useTranslation();
  return (
    <div data-testid="general-error" className="atlas-flex atlas-flex-col atlas-gap-4">
      <strong>{t("title_purchase.error.generic.title")}</strong>
      <p>{message}</p>
    </div>
  );
};
