import { Switch } from "@landtechnologies/components";
import { useTranslation } from "react-migration/lib/i18n/useTranslation";
import { logEvent } from "react-migration/lib/util/logEvent";
import { useState } from "react";
import { getOwnershipAlertsSubscription } from "src/js/stores/user/actions/getSetting";
import { updateOwnershipAlertsSubscription } from "src/js/stores/user/actions/updateSetting";

//The switch is not currentle connected with any server call. We just migrated the logic from the old vue file
export const AlertsCard = () => {
  const [isActive, setIsActive] = useState(getOwnershipAlertsSubscription());
  const { t } = useTranslation();
  const handleSwitch = () => {
    setIsActive(!isActive);
    updateOwnershipAlertsSubscription(!isActive);
    logEvent("Toggle ownership alerts email in settings ", { ownership_alerts: !isActive });
  };

  return (
    <div className="atlas-border atlas-border-solid atlas-border-neutral-200 atlas-p-4 atlas-flex atlas-justify-between atlas-items-center atlas-rounded">
      <div className="atlas-flex atlas-flex-col atlas-gap-1">
        <span className="atlas-text-base atlas-font-semibold atlas-text-neutral-800">
          {t("dashboard.ownership_alerts.email_updates")}
        </span>
        <span className="atlas-text-xs atlas-text-neutral-500">
          {t("dashboard.ownership_alerts.sent_monthly")}
        </span>
      </div>
      <div>
        <Switch
          id="email-update-switch"
          label="Email Update"
          onToggle={handleSwitch}
          value={isActive}
        />
      </div>
    </div>
  );
};
