import { useMemo } from "react";
import { sortBy } from "lodash";
import { Section, TextWithVariants } from "@landtechnologies/components";
import NumberFloors from "src/js/util/number_floors";
import { getAreaVariants, getLengthVariants } from "react-migration/lib/util/getAreaAndPerimeter";
import { Tag, TagColor } from "react-migration/components/Tag";
import { DetailsList } from "react-migration/components/DetailsList";
import { LayerTypeSelectionDetailViewProps } from "../../../types";
import { UnknownValue } from "../components/UnknownValue";
import { usePropertyInformationByUPRN } from "./usePropertyInformationByUPRN";
import { useSnapshot } from "valtio";
import { userStore } from "src/js/stores/user/store";
import { formatToCurrency } from "react-migration/lib/util/numberFormat";

export function UKPropertyInformationSelectionDetailView({
  selection,
}: LayerTypeSelectionDetailViewProps) {
  const { user } = useSnapshot(userStore);
  const selectedPropertyUPRN = selection.id;

  const { data: { propertyInformationByUPRN } = {}, loading } =
    usePropertyInformationByUPRN(selectedPropertyUPRN);

  const latestValuation = useMemo(() => {
    if (!propertyInformationByUPRN?.address.voa_record?.valuations?.length) return undefined;

    return sortBy(propertyInformationByUPRN.address.voa_record?.valuations, "list_year")[0];
  }, [propertyInformationByUPRN?.address.voa_record?.valuations]);

  if (loading || !propertyInformationByUPRN) return null;

  const { address, building_height, dimensions } = propertyInformationByUPRN;

  return (
    <Section bodyClasses="atlas-flex atlas-flex-col atlas-px-3 atlas-py-2 atlas-gap-2 atlas-text-sm">
      <DetailsList>
        <DetailsList.Item label="Use class">
          {address.use_class_description_2020 ? (
            <div className="atlas-inline-block">
              <Tag tag={address.use_class_description_2020} color={TagColor.DEFAULT} />
            </div>
          ) : (
            <UnknownValue />
          )}
        </DetailsList.Item>

        <DetailsList.Item label="State">{address.state || <UnknownValue />}</DetailsList.Item>

        <DetailsList.Item label="Type">
          {address.property_class_description || <UnknownValue />}
        </DetailsList.Item>

        <DetailsList.Item label="Rateable value">
          {latestValuation?.total_value ? (
            formatToCurrency({ amount: latestValuation.total_value, currency: "GBP" })
          ) : (
            <UnknownValue />
          )}
        </DetailsList.Item>

        <DetailsList.Item label="Floor area">
          {typeof dimensions?.area_floor_total_metric === "number" ? (
            <TextWithVariants
              variants={getAreaVariants(dimensions.area_floor_total_metric, user.settings.unit)}
            />
          ) : (
            <UnknownValue />
          )}
        </DetailsList.Item>

        <DetailsList.Item label="Floors">
          <NumberOfFloors address={address} buildingHeight={building_height} />
        </DetailsList.Item>

        <DetailsList.Item label="Highest point">
          {typeof building_height?.rel_h_max === "number" ? (
            <TextWithVariants
              variants={getLengthVariants(building_height.rel_h_max, user.settings.unit)}
            />
          ) : (
            <UnknownValue />
          )}
        </DetailsList.Item>

        <DetailsList.Item label="Roof height">
          {typeof building_height?.rel_h_to_roof === "number" ? (
            <TextWithVariants
              variants={getLengthVariants(building_height.rel_h_to_roof, user.settings.unit)}
            />
          ) : (
            <UnknownValue />
          )}
        </DetailsList.Item>
      </DetailsList>
    </Section>
  );
}

interface NumberOfFloorsProps {
  address?: {
    property_class_code?: string;
  };
  buildingHeight?: {
    rel_h_to_roof?: number;
    rel_h_max?: number;
  };
}

function NumberOfFloors({ address, buildingHeight }: NumberOfFloorsProps) {
  const numberFloors = useMemo(() => {
    if (
      !address?.property_class_code ||
      !buildingHeight?.rel_h_to_roof ||
      !buildingHeight?.rel_h_max
    )
      return null;

    const propType = NumberFloors.getPropertyTypeFromClassificationCode(
      address.property_class_code
    );

    return NumberFloors.getGetFloorsFromHeight(
      buildingHeight.rel_h_to_roof,
      buildingHeight.rel_h_max,
      propType
    );
  }, [address, buildingHeight]);

  return numberFloors ? `${numberFloors} (estimate)` : <UnknownValue />;
}
