import { Point } from "@turf/helpers";
import { AnnotationSpecProps } from "./types";
import { PointMeasurements } from "react-migration/components/PointMeasurements";

export function PointSpec({ drawing }: AnnotationSpecProps) {
  return (
    <div>
      <PointMeasurements point={drawing.geometry as Point} />
    </div>
  );
}
