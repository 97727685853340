import { Button, Divider, SkeletonLoading } from "@landtechnologies/components";
import { useTranslation } from "react-migration/lib/i18n/useTranslation";

import hasFeature from "src/js/stores/user/actions/hasFeature";
import Feature from "src/js/stores/user/Feature";
import { ZooplaListingsFromUPRN } from "../ZooplaListings/ZooplaListingsFromUPRN";
import { getValueAndLogMissingField } from "../ZooplaSection";
import { ZooplaRecordSubItem } from "../ZooplaRecordSubItem";
import { logEvent } from "react-migration/lib/util/logEvent";
import { RecordType } from "react-migration/domains/comparables/typings/Record";
import { ZooplaComparableDTO } from "@landtechnologies/comparables-services";
import { ZooplaField } from "react-migration/domains/comparables/typings/Zoopla";
import { HomeTrackLogoLarge } from "react-migration/domains/comparables/img/HomeTrackLogoLarge";
import { ZooplaLogoLarge } from "react-migration/domains/comparables/img/ZooplaLogoLarge";

export interface ZooplaPreviewProps {
  record: RecordType | ZooplaComparableDTO;
  data?: ZooplaField[];
  loading?: boolean;
  showDivider?: boolean;
}

/** Handles checking for presence of zoopla licence and returns null if user does not have access */
export const ZooplaPreview = ({
  record,
  data,
  loading,
  showDivider = true,
}: ZooplaPreviewProps) => {
  const { t } = useTranslation();

  // show to all unlimited license holders that dont have zooplaFullAccess
  if (hasFeature(Feature.zooplaFullAccess) || !hasFeature(Feature.zooplaLightAccess)) {
    return null;
  }

  // if no zoopla data exists then dont show
  if (!loading && !data?.length) {
    return null;
  }

  const getValueData = (option: string, zooplaField?: ZooplaField) => {
    if (zooplaField == null && zooplaField !== undefined) {
      return getValueAndLogMissingField(zooplaField);
    }
    if (typeof zooplaField?.value === "string") return zooplaField.value;

    return zooplaField?.value?.find((v) => v.title === option)?.value;
  };

  const lastValue = data?.find((d) => d.title === "Last value");
  const propertyAttributes = data?.find((d) => d.title === "Property attributes");
  const energyRating = data?.find((d) => d.title === "Energy rating");

  // do not display component if no lastValue data is available:
  if (!getValueData("Value", lastValue)) {
    return null;
  }

  function contactSupport() {
    window.Intercom.call(null, "showNewMessage", "I want to upgrade to Hometrack");
    logEvent("Upgrade_click", { location: "zoopla" });
  }

  type ZooplaInfo = {
    key: string;
    content: JSX.Element | string;
  };

  const unavailable = (
    <span className="atlas-text-neutral-400 atlas-text-sm atlas-font-normal">-</span>
  );

  const zooplaInfoLeftSide: ZooplaInfo[] = [
    {
      key: "lastValue",
      content: (
        <div className="atlas-text-md atlas-font-semibold atlas-text-neutral-600">Last value</div>
      ),
    },
    {
      key: "Value",
      content: (
        <div>
          Value:{" "}
          <span className="atlas-font-bold">
            {getValueData("Value", lastValue) ? getValueData("Value", lastValue) : unavailable}
          </span>
        </div>
      ),
    },
    {
      key: "Type",
      content: (
        <div>
          Type:{" "}
          <span className="atlas-font-bold">
            {getValueData("Type", lastValue) ? getValueData("Type", lastValue) : unavailable}
          </span>
        </div>
      ),
    },
    {
      key: "Valuation date",
      content: (
        <div>
          Valuation date:{" "}
          <span className="atlas-font-bold">
            {getValueData("Valuation date", lastValue)
              ? getValueData("Valuation date", lastValue)
              : unavailable}
          </span>
        </div>
      ),
    },
  ];

  const zooplaInfoRightSide: ZooplaInfo[] = [
    {
      key: "Energy rating",
      content: (
        <>
          Energy rating:{" "}
          <span className="atlas-font-bold">
            {getValueData("Energy rating", energyRating)
              ? getValueData("Energy rating", energyRating)
              : unavailable}
          </span>
        </>
      ),
    },
    {
      key: "Bedrooms",
      content: (
        <>
          Bedrooms:{" "}
          <span className="atlas-font-bold">
            {getValueData("Bedrooms", propertyAttributes)
              ? getValueData("Bedrooms", propertyAttributes)
              : unavailable}
          </span>
        </>
      ),
    },
    {
      key: "Bathrooms",
      content: (
        <>
          Bathrooms:{" "}
          <span className="atlas-font-bold">
            {getValueData("Bathrooms", propertyAttributes)
              ? getValueData("Bathrooms", propertyAttributes)
              : unavailable}
          </span>
        </>
      ),
    },
    {
      key: "Receptions",
      content: (
        <>
          Receptions:{" "}
          <span className="atlas-font-bold">
            {getValueData("Receptions", propertyAttributes)
              ? getValueData("Receptions", propertyAttributes)
              : unavailable}
          </span>
        </>
      ),
    },
  ];

  type BlurredAttributes = {
    title: string;
    value: string;
  };

  const blurredAttributes: BlurredAttributes[] = [
    {
      title: "Bedroom",
      value: "5",
    },
    {
      title: "Tenure",
      value: "Freehold",
    },
    {
      title: "Bathrooms",
      value: "9",
    },
    {
      title: "Year build",
      value: "1984",
    },
    {
      title: "Receptions",
      value: "2",
    },
    {
      title: "Floor area",
      value: "70m2",
    },
  ];

  return (
    <>
      {showDivider && <Divider />}
      {loading ? (
        <div className="atlas-mt-6">
          <SkeletonLoading rows={7} />
        </div>
      ) : (
        <div data-testid="zoopla-preview">
          <div className="atlas-flex atlas-justify-between atlas-items-start atlas-px-3 atlas-pt-2">
            <div>
              {zooplaInfoLeftSide.map((info) => (
                <div key={info.key}>{info.content}</div>
              ))}
            </div>
            <div>
              <div className="atlas-text-md atlas-font-semibold atlas-text-neutral-600">
                Property attributes
              </div>
              {zooplaInfoRightSide.map((info) => (
                <div key={info.key}>{info.content}</div>
              ))}
            </div>
          </div>

          <ZooplaListingsFromUPRN uprn={record.uprn} className="atlas-ml-3" />
          <div>
            <div className="atlas-absolute atlas-bg-gray-200 atlas-p-1 atlas-m-1 atlas-text-neutral-500 atlas-filter atlas-blur-sm">
              <div
                className="atlas-text-md atlas-font-light atlas-text-neutral-600"
                style={{ color: "#C8C8C8" }}
              >
                Property Attributes
              </div>
              {blurredAttributes.map((a) => (
                <ZooplaRecordSubItem
                  title={a.title}
                  key={a.title}
                  className="atlas-inline-block atlas-w-1/2"
                  style={{ color: "#C8C8C8" }}
                >
                  {a.value}
                </ZooplaRecordSubItem>
              ))}
            </div>
            <div className="atlas-relative atlas-flex atlas-justify-between atlas-p-3">
              <div>
                Enhance valuations with the most current sales, rental data and property attributes{" "}
                with <HomeTrackLogoLarge height={12} />{" "}
                {t("comparables.map.sidebar.value.record.zoopla.by")} <ZooplaLogoLarge />
              </div>
              <div className="atlas-mt-auto atlas-mb-auto">
                <Button.Primary onClick={contactSupport} className="atlas-mx-2 atlas-py-6">
                  Upgrade
                </Button.Primary>
              </div>
            </div>
          </div>
        </div>
      )}

      {showDivider && <Divider />}
    </>
  );
};
