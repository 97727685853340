import { useEffect, useState } from "react";
import { MultiPolygon, Polygon, Position } from "geojson";

// We have presented a multipolygon as a series of separate google maps polygon overlays for each constituent
// polygon of a multipolygon. We did this as part of a fix for a bug when editing multipolygon site geometries.
// Google maps overlays just flatten any paths given to them, so the separateness of the rings in a polygon
// and multipolygon were being lost when the google maps overlay was being created. Now they exist as separate
// polygons, and we reassemble the multipolygon from these polygons after the editing is completed. We do this by
// using a dictionary to keep track of each polygon.

type PolygonMap = Record<string, Position[][]>;

export const breakMultipolygonIntoPolygonMap = (geoJsonData: MultiPolygon | Polygon) => {
  const polygonMap: Record<string, Position[][]> = {};
  if (!geoJsonData || !geoJsonData.type || !geoJsonData.coordinates) return {};

  geoJsonData.type === "Polygon"
    ? (polygonMap["0"] = geoJsonData.coordinates)
    : geoJsonData.coordinates.forEach((polygon, index) => (polygonMap[index.toString()] = polygon));

  return polygonMap;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const useGoogleMapsEditOverlay = (geometry: Polygon | MultiPolygon, mapStore: any) => {
  const [polygonMap, setPolygonMap] = useState<PolygonMap>(
    breakMultipolygonIntoPolygonMap(geometry)
  );
  const [undo, setUndo] = useState(false);
  const [assembledMultiPolygon, setAssembledMultiPolygon] = useState<MultiPolygon | Polygon>(
    geometry
  );

  useEffect(() => {
    const polygons = Object.entries(polygonMap).map(([key, coordinates]) =>
      getPolygonOverlay(
        coordinates,
        mapStore,
        setPolygonMap,
        setAssembledMultiPolygon,
        polygonMap,
        key
      )
    );
    setUndo(false);
    return () => {
      polygons.forEach((polygon) => {
        polygon.overlay.setMap(null);
        polygon.editEvent.remove();
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mapStore.googleMap, undo]);

  return { editedGeometry: assembledMultiPolygon };
};

function assembleMultiPolygon(polygonMap: PolygonMap): MultiPolygon {
  const coordinates = Object.values(polygonMap);
  return { type: "MultiPolygon", coordinates: coordinates } as MultiPolygon;
}

function getPolygonOverlay(
  polygonCoordinates: Position[][],
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  mapStore: any,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setPolygonMap: any,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setAssembledMultiPolygon: any,
  polygonMap: Record<string, Position[][]>,
  key: string
) {
  const overlay = new google.maps.Polygon({
    paths: geoJsonToGoogleMapsPath(polygonCoordinates),
    strokeColor: "#FF0000",
    strokeOpacity: 0.8,
    strokeWeight: 2,
    fillColor: "#FF0000",
    fillOpacity: 0.35,
    zIndex: 1,
    clickable: true,
    map: mapStore.googleMap || undefined,
    editable: true,
  });

  const editEvent = overlay.addListener("mouseup", () => {
    const newCoordinates = getGeojsonCoordinatesFromGoogleMapsOverlay(overlay);
    polygonMap[key] = newCoordinates;
    setPolygonMap({ ...polygonMap });
    setAssembledMultiPolygon(assembleMultiPolygon(polygonMap));
  });

  return { overlay, editEvent };
}

function getGeojsonCoordinatesFromGoogleMapsOverlay(polygonOverlay: google.maps.Polygon) {
  const coordinates = polygonOverlay
    .getPaths()
    .getArray()
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    .map((coord: any) => coord.getArray().map((coord: any) => [coord.lng(), coord.lat()]));

  const repeatFirstCoordAtEnd = (coordinates: Position[][]) => {
    for (const ring of coordinates) {
      const [first] = ring;
      ring.push(first);
    }
    return coordinates;
  };

  return repeatFirstCoordAtEnd(coordinates);
}

type TLatLng = {
  lng: number;
  lat: number;
};

export type TPath = TLatLng[];
type GoogleMapsPath = Array<TLatLng>;

export const geoJsonToGoogleMapsPath = (coordinates: Position[][]): GoogleMapsPath[] => {
  if (!coordinates) {
    return [];
  }

  const paths: GoogleMapsPath[] = [];

  for (const ring of coordinates) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const path = ring.slice(0, -1).map((coord: any) => ({ lat: coord[1], lng: coord[0] }));
    paths.push(path);
  }

  return paths;
};
