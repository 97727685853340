import { Button, ClipboardCopy } from "@landtechnologies/components";
import * as Popover from "@radix-ui/react-popover";
import classNames from "classnames";
import { useState } from "react";
import { logEventV2 } from "react-migration/lib/util/logEventV2";
import { siteShareLink } from "src/js/apollo/site_util";

export interface ShareSiteProps {
  siteId: string;
  title: string;
  showIcon?: boolean;
  buttonType?: keyof typeof Button;
  buttonClassNames?: string;
  onShareButtonClick?: () => void;
  onCopyToClipboardClick?: () => void;
}

export const ShareSiteButton = ({
  siteId,
  title,
  showIcon,
  buttonType = "Primary",
  buttonClassNames,
  onShareButtonClick = () => ({}),
  onCopyToClipboardClick = () => ({}),
}: ShareSiteProps) => {
  const [open, setOpen] = useState(false);

  const onShareClick = () => {
    logEventV2({ name: "Sites layer button click", properties: { type: "share" } });
    setOpen((v) => !v);
    onShareButtonClick();
  };

  const ShareButton = Button[buttonType];

  return (
    <Popover.Root open={open} onOpenChange={setOpen}>
      <div className="atlas-relative">
        <Popover.Anchor className="atlas-absolute atlas-inset-0" />
        <ShareButton
          onClick={onShareClick}
          size="small"
          className={classNames("atlas-relative", buttonClassNames)}
        >
          {showIcon && (
            <i className="icon-lt-share-forward-line atlas-mr-1 atlas-text-base atlas-leading-none" />
          )}
          {title}
        </ShareButton>
      </div>
      <Popover.Portal>
        <Popover.Content
          align="end"
          className={classNames(
            "atlas-bg-white",
            "atlas-rounded",
            "atlas-w-[330px]",
            "atlas-p-3",
            "atlas-drop-shadow-lg",
            "atlas-z-[10000]"
          )}
        >
          <Popover.Arrow className="atlas-fill-white" />
          <ClipboardCopy
            text={siteShareLink({ _id: siteId })}
            onCopyText={onCopyToClipboardClick}
          />
        </Popover.Content>
      </Popover.Portal>
    </Popover.Root>
  );
};
