import { UserSettings, UINotifications, userStore } from "../store";

export const updateHideTitleDocPurchaseAddressPrompt = (
  value: UserSettings["hideTitleDocPurchaseAddressPrompt"]
) => {
  userStore.user.settings.hideTitleDocPurchaseAddressPrompt = value;
};

export const updateMeasurementSystemPreference = (unit: UserSettings["unit"]) => {
  userStore.user.settings.unit = unit;
};

export const updateSitesDisplayMode = (mode: UserSettings["sitesDisplayMode"]) => {
  userStore.user.settings.sitesDisplayMode = mode;
};

export const updateDownloadCompsWithoutFloorArea = (
  value: UserSettings["downloadCompsWithoutFloorArea"]
) => {
  userStore.user.settings.downloadCompsWithoutFloorArea = value;
};

export const updateUiNotifications = (value: Partial<UINotifications>) => {
  userStore.user.settings.uiNotifications = {
    ...userStore.user.settings.uiNotifications,
    ...value,
  };
};

export const updateOwnershipAlertsSubscription = (
  value: UserSettings["receiveOwnershipAlerts"]
) => {
  userStore.user.settings.receiveOwnershipAlerts = value;
};

export const updateNotificationsAlertPeriod = (value: number) => {
  userStore.user.alert_notifications.alert_period = value;
};

export const updateShowHiddenSitesOnPipeline = (
  value: UserSettings["showHiddenSitesOnPipeline"]
) => {
  userStore.user.settings.showHiddenSitesOnPipeline = value;
};
