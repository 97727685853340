import { map, mean, prop } from "ramda";

interface IValueArea {
  value: number;
  area: number;
}
export const getMean = (values: IValueArea[]): number => {
  const average = mean(map(prop("value"), values));
  return isNaN(average) || average === Infinity ? 0 : average;
};

export const getMeanPerArea: (values: IValueArea[]) => number = (values) => {
  const { totalArea, totalValue } = values.reduce(
    (acc, { area, value }) => ({
      totalArea: acc.totalArea + area,
      totalValue: acc.totalValue + value,
    }),
    { totalArea: 0, totalValue: 0 }
  );
  return totalValue / totalArea;
};
