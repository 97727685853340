import isObject from "lodash/isObject";
import isFunction from "lodash/isFunction";
import forEach from "lodash/forEach";
import omit from "lodash/omit";
import isDate from "lodash/isDate";
import isElement from "lodash/isElement";

const Util = {
  isRawObject: function (obj) {
    return (
      isObject(obj) && !isFunction(obj) && !Array.isArray(obj) && !isDate(obj) && !isElement(obj)
    );
  },

  deepExtend: function (target, source) {
    forEach(source, function (val, key) {
      if (Util.isRawObject(val)) {
        if (Util.isRawObject(target[key])) {
          Util.deepExtend(target[key], val);
        } else {
          target[key] = val;
        }
      } else {
        target[key] = val;
      }
    });

    return target;
  },

  omitDeep(obj, ...keys) {
    let vals = obj;
    const isArr = Array.isArray(vals);
    if (!isArr) {
      vals = [vals];
    }

    const newVals = vals.map((val) => {
      const newObj = Util.isRawObject(val) ? omit(val, ...keys) : val;
      Object.keys(newObj).forEach((key) => {
        if (Util.isRawObject(newObj[key]) || Array.isArray(newObj[key])) {
          newObj[key] = Util.omitDeep(newObj[key], ...keys);
        }
      });
      return newObj;
    });

    return isArr ? newVals : newVals[0];
  },
};

export default Util;
