import { ISelectedItem, SearchTabId, SearchTypeState } from "../typings/Search";

export const defaultSearchTypeState: SearchTypeState = {
  searchId: "",
  searchResultsLoading: false,
  searchResults: undefined,
  selectedItemsById: new Map<string, ISelectedItem>(),
  selectedFilters: [],
  searchOnMapChange: false,
  activeTab: SearchTabId.filters,
  searchToDelete: undefined,
  savedResults: false,
  savedResultsError: "",
  isClusteredParcelSearch: false,
  boundaryFilters: [],
};
