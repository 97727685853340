import { useEffect, useMemo } from "react";
import hash from "object-hash";
import { useTranslation } from "react-migration/lib/i18n/useTranslation";
import { ConstraintsLayer } from "react-migration/layouts/map/Multilayer/layer_types/ConstraintsLayerType";
import { Nullable } from "src/js/types/Nullable";
import { getCategoryByIdQuery } from "../../getCategoryByIdQuery";
import {
  getDefaultVisibleCategoriesRecursive,
  getCategoryIdRecursive,
  pruneTree,
} from "./categoryTree";
import { constraintsStoreReducer } from "./reducer";
import { setCategoryTree, setVisibleCategories, setLayerConfigHash } from "./actions";
import type { ConstraintsStore, LayerState } from "./types";
import { useLocalStorageReducer } from "react-migration/lib/persistence/state/useLocalStorageReducer";

function getLayersFromLocalStorage(localStorageKey: string): Nullable<ConstraintsStore["layers"]> {
  try {
    return JSON.parse(localStorage.getItem(localStorageKey)!).layers ?? null;
  } catch {
    return null;
  }
}

function shouldInitialiseLayerState(
  layerId: string,
  localStorageKey: string,
  currentLayerConfigHash: string
) {
  const layerStateFromStore = getLayersFromLocalStorage(localStorageKey);
  const layerConfigChanged =
    currentLayerConfigHash !== layerStateFromStore?.[layerId]?.layerConfigHash;

  return !layerStateFromStore || layerConfigChanged;
}

export function useConstraintsStore(id: string, layers: ConstraintsLayer[]) {
  const translation = useTranslation();
  const [constraintsStore, dispatch, localStorageKey] = useLocalStorageReducer(
    id,
    constraintsStoreReducer,
    createInitialState(layers),
    { omit: layers.map((l) => `layers.${l.id}.categoryTree`) }
  );

  const layerConfigHashHashById = useMemo(
    () => Object.fromEntries(layers.map((layer) => [layer.id, hash(layer.layerConfig!)])),
    [layers]
  );

  useEffect(
    function initialiseConstraintsStore() {
      async function initialise() {
        // TODO: don't initialise layers sequentially, do so in parallel
        for (const layer of layers) {
          if (!layer.layerConfig) return;

          const { rootCategory, categorySchema } = layer.layerConfig;

          try {
            const category = await getCategoryByIdQuery(rootCategory);
            const categoryTree = pruneTree(category, categorySchema, translation);

            if (categoryTree) {
              dispatch(setCategoryTree(layer.id, categoryTree));

              if (
                shouldInitialiseLayerState(
                  layer.id,
                  localStorageKey,
                  layerConfigHashHashById[layer.id]
                )
              ) {
                dispatch(
                  setVisibleCategories(
                    layer.id,
                    getDefaultVisibleCategoriesRecursive(categoryTree, categorySchema)
                  )
                );

                dispatch(setLayerConfigHash(layer.id, hash(layer.layerConfig)));
              } else {
                const allPossibleCategories = getCategoryIdRecursive(categoryTree);
                const visibleCategories = constraintsStore.layers[layer.id].visibleCategories;
                const invalidCategories = visibleCategories.filter(
                  (category) => !allPossibleCategories.includes(category)
                );

                if (invalidCategories.length) {
                  dispatch(
                    setVisibleCategories(
                      layer.id,
                      visibleCategories.filter((category) => !invalidCategories.includes(category))
                    )
                  );
                }
              }
            }
          } catch (error) {
            console.error(
              "Unable to initialise Constraints Store for root category:",
              rootCategory
            );
          }
        }
      }

      initialise();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [layers, dispatch, localStorageKey, translation]
  );

  return [constraintsStore, dispatch] as const;
}

export const initialLayerState: LayerState = {
  hiddenStatuses: [],
  visibleCategories: [],
  hiddenDesignationAttributes: [],
  layerConfigHash: null,
  categoryTree: null,
};

export const initialState = { layers: {} };

export function createInitialState(layers: ConstraintsLayer[]): ConstraintsStore {
  return {
    ...initialState,
    layers: Object.fromEntries(layers.map((layer) => [layer.id, initialLayerState])),
  };
}
