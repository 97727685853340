import { IconLayer, IconLayerProps } from "@deck.gl/layers";
import type { Feature, Point } from "geojson";
import type { Position } from "deck.gl";
import iconAtlas from "./map-pin@2x.png";

const ICON_MAPPING = {
  marker: { x: 0, y: 0, width: 54, height: 76, anchorY: 76, mask: false },
};

export class SelectionMarkerLayer extends IconLayer {
  constructor(props: IconLayerProps<Feature<Point>>) {
    super({
      ...props,
      iconAtlas,
      iconMapping: ICON_MAPPING,
      getIcon: () => "marker",
      getPosition: (d: Feature<Point>) => d.geometry?.coordinates as Position,
      getSize: ICON_MAPPING.marker.height / 2,
      sizeUnits: "pixels",
    });
  }
}
