import length from "@turf/length";
import { GenericMeasurments } from "react-migration/components/GenericMeasurements/GenericMeasurments";
import { getLengthVariants } from "react-migration/lib/util/getAreaAndPerimeter";
import { getMeasurementSystemPreference } from "src/js/stores/user/actions/getSetting";
import { AnnotationSpecProps } from "./types";

export function RulerSpec({ drawing }: AnnotationSpecProps) {
  const unitPreference = getMeasurementSystemPreference();
  const lengthInMeters = length(drawing.geometry, { units: "meters" });
  const lengthVariants = getLengthVariants(lengthInMeters, unitPreference);
  return (
    <div>
      <GenericMeasurments measurements={[{ label: "Length", variants: lengthVariants }]} />
    </div>
  );
}
