import Feature from "src/js/stores/user/Feature";
import { ConstraintsCategory } from "../../Constraints/types";
import { ConstraintsLayerType } from "../layer_types/ConstraintsLayerType";
import { Layer } from "../types";

export const US_URBAN_SERVICE_AREA: Layer = {
  id: ConstraintsCategory.US_URBAN_SERVICE_AREA,
  layerType: ConstraintsLayerType,
  hideByDefault: true,
  description: "Availability of utilities",
  title: "Urban Service Area",
  requiredFeatureAccess: [Feature.utilitiesLayerUS],
  toggleable: true,
  layerConfig: {
    rootCategory: ConstraintsCategory.US_URBAN_SERVICE_AREA,
    categorySchema: [{ key: "urban_service_area", defaultValue: false }],
  },
};

export const US_WATER_RESOURCES: Layer = {
  id: ConstraintsCategory.US_WATER_RESOURCES,
  layerType: ConstraintsLayerType,
  hideByDefault: true,
  description: "Availability of utilities",
  title: "Water Resources",
  requiredFeatureAccess: [Feature.utilitiesLayerUS],
  toggleable: true,
  layerConfig: {
    rootCategory: ConstraintsCategory.US_WATER_RESOURCES,
    minZoom: 13,
    layerRoots: ["us_drinking_water", "us_waste_water"],
    categorySchema: [
      { key: "us_dw_known_public", defaultValue: false },
      { key: "us_dw_likely_public", defaultValue: false },
      { key: "us_dw_known_well", defaultValue: false },
      { key: "us_dw_likely_well", defaultValue: false },
      { key: "us_dw_conflicting", defaultValue: false },
      { key: "us_dw_unknown", defaultValue: false },
      { key: "us_ww_known_sewer", defaultValue: false },
      { key: "us_ww_likely_sewer", defaultValue: false },
      { key: "us_ww_known_septic", defaultValue: false },
      { key: "us_ww_likely_septic", defaultValue: false },
      { key: "us_ww_conflicting", defaultValue: false },
      { key: "us_ww_unknown", defaultValue: false },
    ],
  },
};
