import { useEffect, useMemo } from "react";
import classNames from "classnames";
import { InfoIcon, Theme, Tooltip } from "@landtechnologies/components";
import { useTranslation } from "react-migration/lib/i18n/useTranslation";
import {
  LandTechEndpoints,
  routedClient,
} from "react-migration/lib/persistence/apollo/routedClient";
import { useSelectedParcelIds } from "react-migration/domains/sites/skipTracing/store/hooks/useSelectedParcelIds";
import { useSkipTracingCreditsQuery } from "@gql/li-api/graphql";
import { updateValidationError } from "react-migration/domains/sites/skipTracing/store/actions/updateValidationError";

export const ConfirmOrder = () => {
  const { t } = useTranslation();

  const selectedParcelIds = useSelectedParcelIds();

  const { data: creditsResponse } = useSkipTracingCreditsQuery({
    client: routedClient,
    fetchPolicy: "network-only",
    context: {
      endpoint: LandTechEndpoints.PrivateClient,
    },
  });

  const accountSkipTraceCredit = useMemo(
    () => ({
      cap: creditsResponse?.accountWithViews.skip_traces?.cap ?? 0,
      used: creditsResponse?.accountWithViews.skip_traces?.used ?? 0,
    }),
    [creditsResponse]
  );

  const selectedParcelCount = useMemo(
    () => Object.keys(selectedParcelIds).length ?? 0,
    [selectedParcelIds]
  );

  const remainingCredits = useMemo(() => {
    if (accountSkipTraceCredit?.used && accountSkipTraceCredit.cap) {
      const creditsAfterPurchase =
        accountSkipTraceCredit.cap - (accountSkipTraceCredit.used + selectedParcelCount);
      return `${creditsAfterPurchase > 0 ? creditsAfterPurchase : 0}/${accountSkipTraceCredit.cap}`;
    }
  }, [accountSkipTraceCredit.cap, accountSkipTraceCredit.used, selectedParcelCount]);

  useEffect(() => {
    if (creditsResponse && accountSkipTraceCredit.cap < selectedParcelCount) {
      updateValidationError(t("sites.skip_tracing.summary.insufficient_credits"));
    }
  }, [accountSkipTraceCredit, creditsResponse, selectedParcelCount, t]);

  return (
    <div
      className={classNames(
        "atlas-bg-background-light",
        "atlas-flex",
        "atlas-flex-col",
        "atlas-justify-start",
        "atlas-w-full",
        "atlas-h-full",
        "atlas-p-8",
        "atlas-gap-y-8"
      )}
      data-testid="skip-tracing-confirm-order"
    >
      <span
        className={classNames(
          "atlas-flex",
          "atlas-justify-center",
          "atlas-text-2xl",
          "atlas-font-semibold"
        )}
      >
        {t("sites.skip_tracing.confirm_and_process")}
      </span>
      <div className={classNames("atlas-flex", "atlas-justify-center", "atlas-w-full")}>
        <div
          className={classNames(
            "atlas-flex",
            "atlas-flex-col",
            "atlas-w-[329px]",
            "atlas-bg-background-white",
            "atlas-border",
            "atlas-border-border-divider",
            "atlas-rounded-lg",
            "atlas-p-4"
          )}
        >
          <span
            className={classNames(
              "atlas-flex",
              "atlas-text-base",
              "atlas-font-semibold",
              "atlas-pb-4"
            )}
          >
            {t("sites.skip_tracing.summary.skip_tracing_summary")}
          </span>
          <div
            className={classNames(
              "atlas-flex",
              "atlas-justify-between",
              "atlas-pb-3",
              "atlas-text-sm"
            )}
          >
            <span>
              {selectedParcelCount}x {t("sites.skip_tracing.summary.skip_trace")}
            </span>
            <span>
              {t("sites.skip_tracing.summary.nr_of_credits", {
                nr_of_credits: selectedParcelCount * -1,
              })}
            </span>
          </div>
          <div
            className={classNames(
              "atlas-flex",
              "atlas-flex-col",
              "atlas-border-y",
              "atlas-border-border-divider",
              "atlas-py-3",
              "atlas-text-content-secondary"
            )}
          >
            <div
              className={classNames(
                "atlas-flex",
                "atlas-flex-row",
                "atlas-justify-between",
                "atlas-text-sm"
              )}
            >
              <span>{t("sites.skip_tracing.summary.skip_trace_credits")}</span>

              <Tooltip.Wrapper>
                <Tooltip.Trigger>
                  <InfoIcon />
                </Tooltip.Trigger>
                <Tooltip.Content align="center" side="bottom" theme={Theme.Dark} size="sm">
                  <p className="atlas-pt-2">Cap: {accountSkipTraceCredit.cap}</p>
                  <p>Used credits: {accountSkipTraceCredit.used}</p>
                  <p>Required: {selectedParcelCount}</p>
                </Tooltip.Content>
              </Tooltip.Wrapper>
            </div>
            <span className="atlas-text-xs">
              {t("sites.skip_tracing.summary.remaining_credits_left_after_purchase", {
                nr_of_credits: remainingCredits,
              })}
            </span>
          </div>
          <div
            className={classNames(
              "atlas-flex",
              "atlas-justify-between",
              "atlas-pt-3",
              "atlas-text-base",
              "atlas-font-semibold"
            )}
          >
            <span>{t("sites.skip_tracing.summary.total_credits")}</span>
            <span className="atlas-text-content-positive">
              {t("sites.skip_tracing.summary.nr_of_credits", {
                nr_of_credits: selectedParcelCount,
              })}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};
