import enGB from "./en-GB";
import enUS from "./en-US";

export const languages = {
  en: enGB, // default english language
  "en-GB": enGB,
  "en-US": enUS,
};

export const doesLocaleExist = (language) => languages.hasOwnProperty(language);

export const defaultLanguage = "en-GB";
