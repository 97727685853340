import { PropertyDesignationGQL } from "@landtechnologies/comparables-services";

export const epcSearchURL = ({
  post_code,
  designation,
}: {
  post_code: string;
  designation: PropertyDesignationGQL;
}) => {
  if (!post_code) {
    return "https://www.gov.uk/find-energy-certificate";
  }

  const encodedPostcode = encodeURIComponent(post_code).replace(/%20/g, "+");
  return `https://find-energy-certificate.service.gov.uk/find-a-${
    designation === "Residential" ? "" : "non-domestic-"
  }certificate/search-by-postcode?postcode=${encodedPostcode}`;
};

export const saleAdvertURL = ({ address }: { address: string }) =>
  `https://www.google.co.uk/search?q=${encodeURIComponent(address)}%20sold&gws_rd=ssl`;
