import { multiPolygon } from "@turf/helpers";
import { Geometry, Position } from "geojson";

export function createMultiPolygon(geometries: Geometry[]) {
  const coordinates: Position[][][] = [];

  geometries.forEach((g) => {
    if (g.type === "Polygon") coordinates.push(g.coordinates);
    if (g.type === "MultiPolygon") g.coordinates.forEach((position) => coordinates.push(position));
  });

  return multiPolygon(coordinates);
}
