import classNames from "classnames";
import React, { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { useSnapshot } from "valtio";
import { useTranslation } from "react-migration/lib/i18n/useTranslation";
import { updateSelectedRecipientIds } from "src/js/stores/letter/actions";
import Feature from "src/js/stores/user/Feature";
import hasFeature from "src/js/stores/user/actions/hasFeature";

import { Column, Table } from "@landtechnologies/components";

import { LetterEventName, logLetterEvent } from "../../letters/helpers/logLetterEvent";
import { SelectRecipientsFormCell } from "./SelectRecipientsFormCell";
import { SelectRecipientsFormData } from "./SelectRecipientsFormSchema";
import {
  SingleRecipient,
  singleRecipientValidationSchema,
} from "./SingleRecipientValidationSchema";
import { TableTitle } from "./TableTitle";
import { letterStore } from "src/js/stores/letter/store";

export interface SelectRecipientsFormProps extends React.PropsWithChildren {
  onSubmit?: (formData: SelectRecipientsFormData) => void;
}

export const SELECT_RECIPIENTS_FORM_ID = "selectRecipientsForm";

export const SelectRecipientsForm = ({ onSubmit, children }: SelectRecipientsFormProps) => {
  const { t } = useTranslation();
  const usAccess = hasFeature(Feature.usAccess);
  const { selectedStageId, selectedRecipientIds } = useSnapshot(letterStore);

  const methods = useFormContext<SelectRecipientsFormData>();

  const { handleSubmit, trigger, getValues, setValue } = methods;

  const { recipients } = getValues();

  useEffect(() => {
    if (recipients.length > 0) {
      updateSelectedRecipientIds(
        recipients.filter((recipient) => recipient.isGranted).map((recipient) => recipient._id)
      );
      trigger();
    }
  }, [trigger, recipients]);

  const onFormSubmit = handleSubmit((formData) => {
    onSubmit?.(formData);
  });

  const columns: Column<SingleRecipient>[] = [
    {
      id: "name",
      label: t("sites.letters.name"),
      grow: true,
      render: (recipient, rowIndex: number) => (
        <SelectRecipientsFormCell
          recipient={recipient}
          rowIndex={rowIndex}
          displayingProperty="fullName"
        />
      ),
    },
    {
      id: "addressLine1",
      label: t("sites.letters.address_line_1"),
      grow: true,
      render: (recipient, rowIndex: number) => (
        <SelectRecipientsFormCell
          recipient={recipient}
          rowIndex={rowIndex}
          displayingProperty="addressLine1"
        />
      ),
    },
    {
      id: "city",
      label: t("sites.letters.city"),
      grow: true,
      render: (recipient, rowIndex: number) => (
        <SelectRecipientsFormCell
          recipient={recipient}
          rowIndex={rowIndex}
          displayingProperty="city"
        />
      ),
    },
    {
      id: "region",
      label: t("sites.letters.region"),
      grow: true,
      render: (recipient, rowIndex: number) => (
        <SelectRecipientsFormCell
          recipient={recipient}
          rowIndex={rowIndex}
          displayingProperty="region"
        />
      ),
    },
    {
      id: "postcode",
      label: t("sites.letters.post_code"),
      grow: true,
      render: (recipient, rowIndex: number) => (
        <SelectRecipientsFormCell
          recipient={recipient}
          rowIndex={rowIndex}
          displayingProperty="postCode"
        />
      ),
    },
    {
      label: t("sites.letters.site_name"),
      rowKey: "siteTitle",
      maxWidth: "400px",
    },
  ];

  if (!usAccess) {
    columns.splice(5, 0, { label: t("sites.letters.title"), rowKey: "title" });
  }

  const isSelectionDisabled = (recipient?: SingleRecipient) =>
    (recipient?.hasOwnProperty("isGranted") && !recipient.isGranted) ?? false;

  const invalidRowClassName = (recipient?: SingleRecipient) => {
    const isValid = singleRecipientValidationSchema.safeParse(recipient).success;
    if (!isValid && recipient?.isSelected) {
      return "atlas-bg-background-negative/10 hover:!atlas-bg-background-negative/20";
    }
  };

  const cellClassName = (_r: SingleRecipient, { id }: Column<SingleRecipient>) => {
    if (id) {
      return "!atlas-p-0";
    }
  };

  return (
    <div
      className={classNames(
        "atlas-bg-background-light",
        "atlas-p-6",
        "atlas-h-full",
        "atlas-w-full",
        "atlas-grid",
        "atlas-overflow-x-auto"
      )}
    >
      <form
        onSubmit={onFormSubmit}
        id={SELECT_RECIPIENTS_FORM_ID}
        data-testid="select-recipients-form"
        autoComplete="off"
      >
        <Table
          titleHeader={
            <TableTitle
              recipients={recipients}
              selectedRecipientIds={selectedRecipientIds}
              selectedStageId={selectedStageId}
            />
          }
          titleHeaderClassNames="atlas-w-full"
          primaryKey={"_id"}
          rows={recipients ?? []}
          columns={columns}
          selection={{
            selectedRowIds: selectedRecipientIds ?? [],
            updateSelectedRowIds: updateSelectedRecipientIds,
            selectWithRowClick: false,
            onSelectAllRows: () => {
              recipients.forEach((row) => {
                const recipientIndex = recipients.findIndex(
                  (recipient) => recipient._id === row._id
                );

                recipients[recipientIndex].isGranted &&
                  setValue(
                    `recipients.${recipientIndex}.isSelected`,
                    !selectedRecipientIds!.includes(row._id),
                    { shouldValidate: true }
                  );
              });

              logLetterEvent({
                name: LetterEventName.LETTERS_FORM_SELECT_RECIPIENTS_STEP_ALL_RECIPIENTS_TOGGLED,
              });
            },
            onSelectRow: (row) => {
              const recipientIndex = recipients.findIndex((recipient) => recipient._id === row._id);

              setValue(
                `recipients.${recipientIndex}.isSelected`,
                !selectedRecipientIds!.includes(row._id),
                { shouldValidate: true }
              );

              trigger();

              logLetterEvent({
                name: LetterEventName.LETTERS_FORM_SELECT_RECIPIENTS_STEP_RECIPIENT_TOGGLED,
              });
            },
            disabled: isSelectionDisabled,
          }}
          rowClassName={invalidRowClassName}
          cellClassName={cellClassName}
        />
        {children}
      </form>
    </div>
  );
};
