import { Nullable } from "src/js/types/Nullable";
import DESIGNATIONS_SNAPSHOT_BY_GEOMETRY from "./designationsSnapshotByGeometry.gql";
import { useQuery } from "@apollo/client";
import { SelectionGeometry } from "src/js/stores/map/store";
import { useMemo } from "react";
import { LandTechEndpoints, routedClient } from "react-migration/lib/persistence/apollo";
import { Designation } from "react-migration/domains/constraints/typings/baseTypes/Designation";

const DATA_STATUSES = ["live"];
const SOURCE_STATUSES = ["adopted"];

type DesignationSnapshot = Pick<Designation, "id" | "sub_category_id">;

interface SnapshotResponse {
  nationalLandscape: DesignationSnapshot[];
  commonLand: DesignationSnapshot[];
  greenBelt: DesignationSnapshot[];
  floodRisk: DesignationSnapshot[];
  nearbyListedBuildings: DesignationSnapshot[];
}

export interface Snapshot {
  listedBuildings: {
    listed_buildings: DesignationSnapshot[];
  };
  protectedLand: {
    aonb: DesignationSnapshot[];
    greenbelt: DesignationSnapshot[];
    common_land: { id: string }[];
  };
  floodRisk: {
    very_low_flood_risk: DesignationSnapshot[];
    low_flood_risk: DesignationSnapshot[];
    medium_flood_risk: DesignationSnapshot[];
    high_flood_risk: DesignationSnapshot[];
  };
}

const pointTypes = ["Point", "MultiPoint"];

export function useDesignationsSnapshotByGeometry(geometry: Nullable<SelectionGeometry>) {
  const isPoint = geometry?.type && pointTypes.includes(geometry.type);

  const { data, loading } = useQuery<SnapshotResponse>(DESIGNATIONS_SNAPSHOT_BY_GEOMETRY, {
    variables: {
      geometry,
      sourceStatuses: SOURCE_STATUSES,
      bufferMeters: isPoint ? 100 : -1,
      dataStatuses: DATA_STATUSES,
    },
    skip: !geometry,
    client: routedClient,
    context: {
      endpoint: LandTechEndpoints.Constraints,
    },
  });

  const snapshot = useMemo<Nullable<Snapshot>>(() => {
    if (loading) return null;

    const {
      commonLand = [],
      greenBelt = [],
      nationalLandscape = [],
      floodRisk = [],
      nearbyListedBuildings = [],
    } = data || {};

    return {
      listedBuildings: {
        listed_buildings: nearbyListedBuildings,
      },
      protectedLand: {
        aonb: nationalLandscape,
        common_land: commonLand,
        greenbelt: greenBelt,
      },
      floodRisk: {
        very_low_flood_risk: floodRisk.filter(
          (x) => x.sub_category_id === "very_low_risk_of_flooding_from_rivers_and_seas"
        ),
        low_flood_risk: floodRisk.filter(
          (x) => x.sub_category_id === "low_risk_of_flooding_from_rivers_and_seas"
        ),
        medium_flood_risk: floodRisk.filter(
          (x) => x.sub_category_id === "medium_risk_of_flooding_from_rivers_and_seas"
        ),
        high_flood_risk: floodRisk.filter(
          (x) => x.sub_category_id === "high_risk_of_flooding_from_rivers_and_seas"
        ),
      },
    };
  }, [data, loading]);

  return [snapshot, loading] as [Snapshot, false] | [null, true];
}
