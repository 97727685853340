import COMPARABLE_SEARCH from "../apollo/comparableSearch.gql";
import { useQuery } from "@apollo/client";
import { LandTechEndpoints, routedClient } from "react-migration/lib/persistence/apollo";
import { Point } from "geojson";
import { IComparablesTransactionDTO, IComparableArgsGQL } from "../typings/External";

export type Comparable = Pick<
  IComparablesTransactionDTO,
  | "address"
  | "transaction_id"
  | "geometry"
  | "post_code"
  | "date_of_transfer"
  | "designation"
  | "category"
  | "sub_category"
  | "is_new_property"
  | "tenure"
  | "price"
  | "total_floor_area"
  | "price_per_square_metre"
  | "num_bedrooms"
  | "number_habitable_rooms"
  | "est_bedrooms"
  | "est_price"
>;

type ComparableSearch = {
  edges: {
    node: Comparable;
  }[];
};

export const useComparablePointSearch = (location?: Point) => {
  const fromDate = new Date();
  const toDate = new Date();
  fromDate.setFullYear(fromDate.getFullYear() - 2);

  const { data, ...rest } = useQuery<{ comparableSearch: ComparableSearch }, IComparableArgsGQL>(
    COMPARABLE_SEARCH,
    {
      variables: {
        search: {
          radius_geo_filter: location
            ? {
                location: {
                  lat: location.coordinates[1],
                  lng: location.coordinates[0],
                },
                max_dist: 500,
              }
            : null,
          sold_date_range: {
            from: fromDate.toISOString().split("T")[0],
            to: toDate.toISOString().split("T")[0],
          },
        },
      },
      client: routedClient,
      context: {
        endpoint: LandTechEndpoints.Gateway,
      },
      skip: !location,
    }
  );

  return {
    comparables: data?.comparableSearch?.edges?.map((x) => x.node),
    ...rest,
  };
};
