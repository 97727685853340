import { useMemo, useState } from "react";
import { Accordion } from "@landtechnologies/components";
import {
  PlanningApplication,
  PlanningClassification,
} from "react-migration/domains/planning/types";
import { applicationsByAddress } from "../SelectionSidebar/applicationsByAddress";
import { Nullable } from "src/js/types/Nullable";
import { PlanningApplicationList } from "react-migration/domains/planning/components/PlanningApplicationList";
import { SelectionFeature } from "src/js/stores/map/store";
import noop from "lodash/noop";
import cn from "classnames";
import { ExpandibleList } from "react-migration/components/ExpandibleList";

function usePlanningApplicationsByAddress<T extends PlanningApplication>(
  planningApplications: Nullable<T[]>
) {
  return useMemo(() => {
    if (planningApplications?.length)
      return applicationsByAddress(planningApplications as unknown as PlanningApplication[]);
  }, [planningApplications]);
}

interface PlanningApplicationsByAddressProps<T extends PlanningApplication = PlanningApplication> {
  planningApplications: T[];

  setDetailSelection?: (selection: SelectionFeature | undefined) => void;
}

export function PlanningApplicationsByAddress({
  planningApplications,
  setDetailSelection,
}: PlanningApplicationsByAddressProps) {
  const applicationsByAddress = usePlanningApplicationsByAddress(planningApplications);

  const [expandedAccordionItems, setExpandedAccordionItems] = useState<string[]>([]);

  if (!applicationsByAddress) return null;

  return (
    <Accordion
      value={expandedAccordionItems}
      onValueChange={setExpandedAccordionItems}
      type="multiple"
    >
      <ExpandibleList limit={8} itemsName="addresses">
        {applicationsByAddress.map(({ address, applications, classifications }) => (
          <Accordion.Item key={address} value={address} className="atlas-group">
            <Accordion.Trigger asChild>
              <div
                className="atlas-flex atlas-gap-1 atlas-items-center atlas-px-2 atlas-py-0.5"
                data-testid="planning-selection-sidebar-accordion-trigger"
              >
                <div className="atlas-grow atlas-text-left atlas-truncate atlas-text-ellipsis atlas-text-xs atlas-font-medium">
                  {address}
                </div>
                <div className="atlas-shrink-0 atlas-flex atlas-gap-1 atlas-items-center">
                  <>
                    {[...classifications]
                      .sort((a, b) => a.localeCompare(b))
                      .map((classification) => (
                        <Classification key={classification} classification={classification} />
                      ))}
                    <i
                      className={cn("atlas-text-2xl", {
                        "icon-lt-arrow-up-s-line": expandedAccordionItems.includes(address),
                        "icon-lt-arrow-down-s-line": !expandedAccordionItems.includes(address),
                      })}
                    />
                  </>
                </div>
              </div>
            </Accordion.Trigger>
            <Accordion.Content className="atlas-border-t atlas-border-neutral-200">
              <PlanningApplicationList
                setDetailSelection={setDetailSelection}
                planningApps={applications}
                setSelectedAppealRef={noop}
                setSelectedPlanningApplicationRef={noop}
              />
            </Accordion.Content>
          </Accordion.Item>
        ))}
      </ExpandibleList>
    </Accordion>
  );
}

interface ClassificationProps {
  classification: PlanningClassification;
}

function Classification({ classification }: ClassificationProps) {
  return (
    <li
      className={cn(
        "atlas-flex atlas-items-center atlas-justify-center atlas-h-[18px] atlas-w-[18px] atlas-rounded-full atlas-text-white atlas-text-sm",
        {
          "atlas-bg-planning-residential": classification === PlanningClassification.RESIDENTIAL,
          "atlas-bg-planning-commercial": classification === PlanningClassification.COMMERCIAL,
          "atlas-bg-planning-other": classification === PlanningClassification.OTHER,
          "atlas-bg-gradient-to-b atlas-from-planning-residential atlas-to-planning-commercial":
            classification === PlanningClassification.MIXED_USE,
        }
      )}
    >
      {classification === PlanningClassification.RESIDENTIAL && "R"}
      {classification === PlanningClassification.COMMERCIAL && "C"}
      {classification === PlanningClassification.OTHER && "O"}
      {classification === PlanningClassification.MIXED_USE && "M"}
    </li>
  );
}
