import { skipTracingStore } from "../store";

export function updateSelectedParcelIds(selectedParcelIds: Record<string, boolean>) {
  skipTracingStore.selectedParcelIds = selectedParcelIds;

  const selectedParcelsCount = Object.keys(selectedParcelIds).filter(
    (id) => selectedParcelIds[id]
  ).length;

  if (selectedParcelsCount > 100) {
    skipTracingStore.validationError = "sites.skip_tracing.order_table.too_many_parcels_selected";
  } else if (selectedParcelsCount === 0) {
    skipTracingStore.validationError = "sites.skip_tracing.order_table.no_parcels_selected";
  } else {
    skipTracingStore.validationError = null;
  }
}
