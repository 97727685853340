import React from "react";

export const ErrorIcon = () => {
  return (
    <svg height="100%" viewBox="0 0 32 21">
      <g fill="none" fillRule="evenodd">
        <g fill="red">
          <g transform="translate(0 2)">
            <path
              d="M21.68 0A6 6 0 1029 9.47v7.15A2.4 2.4 0 0126.58 19H2.42A2.4 2.4 0 010 16.62V2.38A2.4 2.4 0 012.42 0h19.26zM10 5.83c0-.46-.35-.83-.78-.83H3.78c-.43 0-.78.37-.78.83v3.34c0 .46.35.83.78.83h5.44c.43 0 .78-.37.78-.83V5.83z"
              opacity="0.2"
            ></path>
            <path
              d="M25 15h-3c-.65 0-1-.3-1-1s.35-1 1-1h3c.65 0 1 .3 1 1s-.35 1-1 1zm-6 0h-3c-.65 0-1-.3-1-1s.35-1 1-1h3c.65 0 1 .3 1 1s-.35 1-1 1zm-6 0h-3c-.65 0-1-.3-1-1s.35-1 1-1h3c.65 0 1 .3 1 1s-.35 1-1 1zm-6 0H4c-.65 0-1-.3-1-1s.35-1 1-1h3c.65 0 1 .3 1 1s-.35 1-1 1z"
              opacity="0.3"
            ></path>
          </g>
          <path
            d="M7 14A7 7 0 117 0a7 7 0 010 14zM6 3v4a1 1 0 002 0V3a1 1 0 00-2 0zm1 8.75a1.25 1.25 0 100-2.5 1.25 1.25 0 000 2.5z"
            transform="translate(18)"
          ></path>
        </g>
      </g>
    </svg>
  );
};
