import clsx from "clsx";
import { ModalV2 } from "react-migration/components/ModalV2/ModalV2";
import { DemographicsRegion } from "./types";

export type DemographicsDialogProps = {
  region: DemographicsRegion;
  label?: string;
  disabled?: boolean;
};

export function DemographicsDialog({ label, region, disabled = false }: DemographicsDialogProps) {
  return (
    <div
      className={clsx("atlas-flex atlas-font-semibold atlas-gap-x-1 atlas-items-center", {
        "atlas-opacity-50": disabled,
      })}
    >
      {label && <div>{label}</div>}
      <ModalV2.Root>
        <ModalV2.Trigger disabled={disabled}>
          <i className="icon-lt-question-line atlas-text-lg atlas-text-content-tertiary" />
        </ModalV2.Trigger>
        <ModalV2.Body title={"Demographics"}>
          {region === "UK" ? <DialogInfoUK /> : <DialogInfoUS />}
        </ModalV2.Body>
      </ModalV2.Root>
    </div>
  );
}

function DialogInfoUK() {
  return (
    <div className="atlas-flex atlas-flex-col atlas-gap-y-4">
      <div>
        <p>
          The demographics layer is made available using data supplied by the Office of National
          Statistics, and through their census. You can find out more about the census on their
          website -{" "}
          <a
            href="https://www.ons.gov.uk/methodology/geography/ukgeographies"
            target="_blank"
            rel="noreferrer"
          >
            {" "}
            read more.
          </a>
        </p>
        <p>
          You can read more about the attributes available for demographics on the LandTech help
          centre -{" "}
          <a
            href="https://support.land.tech/en/articles/9330494-demographics"
            target="_blank"
            rel="noreferrer"
          >
            read more.
          </a>
        </p>
        <p>There are five main points of comparison.</p>
      </div>
      <div>
        <div className="atlas-font-semibold">Lower Super Output Area</div>
        <div>
          Lower layer Super Output Areas (LSOAs) comprise between 400 and 1,200 households and have
          a usually resident population between 1,000 and 3,000 persons.
        </div>
      </div>

      <div>
        <div className="atlas-font-semibold">Middle Super Output Area</div>
        <div>
          Middle layer Super Output Areas (MSOAs) are made up of groups of Lower layer Super Output
          Areas (LSOAs), usually four or five. They comprise between 2,000 and 6,000 households and
          have a usually resident population between 5,000 and 15,000 persons. MSOA&apos;s fit
          within local authorities.
        </div>
      </div>

      <div>
        <div className="atlas-font-semibold">Local Authority</div>
        <div>These are made up from multiple Middle layer Super Output Areas (MSOA&apos;s).</div>
      </div>

      <div>
        <div className="atlas-font-semibold">Region</div>
        <div>These are made up of multiple Local Authorities (LAs).</div>
      </div>

      <div>
        <div className="atlas-font-semibold">Country</div>
        <div>
          This is made up from planning authorities located across the country. It is unavailable
          for visualisation on the map, but is available as a point of comparison.
        </div>
      </div>
    </div>
  );
}

function DialogInfoUS() {
  return (
    <div className="atlas-flex atlas-flex-col atlas-gap-y-4">
      <div>
        <p>
          We’ve sourced demographics from the The United States Census Bureau, officially the Bureau
          of the Census. It&apos;s a principal agency of the U.S. Federal Statistical System,
          responsible for producing data about the American people and economy -{" "}
          <a
            href="https://www.census.gov/geographies/reference-maps/2020/geo/2020pl-maps.html"
            target="_blank"
            rel="noreferrer"
          >
            read more{" "}
          </a>
        </p>
        <p>
          You can read more about the attributes available for demographics on the LandTech help
          centre -{" "}
          <a
            href="https://support.land.tech/en/articles/9357860-layer-demographics"
            target="_blank"
            rel="noreferrer"
          >
            read more.
          </a>
        </p>
        <p>There are three main points of comparison.</p>
      </div>
      <div>
        <div className="atlas-font-semibold">Tract</div>
        <div>
          Tracts are small, relatively permanent statistical subdivisions of a county. Census tracts
          average about 4,000 inhabitants - Minimum Population - 1,200 - Maximum Population - 8,000.
        </div>
      </div>

      <div>
        <div className="atlas-font-semibold">County</div>
        <div>
          Counties are made up of multiple tracts. A county or equivalent is an administrative or
          political subdivision of a state which consists of a geographic area with specific
          boundaries.
        </div>
      </div>

      <div>
        <div className="atlas-font-semibold">State</div>
        <div>Made up of multiple counties.</div>
      </div>
    </div>
  );
}
