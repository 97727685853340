import type { Deck } from "@deck.gl/core";
import {
  LtIconKey,
  getIconUnicodeCharFromKey,
} from "react-migration/domains/constraints/designation/style/icons";

const memos = new WeakMap<Deck, Record<string, FontIconLayerAtlasManager>>();

const DEFAULT_CANVAS_WIDTH = 1024;
const DEFAULT_BUFFER = 4;

export interface FontIconLayerAtlasManagerParams {
  deck: Deck;
  characterSet: LtIconKey[];
  iconSettings?: Partial<IconSettings>;
}

function generateCacheKey(iconKeys: LtIconKey[], iconSettings: Partial<IconSettings>) {
  return `${iconKeys.join(",")}${JSON.stringify(iconSettings)}`;
}

export interface IconSettings {
  radius: number;
  borderWidth: number;
  density: number;
}

interface FontIconMappingEntry {
  x: number;
  y: number;
  width: number;
  height: number;
  mask: boolean;
}
const defaultIconSettings: IconSettings = {
  radius: 16,
  borderWidth: 2,
  density: 2,
};

export function getFontIconLayerAtlasManager(props: FontIconLayerAtlasManagerParams) {
  const cacheKey = generateCacheKey(props.characterSet, props.iconSettings || {});

  if (!memos.has(props.deck) || !memos.get(props.deck)![cacheKey]) {
    const existingManagers = memos.get(props.deck) ?? {};
    memos.set(props.deck, {
      ...existingManagers,
      [cacheKey]: new FontIconLayerAtlasManager(props),
    });
  }

  return memos.get(props.deck)![cacheKey];
}

/**
 * TODO:
 * - will not recalculate atlas if character set changes or iconSettings change
 */
export class FontIconLayerAtlasManager {
  _canvas?: HTMLCanvasElement;
  _iconKeySet: LtIconKey[] = [];
  _iconSettings: IconSettings = defaultIconSettings;

  constructor({ characterSet, iconSettings = {} }: FontIconLayerAtlasManagerParams) {
    this._iconKeySet = characterSet;
    this._iconSettings = {
      ...defaultIconSettings,
      ...iconSettings,
    };
  }

  protected getCanvasDimensions() {
    const { borderWidth, radius, density } = this._iconSettings;
    const iconWidth = (radius * 2 + borderWidth * 2) * density;
    const iconHeight = iconWidth;
    const canvasWidth = DEFAULT_CANVAS_WIDTH;
    const totalCharacterWidth = this._iconKeySet.length * iconWidth;
    const numberOfRows = Math.ceil(totalCharacterWidth / canvasWidth);
    const canvasHeight = nextPowOfTwo(numberOfRows * iconHeight);

    return {
      width: canvasWidth,
      height: canvasHeight,
      cellWidth: iconWidth,
      cellHeight: iconHeight,
    };
  }

  getFontIconAtlas() {
    if (this._canvas) {
      return this._canvas;
    }

    this._canvas ??= document.createElement("canvas");
    const { width, height } = this.getCanvasDimensions();
    this._canvas.width = width;
    this._canvas.height = height;

    const { borderWidth, radius, density } = this._iconSettings;
    const iconMapping = this.getFontIconMapping();
    const canvas = this._canvas;
    const ctx = canvas.getContext("2d")!;

    for (const [iconKey, { x, y, width, height }] of Object.entries(iconMapping)) {
      const centerX = x + width / 2;
      const centerY = y + height / 2;

      // Required to ensure that the edges of the icon are anti-aliased
      ctx.fillStyle = "rgba(255, 255, 255, 0.01)";
      ctx.fillRect(x, y, width, height);

      ctx.fillStyle = "fuchsia";
      ctx.strokeStyle = "white";
      ctx.lineWidth = borderWidth * density;

      ctx.beginPath();
      ctx.arc(centerX, centerY, radius * density, 0, 2 * Math.PI);
      ctx.fill();
      ctx.stroke();

      ctx.font = `normal ${radius * 2 * density * 0.6}px lt-icons`;
      ctx.textAlign = "center";
      ctx.textBaseline = "middle";
      ctx.fillStyle = "white";

      const characterCode = getIconUnicodeCharFromKey(iconKey as LtIconKey);

      ctx.fillText(characterCode, centerX, centerY);
    }

    return canvas;
  }

  getFontIconMapping(): Record<LtIconKey, FontIconMappingEntry> {
    const { width: canvasWidth, cellWidth, cellHeight } = this.getCanvasDimensions();
    const iconOffset = cellWidth + DEFAULT_BUFFER * 2;

    return Object.fromEntries<FontIconMappingEntry>(
      this._iconKeySet.map((iconKey, index) => {
        const x = (index * iconOffset) % canvasWidth;
        const row = Math.floor((index * iconOffset) / canvasWidth);
        const y = row * iconOffset;
        const mapping: FontIconMappingEntry = {
          width: cellWidth,
          height: cellHeight,
          x,
          y,
          mask: false,
        };

        return [iconKey, mapping];
      })
    ) as Record<LtIconKey, FontIconMappingEntry>;
  }
}
function nextPowOfTwo(number: number): number {
  return Math.pow(2, Math.ceil(Math.log2(number)));
}
