<template>
  <SiteReport />
</template>

<script>
import { SiteReport } from "react-migration/domains/sites/card/SiteReport/SiteReport.tsx";

export default {
  components: {
    SiteReport,
  },
};
</script>
