import React from "react";

export const DinersIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" height="100%" viewBox="0 0 24 16">
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g fillRule="nonzero" transform="translate(-320 -280)">
          <g transform="translate(40 200)">
            <g transform="translate(0 80)">
              <g transform="translate(280)">
                <path
                  fill="#FFF"
                  stroke="#000"
                  strokeOpacity="0.2"
                  strokeWidth="0.5"
                  d="M21.997 15.75H22c.955.008 1.74-.773 1.751-1.746V2.006a1.789 1.789 0 00-.52-1.25A1.72 1.72 0 0021.997.25H2.001A1.718 1.718 0 00.77.757c-.33.33-.517.779-.521 1.247v11.99c.004.47.191.92.52 1.25.329.328.771.51 1.233.506h19.994zm0 .5h-.002.002z"
                ></path>
                <path
                  fill="#0165AC"
                  d="M10.002 2.052v-.016h4v.016a6 6 0 010 11.968v.016h-4v-.016a6 6 0 010-11.968z"
                ></path>
                <path
                  fill="#FFF"
                  d="M11.602 11.428a3.6 3.6 0 000-6.784v6.784zm-2.4-6.784a3.6 3.6 0 000 6.784V4.644zm1.2 8.592a5.2 5.2 0 110-10.4 5.2 5.2 0 010 10.4z"
                ></path>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
