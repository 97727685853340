import Feature from "src/js/stores/user/Feature";
import { ConstraintsCategory } from "../../Constraints/types";
import { ConstraintsLayerType } from "../layer_types/ConstraintsLayerType";
import { Layer } from "../types";

export const REZONING_APPLICATIONS: Layer = {
  id: ConstraintsCategory.REZONING_APPLICATIONS,
  layerType: ConstraintsLayerType,
  title: "Rezoning Applications",
  description:
    "Rezoning applications show residential, commercial and mixed used applications for rezoning",
  toggleable: true,
  label: { color: "BLUE", translationKey: "map.explore.label.new" },
  requiredFeatureAccess: [Feature.rezoningApplications],
  inDevelopment: true,
  layerConfig: {
    rootCategory: ConstraintsCategory.REZONING_APPLICATIONS,
    categorySchema: [
      { key: "rezoning_residential" },
      { key: "rezoning_commercial" },
      { key: "rezoning_mixed_use" },
    ],
  },
};
