import { PickingInfo } from "deck.gl";
import { MultiPolygon, Polygon, feature, point } from "@turf/helpers";
import { SelectionType } from "src/js/stores/map/store";
import { getTitlePolysByLocationQuery } from "react-migration/domains/ownership/apollo/useTitlePolysByLocation/getTitlePolysByLocation";
import { OwnershipFeature } from "react-migration/domains/ownership/typings/Feature";
import { generateMergedPolygon } from "react-migration/lib/util/generateMergedPolygon";
import {
  logNoPickedCoordinateError,
  logNoGeometryError,
  logNoUnitsAtLocationError,
  logMissingGeometryWarning,
} from "./logUtils";
import { isDefined } from "react-migration/lib/util/isDefined";
import { MD5 } from "object-hash";
import { getMainPlotOrFirstUnit } from "react-migration/domains/ownership/helpers/getMainPlotOrFirstUnit";

export const titleClickResolverUS = async (pickInfo: PickingInfo<OwnershipFeature>) => {
  // pickInfo.object is verified as non-null by isOwnershipFeature prior to calling this function
  const object = pickInfo.object!;
  if (!pickInfo.coordinate) {
    logNoPickedCoordinateError("titleClickResolverUS");
    return null;
  }

  const clickPoint = point(pickInfo.coordinate);
  const ownershipFeatures = await getTitlePolysByLocationQuery(clickPoint.geometry);
  const mainUnit = getMainPlotOrFirstUnit(ownershipFeatures);

  if (!mainUnit) {
    logNoUnitsAtLocationError("titleClickResolverUS", pickInfo);
    return null;
  }

  const mainUnitGeometries = mainUnit.polygons?.map((p) => p.geometry).filter(isDefined);
  if (!mainUnitGeometries?.length) {
    if (object.geometry) {
      logMissingGeometryWarning("titleClickResolverUS", pickInfo, mainUnit.title_no);
      return parcelFromPickedGeometry(object.geometry);
    } else {
      logNoGeometryError("titleClickResolverUS", pickInfo, mainUnit.title_no);
      return null;
    }
  }

  const mergedPolygon = generateMergedPolygon(mainUnitGeometries);
  const selectionIdentifier = mainUnit.title_no
    ? { type: SelectionType.OWNERSHIP_TITLE, id: mainUnit.title_no }
    : { type: SelectionType.OWNERSHIP_PARCEL, id: MD5(mergedPolygon) };

  return {
    ...selectionIdentifier,
    feature: feature(mergedPolygon),
  };
};

function parcelFromPickedGeometry(geometry: Polygon | MultiPolygon) {
  // need to resolve coordinates from DeckGL proxy object.
  const pickedGeom = {
    ...geometry,
    coordinates: geometry.coordinates,
  } as Polygon | MultiPolygon;

  return {
    type: SelectionType.OWNERSHIP_PARCEL,
    id: MD5(pickedGeom),
    feature: feature(pickedGeom),
  };
}
