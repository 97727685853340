import { useEffect, useState } from "react";
import SITE_BOUNDARY_IMAGE_BULK_QUERY from "react-migration/domains/sites/apollo/li-api/queries/siteBoundaryImageBulk.gql";
import { routedClient } from "react-migration/lib/persistence/apollo/routedClient";

import { useQuery } from "@apollo/client";

type SiteNode = {
  _id: string;
  boundary_image: {
    id: string;
    uri: string;
  };
};

type SiteBoundaryImageBulkResponseData = {
  siteBoundaryImageBulk: {
    edges: {
      node: SiteNode;
    }[];
  };
};

export type LoadSiteBoundaryImageBulkProps = {
  siteIds?: string[];
  stageIds?: string[];
  skip?: boolean;
};

export type SiteBoundaryImageBulkVariables = Omit<LoadSiteBoundaryImageBulkProps, "skip"> & {
  limit: number;
};

export function useLoadSiteBoundaryImageBulk({
  siteIds,
  stageIds,
  skip,
}: LoadSiteBoundaryImageBulkProps) {
  const [siteNodes, setSiteNodes] = useState<SiteNode[]>([]);

  const variables: SiteBoundaryImageBulkVariables = {
    limit: siteIds?.length ?? 50,
  };

  // making sure only one or the other is sent, prioritising siteIds over stageIds
  if (siteIds) {
    variables.siteIds = siteIds;
  } else if (stageIds) {
    variables.stageIds = stageIds;
  }

  const { loading, data } = useQuery<SiteBoundaryImageBulkResponseData>(
    SITE_BOUNDARY_IMAGE_BULK_QUERY,
    {
      client: routedClient,
      variables,
      skip,
    }
  );

  useEffect(() => {
    if (!loading && data) {
      const siteNodes = data.siteBoundaryImageBulk.edges.map(({ node: site }) => site);
      setSiteNodes(siteNodes);
    }
  }, [data, loading]);

  return { loading, siteNodes };
}
