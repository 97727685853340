import { Icon, InfoList, Theme, DeprecatedTooltip } from "@landtechnologies/components";
import { HTMLProps } from "react";

export interface ZooplaRecordItemProps extends HTMLProps<HTMLDivElement> {
  title: string;
  tooltipContent?: string;
}
export const ZooplaRecordItem = ({
  className,
  children,
  title,
  tooltipContent,
}: ZooplaRecordItemProps) => {
  return (
    <InfoList.Item data-testid="zoopla-record-item" className={className}>
      <InfoList.ItemTitle>
        <span className="atlas-text-content-secondary">
          {title}
          {tooltipContent ? (
            <DeprecatedTooltip description={tooltipContent} theme={Theme.Dark}>
              <Icon size="sm" icon="icon-lt-information-line" />
            </DeprecatedTooltip>
          ) : null}
        </span>
      </InfoList.ItemTitle>
      <InfoList.ItemValue>{children}</InfoList.ItemValue>
    </InfoList.Item>
  );
};
