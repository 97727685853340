import { PlanningAlertsTable } from "../PlanningAlertsTable/PlanningAlertsTable";
import ROUTES from "src/js/router/routes";
import { router } from "src/js/router/react-router";
import { useMemo } from "react";

import { SiteHeader } from "./SiteHeader";
import { Site } from "react-migration/domains/dashboard/typings/SitesWithAlertsSubscriptionResponse";
import { PlanningAlert } from "react-migration/domains/dashboard/typings/PlanningAlert";

type SavedSitesAlertsSectionProps = {
  site: Site;
};

export const SavedSitesAlertsSection = ({ site }: SavedSitesAlertsSectionProps) => {
  const handleEditAlert = (planningAlert: PlanningAlert) => {
    router.push({
      name: ROUTES.SITES,
      query: {
        card_page: "alerts",
        site_id: planningAlert.search._site,
        alert_id: planningAlert._id,
      },
    });
  };

  const results = useMemo(
    () =>
      site.planning_alert_subscriptions.reduce(
        (acc, planningAlert) => acc + planningAlert.results_count,
        0
      ),
    [site]
  );
  return (
    <div data-testid={`site-alert-section-${site._id}`} className="atlas-mb-7">
      <SiteHeader _id={site._id} results={results} title={site.title} />
      <PlanningAlertsTable
        planningAlerts={site.planning_alert_subscriptions}
        onEdit={handleEditAlert}
      />
    </div>
  );
};
