import { SimpleTitleOwnership, Title, TitleOwnership } from "../typings/Title";

export const getTitleOwners = (mainTitle?: Title) => {
  const titleOwners: SimpleTitleOwnership[] = [];
  if (mainTitle?.owners) {
    mainTitle.owners.forEach((owner: TitleOwnership) => {
      const ownerName = owner.person ? owner.person.name : owner.company?.name;
      const addresses = owner.person ? owner.person.address : owner.company?.addresses?.[0] ?? "";

      titleOwners.push({
        id: owner.id,
        name: ownerName,
        address: addresses,
        appraiser_link: owner.company?.appraiser_link,
        global_ultimate_owner: owner?.company?.global_ultimate_owner,
        domestic_ultimate_owner: owner?.company?.domestic_ultimate_owner,
        key_people: owner.company?.key_people,
        type: owner.company ? "COMPANY" : "INDIVIDUAL",
      });
    });
  }

  return titleOwners;
};
