import { ApolloError, FetchResult, useMutation } from "@apollo/client";

import ARCHIVE_LABEL from "../graphql/archiveLabel.gql";
import { routedClient } from "react-migration/lib/persistence/apollo/routedClient";

export interface ArchiveLabelVariables {
  _id: string;
}

export interface ArchiveLabelResponse {
  archived_item: {
    _id: string;
  };
}

export const useArchiveLabel = () => {
  const [archiveLabel, { loading, error }] = useMutation<ArchiveLabelResponse>(ARCHIVE_LABEL, {
    client: routedClient,
  });

  const archiveLabelMutation = async (
    variables: ArchiveLabelVariables
  ): Promise<FetchResult<ArchiveLabelResponse>> => {
    try {
      const result = await archiveLabel({ variables, refetchQueries: ["allSiteLabels"] });
      return result;
    } catch (error) {
      console.log(`Error removing label: ${error}`);
      throw error as ApolloError;
    }
  };

  return { archiveLabelMutation, loading, error };
};
