import { useMemo } from "react";
import type { TreeStructure, RecursiveTree } from "./types";
import { sortedTreeRecursive, SortedTreeRecursiveProps } from "./TreeBuilders/sortedTreeRecursive";
import {
  CheckboxItem,
  checkboxTreeRecursive,
  CheckboxTreeRecursiveProps,
} from "./TreeBuilders/checkboxTreeRecursive";
import {
  LabelItem,
  labelTreeRecursive,
  LabelTreeRecursiveProps,
} from "./TreeBuilders/labelTreeRecursive";
import {
  CollapsibleItem,
  collapsibleTreeRecursive,
  CollapsibleTreeRecursiveProps,
} from "./TreeBuilders/collapsibleTreeRecursive";

export type CollapsibleCheckboxTree = RecursiveTree<LabelItem & CheckboxItem & CollapsibleItem>;

type UseCollapsibleCheckboxTreeProps = LabelTreeRecursiveProps &
  CheckboxTreeRecursiveProps &
  SortedTreeRecursiveProps &
  CollapsibleTreeRecursiveProps;

/**
 * The parent will always show if the child is present
 * If the parent is defined without a child, control all it's children and pretend to be leaf
 * If the parent is defined with a child, I am a collapsible treeNode
 */
export function useCollapsibleCheckboxTree(
  tree: TreeStructure,
  props: UseCollapsibleCheckboxTreeProps
) {
  return useMemo(() => {
    return collapsibleTreeRecursive(
      labelTreeRecursive(checkboxTreeRecursive(sortedTreeRecursive(tree, props), props), props),
      props
    );
  }, [tree, props]);
}
