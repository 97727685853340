import DESIGNATION from "react-migration/domains/constraints/apollo/designation.gql";
import { useQuery } from "@apollo/client";
import { LandTechEndpoints, routedClient } from "react-migration/lib/persistence/apollo";
import { SingleDesignation } from "react-migration/domains/constraints/typings/applicationTypes/SingleDesignation";

export function useDesignationById(id?: string) {
  const { data, ...rest } = useQuery<{ designation: SingleDesignation }>(DESIGNATION, {
    variables: {
      id: id,
      documentCursor: {
        limit: 10,
      },
    },
    client: routedClient,
    context: {
      endpoint: LandTechEndpoints.Constraints,
    },
    skip: !id,
  });

  return { designation: data?.designation, ...rest };
}
