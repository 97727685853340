import { TextWithVariants } from "@landtechnologies/components";
import { AllGeoJSON } from "@turf/helpers";
import { useMemo } from "react";
import { DetailsList } from "react-migration/components/DetailsList";
import { Nullable } from "src/js/types/Nullable";
import { useSnapshot } from "valtio";
import { useTranslation } from "react-migration/lib/i18n/useTranslation";
import { getGeometryMeasurements } from "react-migration/lib/util/getAreaAndPerimeter";
import { userStore } from "src/js/stores/user/store";

type GeometryMeasurementsProps = {
  geometry?: Nullable<AllGeoJSON>;
};

export function GeometryMeasurements({ geometry }: Readonly<GeometryMeasurementsProps>) {
  const { user } = useSnapshot(userStore);
  const { t } = useTranslation();

  const measurements = useMemo(() => {
    return geometry && getGeometryMeasurements(geometry, user.settings.unit);
  }, [geometry, user.settings.unit]);

  if (!measurements) return null;

  return (
    <DetailsList>
      <DetailsList.Item label={t("components.measure2D.area")}>
        <TextWithVariants variants={measurements.areaVariants} />
      </DetailsList.Item>
      <DetailsList.Item label={t("components.measure2D.perimeter")}>
        <TextWithVariants variants={measurements.perimeterVariants} />
      </DetailsList.Item>
    </DetailsList>
  );
}
