import { useEffect, useMemo, useState } from "react";

import type { Site } from "../typings/Site";
import { sortMobileStagesToEnd } from "../utils/sortMobileStagesToEnd";
import { useCreateSitesDryRunQuery } from "../useCreateSitesDryRunQuery";
import { useSiteStages } from "react-migration/domains/sites/hooks/useSiteStages";

type useCheckStagesParams = {
  sites: Site[];
  disableDryRun?: boolean;
  siteMatchType?: "EXACT" | "INTERSECTS_POINT";
};

export const useCheckStages = ({ sites, disableDryRun, siteMatchType }: useCheckStagesParams) => {
  const [selectedStageId, setSelectedStageId] = useState<string | undefined>();
  const { stages, loading: isStagesLoading, networkStatus, error: stagesError } = useSiteStages();
  const stagesOrdered = useMemo(() => stages && [...stages].sort(sortMobileStagesToEnd), [stages]);
  const {
    data,
    loading,
    error: dryRunError,
  } = useCreateSitesDryRunQuery({
    shouldWait: isStagesLoading || disableDryRun,
    sites: sites,
    siteMatchType,
    onCompleted: (res) => {
      if (res.createSitesDryRun[0].saved_site) {
        setSelectedStageId(res.createSitesDryRun[0].saved_site?._stage._id);
      } else {
        setSelectedStageId(stagesOrdered?.[0]?._id);
      }
    },
  });

  useEffect(() => {
    if (disableDryRun && stagesOrdered?.length) {
      setSelectedStageId(stagesOrdered[0]._id);
    }
  }, [disableDryRun, stagesOrdered]);

  return {
    isError: dryRunError || stagesError,
    data,
    loading,
    selectedStageId,
    setSelectedStageId,
    stages: stagesOrdered,
    isStagesLoading,
    networkStatus,
  };
};
