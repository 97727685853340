import { ISourcingToolSearchFilter } from "react-migration/domains/search/typings/SourcingToolSpecificFilterTypes";
import { SearchType } from "react-migration/domains/search/constants/searchTypes";
import { IDateRangeSearchFilter } from "react-migration/domains/search/typings/SourcingToolFilters/DateRangeSearchFilter";
import { IMultiTextFilter } from "react-migration/domains/search/typings/SourcingToolFilters/MultiTextFilter";
import { INumberRangeSearchFilter } from "react-migration/domains/search/typings/SourcingToolFilters/NumberRangeSearchFilter";
import { ENVIRONMENT } from "src/js/util/environment";

//Range Limit for date
const startYear = new Date(`1900-01-01T00:00:00.000Z`);
const endOfYear = new Date(`${new Date().getFullYear()}-12-31T23:59:59.999Z`);
const defaultStartYear = new Date(new Date(startYear).setFullYear(new Date().getFullYear() - 5));

const PlanningApplicationsTextFilter: IMultiTextFilter = {
  id: "planning-applications-multi-text-filter-id",
  type: "multi-text-search",
  must: "MATCH_ALL",
  mustOptions: ["MATCH_ALL"],
  mustIsConfigurable: false,
  searchTags: [],
  searchTagsAutoCompleteConfig: {
    basePath: `${ENVIRONMENT.MCSEARCH_SERVICE_URL}/autocomplete/{type}/{limit}/{searchText}`,
    variables: {
      type: `planning_application_tags`,
      limit: 5,
    },
    searchVariableName: "searchText",
  },
  field: "sidebar.react.filter_form.planning_applications.contains.title",
};
const PlanningApplicationsSubmissionDateFilter: IDateRangeSearchFilter = {
  id: "planning-applications-submission-date",
  type: "date-range",
  range: [defaultStartYear, null],
  rangeLimit: [startYear, endOfYear],
  granularity: "month",
  field: "sidebar.react.filter_form.planning_applications.submissions.title",
};
const PlanningApplicationsDwellingsFilter: INumberRangeSearchFilter = {
  id: "planning-applications-number-of-units",
  type: "number-range",
  rangeType: "basic",
  range: [null, null],
  rangeLimit: [0, 500],
  field: "sidebar.react.filter_form.planning_applications.dwellings.title",
  startInputPlaceholder: "from",
  endInputPlaceholder: "to",
};
export const PlanningApplicationsFilter: ISourcingToolSearchFilter = {
  id: "planning-applications-id",
  type: "compound",
  field: "sidebar.react.search_sidebar.planning_applications",
  filters: [
    PlanningApplicationsTextFilter,
    PlanningApplicationsSubmissionDateFilter,
    PlanningApplicationsDwellingsFilter,
  ],
  mustOptions: ["MATCH_ALL", "MATCH_NONE"],
  must: "MATCH_ALL", //This is the default on the dropdown
  mustIsConfigurable: true,
  updateFilterDialogIsVisible: false,
  searchTypes: [SearchType.SOURCING_TOOL_UK],
};
