import designationAttributes from "./designation-attribute.json";
import designationComponents from "./components.json";
import designationCategories from "./categories.json";
import designationStatuses from "./statuses.json";

export default {
  ...designationAttributes,
  ...designationComponents,
  ...designationCategories,
  ...designationStatuses,
};
