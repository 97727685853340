import { useMutation } from "@apollo/client";
import { UserAddressesResponse } from "../typings/UserAddressesResponse";
import INSERT_USER_ADDRESS from "../apollo/mutation/insertUserAddress.gql";
import GET_USERS_ADDRESSES from "../apollo/queries/userAddresses.gql";

import { routedClient } from "react-migration/lib/persistence/apollo/routedClient";

type Variables = {
  address: {
    receiver_name: string;
    line1: string;
    line2: string;
    city: string;
    postcode: string;
    country: string;
  };
  as_default: boolean;
};

export const useCreateUserAddressMutation = () => {
  return useMutation<UserAddressesResponse, Variables>(INSERT_USER_ADDRESS, {
    client: routedClient,
    refetchQueries: [{ query: GET_USERS_ADDRESSES }],
  });
};
