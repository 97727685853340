import { proxy } from "valtio/vanilla";
import Feature from "./Feature";
import { Locale } from "../../locales";

export enum MeasurementSystem {
  IMPERIAL = "imperial",
  METRIC = "metric",
}

export enum Region {
  UK = "UK",
  US = "US",
}

export type UINotifications = {
  helpMenu?: {
    seen: boolean;
  };
  accountMenu?: {
    seen: boolean;
  };
};

export type UserSettings = {
  unit: MeasurementSystem;
  uiNotifications: UINotifications | null;
  receiveOwnershipAlerts: boolean;
  showDashboardWelcome: boolean;
  downloadCompsWithoutFloorArea: boolean;
  showHiddenSites: boolean;
  showHiddenSitesOnPipeline: boolean;
  hideTitleDocPurchaseAddressPrompt: boolean;
  sitesDisplayMode: "stages" | "list";
};

export type User = {
  _id: string | null;
  email: string | null;
  telephone: string | null;
  surname: string | null;
  firstname: string | null;
  region: Region;
  features: Feature[];
  beta_features: Feature[];
  licence_summary: string;
  organisation_name: string;
  intercom_hash?: string;
  has_overdue_invoices: boolean;

  // alert_notifications should be moved inside of settings
  alert_notifications: {
    alert_time?: number;
    alert_period: number;
  };
  settings: UserSettings;

  // still needed by pusher logic (which feels wrong)
  _account: {
    _id: string | null;
  };

  permissions: {
    geofence: { geometryUrl: string; id: string }[];
    geofencesGeometries: GeoJSON.Feature<GeoJSON.Polygon | GeoJSON.MultiPolygon>[];
  } | null;
  stripe_managed_signup: boolean;
  self_upgrade_options: string[];
  plan_name: string;
  api_key: string;
  self_service_trial_state: {
    active: boolean;
    end_date?: Date;
    automatic_payment?: boolean;
  };
};

export type UserStore = {
  user: User;
  isSaving: boolean;
  locale: Locale;
};

export const defaultUser: User = {
  _id: null,
  email: null,
  telephone: null,
  surname: null,
  firstname: null,
  region: Region.UK,
  features: [],
  beta_features: [],
  licence_summary: "",
  organisation_name: "",
  has_overdue_invoices: false,
  self_upgrade_options: [],
  alert_notifications: {
    alert_period: 0,
  },
  settings: {
    unit: MeasurementSystem.IMPERIAL,
    uiNotifications: {},
    receiveOwnershipAlerts: false,
    showDashboardWelcome: false,
    downloadCompsWithoutFloorArea: false,
    showHiddenSites: false,
    showHiddenSitesOnPipeline: true,
    hideTitleDocPurchaseAddressPrompt: false,
    sitesDisplayMode: "stages",
  },
  _account: {
    _id: null,
  },
  permissions: {
    geofence: [],
    geofencesGeometries: [],
  },
  stripe_managed_signup: true,
  plan_name: "",
  api_key: "",
  self_service_trial_state: {
    active: false,
  },
};

export const userStore = proxy<UserStore>({
  user: defaultUser,
  isSaving: false,
  locale: "en-GB",
});
