import { Icon } from "@landtechnologies/components";

import { Avatar, AvatarSize } from "../Avatar";
import { Button } from "../Button";

export interface AvatarItem {
  id: string;
  firstName: string;
  lastName: string;
  selected: boolean;
}

type AvatarSelectItemProps = {
  avatar: AvatarItem;
  disabled: boolean;
  onRowClick: () => void;
  onDelete: () => void;
};

export const AvatarSelectItem = ({
  avatar,
  disabled,
  onRowClick,
  onDelete,
}: AvatarSelectItemProps) => {
  return (
    <div data-testid="avatar-row" className="atlas-flex atlas-items-center atlas-cursor-pointer">
      <button
        className="atlas-flex atlas-flex-grow atlas-items-center"
        disabled={disabled}
        onClick={onRowClick}
      >
        <div className="atlas-flex-shrink-0">
          <Avatar
            key={avatar.id}
            firstName={avatar.firstName}
            lastName={avatar.lastName}
            size={AvatarSize.XS}
          />
        </div>
        <span className="atlas-flex-grow atlas-mx-2 atlas-truncate atlas-text-neutral-800 atlas-text-sm atlas-text-left atlas-font-normal hover:atlas-bg-platformBlue-50">
          {avatar.firstName} {avatar.lastName}
        </span>
      </button>
      {avatar.selected ? (
        <Button
          data-testid="delete-avatar"
          variant="ghost"
          className="atlas-w-5 atlas-h-5 atlas-flex-shrink-0"
          disabled={disabled}
          onClick={onDelete}
        >
          <Icon
            size="sm"
            icon="icon-lt-close-line atlas-text-neutral-500"
            extraClasses="atlas-pointer-events-none"
          />
        </Button>
      ) : (
        <div className="atlas-w-5 atlas-h-5" />
      )}
    </div>
  );
};
