import {
  Button,
  DropdownMenu,
  DropdownMenuTriggerAlignment,
  Icon,
  SearchIcon,
  TextInput,
} from "@landtechnologies/components";
import { useCallback, useEffect, useMemo, useState } from "react";

import { ActionBar } from "./ActionBar";
import { SelectOptionItem } from "./SelectOptionItem";
import classNames from "classnames";
import { useTranslation } from "react-migration/lib/i18n/useTranslation";

export type SelectOption = {
  id: string;
  name: string;
  color?: string;
};

export type SelectFilterProps = {
  label: string;
  labelClassName?: string;
  contentTitle: string;
  options: SelectOption[];
  selectedOptionIds: string[];
  onSelectedOptionsUpdate: (optionIds: string[]) => void;
  className?: string;
  dataTestId?: string;
};

export const SelectFilter: React.FC<SelectFilterProps> = ({
  label,
  labelClassName,
  contentTitle,
  options,
  selectedOptionIds,
  onSelectedOptionsUpdate,
  className,
  dataTestId = "select-filter",
}: SelectFilterProps) => {
  const { t } = useTranslation();

  const [filterValue, setFilterValue] = useState("");

  const [activeOptionIds, setActiveOptionIds] = useState<string[]>(selectedOptionIds);

  useEffect(() => {
    setActiveOptionIds(selectedOptionIds);
  }, [selectedOptionIds]);

  const triggerText = useMemo(() => {
    if (!selectedOptionIds.length) {
      return t("sites.pipeline.filters.all");
    }
    return t("sites.pipeline.filters.number_of_filters_selected", {
      selectedFiltersCount: selectedOptionIds.length,
    });
  }, [selectedOptionIds.length, t]);

  const onCancel = () => {
    setActiveOptionIds(selectedOptionIds);
  };

  const onApply = () => {
    onSelectedOptionsUpdate(
      options.map(({ id }) => id).filter((id) => activeOptionIds.includes(id))
    );
  };

  const onClearFilterClick = () => {
    setActiveOptionIds([]);
  };

  const onSelectAllToggle = useCallback(() => {
    if (activeOptionIds.length !== options.length) {
      setActiveOptionIds(options.map(({ id }) => id));
    } else {
      setActiveOptionIds([]);
    }
  }, [activeOptionIds.length, options]);

  const onOptionToggle = useCallback(
    (optionId: string) => {
      if (activeOptionIds.includes(optionId)) {
        setActiveOptionIds(activeOptionIds.filter((id) => id !== optionId));
      } else {
        setActiveOptionIds([...activeOptionIds, optionId]);
      }
    },
    [activeOptionIds]
  );

  return (
    <div
      data-testid={dataTestId}
      className={classNames(
        "atlas-flex",
        "atlas-flex-row",
        "atlas-flex-nowrap",
        "atlas-items-center",
        className
      )}
    >
      <span
        className={classNames("atlas-text-neutral-500", "atlas-mr-2", labelClassName)}
        data-testid="select-filter-label"
      >
        {label}
      </span>
      <DropdownMenu.Provider>
        <DropdownMenu.Trigger
          align={DropdownMenuTriggerAlignment.BOTTOM_LEFT}
          data-testid="select-filter-dropdown-trigger"
        >
          <span
            className={classNames(
              "atlas-font-semibold",
              "atlas-px-2",
              "atlas-py-1",
              "atlas-rounded",
              "atlas-text-content-primary",
              "atlas-flex",
              "atlas-items-center",
              "atlas-justify-center",
              "hover:atlas-bg-neutral-200"
            )}
          >
            {triggerText}
            <Icon
              cursor="pointer"
              data-testid="site-options-trigger-icon"
              icon="icon-lt-arrow-down-s-fill"
              size="sm"
              extraClasses="atlas-ml-1"
            />
          </span>
        </DropdownMenu.Trigger>
        <DropdownMenu.Content data-testid="select-filter-dropdown-content">
          <DropdownMenu.ItemText
            data-testid="select-filter-dropdown-title"
            className="atlas-font-semibold"
          >
            {contentTitle}
          </DropdownMenu.ItemText>

          <div data-testid="select-filter-search" className="atlas-pb-2 atlas-pt-0 atlas-px-3">
            <TextInput
              size="medium"
              dataTestId="select-filter-search-input"
              placeholder={t("sites.pipeline.filters.search")}
              IconLeft={
                <div className={classNames("atlas-w-4", "atlas-h-4", "atlas-text-neutral-300")}>
                  <SearchIcon />
                </div>
              }
              value={filterValue}
              onChange={(e) => setFilterValue(e.target.value)}
            />
          </div>

          <Button.Borderless
            data-testid="select-filter-clear-button"
            disabled={activeOptionIds.length === 0}
            className={classNames("atlas-text-content-action", "atlas-my-2")}
            onClick={onClearFilterClick}
          >
            {t("sites.pipeline.filters.clear_filter")}
          </Button.Borderless>

          <SelectOptionItem
            id="all"
            name={t("sites.pipeline.filters.select_all")}
            isSelected={activeOptionIds.length === options.length}
            onToggle={onSelectAllToggle}
          />

          <div
            className={classNames(
              "atlas-pl-4",
              "atlas-flex",
              "atlas-overflow-scroll",
              "atlas-max-h-48",
              "atlas-flex-col"
            )}
            data-testid="select-filter-option-list"
          >
            {options
              .filter(({ name }) => {
                const filter = new RegExp(filterValue, "i");
                return filter.test(name);
              })
              .sort(({ name: a }, { name: b }) => {
                if (a < b) return -1;
                if (a > b) return 1;
                return 0;
              })
              .map(({ id, name, color }) => (
                <SelectOptionItem
                  key={id}
                  id={id}
                  name={name}
                  color={color}
                  isSelected={activeOptionIds.includes(id)}
                  onToggle={onOptionToggle}
                />
              ))}
          </div>
          <ActionBar onApply={onApply} onCancel={onCancel} />
        </DropdownMenu.Content>
      </DropdownMenu.Provider>
    </div>
  );
};
