import { ISourcingToolSearchFilter } from "react-migration/domains/search/typings/SourcingToolSpecificFilterTypes";
import { SearchType } from "react-migration/domains/search/constants/searchTypes";

export const AmenitiesTypeFilter: ISourcingToolSearchFilter = {
  id: "amenities-type-filter",
  type: "nested-checkbox",
  must: "MATCH_ALL",
  mustOptions: ["MATCH_ALL", "MATCH_NONE"],
  openAllSublevelsByDefault: true,
  updateFilterDialogIsVisible: false,
  displayMessages: {
    mustOptions: {
      MATCH_ALL: "sidebar.react.search_sidebar.has_all",
      MATCH_NONE: "sidebar.react.search_sidebar.has_no",
      MATCH_ANY: "sidebar.react.search_sidebar.has_some",
    },
    allSelectedMustOptions: {
      MATCH_ALL: "sidebar.react.search_sidebar.all_amenities",
      MATCH_NONE: "sidebar.react.search_sidebar.amenities",
      MATCH_ANY: "sidebar.react.search_sidebar.amenities",
    },
  },
  values: [
    {
      name: "sidebar.search_sidebar.transport",
      key: "transport",
      checked: false,
      nonSelectableRoot: true,
      items: [
        {
          name: "sidebar.search_sidebar.bus_stop",
          key: "shortest_distance_bus_stop",
        },
        {
          name: "sidebar.search_sidebar.train_station",
          key: "shortest_distance_train_station",
        },
        {
          name: "sidebar.search_sidebar.metro_station",
          key: "shortest_distance_metro_station",
        },
      ],
    },
    {
      name: "sidebar.search_sidebar.education",
      key: "education",
      checked: false,
      nonSelectableRoot: true,
      items: [
        {
          name: "sidebar.search_sidebar.primary_school",
          key: "shortest_distance_primary_school",
        },
        {
          name: "sidebar.search_sidebar.secondary_school",
          key: "shortest_distance_secondary_school",
        },
        {
          name: "sidebar.search_sidebar.further_education",
          key: "shortest_distance_further_education",
        },
        {
          name: "sidebar.search_sidebar.primary_school_independent",
          key: "shortest_distance_primary_school_independent",
        },
        {
          name: "sidebar.search_sidebar.secondary_school_independent",
          key: "shortest_distance_secondary_school_independent",
        },
        {
          name: "sidebar.search_sidebar.further_education_independent",
          key: "shortest_distance_further_education_independent",
        },
      ],
    },
    {
      name: "sidebar.search_sidebar.retail",
      key: "retail",
      nonSelectableRoot: true,
      checked: false,
      items: [
        {
          name: "sidebar.search_sidebar.supermarkets_and_convenience_stores",
          key: "shortest_distance_supermarkets_and_convenience_stores",
        },
      ],
    },
    {
      name: "sidebar.search_sidebar.healthcare",
      key: "healthcare",
      checked: false,
      nonSelectableRoot: true,
      items: [
        {
          name: "sidebar.search_sidebar.gp_surgeries",
          key: "shortest_distance_gp_surgeries",
        },
        {
          name: "sidebar.search_sidebar.pharmacies",
          key: "shortest_distance_pharmacies",
        },
        {
          name: "sidebar.search_sidebar.clinics",
          key: "shortest_distance_clinics",
        },
        {
          name: "sidebar.search_sidebar.dentists",
          key: "shortest_distance_dentists",
        },
        {
          name: "sidebar.search_sidebar.hospitals",
          key: "shortest_distance_hospitals",
        },
      ],
    },
  ],
  searchTypes: [SearchType.SOURCING_TOOL_US_FL],
};

export const AmenitiesDistanceFilter: ISourcingToolSearchFilter = {
  id: "amenities-distance-filter",
  field: "sidebar.react.search_sidebar.amenities_within_distance",
  type: "number",
  value: undefined,
  displayMessages: {
    mustOption: "seems.pointless.empty_string",
  },
  rangeLimit: [0, null],
};

export const AmenitiesFilter: ISourcingToolSearchFilter = {
  id: "amenities-filter",
  field: "sidebar.react.search_sidebar.amenities_title",
  type: "compound",
  must: "MATCH_ALL",
  mustOptions: ["MATCH_ALL"],
  filters: [AmenitiesTypeFilter, AmenitiesDistanceFilter],
  requiredFilters: "all",
  searchTypes: [SearchType.SOURCING_TOOL_UK],
  displayMessages: {
    updateTitle: "sidebar.react.search_sidebar.distance_to_closest_amenity",
  },
};
