import { ChangeEvent, useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-migration/lib/i18n/useTranslation";
import { useSnapshot } from "valtio";
import { SingleRecipient } from "./SingleRecipientValidationSchema";
import {
  LetterEventName,
  logLetterEvent,
  mapToLetterFormStepStringEnum,
} from "../../letters/helpers/logLetterEvent";
import { updateLetterName } from "src/js/stores/letter/actions";
import { EditIcon, TextInput } from "@landtechnologies/components";
import { debounce } from "lodash";
import { letterStore } from "src/js/stores/letter/store";
import { useStageByStageId } from "../../pipeline/store/hooks/useStageByStageId";

type TableTitleProps = {
  recipients: SingleRecipient[] | null;
  selectedRecipientIds: string[] | null;
  selectedStageId: string | null;
};

export const TableTitle = ({
  recipients,
  selectedRecipientIds,
  selectedStageId,
}: TableTitleProps) => {
  const { t } = useTranslation();
  const stage = useStageByStageId(selectedStageId ?? "");
  const { currentStep, letterName } = useSnapshot(letterStore);

  const [letterTitle, setLetterTitle] = useState(letterName || t("sites.letters.untitled_letter"));

  const recipientsHeaderDetails = useMemo(() => {
    const sitesCountInfo =
      stage?._sites?.totalCount &&
      t("sites.letters.recipients.sites", {
        count: stage._sites.totalCount,
      });

    const eligibleSitesCountInfo = t("sites.letters.recipients.eligible_sites", {
      count: recipients?.filter((recipient) => recipient.isGranted).length,
    });

    const recipientsCountInfo = t("sites.letters.recipients.owners", {
      count: recipients?.length,
    });

    const selectedRecipientsInfo = t("sites.letters.recipients.selected", {
      count: selectedRecipientIds?.length,
    });

    return [
      stage && sitesCountInfo,
      stage && eligibleSitesCountInfo,
      recipientsCountInfo,
      selectedRecipientIds?.length && selectedRecipientsInfo,
    ]
      .filter(Boolean) // filtering out falsy values if letter creation is not bulk send
      .join(" \u2022 "); // adding a bullet point between the values
  }, [recipients, selectedRecipientIds, stage, t]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onTitleChange = useCallback(
    debounce(async ({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
      setLetterTitle(value);
      updateLetterName(value);

      logLetterEvent({
        name: LetterEventName.LETTERS_FORM_LETTER_TITLE_UPDATED,
        context: { step: mapToLetterFormStepStringEnum(currentStep) },
      });
    }, 500),
    [letterTitle]
  );

  return (
    <div
      className="atlas-grid atlas-grid-cols-3 atlas-flex-row atlas-p-4"
      data-testid="recipients-table-title"
    >
      <div className="atlas-flex atlas-flex-col atlas-gap-y-1">
        <span className="atlas-flex atlas-text-base atlas-font-semibold">
          {t("sites.letters.recipients")}
        </span>
        <span className="atlas-flex atlas-text-content-secondary">{recipientsHeaderDetails}</span>
      </div>
      <div className="atlas-flex atlas-grow atlas-justify-center atlas-items-center">
        <div className="atlas-min-w-96">
          <TextInput
            dataTestId="letters-header-letter-name-input"
            size="medium"
            placeholder={t("sites.letters.enter_letter_name")}
            IconLeft={<EditIcon />}
            value={letterTitle}
            onChange={onTitleChange}
          />
        </div>
      </div>
    </div>
  );
};
