<template>
  <div class="skip-trace">
    <react-wrapper :component="CreateSkipTracePage" />
  </div>
</template>

<script>
import ReactWrapper from "view/wrappers/react-wrapper";
import { CreateSkipTracePage } from "react-migration/domains/sites/skipTracing/CreateSkipTracePage";

export default {
  components: {
    ReactWrapper,
  },
  data: () => ({}),
  computed: {
    CreateSkipTracePage() {
      return CreateSkipTracePage;
    },
  },
};
</script>

<style lang="scss">
.skip-trace {
  width: 100%;
  height: 100%;
  > div {
    height: 100%;
  }
}
</style>
