import { DisabledTextContentColor, RangeInput, Theme } from "@landtechnologies/components";
import { identity } from "lodash";

import { FC, FocusEvent, KeyboardEvent, ReactNode, useCallback } from "react";

export interface RangeFilterProps {
  disabled?: boolean;
  label?: ReactNode;
  minLimit?: number;
  min?: number;
  setMin: (value: number) => void;
  max?: number;
  maxLimit?: number;
  setMax: (value: number) => void;
  parse?: (value: number) => number;
  format?: (value: number) => number;
  units?: string;
  "data-testid"?: string;
  theme?: Theme;
}

export const RangeFilter: FC<RangeFilterProps> = ({
  disabled,
  label,
  minLimit = 0,
  min,
  setMin,
  max,
  maxLimit = Infinity,
  setMax,
  parse = identity as (x: number) => number,
  format = identity as (x: number) => number,
  units,
  "data-testid": dataTestId,
  theme = Theme.Light,
}) => {
  const dispatchToMin = useCallback(
    (val: number) => {
      const parsed = parse(val);
      setMin(typeof parsed === "number" ? parsed : NaN);
    },
    [setMin, parse]
  );
  const dispatchToMax = useCallback(
    (val: number) => {
      const parsed = parse(val);
      setMax(typeof parsed === "number" ? parsed : NaN);
    },
    [setMax, parse]
  );
  const updateMinFromNonChangeEvent = useCallback(
    (e: FocusEvent<Element> | KeyboardEvent<HTMLInputElement>) => {
      const target = e.target as HTMLInputElement;
      const valueAsNumber = target.value === "" ? NaN : Number(target.value.replaceAll(",", ""));
      if (e.type === "blur" || !isNaN(valueAsNumber)) {
        dispatchToMin(valueAsNumber);
      } else if (e.type === "keyup" && (e as KeyboardEvent).code === "Enter") {
        dispatchToMin(valueAsNumber);
      }
    },
    [dispatchToMin]
  );

  const updateMaxFromNonChangeEvent = useCallback(
    (e: KeyboardEvent<HTMLInputElement> | FocusEvent<Element>) => {
      const target = e.target as HTMLInputElement;
      const valueAsNumber = target.value === "" ? NaN : Number(target.value.replaceAll(",", ""));
      if (e.type === "blur" || !isNaN(valueAsNumber)) {
        dispatchToMax(valueAsNumber);
      } else if (e.type === "keyup" && (e as KeyboardEvent).code === "Enter") {
        dispatchToMax(target.value === "" ? NaN : Number(target.value));
      }
    },
    [dispatchToMax]
  );

  const formattedMinimum = format(min ?? NaN);
  const formattedMaximum = format(max ?? NaN);

  return (
    <div>
      {label ? (
        <h4 className={`atlas-my-2 ${disabled ? DisabledTextContentColor[theme] : ""}`}>
          {" "}
          {label}
        </h4>
      ) : null}
      <div className="atlas-flex atlas-flex-row atlas-items-center">
        <RangeInput
          isDisabled={disabled}
          size="small"
          minValue={minLimit}
          maxValue={maxLimit}
          minDecimals={0}
          maxDecimals={0}
          units={units}
          alwaysShowUnits={!!units}
          startInputOnBlur={updateMinFromNonChangeEvent}
          startInputOnChange={dispatchToMin}
          startInputMaxValue={isNaN(formattedMaximum) ? undefined : formattedMaximum}
          startInputOnKeyUp={updateMinFromNonChangeEvent}
          startInputValue={formattedMinimum}
          startInputPlaceholder={minLimit?.toFixed(0)}
          endInputOnBlur={updateMaxFromNonChangeEvent}
          endInputOnChange={dispatchToMax}
          endInputOnKeyUp={updateMaxFromNonChangeEvent}
          endInputMinValue={isNaN(formattedMinimum) ? undefined : formattedMinimum}
          endInputValue={formattedMaximum}
          endInputPlaceholder={maxLimit === Infinity ? "∞" : maxLimit.toFixed(0)}
          data-testid={dataTestId}
          theme={theme}
        />
      </div>
    </div>
  );
};
