export const ProgressBar = ({ percent }: { percent: number }) => {
  return (
    <div className="atlas-h-2 atlas-w-full atlas-bg-neutral-4 atlas-rounded-full">
      <div
        className="atlas-h-2 atlas-bg-platform-500 atlas-rounded-full"
        style={{ width: `${percent}%` }}
      ></div>
    </div>
  );
};
