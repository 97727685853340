import React from "react";

export const DiscoverIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" height="100%" viewBox="0 0 24 16">
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g fillRule="nonzero" transform="translate(-280 -280)">
          <g transform="translate(40 200)">
            <g transform="translate(0 80)">
              <g transform="translate(240)">
                <path
                  fill="#FFF"
                  stroke="#000"
                  strokeOpacity="0.2"
                  strokeWidth="0.5"
                  d="M21.997 15.75H22c.955.008 1.74-.773 1.751-1.746V2.006a1.789 1.789 0 00-.52-1.25A1.72 1.72 0 0021.997.25H2.001A1.718 1.718 0 00.77.757c-.33.33-.517.779-.521 1.247v11.99c.004.47.191.92.52 1.25.329.328.771.51 1.233.506h19.994zm0 .5h-.002.002z"
                ></path>
                <path
                  fill="#F27712"
                  d="M12.612 16h9.385A1.986 1.986 0 0024 14.03v-2.358A38.74 38.74 0 0112.612 16z"
                ></path>
                <path
                  fill="#000"
                  d="M23.172 9.296h-.852l-.96-1.266h-.091v1.266h-.695V6.152H21.6c.803 0 1.266.33 1.266.927 0 .488-.29.802-.81.902l1.116 1.315zm-1.026-2.193c0-.306-.232-.463-.662-.463h-.215v.952h.199c.446 0 .678-.166.678-.489zm-4.005-.951h1.97v.53h-1.275v.703h1.225v.538h-1.225v.852h1.274v.53h-1.97V6.152zm-2.235 3.227L14.4 6.143h.761l.952 2.119.96-2.119h.745L16.295 9.38h-.389zm-6.298-.008c-1.059 0-1.887-.72-1.887-1.655 0-.91.845-1.647 1.904-1.647.298 0 .546.058.852.19v.729a1.241 1.241 0 00-.869-.356c-.662 0-1.167.48-1.167 1.084 0 .637.497 1.092 1.2 1.092.315 0 .555-.1.836-.347v.728a2.13 2.13 0 01-.869.182zM7.506 8.336c0 .613-.505 1.035-1.233 1.035-.53 0-.91-.182-1.233-.596l.455-.389c.157.282.422.422.753.422.315 0 .538-.19.538-.438 0-.141-.066-.249-.207-.331a2.88 2.88 0 00-.48-.183c-.653-.206-.877-.43-.877-.868 0-.514.48-.903 1.109-.903.397 0 .753.125 1.051.356l-.364.414a.761.761 0 00-.563-.248c-.298 0-.513.149-.513.347 0 .166.124.257.538.398.794.248 1.026.48 1.026.993v-.009zM4.088 6.152h.695v3.153h-.695V6.152zM1.854 9.305H.828V6.152h1.026c1.125 0 1.903.645 1.903 1.572 0 .472-.231.919-.637 1.217-.348.248-.737.364-1.274.364h.008zm.81-2.367c-.23-.182-.496-.248-.95-.248h-.191v2.085h.19c.447 0 .728-.083.952-.248.24-.199.38-.497.38-.803 0-.306-.14-.596-.38-.786z"
                ></path>
                <path
                  fill="#F27712"
                  d="M12.414 6.069c-.91 0-1.655.728-1.655 1.63 0 .96.711 1.68 1.655 1.68a1.64 1.64 0 001.655-1.655c0-.927-.72-1.655-1.655-1.655z"
                ></path>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
