import { isDefined } from "react-migration/lib/util/isDefined";
import { mapStore } from "src/js/stores/map/store";
import { useSnapshot } from "valtio";
import { Layer } from "../types";
import { useMemo } from "react";

export enum LayerZoomLimit {
  OVER_LIMIT = "OVER_LIMIT",
  UNDER_LIMIT = "UNDER_LIMIT",
}

function checkVisualisationZoom({ zoomConfig }: Layer, currentZoom: number) {
  if (!zoomConfig) return null;
  const { maxVisualisationZoom, minVisualisationZoom } = zoomConfig;

  if (isDefined(maxVisualisationZoom) && currentZoom > maxVisualisationZoom) {
    return LayerZoomLimit.OVER_LIMIT;
  } else if (isDefined(minVisualisationZoom) && currentZoom < minVisualisationZoom) {
    return LayerZoomLimit.UNDER_LIMIT;
  } else {
    return null;
  }
}

function checkConsiderationZoom({ zoomConfig }: Layer, currentZoom: number) {
  if (!zoomConfig) return null;
  const { maxConsiderationZoom, minConsiderationZoom } = zoomConfig;

  if (isDefined(maxConsiderationZoom) && currentZoom > maxConsiderationZoom) {
    return LayerZoomLimit.OVER_LIMIT;
  } else if (isDefined(minConsiderationZoom) && currentZoom < minConsiderationZoom) {
    return LayerZoomLimit.UNDER_LIMIT;
  } else {
    return null;
  }
}

function checkLayerZoomLimits(currentZoom: number, layer: Layer) {
  return {
    visualisation: checkVisualisationZoom(layer, currentZoom),
    consideration: checkConsiderationZoom(layer, currentZoom),
  };
}

function useCurrentZoom() {
  const { zoom } = useSnapshot(mapStore.settings);

  // Note: zoom is 1 off - Google vs Deck.gl & MVT
  return zoom - 1;
}

export function useLayerZoomLimit(layer: Layer) {
  const currentZoom = useCurrentZoom();

  return useMemo(() => checkLayerZoomLimits(currentZoom, layer), [currentZoom, layer]);
}

export function useLayerZoomLimitLookup(layers: Layer[]) {
  const currentZoom = useCurrentZoom();

  return useMemo(
    () =>
      Object.fromEntries(
        layers.map((layer) => [layer.id, checkLayerZoomLimits(currentZoom, layer)] as const)
      ),
    [currentZoom, layers]
  );
}
