import { cloneDeep, omit } from "lodash";

function sanitiseArrayInput<T>(source: Array<T>) {
  const sourceClone = cloneDeep(omit(source, "__ob__"));
  return Object.values(sourceClone).map((value) => {
    return sanitiseVueObservableUntyped(value);
  });
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function isObject(variable: any) {
  return typeof variable === "object" && !Array.isArray(variable) && variable !== null;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function sanitiseObject(obj: any) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const objectClone: Record<string, any> = {};

  // Observable objects use getters and setters, which aren't visible to regular object mapping
  Object.getOwnPropertyNames(obj).forEach((key) => {
    if (key !== "__ob__") {
      objectClone[key] = sanitiseVueObservableUntyped(obj[key]);
    }
  });

  return objectClone;
}

// Ideally we'd do this in a totally type generic way
// However, it's hacky workaround for having to pass Vue observables into React through the react wrapper
// So don't want to waste too much time on typing here.
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const sanitiseVueObservableUntyped = (observableObject: unknown): any => {
  if (isObject(observableObject)) {
    return sanitiseObject(observableObject);
  } else if (Array.isArray(observableObject)) {
    return sanitiseArrayInput(observableObject);
  } else {
    return observableObject;
  }
};

// Set the return type to the input type - note the underlying function isn't type safe
/**
 * Recursively removes the __ob__ property from Vue Observables. This prevents things like Valtio getting into
 * an infinite recursive loop when trying to apply a Proxy to the observable.
 */
export const sanitiseVueObservable = <T>(obj: T): T => {
  return sanitiseVueObservableUntyped(obj);
};
