type ChartTooltipProps = {
  formattedValue: string;
  color?: string;
};
export function ChartTooltip({ formattedValue, color }: ChartTooltipProps) {
  return (
    <div className="atlas-bg-neutral-700 atlas-shadow-md atlas-rounded atlas-flex atlas-gap-x-2 atlas-items-center atlas-px-3 atlas-py-2">
      {color && (
        <div
          className="atlas-w-3 atlas-h-3 atlas-rounded-sm atlas-shrink-0"
          style={{ backgroundColor: color }}
        />
      )}
      <div className=" atlas-text-white atlas-text-xs atlas-text-left">{formattedValue}</div>
    </div>
  );
}
