import { Button, Loading } from "@landtechnologies/components";
import { useState, useEffect, useMemo } from "react";
import { useTranslation } from "react-migration/lib/i18n/useTranslation";
import { updateSelectedRecipientIds, updateCurrentStep } from "src/js/stores/letter/actions";
import { LetterStep, Letter } from "src/js/stores/letter/store";
import { logLetterEvent, LetterEventName } from "./helpers/logLetterEvent";
import { useFormContext } from "react-hook-form";
import { useLoadLetterTemplate } from "../hooks/useLoadLetterTemplate/useLoadLetterTemplate";
import classNames from "classnames";
import { useUpdateLetter } from "../hooks/useUpdateLetter";
import hasFeature from "src/js/stores/user/actions/hasFeature";
import Feature from "src/js/stores/user/Feature";
import { Header } from "./components/Header";
import { TemplateEditor } from "./components/TemplateEditor";
import { Footer } from "./components/Footer";
import { TemplateFormProvider } from "../forms/TemplateForm/TemplateFormProvider";
import { TemplateFormData } from "../forms/TemplateForm/TemplateFormDataSchema";

export const TemplateEditorStepContent = () => {
  const { t } = useTranslation();
  const [isNextBlocked, setIsNextBlocked] = useState(false);
  const {
    trigger,
    watch,
    formState: { isValid },
  } = useFormContext<TemplateFormData>();

  const isPhysicalSendAvailable = hasFeature(Feature.physicalSend);

  const { updateLetter } = useUpdateLetter();

  useEffect(() => {
    watch((value) => {
      updateLetter(value as Letter);
    });
  }, [watch, updateLetter]);

  const onBackClick = () => {
    updateCurrentStep(LetterStep.SelectRecipients);
    updateSelectedRecipientIds([]);
    logLetterEvent({
      name: LetterEventName.LETTERS_FORM_CREATE_LETTER_STEP_BACK_BUTTON_CLICKED,
    });
  };
  const onNextClick = () => {
    if (!isValid) {
      trigger();
    } else {
      updateCurrentStep(LetterStep.PreviewLetter);
      logLetterEvent({
        name: LetterEventName.LETTERS_FORM_CREATE_LETTER_STEP_PREVIEW_LETTER_BUTTON_CLICKED,
      });
    }
  };

  const percentage = useMemo(() => (isPhysicalSendAvailable ? 25 : 33), [isPhysicalSendAvailable]);

  return (
    <div
      data-testid="letters-template-editor-step"
      className="atlas-h-full atlas-w-full atlas-flex atlas-flex-col"
    >
      <Header />
      <TemplateEditor onBlockNext={setIsNextBlocked} />
      <Footer percentage={percentage}>
        <div className="atlas-flex atlas-flex-grow atlas-justify-between">
          <Button.Secondary onClick={onBackClick}>{t("sites.letters.back")}</Button.Secondary>
          <Button.Primary onClick={onNextClick} disabled={isNextBlocked}>
            {t("sites.letters.next_preview_letter")}
          </Button.Primary>
        </div>
      </Footer>
    </div>
  );
};

export const TemplateEditorStep = () => {
  const { letter, loading } = useLoadLetterTemplate();

  return (
    <>
      {loading && (
        <div
          className={classNames(
            "atlas-h-full",
            "atlas-w-full",
            "atlas-flex",
            "atlas-justify-center",
            "atlas-items-center",
            "atlas-overflow-hidden"
          )}
        >
          <Loading />
        </div>
      )}
      {!loading && (
        <TemplateFormProvider data={letter as TemplateFormData}>
          <TemplateEditorStepContent />
        </TemplateFormProvider>
      )}
    </>
  );
};
