import classNames from "classnames";
import { useTranslation } from "react-migration/lib/i18n/useTranslation";

export const SkipTraceOrderPlacedPage = () => {
  const { t } = useTranslation();
  return (
    <div
      className={classNames(
        "atlas-bg-background-light",
        "atlas-flex",
        "atlas-flex-col",
        "atlas-gap-y-5",
        "atlas-items-center",
        "atlas-justify-center",
        "atlas-h-full",
        "atlas-w-full"
      )}
      data-testid="skip-tracing-order-placed"
    >
      <div
        className={classNames(
          "atlas-flex",
          "atlas-flex-col",
          "atlas-gap-y-5",
          "atlas-text-center",
          "atlas-items-center",
          "atlas-justify-center",
          "atlas-w-[330px]"
        )}
      >
        <i
          className={classNames(
            "icon-lt-clock-line",
            "atlas-text-8xl",
            "atlas-text-content-primary"
          )}
          data-testid="clock-icon"
        />
        <span className="atlas-text-xl atlas-font-semibold">
          {t("sites.skip_tracing.order_placed.order_created")}
        </span>
        <span className="atlas-text-md">
          {t("sites.skip_tracing.order_placed.first_message_line")}
        </span>
        <span className="atlas-text-md">
          {t("sites.skip_tracing.order_placed.second_message_line")}
        </span>
      </div>
    </div>
  );
};
