import { router } from "src/js/router/react-router";
import ROUTES from "src/js/router/routes";
import { getParamFromQueryParams } from "./helpers";
import { PlanningAlert } from "../../typings/PlanningAlert";

export const handleRedirectToAlerts = (planningAlert: PlanningAlert) => {
  type ParamType = Parameters<typeof router.push>;
  const email = getParamFromQueryParams("email");
  const params: ParamType[0] = {
    name: ROUTES.DASHBOARD_PLANNING_ALERTS,
    query: {
      tab: planningAlert.search._site ? "saved-sites-alerts" : "planning-search-alerts",
    },
  };
  if (email) params.query = { ...params.query, email };

  router.push(params);
};
