import { CompositeLayer, GetPickingInfoParams } from "@deck.gl/core";
import {
  DesignationLayer,
  DesignationLayerProps,
} from "react-migration/domains/constraints/components/ConstraintLayer/DesignationLayer";
import {
  ConstraintsTileOptions,
  buildConstraintsTileUrl,
} from "react-migration/domains/constraints/components/ConstraintLayer/ConstraintLayer";
import { SubCategoryCondition } from "./useDemographicSubCategory";
import { isDefined } from "react-migration/lib/util/isDefined";

type CompositeDesignationLayerProps = {
  categoryConditions: SubCategoryCondition[];
  tileOptions: Omit<ConstraintsTileOptions, "rootCategory">;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  pickInfoMeta?: any;
} & DesignationLayerProps;

type FilterSubLayerProps = Parameters<typeof CompositeLayer.prototype.filterSubLayer>[0];

export class CompositeDesignationLayer extends CompositeLayer<CompositeDesignationLayerProps> {
  renderLayers() {
    return this.props.categoryConditions
      .filter(({ categoryId }) => isDefined(categoryId))
      .map(({ categoryId }) => {
        const url = buildConstraintsTileUrl({
          ...this.props.tileOptions,
          rootCategory: categoryId!,
        });

        return new DesignationLayer(
          this.getSubLayerProps({
            ...this.props,
            data: url,
            id: categoryId!,
          })
        );
      });
  }

  getPickingInfo(pickingInfoParams: GetPickingInfoParams) {
    const info = super.getPickingInfo(pickingInfoParams);
    const { pickInfoMeta } = this.props;

    if (pickInfoMeta) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (info as any).meta = pickInfoMeta;
    }

    return info;
  }

  filterSubLayer({ layer, viewport }: FilterSubLayerProps) {
    const condition = this.props.categoryConditions.find(
      ({ maxZoom = Infinity }) => Math.round(viewport.zoom) < maxZoom
    );

    return !!condition?.categoryId && layer.id.endsWith(condition.categoryId);
  }
}
