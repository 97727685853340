import { useRef } from "react";
import { useTranslation } from "react-migration/lib/i18n/useTranslation";

function useAttributeTranslation(capitals = false, category?: string) {
  const { t, checkKey } = useTranslation();
  const cat = useRef(category);
  const caps = useRef(capitals);

  function capitalise(str: string, minlng = 2): string {
    return str.length >= minlng ? str.charAt(0).toUpperCase() + str.slice(1).toLowerCase() : str;
  }

  const formatKey = (key: string): string => {
    let sentence = capitalise(key);

    if (caps.current) {
      sentence = key.split("_").map(capitalise).join(" ");
    }

    sentence = sentence.replace(/_/g, " ");
    return sentence;
  };

  const translate = (input: string): string => {
    const key = `constraints${cat.current ? "." + cat.current : ""}.attributes.${input}`;
    return checkKey(key) ? t(key) : formatKey(input);
  };

  return translate;
}

export { useAttributeTranslation };
