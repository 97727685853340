import { SearchType } from "react-migration/domains/search/constants/searchTypes";
import { ISourcingToolSearchFilter } from "react-migration/domains/search/typings/SourcingToolSpecificFilterTypes";

export const PropertyTypeFilter: ISourcingToolSearchFilter = {
  id: "property-type-id",
  type: "checkbox",
  field: "sidebar.react.search_sidebar.property_type",
  must: "MATCH_ANY",
  mustOptions: ["MATCH_ANY", "MATCH_NONE"],
  mustIsConfigurable: false,
  updateFilterDialogIsVisible: false,
  searchTypes: [SearchType.SOURCING_TOOL_US_FL],
  values: [
    {
      key: "VACANT LAND",
      name: "Vacant land",
    },
    {
      key: "NON-VACANT LAND",
      name: "Non-vacant land",
    },
  ],
};
