import { Divider, SkeletonLoading } from "@landtechnologies/components";
import { useTranslation } from "react-migration/lib/i18n/useTranslation";
import { logEvent } from "react-migration/lib/util/logEvent";
import { ZooplaRecordItem } from "../ZooplaRecordItem";
import { ReactNode } from "react";
import { RecordItemUnavailable } from "../RecordItemUnavailable";
import hasFeature from "src/js/stores/user/actions/hasFeature";
import Feature from "src/js/stores/user/Feature";
import { ZooplaListingsFromUPRN } from "../ZooplaListings/ZooplaListingsFromUPRN";
import { ZooplaRecordSubFields } from "./ZooplaRecordSubFields";
import { RecordType } from "react-migration/domains/comparables/typings/Record";
import { ZooplaComparableDTO } from "@landtechnologies/comparables-services";
import { ZooplaField, ZooplaSubField } from "react-migration/domains/comparables/typings/Zoopla";
import { HomeTrackLogoLarge } from "react-migration/domains/comparables/img/HomeTrackLogoLarge";
import { ZooplaLogoLarge } from "react-migration/domains/comparables/img/ZooplaLogoLarge";

export interface ZooplaSectionProps {
  record: RecordType | ZooplaComparableDTO;
  data?: ZooplaField[];
  loading?: boolean;
  showDivider?: boolean;
}

function getValueAndLogMissingField(
  field: ZooplaSubField,
  parentField: ZooplaField
): string | number | ReactNode;
function getValueAndLogMissingField(
  field: ZooplaField,
  parentField?: never
): string | number | ReactNode;
function getValueAndLogMissingField(
  field: ZooplaField | ZooplaSubField,
  parentField?: ZooplaField
): string | number | ReactNode {
  if (field.value == null) {
    logEvent("Comparables Hometrack Data Missing", {
      field: `${parentField?.title ?? ""}${parentField ? "." : ""}${field.title}`,
      value: "missing",
    });

    return parentField ? NotAvailableShort : NotAvailable;
  }

  return field.value as string | number;
}

export { getValueAndLogMissingField };

function DataNotAvailableFromSource({ translationCode }: { translationCode: string }) {
  const { t } = useTranslation();
  return (
    <span className="atlas-text-neutral-400 atlas-text-sm atlas-font-normal">
      {t(translationCode)}
    </span>
  );
}

export const NotAvailable = (
  <DataNotAvailableFromSource translationCode="comparables.map.sidebar.value.record.zoopla.source_data_not_available" />
);
const NotAvailableShort = (
  <DataNotAvailableFromSource translationCode="comparables.map.sidebar.value.record.zoopla.source_data_not_available_short" />
);

/** Handles checking for presence of zoopla license and returns null if user does not have access */
export const ZooplaSection = ({
  data,
  loading,
  record,
  showDivider = true,
}: ZooplaSectionProps) => {
  const { t } = useTranslation();

  if (!hasFeature(Feature.zooplaFullAccess)) {
    return null;
  }

  if (!loading && !data?.length) {
    return <RecordItemUnavailable source="zoopla" />;
  }
  return (
    <>
      {showDivider && <Divider />}

      {loading ? (
        <div className="atlas-mt-6">
          <SkeletonLoading rows={7} />
        </div>
      ) : (
        <>
          <ZooplaListingsFromUPRN uprn={record.uprn} className="atlas-px-3 atlas-pt-2" />

          <div data-testid="zoopla-section">
            {data?.map((field: ZooplaField) => {
              return (
                <ZooplaRecordItem
                  title={field.title}
                  key={field.title}
                  tooltipContent={field.tooltipContent}
                >
                  {Array.isArray(field.value) ? (
                    <ZooplaRecordSubFields subfields={field.value} parentField={field} />
                  ) : (
                    <>{getValueAndLogMissingField(field)}</>
                  )}
                </ZooplaRecordItem>
              );
            })}
          </div>
        </>
      )}
      <div className="atlas-px-3 atlas-py-2 atlas-text-xs md:atlas-text-sm atlas-gap-5 atlas-bg-white atlas-text-neutral-400 atlas-flex">
        <div>
          {t("comparables.map.sidebar.value.record.zoopla.source")}{" "}
          <HomeTrackLogoLarge height={12} /> {t("comparables.map.sidebar.value.record.zoopla.by")}{" "}
          <ZooplaLogoLarge />
        </div>
      </div>
      {showDivider && <Divider />}
    </>
  );
};
