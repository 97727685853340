import { ISearchFilter } from "react-migration/domains/search/typings/Filters";

/**
 *
 * @param filters a list of filters to encode. Whilst this is defined as a partial an id is necessary for the setting code to be able to apply any of the other fields in to the search state
 * @returns
 */
export function encodeFiltersForQueryString(filters: Partial<ISearchFilter>[]) {
  return btoa(JSON.stringify(filters));
}
