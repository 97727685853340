import { ScatterplotLayer, ScatterplotLayerProps } from "@deck.gl/layers";
import type { Color } from "deck.gl";
import { MaskExtension } from "@deck.gl/extensions";
import Feature from "src/js/stores/user/Feature";
import { getPosition } from "./comparablesLayerUtil";
import { isZoopla } from "react-migration/domains/comparables/util/isZoopla";
import getPermissions from "src/js/stores/user/actions/getPermissions";
import hasBetaFeature from "src/js/stores/user/actions/hasBetaFeature";
import { ZooplaPriceModes } from "react-migration/domains/comparables/typings/PriceModes";
import { AggregatedZooplaComparableTile } from "react-migration/domains/comparables/typings/AggregatedZooplaComparableTile";

const FILL_COLOR: Color = [126, 34, 206, 255];
const LINE_COLOR: Color = [255, 255, 255, 255];
export function zooplaPricePerUnitLayers(
  priceMode: ZooplaPriceModes,
  data: AggregatedZooplaComparableTile[],
  getSubLayerProps: (i: number) => ScatterplotLayerProps<AggregatedZooplaComparableTile>
) {
  const geofenceGeometries = getPermissions()?.geofencesGeometries;
  const maskExtensions = [] as MaskExtension[];
  let maskId: string | undefined;

  if (geofenceGeometries?.length && !hasBetaFeature(Feature.disableGeofence)) {
    maskExtensions.push(new MaskExtension());
    maskId = "Geofence";
  }
  // const visible = /price_per/.test(priceMode) === false;
  const visible = isZoopla(priceMode);
  return new ScatterplotLayer<AggregatedZooplaComparableTile>({
    ...getSubLayerProps(-1),
    extensions: maskExtensions,
    // @ts-expect-error deck.gl types are not up-to-date
    maskId,
    pickable: true,
    data,
    opacity: 0.8,
    stroked: true,
    filled: true,
    visible,
    lineWidthUnits: "pixels",
    lineWidthMaxPixels: 1,
    lineWidthMinPixels: 1,
    lineWidthScale: 1,
    radiusUnits: "pixels",
    radiusScale: 1,
    radiusMinPixels: 1,
    radiusMaxPixels: 12,
    // getPosition: locationGetters[priceMode],
    getPosition,
    getRadius: 12,
    getFillColor: FILL_COLOR,
    getLineColor: LINE_COLOR,
    getLineWidth: 0,
    updateTriggers: {
      visible,
    },
  });
}
