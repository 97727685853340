/* eslint-disable @typescript-eslint/no-explicit-any */
// eslint-disable-next-line @typescript-eslint/ban-types
export function createWeakMemo<T extends Function>(fn: T): T {
  if (typeof fn !== "function") {
    throw new TypeError("fn must be a function");
  }
  const memos = new WeakMap();

  function getPreviousValue(...args: any[]): T | undefined {
    return args.reduce((acc, arg) => acc?.get(arg), memos);
  }

  function setValue(...args: any[]) {
    const value = fn(...args);
    return args.reduce((acc, arg, index, { length }) => {
      if (index < length - 1) {
        return acc.set(arg, acc.get(arg) || new WeakMap()).get(arg);
      }

      return acc.set(arg, value).get(arg);
    }, memos);
  }

  // @ts-expect-error shh - typing variadic memoised functions is hard. Caveat emptor
  return (...args: any[]): T => {
    return getPreviousValue(...args) || setValue(...args);
  };
}

export function groupByAllMemoized<T>(
  groupByAccessors: Array<(x: T) => string>
): (data: T[] | Record<string, T>) => Record<string, T[]> {
  const resultsMap = new WeakMap();

  return function groupByAllTheThings(items) {
    const prev = resultsMap.get(items);

    if (prev) {
      return prev;
    }

    resultsMap.set(
      items,
      (Array.isArray(items) ? items : Object.values(items)).reduce<Record<string, T[]>>(function (
        acc,
        item
      ) {
        return groupByAccessors.reduce<Record<string, T[]>>(function (acc2, accessor) {
          const id = accessor(item);

          acc2[id] = acc2[id] || [];
          acc2[id].push(item);
          return acc2;
        }, acc);
      },
      {})
    );

    return resultsMap.get(items) || {};
  };
}

export function memoizeOnce<T>(func: (...args: any[]) => T): (...args: any[]) => T {
  let lastArgs: any[] = [];
  let lastResult: T;

  return function (...args) {
    if (args.length !== lastArgs.length || args.some((arg, i) => arg !== lastArgs[i])) {
      lastResult = func(...args);
    }

    lastArgs = args;
    return lastResult;
  };
}
