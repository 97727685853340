export function PointSelectionHeader() {
  return (
    <div className="atlas-flex atlas-items-center">
      <i className="icon-lt-map-pin atlas-text-xl atlas-mr-2 -atlas-mt-1 atlas-text-neutral-600" />
      <div className="atlas-flex-col">
        <span className="atlas-text-content-tertiary atlas-font-semibold atlas-text-xs atlas-overflow-hidden atlas-overflow-ellipsis atlas-whitespace-pre-line atlas-max-h-16 atlas-line-clamp-3 atlas-truncate">
          POINT
        </span>
      </div>
    </div>
  );
}
