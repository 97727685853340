import { PROPRIETOR_GROUPS } from "react-migration/domains/ownership/data";
import type { BaseMapType } from "src/js/stores/map/store";
import type { Color } from "@deck.gl/core";

export const TRANSPARENT: Color = [0, 0, 0, 0];

export const HOVER_HIGHLIGHT_COLOR_BY_MAP_TYPE: Record<BaseMapType, number[]> = {
  greyscale: [32, 42, 55, 33],
  "greyscale-terrain": [32, 42, 55, 33],
  hybrid: [32, 42, 55, 73],
  "os-default": [32, 42, 55, 33],
  roadmap: [32, 42, 55, 33],
  terrain: [32, 42, 55, 33],
  satellite: [32, 42, 55, 73],
  "usgs-topo": [32, 42, 55, 33],
};
// Title outline colors
export const SATELLITE_LINE_COLOR: Color = [255, 255, 255, 255];
export const STREETMAP_LINE_COLOR: Color = [96, 162, 207, 255];

// Selected title colors
export const SELECTED_TITLE_FILL_COLOR: Color = [0, 235, 151, 255 * 0.1];
export const SELECTED_TITLE_LINE_COLOR: Color = [0, 235, 135, 255];

// Known freeholder colors
export const PRIVATE_PROPRIETOR: Color = [0, 0, 0, 0];
export const COMPANY_PROPRIETOR: Color = [252, 75, 101, 255];
export const CORPORATE_BODY_PROPRIETOR: Color = [251, 226, 180, 255];
export const HOUSING_ASSOCIATION_PROPRIETOR: Color = [253, 195, 60, 255];
export const LOCAL_AUTHORITY_PROPRIETOR: Color = [89, 196, 197, 255];

export const SOURCING_TOOL_FILTER_COLOR: Color = [34, 197, 95, 255 * 0.2];
export const SOURCING_TOOL_LINE_COLOR: Color = [170, 255, 195, 255];
export const SOURCING_TOOL_SELECTED_POLYGON_FILL_COLOR: Color = [34, 197, 95, 255 * 0.8];
export const SOURCING_TOOL_CLUSTER_POLYGON_FILL_COLOR: Color = [249, 115, 22, 255 * 0.4];
export const SOURCING_TOOL_SELECTED_CLUSTER_POLYGON_FILL_COLOR: Color =
  SOURCING_TOOL_SELECTED_POLYGON_FILL_COLOR;

export const PROPRIETOR_COLOR_MAP: Record<string, Color> = Object.fromEntries([
  ["Private", PRIVATE_PROPRIETOR],
  ["Company", COMPANY_PROPRIETOR],
  ["Corporate Body", CORPORATE_BODY_PROPRIETOR],
  ["Housing Association", HOUSING_ASSOCIATION_PROPRIETOR],
  ["Council", LOCAL_AUTHORITY_PROPRIETOR],
  // the below are deprecated
  ...PROPRIETOR_GROUPS["Housing Association"].map((x) => [x, HOUSING_ASSOCIATION_PROPRIETOR]),
  ...PROPRIETOR_GROUPS["Company"].map((x) => [x, COMPANY_PROPRIETOR]),
  ...PROPRIETOR_GROUPS["Corporate Body"].map((x) => [x, CORPORATE_BODY_PROPRIETOR]),
  ...PROPRIETOR_GROUPS["Housing Association"].map((x) => [x, HOUSING_ASSOCIATION_PROPRIETOR]),
  ...PROPRIETOR_GROUPS["Local/county authority"].map((x) => [x, LOCAL_AUTHORITY_PROPRIETOR]),
]);
