import { proxy } from "valtio/vanilla";

export type PlanningStore = {
  selectedGssCodes: string[];
};

export const getInitialPlanningStore = () =>
  ({
    selectedGssCodes: [],
  } as PlanningStore);

export const planningStore = proxy<PlanningStore>(getInitialPlanningStore());
