import { SingleDesignation } from "react-migration/domains/constraints/typings/applicationTypes/SingleDesignation";
import { OutputAreaAttribute } from "../../types";
import { useMemo } from "react";
import { getAttributeNumericValue } from "../../../utils";
import { ResponsiveBar } from "@nivo/bar";
import { formatPercent } from "../../utils";
import { ChartTooltip } from "../ChartTooltip";
import { Legend } from "../Legend";
import { useArrayToggle } from "react-migration/lib/util/useArrayToggle";
import { Color } from "d3-color";

const BAR_HEIGHT = 17;
const BOTTOM_MARGIN = 20;
const TOP_MARGIN = 10;
const BAR_MARGIN = 2.5;
const MAX_LABEL_LENGTH = 20;
const LABEL_COLOR = "#6d7482";
const LABEL_FONT_SIZE = 11;

interface HorizontalBarChartProps {
  designation: SingleDesignation;
  comparativeDesignation?: SingleDesignation;
  attributes: OutputAreaAttribute[];
  colorAccessor: (id: string) => Color;
}

export function HorizontalBarChart({
  designation,
  comparativeDesignation,
  attributes,
  colorAccessor,
}: HorizontalBarChartProps) {
  const [hiddenSeries, toggleSeriesById] = useArrayToggle();

  const valueFormat = attributes.find((x) => !!x.format)?.format || formatPercent;

  const chartHeight = TOP_MARGIN + (BAR_HEIGHT + BAR_MARGIN) * attributes.length + BOTTOM_MARGIN;
  const marginLeft =
    Math.min(MAX_LABEL_LENGTH, Math.max(...attributes.map((a) => a.label.length))) * 6 + 15;

  const { keys, data, legend } = useMemo(() => {
    if (!designation || !comparativeDesignation) return { keys: [], data: [] };

    return {
      keys: [designation.id, comparativeDesignation.id],
      data: attributes.map(({ attributeKey, label }) => ({
        attribute: label,
        [designation.id]: getAttributeNumericValue(
          designation.designation_attributes,
          attributeKey
        )!,
        [comparativeDesignation.id]: getAttributeNumericValue(
          comparativeDesignation.designation_attributes,
          attributeKey
        )!,
      })),
      legend: [
        {
          id: designation.id,
          display_name: designation.display_name!,
          color: colorAccessor(designation.id),
        },
        {
          id: comparativeDesignation.id,
          display_name: comparativeDesignation.display_name!,
          color: colorAccessor(comparativeDesignation.id),
        },
      ],
    };
  }, [attributes, colorAccessor, comparativeDesignation, designation]);

  const filteredData = useMemo(
    () =>
      data.map((attributeRecord) => ({
        ...Object.fromEntries(
          Object.entries(attributeRecord).filter(([key]) => !hiddenSeries.includes(key))
        ),
      })),
    [data, hiddenSeries]
  );

  return (
    <div className="atlas-flex atlas-flex-col atlas-gap-4">
      <div
        style={{
          height: `${chartHeight}px`,
        }}
      >
        <ResponsiveBar
          data={filteredData}
          keys={keys}
          indexBy="attribute"
          margin={{ bottom: BOTTOM_MARGIN, left: marginLeft }}
          padding={0.2}
          groupMode="grouped"
          layout="horizontal"
          valueScale={{ type: "linear" }}
          indexScale={{ type: "band", round: true }}
          colors={({ id }) => colorAccessor(id.toString()).toString()}
          axisTop={null}
          valueFormat={valueFormat}
          axisRight={null}
          isInteractive={true}
          tooltip={ChartTooltip}
          axisBottom={{
            tickSize: 0,
            tickPadding: 5,
            tickRotation: 0,
            legend: "",
            legendPosition: "middle",
            legendOffset: 32,
            truncateTickAt: 0,
            format: valueFormat,
            tickValues: 6,
          }}
          axisLeft={{
            tickSize: 0,
            tickPadding: 10,
            tickRotation: 0,
            legendOffset: -50,
            renderTick: ({ opacity, textAnchor, textBaseline, textX, textY, value, x, y }) => {
              return (
                <g transform={`translate(${x},${y})`} style={{ opacity }}>
                  <text
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    alignmentBaseline={textBaseline as any}
                    style={{ fill: "#6d7482", fontSize: LABEL_FONT_SIZE }}
                    className="axisLegend"
                    textAnchor={textAnchor}
                    transform={`translate(${textX},${textY})`}
                  >
                    <tspan>
                      {value.length > MAX_LABEL_LENGTH
                        ? value.substring(0, MAX_LABEL_LENGTH - 2) + "..."
                        : value}
                      <title>{value}</title>
                    </tspan>
                  </text>
                </g>
              );
            },
          }}
          theme={{
            axis: {
              ticks: { text: { fill: LABEL_COLOR, fontSize: LABEL_FONT_SIZE } },
              domain: {
                line: {
                  stroke: "#e5e7eb",
                  strokeWidth: 2,
                },
              },
            },
          }}
          enableGridY={false}
          enableLabel={false}
          animate
        />
      </div>

      {legend && (
        <Legend
          paddingLeft={marginLeft}
          series={legend}
          hiddenSeries={hiddenSeries}
          toggleSeriesById={toggleSeriesById}
        />
      )}
    </div>
  );
}
