import { ISourcingToolSearchFilter } from "react-migration/domains/search/typings/SourcingToolSpecificFilterTypes";
import { SearchType } from "react-migration/domains/search/constants/searchTypes";

export const PropertyStateFilter: ISourcingToolSearchFilter = {
  id: "property-state-id",
  type: "checkbox",
  field: "sidebar.react.search_sidebar.property_state",
  must: "MATCH_ANY",
  mustOptions: ["MATCH_ANY", "MATCH_NONE"],
  updateFilterDialogIsVisible: false,
  searchTypes: [SearchType.SOURCING_TOOL_UK],
  values: [
    {
      key: "under_construction",
      name: "Under Construction",
    },
    {
      key: "in_use",
      name: "In Use",
    },
    {
      key: "unoccupied_vacant_derelict",
      name: "Unoccupied/Vacant/Derelict",
    },
    {
      key: "demolished",
      name: "Demolished",
    },
    {
      key: "planning_permission_granted",
      name: "Planning Permission Granted",
    },
    {
      key: "property_state",
      name: "Unclassified",
    },
  ],
};
