// This isn't exactly a component, but it should be
import toastr from "toastr";

toastr.options = {
  closeButton: false,
  debug: false,
  newestOnTop: false,
  progressBar: true,
  positionClass: "toast-bottom-right",
  preventDuplicates: false,
  onclick: null,
  showDuration: "300",
  hideDuration: "1000",
  timeOut: "5000",
  extendedTimeOut: "1000",
  showEasing: "swing",
  hideEasing: "linear",
  showMethod: "fadeIn",
  hideMethod: "fadeOut",
};

export default {
  failMessage: function (message) {
    toastr.error(message, "");
  },

  successMessage: function (message) {
    toastr.success(message, "");
  },
};
