import { useTranslation } from "react-migration/lib/i18n/useTranslation";
import classNames from "classnames";
import hasFeature from "src/js/stores/user/actions/hasFeature";
import Feature from "src/js/stores/user/Feature";
import { letterStore } from "src/js/stores/letter/store";
import { useSnapshot } from "valtio";

const SenderPropertyValues = ({ values }: { values: string[] }) => {
  return (
    <>
      <br />
      {values.filter((value) => value).join(", ")}
    </>
  );
};

export const Footer = () => {
  const { t } = useTranslation();

  const usAccess = hasFeature(Feature.usAccess);

  const {
    letter: {
      vatRegistrationNumber,
      companyRegistrationNumber,
      senderAddressLine1,
      senderCity,
      senderName,
      senderPostcode,
      senderProvince,
    },
  } = useSnapshot(letterStore);

  if (usAccess) {
    return null;
  }

  return (
    <div
      data-testid={"footer"}
      className={classNames("atlas-absolute", "atlas-bottom-8")}
      // Adding a custom width to accommodate the padding of 22mm each side of the parent
      // Annoyingly custom width values using the `atlas-w-[50px]` syntax doesn't accept mm
      style={{ width: "166mm" }}
    >
      {vatRegistrationNumber && (
        <span className="atlas-mr-4">{`${t(
          "sites.letters.preview.footer.vat_registration_number_display"
        )} ${vatRegistrationNumber}`}</span>
      )}

      {companyRegistrationNumber && (
        <span className="atlas-mr-4">{`${t(
          "sites.letters.preview.footer.company_registration_number_display"
        )} ${companyRegistrationNumber}`}</span>
      )}

      <SenderPropertyValues
        values={[senderName, senderAddressLine1, senderCity, senderProvince, senderPostcode]}
      />
    </div>
  );
};
