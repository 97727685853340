import { useEffect, useMemo, useReducer } from "react";
import type { Reducer, ReducerAction, ReducerState } from "react";
import { merge, omit } from "lodash";
import hashObject from "object-hash";

interface Config {
  omit?: string[];
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type AnyReducer = Reducer<any, any>;

function withLocalStorageReducer<R extends AnyReducer>(
  localStorageKey: string,
  reducer: R,
  config?: Config
) {
  return (currentState: ReducerState<R>, action: ReducerAction<R>) => {
    const state: ReducerState<R> = reducer(currentState, action);
    const snapshot = omit(state, config?.omit || []);

    localStorage.setItem(localStorageKey, JSON.stringify(snapshot));

    return state;
  };
}

function getValueFromLocalStorage<T>(localStorageKey: string): T | null {
  try {
    return JSON.parse(localStorage.getItem(localStorageKey)!);
  } catch {
    return null;
  }
}

export function useLocalStorageReducer<R extends AnyReducer>(
  localStorageKey: string,
  reducer: R,
  initialValue: ReducerState<R>,
  config?: Config
) {
  const localStorageKeyWithHash = useMemo(
    () => getLocalStorageKey(localStorageKey, initialValue),
    [initialValue, localStorageKey]
  );

  useEffect(
    function cleanStaleLocalStorage() {
      const localStorageKeyMatcher = new RegExp(localStorageKey);
      const localStorageEntriesToCleanUp = Object.keys(localStorage)
        .filter((key) => localStorageKeyMatcher.test(key))
        .filter((key) => key !== localStorageKeyWithHash);

      for (const key of localStorageEntriesToCleanUp) {
        localStorage.removeItem(key);
      }
    },
    [localStorageKey, localStorageKeyWithHash]
  );

  const [store, dispatch] = useReducer(
    withLocalStorageReducer<R>(localStorageKeyWithHash, reducer, config),
    merge({}, initialValue, getValueFromLocalStorage<ReducerState<R>>(localStorageKeyWithHash))
  );

  return [store, dispatch, localStorageKeyWithHash] as const;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function getLocalStorageKey(localStorageKey: string, initialValue: any) {
  return `${localStorageKey}__${hashObject(initialValue)}`;
}
