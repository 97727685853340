import { Term, addTermFilter } from "./addTermFilter";
import { getDefaultSearch } from "./getDefaultSearch";

export function createCompanySearch(company: {
  name?: string;
  company_no?: string;
  company_reg_number?: string;
  company_name?: string;
}) {
  console.assert(company.name || company.company_no);
  const search = getDefaultSearch();
  const term: Term = {
    label: "Company: " + company.name,
    searches: {},
    or_set: "Company",
  };
  if (company.company_no) {
    term.searches.company_reg_number = [company.company_no];
  } else {
    term.searches.company_name = [company.name];
  }
  addTermFilter(search, term);
  return search;
}
