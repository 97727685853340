/* eslint-disable react/display-name */
import { useTitlesByLocationQuery } from "react-migration/domains/ownership/apollo/useTitlesByLocationQuery";
import { SiteOverview } from "../../typings/Site";

import { Table } from "@landtechnologies/components";
import { useTranslation } from "react-migration/lib/i18n/useTranslation";
import { useEffect, forwardRef, ForwardedRef } from "react";

type OwnershipReportProps = {
  site: SiteOverview;
  onLoaded?: (isLoaded: boolean) => void;
};

export const OwnershipReport = forwardRef(
  (
    { site, onLoaded = () => false }: OwnershipReportProps,
    ownershipReportRef: ForwardedRef<HTMLDivElement>
  ) => {
    const { t } = useTranslation();
    const { titlesByLocation, loading } = useTitlesByLocationQuery(
      site && { type: site.geometry.type, coordinates: site.geometry.coordinates }
    );

    useEffect(() => {
      if (!loading) {
        onLoaded(true);
      }
    }, [loading, onLoaded]);

    const ownershipRows = titlesByLocation.map((title) => ({
      id: title.id,
      company_name: (title.owners?.[0]?.company ?? title.owners?.[0]?.person)?.name,
      address: title.owners?.[0]?.company?.addresses?.[0] ?? title.addresses?.[0],
      company_number: title.owners?.[0]?.company?.company_no,
      proprietorship: title.owners?.[0]?.company?.proprietorship,
      title_no: title.title_no,
      tenure: title.tenure,
    }));

    const mainOwner = ownershipRows[0];

    const renderCell = (value?: string) => (
      <span className="print:atlas-whitespace-pre-line print:atlas-w-[250px]">{value}</span>
    );

    return (
      <div className="atlas-mb-10" data-testid="ownership-report" ref={ownershipReportRef}>
        <h2 className="atlas-mb-2">{t("sites.card.site_report.ownership")}</h2>
        <div>
          {t("sites.card.site_report.ownership.number_of_titles")}: {ownershipRows.length}
        </div>
        <div className="atlas-mb-4">
          {t("sites.card.site_report.ownership.most_significant_owner")}:{" "}
          {mainOwner?.company_name ?? "Unknown"}
        </div>
        <Table
          size="small"
          primaryKey="id"
          columns={[
            {
              label: t("sites.card.site_report.ownership.address"),
              render: ({ address }) => renderCell(address),
              grow: true,
            },
            {
              label: t("sites.card.site_report.ownership.company_name"),
              render: ({ company_name }) => renderCell(company_name),
              grow: true,
            },
            {
              label: t("sites.card.site_report.ownership.company_number"),
              rowKey: "company_number",
              grow: true,
              align: "center",
            },
            {
              label: t("sites.card.site_report.ownership.proprietorship"),
              render: ({ proprietorship }) => renderCell(proprietorship),
              grow: true,
            },
            {
              label: t("sites.card.site_report.ownership.title_number"),
              rowKey: "title_no",
              grow: true,
              align: "center",
            },
            {
              label: t("sites.card.site_report.ownership.tenure"),
              rowKey: "tenure",
              grow: true,
              align: "center",
            },
          ]}
          rows={ownershipRows}
          tableClassName="atlas-block atlas-overflow-x-auto print:atlas-table print:atlas-table-fixed print:atlas-overflow-hidden"
        />
      </div>
    );
  }
);
