import { useState } from "react";
import { Loading, Notification, NumericInput } from "@landtechnologies/components";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

import Feature from "src/js/stores/user/Feature";
import { ENVIRONMENT } from "src/js/util/environment";
import { ModalV2 } from "react-migration/components/ModalV2/ModalV2";
import StripePayment from "react-migration/components/StripePayment/StripePayment";
import { useTranslation } from "react-migration/lib/i18n/useTranslation";
import { logEvent } from "react-migration/lib/util/logEvent";
import {
  AttemptChargeParams,
  PaymentResponse,
} from "react-migration/components/StripePayment/typings";

import GET_ACCOUNT from "../../../apollo/queries/account.gql";
import { useTopupBalanceMutation } from "../../../hooks/useTopupBalanceMutation";
import { UKQuickOptions } from "./components/UKQuickOptions";
import { USQuickOptions } from "./components/USQuickOptions";
import hasFeature from "src/js/stores/user/actions/hasFeature";

export const TopupModal = ({ setShowTopupModal }: { setShowTopupModal: (v: boolean) => void }) => {
  const { t } = useTranslation();
  const [paymentComplete, setPaymentComplete] = useState(false);
  const [amount, setAmount] = useState<number>();
  const [errorMessage, setErrorMessage] = useState("");
  const [topupBalance, { loading }] = useTopupBalanceMutation();
  const [amountError, setAmountError] = useState("");
  const [paymentPending, setPaymentPending] = useState(false);

  const attemptCharge = (
    params: AttemptChargeParams,
    onCompleted: (res: PaymentResponse) => void
  ) => {
    setPaymentPending(true);
    topupBalance({
      variables: {
        pence: params.amount,
        stripeCharge: params.stripeCharge,
      },
      onCompleted: (res) => {
        onCompleted({
          id: res.topupBalance.id,
          status: res.topupBalance.status,
          error_message: res.topupBalance.error_message,
          client_secret: res.topupBalance.client_secret,
        });
        setPaymentPending(false);
        logEvent("Top Up Complete", { amount: params.amount / 100, location: "modal" });
      },
      onError: (res) => {
        setErrorMessage(res.message);
        setPaymentPending(false);
        logEvent("Top Up Error", { message: res.message, location: "modal" });
      },
    });
  };
  const queriesToInvalidate = [GET_ACCOUNT];

  const handleAmountValueChange = (value: number | undefined) => {
    setAmount(value);
    if (!value) {
      setAmountError("Amount required");
    } else {
      setAmountError("");
    }
  };

  return (
    <ModalV2.Root defaultOpen={true} onOpenChange={setShowTopupModal}>
      <ModalV2.Body
        title="Topup"
        className="!atlas-z-[99999]"
        contentClassName="!atlas-w-[500px]"
        descriptionClassName="!atlas-overflow-visible"
      >
        <Elements stripe={loadStripe(ENVIRONMENT.STRIPE_PUBLIC_KEY || "")}>
          <div className="atlas-flex atlas-justify-center atlas-text-md atlas-p-4">
            <div className="atlas-flex atlas-flex-col atlas-max-w-[500px]">
              {!paymentComplete && (
                <>
                  <div className="atlas-mb-4">
                    {paymentPending && <Loading />}
                    {!paymentPending && (
                      <>
                        <NumericInput
                          value={amount}
                          onChange={(v) => handleAmountValueChange(v)}
                          onKeyUp={(v) => handleAmountValueChange(Number(v.currentTarget.value))}
                          placeholder="0"
                          label={t("dashboard.topup.topup.amount")}
                          errorMessage={amountError}
                          units={hasFeature(Feature.usAccess) ? "$" : "£"}
                          unitsPrefix={true}
                          hideSteppers={true}
                        />
                        {hasFeature(Feature.usAccess) ? (
                          <USQuickOptions handleAmountValueChange={handleAmountValueChange} />
                        ) : (
                          <UKQuickOptions handleAmountValueChange={handleAmountValueChange} />
                        )}
                      </>
                    )}
                  </div>
                  <StripePayment
                    amount={amount || 0}
                    attemptCharge={attemptCharge}
                    setPaymentComplete={setPaymentComplete}
                    queriesToInvalidate={queriesToInvalidate}
                    errorMessage={errorMessage}
                    isFormInvalid={Boolean(amountError)}
                    loading={loading}
                  />
                </>
              )}
              {paymentComplete && (
                <Notification.Success
                  additionalClassName="atlas-w-[440px] atlas-mt-4"
                  title={t("dashboard.topup.payment_complete")}
                  message={t("dashboard.topup.payment_complete_text")}
                  icon="check"
                />
              )}
            </div>
          </div>
        </Elements>
      </ModalV2.Body>
    </ModalV2.Root>
  );
};
