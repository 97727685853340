import gql from "graphql-tag";

export const TransactionFragment = gql`
  fragment TransactionFragment on Transaction {
    id
    purchase {
      reference
      type
      title_document {
        _id
        title_no
        created
        url
      }
    }
    state {
      status
      type
    }
    created
  }
`;

// This is for use with search results
export const OwnershipRecordFragment = gql`
  fragment OwnershipRecordFragment on TitleOwnership {
    id
    date_proprietor_added
    title {
      id
      title_no
      tenure
      registered_lease {
        start_date
        end_date
      }
      polygons {
        id
        geometry {
          type
          coordinates
        }
        location {
          coordinates
        }
        area
      }
      transactions {
        state {
          status
          type
          expected_response_time
        }
      }
    }
    company {
      name
      company_no
      is_foreign
      proprietorship
      addresses
      relevant_owners: tree(mode: RELEVANT_OWNER) {
        # relevant to the search terms
        parent_company {
          name
          company_no
        }
      }
      ultimate_owners: tree(mode: ULTIMATE_OWNER_COMPANY) {
        parent_company {
          name
          company_no
          is_foreign
        }
      }
    }
    person {
      name
      address
      email
    }
  }
`;

export const PlanningApplicationFragment = gql`
  fragment PlanningApplicationFragment on Planning {
    ref
    gss_code
    title
    address
    type
    decision
    decision_date
    date_received
    applicant_name
    agent_name
    modified
    agent_address
    status_derived
    labels {
      name
    }
    location {
      coordinates
    }
    authority {
      name
    }
    extract_url
    appeal_decision
    size
    classification
  }
`;

export const AddressBaseAndVoaFragment = gql`
  fragment AddressBaseAndVoaFragment on AddressBaseRecord {
    uprn
    property_class_description
    property_class_code
    use_class
    use_class_sep_2020
    state
    location {
      coordinates
      type
    }
    company_name
    address
    voa_record {
      uarn
      rateable_value

      valuations {
        total_value
        total_area
        list_year
        ppsqm
      }
    }
  }
`;

export const PlanningSearchFragment = gql`
  fragment PlanningSearchFragment on PlanningSearch {
    _id
    created
    modified
    _site
    composite_terms {
      and {
        or {
          search_group_details {
            isAggregate
            name
            id
          }
          or {
            field
            matchType
            value
          }
        }
      }
    }
    resi_count_range {
      from
      to
    }
    date_received_range {
      from
      to
    }
  }
`;

export const OwnershipSearchFragment = gql`
  fragment OwnershipSearchFragment on OwnershipSearch {
    _id
    created
    modified
    composite_terms {
      and {
        or {
          search_group_details {
            unionCollectionId
            isAggregate
            name
            id
          }
          or {
            field
            matchType
            value
          }
        }
      }
    }
    parcel_size_range {
      from
      to
    }
    proprietorship_added_date_range {
      from
      to
    }
    search_in_subsidiaries
  }
`;

export const PropertySearchFragment = gql`
  fragment PropertySearchFragment on PropertySearch {
    _id
    created
    modified
    composite_terms {
      and {
        or {
          search_group_details {
            unionCollectionId
            isAggregate
            name
            id
          }
          or {
            field
            matchType
            value
          }
        }
      }
    }
    total_area_range {
      from
      to
    }
    total_value_range {
      from
      to
    }
  }
`;

export const PlanningAlertSubscriptionFragment = gql`
  fragment PlanningAlertSubscriptionFragment on PlanningAlertSubscription {
    _id
    name
    subscribed
    created
    modified
    search {
      ...PlanningSearchFragment
      gss_codes
      geo_filter {
        ... on BoundingBoxGeoFilter {
          top_left {
            lat
            lng
          }
          bottom_right {
            lat
            lng
          }
        }
        ... on PolygonGeoFilter {
          coordinates {
            lat
            lng
          }
        }
        ... on RadiusGeoFilter {
          min_dist
          max_dist
          location {
            lat
            lng
          }
        }
      }
    }
  }

  ${PlanningSearchFragment}
`;

/**
 * @deprecated
 */
export const PricepaidRecordTidbitFragment = gql`
  fragment PricepaidRecordTidbitFragment on SiteTidbitPricepaidRecord {
    pricepaid_record {
      _id
      address
      transaction_id
      price_paid
      deed_date
      date_of_transfer
      postcode
      property_type
      new_build
      estate_type
      transaction_type
      total_floor_area
      location {
        coordinates
      }
      paon
      saon
      street
      town
      num_bedrooms
      est_bedrooms
      est_price
      number_habitable_rooms
    }
  }
`;

export const ComparableTransactionTidbitFragment = gql`
  fragment ComparableTransactionTidbitFragment on SiteTidbitComparableTransaction {
    comparable_transaction {
      _id
      transaction_id
      price
      date_of_transfer
      post_code
      designation
      category
      sub_category
      is_new_property
      tenure
      total_floor_area
      geometry {
        coordinates
      }
      town
      region
      address
      num_bedrooms
      est_bedrooms
      est_price
      number_habitable_rooms
    }
  }
`;

export const AttachmentTidbitFragment = gql`
  fragment AttachmentTidbitFragment on SiteTidbitAttachment {
    file_name
    title
    attachment {
      _id
      signed_url {
        url
        expires_at
      }
      thumb_url {
        url
        expires_at
      }
    }
  }
`;

export const OSMapPrintFragment = gql`
  fragment OSMapPrintFragment on OSMapPrint {
    _id
    scale
    orientation
    page_size
    url
  }
`;

export const OSMapPrintTidbitFragment = gql`
  fragment OSMapPrintTidbitFragment on SiteTidbitOSMapPrint {
    os_map_print {
      ...OSMapPrintFragment
    }
  }
  ${OSMapPrintFragment}
`;

export const TitleDocumentTidbitFragment = gql`
  fragment TitleDocumentTidbitFragment on SiteTidbitTitleDocument {
    title_document {
      _id
      title_no
      url
    }
  }
`;

export const SiteFragment = gql`
  fragment SiteFragment on Site {
    _id
    title
    notes
    site_labels {
      _id
      name
    }
    _stage {
      _id
      name
      color
    }
    geometry {
      type
      coordinates
    }
    assignees
  }
`;

export const SiteSavingFragment = gql`
  fragment SiteSavingFragment on SiteSaving {
    id
    saved_site {
      ...SiteFragment
    }
    geometry_info
    status
  }
  ${SiteFragment}
`;

export const ActivityFragment = gql`
  fragment ActivityFragment on SiteActivity {
    _id
    timestamp
    user {
      _id
      firstname
      surname
    }
    site {
      _id
      source {
        _id

        ... on SiteSourceDrawingTools {
          drawingType
        }
      }
    }
    ... on SiteActivitySiteAction {
      verb
    }
    ... on SiteActivityFieldUpdate {
      changed_fields {
        title {
          old
          new
        }
        site_labels {
          added {
            _id
            name
          }
          removed {
            _id
            name
          }
        }
        internal_status {
          old
          new
        }
        hidden {
          old
          new
        }
        priority {
          old
          new
        }
        geometry {
          new {
            type
          }
        }
        notes {
          new
        }
        stage {
          old {
            name
          }
          new {
            name
          }
        }
        assignees {
          old {
            _id
            firstname
            surname
          }
          new {
            _id
            firstname
            surname
          }
        }
      }
    }
    ... on SiteActivitySiteTidbit {
      verb
      tidbit {
        _id
        site {
          _id
        }
        is_pinned
        ...ActivityPlanningRecord
        ...ActivityOwnershipRecord
        ...PricepaidRecordTidbitFragment
        ...ComparableTransactionTidbitFragment
        ...AttachmentTidbitFragment
        ...OSMapPrintTidbitFragment
        ...TitleDocumentTidbitFragment
        ... on SiteTidbitComment {
          archived
          created
          comment_body
          user {
            _id
            firstname
            surname
          }
          parent {
            _id
            site {
              _id
            }
            ...ActivityPlanningRecord
            ...ActivityOwnershipRecord
            ...PricepaidRecordTidbitFragment
            ...ComparableTransactionTidbitFragment
            ...AttachmentTidbitFragment
            ...OSMapPrintTidbitFragment
            ...TitleDocumentTidbitFragment
          }
        }
      }
    }
  }

  fragment ActivityPlanningRecord on SiteTidbitPlanningRecord {
    _id
    planning_record {
      ref
    }
  }
  fragment ActivityOwnershipRecord on SiteTidbitOwnershipRecord {
    _id
    ownership_record {
      id
      company {
        name
      }
      title {
        id
        title_no
        tenure
      }
    }
  }
  ${PricepaidRecordTidbitFragment}
  ${ComparableTransactionTidbitFragment}
  ${AttachmentTidbitFragment}
  ${OSMapPrintTidbitFragment}
  ${TitleDocumentTidbitFragment}
`;
