import { DetailsList } from "react-migration/components/DetailsList";
import { updateDrawing } from "src/js/stores/drawing/actions/updateDrawing";
import { AnnotationSpecProps } from "./types";

export function TextBoxSpec({ drawing }: AnnotationSpecProps) {
  const onTextChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const newDrawing = {
      ...drawing,
      properties: {
        ...drawing.properties,
        text: e.target.value,
      },
    };

    updateDrawing(newDrawing, true);
  };

  return (
    <DetailsList>
      <DetailsList.Item label={"Update text"}>
        <textarea
          className="atlas-appearance-none atlas-px-3 atlas-py-2 atlas-text-md atlas-w-full atlas-border atlas-border-solid atlas-border-neutral-300 focus:atlas-border-border-action-focus atlas-rounded-md"
          onChange={onTextChange}
          value={drawing.properties.text}
          autoFocus
        />
      </DetailsList.Item>
    </DetailsList>
  );
}
