import type { Color } from "@deck.gl/core";
import { ScaleOrdinal, scaleOrdinal } from "d3-scale";
import { isDefined } from "react-migration/lib/util/isDefined";
import {
  DesignationFeature,
  DesignationFeatureProps,
} from "../../components/ConstraintLayer/DesignationLayer";
import patternConfig from "./pattern.json";
import type {
  DesignationStyle,
  PatternMapping,
  ValidDesignationLabelName,
  AttributeStyle,
} from "./types";
import {
  CATEGORY_STYLES,
  DEFAULT_COLOUR,
  DEFAULT_ICON_SIZE,
  DEFAULT_LINE_WIDTH,
  DEFAULT_POINT_RADIUS,
} from "./StyleMap";
import type { LtIconKey } from "./icons";
import { getIconUnicodeCharFromKey } from "./icons";
import { CollisionFilter } from "react-migration/layouts/map/Multilayer/layer_types/ConstraintsLayerType";
import { pathOr } from "ramda";
import { SingleDesignation } from "../../typings/applicationTypes/SingleDesignation";

export type StyleableDesignation = Pick<
  SingleDesignation,
  "sub_category_id" | "designation_attributes"
>;

export function isAttributeControlledStyle<T>(style: unknown): style is AttributeStyle<T> {
  return (
    typeof style === "object" && !Array.isArray(style) && !!(style as AttributeStyle<T>).attribute
  );
}

function isMapFeature(d: DesignationFeature | StyleableDesignation): d is DesignationFeature {
  return !!(d as DesignationFeature).properties;
}

export function normaliseDesignation(d: DesignationFeature | StyleableDesignation) {
  return isMapFeature(d) ? d.properties : d;
}

/**
 * Modify a colour accessor by changing multiplying the alpha value it produces
 * @param colourAccessor Color accessor for a designation
 * @param multiplier The decimal multiplier for the alpha (1.5 === 150%)
 * @returns New colour accessor with modified alpha channel
 */
function alphaModifier<D>(colourAccessor: (d: D) => Color, multiplier: number): (d: D) => Color {
  return (d: D) => {
    const [r, g, b, a] = colourAccessor(d);
    return [r, g, b, Math.min((a ?? 255) * multiplier, 255)];
  };
}

/**
 * Takes a propName (used for caching of scale) and an AttributeStyle, produces
 * a function that when called with a value will map it to another value based
 * on the underlying styles domain/range values. Uses an ordinal scale.
 */
const _attributeStyleScaleCache: Record<string, ScaleOrdinal<string | number, unknown>> = {};
function getAttributeStyleScale<D>(propName: string, style: AttributeStyle<D>) {
  const { attribute, domain, range, unknown } = style;
  const scaleCacheKey = attribute + propName;
  const cachedScale = _attributeStyleScaleCache[scaleCacheKey];
  const scale = cachedScale || scaleOrdinal();

  if (!cachedScale) {
    scale.domain(domain).range(range).unknown(unknown);
    _attributeStyleScaleCache[scaleCacheKey] = scale;
  }

  return scale as ScaleOrdinal<string | number, D>;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type UnwrapDefaultType<T> = T extends (...args: any) => infer R ? R : T;
type StyleAccessor<D> = (d: DesignationFeature | StyleableDesignation) => UnwrapDefaultType<D>;
/**
 * @todo infer return type instead of casting
 * @param propName A possible style prop configured on DesignationStyle
 * @param defaultValue The default value to use if style cannot be accessed
 * @returns Returns an accessor function that will return the style for the
 * provided style property for a given designation – either DesignationFeature
 * (from map) or SingleDesignation (from GraphQL).
 */

function createStyleAccessor<D>(
  propName: keyof DesignationStyle,
  defaultValue?: D
): StyleAccessor<D> {
  return (d) => {
    const { sub_category_id, designation_attributes } = normaliseDesignation(d);
    const styleMap = CATEGORY_STYLES[sub_category_id];
    const style = styleMap?.[propName];

    if (!style) {
      if (typeof defaultValue === "function") {
        return defaultValue(d);
      } else {
        return defaultValue as UnwrapDefaultType<D>;
      }
    }

    if (isAttributeControlledStyle(style)) {
      const attributeValue = designation_attributes?.find((a) => a.key === style.attribute)?.value;

      if (attributeValue === undefined) {
        console.warn(
          `No value found for attribute "${style.attribute}" for datum of type "${sub_category_id}" which uses attribute controlled styles.`
        );
      }

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      return getAttributeStyleScale(propName, style as any)(attributeValue!) as D;
    } else {
      return style as UnwrapDefaultType<D>;
    }
  };
}

/**
 * The following accessors allow for the underlying styles to be defined as
 * AttributeStyles which map designation_attribute values to style values.
 */
export const getDesignationFillColour = createStyleAccessor("fillColor", DEFAULT_COLOUR);

export const getDesignationLineColour = createStyleAccessor(
  "lineColor",
  alphaModifier(getDesignationFillColour, 1.2)
);

export const getDesignationLineWidth = createStyleAccessor("lineWidth", DEFAULT_LINE_WIDTH);

export const getDesignationPointRadius = createStyleAccessor("pointRadius", DEFAULT_POINT_RADIUS);

export const getDesignationPointFillColour = createStyleAccessor(
  "pointFillColor",
  alphaModifier(getDesignationFillColour, 1.5)
);

export const getDesignationIconKeyAccessor = createStyleAccessor<LtIconKey>("icon");
export const getDesignationIconSize = createStyleAccessor("iconSize", DEFAULT_ICON_SIZE);

export const getDesignationIconCodeAccessor: StyleAccessor<string> = (d) => {
  const iconKey = getDesignationIconKeyAccessor(d);
  return getIconUnicodeCharFromKey(iconKey);
};

/**
 * @deprecated should be replaced with `getDesignationFillColour` as the
 * sub_category_id alone isn't enough information to fetch the required style.
 */
export function getCategoryFillColour(key = ""): Color {
  return getDesignationFillColour({ sub_category_id: key } as SingleDesignation);
}

/**
 * @deprecated should be replaced with `getDesignationLineColour` as the
 * sub_category_id alone isn't enough information to fetch the required style.
 */
export function getCategoryLineColour(key = ""): Color {
  return getDesignationLineColour({
    sub_category_id: key,
  } as SingleDesignation) as unknown as Color;
}

export function getCategoryPatternConfig(
  key = ""
): Pick<DesignationStyle, "fillPattern" | "fillPatternScale"> {
  return CATEGORY_STYLES[key] ?? {};
}

export function getCategoryPatternMapping(key = ""): PatternMapping | undefined {
  const fillPatternKey = getCategoryPatternConfig(key)?.fillPattern;
  return fillPatternKey && patternConfig.mapping[fillPatternKey];
}

export function getLabelName(key: string): ValidDesignationLabelName | undefined {
  return CATEGORY_STYLES[key]?.labelName;
}

/**
 * @param categories List of `sub_category_id`'s
 * @returns List of `designation_attribute` keys which are needed to style these categories
 */
export function getStyledAttributes(categories: string[]): string[] {
  const attributes = categories
    .map((c) => CATEGORY_STYLES[c])
    .filter(isDefined)
    .flatMap((styleRules) => Object.values(styleRules))
    .filter(isAttributeControlledStyle)
    .map((attributeStyle) => attributeStyle.attribute);

  return Array.from(new Set(attributes));
}

export function getIconKeySet(categories: string[]): LtIconKey[] {
  return Array.from(
    new Set(categories.map((category) => CATEGORY_STYLES[category]?.icon).filter(isDefined))
  );
}

export function createCollisionFilterPriorityAccessor(
  collisionFilter: CollisionFilter["collisionPriorityMap"]
) {
  if (!collisionFilter) {
    return undefined;
  }

  /**
   * compiles the collisionFilter object in to an array of functions that can be efficiently called during the render phase to calculate the collision priority
   * although this function is recursive it should only ever end up recursing a couple of levels down e.g. attributes.attribute_key
   */
  function createPathGetters(
    path: string[],
    value: NonNullable<CollisionFilter["collisionPriorityMap"]>
  ): Array<
    [(d: DesignationFeatureProps | StyleableDesignation) => string, Record<string, number>]
  > {
    const entries = Object.entries(value);
    if (entries.every(([, v]) => typeof v === "number")) {
      return [[pathOr("", path), value as Record<string, number>]];
    }

    return entries.flatMap(([key, value]) =>
      createPathGetters(
        [...path, key],
        value as NonNullable<CollisionFilter["collisionPriorityMap"]>
      )
    );
  }

  const getters = createPathGetters([], collisionFilter);
  return (d: DesignationFeature | StyleableDesignation) => {
    const priority = getters.reduce((priority, [getter, valueMap]) => {
      const accessorKey = getter(normaliseDesignation(d));
      return priority + (valueMap[accessorKey] ?? 0);
    }, 0);
    return priority;
  };
}
