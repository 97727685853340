import { ukPostcodeSearchRegex, usPostcodeSearchRegex } from "../letters/helpers/postcodeRegex";

import { AvailableCountryCodes } from "../typings/CountryCode";
import Feature from "src/js/stores/user/Feature";
import hasFeature from "src/js/stores/user/actions/hasFeature";

export interface Address {
  addressLine1: string;
  city: string;
  region: string;
  postcode: string;
  countryCode?: AvailableCountryCodes;
}

export const parseUkAddress = (address: string): Address => {
  let addressLine1 = "";
  let city = "";
  let region = "";

  const postcode = address.match(ukPostcodeSearchRegex)?.[0] ?? "";
  const addressWithoutPostcode = address.replace(postcode, "");
  const addressParts = addressWithoutPostcode
    .split(",")
    .map((part) => part.trim())
    .filter((part) => !!part);
  if (addressParts.length <= 3) {
    [addressLine1 = "", city = "", region = ""] = addressParts;
  } else {
    region = addressParts.slice(-1)[0] || "";
    city = addressParts.slice(-2)[0] || "";
    addressLine1 = addressParts.slice(0, -2).join(", ");
  }
  return {
    addressLine1,
    city,
    region,
    postcode,
    countryCode: AvailableCountryCodes.UNITED_KINGDOM,
  };
};

export const parseUsAddress = (address: string): Address => {
  let addressLine1 = "";
  let city = "";
  let region = "";

  const postcode = address.match(usPostcodeSearchRegex)?.[0] ?? "";
  const addressWithoutPostcode = address.replace(postcode, "");
  const addressParts = addressWithoutPostcode
    .split(",")
    .map((part) => part.trim())
    .filter((part) => !!part);
  if (addressParts.length <= 3) {
    [addressLine1 = "", city = "", region = ""] = addressParts;
  } else {
    region = addressParts.at(-1) || "";
    city = addressParts.at(-2) || "";
    addressLine1 = addressParts.slice(0, -2).join(", ");
  }
  return {
    addressLine1,
    city,
    region,
    postcode,
    countryCode: AvailableCountryCodes.UNITED_STATES,
  };
};

export const parseAddress = (address?: string): Address => {
  const isUsUser = hasFeature(Feature.usAccess);
  if (!address) {
    return {
      addressLine1: "",
      city: "",
      region: "",
      postcode: "",
      countryCode: isUsUser
        ? AvailableCountryCodes.UNITED_STATES
        : AvailableCountryCodes.UNITED_KINGDOM,
    };
  }
  return isUsUser ? parseUsAddress(address) : parseUkAddress(address);
};
