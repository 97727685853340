import { useEffect, useState } from "react";

function getLocalStorageValue(key: string) {
  const value = localStorage.getItem(key);

  if (!value) return null;

  return JSON.parse(value);
}

export function useLocalStorage<T>(key: string, defaultValue: T) {
  const initialValue = getLocalStorageValue(key) ?? defaultValue;
  const [localStorageState, setLocalStorageState] = useState<T>(initialValue);

  useEffect(() => {
    localStorage.setItem(key, JSON.stringify(localStorageState));
  }, [key, localStorageState]);

  return [localStorageState, setLocalStorageState] as const;
}
