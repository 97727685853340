import Feature from "src/js/stores/user/Feature";
import { ConstraintsCategory } from "../../Constraints/types";
import { ConstraintsLayerType } from "../layer_types/ConstraintsLayerType";
import { Layer } from "../types";

export const FLUM: Layer = {
  id: ConstraintsCategory.FLUM,
  layerType: ConstraintsLayerType,
  hideByDefault: false,
  description:
    "Future Land Use data acquired from a variety of sources, including directly from the publisher and from the Florida Geographic Data Library (FGDL)",
  title: "Future Land Use (FLUM)",
  requiredFeatureAccess: [Feature.flumLayer],
  toggleable: true,
  layerConfig: {
    rootCategory: ConstraintsCategory.FLUM,
    showConstraintLabels: true,
    categorySchema: [
      { key: "flum_agriculture_outer", defaultValue: true },
      { key: "flum_planned_outer", defaultValue: false },
      { key: "flum_special", defaultValue: false },
      { key: "flum_federal", defaultValue: false },
      { key: "flum_institutional", defaultValue: false },
      { key: "flum_recreation", defaultValue: false },
      { key: "flum_unknown", defaultValue: false },
      { key: "flum_native_american_lands", defaultValue: false },
      { key: "flum_water", defaultValue: false },
      { key: "flum_conservation", defaultValue: false },
      { key: "flum_transportation_utilities", defaultValue: false },
      { key: "flum_mixed_use", defaultValue: false },
      { key: "flum_neighborhood_activity_center", defaultValue: false },
      { key: "flum_downtown_urban_core", defaultValue: false },
      { key: "flum_mixed_use_general", defaultValue: false },
      { key: "flum_special_planning_area", defaultValue: false },
      { key: "flum_mixed_use_rural", defaultValue: false },
      { key: "flum_single_family", defaultValue: true },
      { key: "flum_two_family", defaultValue: true },
      { key: "flum_multi_family", defaultValue: true },
      { key: "flum_neighborhood_commercial", defaultValue: true },
      { key: "flum_general_commercial", defaultValue: true },
      { key: "flum_core_commercial", defaultValue: true },
      { key: "flum_retail_commercial", defaultValue: true },
      { key: "flum_special_commercial", defaultValue: true },
      { key: "flum_light_industrial", defaultValue: true },
      { key: "flum_industrial", defaultValue: true },
      { key: "flum_office", defaultValue: true },
    ],
  },
};
