import { useMemo } from "react";
import { SelectionFeature } from "src/js/stores/map/store";
import { filterApplicationListByLayerFilters } from "../utils/filterApplicationListByLayerFilters";
import { usePlanningApplicationsContext } from "../Context";
import { usePlanningApplicationsByGeometry } from "./usePlanningApplicationsByGeometry";

interface Options {
  useLayerFilters?: boolean;
  filterSelectionFromList?: boolean;
}

export function usePlanningApplicationListBySelection(
  selection: SelectionFeature,
  { useLayerFilters = true, filterSelectionFromList = false }: Options = {}
) {
  const { planningApplicationLayerFilters: layerFilters } = usePlanningApplicationsContext();
  const {
    data: planningApplications,
    loading,
    error,
  } = usePlanningApplicationsByGeometry(selection?.feature?.geometry ?? null);

  const filteredPlanningApplications = useMemo(() => {
    if (!planningApplications?.length) return [];

    const enrichedApplications = planningApplications.map((application) => ({
      ...application,
      _id: application.id,
    }));

    let applications =
      useLayerFilters && layerFilters
        ? filterApplicationListByLayerFilters(enrichedApplications, layerFilters)
        : enrichedApplications;

    if (filterSelectionFromList) {
      applications = applications.filter((application) => {
        return application._id !== selection?.id;
      });
    }

    return applications;
  }, [planningApplications, useLayerFilters, layerFilters, filterSelectionFromList, selection?.id]);

  return { filteredPlanningApplications, loading, error };
}
