import { ISourcingToolSearchFilter } from "react-migration/domains/search/typings/SourcingToolSpecificFilterTypes";
import { SearchType } from "react-migration/domains/search/constants/searchTypes";

export const ZoneCodesFilter: ISourcingToolSearchFilter = {
  id: "zone-codes-id",
  type: "multi-text-search",
  field: "sidebar.react.search_sidebar.zone_codes",
  mustOptions: ["MATCH_ALL", "MATCH_NONE"],
  must: "MATCH_ALL",
  mustIsConfigurable: true,
  updateFilterDialogIsVisible: false,
  searchTags: [],
  // searchTagsAutoCompleteConfig: {
  //   basePath: `${ENVIRONMENT.MCSEARCH_SERVICE_URL}/autocomplete/{type}/{limit}/{searchText}`,
  //   variables: {
  //     type: `zone_codes_tags`,
  //     limit: 5,
  //   },
  //   searchVariableName: "searchText",
  // },
  searchTypes: [SearchType.SOURCING_TOOL_US_FL],
};
