export const GeometryIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="none" viewBox="0 0 20 20">
      <path
        fill="currentColor"
        d="M11.715 3.06L4.28 6.44l-2.04 9.43h15.724l-.348-9.533-5.9-3.278zm-.598-1.926a1.5 1.5 0 011.35.055l6.366 3.537a1.5 1.5 0 01.77 1.256l.377 10.334a1.5 1.5 0 01-1.499 1.554H1.62a1.5 1.5 0 01-1.466-1.817L2.378 5.771a1.5 1.5 0 01.845-1.048l7.894-3.589z"
      ></path>
      <defs>
        <clipPath id="clip0_2962_53871">
          <path fill="#fff" d="M0 0H20V20H0z"></path>
        </clipPath>
      </defs>
    </svg>
  );
};
