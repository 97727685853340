import { Button, useDropdownMenuContext } from "@landtechnologies/components";

import classNames from "classnames";
import { useCallback } from "react";
import { useTranslation } from "react-migration/lib/i18n/useTranslation";

export interface ActionBarProps {
  onCancel: () => void;
  onApply: () => void;
}

export const ActionBar = ({ onCancel, onApply }: ActionBarProps) => {
  const { t } = useTranslation();

  const { closeMenu } = useDropdownMenuContext();

  const handleCancel = useCallback(() => {
    onCancel();
    closeMenu();
  }, [closeMenu, onCancel]);
  const handleApply = useCallback(() => {
    onApply();
    closeMenu();
  }, [closeMenu, onApply]);

  return (
    <div
      className={classNames(
        "atlas-p-4",
        "atlas-flex",
        "atlas-flex-row",
        "atlas-justify-end",
        "atlas-gap-2"
      )}
      data-testid="select-filter-action-bar"
    >
      <Button.Secondary onClick={handleCancel}>
        {t("components.action_bar.filters.cancel")}
      </Button.Secondary>
      <Button.Primary onClick={handleApply}>
        {t("components.action_bar.filters.apply")}
      </Button.Primary>
    </div>
  );
};
