// This is the "shell" Intercom object, which the Intercom script will look at once it's loaded
// Note that it will create a new object and assign back to window.Intercom.
window.Intercom = function () {
  window.Intercom.c(arguments);
};
window.Intercom.q = [];
window.Intercom.c = (args) => window.Intercom.q.push(args);

// We defer for a few seconds after page load as we want the page load itself to be snappy
module.exports.bootIntercomDeferred = function (settings, delayMs = 5000) {
  window.Intercom("update", settings);

  setTimeout(() => {
    const s = document.createElement("script");
    s.type = "text/javascript";
    s.async = true;
    s.src = `https://widget.intercom.io/widget/${settings.app_id}`;
    const x = document.getElementsByTagName("script")[0];
    x.parentNode.insertBefore(s, x);
  }, delayMs);
};

// Because the window.Intercom Object changes after the actual script is loaded we use this wrapper
// which enables other files to import it as though it were a static object
module.exports.Intercom = function () {
  window.Intercom.call(null, ...arguments);
};

// it's handy to track the visible state
// eslint-disable-next-line no-undef
Intercom("onShow", () => (module.exports.Intercom.visible = true));
// eslint-disable-next-line no-undef
Intercom("onHide", () => (module.exports.Intercom.visible = false));
