import { SkeletonLoading } from "@landtechnologies/components";
import { useCallback } from "react";
import { CheckboxTree, CheckboxTreeChange } from "react-migration/components/CheckboxTree";
import { HatchComponent, TreeStructure } from "react-migration/components/CheckboxTree/types";
import { CheckboxSchema } from "../Multilayer/layer_types/ConstraintsLayerType";
import { useControlTracking } from "../Multilayer/ControlTrackingContext";
import clsx from "clsx";
interface GenericCategoryFilterProps {
  id: string;
  categorySchema: CheckboxSchema[];
  disabled: boolean;
  visibleCategoryIds: string[];
  setVisibleCategories(visibleCategories: string[]): void;
  Hatch?: HatchComponent;
  title?: JSX.Element;
  categoryTree: TreeStructure | null;
  getCollapsed?: (id: string) => boolean;
  setCollapsed?: (id: string, value: boolean) => void;
}

export function GenericCategoryFilter({
  categorySchema,
  disabled,
  visibleCategoryIds,
  setVisibleCategories,
  Hatch,
  title,
  categoryTree,
  getCollapsed,
  setCollapsed,
}: Readonly<GenericCategoryFilterProps>) {
  const { trackControlChange } = useControlTracking();

  const handleChange = useCallback<CheckboxTreeChange>(
    ({ id, checked, visibleNodeIds }) => {
      trackControlChange({ filterName: id, filterState: checked });

      setVisibleCategories(visibleNodeIds);
    },
    [setVisibleCategories, trackControlChange]
  );

  if (!categoryTree) {
    return (
      <div className="atlas-relative atlas-overflow-hidden" style={{ minHeight: 60 }}>
        <SkeletonLoading rows={1} bgClassName="atlas-bg-neutral-1" showPadding={false} />
      </div>
    );
  }

  return (
    <div className="">
      <div
        className={clsx("atlas-flex atlas-flex-col atlas-gap-2 atlas-pb-4", {
          "atlas-opacity-50": disabled,
        })}
      >
        <CheckboxTree
          title={title}
          treeStructure={categoryTree}
          treeSchema={categorySchema}
          onChange={handleChange}
          visibleNodeIds={visibleCategoryIds}
          Hatch={Hatch}
          getCollapsed={getCollapsed}
          setCollapsed={setCollapsed}
          disabled={disabled}
        />
      </div>
    </div>
  );
}
