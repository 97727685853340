import { useQuery } from "@apollo/client";
import GET_SITE_WITH_ALERT from "../apollo/queries/siteWithAlerts.gql";
import { routedClient } from "react-migration/lib/persistence/apollo/routedClient";
import { SiteWithAlertsResponse } from "../../typings/SiteWithAlertsResponse";

type UseSiteWithAlertsQueryParams = {
  _id: string | undefined | null;
};

export const useSiteWithAlertsQuery = ({ _id }: UseSiteWithAlertsQueryParams) => {
  return useQuery<SiteWithAlertsResponse, UseSiteWithAlertsQueryParams>(GET_SITE_WITH_ALERT, {
    client: routedClient,
    variables: {
      _id,
    },
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    skip: !_id,
  });
};
