import { useQuery } from "@apollo/client";
import { LandTechEndpoints, routedClient } from "react-migration/lib/persistence/apollo";
import ADDITIONAL_TRANSACTIONS_BY_TRANSACTION_ID from "../apollo/additionalTransactionsByTransactionIds.gql";
import { TransactionDTO } from "@landtechnologies/comparables-services";
import { useMemo } from "react";

interface AdditionalTransactionsByTransactionIdHookArgs {
  transactionId: string;
}

export function useAdditionalTransactionsByTransactionId({
  transactionId,
}: AdditionalTransactionsByTransactionIdHookArgs) {
  const { data, loading, error } = useQuery<{
    additionalTransactionsByTransactionId: TransactionDTO[];
  }>(ADDITIONAL_TRANSACTIONS_BY_TRANSACTION_ID, {
    variables: { transaction_id: transactionId },
    client: routedClient,
    context: {
      endpoint: LandTechEndpoints.Gateway,
    },
  });

  return useMemo(
    () => ({
      data: loading ? undefined : data?.additionalTransactionsByTransactionId ?? [],
      loading,
      error,
    }),
    [data, loading, error]
  );
}
