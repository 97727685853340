import { useState } from "react";
import { Cross2Icon } from "@radix-ui/react-icons";
import clsx from "clsx";
import { Modal, Button, Alert } from "@landtechnologies/components";
import type { SiteFeature } from "src/js/stores/siteGeometry/store";
import { useTranslation } from "react-migration/lib/i18n/useTranslation";
import { logEvent } from "react-migration/lib/util/logEvent";
import { useSnapshot } from "valtio";
import { SiteButton } from "react-migration/layouts/map/Multilayer/layer_types/SitesLayerType/SiteButton";
import { mapStore } from "src/js/stores/map/store";

interface MergeSiteModalProps {
  handleCancelSave(): void;
  handleSaveAsSiteAs(): void;
  handleMergeSave(selectedMergeSiteId: string | undefined): void;
  intersectingSites: SiteFeature[];
  hasError: boolean;
  setHasError: (v: boolean) => void;
  disabled?: boolean;
}

export const MergeSiteModal = ({
  handleCancelSave,
  handleSaveAsSiteAs,
  handleMergeSave,
  intersectingSites,
  disabled,
  hasError,
  setHasError,
}: MergeSiteModalProps) => {
  const { t } = useTranslation();
  const { googleMap } = useSnapshot(mapStore);
  const [selectedMergeSiteId, setSelectedMergeSiteId] = useState<string>();

  function openHelp() {
    const center = googleMap?.getCenter();
    if (center) {
      logEvent("Open Intercom Widget", { from: "failed to save site" });
      window?.Intercom(
        "showNewMessage",
        t("sites.save_site.merge_site_modal.contact_support_message", {
          coordinates: JSON.stringify(center.toJSON()),
        })
      );
    }
  }

  return (
    <Modal
      title={t("sites.save_site.merge_site_modal.title")}
      titleClasses="atlas-p-4"
      contentClasses="!atlas-p-0 atlas-text-sm atlas-max-w-lg"
    >
      <button
        onClick={handleCancelSave}
        className="hover:atlas-bg-neutral-300 atlas-absolute atlas-top-[10px] atlas-right-[10px] atlas-inline-flex atlas-h-[25px] atlas-w-[25px] atlas-appearance-none atlas-items-center atlas-justify-center atlas-rounded-full focus:atlas-outline-none"
      >
        <Cross2Icon />
      </button>
      <div className="atlas-px-6 atlas-py-4">
        <p className="atlas-font-bold">{t("sites.save_site.merge_site_modal.prompt_text")}</p>
        <p>{t("sites.save_site.merge_site_modal.prompt_text_secondary")}</p>

        <div className="atlas-mt-4 atlas-flex atlas-flex-col atlas-items-baseline atlas-gap-y-2 atlas-max-h-[40vh] atlas-overflow-auto">
          {intersectingSites.map((site) => {
            const isSelected = selectedMergeSiteId === site.id;

            return (
              <label
                className="atlas-w-full atlas-flex atlas-flex-row atlas-flex-shrink-0 atlas-overflow-hidden"
                key={`match-${site.id}`}
              >
                <input
                  className="atlas-mr-2"
                  type="radio"
                  value={site.id}
                  checked={isSelected}
                  onChange={(e) => setSelectedMergeSiteId(e.target.value)}
                />
                <div
                  className={clsx("atlas-grow atlas-border atlas-rounded atlas-overflow-hidden", {
                    "atlas-border-neutral-200": !isSelected,
                    "atlas-border-border-action-focus atlas-bg-blue-50": isSelected,
                  })}
                >
                  <SiteButton
                    site={site}
                    onClick={() => setSelectedMergeSiteId(isSelected ? undefined : String(site.id))}
                  />
                </div>
              </label>
            );
          })}
        </div>
      </div>

      {hasError && (
        <div className="atlas-px-4">
          <Alert.Warning onClickClose={() => setHasError(false)}>
            {t("sites.save_site.merge_site_modal.failed", {
              contactSupport: (
                <span className="atlas-cursor-pointer atlas-text-blue-600" onClick={openHelp}>
                  {t("sites.save_site.merge_site_modal.contact_support")}
                </span>
              ),
            })}
          </Alert.Warning>
        </div>
      )}

      <div className="atlas-flex atlas-gap-2 atlas-justify-end atlas-px-4 atlas-py-4">
        <Button.Secondary disabled={disabled} onClick={handleCancelSave}>
          {t("sites.save_site.merge_site_modal.cancel")}
        </Button.Secondary>
        <Button.Primary disabled={disabled} onClick={handleSaveAsSiteAs}>
          {t("sites.save_site.merge_site_modal.save_as_site")}
        </Button.Primary>
        <Button.Primary
          onClick={() => handleMergeSave(selectedMergeSiteId)}
          disabled={disabled || !selectedMergeSiteId}
        >
          {t("sites.save_site.merge_site_modal.merge_sites")}
        </Button.Primary>
      </div>
    </Modal>
  );
};
