import { ISourcingToolSearchFilter } from "react-migration/domains/search/typings/SourcingToolSpecificFilterTypes";
import { ITextSearchFilter } from "react-migration/domains/search/typings/SourcingToolFilters/TextSearchFilter";
import { SearchType } from "react-migration/domains/search/constants/searchTypes";
import { ENVIRONMENT } from "src/js/util/environment";
import Feature from "src/js/stores/user/Feature";

const OwnerPortfolioTextFilter: ITextSearchFilter = {
  id: "owner-portfolio-text-filter-id",
  type: "text",
  field: "sidebar.react.search_sidebar.owner_portfolio",
  text: "",
  must: "EQUAL",
  mustOptions: ["EQUAL"],
  searchTypes: [SearchType.PORTFOLIO],
  include: [Feature.TURTLES],
  updateFilterDialogIsVisible: false,
  autoCompleteConfig: {
    basePath: `${ENVIRONMENT.MCSEARCH_SERVICE_URL}/autocomplete/{type}/{limit}/{searchText}`,
    variables: {
      type: `owner_name`,
      limit: 5,
    },
    searchVariableName: "searchText",
  },
  hidden: false,
};

const OwnerPortfolioAddressTextFilter: ITextSearchFilter = {
  id: "owner-portfolio-address-text-filter-id",
  type: "text",
  field: "sidebar.react.search_sidebar.owner_portfolio",
  text: "",
  must: "EQUAL",
  mustOptions: ["EQUAL"],
  searchTypes: [SearchType.PORTFOLIO],
  updateFilterDialogIsVisible: false,
  hidden: true,
};

export const OwnerPortfolioFilter: ISourcingToolSearchFilter = {
  id: "owner-portfolio-id",
  type: "compound",
  field: "sidebar.react.search_sidebar.owner_portfolio",
  filters: [OwnerPortfolioTextFilter, OwnerPortfolioAddressTextFilter],
  mustOptions: ["MATCH_ALL", "MATCH_NONE"],
  must: "MATCH_ALL",
  updateFilterDialogIsVisible: false,
  searchTypes: [SearchType.PORTFOLIO],
  include: [Feature.TURTLES],
  isDefault: true,
};
