import { updateAssignees } from "./actions/updateAssignees";
import { updateLocalAuthorities } from "./actions/updateLocalAuthorities";
import { updateSearchString } from "./actions/updateSearchString";
import { updateSiteLabels } from "./actions/updateSiteLabels";
import { updateStageIds } from "./actions/updateStageIds";
import { useAssignees } from "./hooks/useAssignees";
import { useLocalAuthorities } from "./hooks/useLocalAuthorities";
import { useSearchString } from "./hooks/useSearchString";
import { useSiteLabels } from "./hooks/useSiteLabels";
import { useStageIds } from "./hooks/useStageIds";

import { proxy } from "valtio/vanilla";

export type SiteFiltersStore = {
  searchString: string;
  siteLabels: string[];
  assignees: string[];
  localAuthorities: string[];
  stageIds: string[];
};

export const defaultValues: SiteFiltersStore = {
  searchString: "",
  siteLabels: [],
  assignees: [],
  localAuthorities: [],
  stageIds: [],
};

export const siteFiltersStore = proxy<SiteFiltersStore>({ ...defaultValues });
export const siteFiltersMapStore = proxy<SiteFiltersStore>({ ...defaultValues });

// It would be good to have this reset the store dynamically without explicitly resetting each property
// but TypeScript makes it impossible to assign the given keys to an object dynamically
export function resetSiteFiltersStore(store: SiteFiltersStore): void {
  store.searchString = defaultValues.searchString;
  store.siteLabels = defaultValues.siteLabels;
  store.assignees = defaultValues.assignees;
  store.localAuthorities = defaultValues.localAuthorities;
  store.stageIds = defaultValues.stageIds;
}

export function buildSiteFiltersStoreMethods(store: SiteFiltersStore) {
  return {
    useAssignees: () => useAssignees(store),
    updateAssignees: (assignees: string[]) => updateAssignees(assignees, store),
    useLocalAuthorities: () => useLocalAuthorities(store),
    updateLocalAuthorities: (localAuthorities: string[]) =>
      updateLocalAuthorities(localAuthorities, store),
    useSearchString: () => useSearchString(store),
    updateSearchString: (searchString: string) => updateSearchString(searchString, store),
    useSiteLabels: () => useSiteLabels(store),
    updateSiteLabels: (siteLabels: string[]) => updateSiteLabels(siteLabels, store),
    useStageIds: () => useStageIds(store),
    updateStageIds: (stageIds: string[]) => updateStageIds(stageIds, store),
    resetSiteFiltersStore: () => resetSiteFiltersStore(store),
  };
}
