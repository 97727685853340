import sidebar from "./sidebar.json";
import search from "./search.json";
import filterForm from "./filterForm.json";
import filterFieldsForm from "./filterFieldsForm.json";

export default {
  ...sidebar,
  ...search,
  ...filterForm,
  ...filterFieldsForm,
};
