import Feature from "src/js/stores/user/Feature";
import { ConstraintsCategory } from "../../Constraints/types";
import { ConstraintsLayerType } from "../layer_types/ConstraintsLayerType";
import { Layer } from "../types";

export const PERMITS: Layer = {
  id: ConstraintsCategory.BUILDING_PERMITS,
  // For now the building permits data causes the tile service to fail at <14
  layerType: ConstraintsLayerType,
  title: "Building Permits",
  requiredFeatureAccess: [Feature.buildingPermits],
  toggleable: true,
  hideByDefault: true,
  description:
    "Building Permits show active, finalised and expired Commercial and Residential building permits. It covers Miami City and Miami-Dade County",
  layerConfig: {
    showConstraintLabels: false,
    rootCategory: ConstraintsCategory.BUILDING_PERMITS,
    categorySchema: [
      { key: "us_building_permits_residential" },
      { key: "us_building_permits_commercial" },
    ],
  },
};
