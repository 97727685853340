<template>
  <react-wrapper :component="Integrations" />
</template>

<script>
import ReactWrapper from "view/wrappers/react-wrapper";
import { Integrations } from "react-migration/domains/dashboard/integrations/Integrations";

export default {
  components: {
    ReactWrapper,
  },
  computed: {
    Integrations() {
      return Integrations;
    },
  },
};
</script>
