import { AppealDecision, PlanningApplication, StatusDerived } from "../types";

export enum PlanningColor {
  GREEN = "GREEN",
  GRAY = "GRAY",
  RED = "RED",
  ORANGE = "ORANGE",
  DEFAULT = "DEFAULT",
}

export const PLANNING_TEXT_COLOR_MAP = {
  [PlanningColor.GREEN]: "atlas-text-green-400",
  [PlanningColor.GRAY]: "atlas-text-neutral-400",
  [PlanningColor.RED]: "atlas-text-red-500",
  [PlanningColor.DEFAULT]: "atlas-text-blue-700",
  [PlanningColor.ORANGE]: "atlas-text-orange-500",
};

export function getAppealColor(appealDecision: PlanningApplication["appeal_decision"]) {
  if (!appealDecision) return;

  switch (appealDecision) {
    case AppealDecision.NOTICE_QUASHED:
    case AppealDecision.QUASHED_ON_LEGAL_GROUNDS:
    case AppealDecision.PLANNING_PERMISSION_GRANTED:
    case AppealDecision.ALLOWED_IN_PART:
    case AppealDecision.SPLIT_DECISION:
    case AppealDecision.ALLOWED_WITH_CONDITIONS:
    case AppealDecision.ALLOWED: {
      return PlanningColor.GREEN;
    }

    case AppealDecision.NOTICE_UPHELD:
    case AppealDecision.NOTICE_VARIED_AND_UPHELD:
    case AppealDecision.INVALID:
    case AppealDecision.DISMISSED:
    case AppealDecision.TURNED_AWAY: {
      return PlanningColor.RED;
    }

    case AppealDecision.UNKNOWN:
    case AppealDecision.NO_DECISION_CODE_ASSOCIATED:
    case AppealDecision.APPEAL_WITHDRAWN:
    default: {
      return PlanningColor.GRAY;
    }
  }
}

export function getStatusColor(statusDerived: PlanningApplication["status_derived"]) {
  if (statusDerived === StatusDerived.APPROVED) return PlanningColor.GREEN;
  if (statusDerived === StatusDerived.REJECTED) return PlanningColor.RED;
  if (statusDerived === StatusDerived.WITHDRAWN) return PlanningColor.RED;
  if (statusDerived === StatusDerived.PENDING) return PlanningColor.ORANGE;
  if (statusDerived === StatusDerived.UNKNOWN) return PlanningColor.GRAY;
}
