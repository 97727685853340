import { identity } from "ramda";
import { convertDistance } from "react-migration/lib/util/conversion";
import { MeasurementSystem } from "src/js/stores/user/store";
import {
  msqToAcres,
  msqToFtsq,
  msqToSqMiles,
  msqToHectares,
  msqToKmSq,
  hectaresToMsq,
  kmSqToMsq,
  ftsqToMsq,
  acresToMsq,
  sqMilesToMsq,
} from "src/js/util/units_and_constants";

export const toUsersPreferredArea = ({
  area,
  unitPreference,
  granularity = "small",
}: {
  area: number | null;
  unitPreference: MeasurementSystem;
  granularity?: "small" | "medium" | "large";
}): number => {
  if (!area) {
    return 0;
  }

  const conversions = {
    [MeasurementSystem.METRIC]: {
      small: identity,
      medium: msqToHectares,
      large: msqToKmSq,
    },
    [MeasurementSystem.IMPERIAL]: {
      small: msqToFtsq,
      medium: msqToAcres,
      large: msqToSqMiles,
    },
  };

  return conversions[unitPreference][granularity || "small"](area);
};

export const fromUsersPreferredArea = ({
  area,
  unitPreference,
  granularity = "small",
}: {
  area: number;
  unitPreference: MeasurementSystem;
  granularity?: "small" | "medium" | "large";
}): number => {
  if (!area) {
    return 0;
  }

  const conversions = {
    [MeasurementSystem.METRIC]: {
      small: identity,
      medium: hectaresToMsq,
      large: kmSqToMsq,
    },
    [MeasurementSystem.IMPERIAL]: {
      small: ftsqToMsq,
      medium: acresToMsq,
      large: sqMilesToMsq,
    },
  };

  return conversions[unitPreference][granularity || "small"](area);
};

export const formattedArea = ({
  area,
  unitPreference,
  t,
  granularity,
}: {
  area: number;
  unitPreference: MeasurementSystem;
  t: (key: string, values?: Record<string, string | number>) => string;
  granularity?: "small" | "medium" | "large";
}) => {
  const units = getAreaDisplayUnits(unitPreference, t, granularity);
  const fractionDigits = getFractionDigits(granularity);
  return `${toUsersPreferredArea({ area, unitPreference, granularity }).toLocaleString(undefined, {
    maximumFractionDigits: fractionDigits,
  })} ${units}`;
};

export const radiusInUserUnits = ({
  radius,
  unitPreference,
  isSmallScale,
}: {
  radius: number;
  unitPreference: MeasurementSystem;
  isSmallScale: boolean;
}) => {
  return convertDistance(radius, {
    unitPreference,
    smallUnitSize: isSmallScale,
  });
};

const unitSmall = (unitPreference: MeasurementSystem) =>
  unitPreference === MeasurementSystem.METRIC ? "m" : "ft";
const unitLarge = (unitPreference: MeasurementSystem) =>
  unitPreference === MeasurementSystem.METRIC ? "km" : "miles";
export const unitOfMeasurement = ({
  unitPreference,
  small = true,
}: {
  unitPreference: MeasurementSystem;
  small?: boolean;
}): string => (small ? unitSmall(unitPreference) : unitLarge(unitPreference));

export const getAreaDisplayUnits = (
  unitPreference: MeasurementSystem,
  t: (key: string, values?: Record<string, string | number>) => string,
  granularity?: "small" | "medium" | "large"
) => {
  const i18nCodes = {
    [MeasurementSystem.METRIC]: {
      small: "units.area.abbreviated.metric.small",
      medium: "units.area.abbreviated.metric.medium",
      large: "units.area.abbreviated.metric.large",
    },
    [MeasurementSystem.IMPERIAL]: {
      small: "units.area.abbreviated.imperial.small",
      medium: "units.area.full.imperial.medium",
      large: "units.area.abbreviated.imperial.large",
    },
  };

  return t(i18nCodes[unitPreference][granularity ?? "small"]);
};

export const getDistanceDisplayUnits = (
  unitPreference: MeasurementSystem,
  t: (key: string, values?: Record<string, string | number>) => string,
  granularity?: "small" | "medium" | "large"
) => {
  const i18nCodes = {
    [MeasurementSystem.METRIC]: {
      small: "units.distance.short.metric.abbreviated",
      medium: "units.distance.long.metric.abbreviated",
      large: "units.distance.long.metric.abbreviated",
    },
    [MeasurementSystem.IMPERIAL]: {
      small: "units.distance.short.imperial.abbreviated",
      medium: "units.distance.long.imperial",
      large: "units.distance.long.imperial",
    },
  };

  return t(i18nCodes[unitPreference][granularity ?? "small"]);
};

export const getFractionDigits = (granularity?: "small" | "medium" | "large") => {
  if (granularity === "medium" || granularity === "large") {
    return 2;
  }

  return 0;
};
