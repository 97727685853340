import { LayerTypeSelectionHeaderProps } from "../../types";
import { useUtilityById } from "./useUtilityById";

export function UtilitySelectionHeader({ selection }: Readonly<LayerTypeSelectionHeaderProps>) {
  const selectedUtility = useUtilityById(selection.id);

  if (!selectedUtility) return null;

  return (
    <div className="atlas-flex atlas-flex-row atlas-items-start atlas-gap-x-2">
      <div
        className="atlas-h-4 atlas-w-4 atlas-rounded atlas-shrink-0"
        style={{
          backgroundColor: selectedUtility.properties.fill ?? selectedUtility.properties.stroke,
        }}
      />
      <div className="atlas-flex atlas-flex-col atlas-items-stretch atlas-grow">
        <div className="atlas-text-xs atlas-font-semibold atlas-text-content-tertiary">
          {selectedUtility.properties.Category}
        </div>
        <div className="atlas-text-sm atlas-font-semibold atlas-text-content-primary atlas-line-clamp-3">
          {selectedUtility.properties.Provider}
        </div>
      </div>
    </div>
  );
}
