import { Layer } from "../../types";
import { ConstraintsLayerType } from "./ConstraintsLayerType";
import { ConstraintsLayer } from "./types";

function isConstraintLayer(layer: Layer): layer is ConstraintsLayer {
  return layer.layerType === ConstraintsLayerType;
}

export function setLayerCategoryDefaultValue(layer: Layer, value: boolean): Layer {
  return !isConstraintLayer(layer)
    ? layer
    : {
        ...layer,
        ...(layer.layerConfig
          ? {
              layerConfig: {
                ...layer.layerConfig,
                categorySchema: layer.layerConfig.categorySchema.map((category) => ({
                  ...category,
                  defaultValue: value,
                })),
              },
            }
          : {}),
      };
}

export function setLayerHideByDefault(layer: Layer, value: boolean, toggleable?: boolean): Layer {
  if (value === false) return { ...layer, hideByDefault: value, toggleable: toggleable as true };
  return { ...layer, hideByDefault: value, toggleable: true };
}

export function setHideMapLayer(layer: Layer): Layer {
  return { ...layer, hideMapLayer: true };
}
