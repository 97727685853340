import { proxy } from "valtio/vanilla";
import { SiteClusterFeature, SiteFeature as SiteFeatureType } from "src/js/api/siteGeometry.types";

export type SiteFeature = SiteFeatureType;

export type SiteGeometryStore = {
  siteFeatures: SiteFeature[];
  clusters: SiteClusterFeature[];
  boundsDistance: number;
  activeRequestAbort?: AbortController["abort"];
  loading: boolean;
};

export const getInitialSiteGeometryStore = (): SiteGeometryStore => ({
  siteFeatures: [],
  clusters: [],
  boundsDistance: 0,
  loading: false,
});

export const siteGeometryStore = proxy<SiteGeometryStore>(getInitialSiteGeometryStore());
