import { saveAs } from "file-saver";
import { RemoteFile } from "../typings/RemoteFile";

export const downloadFile = async (file: RemoteFile) => {
  const result = await fetch(file.url);
  if (!result.ok) {
    return;
  }
  const blob = await result.blob();

  saveAs(blob, file.name);
};
