import { Button } from "@landtechnologies/components";
import { LayerTypeSelectionSidebarProps } from "../../../types";
import { UtilitiesIcon } from "../UtilitiesIcon";
import { UtilitiesList } from "./UtilitiesList";
import { useUtilitiesLayerTypeContext } from "../UtilitiesContext";
import {
  logUtilitiesReportEvent,
  ViewType,
} from "react-migration/domains/sites/components/SiteCard/ReportSidebar/logUtilitiesEvent";
import { useAtkinsOrder } from "react-migration/domains/sites/hooks/useAtkinsOrder";
import { differenceInDays } from "date-fns";
import { useTranslation } from "react-migration/lib/i18n/useTranslation";
import { downloadFile } from "react-migration/domains/sites/helpers/downloadFile";
import { SelectionType } from "src/js/stores/map/store";
import { useSelectionSidebarLoading } from "../../../Bundle/useSelectionSidebarLoadingState";
import { CollapsibleConsiderationsCard } from "../../../Bundle/Workbench/CollapsibleConsiderationsCard";
import hasFeature from "src/js/stores/user/actions/hasFeature";
import { ReportSection } from "react-migration/domains/sites/components/SiteCard/ReportSection";
import Feature from "src/js/stores/user/Feature";
import { useCallback } from "react";
import { updateMapSettings } from "src/js/stores/map/actions/updateMapSettings";
import { setActiveSiteReportUtilityStep } from "src/js/stores/site/actions/setActiveSiteReportUtilityStep";
import { SiteReportUtilitySteps } from "react-migration/domains/sites/typings/SiteUtilityReport";
import { useSiteOverviewQuery } from "@gql/li-api/graphql";
import { routedClient } from "react-migration/lib/persistence/apollo/routedClient";

export const UtilitiesSelectionSidebar = ({
  selection,
  setDetailSelection,
  onLoadingChange,
  cardExpanded,
}: Pick<
  LayerTypeSelectionSidebarProps,
  "layer" | "selection" | "setDetailSelection" | "onLoadingChange" | "cardExpanded"
>) => {
  const { t } = useTranslation();
  const siteId = selection.type === SelectionType.SITE && selection?.id ? selection?.id : "";
  const { data: atkinsOrderQueryData, loading } = useAtkinsOrder({ siteId });
  useSelectionSidebarLoading(onLoadingChange, loading);

  const { utilitiesFeatures } = useUtilitiesLayerTypeContext();

  const handleShowSiteUtilityReport = useCallback((isShown: boolean) => {
    updateMapSettings("showSiteUtilityReport", isShown);
    if (!isShown) {
      setActiveSiteReportUtilityStep(SiteReportUtilitySteps.Overview);
    }
  }, []);

  const { data } = useSiteOverviewQuery({
    variables: { _id: siteId },
    client: routedClient,
    skip: !siteId,
  });

  const atkinsOrderId = atkinsOrderQueryData?.atkinsOrder.atkinsOrderId;
  const signedReportUrl = atkinsOrderQueryData?.atkinsOrder.signedReportUrl;

  const numberOfDaysSincePurchase = atkinsOrderQueryData?.atkinsOrder.createdAt
    ? differenceInDays(new Date(), new Date(Number(atkinsOrderQueryData?.atkinsOrder.createdAt)))
    : 0;

  const purchaseDateText =
    numberOfDaysSincePurchase > 0
      ? t("sites.card.site_card.purchased_number_of_days_ago", {
          numberOfDays: numberOfDaysSincePurchase,
        })
      : t("sites.card.site_card.purchased_less_than_a_day_ago");

  const downloadReport = async () => {
    logUtilitiesReportEvent({
      name: "Utilities Report - Download Report Clicked",
      context: { viewType: ViewType.MAP },
    });

    const url = atkinsOrderQueryData?.atkinsOrder.signedReportUrl;
    const orderId = atkinsOrderQueryData?.atkinsOrder.atkinsOrderId;
    if (!url) return;
    const fileReference = {
      url,
      name: `utilities-report-order-${orderId}.pdf`,
    };

    await downloadFile(fileReference);
  };

  if (!data?.siteOverview) return null;

  return (
    <CollapsibleConsiderationsCard
      name="Utilities Report"
      heading="Utilities Report"
      defaultExpanded={cardExpanded}
      label="New"
    >
      <div className="atlas-flex atlas-flex-col atlas-gap-y-2 atlas-p-2">
        {hasFeature(Feature.siteUtilitiesReports) && (
          <ReportSection onButtonClick={() => handleShowSiteUtilityReport(true)} />
        )}
        {signedReportUrl && (
          <div className="atlas-flex atlas-gap-x-3 atlas-p-2" data-testid="download-report">
            <UtilitiesIcon />
            <div>
              <p className="atlas-text-content-secondary">
                {t("sites.card.site_card.order_number", { orderNumber: atkinsOrderId })}
                {" - "}
                {purchaseDateText}
              </p>
              <Button.Secondary
                onClick={downloadReport}
                size="large"
                data-testid="utilities-sidebar-download-report-button"
              >
                {t("sites.utility_report.download_report")}
              </Button.Secondary>
            </div>
          </div>
        )}
        {!!utilitiesFeatures?.length && (
          <UtilitiesList features={utilitiesFeatures} setDetailSelection={setDetailSelection} />
        )}
      </div>
    </CollapsibleConsiderationsCard>
  );
};
