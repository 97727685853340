import { useState } from "react";
import { useQuery } from "@apollo/client";
import { routedClient } from "react-migration/lib/persistence/apollo";
import PAGINATION_QUERY from "./useSitePaginationQuery.gql";
import { SiteEdge } from "./types";

interface PaginationQueryData {
  siteEdges: SiteEdge[];

  siteCount: {
    _sites: {
      totalCount: number;
    };
  };
}

interface PaginationQueryVariables {
  siteId?: string;
  stageId?: string;
}

export function useSitePaginationQuery(queryVariables: PaginationQueryVariables) {
  const [paginationState, setPaginationState] = useState<{ edges: SiteEdge[]; count: number }>({
    edges: [],
    count: 0,
  });

  const edgesQuery = useQuery<PaginationQueryData, PaginationQueryVariables>(PAGINATION_QUERY, {
    variables: queryVariables,
    skip: !queryVariables.siteId || !queryVariables.stageId,
    client: routedClient,
    fetchPolicy: "no-cache",
    onCompleted: ({ siteCount, siteEdges }) =>
      // want to cache last result even when query vars change
      setPaginationState({
        edges: siteEdges,
        count: siteCount?._sites.totalCount,
      }),
  });

  return {
    ...edgesQuery,
    ...paginationState,
  };
}
