import { ScatterplotLayer, ScatterplotLayerProps } from "@deck.gl/layers";
import { compsFeatureTypeGetters, getPosition, toPercentileGroups } from "./comparablesLayerUtil";
import { PPSFT_COLOR_FILL_RGB } from "../../../util/Colors";
import { Color } from "@deck.gl/core";
import { MaskExtension } from "@deck.gl/extensions";
import Feature from "src/js/stores/user/Feature";
import { CorePriceModes } from "react-migration/domains/comparables/typings/PriceModes";
import { ComparableTile } from "@landtechnologies/comparables-services";
import getPermissions from "src/js/stores/user/actions/getPermissions";
import hasBetaFeature from "src/js/stores/user/actions/hasBetaFeature";

export function pricePerAreaLayers(
  priceMode: CorePriceModes,
  distribution: number[],
  data: ComparableTile[],
  getSubLayerProps: (i: number) => ScatterplotLayerProps<ComparableTile>
) {
  const geofenceGeometries = getPermissions()?.geofencesGeometries;
  const maskExtensions = [] as MaskExtension[];
  let maskId: string | undefined;

  if (geofenceGeometries?.length && !hasBetaFeature(Feature.disableGeofence)) {
    maskExtensions.push(new MaskExtension());
    maskId = "Geofence";
  }
  const percentileGroups = toPercentileGroups(
    compsFeatureTypeGetters[priceMode],
    distribution,
    data
  );
  const visible = /price_per/.test(priceMode);
  return percentileGroups
    .map(
      (data: ComparableTile[], i: number) =>
        new ScatterplotLayer<ComparableTile>({
          ...getSubLayerProps(i),
          extensions: maskExtensions,
          // @ts-expect-error maskId is not defined in types
          maskId,
          pickable: true,
          data,
          opacity: 1,
          stroked: true,
          filled: true,
          lineWidthUnits: "pixels",
          lineWidthMaxPixels: 1,
          lineWidthMinPixels: 1,
          lineWidthScale: 1,
          radiusUnits: "pixels",
          radiusScale: 1,
          radiusMinPixels: 1,
          radiusMaxPixels: 12,
          getPosition,
          getRadius: 12,
          getFillColor: PPSFT_COLOR_FILL_RGB[i] as Color,
          getLineColor: [255, 255, 255],
          getLineWidth: 1,
          visible,
          updateTriggers: {
            visible,
          },
        })
    )
    .reverse();
}
