export const TitleIcon = ({ className }: { className: string }) => {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      width="344px"
      height="344px"
      viewBox="311.5 106.998 344 344"
      enableBackground="new 311.5 106.998 344 344"
      xmlSpace="preserve"
      className={className}
    >
      <g>
        <path
          d="M519.618,137.23H377.262v283.534h212.477V207.352L519.618,137.23z M530.222,162.825l33.923,33.927h-33.923V162.825z
		 M579.139,410.165H387.861V147.83h131.757v59.521h59.517v202.813H579.139z"
        />
      </g>
      <g>
        <rect x="407.712" y="173.272" width="74.207" height="10.604" />
      </g>
      <g>
        <rect x="407.712" y="195.538" width="74.207" height="10.604" />
      </g>
      <g>
        <path
          d="M556.139,398.426V219H407.712v179.426H556.139z M510.198,241.962c-0.664,5.073-5.743,9.612-13.474,12.549
		c-1.768,0.534-6.186,0.535-10.382,0.802c-0.883,0-1.326,0-2.208,0v-28.837l18.995,0.001
		C508.21,232.083,510.86,237.423,510.198,241.962z M480.821,255.579c-8.614,0.267-17.228,1.602-21.204,6.408
		c-0.442,0.535-0.883,1.068-0.882,1.604c-1.105,1.869-1.988,5.072-0.442,9.613c2.651,7.475,11.265,11.479,22.75,14.149
		l-0.002,20.294l-51.684-0.002c-0.662-7.741-0.221-15.752,0.663-23.762c1.325-18.155,3.092-36.847-10.602-57.139h61.845
		L480.821,255.579z M480.82,263.056v16.02c-11.927-2.67-15.901-6.407-16.785-9.078c-0.663-1.869-0.222-2.402-0.001-2.668
		C465.581,264.39,473.974,263.322,480.82,263.056z M549.954,343.424c-2.208,8.009-8.613,7.742-19.215,6.676
		c-7.731-0.803-15.683-1.871-21.646,3.203c-0.662,0.8-1.547,1.336-2.209,2.137c-3.976,4.806-6.404,11.48-6.847,18.958
		c-0.221,5.606,0.662,11.479,2.871,16.285h-18.994l0.221-55.269c1.987-2.403,3.754-4.539,5.522-6.676
		c2.649-3.204,3.534-2.671,7.288-0.267c2.429,1.336,5.742,3.202,9.938,3.471c4.859,0,10.16,2.136,15.683,4.539
		c8.393,3.738,17.892,7.741,27.167,4.005L549.954,343.424z M524.553,329.271c-5.962-2.403-11.705-5.071-17.448-5.071
		c-2.87-0.268-5.3-1.602-7.508-2.672c-3.978-2.135-8.836-4.805-14.356,1.87c-0.443,0.535-0.884,1.068-1.105,1.337v-13.353
		l65.819,0.002v20.825C542.003,336.481,533.389,333.011,524.553,329.271z M481.043,328.472
		c-9.721,11.215-18.333,19.491-27.168,18.958c-5.3-0.536-10.381-4.539-15.46-12.283c-5.081-8.277-7.952-16.021-8.836-24.03h51.244
		L481.043,328.472z M483.914,307.379V287.62c7.287,1.334,15.019,2.135,22.97,2.671c1.104,0.267,1.988,0.266,2.65-0.003
		c8.615,1.337,12.147-4.003,14.8-8.276c1.987-2.935,3.312-4.537,5.742-4.805c3.975,3.737,14.576,11.214,19.657,14.151v16.021
		H483.914z M423.614,283.081c-0.662,8.277-1.324,16.019-0.883,24.563l-8.834-0.001l-0.001-76.896
		C426.487,249.171,425.162,265.725,423.614,283.081z M423.396,311.116c1.104,9.344,3.974,18.691,10.38,28.569
		c6.185,9.612,12.59,14.686,19.878,15.487c9.939,0.8,19.216-7.21,27.388-16.022l0.001,51.799h-67.146v-79.566L423.396,311.116z
		 M510.64,390.949c-2.872-4.004-4.417-9.611-4.198-15.752c0.222-6.143,2.651-11.748,6.406-15.219
		c3.976-3.206,10.602-2.671,17.449-1.87c6.626,0.535,14.136,1.602,19.657-2.402v35.778L510.64,390.949z M549.954,283.08
		c-5.521-3.47-14.577-10.146-16.124-12.014l-0.883-1.068l-1.104-0.268c-6.185-0.534-9.497,4.006-11.928,7.477
		c-2.43,3.471-4.195,6.142-9.496,5.608c-0.885-0.002-1.768-0.001-2.873-0.267c-9.717-0.536-17.228-1.604-23.411-2.669l-0.221-16.824
		c0.882,0,1.767,0,2.208,0.001c5.301,0,9.497-0.268,12.148-1.336c10.381-4.003,16.786-10.679,17.891-18.423
		c0.662-4.004,0.222-9.877-4.64-16.82h38.433V283.08z"
        />
      </g>
    </svg>
  );
};
