import { useQuery } from "@apollo/client";
import { routedClient } from "react-migration/lib/persistence/apollo/routedClient";
import GET_PAGED_TRANSACTIONS from "../queries/pagedTransactions.gql";
import { router } from "src/js/router/react-router";
import { PaginatedTransactionsResponse } from "../../typings/TransactionsResponse";

type UsePagedTransactionsQueryParams = {
  page: number;
  perPage: number;
  search?: string;
  kind: string[];
};
export const usePagedTransactionsQuery = ({
  page,
  perPage,
  search,
  kind,
}: UsePagedTransactionsQueryParams) => {
  return useQuery<PaginatedTransactionsResponse>(GET_PAGED_TRANSACTIONS, {
    client: routedClient,
    variables: {
      page,
      perPage,
      search,
      kind,
    },
    onCompleted: (data) => {
      if (data.pagedTransactions.nodes.length === 0 && page > 0) {
        router.push({
          query: {
            page: "1",
          },
        });
        location.reload();
      }
    },
    // notifyOnNetworkStatusChange: true,
    fetchPolicy: "cache-first",
  });
};
