import { useMemo } from "react";
import { Company } from "react-migration/domains/ownership/typings/Company";
import { Person } from "react-migration/domains/ownership/typings/Person";
import { TitleOwnership } from "react-migration/domains/ownership/typings/Title";

export type PersonOwner = TitleOwnership & { person: Person };
export type CompanyOwner = TitleOwnership & { company: Company };

export function useSelectedOwner(owners?: TitleOwnership[], selectedOwner?: string) {
  return useMemo(() => {
    const person = owners?.find((owner) => owner.person?.name === selectedOwner);
    if (person) return person as PersonOwner;

    const company = owners?.find((owner) => owner.company?.name === selectedOwner);
    if (company) return company as CompanyOwner;

    return;
  }, [owners, selectedOwner]);
}

export function isCompanyOwner(owner?: PersonOwner | CompanyOwner): owner is CompanyOwner {
  return !!owner?.company;
}

export function isPersonOwner(owner?: PersonOwner | CompanyOwner): owner is PersonOwner {
  return !!owner?.person;
}
