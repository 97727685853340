import { v4 } from "uuid";
import { isDefaultFilter } from "./isDefaultFilter";
import { filters } from "../components/FilterList/SearchFilters";
import { ISearchFilter } from "../typings/Filters";

const createDefaultFilters = (searchType: string) => {
  return filters
    ?.map((f) => {
      if (isDefaultFilter(searchType, f)) {
        return {
          ...f,
          index: v4(),
        };
      }
    })
    .filter((f) => f !== undefined) as ISearchFilter[];
};

const defaultFilters: Record<string, ISearchFilter[]> = {};
export const getDefaultFilters = (searchType: string) => {
  if (!defaultFilters[searchType]) {
    defaultFilters[searchType] = createDefaultFilters(searchType);
  }
  return defaultFilters[searchType];
};

export const updateDefaultFilters = (searchType: string) => {
  defaultFilters[searchType] = createDefaultFilters(searchType);
  return defaultFilters[searchType];
};
