import Feature from "src/js/stores/user/Feature";
import { ConstraintsCategory } from "../../Constraints/types";
import { ConstraintsLayerType } from "../layer_types/ConstraintsLayerType";
import { Layer } from "../types";

export const SHLAA: Layer = {
  id: ConstraintsCategory.SHLAA,
  layerType: ConstraintsLayerType,
  title: "Land Availability Assessments",
  toggleable: true,
  requiredFeatureAccess: [Feature.planningPolicyLayer],
  description: "Suitability of land potentially available for housing development",
  hideByDefault: true,
  layerConfig: {
    rootCategory: ConstraintsCategory.SHLAA,
    categorySchema: [
      { key: "shlaa_positive" },
      { key: "shlaa_neutral" },
      { key: "shlaa_negative" },
      { key: "brownfield_land" },
    ],
  },
};
