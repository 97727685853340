import { useMutation } from "@apollo/client";
import UPDATE_PLANNING_ALERT_SUBSCRIPTION from "../apollo/mutations/updatePlanningAlertSubscription.gql";
import {
  LandTechEndpoints,
  routedClient,
} from "react-migration/lib/persistence/apollo/routedClient";
import { UpdatePlanningAlertSubscriptionResponse } from "../../typings/UpdatePlanningAlertSubscriptionResponse";

export const useUpdatePlanningAlertSubscriptionMutation = () => {
  return useMutation<UpdatePlanningAlertSubscriptionResponse>(UPDATE_PLANNING_ALERT_SUBSCRIPTION, {
    client: routedClient,
    context: {
      endpoint: LandTechEndpoints.PlanningService,
    },
  });
};
