import { useCallback, useMemo } from "react";
import { isNumber } from "lodash";
import {
  DesignationFeature,
  defaultDesignationStyleAccessors,
} from "react-migration/domains/constraints/components/ConstraintLayer/DesignationLayer";
import { TRANSPARENT } from "react-migration/domains/constraints/designation/style/colours";
import { LayerTypeMapLayerProps } from "../../types";
import { SimplifyProfile } from "../ConstraintsLayerType";
import { CompositeDesignationLayerWrapper } from "./CompositeDesignationLayerWrapper";
import { getAttributeNumericValue } from "./utils";
import { DemographicsLayerConfig } from "./DemographicsLayerType";
import { useDemographicsContext } from "./DemographicsProvider";
import { DEMOGRAPHIC_STYLE_MAP } from "./constants";
import { isDefined } from "react-migration/lib/util/isDefined";
import { DemographicsPickingInfo } from "./types";

export function DemographicsMapLayer({ zOrder, layer, visible }: LayerTypeMapLayerProps) {
  const layerConfig = layer.layerConfig as DemographicsLayerConfig;
  const featureIsVisible = useCallback(() => true, []);

  const { selectedAttribute } = useDemographicsContext();
  const { selectedStyleOption, designationAttributes, accessors } = useAttributeStyles(
    selectedAttribute,
    layerConfig
  );

  const pickInfoMeta: DemographicsPickingInfo["meta"] = useMemo(
    () => ({ selectedAttribute }),
    [selectedAttribute]
  );

  const subCategoryConditions =
    selectedStyleOption?.customConditions || layerConfig.subCategoryConditions;

  if (!visible) return null;

  return (
    <CompositeDesignationLayerWrapper
      layerId={layer.id}
      designationAttributes={designationAttributes}
      minZoom={0}
      featureIsVisible={featureIsVisible}
      showLabels
      zOrder={zOrder}
      simplifyProfile={SimplifyProfile.LOW}
      styleAccessors={accessors}
      categoryConditions={subCategoryConditions}
      pickInfoMeta={pickInfoMeta}
    />
  );
}

function useAttributeStyles(selectedAttribute: string, layerConfig: DemographicsLayerConfig) {
  const selectedStyleOption = useMemo(
    () => layerConfig.attributes_style_options.find((a) => a?.attribute === selectedAttribute),
    [selectedAttribute, layerConfig]
  );

  const designationAttributes = useMemo(
    () =>
      layerConfig.attributes_style_options
        .filter((option) => option?.customConditions === selectedStyleOption?.customConditions)
        .map((c) => c?.attribute)
        .filter(isDefined),
    [layerConfig, selectedStyleOption]
  );

  const selectedStyle = selectedStyleOption?.style;

  const styleOverride = selectedStyle && DEMOGRAPHIC_STYLE_MAP[selectedStyle];

  const accessors = useMemo(
    () =>
      styleOverride
        ? {
            ...defaultDesignationStyleAccessors,
            getDesignationFillColour: (designation: DesignationFeature) => {
              const value = getAttributeNumericValue(
                designation.properties.designation_attributes,
                selectedAttribute
              );

              if (!isNumber(value)) {
                return TRANSPARENT;
              }

              return styleOverride.fillColor(value);
            },
            getDesignationLineColour: styleOverride.lineColor,
          }
        : defaultDesignationStyleAccessors,
    [selectedAttribute, styleOverride]
  );
  return { selectedStyleOption, designationAttributes, accessors };
}
