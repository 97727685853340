import { LtIcons } from "@landtechnologies/icons/font/lt-icons";
import clsx from "clsx";
import { ButtonHTMLAttributes, forwardRef } from "react";

type IconAnimation = "clockwise";

export type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & {
  disabled?: boolean;
  leadIcon?: `${LtIcons}`;
  leadIconAnimation?: IconAnimation;
  tailIcon?: `${LtIcons}`;
  tailIconAnimation?: IconAnimation;
  variant?: "primary" | "secondary" | "tertiary" | "ghost" | undefined;
};

const primaryStyles = clsx(
  "atlas-text-white atlas-bg-button-primary-base atlas-ring-platform-200",
  "enabled:hover:atlas-bg-button-primary-hover",
  "enabled:active:atlas-bg-button-primary-pressed",
  "enabled:focus:atlas-ring-2",
  "disabled:atlas-bg-button-primary-disabled disabled:atlas-text-blueGrey-400"
);

const secondaryStyles = clsx(
  "atlas-text-platform-950 atlas-bg-platform-50 atlas-ring-platform-600 atlas-ring-2",
  "enabled:hover:atlas-bg-platform-100 hover:atlas-ring-platform-700",
  "enabled:active:atlas-bg-platform-200 active:atlas-ring-platform-800",
  "enabled:focus:atlas-ring-platform-200",
  "disabled:atlas-bg-white disabled:atlas-ring-platform-100 disabled:atlas-text-platform-200"
);
const tertiaryStyles = clsx(
  "atlas-text-platform-950 atlas-bg-neutral-1 atlas-ring-platform-200",
  "enabled:hover:atlas-bg-neutral-2",
  "enabled:active:atlas-bg-neutral-3",
  "enabled:focus:atlas-ring-2",
  "disabled:atlas-bg-white disabled:atlas-text-platform-300"
);
const ghostStyles = clsx(
  "atlas-bg-transparent atlas-ring-platform-200/20",
  "enabled:hover:atlas-bg-platformBlue-50",
  "enabled:active:atlas-bg-platformBlue-100",
  "enabled:focus:atlas-ring-2",
  "disabled:atlas-text-platform-300/40"
);

const variants = {
  primary: primaryStyles,
  secondary: secondaryStyles,
  tertiary: tertiaryStyles,
  ghost: ghostStyles,
};

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      children,
      variant = "primary",
      className,
      leadIcon,
      tailIcon,
      leadIconAnimation,
      tailIconAnimation,
      ...rest
    },
    ref
  ) => {
    return (
      <button
        className={clsx(
          "atlas-inline-flex atlas-items-center atlas-justify-center atlas-relative atlas-isolate atlas-shadow-sm atlas-gap-x-2 atlas-px-3 atlas-py-2 atlas-rounded-lg atlas-text-sm ring-inset",
          variants[variant],
          className,
          {
            "atlas-cursor-pointer": !rest.disabled,
          }
        )}
        ref={ref}
        {...rest}
      >
        {leadIcon && <ButtonIcon icon={leadIcon} animation={leadIconAnimation} />}
        {children}
        {tailIcon && <ButtonIcon icon={tailIcon} animation={tailIconAnimation} />}
      </button>
    );
  }
);

interface ButtonIconProps {
  icon: `${LtIcons}`;
  animation?: IconAnimation;
}

function ButtonIcon({ icon, animation }: ButtonIconProps) {
  return (
    <i
      className={clsx("atlas-text-lg atlas-leading-5", `icon-${icon}`, {
        "atlas-animate-spin": animation === "clockwise",
      })}
    />
  );
}

Button.displayName = "Button";
