import * as RadixCheckbox from "@radix-ui/react-checkbox";
import cn from "clsx";
import { CheckLineThick, SubtractLineThick } from "./Icons";

export const INDETERMINATE = "indeterminate";
type RadixCheckboxProps = RadixCheckbox.CheckboxProps;

interface BaseProps {
  name?: RadixCheckboxProps["name"];
  disabled?: RadixCheckboxProps["disabled"];
  required?: RadixCheckboxProps["required"];
  size?: "sm" | "md" | "lg";
  id?: RadixCheckboxProps["id"];
}

interface BinaryCheckboxProps extends BaseProps {
  supportsIndeterminate?: false;
  checked?: boolean;
  onCheckedChange?: (v: boolean) => void;
}

interface TertiaryCheckboxProps extends BaseProps {
  supportsIndeterminate: true;
  checked?: RadixCheckboxProps["checked"];
  onCheckedChange?: RadixCheckboxProps["onCheckedChange"];
}

type CheckboxProps = BinaryCheckboxProps | TertiaryCheckboxProps;

/**
 * Checkbox component that wraps Radix UI Checkbox with additional props.
 *
 * This component supports two modes:
 * - Binary mode: When `supportsIndeterminate` is `false` or not provided, the checkbox behaves as a standard binary checkbox.
 * - Tertiary mode: When `supportsIndeterminate` is `true`, the checkbox can be in an indeterminate state.
 */
export function Checkbox({
  id,
  name,
  checked,
  disabled,
  required,
  size = "md",
  supportsIndeterminate,
  onCheckedChange,
}: CheckboxProps) {
  const value = supportsIndeterminate ? checked : checked === true;

  const IndicatorComponent = getIndicatorComponent(checked);

  return (
    <RadixCheckbox.Root
      id={id}
      name={name}
      disabled={disabled}
      checked={value}
      required={required}
      onCheckedChange={onCheckedChange}
      className={cn("atlas-rounded atlas-ring-1", {
        // Size
        "atlas-w-4 atlas-h-4": size === "sm",
        "atlas-w-5 atlas-h-5": size === "md",
        "atlas-w-6 atlas-h-6": size === "lg",
        // State
        "atlas-bg-white atlas-ring-blueGrey-200 hover:atlas-ring-blueGrey-300 disabled:atlas-bg-blueGrey-300 disabled:atlas-ring-blueGrey-300":
          value === false,
        "atlas-bg-platformBlue-700 atlas-ring-platformBlue-700 hover:atlas-ring-platformBlue-800 hover:atlas-bg-platformBlue-800 disabled:atlas-bg-blueGrey-300 disabled:atlas-ring-blueGrey-300":
          value === true || value === INDETERMINATE,
        "atlas-cursor-not-allowed": disabled,
      })}
    >
      <RadixCheckbox.Indicator className="atlas-flex atlas-items-center atlas-justify-center">
        {IndicatorComponent && (
          <IndicatorComponent
            className={cn("atlas-text-white", {
              "atlas-w-3": size === "sm",
              "atlas-w-4": size === "md",
              "atlas-w-5": size === "lg",
            })}
          />
        )}
      </RadixCheckbox.Indicator>
    </RadixCheckbox.Root>
  );
}

function getIndicatorComponent(checked?: boolean | "indeterminate") {
  if (checked === true) return CheckLineThick;
  if (checked === INDETERMINATE) return SubtractLineThick;
  return null;
}
