import { groupBy } from "lodash";
import { useMemo } from "react";
import { CollapsibleConsiderationsCard } from "react-migration/layouts/map/Multilayer/Bundle/Workbench/CollapsibleConsiderationsCard";
import { TenureType } from "react-migration/domains/ownership/typings/Tenure";
import { OwnershipSelectionSidebarHeaderUK } from "../../OwnershipSelectionSidebar/OwnershipSelectionSidebarUK";
import { TitleWithOwnerNames } from "../../hooks/useTitlesWithOwnerNamesQuery";
import { TenureDropdown } from "../TenureDropdown";
import { useHoverableOwnersLayer } from "./useHoverableOwnersLayer";
import { DataPanel } from "react-migration/components/DataPanel";

interface RelatedOwnersProps {
  titlesWithOwnerNames: TitleWithOwnerNames[];
  setSelectedOwner(ownerName?: string): void;
  selectedTitleNo?: string;
  withCard?: boolean;
}

export function RelatedOwners({
  titlesWithOwnerNames,
  selectedTitleNo,
  setSelectedOwner,
  withCard = false,
}: RelatedOwnersProps) {
  const { FREEHOLD, LEASEHOLD, UNKNOWN_OTHER } = useMemo(
    () => getOwnersByTenure(titlesWithOwnerNames),
    [titlesWithOwnerNames]
  );

  const { setHoveredOwner } = useHoverableOwnersLayer(titlesWithOwnerNames);

  const titleHasOwners = hasTenure(FREEHOLD) || hasTenure(LEASEHOLD) || hasTenure(UNKNOWN_OTHER);

  if (!titleHasOwners) return null;

  const columns = [hasTenure(FREEHOLD), hasTenure(LEASEHOLD), hasTenure(UNKNOWN_OTHER)].filter(
    Boolean
  ).length;

  const body = (
    <div className="atlas-bg-white atlas-m-2">
      <DataPanel.Grid columns={columns}>
        {hasTenure(FREEHOLD) && (
          <TenureDropdown
            selectedTitleNumber={selectedTitleNo}
            setSelectedOwner={setSelectedOwner}
            setHoveredOwner={setHoveredOwner}
            tenure={TenureType.FREEHOLD}
            owners={FREEHOLD}
          />
        )}
        {hasTenure(LEASEHOLD) && (
          <TenureDropdown
            selectedTitleNumber={selectedTitleNo}
            setSelectedOwner={setSelectedOwner}
            setHoveredOwner={setHoveredOwner}
            tenure={TenureType.LEASEHOLD}
            owners={LEASEHOLD}
          />
        )}
        {hasTenure(UNKNOWN_OTHER) && (
          <TenureDropdown
            selectedTitleNumber={selectedTitleNo}
            setSelectedOwner={setSelectedOwner}
            setHoveredOwner={setHoveredOwner}
            tenure={TenureType.UNKNOWN_OTHER}
            owners={UNKNOWN_OTHER}
          />
        )}
      </DataPanel.Grid>
    </div>
  );

  if (withCard) {
    return (
      <CollapsibleConsiderationsCard
        heading={<OwnershipSelectionSidebarHeaderUK />}
        name="Ownership Information"
        defaultExpanded
      >
        {body}
      </CollapsibleConsiderationsCard>
    );
  } else {
    return body;
  }
}

type TitlesByOwner = Record<string, TitleWithOwnerNames[]>;
type OwnersByTenure = Record<TenureType, TitlesByOwner>;

function getOwnersByTenure(titlesWithOwnerNames: TitleWithOwnerNames[]) {
  const titlesByTenure = Object.entries(
    groupBy(titlesWithOwnerNames, (x) => x.tenure || TenureType.UNKNOWN_OTHER)
  ) as [TenureType, TitleWithOwnerNames[]][];

  return Object.fromEntries(
    titlesByTenure.map(([tenure, titles]) => {
      const TitlesByOwnerMap = new Map<string, TitleWithOwnerNames[]>();

      for (const title of titles) {
        for (const ownerName of title.ownerNames) {
          TitlesByOwnerMap.set(ownerName, [...(TitlesByOwnerMap.get(ownerName) || []), title]);
        }
      }

      return [tenure, Object.fromEntries(TitlesByOwnerMap.entries())];
    })
  ) as OwnersByTenure;
}

function hasTenure(tenure: TitlesByOwner) {
  return !!tenure && !!Object.values(tenure).length;
}
