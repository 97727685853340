import { useCallback } from "react";
import { SiteFeature } from "src/js/stores/siteGeometry/store";
import { ClusterFeature } from "./types";
import { isSiteBoundary } from "./utils";
import { mapStore, SelectionFeature } from "src/js/stores/map/store";
import { useSnapshot } from "valtio";

export type FeatureIsVisible = (feature: SiteFeature | ClusterFeature) => boolean;

function featureIsHidden(feature: unknown) {
  return isSiteBoundary(feature) && feature.properties.hidden;
}

export function useFeatureIsVisible(detailSelection?: SelectionFeature) {
  const {
    settings: { showHiddenSites },
  } = useSnapshot(mapStore);

  return useCallback<FeatureIsVisible>(
    (feature) => {
      if (!showHiddenSites && featureIsHidden(feature)) return false;
      if (!detailSelection) return true;

      return detailSelection.id === feature.id;
    },
    [detailSelection, showHiddenSites]
  );
}
