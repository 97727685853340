import { AllGeoJSON } from "@turf/helpers";
import { MeasurementSystem } from "src/js/stores/user/store";
import { area as areaConverter, length } from "src/js/util/units";
import turfArea from "@turf/area";
import turfLength from "@turf/length";

export type MeasurementVariants = [string, string];

export function getGeometryMeasurements(
  geometry: AllGeoJSON,
  measurementSystem: MeasurementSystem
) {
  if (!geometry) return;

  return {
    areaVariants: getAreaVariants(turfArea(geometry), measurementSystem),
    perimeterVariants: getLengthVariants(
      turfLength(geometry, { units: "meters" }),
      measurementSystem
    ),
  } as const;
}

type GetAreaVariants = (
  areaInMeters: number | string | undefined,
  measurementSystem: MeasurementSystem
) => MeasurementVariants;

export const getAreaVariants: GetAreaVariants = (areaInMeters, measurementSystem) => [
  areaConverter.format(Number(areaInMeters), { unitType: measurementSystem, small: true }),
  areaConverter.format(Number(areaInMeters), { unitType: measurementSystem, small: false }),
];

type GetLengthVariants = (
  perimeterInMeters: number | string | undefined,
  measurementSystem: MeasurementSystem
) => MeasurementVariants;

export const getLengthVariants: GetLengthVariants = (lengthInMeters, measurementSystem) => [
  length.format(Number(lengthInMeters), { unitType: measurementSystem, small: true }),
  length.format(Number(lengthInMeters), { unitType: measurementSystem, small: false }),
];
