import { useMutation } from "@apollo/client";
import { Button, Modal } from "@landtechnologies/components";

import { removeSiteFeatureById } from "src/js/stores/siteGeometry/actions/removeSiteFeatures";
import { default as siteOverviewQuery } from "react-migration/domains/sites/apollo/li-api/queries/siteOverview.gql";
import { useTranslation } from "react-migration/lib/i18n/useTranslation";
import { routedClient } from "react-migration/lib/persistence/apollo";
import { logEventV2 } from "react-migration/lib/util/logEventV2";

import { default as archiveSiteQuery } from "./archiveSite.gql";

export interface DeleteSiteButtonProps {
  siteId: string;
  onCancel: () => void;
  onDelete?(): void;
}

export const DeleteSiteModal = ({ siteId, onCancel, onDelete }: DeleteSiteButtonProps) => {
  const { t } = useTranslation();

  const [deleteSite] = useMutation(archiveSiteQuery, {
    client: routedClient,
    onCompleted() {
      removeSiteFeatureById(siteId);
      onDelete?.();
    },
    update(cache, { data, errors }) {
      if (!errors || errors.length === 0) {
        const siteId = data.archiveSite.archived_items[0]._id;

        cache.writeQuery({
          query: siteOverviewQuery,
          variables: { _id: siteId },
          data: { siteOverview: null },
        });
      }
    },
    variables: { id: siteId },
  });

  return (
    <Modal
      title={t("components.modal.titles")}
      description={t("sidebar.site.are_you_sure_delete_site")}
    >
      <div className="atlas-flex atlas-justify-end atlas-gap-2">
        <Button.Danger
          onClick={() => {
            logEventV2({ name: "Sites layer button click", properties: { type: "delete" } });
            deleteSite();
          }}
        >
          Delete
        </Button.Danger>
        <Button.Secondary onClick={onCancel}>Cancel</Button.Secondary>
      </div>
    </Modal>
  );
};
