import {
  Alert,
  ClipboardCopy,
  InfoList,
  Loading,
  Section,
  TextWithVariants,
} from "@landtechnologies/components";
import { FC, ReactNode, useMemo } from "react";
import { useTranslation } from "react-migration/lib/i18n/useTranslation";
import { getAreaVariants, getLengthVariants } from "react-migration/lib/util/getAreaAndPerimeter";
import { PropertyInfoItem } from "./PorpertyInfoItem";
import { PropertySaleValue } from "../PropertySaleValue";
import { getMeasurementSystemPreference } from "src/js/stores/user/actions/getSetting";
import { usePropertyInformationByUPRNQuery } from "../../apollo/usePropertyInformationByUPRNQuery";

interface PropertyInfoDetailedProps {
  propertyId: string;
  parcelId?: string;
  isUnitInfo?: boolean;
  TitleElement?: ReactNode;
}

const DefaultTitle = ({ isUnitInfo }: { isUnitInfo?: boolean }) => {
  const { t } = useTranslation();
  return (
    <InfoList.Title>
      {isUnitInfo
        ? t("ownership.react.map.sidebar.unit_info.title")
        : t("ownership.react.map.sidebar.property_info.title")}
    </InfoList.Title>
  );
};

export const PropertyInfoDetailed: FC<PropertyInfoDetailedProps> = ({
  propertyId,
  parcelId,
  isUnitInfo,
  TitleElement,
}) => {
  const { t } = useTranslation();

  const unitPreference = getMeasurementSystemPreference();

  const { propertyInformation, loading: propertyInfoLoading } =
    usePropertyInformationByUPRNQuery(propertyId);

  const Title = useMemo(
    () => TitleElement ?? <DefaultTitle isUnitInfo={isUnitInfo} />,
    [TitleElement, isUnitInfo]
  );

  if (!propertyInformation && !propertyInfoLoading)
    return (
      <div className="atlas-px-2">
        <Alert.Info>
          {isUnitInfo
            ? t("ownership.react.map.sidebar.property_info.unit_info_not_found")
            : t("ownership.react.map.sidebar.property_info.info_not_found")}
        </Alert.Info>
      </div>
    );

  const {
    area_building_metric,
    area_lot_width_metric,
    area_lot_metric,
    coverage,
    floor_area_ratio,
  } = propertyInformation?.dimensions || {};

  const getBuildingAreaCoverageText = (coverage: number | null | undefined) =>
    coverage && `(${(coverage * 100).toFixed(2)}%)`;

  const displayYearBuilt = () => propertyInformation?.year_built || t("not_applicable.long");
  const displayFloorRatio = () =>
    floor_area_ratio ? floor_area_ratio.toFixed(2) : t("not_applicable.long");

  const displayVacantLot = () => {
    if (propertyInformation?.vacant_lot == null) {
      return t("not_applicable.long");
    }

    return propertyInformation.vacant_lot.toLowerCase().includes("vacant land") ? "Yes" : "No";
  };

  return (
    <Section>
      <InfoList data-testid="property-info-detailed">
        {Title}
        {propertyInfoLoading ? (
          <div className="atlas-pt-4 atlas-pb-2">
            <Loading />
          </div>
        ) : (
          <>
            <PropertyInfoItem
              title={t("property_information.sidebar.address")}
              testId="property-address"
            >
              {propertyInformation?.address.full_address}
            </PropertyInfoItem>
            <PropertySaleValue uprn={propertyId} />

            <PropertyInfoItem
              title={
                isUnitInfo
                  ? t("property_information.sidebar.unit_size")
                  : t("property_information.sidebar.lot_size")
              }
              testId="lot-size"
            >
              <TextWithVariants variants={getAreaVariants(area_lot_metric, unitPreference)} />
            </PropertyInfoItem>
            <PropertyInfoItem
              title={
                isUnitInfo
                  ? t("property_information.sidebar.unit_width")
                  : t("property_information.sidebar.lot_width")
              }
              testId="lot-width"
            >
              {area_lot_width_metric ? (
                <TextWithVariants
                  data-testid="lot-width-toggle"
                  variants={getLengthVariants(area_lot_width_metric, unitPreference)}
                />
              ) : (
                t("not_applicable.long")
              )}
            </PropertyInfoItem>
            <PropertyInfoItem
              title={t("property_information.sidebar.building_area")}
              testId="total-building-floor-area"
            >
              {area_building_metric ? (
                <TextWithVariants
                  variants={getAreaVariants(area_building_metric, unitPreference)}
                />
              ) : (
                t("not_applicable.long")
              )}{" "}
              {area_building_metric && getBuildingAreaCoverageText(coverage)}
            </PropertyInfoItem>
            <PropertyInfoItem
              title={t("property_information.sidebar.floor_area_ratio")}
              testId="floor-area-ratio"
            >
              {displayFloorRatio()}
            </PropertyInfoItem>
            <PropertyInfoItem
              title={t("property_information.sidebar.year_built")}
              testId="surface-year-built"
            >
              {displayYearBuilt()}
            </PropertyInfoItem>
            <PropertyInfoItem
              title={t("property_information.sidebar.vacant_lot_title")}
              testId="property-vacant-lot"
            >
              {displayVacantLot()}
            </PropertyInfoItem>
            {parcelId && (
              <InfoList.Item>
                <InfoList.ItemTitle>
                  {t("ownership.react.map.sidebar.property_info.parcel_id_title")}
                </InfoList.ItemTitle>
                <InfoList.ItemValue>
                  <ClipboardCopy text={parcelId} />
                </InfoList.ItemValue>
              </InfoList.Item>
            )}
          </>
        )}
      </InfoList>
    </Section>
  );
};
