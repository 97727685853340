import { PropsWithChildren } from "react";
import classNames from "classnames";

interface PlaceholderProps extends PropsWithChildren {
  className?: string;
  "data-testid"?: string;
}

export const Placeholder = ({
  className,
  children,
  "data-testid": dataTestId,
}: PlaceholderProps) => {
  return (
    <div
      className={classNames(
        "atlas-bg-neutral-100",
        "atlas-border-neutral-400",
        "atlas-border",
        "atlas-rounded",
        "atlas-flex",
        "atlas-items-center",
        "atlas-justify-center",
        "atlas-border-dashed",
        className
      )}
      data-testid={dataTestId || "letter-placeholder"}
    >
      {children}
    </div>
  );
};
