import Feature from "src/js/stores/user/Feature";
import { userStore } from "src/js/stores/user/store";
import { useSnapshot } from "valtio";

export function useFeatureFlag(feature: Feature) {
  const { user } = useSnapshot(userStore);

  return user.features.includes(feature);
}

export function useBetaFeatureFlag(feature: Feature) {
  const { user } = useSnapshot(userStore);

  return user.beta_features.includes(feature);
}
