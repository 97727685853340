import clsx from "clsx";

import { Button } from "react-migration/components/Button/Button";
import { useTranslation } from "react-migration/lib/i18n/useTranslation";

export const SummaryCollapse = ({
  isExpanded,
  setIsExpanded,
}: {
  isExpanded: boolean;
  setIsExpanded: (v: boolean) => void;
}) => {
  const { t } = useTranslation();

  return (
    <>
      <div
        data-testid="fade-over-text"
        className={clsx(
          "atlas-w-full atlas-absolute atlas-top-0 atlas-pointer-events-none",
          "atlas-bg-gradient-to-b atlas-from-transparent atlas-to-white atlas-transition-opacity atlas-ease-out atlas-duration-1000",
          isExpanded ? "atlas-opacity-0 atlas-h-60" : "atlas-opacity-100 atlas-h-36"
        )}
      />
      <div
        data-testid="collapse-summary-wrapper"
        className="atlas-flex atlas-flex-col atlas-w-full atlas-items-center atlas-justify-center atlas-p-2 atlas-neutral-900 atlas-font-medium"
      >
        <Button
          data-testid="collapse-summary-button"
          variant="ghost"
          tailIcon={isExpanded ? "lt-arrow-up-line" : "lt-arrow-down-line"}
          onClick={() => setIsExpanded(!isExpanded)}
        >
          {isExpanded ? t("planning.ai.collapse") : t("planning.ai.show")}
        </Button>
      </div>
    </>
  );
};
