import classNames from "classnames";
import { Controller, useFormContext } from "react-hook-form";
import { useTranslation } from "react-migration/lib/i18n/useTranslation";
import { useSnapshot } from "valtio";
import { updateIsUploadingLogo } from "src/js/stores/letter/actions";
import { SignatureBlock } from "./SignatureBlock";
import { motion } from "framer-motion";
import { Banner } from "react-migration/domains/sites/components/Banner";
import { SiteLetterImageUpload } from "./SiteLetterImageUpload";
import { TextInput } from "react-migration/components/TextInput";
import { Switch, Textarea } from "@landtechnologies/components";
import { LetterEventName, logLetterEvent } from "../../../letters/helpers/logLetterEvent";
import { TemplateFormData } from "../TemplateFormDataSchema";
import React, { PropsWithChildren } from "react";
import { letterStore } from "src/js/stores/letter/store";
import { animationVariants } from "../style/animationVariants";
import { labelClassNames, sectionTitleClass } from "../style/labelClassNames";

interface AccordionAnimationProps {
  keyName: string;
}

export const AccordionAnimationContainer = ({
  keyName,
  children,
}: PropsWithChildren<AccordionAnimationProps>) => {
  return (
    <motion.div
      key={keyName}
      data-testid="template-form-page-accordion-content"
      initial={animationVariants.collapsed}
      animate={animationVariants.open}
      variants={animationVariants}
      transition={{ duration: 0.3, ease: "easeInOut" }}
    >
      {children}
    </motion.div>
  );
};

export const FirstPageSection = () => {
  const { isUploadingLogo } = useSnapshot(letterStore);
  const { t } = useTranslation();

  const {
    register,
    control,
    formState: { errors, defaultValues },
  } = useFormContext<TemplateFormData>();

  return (
    <AccordionAnimationContainer keyName={"first-page-section-content"}>
      <div data-testid="template-form-page-one-accordion-content">
        <div
          className={classNames(
            "atlas-pt-4",
            "atlas-border-b",
            "atlas-border-border-divider",
            "atlas-px-3",
            "atlas-pb-4"
          )}
          data-testid="first-page-content-letter-head"
        >
          <p className={classNames(sectionTitleClass)}>
            {t("sites.letters.letter_form.letterhead")}
          </p>

          {isUploadingLogo && (
            <Banner
              showIcon
              variant="warning"
              message={t("sites.letters.letter_form.please_wait_for_the_image", {
                label: "logo",
              })}
              className="atlas-my-2"
            />
          )}

          <Controller
            render={({ field, fieldState: { error } }) => (
              <div>
                <span className={classNames(labelClassNames, error && "atlas-text-red-700")}>
                  {t("sites.letters.letter_form.company_logo")}
                </span>
                <SiteLetterImageUpload
                  field={field}
                  onUploadStateChange={updateIsUploadingLogo}
                  data-testid="logo-upload"
                />
              </div>
            )}
            name={"logo"}
            control={control}
          />
        </div>
        <div
          className={classNames(
            "atlas-border-b",
            "atlas-pb-4",
            "atlas-border-border-divider",
            "atlas-px-3",
            "atlas-pt-4"
          )}
        >
          <p className={classNames(sectionTitleClass)}>
            {t("sites.letters.letter_form.letter_body")}
          </p>

          <TextInput
            data-testid="greeting-input"
            label={t("sites.letters.letter_form.greeting")}
            labelClassName={classNames(labelClassNames)}
            {...register("greeting")}
            error={(errors.greeting?.message && t(errors.greeting.message as string)) ?? undefined}
          />

          <Controller
            render={({ field }) => (
              <Textarea
                data-testid="bodyText-input"
                placeholder={t("sites.letters.letter_form.main_content")}
                onChange={(e) => {
                  field.onChange && field.onChange(e as unknown as React.ChangeEvent<Element>);
                }}
                label={t("sites.letters.letter_form.main_content")}
                labelClassName={classNames(labelClassNames)}
                className={classNames("atlas-bg-white", "atlas-mb-4")}
                defaultValue={field.value ?? defaultValues?.bodyText}
                error={
                  (errors.bodyText?.message && t(errors.bodyText.message as string)) ?? undefined
                }
              />
            )}
            name={"bodyText"}
            control={control}
          />
        </div>
        <SignatureBlock id="signature-page1" page={1} />
      </div>
    </AccordionAnimationContainer>
  );
};

export const SecondPageSection = () => {
  const { t } = useTranslation();
  const {
    control,
    formState: { errors, defaultValues },
  } = useFormContext<TemplateFormData>();

  return (
    <AccordionAnimationContainer keyName={"second-page-section-content"}>
      <div data-testid="template-form-page-two-accordion-content">
        <Controller
          render={({ field }) => (
            <div
              className={classNames(
                "atlas-flex",
                "atlas-justify-between",
                "atlas-border-b",
                "atlas-border-border-divider",
                "atlas-px-3"
              )}
              data-testid="site-boundary-switch-wrapper"
            >
              <span className={classNames(sectionTitleClass, "atlas-pt-3.5")}>
                {t("sites.letters.letter_form.satellite_image")}
              </span>
              <Switch
                size="small"
                label=""
                showLabel={false}
                id="site-boundary-switch"
                onToggle={() => {
                  if (!field.value) {
                    logLetterEvent({
                      name: LetterEventName.LETTERS_FORM_CREATE_LETTER_STEP_SATELLITE_IMAGE_INSERTED,
                    });
                  }
                  field.onChange(!field.value);
                }}
                value={field.value}
              />
            </div>
          )}
          name={"showSiteBoundary"}
          control={control}
        />
        <div
          className={classNames(
            "atlas-border-b",
            "atlas-border-border-divider",
            "atlas-px-3",
            "atlas-pt-4"
          )}
        >
          <p className={classNames(sectionTitleClass)}>
            {t("sites.letters.letter_form.letter_body")}
          </p>

          <Controller
            render={({ field }) => (
              <Textarea
                data-testid="bodyText-page2"
                placeholder={t("sites.letters.letter_form.main_content")}
                onChange={(e) => {
                  field.onChange && field.onChange(e as unknown as React.ChangeEvent<Element>);
                }}
                label={t("sites.letters.letter_form.main_content")}
                labelClassName={classNames(labelClassNames)}
                className={classNames("atlas-bg-white", "atlas-mb-4")}
                defaultValue={field.value ?? defaultValues?.bodyTextPage2}
                error={
                  (errors.bodyTextPage2?.message && t(errors.bodyTextPage2.message as string)) ??
                  undefined
                }
              />
            )}
            name={"bodyTextPage2"}
            control={control}
          />
        </div>
        <SignatureBlock id="signature-page2" page={2} />
      </div>
    </AccordionAnimationContainer>
  );
};
