import { ISourcingToolSearchFilter } from "react-migration/domains/search/typings/SourcingToolSpecificFilterTypes";
import { SearchType } from "react-migration/domains/search/constants/searchTypes";

export const ZoneTypeFilter: ISourcingToolSearchFilter = {
  id: "zone-type-id",
  type: "checkbox",
  field: "sidebar.react.search_sidebar.zoning",
  must: "MATCH_ANY",
  values: [],
  mustOptions: ["MATCH_ANY", "MATCH_NONE"],
  updateFilterDialogIsVisible: false,
  searchTypes: [SearchType.SOURCING_TOOL_US_FL],
};
