import {
  PlanningApplication,
  PlanningApplicationReference,
} from "react-migration/domains/planning/types";

export function findApplicationReferences(
  text: string,
  applications: PlanningApplication[]
): PlanningApplicationReference[] {
  if (!text) return [];

  const matchesInSummary = applications.flatMap((application) => {
    const searchPattern = new RegExp(application.ref, "gi");
    const matchIterator = text.matchAll(searchPattern);

    return Array.from(matchIterator).map((match) => ({
      planningId: application.id,
      originalRef: match[0],
      calculatedRef: match[0],
      indexInTitle: match.index!,
    }));
  });

  return matchesInSummary.sort((matchA, matchB) => matchA.indexInTitle - matchB.indexInTitle);
}
