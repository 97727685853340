import Feature from "src/js/stores/user/Feature";
import { ConstraintsCategory } from "../../Constraints/types";
import { ConstraintsLayerType } from "../layer_types/ConstraintsLayerType";
import { Layer } from "../types";

export const OTHER_HABITATS: Layer = {
  id: "other_habitats",
  layerType: ConstraintsLayerType,
  title: "Other Protected Habitats",
  requiredFeatureAccess: [Feature.planningPolicyLayer],
  toggleable: true,
  description:
    "Although not classified as irreplaceable, these habitats will still represent a significant constraint for development or opportunity for biodiversity enhancement",
  hideByDefault: true,
  layerConfig: {
    rootCategory: ConstraintsCategory.HABITATS,
    categorySchema: [{ key: "open_mosaic_habitats" }, { key: "priority_habitat_inventory" }],
  },
};
