import { useMemo } from "react";
import type { Nullable } from "src/js/types/Nullable";
import { sanitiseVueObservable } from "react-migration/domains/sites/pipeline/helpers/sanitiseVueObservable";
import type { SelectionGeometry } from "src/js/stores/map/store";

import convex from "@turf/convex";
import { useSnapshot } from "valtio";
import { siteGeometryStore } from "src/js/stores/siteGeometry/store";
import { getFeaturesIntersectingGeometry } from "src/js/stores/siteGeometry/utils/getSitesContainingPoint";

export function useIntersectingSitesInViewport(geometry?: Nullable<SelectionGeometry>) {
  const { siteFeatures } = useSnapshot(siteGeometryStore);

  const sitesInViewport = useMemo(() => siteFeatures.map(sanitiseVueObservable), [siteFeatures]);

  const selectionOutline = useMemo(() => {
    if (geometry?.type === "Point") return geometry;
    if (geometry) return convex(geometry)?.geometry;
  }, [geometry]);

  const sitesIntersectingSelection = useMemo(() => {
    if (!selectionOutline) return [];
    return getFeaturesIntersectingGeometry(sitesInViewport, selectionOutline);
  }, [sitesInViewport, selectionOutline]);

  return sitesIntersectingSelection;
}
