import { useMemo } from "react";
import { feature } from "@turf/helpers";
import type {
  PlanningApplication,
  PlanningApplicationFeature,
} from "react-migration/domains/planning/types";
import { PlanningApplicationGeoJsonLayer } from "react-migration/domains/planning/map/layers/PlanningApplicationLayer/PlanningApplicationLayer";
import { useMapLayer } from "react-migration/lib/map/useMapLayer";
import { LayerTypeSelectionMapLayerProps } from "../../types";
import { usePlanningAppById } from "./usePlanningAppsById";

export function PlanningApplicationsSelectionMapLayer({
  selection,
}: LayerTypeSelectionMapLayerProps) {
  const [planningApplication] = usePlanningAppById(selection.id);

  const planningApplicationSelectedFeature = useMemo(
    () => createSelectionFeature(planningApplication),
    [planningApplication]
  );

  useMapLayer(
    () => ({
      zOrder: Infinity,
      layer: new PlanningApplicationGeoJsonLayer({
        id: "planning-application-selection",
        data: planningApplicationSelectedFeature ?? [],
      }),
    }),
    [planningApplicationSelectedFeature]
  );

  return null;
}

function createSelectionFeature(
  planningApplication: PlanningApplication
): PlanningApplicationFeature | null {
  if (!planningApplication) return null;

  const planningGeometry = planningApplication.boundary || planningApplication.location;

  if (!planningGeometry) return null;

  return feature(planningGeometry, {
    classification: planningApplication?.classification,
    date_received: new Date(planningApplication!.date_received!).getTime(),
    size: planningApplication?.size,
  }) as PlanningApplicationFeature;
}
