import { Recipient } from "react-migration/domains/sites/typings/Recipient";
import { proxy } from "valtio/vanilla";

export type LetterImage = {
  file: File;
  url?: string;
  s3ObjectKey?: string;
};

export type Letter = {
  logo?: LetterImage;
  greeting: string;
  bodyText: string;
  signOff?: string;
  signature?: LetterImage;
  contactName?: string;
  contactEmail?: string;
  contactPhone?: string;
  bodyTextPage2?: string;
  signaturePage?: number;
  showSiteBoundary: boolean;
  vatRegistrationNumber?: string;
  companyRegistrationNumber?: string;
  senderAddressLine1: string;
  senderCity: string;
  senderName: string;
  senderPostcode: string;
  senderProvince: string;
};

export enum LetterStep {
  SelectRecipients,
  TemplateEditor,
  PreviewLetter,
  ConfirmAndSend,
  FinishAndDownload,
  OrderConfirmed,
}

export type LetterStore = {
  selectedStageId: string | null;
  currentStep: LetterStep;
  recipients: Recipient[] | null;
  selectedRecipientIds: string[] | null;
  htmlTemplate: string;
  activeRecipientId: string;
  letter: Letter;
  letterName: string;
  isLetterFormValid: boolean;
  isUploadingLogo: boolean;
  isUploadingSignature: boolean;
  pageCount: number;
  siteBoundaryImageUrlBySiteId: Record<string, string>;
};

export const letterStore = proxy<LetterStore>({
  selectedStageId: null,
  currentStep: LetterStep.SelectRecipients,
  recipients: null,
  selectedRecipientIds: null,
  htmlTemplate: "",
  activeRecipientId: "",
  letter: {
    greeting: "Dear",
    bodyText: "",
    signOff: "Yours sincerely,",
    signaturePage: 1,
    showSiteBoundary: false,
    vatRegistrationNumber: "",
    companyRegistrationNumber: "",
    senderAddressLine1: "",
    senderCity: "",
    senderName: "",
    senderPostcode: "",
    senderProvince: "",
  },
  letterName: "",
  pageCount: 1,
  isLetterFormValid: false,
  isUploadingLogo: false,
  isUploadingSignature: false,
  siteBoundaryImageUrlBySiteId: {},
});
