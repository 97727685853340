import Feature from "src/js/stores/user/Feature";
import { ConstraintsCategory } from "../../Constraints/types";
import { ConstraintsLayerType } from "../layer_types/ConstraintsLayerType";
import { Layer } from "../types";

export const OPPORTUNITY_ZONES: Layer = {
  id: ConstraintsCategory.US_OPPORTUNITY_ZONES,
  layerType: ConstraintsLayerType,
  hideByDefault: true,
  title: "Opportunity Zones",
  requiredFeatureAccess: [Feature.zoningLayer],
  toggleable: true,
  description:
    "Established by the US Department of Treasury and the IRS under the Tax Cuts and Jobs Act of 2017, Qualified Opportunity Zone (QOZ) data is sourced from the Department of Housing and Urban Development (HUD). Opportunity Zones are an economic development tool that allows people to invest in distressed areas in the United States. Their purpose is to spur economic growth and job creation in low-income communities while providing tax benefits to investors.",
  layerConfig: {
    rootCategory: ConstraintsCategory.US_OPPORTUNITY_ZONES,
    categorySchema: [{ key: "us_opportunity_zones" }],
  },
};
