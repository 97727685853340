import Feature from "src/js/stores/user/Feature";
import { ConstraintsCategory } from "../../Constraints/types";
import { ConstraintsLayerType } from "../layer_types/ConstraintsLayerType";
import { Layer } from "../types";

export const POWER_TOWERS_AND_CABLES: Layer = {
  id: ConstraintsCategory.POWER,
  layerType: ConstraintsLayerType,
  title: "Power Towers, Lines & Cables",
  requiredFeatureAccess: [Feature.powerLayer],
  toggleable: true,
  description: "Location of towards, pylons and cables",
  layerConfig: {
    rootCategory: ConstraintsCategory.POWER,
    dedupePoints: false,
    layerRoots: ["power_cables", "power_overhead_lines", "power_towers"],
    categorySchema: [
      { key: "power_cables" },
      { key: "power_overhead_lines" },
      { key: "power_towers" },
    ],
  },
};
