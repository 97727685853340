import * as Dialog from "@radix-ui/react-dialog";
import { HTMLProps, PropsWithChildren } from "react";
import clsx from "clsx";

const Root = ({
  children,
  ...rest
}: HTMLProps<HTMLDivElement> & { defaultOpen?: boolean; onOpenChange?: (v: boolean) => void }) => {
  return <Dialog.Root {...rest}>{children}</Dialog.Root>;
};

const Trigger = ({ children, disabled = false }: PropsWithChildren & { disabled?: boolean }) => {
  return (
    <Dialog.Trigger disabled={disabled} className="disabled:atlas-cursor-not-allowed">
      {children}
    </Dialog.Trigger>
  );
};

type ModalBodyProps = {
  title?: string;
  className?: string;
  contentClassName?: string;
  descriptionClassName?: string;
  hideClose?: boolean;
};
const Body = ({
  title,
  children,
  className,
  contentClassName,
  descriptionClassName,
  hideClose = false,
}: PropsWithChildren<ModalBodyProps>) => {
  return (
    <Dialog.Portal>
      <Dialog.Overlay
        className={clsx(
          "atlas-z-50 atlas-bg-background-map-overlay atlas-bg-opacity-50 atlas-fixed atlas-inset-0",
          className
        )}
      >
        <Dialog.Content
          className={clsx(
            "atlas-z-50 atlas-fixed atlas-top-[50%] atlas-left-[50%] atlas-w-[90vw] md:atlas-max-w-[800px] lg:atlas-max-w-[1200px] atlas-translate-x-[-50%] atlas-translate-y-[-50%] atlas-rounded-[6px] atlas-bg-white focus:atlas-outline-none",
            contentClassName
          )}
          onInteractOutside={(e) => hideClose && e.preventDefault()}
        >
          <Dialog.Title className="atlas-justify-between atlas-flex atlas-p-4 atlas-text-base atlas-bg-neutral-100 atlas-rounded atlas-items-center">
            <div>{title}</div>
            {!hideClose && (
              <Dialog.Close>
                <i className="icon-lt-close-line atlas-text-xl" />
              </Dialog.Close>
            )}
          </Dialog.Title>
          <Dialog.Description asChild={true}>
            <div
              className={clsx(
                "atlas-text-sm atlas-p-4 atlas-overflow-y-auto atlas-max-h-[85vh]",
                descriptionClassName
              )}
            >
              {children}
            </div>
          </Dialog.Description>
        </Dialog.Content>
      </Dialog.Overlay>
    </Dialog.Portal>
  );
};

export const ModalV2 = {
  Root: Root,
  Trigger: Trigger,
  Body: Body,
};
