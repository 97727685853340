import { Position } from "@deck.gl/core";
import {
  ComparableTile,
  ComparableTileProperties,
  ZooplaComparableTile,
} from "@landtechnologies/comparables-services";
import { CurriedFunction1 } from "lodash";
import curry from "lodash/curry";
import identity from "lodash/identity";
import { clamp } from "ramda";
import { AggregatedZooplaComparableTile } from "react-migration/domains/comparables/typings/AggregatedZooplaComparableTile";
import {
  CorePriceModes,
  PerAreaPriceModes,
  PerUnitPriceModes,
} from "react-migration/domains/comparables/typings/PriceModes";
import { modifiers } from "react-migration/lib/util/numberFormat";
import { MeasurementSystem } from "src/js/stores/user/store";
import { createWeakMemo } from "src/js/util/memoization";
import { pricePerMsqToPricePerFtsq } from "src/js/util/units_and_constants";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const emptyArray: any[] = [];
/** Will not current filter zoopla data but is only (at the time of writing) used by LandTech comps layers */
export const memoizedDataFilter = createWeakMemo(function (
  data: ComparableTile[],
  filterFunctions: Array<(c: ComparableTile["properties"]) => boolean>
) {
  return data.filter((item) =>
    filterFunctions.every((filterFunction) => filterFunction(item.properties))
  );
});
const numberFormatter = new Intl.NumberFormat();
export type CompsTypeGetter = (x: ComparableTile) => number | null;
export type CompsTypeHeatmapGetter = (x: ComparableTile["properties"]) => number | null;

export const compsFeatureTypeGetters: Record<CorePriceModes, CompsTypeGetter> = {
  est_price_per_square_metre: (x: ComparableTile) =>
    Number(x.properties.est_price) / Number(x.properties.total_floor_area) || 0,
  est_price: (x: ComparableTile) => Number(x.properties.est_price) || 0,
  price_per_square_metre: (x: ComparableTile) =>
    Number(x.properties.price) / Number(x.properties.total_floor_area) || 0,
  price: (x: ComparableTile) => Number(x.properties.price) || 0,
};

const percentileGroupClamp = clamp(0, 5);
export const toPercentileGroups = createWeakMemo<
  (
    getter: (x: ComparableTile) => number | null,
    d1: number[],
    data: ComparableTile[]
  ) => ComparableTile[][]
>((getter, distribution, data) =>
  data.reduce<ComparableTile[][]>(
    (acc, item) => {
      // use the non-null ! here because ~~null => 0 so it doesn't matter if a null value is returned
      const pricePerArea = getter(item)!;
      const index = pricePerArea
        ? percentileGroupClamp(
            distribution.findIndex(
              (p, i, ps) => pricePerArea >= p && pricePerArea < (ps[i + 1] ?? Infinity)
            ) + 1
          )
        : 0;
      acc[index].push(item);
      return acc;
    },
    [[], [], [], [], [], []]
  )
);

export function toNumber(value: unknown): number {
  const asNumber = Number(value);
  if (isNaN(asNumber)) {
    return 0;
  }
  return asNumber;
}

export const corePriceModeToRawValue: Record<
  CorePriceModes,
  (x: ComparableTileProperties) => number
> = {
  [PerAreaPriceModes.EPPSM]: (props: ComparableTileProperties) =>
    toNumber(props.est_price_per_square_metre),
  [PerAreaPriceModes.PPSM]: (props: ComparableTileProperties) =>
    toNumber(props.price_per_square_metre),
  [PerUnitPriceModes.PRICE]: (props: ComparableTileProperties) => toNumber(props.price),
  [PerUnitPriceModes.EP]: (props: ComparableTileProperties) => toNumber(props.est_price),
};
export const formatPricing = curry(function formatPricing(
  getValue: (x: ComparableTileProperties) => number,
  conversion: (x: number) => number = identity,
  c: ComparableTile
): string {
  const value = conversion(getValue(c.properties));
  const modifier = modifiers.find(({ test }) => test(Number(value)));
  if (!modifier || !value) {
    return "";
  }

  return `${c.properties.country === "GB" ? "£" : "$"}${numberFormatter.format(
    modifier.modifier(value)
  )}${modifier.suffix}`;
},
3);

export const characterSet = [
  "0",
  "1",
  "2",
  "3",
  "4",
  "5",
  "6",
  "7",
  "8",
  "9",
  "£",
  "$",
  ".",
  "k",
  "K",
  "M",
  "m",
  ",",
];
export const getPosition = (
  comp: ComparableTile | AggregatedZooplaComparableTile | ZooplaComparableTile
) => comp.geometry.coordinates as Position;

const ltCompsUnitPrice = formatPricing(corePriceModeToRawValue[PerUnitPriceModes.PRICE])(identity);
const ltCompsEstimatedUnitPrice = formatPricing(corePriceModeToRawValue[PerUnitPriceModes.EP])(
  identity
);
const ltCompsCostPerAreaPrice = formatPricing(corePriceModeToRawValue[PerAreaPriceModes.PPSM]);
const ltCompsEstimatedPerAreaPrice = formatPricing(
  corePriceModeToRawValue[PerAreaPriceModes.EPPSM]
);

export const textAccessors: Record<
  MeasurementSystem,
  Record<CorePriceModes, CurriedFunction1<ComparableTile, string>>
> = {
  imperial: {
    [PerUnitPriceModes.PRICE]: ltCompsUnitPrice,
    [PerUnitPriceModes.EP]: ltCompsEstimatedUnitPrice,
    [PerAreaPriceModes.PPSM]: ltCompsCostPerAreaPrice(pricePerMsqToPricePerFtsq),
    [PerAreaPriceModes.EPPSM]: ltCompsEstimatedPerAreaPrice(pricePerMsqToPricePerFtsq),
  },
  metric: {
    [PerUnitPriceModes.PRICE]: ltCompsUnitPrice,
    [PerUnitPriceModes.EP]: ltCompsEstimatedUnitPrice,
    [PerAreaPriceModes.PPSM]: ltCompsCostPerAreaPrice(identity),
    [PerAreaPriceModes.EPPSM]: ltCompsEstimatedPerAreaPrice(identity),
  },
};
