import { useTranslation } from "react-migration/lib/i18n/useTranslation";

import { InfoTooltip } from "./InfoTooltip";
import { SingleRecipient } from "../../../../forms/SelectRecipientsForm/SingleRecipientValidationSchema";
import clsx from "clsx";

type RecipientColumnProps = {
  recipient: SingleRecipient;
  colKey: keyof SingleRecipient;
};

export const RecipientCell = ({ recipient, colKey }: RecipientColumnProps) => {
  const { t } = useTranslation();

  if (recipient.hasOwnProperty("isGranted") && !recipient.isGranted) {
    return (
      <div className="atlas-relative atlas-p-6">
        <span className="atlas-flex">{recipient[colKey]}</span>
        <div
          className={clsx(
            "atlas-mt-1",
            "atlas-text-xs",
            "atlas-absolute",
            "atlas-left-6",
            "atlas-right-2",

            "atlas-bottom-1"
          )}
        >
          <InfoTooltip
            content={t("sites.letters.recipients.unavailable_for_letter_sending")}
            tooltipContent={t("sites.letters.recipients.owner_details_unavailable")}
            dataTestId="unavailable-for-letter-sending"
          />
        </div>
      </div>
    );
  } else {
    return recipient[colKey];
  }
};
