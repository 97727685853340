import { MeasurementSystem } from "src/js/stores/user/store";

export const metersToFeet = (amountInMeters: number): number => amountInMeters * 3.280839895;
export const metersToKilometers = (amountInMeters: number): number => amountInMeters / 1000;
export const kilometersToMeters = (amountInMeters: number): number => amountInMeters * 1000;
export const metersToMiles = (x: number) => x / 1609.34;
export const milesToMeters = (x: number) => x * 1609.34;
export const metersToWalkingMinutes = (v: number) => v / 80.467;

export const squareMetersToSquareFeet = (amountInSquareMeters: number): number =>
  amountInSquareMeters * 10.76391;
export const squareMetersToHectares = (amountInSquareMeters: number): number =>
  amountInSquareMeters / 10000;
export const squareMetersToAcres = (amountInSquareMeters: number): number =>
  amountInSquareMeters * 0.000247;

export const feetToMeters = (amountInFeet: number) => amountInFeet / 3.280839895;
export const squareFeetToSquareMeters = (amountInSquareFeet: number) =>
  amountInSquareFeet / 10.76391;

export const walkingMinutesToMeters = (v: number) => v * 80.467;

type UnitType = "m" | "m2" | "hectares";

export const measurementValue = (value: number, unitType: UnitType, isImperial: boolean) => {
  let newValue = value;

  if (unitType === "hectares") newValue = squareMetersToHectares(value);

  if (isImperial) {
    if (unitType === "m2") newValue = squareMetersToSquareFeet(value);
    if (unitType === "m") newValue = metersToFeet(value);
    if (unitType === "hectares") newValue = squareMetersToAcres(value);
  }

  return round(newValue);
};

export const unitString = (unitType: UnitType, isImperial: boolean) => {
  if (unitType === "m2") return isImperial ? "ft2" : "m2";
  if (unitType === "m") return isImperial ? "ft" : "m";
  if (unitType === "hectares") return isImperial ? "acres" : "ha";

  return "";
};

export const measurementString = (
  value: number,
  unitType: UnitType,
  isImperial: boolean,
  showMeasurement = true
) =>
  measurementValue(value, unitType, isImperial) +
  (showMeasurement ? unitString(unitType, isImperial) : "");

export const round = (value: number) => Math.round(value * 100) / 100;

interface ConversionConfig {
  unitPreference: MeasurementSystem;
  smallUnitSize: boolean;
}

export const convertDistance = (
  value: number,
  { unitPreference, smallUnitSize = true }: ConversionConfig
) => {
  if (unitPreference === MeasurementSystem.METRIC && smallUnitSize) return value;
  if (unitPreference === MeasurementSystem.METRIC && !smallUnitSize)
    return metersToKilometers(value);
  if (unitPreference === MeasurementSystem.IMPERIAL && smallUnitSize) return metersToFeet(value);
  if (unitPreference === MeasurementSystem.IMPERIAL && !smallUnitSize) return metersToMiles(value);
  return value;
};

/**
 * converts an area in square meters to the user's preferred units
 * @param area in m2
 * @param unitPreference "imperial" | "metric"
 * @returns
 */
export const areaAsUserUnits = (area: number, unitPreference: MeasurementSystem) =>
  unitPreference === MeasurementSystem.IMPERIAL ? squareMetersToSquareFeet(area) : area;

export const convertMetersToUserUnits = convertDistance;
export const convertUserUnitsToMeters = (
  value: number,
  { unitPreference, smallUnitSize = true }: ConversionConfig
) => {
  if (unitPreference === MeasurementSystem.METRIC && smallUnitSize) return value;
  if (unitPreference === MeasurementSystem.METRIC && !smallUnitSize)
    return kilometersToMeters(value);
  if (unitPreference === MeasurementSystem.IMPERIAL && smallUnitSize) return feetToMeters(value);
  if (unitPreference === MeasurementSystem.IMPERIAL && !smallUnitSize) return milesToMeters(value);
  return value;
};
