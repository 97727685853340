import { SingleDesignation } from "react-migration/domains/constraints/typings/applicationTypes/SingleDesignation";
import { OutputAreaAttribute } from "./types";
import { isDefined } from "react-migration/lib/util/isDefined";

const numberFormatter = Intl.NumberFormat();

const dollarFormatter = Intl.NumberFormat(undefined, {
  currency: "USD",
  style: "currency",
  currencyDisplay: "narrowSymbol",
  minimumFractionDigits: 0,
});

const gbpFormatter = Intl.NumberFormat(undefined, {
  currency: "GBP",
  style: "currency",
  currencyDisplay: "narrowSymbol",
  minimumFractionDigits: 0,
});

export type ValueFormatter = (value: number) => string;

const formatSigned =
  (baseFormat: ValueFormatter): ValueFormatter =>
  (v) =>
    `${v >= 0 ? "+" : ""}${baseFormat(v)}`;

export const formatNumber: ValueFormatter = (v) => numberFormatter.format(v);
export const formatDollar: ValueFormatter = (v) => dollarFormatter.format(v);
export const formatGBP: ValueFormatter = (v) => gbpFormatter.format(v);
export const formatPercent: ValueFormatter = (v) => `${formatNumber(v)}%`;
export const formatPercentNormal: ValueFormatter = (v) => formatPercent(v * 100);
export const formatSignedPercentNormal: ValueFormatter = formatSigned(formatPercentNormal);
export const formatPeople: ValueFormatter = (v) => `${formatNumber(v)} people`;
export const formatPerSqMi: ValueFormatter = (v) => `${formatNumber(v)} /sq Mi`;
export const formatPerSqKm: ValueFormatter = (v) => `${formatNumber(v)} /km²`;
export const formatPerHa: ValueFormatter = (v) => `${formatNumber(v)} /ha`;

/**
 * This component takes a set of attirbutes, and a set of designations. The function returns true
 * if one of the required attributes exists on one of the provided designations.
 *
 * @param requiredAttributes The set of attributes which may exist on the provided designation(s), in order for the children to render.
 * @param designations The designation or designations that may contain one of the required attributes
 *
 * @returns boolean
 */

export function hasAnyRequiredAttribute(
  requiredAttributes: OutputAreaAttribute[],
  designation: SingleDesignation
) {
  return requiredAttributes.some(({ attributeKey }) =>
    isDefined(designation.designation_attributes.find((a) => a.key === attributeKey)?.value)
  );
}
