// these unit conerstions are DEPRECATED in favour of util/units
export const msqToFtsq = (x: number) => x * 10.7639;
export const msqToAcres = (x: number) => x * 0.000247105;
export const msqToSqMiles = (x: number) => x / 2589988.1103;
export const msqToHectares = (x: number) => x / 10000;
export const msqToKmSq = (x: number) => x / (1000 * 1000);
export const ftsqToMsq = (x: number) => x / 10.7639;
export const acresToMsq = (x: number) => x * 4046.8564224;
export const sqMilesToMsq = (x: number) => x * 2589988.1103;
export const hectaresToMsq = (x: number) => x * 10000;
export const kmSqToMsq = (x: number) => x * (1000 * 1000);
export const acresToSqft = (x: number) => x * 43560;
export const sqftToAcres = (x: number) => x / 43560;
export const pricePerMsqToPricePerFtsq = (x: number) => x / 10.7639;
export const pricePerFtSqToPricePerMSq = (x: number) => x * 10.7639;
export const mToMiles = (x: number) => x / 1609.34;
export const milesToM = (x: number) => x * 1609.34;
export const milesToKm = (x: number) => x * 1.60934;
export const minsToM = (x: number) => x * 80.467; // walking at 3 miles/hour
export const mToMins = (x: number) => x / 80.467; // walking at 3 miles/hour

// These should be moved to the data directory...

export const MAX_AREA_FILTER_MSQ = 20000; // for ownership slider (custom range has no limit)
// this isn't pointlessly large! We just need something that's bigger than almost any concievable value that will be used
// it can't be infinity otherwise webgl will baulk on the data filter extension
export const POINTLESSLY_LARGE_NUMBER = Math.pow(2, 63);

export const MAX_DATE_FILTER_MONTHS = 60; // 5 Years back
export const DEFAULT_DATE_FILTER_MONTHS = 4;
export const MAX_FLOOR_AREA_FILTER_MSQ = 5000;
export const MAX_COMPS_RADIUS_METERS = 8000;
export const MIN_COMPS_RADIUS_METERS = 10;
export const MAX_SEARCH_RESULTS_SAVED_AS_SITES = 50; //not sure if this is defined elsewhere too??

export const ANNOTATION_COLORS = {
  satellite: "#FFFF00",
  hybrid: "#FFFF00",
  roadmap: "#FF0000",
  "os-default": "#FF0000",
  terrain: "#FF0000",
};

export const ANNOTATION_RADIUS_COLORS = {
  satellite: "#DFDFDF",
  hybrid: "#DFDFDF",
  roadmap: "#4F5354",
  "os-default": "#4F5354",
  terrain: "#4F5354",
};

export const ANNOTATION_COLORS_OTHER = {
  satellite: "#0099ff",
  hybrid: "#0099ff",
  roadmap: "#0099ff",
  "os-default": "#0099ff",
  terrain: "#0099ff",
};

export const PRICE_PER_TITLE_GBP = 3;
