import { feature, polygon } from "@turf/helpers";
import {
  mapStore,
  SelectionFeature,
  SelectionGeometry,
  SelectionType,
} from "src/js/stores/map/store";
import type { ClickSelectionResolver } from "./types";
import { createLandAssembly } from "./layer_types/LandAssembly/utils";

const DEFAULT_GEOMETRY = polygon([
  [
    [-5, 52],
    [-4, 56],
    [-2, 51],
    [-7, 54],
    [-5, 52],
  ],
]).geometry!;

const DEFAULT_PROPERTIES = {
  type: SelectionType.POINT,
};

export function mockSelection(
  geometry: SelectionGeometry = DEFAULT_GEOMETRY,
  properties: Partial<SelectionFeature> = DEFAULT_PROPERTIES
): SelectionFeature {
  return { ...DEFAULT_PROPERTIES, ...properties, feature: feature(geometry) };
}

/**
 * Resolves the click selection as Land Assembly.
 * @param clickResolver The click selection resolver function.
 * @returns The resolved click selection as Land Assembly.
 */
export function landAssemblyClickResolver(
  clickResolver: ClickSelectionResolver
): ClickSelectionResolver {
  return async (...pickInfoArgs) => {
    const selectedFeature = await clickResolver(...pickInfoArgs);
    if (!selectedFeature) return null;

    return createLandAssembly(selectedFeature, mapStore.selection);
  };
}
