import { Recipient } from "../../typings/Recipient";
import { SelectRecipientsFormData } from "./SelectRecipientsFormSchema";

export const mergeFormDataWithRecipientData = (
  recipients: Recipient[],
  formData: SelectRecipientsFormData
): Recipient[] => {
  const updatedRecipients = formData.recipients
    .filter(({ isSelected }) => Boolean(isSelected))
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    .map(({ isSelected: _isSelected, ...formRecipient }) => {
      const recipient = recipients.find((recipient) => recipient._id === formRecipient._id)!;
      const updatedRecipient: Recipient = { ...recipient, ...formRecipient };

      return updatedRecipient;
    });

  return updatedRecipients;
};
