import { useTranslation } from "react-migration/lib/i18n/useTranslation";
import { setShowTopupModal } from "src/js/stores/navigation/actions";

export function InsufficientCredit() {
  const { t } = useTranslation();
  return (
    <div data-testid="insufficient-credit" className="atlas-flex atlas-flex-col atlas-gap-4">
      <strong>{t("title_purchase.error.insufficient_credit.title")}</strong>
      <p>
        {t("title_purchase.error.insufficient_credit.message", {
          topUp: (
            <span
              className="atlas-cursor-pointer atlas-text-blue-600"
              onClick={() => setShowTopupModal(true)}
            >
              {t("title_purchase.error.insufficient_credit.topup")}
            </span>
          ),
        })}
      </p>
    </div>
  );
}
