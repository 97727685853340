import {
  PerUnitPriceModes,
  PriceModes,
  UnitPriceModes,
  ZooplaPricePerUnitModes,
} from "../typings/PriceModes";

export function isPerUnitMode(x: PriceModes): x is UnitPriceModes {
  return (
    x === PerUnitPriceModes.PRICE ||
    x === PerUnitPriceModes.EP ||
    x === ZooplaPricePerUnitModes.RENT_ASKING ||
    x === ZooplaPricePerUnitModes.RENT_ESTIMATE ||
    x === ZooplaPricePerUnitModes.VAL_ESTIMATE ||
    x === ZooplaPricePerUnitModes.VAL_LAST
  );
}
