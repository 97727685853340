import { ModalV2 } from "react-migration/components/ModalV2/ModalV2";
import { useState, useMemo, useRef } from "react";
import { usePurchaseTitleDocumentsMutation } from "./usePurchaseTitleDocuments";
import { PurchaseTitleModalNavigation } from "./components/Navigation";
import {
  NavigationStepType,
  PaymentTypes,
  PurchaseTitleModalProps,
  PurchaseType,
  StepStatus,
} from "./types";
import { PurchaseTitleModalAddress } from "./components/Address";
import { PurchaseTitleModalPurchaseForm } from "./components/PuchaseForm";
import { Address } from "react-migration/domains/dashboard/yourAddresses/typings";
import { PurchaseStatus } from "./components/PurchaseStatus";
import { logEvent } from "react-migration/lib/util/logEvent";
import { getHideTitleDocPurchaseAddressPrompt } from "src/js/stores/user/actions/getSetting";
import { useUserAddressesQuery } from "react-migration/domains/dashboard/yourAddresses/hooks/useUserAddressesQuery";

export const PurchaseTitleModal = ({
  title,
  titlesToPurchase,
  onCancel,
  transactionPurchaseType,
  location,
  purchaseType = PurchaseType.Purchase,
}: PurchaseTitleModalProps) => {
  const [selectedStep, setSelectedStep] = useState<number>(1);
  // we only want to skip the address prompt on the first run of this modal,
  // users should be able to go back to this page to change their addresses
  const [firstRun, setFirstRun] = useState<boolean>(true);
  const [selectedAddress, setSelectedAddress] = useState<Partial<Address>>();
  const [paymentType, setPaymentType] = useState<PaymentTypes>(PaymentTypes.Balance);
  const [hmlrUsername, setHmlrUsername] = useState<string>("");
  const [hmlrPassword, setHmlrPassword] = useState<string>("");
  const addressFormRef = useRef(null);

  const [
    purchaseTitleDocuments,
    {
      error: purchaseTitleDocumentsError,
      data: purchaseTitleDocumentsData,
      loading: purchaseTitleLoading,
    },
  ] = usePurchaseTitleDocumentsMutation();

  const { data: userAddresses, loading: userAddressLoading, refetch } = useUserAddressesQuery();

  useMemo(() => {
    const defaultAddress = (userAddresses?.userAddresses ?? []).find(
      (address) => address.is_default
    );
    if (getHideTitleDocPurchaseAddressPrompt() && defaultAddress && firstRun) {
      setSelectedAddress(defaultAddress);
      setSelectedStep(2);
      setFirstRun(false);
    }
  }, [userAddresses, firstRun]);

  const steps: NavigationStepType[] = [
    {
      id: 1,
      name: "Select Address",
      onClick: () => setSelectedStep(1),
      status: selectedStep === 1 ? StepStatus.Current : StepStatus.Complete,
    },
    {
      id: 2,
      name: "Confirm Payment",
      onClick: () => selectedAddress && setSelectedStep(2),
      status:
        selectedStep === 1
          ? StepStatus.Upcoming
          : selectedStep === 2
          ? StepStatus.Current
          : StepStatus.Complete,
    },
    {
      id: 3,
      name: "Purchased",
      onClick: () =>
        (purchaseTitleDocumentsError || purchaseTitleDocumentsData) && setSelectedStep(3),
      status: selectedStep === 3 ? StepStatus.Current : StepStatus.Upcoming,
    },
  ];

  const handlePurchase = async () => {
    if (selectedAddress) {
      setSelectedStep(3);
      const purchase = await purchaseTitleDocuments({
        variables: {
          title_nos: titlesToPurchase,
          doc_type: transactionPurchaseType,
          address: {
            receiver_name: selectedAddress.receiver_name || "",
            line1: selectedAddress.line1 || "",
            line2: selectedAddress.line2 || "",
            city: selectedAddress.city || "",
            country: selectedAddress.country || "",
            postcode: selectedAddress.postcode || "",
          },
          landRegCredentials:
            paymentType === PaymentTypes.HMLR && hmlrUsername && hmlrPassword
              ? {
                  username: hmlrUsername,
                  password: hmlrPassword,
                }
              : undefined,
        },
      });
      logEvent("Purchase Title Documents", {
        nDocs: titlesToPurchase.length,
        type: transactionPurchaseType,
        purchaseMethod: paymentType,
        location: location,
        // @ts-expect-error message does exist
        error: purchase?.errors?.message,
        state: purchase?.data?.purchaseTitleDocuments?.[0]?.state?.status || "error",
      });
    }
  };

  return (
    <ModalV2.Root defaultOpen={true}>
      <ModalV2.Body title={title} className="!atlas-z-[1000]">
        <div className="atlas-flex md:atlas-h-[500px] atlas-items-stretch atlas-flex-col atlas-text-sm">
          <PurchaseTitleModalNavigation steps={steps} />
          {selectedStep === 1 && (
            <PurchaseTitleModalAddress
              onCancel={onCancel}
              setSelectedStep={setSelectedStep}
              setSelectedAddress={setSelectedAddress}
              userAddresses={userAddresses}
              userAddressLoading={userAddressLoading}
              refetch={refetch}
              addressFormRef={addressFormRef}
            />
          )}
          {selectedStep === 2 && (
            <PurchaseTitleModalPurchaseForm
              onCancel={onCancel}
              titlesToPurchase={titlesToPurchase}
              setSelectedStep={setSelectedStep}
              transactionPurchaseType={transactionPurchaseType}
              purchaseType={purchaseType}
              paymentType={paymentType}
              setPaymentType={setPaymentType}
              hmlrUsername={hmlrUsername}
              setHmlrUsername={setHmlrUsername}
              hmlrPassword={hmlrPassword}
              setHmlrPassword={setHmlrPassword}
              handlePurchase={handlePurchase}
            />
          )}
          {selectedStep === 3 && (
            <PurchaseStatus
              onCancel={onCancel}
              loading={purchaseTitleLoading}
              transaction={purchaseTitleDocumentsData?.purchaseTitleDocuments?.[0]}
              requestError={purchaseTitleDocumentsError}
              setSelectedStep={setSelectedStep}
            />
          )}
        </div>
      </ModalV2.Body>
    </ModalV2.Root>
  );
};
