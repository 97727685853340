import classNames from "classnames";
import { Recipient } from "react-migration/domains/sites/typings/Recipient";
import { useTranslation } from "react-migration/lib/i18n/useTranslation";
import { useSnapshot } from "valtio";
import Feature from "src/js/stores/user/Feature";
import hasFeature from "src/js/stores/user/actions/hasFeature";

import { Placeholder } from "./Placeholder";
import { letterStore } from "src/js/stores/letter/store";

interface MainContentProps {
  showValues?: boolean;
}

export const MainContent = ({ showValues }: MainContentProps) => {
  const { t } = useTranslation();
  const usAccess = hasFeature(Feature.usAccess);
  const sentDate = new Date().toLocaleDateString();
  const {
    letter: { greeting, bodyText },
    recipients,
    activeRecipientId,
  } = useSnapshot(letterStore);

  const { fullName, propertyAddress, title } =
    recipients?.find((recipient: Recipient) => recipient._id === activeRecipientId) || {};

  return (
    <div data-testid="letter-preview-body">
      <div className={classNames("atlas-mt-[76px]")} data-testid="letter-preview-body-date">
        {showValues ? (
          sentDate
        ) : (
          <Placeholder className={classNames("atlas-h-5", "atlas-w-40")}>
            {`{{${t("sites.letters.sent_date")}}}`}
          </Placeholder>
        )}
      </div>

      <div
        className={classNames("atlas-mt-2.5", "atlas-flex", "atlas-flex-row")}
        data-testid="letter-preview-body-greeting"
      >
        <span className="atlas-mr-1">{greeting}</span>
        {showValues ? (
          fullName
        ) : (
          <Placeholder className={classNames("atlas-h-5", "atlas-w-48", "atlas-mr-1")}>
            {`{{${t("sites.letters.full_name")}}}`}
          </Placeholder>
        )}
        <span>,</span>
      </div>

      <div
        className={classNames("atlas-mt-3", "atlas-flex", "atlas-flex-row")}
        data-testid="letter-preview-body-property-address"
      >
        {propertyAddress && (
          <span>
            <span
              className={classNames("atlas-mr-1", "atlas-font-bold", "atlas-whitespace-nowrap")}
            >
              {t("sites.letters.property_address") + ":"}
            </span>
            {showValues ? (
              propertyAddress
            ) : (
              <Placeholder
                className={classNames("atlas-font-normal", "atlas-h-5", "atlas-w-48", "atlas-mr-1")}
              >
                {`{{${t("sites.letters.property_address")}}}`}
              </Placeholder>
            )}
          </span>
        )}
      </div>

      {!usAccess && (
        <div
          className={classNames("atlas-mt-1", "atlas-flex", "atlas-flex-row")}
          data-testid="letter-preview-body-title"
        >
          <span className={classNames("atlas-mr-1", "atlas-font-bold", "atlas-whitespace-nowrap")}>
            {t("sites.letters.title_number") + ":"}
          </span>
          {showValues ? (
            title
          ) : (
            <Placeholder
              className={classNames("atlas-font-normal", "atlas-h-5", "atlas-w-48", "atlas-mr-1")}
            >
              {`{{${t("sites.letters.title_number")}}}`}
            </Placeholder>
          )}
        </div>
      )}

      <div
        className={classNames("atlas-mt-4", "atlas-whitespace-pre-wrap", "atlas-break-words")}
        data-testid="letter-preview-body-content"
      >
        {bodyText}
      </div>
    </div>
  );
};
