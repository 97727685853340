import type { DesignationStyle, DesignationStyleMap, Color } from "./types";
import { groupBy, isEmpty, negate } from "lodash";
import { ENVIRONMENT } from "src/js/util/environment";

const isNotEmpty = negate(isEmpty);

export function generateColor([r, g, b, a]: Color, multiplier: number) {
  const n = 255 / Math.max(r, g, b);
  return [
    Math.floor(r * multiplier * n),
    Math.floor(g * multiplier * n),
    Math.floor(b * multiplier * n),
    a,
  ] as Color;
}

function isColor(input: unknown): input is Color {
  return Array.isArray(input) && input.length >= 3 && input.length <= 4;
}

/**
 * Input:
 * {
 *    "key1, key2": [255, 0, 0],
 *    "key3": { fillColor: [0, 255, 0], fillPattern: "hatch-2x" },
 * }
 * Output:
 * {
 *    "key1": { fillColor: [255, 0, 0] },
 *    "key2": { fillColor: [255, 0, 0] },
 *    "key3": { fillColor: [0, 255, 0], fillPattern: "hatch-2x" },
 * }
 */
export function createStyleMap<K extends string = string>(
  styleMap: Record<K, DesignationStyle | Color>
): DesignationStyleMap {
  const styleEntries = Object.entries(styleMap);
  const styleEntriesFlattened = styleEntries.flatMap(([keys, style]) =>
    keys
      .split(",")
      .map((k) => k.trim())
      .filter(isNotEmpty)
      .map((k) => [k, style])
  );

  if (/local/i.test(ENVIRONMENT.APP_ENV)) {
    Object.entries(groupBy(styleEntriesFlattened, ([key]) => key))
      .filter(([, value]) => value.length > 1)
      .forEach(([key]) => {
        console.warn("Duplicate style key:", key);
      });
  }

  return Object.fromEntries(
    styleEntriesFlattened.map(([key, style]) => [
      key,
      isColor(style) ? { fillColor: style } : style,
    ])
  );
}

export function parseStringToNumber(input: string | undefined | null): number | undefined {
  if (!input) return;

  // Remove special characters like '%', '$', and ','
  const cleanedInput = input.replace(/[%$,]/g, "");

  // Parse the cleaned input to a number
  const parsedNumber = parseFloat(cleanedInput);

  // Check if the parsed number is NaN (Not a Number)
  if (isNaN(parsedNumber)) {
    throw new Error("Invalid input");
  }

  return parsedNumber;
}
