import Feature from "src/js/stores/user/Feature";
import { ConstraintsCategory } from "../../Constraints/types";
import { ConstraintsLayerType } from "../layer_types/ConstraintsLayerType";
import { Layer } from "../types";

export const NEIGHBOURHOOD_PLAN_LAYER: Layer = {
  id: ConstraintsCategory.NEIGHBOURHOOD_PLAN_AREAS,
  layerType: ConstraintsLayerType,
  title: "Neighbourhood Plan Areas",
  requiredFeatureAccess: [Feature.planningPolicyLayer],
  toggleable: true,
  description:
    "Areas where an application has been made for a Neighbourhood Plan under the Localism Act 1990",
  hideByDefault: true,
  layerConfig: {
    rootCategory: ConstraintsCategory.NEIGHBOURHOOD_PLAN_AREAS,
    categorySchema: [
      { key: "neighbourhood_plans_plan_made" },
      { key: "neighbourhood_plans_passed_referendum" },
      { key: "neighbourhood_plans_failed_referendum" },
      { key: "neighbourhood_plans_referendum" },
      { key: "neighbourhood_plans_passed_examination" },
      { key: "neighbourhood_plans_failed_examination" },
      { key: "neighbourhood_plans_examination" },
      { key: "neighbourhood_plans_consultation" },
      { key: "neighbourhood_plans_designated" },
    ],
  },
};
