import { proxy } from "valtio/vanilla";
import { Stage, StageId } from "../../typings/Stage";
import { Site, SiteId } from "../../typings/Site";
import { SitesByStage } from "../../typings/PipelineContextState";
import { TeamMember } from "../../typings/TeamMember";

type OrderedSiteIdsByStageId = Record<StageId, SiteId[]>;
type OrderedStageIds = StageId[];
type StageByStageId = Record<StageId, Stage>;
type SiteBySiteId = Record<SiteId, Site>;

export type SitesPipelineStore = {
  orderedSiteIdsByStageId: OrderedSiteIdsByStageId;
  orderedStageIds: OrderedStageIds;
  stageByStageId: StageByStageId;
  siteBySiteId: SiteBySiteId;
  vueSitesByStage: SitesByStage;
  vueStages: Stage[];
  stageIdLoading?: string;
  exportStageSitesUrls: Record<string, string>;
  teamMembers: TeamMember[];
  pipelineLoading?: boolean;
  totalStageCount: number | null;
  scrollOffsetByStageId: Record<string, number>;
  areSitesLoadingByStageId: Record<string, boolean>;
  setRowHeightsByStageId: Record<string, (rowHeights: number[]) => void>;
  getRowHeightsByStageId: Record<string, () => number[]>;
};

export const defaultValues: SitesPipelineStore = {
  orderedSiteIdsByStageId: {},
  orderedStageIds: [],
  stageByStageId: {},
  siteBySiteId: {},
  vueStages: [],
  vueSitesByStage: {},
  stageIdLoading: undefined,
  exportStageSitesUrls: {},
  teamMembers: [],
  pipelineLoading: false,
  totalStageCount: null,
  scrollOffsetByStageId: {},
  areSitesLoadingByStageId: {},
  setRowHeightsByStageId: {},
  getRowHeightsByStageId: {},
};

export const sitesPipelineStore = proxy<SitesPipelineStore>(defaultValues);

// It would be good to have this reset the store dynamically without explicitly resetting each property
// but TypeScript makes it impossible to assign the given keys to an object dynamically
export function resetSitesPipelineStore(): void {
  sitesPipelineStore.orderedSiteIdsByStageId = {};
  sitesPipelineStore.orderedStageIds = [];
  sitesPipelineStore.stageByStageId = {};
  sitesPipelineStore.siteBySiteId = {};
  sitesPipelineStore.vueStages = [];
  sitesPipelineStore.vueSitesByStage = {};
  sitesPipelineStore.stageIdLoading = undefined;
  sitesPipelineStore.exportStageSitesUrls = {};
  sitesPipelineStore.teamMembers = [];
  sitesPipelineStore.pipelineLoading = false;
  sitesPipelineStore.totalStageCount = null;
  sitesPipelineStore.scrollOffsetByStageId = {};
  sitesPipelineStore.areSitesLoadingByStageId = {};
  sitesPipelineStore.setRowHeightsByStageId = {};
  sitesPipelineStore.getRowHeightsByStageId = {};
}
