import Vue from "vue";
import { proxy } from "valtio";
export type StoreItem = Record<string, unknown>;
export type Stores = { [key: string]: StoreItem };
export class Store {
  _vm: Stores;
  constructor(public stores: Stores) {
    this._vm = new Vue({
      data: {
        ...stores,
      },
    });
  }

  get() {
    return this._vm;
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getNestedValue(obj: Stores | StoreItem | { [key: string]: any }, el: string) {
    const levels = el.split(".");
    let object = obj;
    let i = 0;
    if (levels.length > 1) {
      while (i < levels.length) {
        object = this.getNestedValue(object, levels[i]);
        i++;
      }
      return object;
    } else {
      return object[levels[levels.length - 1]];
    }
  }

  renderValue(value: typeof proxy) {
    if (typeof value === "function") {
      return value();
    }
    return value;
  }

  getReactive(store: string, key: string) {
    return this.renderValue(this.getNestedValue(this.get()[store], key));
  }
  // global is not reactive as it is outside of the vue instance
  // perfect for storing singletons
  getOriginal(store: string, key: string) {
    return this.renderValue(this.getNestedValue(this.stores[store], key));
  }
}
