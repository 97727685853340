import { useMemo, useState } from "react";
import { TitleWithOwnerNames } from "../../hooks/useTitlesWithOwnerNamesQuery";
import { TitleFeature, useTitleFeatures } from "../useTitleFeatures";
import { TITLE_STYLE_MAP } from "../TitlesSection";
import { useHoverableSelectionLayer } from "react-migration/layouts/map/Multilayer/hooks/useHoverableSelectionLayer";
import { isDefined } from "react-migration/lib/util/isDefined";
import { Title } from "react-migration/domains/ownership/typings/Title";

const idAccessor = (f: TitleFeature) => f.properties?.title_no;
const isHovered = (fId: string | undefined, hId: string[]) => !!fId && hId.includes(fId);

export function useHoverableOwnersLayer(titlesWithOwnerNames: TitleWithOwnerNames[]) {
  const [hoveredOwner, setHoveredOwner] = useState<string>();

  const { titleFeatures } = useTitleFeatures(titlesWithOwnerNames);

  const hoveredTitles = useMemo(
    () =>
      titlesWithOwnerNames
        .filter((t) => hoveredOwner && t.ownerNames.includes(hoveredOwner))
        .map((t) => t.title_no)
        .filter(isDefined),
    [hoveredOwner, titlesWithOwnerNames]
  );

  useHoverableSelectionLayer({
    features: titleFeatures as GeoJSON.Feature<GeoJSON.Geometry, Title>[],
    pickable: false,
    hoveredId: hoveredTitles,
    styleMap: TITLE_STYLE_MAP,
    idAccessor,
    isHovered,
  });

  return { hoveredOwner, setHoveredOwner };
}
