import { useTranslation } from "react-migration/lib/i18n/useTranslation";
import { MeasurementSystem, userStore } from "src/js/stores/user/store";
import { SupportedLocale } from "src/js/util/dates";
import { ftsqToMsq, msqToFtsq } from "src/js/util/units_and_constants";
import { filter, identity, map, pipe } from "ramda";
import { getMean, getMeanPerArea } from "./Averages";
import { isPerUnitMode } from "./isPerUnitMode";
import {
  CorePriceModes,
  PerAreaPriceModes,
  PerUnitPriceModes,
  PriceModes,
} from "../typings/PriceModes";
import { Currency, formatToCurrency } from "react-migration/lib/util/numberFormat";
import { RecordType } from "../typings/Record";
import { squareMetersToSquareFeet } from "react-migration/lib/util/conversion";
import { CalculatorResultTypes } from "../typings/ResultTypes";

// eslint-disable-next-line react-hooks/rules-of-hooks
const { t } = useTranslation();
const locale = userStore.locale as SupportedLocale;

interface PricePerUnitAreaProps {
  price: number;
  area: number;
  unitPreference: MeasurementSystem;
}
export const pricePerUnitArea = ({ price, area, unitPreference }: PricePerUnitAreaProps) => {
  if (!area || !price) {
    return 0;
  }

  return unitPreference === MeasurementSystem.IMPERIAL ? price / msqToFtsq(area) : price / area;
};

export function formatPPSM(value: number, unitType: string): string {
  const unitValue = unitType === MeasurementSystem.METRIC ? value : ftsqToMsq(value);
  if (unitValue < 1e3) {
    return String(Math.round(unitValue));
  } else if (unitValue < 1e5) {
    return (unitValue / 1e3).toFixed(1) + "k";
  }
  return String(Math.round(unitValue / 1e3)) + "k";
}

interface FormattedPriceProps extends React.HTMLProps<HTMLDivElement> {
  area: number;
  currency: string;
  priceMode: PriceModes;
  price: number;
  unitPreference: MeasurementSystem;
}

export const formattedPrice = ({
  priceMode,
  price,
  area,
  unitPreference,
  currency,
}: FormattedPriceProps) => {
  if (isPerUnitMode(priceMode)) {
    return formatToCurrency({ amount: price, currency: currency as Currency, locale });
  } else {
    return t("comparables.ppu", {
      price:
        formatToCurrency({
          amount: pricePerUnitArea({
            area,
            price,
            unitPreference,
          }),
          locale,
          currency: currency as Currency,
        }) || "-",
      unit:
        unitPreference === MeasurementSystem.METRIC
          ? t("comparables.sqm.alt")
          : t("comparables.sqft.alt"),
    });
  }
};

const filters: Record<CorePriceModes, (transactions: RecordType[]) => RecordType[]> = {
  [PerAreaPriceModes.EPPSM]: filter<RecordType>((t) => !!t.est_price && !!t.total_floor_area),
  [PerAreaPriceModes.PPSM]: filter<RecordType>((t) => !!t.price && !!t.total_floor_area),
  [PerUnitPriceModes.EP]: filter<RecordType>((t) => !!t.est_price),
  [PerUnitPriceModes.PRICE]: filter<RecordType>((t) => !!t.price),
};

interface IValueArea {
  value: number;
  area: number;
}
const getValue: Record<CorePriceModes, (transaction: RecordType) => IValueArea> = {
  [PerAreaPriceModes.EPPSM]: (transaction) => ({
    value: transaction.est_price as number,
    area: transaction.total_floor_area as number,
  }),
  [PerAreaPriceModes.PPSM]: (transaction) => ({
    value: transaction.price as number,
    area: transaction.total_floor_area as number,
  }),
  [PerUnitPriceModes.EP]: (transaction) => ({
    value: transaction.est_price as number,
    area: transaction.total_floor_area as number,
  }),
  [PerUnitPriceModes.PRICE]: (transaction) => ({
    value: transaction.price as number,
    area: transaction.total_floor_area as number,
  }),
};

/**
 * These should only get called when using imperial units as all landtech values are metric
 */
const toImperial: Record<CorePriceModes, (value: IValueArea) => IValueArea> = {
  [PerAreaPriceModes.EPPSM]: ({ value, area }) => ({
    value: value,
    area: squareMetersToSquareFeet(area),
  }),
  [PerAreaPriceModes.PPSM]: ({ value, area }) => ({
    value: value,
    area: squareMetersToSquareFeet(area),
  }),
  [PerUnitPriceModes.EP]: identity,
  [PerUnitPriceModes.PRICE]: identity,
};

/**
 * maps an LT price mode to always be a cost/area price mode
 * @param priceMode
 * @returns
 */
export function getPerAreaPriceMode(priceMode: CorePriceModes) {
  const conversionMap: Record<CorePriceModes, PerAreaPriceModes> = {
    [PerUnitPriceModes.EP]: PerAreaPriceModes.EPPSM,
    [PerUnitPriceModes.PRICE]: PerAreaPriceModes.PPSM,
    [PerAreaPriceModes.EPPSM]: PerAreaPriceModes.EPPSM,
    [PerAreaPriceModes.PPSM]: PerAreaPriceModes.PPSM,
  };
  return conversionMap[priceMode];
}
/**
 * changes any core price mode to an equivalent last value price mode
 * @param priceMode ZooplaPriceModes
 */
export function getSoldPriceMode(priceMode: CorePriceModes): CorePriceModes {
  const conversionMap: Record<CorePriceModes, CorePriceModes> = {
    [PerUnitPriceModes.EP]: PerUnitPriceModes.PRICE,
    [PerUnitPriceModes.PRICE]: PerUnitPriceModes.PRICE,
    [PerAreaPriceModes.EPPSM]: PerAreaPriceModes.PPSM,
    [PerAreaPriceModes.PPSM]: PerAreaPriceModes.PPSM,
  };

  return conversionMap[priceMode];
}

/**
 * changes any core price mode to an equivalent last value price mode
 * @param priceMode CorePriceModes
 */
export function getMarketValuePriceMode(priceMode: CorePriceModes): CorePriceModes {
  const conversionMap: Record<CorePriceModes, CorePriceModes> = {
    [PerUnitPriceModes.PRICE]: PerUnitPriceModes.EP,
    [PerUnitPriceModes.EP]: PerUnitPriceModes.EP,
    [PerAreaPriceModes.PPSM]: PerAreaPriceModes.EPPSM,
    [PerAreaPriceModes.EPPSM]: PerAreaPriceModes.EPPSM,
  };

  return conversionMap[priceMode];
}

export const validPriceResults = (
  transactions: RecordType[],
  priceMode: CorePriceModes,
  mode: CalculatorResultTypes
): RecordType[] => {
  if (mode === CalculatorResultTypes.ALL) {
    return transactions;
  }
  const valuePriceMode =
    mode === CalculatorResultTypes.SOLD
      ? getSoldPriceMode(priceMode)
      : getMarketValuePriceMode(priceMode);
  return filters[valuePriceMode](transactions);
};

export const soldPriceAverage = (
  transactions: RecordType[],
  priceMode: CorePriceModes,
  units: MeasurementSystem
): number => {
  const soldValuePriceMode = getSoldPriceMode(priceMode);
  return pipe<[RecordType[]], RecordType[], IValueArea[], IValueArea[], number>(
    filters[soldValuePriceMode],
    map(getValue[soldValuePriceMode]),
    units === MeasurementSystem.IMPERIAL ? map(toImperial[soldValuePriceMode]) : identity,
    priceMode === PerAreaPriceModes.EPPSM || priceMode === PerAreaPriceModes.PPSM
      ? getMeanPerArea
      : getMean
  )(transactions);
};

export const marketValueAverage = (
  transactions: RecordType[],
  priceMode: CorePriceModes,
  units: MeasurementSystem
): number => {
  const marketValuePriceMode = getMarketValuePriceMode(priceMode);
  return pipe<[RecordType[]], RecordType[], IValueArea[], IValueArea[], number>(
    filters[marketValuePriceMode],
    map(getValue[marketValuePriceMode]),
    units === MeasurementSystem.IMPERIAL ? map(toImperial[marketValuePriceMode]) : identity,
    priceMode === PerAreaPriceModes.EPPSM || priceMode === PerAreaPriceModes.PPSM
      ? getMeanPerArea
      : getMean
  )(transactions);
};
