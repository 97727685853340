import Feature from "src/js/stores/user/Feature";
import { ConstraintsCategory } from "../../Constraints/types";
import { CollisionFilter, ConstraintsLayerType } from "../layer_types/ConstraintsLayerType";
import { Layer } from "../types";

const amenitiesCollisionFilter: CollisionFilter = {
  maxZoom: 15,
  collisionPriorityMap: {
    sub_category_id: {
      hospitals: 210,
      train_station: 200,
      metro_station: 190,
      supermarkets_and_convenience_stores: 180,
      gp_surgeries: 170,
      clinics: 160,
      pharmacies: 150,
      primary_school: 100,
      primary_school_independent: 100,
      secondary_school: 90,
      secondary_school_independent: 90,
      further_education: 80,
      further_education_independent: 80,
      dentists: 70,
      bus_stop: 1,
    },
    designation_attributes: {
      ofsted_rating: {
        Outstanding: 5,
        Good: 4,
        Independent: 4,
        Inadequate: 3,
        "Requires improvement": 2,
        "Serious Weaknesses": 1,
        "Special Measures": 0,
      },
    },
  },
  collisionGroup: "amenities",
  collisionTestProps: {
    sizeScale: 1.8,
  },
};

export const UK_AMENITIES: Layer = {
  id: ConstraintsCategory.AMENITIES,
  layerType: ConstraintsLayerType,
  title: "Amenities",
  toggleable: true,
  description: "Amenities in the local area",
  hideByDefault: true,
  requiredFeatureAccess: [Feature.amenitiesBundle],
  layerConfig: {
    collisionFilter: amenitiesCollisionFilter,
    rootCategory: ConstraintsCategory.AMENITIES,
    categorySchema: [
      { key: "primary_school" },
      { key: "secondary_school" },
      { key: "further_education" },
      { key: "primary_school_independent" },
      { key: "secondary_school_independent" },
      { key: "further_education_independent" },
      { key: "bus_stop" },
      { key: "train_station" },
      { key: "metro_station" },
      { key: "supermarkets_and_convenience_stores" },
      { key: "gp_surgeries" },
      { key: "pharmacies" },
      { key: "clinics" },
      { key: "dentists" },
      { key: "hospitals" },
    ],
  },
};
