import { ReactNode } from "react";
import classNames from "classnames";

interface TriggerElementProps {
  label: string;
  isOpen?: boolean;
  additionalElements?: ReactNode;
}

export const TemplateFormAccordionTrigger = ({
  label,
  isOpen,
  additionalElements,
}: TriggerElementProps) => {
  return (
    <div
      className={classNames(
        "atlas-flex",
        "atlas-h-12",
        "atlas-items-center",
        "atlas-justify-between",
        "atlas-py-3.5",
        "atlas-border-b",
        "atlas-border-border-divider-subtle"
      )}
      data-testid="template-form-accordion-trigger-wrapper"
    >
      <div className="atlas-flex">
        <i
          className={classNames(
            `icon-lt-arrow-${isOpen ? "down" : "right"}-s-line`,
            "atlas-flex",
            "atlas-text-xl",
            "atlas-text-content-primary",
            "atlas-font-semibold",
            "atlas-px-2.5"
          )}
          data-testid="template-form-accordion-trigger-icon"
        />
        <span
          className={classNames(
            "atlas-flex",
            "atlas-font-semibold",
            "atlas-text-sm",
            "atlas-leading-5",
            "atlas-text-content-primary"
          )}
          data-testid="template-form-accordion-trigger-label"
        >
          {label}
        </span>
      </div>
      {additionalElements}
    </div>
  );
};
