import { SelectionHandler } from "../../types";
import { PlanningApplicationSelectionDetailView } from "./PlanningApplicationSelectionDetailView/PlanningApplicationSelectionDetailView";
import { PlanningApplicationSelectionHeader } from "./PlanningApplicationSelectionHeader";
import { PlanningApplicationsSelectionMapLayer } from "./PlanningApplicationsSelectionMapLayer";
import { PlanningApplicationSelectionTitle } from "./PlanningApplicationSelectionTitle";

export const PlanningApplicationSelectionHandler: SelectionHandler = {
  SelectionTitle: PlanningApplicationSelectionTitle,
  SelectionDetailView: PlanningApplicationSelectionDetailView,
  SelectionHeader: PlanningApplicationSelectionHeader,
  SelectionMapLayer: PlanningApplicationsSelectionMapLayer,
};
