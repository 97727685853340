import { PlanningApplicationFragment } from "apollo/fragments";
import { esSortToGraphQLEsSort, searchToEsSearchInput } from "apollo/search_util";
import { paginateList } from "apollo/util";
import gql from "graphql-tag";
import cloneDeep from "lodash/cloneDeep";
import isEqual from "lodash/isEqual";
import merge from "lodash/merge";
import pick from "lodash/pick";
import zipWith from "lodash/zipWith";
import hasRange from "util/has_range";
import { logEvent } from "react-migration/lib/util/logEvent";
import google from "util/map_loader";
import { googleBoundsToEsBounds } from "util/map_util";
import { date, homes } from "util/units";
import Vue from "vue";
import { ENVIRONMENT } from "./environment";
import { initialiseApolloProvider } from "./apollo";

function validateSearch(search) {
  if (search.composite_terms.and && search.composite_terms.and.every((x) => x.or)) {
    return search;
  } else {
    throw "Invalid planning search";
  }
}

export function containsResidentialFilter(search) {
  return validateSearch(search || { composite_terms: { and: [] } }).composite_terms.and.some((s) =>
    s.or.some((t) => /^residential/i.test(t.search_group_details.name))
  );
}

export function autoNameSearch(search) {
  const names = [];
  if (!search) {
    return "Untitled";
  }
  search = validateSearch(search);
  search.composite_terms.and.forEach((t) =>
    t.or.forEach((tt) => names.push(tt.search_group_details.name))
  );
  return names.length ? names.join(", ") : "Untitled";
}

export function getTitleHighlights(searchResult, options = {}) {
  let title;
  const realTitle = searchResult.result_item.title;
  const highlights = searchResult.es_result.highlight;
  const titleHighlight = highlights && highlights.find((h) => h.fieldName === "title");
  if (titleHighlight) {
    const frags = titleHighlight.values[0].highlightFragments;
    const lastIndex = frags.length - 1;
    let lastPostText = null;
    title = frags.reduce(
      (curr, fragment, i) => {
        if (!options.phraseLimit || i < options.phraseLimit) {
          const highlightEndIndex = fragment.startIndex + fragment.length;
          const fragmentText =
            fragment.openingTag +
            realTitle.substring(fragment.startIndex, highlightEndIndex) +
            fragment.closingTag;
          let preText = "";
          if (fragment.preText !== lastPostText) {
            preText = fragment.preText;
            if (i !== 0 || fragment.startIndex - preText.length > 0) {
              preText = "..." + preText;
            }
          }
          curr += preText + fragmentText + fragment.postText;
          lastPostText = fragment.postText;
          if (
            i === lastIndex &&
            highlightEndIndex + fragment.postText.length < realTitle.length - 1
          ) {
            curr += "...";
          }
        }
        return curr;
      },
      "",
      this
    );
  } else {
    title = realTitle;
    if (options.maxTitleLength && title.length > options.maxTitleLength) {
      title = title.substring(0, options.maxTitleLength) + "...";
    }
  }
  return title;
}

export const getDefaultSearch = () => ({
  composite_terms: {
    and: [],
  },
  must_not: [],
  resi_count_range: {
    from: null,
    to: null,
  },
  date_received_range: {
    from: null,
    to: null,
  },
  geo_filter: null,
});

export function filtersForSearch(search, { showDateReceived = true, locale = "en-GB" }) {
  // for use with search-labels
  if (!search) {
    return [];
  }
  search = validateSearch(search);
  const ret = [];

  search.composite_terms.and.forEach((term) => {
    term.or
      .filter((subterm) => subterm.search_group_details && subterm.search_group_details.id) // ignore invalid subterms
      .forEach((f) =>
        ret.push({
          text: f.search_group_details.name,
          extraText:
            f.search_group_details.isAggregate &&
            "Searching for: " + f.or.map((query) => query.value).join(", "),
          backgroundColor: "#0284C7",
          id: f.search_group_details.id,
        })
      );
  });

  if (hasRange(search.resi_count_range)) {
    ret.push({
      text: homes.format(search.resi_count_range, { range: true }),
      backgroundColor: "#b55e38",
      id: "resi_count_range",
    });
  }

  if (showDateReceived && hasRange(search.date_received_range)) {
    const range = search.date_received_range;
    ret.push({
      text: "Received " + date.format(range, { range: true, locale }),
      backgroundColor: "#579749",
      id: "date_received_range",
    });
  }

  return ret;
}

export function addTermFilter(search, term) {
  // this accepts either suggestions or a raw string (see text_search.vue)
  search = validateSearch(search);
  let group;
  if (!term) {
    return;
  } else if (term.searches) {
    group = {
      search_group_details: {
        id: term.label,
        name: term.label,
        isAggregate: true,
        orSet: term.or_set || null,
      },
      or: [],
    };
    Object.keys(term.searches).forEach((field) => {
      term.searches[field].forEach((value) =>
        group.or.push({
          value,
          field,
          matchType: "match_phrase",
        })
      );
    });
  } else {
    group = {
      search_group_details: {
        id: term.label,
        name: term.label,
        isAggregate: false,
        orSet: null,
      },
      or: [
        {
          value: term.label,
          matchType: "match_phrase",
          field: "all_text",
        },
      ],
    };
  }

  if (
    search.composite_terms.and.some((s) => s.or.some((t) => t.id === group.search_group_details.id))
  ) {
    return; // group already present
  }

  let orSet;
  if (group.search_group_details.orSet) {
    orSet = search.composite_terms.and.find((s) =>
      s.or.some((t) => t.search_group_details.orSet === group.search_group_details.orSet)
    );
  }
  if (!orSet) {
    orSet = { or: [] };
    search.composite_terms.and.push(orSet);
  }
  orSet.or.push(group);
}

const SearchState = Vue.extend({
  apollo: {
    searchResultConnection: {
      query: gql`
        query (
          $search: PlanningSearchInput!
          $search_location: String
          $limit: Int
          $highlight: HighlightInput
          $cursor: String
          $sort: [EsSort]
        ) {
          searchResultConnection: planningSearch(
            search: $search
            search_location: $search_location
            limit: $limit
            highlight: $highlight
            cursor: $cursor
            sort: $sort
          ) {
            totalCount
            nodes {
              result_id
              result_item {
                ...PlanningApplicationFragment
              }
              es_result {
                highlight {
                  values {
                    highlightFragments {
                      startIndex
                      length
                      openingTag
                      closingTag
                      preText
                      postText
                      highlightedText
                    }
                    highlightedValue
                    unhighlightedValue
                  }
                  fieldName
                }
              }

              location {
                coordinates
              }
            }
            pageInfo {
              endCursor
              hasNextPage
            }
          }
        }
        ${PlanningApplicationFragment}
      `,
      variables() {
        return {
          search: this.searchInput,
          search_location: this.searchLocation,
          highlight: {
            pre_tags: ["<em>"],
            post_tags: ["</em>"],
          },
          limit: this.limit,
          sort: esSortToGraphQLEsSort(this.searchSort),
        };
      },
      skip() {
        return !this.currentSearch;
      },
      loadingKey: "searchResultConnectionLoading",
      fetchPolicy: "network-only",
      watchLoading(isLoading) {
        if (!isLoading) {
          this.loadingNewSearch = false;
        }
      },
      client: "planningService",
    },
  },
  data: () => ({
    currentSearch: null,
    searchLocation: null,
    userId: null,
    queuedSearch: getDefaultSearch(),
    siteStages: [],
    highlightedSearchResults: [],
    highlightedSearchResultSources: [],
    selectedSearchResult: null,
    uiState: "search",
    uiStateStack: [], // Keeps track of the previous states
    searchResultConnection: {
      nodes: [],
      pageInfo: {},
    },
    searchResultConnectionLoading: 0,
    loadingNewSearch: false,
    selectedResultSelectSource: null,
    searchSort: [{ date_received: { order: "desc" } }, "_score"],
    readyToLoadMore: true,
    limit: 25,
  }),
  computed: {
    hasResults() {
      return this.searchResultConnection && !!this.searchResultConnection.totalCount;
    },
    searchInput() {
      if (this.currentSearch) {
        return searchToEsSearchInput(this.currentSearch);
      }
      return "";
    },
    hasMoreSearchResults() {
      return this.searchResultConnection && this.searchResultConnection.pageInfo.hasNextPage;
    },
    searchResultList() {
      return this.searchResultConnection.nodes;
    },
    highlightedSearchResultsAndSources() {
      return zipWith(
        this.highlightedSearchResults,
        this.highlightedSearchResultSources,
        (highlightedResult, source) => ({
          highlightedResult,
          source,
        })
      );
    },
    createSiteInputArray() {
      return this.searchResultList
        .filter((r) => !!r.result_item.location?.coordinates?.length) // Filter out results without coords
        .map((r) => {
          const { coordinates } = r.result_item.location;
          return {
            geometry: { type: "Point", coordinates },
            source: {
              planning_search: this.searchInput,
            },
            tidbit: {
              planning_application: {
                ref: r.result_item.ref,
                gss_code: r.result_item.gss_code,
              },
            },
            assignees: [this.userId],
          };
        });
    },
  },
  methods: {
    getBoundsForAllResults() {
      const targetBounds = new google.maps.LatLngBounds();
      this.searchResultList
        .map((result) => {
          const coords = this.resultLatLngGetter(result);
          return coords && new google.maps.LatLng(coords[1], coords[0]);
        })
        .filter((result) => !!result)
        .forEach((latLng) => targetBounds.extend(latLng));
      return targetBounds;
    },
    removeFilter(id) {
      const search = validateSearch(this.queuedSearch);
      if (id === "resi_count_range") {
        this.queuedSearch.resi_count_range = {
          from: null,
          to: null,
        };
      } else if (id === "date_received_range") {
        this.queuedSearch.date_received_range = {
          from: null,
          to: null,
        };
      } else {
        // remove text search
        const orSetIdx = search.composite_terms.and.findIndex((s) =>
          s.or.some((t) => t.search_group_details.id === id)
        );
        if (orSetIdx === -1) {
          return;
        }
        const orSet = search.composite_terms.and[orSetIdx];
        if (orSet.or.length === 1) {
          search.composite_terms.and.splice(orSetIdx, 1);
        } else {
          const idx = orSet.or.findIndex((t) => t.search_group_details.id === id);
          orSet.or.splice(idx, 1);
        }
      }
    },
    addTermFilter(term, exclude) {
      if (exclude) {
        const shouldBlock = {
          match_phrase: {
            all_text: term.label,
          },
        };

        this.queuedSearch.must_not.push(shouldBlock);
      } else {
        addTermFilter(this.queuedSearch, term);
      }
      // this accepts either suggestions or a raw string (see text_search.vue)
    },
    resultLatLngGetter(r) {
      r = (r && r.result_item) || r;
      return r && r.location && r.location.coordinates;
    },
    latLngStringForResult(result) {
      if (result && result.lat && result.lng) {
        return result.lat + "," + result.lng;
      }
      const coords = this.resultLatLngGetter((result && result.result_item) || result);
      return coords && coords[1] + "," + coords[0];
    },
    formatNewQueuedSearchBeforeAssigning(newSearch, oldQueuedSearch) {
      const inputFields = [
        "composite_terms",
        "must_not",
        "date_received_range",
        "geo_filter",
        "resi_count_range",
        "gss_codes",
      ];
      return merge({}, getDefaultSearch(), cloneDeep(pick(newSearch, inputFields)), {
        geo_filter: cloneDeep(oldQueuedSearch.geo_filter || newSearch.geo_filter),
      });
    },
    setQueuedSearch(searchObj) {
      const newQueuedSearch = this.formatNewQueuedSearchBeforeAssigning(
        cloneDeep(searchObj),
        this.queuedSearch
      );
      if (!isEqual(newQueuedSearch, this.queuedSearch)) {
        this.queuedSearch = newQueuedSearch;
      }
      return this.queuedSearch;
    },
    setPlanningTypeSearch(term, previousTerm) {
      if (previousTerm) {
        this.removeFilter(previousTerm.label);
      }

      this.addTermFilter(term);
    },
    search(searchObj, force) {
      if (searchObj) {
        this.setQueuedSearch(searchObj);
      }
      const oldSearch = Object.assign({}, this.currentSearch);
      const newCurrentSearch = this.queuedSearch ? cloneDeep(this.queuedSearch) : null;

      if (!isEqual(this.currentSearch, newCurrentSearch) || force) {
        this.loadingNewSearch = true;
        this.currentSearch = newCurrentSearch;
        if (isEqual(this.currentSearch, oldSearch)) {
          this.$apollo.queries.searchResultConnection.refetch();
        }
      }
    },
    fetchMoreResults() {
      if (!this.readyToLoadMore) {
        return;
      }

      this.readyToLoadMore = false;

      paginateList({
        smartQuery: this.$apollo.queries.searchResultConnection,
        connectionPath: "searchResultConnection",
      }).then(() => {
        setTimeout(() => {
          this.readyToLoadMore = true;
        }, 50);
      });
    },
    isSearchResultHighlighted(result) {
      return !!this.highlightedSearchResults.find((highlighted) => result === highlighted);
    },
    highlightSearchResult(result, source = "list") {
      if (!this.isSearchResultHighlighted(result)) {
        this.highlightedSearchResults.push(result);
        this.highlightedSearchResultSources.push(source);
      }
    },
    removeHighlightingOnSearchResult(result) {
      if (this.isSearchResultHighlighted(result)) {
        const indexesToRemove = this.highlightedSearchResults.reduce((s, highlighted, i) => {
          highlighted === result && s.add(i);
          return s;
        }, new Set());
        this.highlightedSearchResults = this.highlightedSearchResults.filter(
          (_, i) => !indexesToRemove.has(i)
        );
        this.highlightedSearchResultSources = this.highlightedSearchResultSources.filter(
          (_, i) => !indexesToRemove.has(i)
        );
      }
    },
    selectResult(result, source = "list") {
      this.selectedSearchResult = result;
      if (this.selectedSearchResult) {
        this.selectedResultSelectSource = source;
      }
    },
    setUiState(state) {
      this.uiStateStack.push(this.uiState);
      this.uiState = state;
      logEvent(`${this.searchGraphQLEndpoint} click ${state}`);
    },
    popUiState() {
      if (this.uiStateStack.length) {
        this.uiState = this.uiStateStack.pop();
      }
    },
    teardown() {
      this.queryManager.unsubscribeAll();
    },
    setCurrentBounds(bounds) {
      this.queuedSearch.geo_filter = bounds && googleBoundsToEsBounds(bounds);
    },
  },
});

export function createPlanningSearchState({ searchLocation, userId }) {
  return new SearchState({
    apolloProvider: initialiseApolloProvider({
      planningServiceUrl: ENVIRONMENT.PLANNING_SERVICE_V2_URL_EXTERNAL,
    }),
    data: {
      searchLocation,
      userId,
    },
  });
}
