export type TextSearchFilterId =
  | "proprietor-name"
  | "permitted-use-id"
  | "owner-portfolio-address-text-filter-id"
  | "owner-portfolio-text-filter-id";

export enum TextFilterMust {
  EQUAL = "EQUAL",
  CONTAIN = "CONTAIN",
  NOT_CONTAIN = "NOT_CONTAIN",
}

export interface ITextSearchFilter {
  id: TextSearchFilterId;
  text: string;
  must: TextFilterMust;
  type: "text";
}

export type TextFilterMustDTO = keyof typeof TextFilterMust;

export type TextSearchFilterDTO = Omit<ITextSearchFilter, "must"> & { must: TextFilterMustDTO };
