import { letterStore } from "src/js/stores/letter/store";
import { ref } from "valtio";

import { Letter, LetterStep } from "../store";
import { Recipient } from "react-migration/domains/sites/typings/Recipient";

export const addRecipient = (recipient: Recipient) => {
  letterStore.recipients = [...(letterStore.recipients ?? []), recipient];
};

export const editRecipient = (updatedRecipient: Recipient) => {
  letterStore.recipients =
    letterStore.recipients?.map((recipient) => {
      return recipient._id !== updatedRecipient._id ? recipient : updatedRecipient;
    }) ?? [];
};

export const loadRecipients = (recipients: Recipient[]) => {
  letterStore.recipients = recipients;
};

export const updateHtmlTemplate = (template: string) => {
  letterStore.htmlTemplate = template;
};

export const clearStore = () => {
  letterStore.selectedStageId = null;
  letterStore.htmlTemplate = "";
  letterStore.recipients = [];
  letterStore.selectedRecipientIds = [];
  letterStore.activeRecipientId = "";
  letterStore.letter = {
    greeting: "Dear",
    bodyText: "",
    signOff: "Yours sincerely,",
    signaturePage: 1,
    showSiteBoundary: false,
    senderAddressLine1: "",
    senderCity: "",
    senderName: "",
    senderPostcode: "",
    senderProvince: "",
  };
  letterStore.pageCount = 1;
  letterStore.letterName = "";
  letterStore.currentStep = LetterStep.SelectRecipients;
  letterStore.isLetterFormValid = false;
  letterStore.isUploadingLogo = false;
  letterStore.isUploadingSignature = false;
  letterStore.siteBoundaryImageUrlBySiteId = {};
};

export const updateSelectedRecipientIds = (ids: (string | null)[]) => {
  letterStore.selectedRecipientIds = ids.filter((id) => id) as string[];
  letterStore.activeRecipientId = ids[0] || "";
};

export const updateActiveRecipientId = (id: string) => {
  letterStore.activeRecipientId = id;
};

export const updateLetter = (letter: Letter) => {
  const letterUpdate = letter;

  // We cannot access the properties of File types via a Javascript Proxy eg. file.name
  // (which Valtio automatically wraps everything in)
  // Therefore we need to store an unproxied reference to the File in order to read from it
  if (letterUpdate.logo?.file) {
    letterUpdate.logo.file = ref(letterUpdate.logo?.file);
  }

  if (letterUpdate.signature?.file) {
    letterUpdate.signature.file = ref(letterUpdate.signature?.file);
  }

  letterStore.letter = letterUpdate;
};

export const updateLetterName = (name: string) => {
  letterStore.letterName = name;
};

export const updateIsLetterFormValid = (isValid: boolean) => {
  letterStore.isLetterFormValid = isValid;
};

export const updatePageCount = (pageCount: number) => {
  letterStore.pageCount = pageCount;
};

export const updateCurrentStep = (step: LetterStep) => {
  letterStore.currentStep = step;
};

export const updateIsUploadingLogo = (isUploadingLogo: boolean) => {
  letterStore.isUploadingLogo = isUploadingLogo;
};

export const updateIsUploadingSignature = (isUploadingSignature: boolean) => {
  letterStore.isUploadingSignature = isUploadingSignature;
};

export const updateSelectedStageId = (selectedStageId: string | null) => {
  letterStore.selectedStageId = selectedStageId;
};

export const updateSiteBoundaryImageUrlBySiteId = (
  siteBoundaryImageUrlById: Record<string, string>
) => {
  letterStore.siteBoundaryImageUrlBySiteId = siteBoundaryImageUrlById;
};
