import { SmallTabs } from "@landtechnologies/components";
import { useState } from "react";
import { router } from "src/js/router/react-router";
import { useTranslation } from "react-migration/lib/i18n/useTranslation";
import { getParamFromQueryParams } from "./util/helpers";
import { EmailAlerts } from "./components/EmailAlerts";
import { PlanningSearchAlerts } from "./components/PlanningSearchAlerts";
import { SavedSitesAlerts } from "./components/SavedSitesAlerts";

enum TabId {
  planningSearchAlertsId = "planning-search-alerts",
  savedSitesAlertsId = "saved-sites-alerts",
}

type TabIdType = keyof typeof TabId;

export const PlanningAlerts = () => {
  const { t } = useTranslation();
  const tabId = (getParamFromQueryParams("tab") || TabId.planningSearchAlertsId) as TabIdType;
  const email = getParamFromQueryParams("email");
  const [currentTabId, setCurrentTabId] = useState(tabId);
  const handleTabChange = (id: string) => {
    setCurrentTabId(id as TabIdType);
    if (id !== currentTabId) {
      type ParamType = Parameters<typeof router.push>;
      const params: ParamType[0] = {
        query: {
          tab: id,
        },
      };
      if (email) params.query = { ...params.query, email: email };
      router.push(params);
    }
  };
  return (
    <div data-testid="planning-alerts" className="">
      <h2 className="atlas-text-sm atlas-font-medium md:atlas-text-3xl atlas-mb-6">
        {t("dashboard.planning_alerts.planning_alerts")}
      </h2>
      <EmailAlerts />
      <SmallTabs
        tabs={[
          {
            id: TabId.planningSearchAlertsId,
            title: t("dashboard.planning_alerts.tab_search"),
            content: <PlanningSearchAlerts />,
          },
          {
            id: TabId.savedSitesAlertsId,
            title: t("dashboard.planning_alerts.tab_sites"),
            content: <SavedSitesAlerts />,
          },
        ]}
        activeId={currentTabId}
        onTabChange={handleTabChange}
      />
    </div>
  );
};
