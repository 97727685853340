import { SourceStatus } from "react-migration/domains/constraints/designation/components/SourceStatus";
import { FunctionComponent } from "react";
import { useTranslation } from "react-migration/lib/i18n/useTranslation";
import {
  SingleDesignation,
  SingleDesignationSubCategory,
} from "react-migration/domains/constraints/typings/applicationTypes/SingleDesignation";

type DesignationListProps = {
  category: SingleDesignationSubCategory;
  designations: SingleDesignation[];
};

export const DesignationsList: FunctionComponent<DesignationListProps> = ({
  category,
  designations,
}) => {
  const { t } = useTranslation();
  const maxAmountOfRows = 17;
  const amountOfDesignations = designations.length;
  const hasTooManyDesignations = amountOfDesignations > maxAmountOfRows;
  const displayName = category.display_name.toLowerCase();

  if (hasTooManyDesignations) {
    return (
      <div>
        {t("sites.card.site_report.designations.too_many_designations", {
          amountOfDesignations,
          displayName,
        })}
      </div>
    );
  } else {
    return designations.map((designation) => (
      <div key={designation.id} className="atlas-flex atlas-items-center atlas-space-x-2">
        <div>
          {designation.display_name}, {designation.source.authority.name}
        </div>
        <SourceStatus status={designation.source.status} />
      </div>
    ));
  }
};
