import { SendOption } from "../../ConfirmAndSendStep/SendOptions";
import { CurrentBalanceSection } from "./components/CurrentBalanceSection";
import { DownloadSummarySection } from "./components/DownloadSummarySection";
import { PhysicalSendSummarySection } from "./components/PhysicalSendSummarySection";
import classNames from "classnames";

export type PaymentSummaryProps = {
  sendOption: SendOption;
  onBalanceLoaded?: (balance: number) => void;
  onTotalCostCalculated?: (totalCost: number) => void;
};
export const PaymentSummary = ({
  sendOption,
  onBalanceLoaded,
  onTotalCostCalculated,
}: PaymentSummaryProps) => {
  return (
    <div
      className={classNames(
        "atlas-w-[330px]",
        "atlas-h-fit",
        "atlas-border",
        "atlas-border-border-divider-subtle",
        "atlas-rounded-lg"
      )}
      data-testid="letter-payment-summary-wrapper"
    >
      <CurrentBalanceSection onBalanceLoaded={onBalanceLoaded} />

      {
        {
          [SendOption.Download]: <DownloadSummarySection />,
          [SendOption.Send]: (
            <PhysicalSendSummarySection onTotalCostCalculated={onTotalCostCalculated} />
          ),
        }[sendOption]
      }
    </div>
  );
};
