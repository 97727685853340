import type { LayerType } from "../../types";
import { UtilitiesLayerTypeProvider } from "./UtilitiesContext";
import { UtilitiesMapLayer } from "./UtilitiesMapLayer";
import { UtilitiesSelectionSidebar } from "./UtilitiesSelectionSidebar";
import { UTILITIES_LAYER_TYPE_ID } from "./constants";

export const UtilitiesLayerType: LayerType = {
  id: UTILITIES_LAYER_TYPE_ID,
  hideMapControls: true,
  MapLayer: UtilitiesMapLayer,
  Provider: UtilitiesLayerTypeProvider,
  SelectionSidebar: UtilitiesSelectionSidebar,
};
