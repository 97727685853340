import { SearchTypeState } from "react-migration/domains/search/typings/Search";
import { searchStore } from "../store";
import devLog from "src/js/util/devLog";

export const setActiveTab = (activeTab: SearchTypeState["activeTab"]) => {
  if (!searchStore.types[searchStore.currentSearchType]) {
    return devLog(
      `setActiveTab: searchType ${searchStore.currentSearchType} not found in searchStore`
    );
  }

  searchStore.types[searchStore.currentSearchType].activeTab = activeTab;
};
