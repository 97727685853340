import { useEffect, useState } from "react";
import { PlanningApplication } from "react-migration/domains/planning/types";
import { Nullable } from "src/js/types/Nullable";
import { ENVIRONMENT } from "src/js/util/environment";

export function usePlanningAppById(id?: string) {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<Nullable<PlanningApplication>>();

  useEffect(() => {
    if (!id) return;

    const controller = new AbortController();

    async function fetchPlanningApplications() {
      try {
        const application = await fetchPlanningApplicationById(id!, controller);

        setData(application);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    }

    fetchPlanningApplications();

    return () => controller.abort();
  }, [id]);

  return [data as PlanningApplication, loading] as const;
}

export async function fetchPlanningApplicationById(id: string, controller?: AbortController) {
  const response = await fetch(
    `${ENVIRONMENT.PLANNING_SERVICE_URL}/planning-applications/${encodeURIComponent(id)}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      mode: "cors",
      signal: controller?.signal,
    }
  );

  if (!response.ok) {
    throw new Error(`Request failed with status ${response.status}`);
  }

  const application = await response.json();

  if (!application || application.length === 0) {
    throw new Error(`No data found for application with ${id}.`);
  }

  return application as PlanningApplication;
}
