import classNames from "classnames";
import { useState } from "react";
import { useTranslation } from "react-migration/lib/i18n/useTranslation";
import { LetterStep } from "src/js/stores/letter/store";
import { updateCurrentStep } from "src/js/stores/letter/actions";

import { Button } from "@landtechnologies/components";

import { SendStatus, useSendLetters } from "../../hooks/useSendLetters/useSendLetters";
import { BalanceWarningModal } from "../components/PaymentSummary/components/components/BalanceWarningModal";
import { LetterEventName, logLetterEvent } from "../helpers/logLetterEvent";
import { SendOption, SendOptions } from "./SendOptions";
import { setShowTopupModal } from "src/js/stores/navigation/actions";
import { Header } from "../components/Header";
import { LetterError } from "../components/FinalStep/LetterError";
import { LetterLoading } from "../components/FinalStep/LetterLoading";
import { Footer } from "../components/Footer";

export const ConfirmAndSendStep = () => {
  const { t } = useTranslation();
  const { sendLetters, sendStatus, clearSendStatus } = useSendLetters();

  const [sendOption, setSendOption] = useState<SendOption>(SendOption.Send);
  const [balance, setBalance] = useState<number>();
  const [letterCost, setLetterCost] = useState<number>();
  const [isBalanceWarningModalOpen, setBalanceWarningModalOpen] = useState<boolean>(false);

  const onSendOptionChange = (option: SendOption) => {
    setSendOption(option);
    const eventName =
      option === SendOption.Send
        ? LetterEventName.LETTERS_FORM_CONFIRM_AND_SEND_STEP_SEND_VIA_LANDINSIGHT_OPTION_CLICKED
        : LetterEventName.LETTERS_FORM_FINISH_AND_DOWNLOAD_STEP_DOWNLOAD_MANUALLY_CLICKED;
    logLetterEvent({ name: eventName });
  };

  const onBackClick = () => {
    updateCurrentStep(LetterStep.PreviewLetter);
    logLetterEvent({
      name: LetterEventName.LETTERS_FORM_CONFIRM_AND_SEND_STEP_BACK_BUTTON_CLICKED,
    });
  };

  const onLetterErrorBackClick = () => clearSendStatus();

  const onNextClick = async () => {
    if (sendOption === SendOption.Send) {
      logLetterEvent({
        name: LetterEventName.LETTERS_FORM_CONFIRM_AND_SEND_STEP_CONFIRM_AND_SEND_BUTTON_CLICKED,
      });

      const userHasEnoughBalance = !!balance && !!letterCost && letterCost <= balance;

      if (userHasEnoughBalance) {
        await sendLetters();
      } else {
        setBalanceWarningModalOpen(true);
      }
    } else {
      updateCurrentStep(LetterStep.FinishAndDownload);
      logLetterEvent({
        name: LetterEventName.LETTERS_FORM_CONFIRM_AND_SEND_STEP_CONFIRM_AND_DOWNLOAD_BUTTON_CLICKED,
      });
    }
  };

  const isDuringASend = sendOption === SendOption.Send && sendStatus === SendStatus.SENDING;

  if (sendStatus === SendStatus.SENT) {
    updateCurrentStep(LetterStep.OrderConfirmed);
  }

  return (
    <>
      {isDuringASend && <LetterLoading />}
      {sendStatus === SendStatus.ERROR && (
        <LetterError
          backButtonText={t("sites.letters.back")}
          onBackClick={onLetterErrorBackClick}
        />
      )}
      {!isDuringASend && !sendStatus && (
        <div
          data-testid="letters-confirm-and-send-step"
          className={classNames("atlas-h-full", "atlas-w-full", "atlas-flex", "atlas-flex-col")}
        >
          <Header />
          <BalanceWarningModal
            isOpen={isBalanceWarningModalOpen}
            onCloseClick={() => setBalanceWarningModalOpen(false)}
            onTopUpClick={() => {
              setShowTopupModal(true);
              setBalanceWarningModalOpen(false);
            }}
          />
          <SendOptions
            sendOption={sendOption}
            onSendOptionChange={onSendOptionChange}
            setBalance={setBalance}
            setLetterCost={setLetterCost}
          />
          <Footer percentage={75}>
            <div className="atlas-flex atlas-flex-grow atlas-justify-between">
              <Button.Secondary onClick={onBackClick}>{t("sites.letters.back")}</Button.Secondary>
              <Button.Primary className="atlas-w-48" onClick={onNextClick} disabled={isDuringASend}>
                {sendOption === SendOption.Send
                  ? t("sites.letters.confirm_and_send")
                  : t("sites.letters.confirm_and_download")}
              </Button.Primary>
            </div>
          </Footer>
        </div>
      )}
    </>
  );
};
