import { AlertIcon } from "../../icons/AlertIcon";

type NoDataProps = {
  title: string;
  children: React.ReactNode;
};

export const NoData = ({ title, children }: NoDataProps) => {
  return (
    <div className="atlas-items-center atlas-flex atlas-flex-col atlas-mt-[80px]">
      <AlertIcon />
      <span className="atlas-mt-2 atlas-font-semibold atlas-text-base">{title}</span>
      <div className="atlas-mt-1">{children}</div>
    </div>
  );
};
