import { Feature, feature, point } from "@turf/helpers";
import { getGeom } from "@turf/turf";
import buffer from "@turf/buffer";
import { PickingInfo } from "deck.gl";
import { SelectionFeature, SelectionType } from "src/js/stores/map/store";
import { getDeckInstance } from "src/js/plugins/deck.gl/deckInstance";
import { designationGeometryByIdQuery } from "react-migration/domains/constraints/designation/hooks/useDesignationGeometryById";
import { createDesignationSelection } from "react-migration/domains/constraints/designation/util/createDesignationSelection";
import { getDesignationPointRadius } from "react-migration/domains/constraints/designation/style/accessors";
import { ClickSelectionResolver } from "react-migration/layouts/map/Multilayer/types";
import { SELECTABLE_CATEGORIES } from "react-migration/domains/constraints/designation/style/StyleMap";
import { createMultiLineString } from "react-migration/domains/constraints/designation/util/createMultiLineString";
import { createMultiPolygon } from "react-migration/domains/constraints/designation/util/createMultiPolygon";
import {
  DesignationFeature,
  DesignationLayer,
  DesignationLayerLight,
} from "react-migration/domains/constraints/components/ConstraintLayer/DesignationLayer";
import { getDeckLayerInstanceIds } from "react-migration/lib/map/getLayerInstances";

const POINT_BUFFER_RADIUS_METERS = 2;
const CONSTRAINT_LAYER_CLASSES = [DesignationLayer, DesignationLayerLight];

function isDesignationFeature(o: Feature): o is DesignationFeature {
  return !!o?.properties && "designation_id" in o.properties;
}

async function createPointSelection(
  designation: DesignationFeature,
  zoomPointScale?: (zoom: number) => number
): Promise<SelectionFeature> {
  const { data } = await designationGeometryByIdQuery(designation.properties.designation_id);
  const geometry = data.designation.designation_geometries[0]?.geometry;

  if ((geometry.type as any) === "MultiPoint")
    throw new Error("MultiPoint geometries are not supported");

  // add a buffer so that features at this same location are returned in considerations
  const areaAroundPoint = getGeom(
    buffer(geometry, POINT_BUFFER_RADIUS_METERS, { units: "meters" })
  );

  return {
    type: SelectionType.DESIGNATION,
    id: designation.properties.designation_id,
    feature: feature(areaAroundPoint),
    savable: false,
    __zoomPointScale: zoomPointScale,
    __renderGeometry: point(geometry.coordinates as [number, number], {
      radius: getDesignationPointRadius(designation),
    }),
  };
}

export const constraintClickResolver: ClickSelectionResolver = async (
  info: PickingInfo<unknown>
) => {
  const { x, y } = info;
  const deck = getDeckInstance();
  const layerIds = getDeckLayerInstanceIds(CONSTRAINT_LAYER_CLASSES);
  const picked = deck?.pickMultipleObjects({ x, y, layerIds }) || [];

  for (const { object } of picked) {
    if (
      isDesignationFeature(object) &&
      SELECTABLE_CATEGORIES.includes(object.properties.sub_category_id || "")
    ) {
      const zoomPointScale = info.layer?.state?.zoomPointScale as (zoom: number) => number;

      switch (object.geometry.type) {
        case "Point": {
          return createPointSelection(object, zoomPointScale);
        }

        case "Polygon":
        case "MultiPolygon": {
          return createDesignationSelection(object, createMultiPolygon);
        }

        case "LineString":
        case "MultiLineString": {
          return createDesignationSelection(object, createMultiLineString);
        }
      }
    }
  }

  return null;
};
