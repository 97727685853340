import { siteStore } from "../store";
import type { SiteReportConfiguration } from "../store";

export function setSiteSiteReportConfiguration(
  siteId: string,
  configuration: SiteReportConfiguration
) {
  siteStore.siteReportConfiguration[siteId] = configuration;
}

export function clearSiteSiteReportConfiguration(siteId: string) {
  siteStore.siteReportConfiguration = Object.fromEntries(
    Object.entries(siteStore.siteReportConfiguration).filter(([key]) => key !== siteId)
  );
}
