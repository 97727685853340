import { PropsWithChildren, SelectHTMLAttributes } from "react";
import clsx from "clsx";

export enum SelectSize {
  SM = "sm",
  XS = "xs",
}

type SelectProps = PropsWithChildren<{ size?: `${SelectSize}` }> &
  Omit<SelectHTMLAttributes<HTMLSelectElement>, "size">;

export function Select({ children, className, size = SelectSize.SM, ...props }: SelectProps) {
  return (
    <div className={clsx("atlas-relative atlas-flex atlas-items-center atlas-pointer", className)}>
      <select
        className={clsx(
          "atlas-appearance-none atlas-w-full atlas-max-w-full atlas-truncate atlas-pl-2 atlas-pr-8 atlas-py-1 atlas-border-neutral-300 atlas-text-content-primary atlas-rounded-md focus:atlas-border-border-action-focus disabled:atlas-cursor-not-allowed",
          {
            "!atlas-bg-neutral-100": props.disabled,
            "atlas-text-sm atlas-h-8": size === SelectSize.SM,
            "atlas-text-xs atlas-h-7": size === SelectSize.XS,
          }
        )}
        {...props}
      >
        {children}
      </select>
      <i
        className={clsx(
          "icon-lt-arrow-down-s-line atlas-absolute atlas-right-2 atlas-pointer-events-none",
          {
            "atlas-text-neutral-500": props.disabled,
            "atlas-text-lg": size === SelectSize.SM,
            "atlas-text-md": size === SelectSize.XS,
          }
        )}
      />
    </div>
  );
}
