import { formatToCurrency } from "src/js/util/currency";
import { Purchase, Transaction } from "../../typings/TransactionsResponse";

export const TYPE_STR = {
  TitleRegisterDocument: "Title Register",
  TitlePlanDocument: "Title Plan",
  OSMapPrint: "Map Print",
};

export const formatTransactionURL = (purchase: Purchase) => {
  return (
    purchase && (purchase.title_document ? purchase.title_document.url : purchase?.map_print?.url)
  );
};

export const formatPurchaseType = (purchase: Purchase) => {
  return (purchase && TYPE_STR[purchase.type as keyof typeof TYPE_STR]) || "Other";
};

export const formatPurchaseAmount = (transaction: Transaction) => {
  const purchase = transaction.purchase;
  if (!purchase) {
    return "N/A";
  }
  let amount = 0;
  if (purchase.map_print) {
    amount = -purchase.amount;
  } else if (purchase.title_document) {
    amount = transaction.amountInPence / 100;
  }

  if (!amount) return "N/A";
  if (amount > 0) return `+${formatToCurrency(amount, "GBP")}`;
  if (amount < 0) return formatToCurrency(amount, "GBP") || "";
  return "N/A";
};
