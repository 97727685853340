import { useEffect, useState } from "react";
import LETTER_ACCESS_QUERY from "react-migration/domains/sites/apollo/sites-api/queries/letterAccessQuery.gql";
import SELECT_RECIPIENTS_TABLE_QUERY from "react-migration/domains/sites/apollo/sites-api/queries/selectRecipientsTableQuery.gql";
import {
  LandTechEndpoints,
  routedClient,
} from "react-migration/lib/persistence/apollo/routedClient";

import { useQuery } from "@apollo/client";

import { titlesToRecipients } from "../../letters/helpers/titlesToRecipients";
import { SiteFilters } from "../../typings/SiteFilters";
import { Recipient } from "../../typings/Recipient";
import {
  LettersAccessQueryResponseData,
  LettersAccessQueryVariables,
  SelectRecipientsTableQueryResponseData,
  SelectRecipientsTableQueryVariables,
} from "../../typings/apollo";
import { MultiPolygon, Polygon } from "geojson";

export type LoadRecipientsProps = {
  siteIds?: string[];
  stageIds?: string[];
  filters: SiteFilters;
};

export function useLoadRecipients({
  siteIds,
  stageIds,
  filters: { siteLabels, searchText, assignees, localAuthorities },
}: LoadRecipientsProps) {
  const [formattedRecipients, setFormattedRecipients] = useState<Recipient[] | null>();
  const variables: LettersAccessQueryVariables = {
    siteLabels,
    assignees,
    searchString: searchText,
    localAuthorities,
  };

  // making sure only one or the other is sent, prioritising siteIds over stageIds
  if (siteIds) {
    variables.siteIds = siteIds;
  } else if (stageIds) {
    variables.stageIds = stageIds;
  }

  const { loading: selectRecipientsTableLoading, data: selectRecipientsTableData } = useQuery<
    SelectRecipientsTableQueryResponseData,
    SelectRecipientsTableQueryVariables
  >(SELECT_RECIPIENTS_TABLE_QUERY, {
    client: routedClient,
    fetchPolicy: "network-only",
    variables: { ...variables, limit: 50 },
  });

  const { loading: lettersAccessLoading, data: lettersAccessData } = useQuery<
    LettersAccessQueryResponseData,
    LettersAccessQueryVariables
  >(LETTER_ACCESS_QUERY, {
    client: routedClient,
    fetchPolicy: "network-only",
    context: { endpoint: LandTechEndpoints.Sites },
    variables,
  });

  useEffect(() => {
    if (selectRecipientsTableData && lettersAccessData) {
      const recipients = selectRecipientsTableData.selectRecipientsTable?.edges
        .map(({ node: site }) =>
          titlesToRecipients({
            propertyAddress: site.address,
            titles: site.titles,
            siteTitle: site.title,
            siteId: site._id,
            geometry: site.geometry as Polygon | MultiPolygon,
          })
        )
        .flat();

      const formattedRecipients = lettersAccessData.lettersAccess?.edges
        .map(({ node: site }) => {
          const recipient: Recipient = recipients.find(
            (recipient) => recipient.siteId === site._id
          )!;

          return {
            ...recipient,
            isGranted: site.lettersAccess.isGranted,
          };
        })
        .flat();

      setFormattedRecipients(formattedRecipients ?? []);
    }
  }, [selectRecipientsTableData, lettersAccessData]);

  return {
    loading: selectRecipientsTableLoading ?? lettersAccessLoading,
    recipients: formattedRecipients,
  };
}
