export const UtilitiesIcon = () => {
  return (
    <svg width="30" height="36" viewBox="0 0 30 36" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="utlities_icon__Layer_1" clipPath="url(#clip0_880_66777)">
        <path
          id="utlities_icon__Vector"
          d="M25.6002 10.5366L15.0002 0L4.4002 10.5366C-1.4498 16.3516 -1.4498 25.7948 4.4002 31.6263C10.2502 37.4579 19.7502 37.4413 25.6169 31.6263C31.4669 25.8113 31.4669 16.3682 25.6169 10.5366H25.6002ZM13.3002 31.4441V21.3217H8.21686L16.7002 7.83617V17.9586H21.7835L13.3002 31.4441Z"
          fill="black"
        />
      </g>
      <defs>
        <clipPath id="clip0_880_66777">
          <rect width="30" height="36" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
