import { McSearchResultDTO, MultiCriteriaSearchArgsDTO, SearchLocationDTO } from "./External";
import { SavedSearch } from "./SavedSearch";
import { CheckboxFilter, ISearchFilter } from "./Filters";
import { ICheckboxFilter } from "@landtechnologies/dtos";
import { ILocationFilter } from "./SourcingToolFilters/LocationFilter";

export enum SearchTabId {
  filters = "filters",
  results = "results",
  saved = "saved",
}

export type SearchTypeState = {
  savedResults: boolean;
  savedResultsError: string;
  boundaryFilters: ICheckboxFilter[];
  locationFilter?: ILocationFilter;
  searchId: string; // used to track mixpanel stats around searches
  searchResultsLoading: boolean;
  searchResults?: McSearchResultDTO[];
  searchResultsError?: string;
  selectedFilters: ISearchFilter[];
  selectedItemsById: Map<string, ISelectedItem>;
  searchOnMapChange: boolean;
  activeTab: SearchTabId;
  searchToDelete?: SavedSearch;
  lastRequest?: {
    abort: () => void;
    params?: MultiCriteriaSearchArgsDTO;
  };
  isClusteredParcelSearch: boolean;
};

export type SearchLocation = SearchLocationDTO & {
  boundaries?: CheckboxFilter[];
  viewport?: boolean;
};

export interface ISelectedItem {
  /** unique id of the item */
  id: string;
  /** if the parcel is part of cluster of parcels owned by the same entity/person it will have a cluster id */
  clusterId?: string;
  /**
   * where the item was selected from - allows different behaviour when selecting items from different areas. e.g. selecting an item from a map layer
   * would be expected to bring the item to view in the list results, selecting from list may want to focus in the map
   */
  from: string;
  /**
   * the centre point of the selected item
   */
  coords?: {
    lat: number;
    lng: number;
  };
  /**
   * the geometry of the selected item if available
   */
  geometry?: GeoJSON.Geometry | GeoJSON.GeometryCollection;
}
