import Feature from "src/js/stores/user/Feature";
import { Layer } from "../types";
import { UtilitiesLayerType } from "../layer_types/UtilitiesLayerType/UtilitiesLayerType";

export const UTILITIES_LAYER_ID = "utilities";

export const UTILITIES_LAYER: Layer = {
  id: UTILITIES_LAYER_ID,
  layerType: UtilitiesLayerType,
  toggleable: false,
  title: "Utilities",
  description: "Utilities",
  requiredFeatureAccess: [Feature.siteUtilitiesReports],
};
