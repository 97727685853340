import * as Select from "@radix-ui/react-select";
import { Icon } from "@landtechnologies/components";
import { SiteStage } from "react-migration/domains/sites/typings/SiteStage";
import { SiteStageSelectItem } from "./SiteStageSelectItem";
import { StageDotIcon } from "./StageDotIcon";

export enum SiteStageSelectrTriggerType {
  Hatch = "hatch",
  Select = "select",
}

export type SiteStageSelectProps = Pick<Select.SelectProps, "disabled" | "value"> & {
  stages: SiteStage[];
  value?: string;
  onChange(value: string): void;
  triggerType?: SiteStageSelectrTriggerType;
};

export const SiteStageSelect = ({
  stages,
  value,
  onChange,
  triggerType = SiteStageSelectrTriggerType.Hatch,
  ...props
}: SiteStageSelectProps) => {
  const selected = stages.find((stage) => stage._id === value);
  const selectedStageColor = selected?.color ?? "#eee";
  const selectedStageName = selected?.name ?? "";

  return (
    <Select.Root onValueChange={onChange} value={value} {...props}>
      {triggerType === SiteStageSelectrTriggerType.Hatch ? (
        <HatchTrigger color={selectedStageColor} name={selectedStageName} />
      ) : (
        <SelectTrigger color={selectedStageColor} name={selectedStageName} />
      )}
      <Select.Portal>
        <Select.Content
          position="popper"
          side="bottom"
          sideOffset={4}
          className="atlas-flex atlas-flex-col atlas-gap-y-2 atlas-p-3 atlas-overflow-hidden atlas-w-60 atlas-max-h-72 atlas-z-10 atlas-shadow-md atlas-rounded-lg atlas-bg-white atlas-border atlas-border-blueGrey-200"
        >
          <div className="atlas-flex atlas-flex-col atlas-gap-x-1">
            <h1
              className="atlas-text-sm atlas-font-normal atlas-text-content-primary atlas-cursor-default atlas-truncate"
              title={selectedStageName}
            >
              {selectedStageName}
            </h1>
            <p className="atlas-text-xs atlas-text-neutral-5 atlas-cursor-default">
              Move this site to another stage
            </p>
          </div>
          <Select.Viewport>
            <Select.Group className="atlas-max-h-52">
              {stages.map((stage) => (
                <SiteStageSelectItem
                  key={stage._id}
                  value={stage._id}
                  color={stage.color}
                  hidden={stage.hidden}
                >
                  {stage.name}
                </SiteStageSelectItem>
              ))}
            </Select.Group>
          </Select.Viewport>
        </Select.Content>
      </Select.Portal>
    </Select.Root>
  );
};

SiteStageSelect.TriggerType = SiteStageSelectrTriggerType;

interface SiteStageSelectTriggerProps {
  color: string;
  name: string;
}

const HatchTrigger = ({ color, name }: SiteStageSelectTriggerProps) => (
  <Select.Trigger
    style={{ background: color }}
    className="atlas-w-6 atlas-h-6 atlas-rounded-md atlas-shrink-0"
    title={name}
  />
);

const SelectTrigger = ({ color, name }: SiteStageSelectTriggerProps) => (
  <div className="atlas-w-full atlas-overflow-hidden">
    <Select.Trigger className="atlas-h-9 atlas-w-full atlas-px-4 atlas-flex atlas-items-center atlas-gap-x-3 atlas-rounded-md atlas-border atlas-border-solid atlas-border-blueGrey-200 focus:atlas-border-border-action-focus">
      <StageDotIcon color={color} />
      <span className="atlas-text-sm atlas-text-content-primary atlas-truncate atlas-select-none">
        {name}
      </span>
      <Icon
        icon="icon-lt-arrow-down-s-line"
        extraClasses="atlas-ml-auto atlas-pointer-events-none"
      />
    </Select.Trigger>
  </div>
);
