import DESIGNATION_GEOMETRY from "react-migration/domains/constraints/apollo/designationGeometry.gql";
import { useQuery } from "@apollo/client";
import { LandTechEndpoints, routedClient } from "react-migration/lib/persistence/apollo";
import { SingleDesignation } from "../../typings/applicationTypes/SingleDesignation";
import { DesignationGeometry } from "../../typings/baseTypes/DesignationGeometry";
export type Designation = Pick<SingleDesignation, "id"> & {
  designation_geometries: Pick<DesignationGeometry, "id" | "geometry">[];
};

export function useDesignationGeometryById(id?: string) {
  const { data, ...rest } = useQuery<{ designation: Designation }>(DESIGNATION_GEOMETRY, {
    variables: {
      id,
    },
    client: routedClient,
    context: {
      endpoint: LandTechEndpoints.Constraints,
    },
    skip: !id,
  });

  return { designation: data?.designation, ...rest };
}

export async function designationGeometryByIdQuery(id?: string) {
  return routedClient.query<{ designation: Designation }, { id?: string }>({
    query: DESIGNATION_GEOMETRY,
    variables: { id },
    context: {
      endpoint: LandTechEndpoints.Constraints,
    },
  });
}
