export type SiteLabel = {
  _id: string;
  name: string;
};

// Would be nice to get this from fragment-matcher.json (alongside SiteSource from the autogen)
// We tried extracting the type via :
// import fragmatch from "@gql/li-api/fragment-matcher.json";
// const typeArray = [...fragmatch.possibleTypes.SiteSource] as const
// type SiteSourceType = (typeof typeArray)[number];
// But this fell down at a compiler level and just gave us 'string'
export enum SiteSourceType {
  SiteSourceOwnershipLayer = "SiteSourceOwnershipLayer",
  SiteSourceOwnershipSearch = "SiteSourceOwnershipSearch",
  SiteSourcePlanningSearch = "SiteSourcePlanningSearch",
  SiteSourcePropertySearch = "SiteSourcePropertySearch",
  SiteSourcePlanningAlertResult = "SiteSourcePlanningAlertResult",
  SiteSourceDrawingTools = "SiteSourceDrawingTools",
  SiteSourceMobileApp = "SiteSourceMobileApp",
  SiteSourcePlanningLayer = "SiteSourcePlanningLayer",
  SiteSourcePlanningPolicyLayer = "SiteSourcePlanningPolicyLayer",
  SiteSourceZoningLayer = "SiteSourceZoningLayer",
  SiteSourceConstraintsLayer = "SiteSourceConstraintsLayer",
  SiteSourceCompsLayer = "SiteSourceCompsLayer",
  SiteSourcePropertyInfoLayer = "SiteSourcePropertyInfoLayer",
  SiteSourceDrawingTool = "SiteSourceDrawingTool",
  SiteSourceSitesUploader = "SiteSourceSitesUploader",
}

export type SiteSource = {
  _id: string;
  __typename: SiteSourceType;
};

export type SiteStage = {
  _id: string;
};

export type Site = {
  _id: SiteId;
  title: string;
  notes?: string;
  site_labels?: SiteLabel[];
  hidden: boolean;
  position_in_stage: number;
  local_authority: string;
  created: string;
  priority: number;
  _stage: SiteStage;
  assignees?: string[];
  source?: SiteSource;
};

export type SiteId = string;

export type SiteOverview = {
  _id: string;
  title: string;
  notes: string | null;
  ptal_rating: string;
  local_authority: string;
  use_classes: string[];
  use_classes_sep_2020: string[];
  average_building_height: {
    rel_h_to_roof: number;
    rel_h_max: number;
  };
  geometry: GeoJSON.Polygon;
  assignees: string[];
  site_labels: SiteLabel[];
  location: GeoJSON.Point;
  _stage: {
    color: string;
    _id: string;
    name: string;
  };
  source?: SiteSource;
};
