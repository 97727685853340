import { ISourcingToolSearchFilter } from "react-migration/domains/search/typings/SourcingToolSpecificFilterTypes";
import { SearchType } from "react-migration/domains/search/constants/searchTypes";

export const LotAreaFilter: ISourcingToolSearchFilter = {
  id: "lot-area-id",
  type: "number-range",
  rangeType: "area",
  field: "sidebar.react.search_sidebar.parcel_size",
  isDefault: true,
  range: [null, null],
  rangeLimit: [0, null],
  updateFilterDialogIsVisible: false,
  searchTypes: [SearchType.SOURCING_TOOL_UK, SearchType.SOURCING_TOOL_US_FL],
};
