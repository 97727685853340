import { ISourcingToolSearchFilter } from "react-migration/domains/search/typings/SourcingToolSpecificFilterTypes";
import { SearchType } from "react-migration/domains/search/constants/searchTypes";

export const PermittedUseFilter: ISourcingToolSearchFilter = {
  id: "permitted-use-id",
  type: "text",
  field: "sidebar.react.search_sidebar.permitted_use",
  text: "",
  must: "CONTAIN",
  mustOptions: ["CONTAIN", "NOT_CONTAIN"],
  updateFilterDialogIsVisible: false,
  searchTypes: [SearchType.SOURCING_TOOL_US_FL],
};
