import { ISourcingToolSearchFilter } from "react-migration/domains/search/typings/SourcingToolSpecificFilterTypes";
import { SearchType } from "react-migration/domains/search/constants/searchTypes";

export const UseClassNestedFilter: ISourcingToolSearchFilter = {
  id: "use-class-nested-id",
  type: "nested-checkbox",
  field: "sidebar.react.search_sidebar.use_class",
  must: "MATCH_ANY",
  mustOptions: ["MATCH_ANY", "MATCH_NONE"],
  searchTypes: [SearchType.SOURCING_TOOL_UK],
  displayMessages: {
    mustOptions: {
      MATCH_ANY: "sidebar.react.search_sidebar.is",
      MATCH_NONE: "sidebar.react.search_sidebar.is_not",
    },
  },
  values: [
    {
      key: "B2",
      name: "B2 - General industrial",
    },
    {
      key: "B8",
      name: "B8 - Storage or distribution",
    },
    {
      key: "C1",
      name: "C1 - Hotels",
    },
    {
      key: "C2",
      name: "C2 - Residential institutions",
    },
    {
      key: "C2A",
      name: "C2A - Secure Residential Institution",
    },
    {
      key: "C3",
      name: "C3 - Dwellinghouses",
    },
    {
      key: "C4",
      name: "C4 - Houses in multiple occupation",
    },
    {
      key: "E",
      name: "E - Commercial, Business and Service",
      items: [
        {
          key: "E(a)",
          name: "E(a) Retail of goods other than hot food",
        },
        {
          key: "E(b)",
          name: "E(b) - Sale of food and drink for consumption (mostly) on the premises",
        },
        {
          key: "E(c)",
          name: "E(c) Provision of financial services, professional services or other commercial/business services",
        },
        {
          key: "E(c)(i)",
          name: "E(c)(i) Provision of financial services",
        },
        {
          key: "E(d)",
          name: "E(d) Indoor sport and recreation (excluding swimming pools or skating rinks)",
        },
        {
          key: "E(e)",
          name: "E(e) Provision of medical or health services",
        },
        {
          key: "E(f)",
          name: "E(f) Creche, day nursery or day centre",
        },
        {
          key: "E(g)(i)",
          name: "E(g)(i) Offices to carry out operational functions",
        },
      ],
    },
    {
      key: "F.1",
      name: "F.1 - Learning and non-residential institutions",
      items: [
        {
          key: "F.1(a)",
          name: "F.1(a) Provision of education e.g. schools",
        },
        {
          key: "F.1(b)",
          name: "F.1(b) Display of works of art",
        },
        {
          key: "F.1(c)",
          name: "F.1(c) Museums",
        },
        {
          key: "F.1(d)",
          name: "F.1(d) Public libraries or public reading rooms",
        },
        {
          key: "F.1(f)",
          name: "F.1(f) Public worship or religious instruction",
        },
        {
          key: "F.1(g)",
          name: "F.1(g) Law courts",
        },
      ],
    },
    {
      key: "F.2",
      name: "F.2 - Local community uses",
      items: [
        {
          key: "F.2(b)",
          name: "F.2(b) Halls or meeting places for the principal use of the local community",
        },
        {
          key: "F.2(c)",
          name: "F.2(c) Areas for outdoor sports and recreation",
        },
        {
          key: "F.2(d)",
          name: "F.2(d) Indoor or outdoor swimming pools or skating rinks",
        },
      ],
    },
    {
      key: "sui-generis",
      name: "Sui Generis",
      items: [
        {
          key: "i-Agricultural",
          name: "Agricultural",
        },
        {
          key: "ii-Agricultural",
          name: "Agricultural - Applicable to land in farm ownership and not run as a separate business enterprise",
        },
        {
          key: "iii-Amusements",
          name: "Amusements",
        },
        {
          key: "iv-Bingo-Hall",
          name: "Bingo Hall / Cinema / Conference / Exhibition Centre / Theatre / Concert Hall",
        },
        {
          key: "v-Car",
          name: "Car / Coach / Commercial Vehicle / Taxi Parking / Park And Ride Site",
        },
        {
          key: "vi-Farm",
          name: "Farm / Non-Residential Associated Building",
        },
        {
          key: "vii-Garage",
          name: "Garage",
        },
        {
          key: "viii-Golf",
          name: "Golf Facility",
        },
        {
          key: "ix-Grazing",
          name: "Grazing Land",
        },
        {
          key: "x-Holiday",
          name: "Holiday / Campsite",
        },
        {
          key: "xi-Licensed",
          name: "Licensed Private Members’ Club",
        },
        {
          key: "xii-Lock",
          name: "Lock-Up Garage / Garage Court",
        },
        {
          key: "xiii-Permanent",
          name: "Permanent Crop / Crop Rotation",
        },
        {
          key: "xiv-Petrol",
          name: "Petrol Filling Station",
        },
        {
          key: "xv-car-parking",
          name: "Public Car Parking",
        },
        {
          key: "xvi-Public",
          name: "Public House / Bar / Nightclub",
        },
        {
          key: "xvii-Recreational",
          name: "Recreational / Social Club",
        },
      ],
    },
  ],
};
