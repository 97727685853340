export const HomeTrackLogoLarge = ({ width, height }: { width?: number; height?: number }) => {
  const defaultWidth = 509;
  const defaultHeight = 57;
  const resizeRatio = width ? width / defaultWidth : height ? height / defaultHeight : 1;
  return (
    <svg
      width={width ? width : defaultWidth * resizeRatio}
      height={height ? height : defaultHeight * resizeRatio}
      viewBox="0 0 509 57"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M170.894 16.0912C167.819 17.7669 165.391 20.1694 163.611 23.319C161.831 26.4687 160.94 30.1632 160.94 34.3831C160.94 37.1087 161.345 39.5719 162.094 41.8531L167.92 36.0384C167.88 35.4936 167.839 34.9483 167.839 34.3831C167.839 31.0923 168.466 28.3259 169.721 26.0849C170.975 23.844 172.634 22.1886 174.677 21.098C176.721 20.0078 178.946 19.4829 181.374 19.4829C182.365 19.4829 183.296 19.6042 184.226 19.7856L189.122 14.9001C186.735 14.0316 184.166 13.5876 181.374 13.5876C177.47 13.5876 173.989 14.4154 170.915 16.0912H170.894Z"
        fill="#283748"
      />
      <path
        d="M153.374 31.0923C153.374 25.3786 151.917 21.0175 149.004 17.9891C146.091 14.9607 142.267 13.4463 137.533 13.4463C134.073 13.4463 131.039 14.3344 128.389 16.1115C125.758 17.8882 123.917 20.4115 122.885 23.7227V0.0201302H116.148V54.6535H122.885V33.1717C122.885 28.5883 123.998 25.1161 126.223 22.7335C128.449 20.3513 131.403 19.1598 135.105 19.1598C138.808 19.1598 141.458 20.2504 143.541 22.4103C145.605 24.5708 146.657 27.6801 146.657 31.7181V54.6734H149.328L153.374 50.6354V31.0923Z"
        fill="#283748"
      />
      <path
        d="M271.119 17.9891C268.227 14.9607 264.423 13.4463 259.689 13.4463C256.33 13.4463 253.336 14.3548 250.666 16.1314C247.995 17.9284 246.093 20.4721 244.961 23.7635C243.868 20.3712 242.067 17.7873 239.518 16.0509C236.97 14.3145 233.975 13.4261 230.536 13.4261C227.096 13.4261 224.305 14.2742 221.776 15.9699C219.226 17.666 217.467 20.1087 216.495 23.319V14.0923H209.758V54.6132H216.495V33.1314C216.495 28.6087 217.568 25.1767 219.712 22.8344C221.856 20.4925 224.729 19.3213 228.331 19.3213C231.689 19.3213 234.339 20.3712 236.322 22.4914C238.284 24.611 239.276 27.6597 239.276 31.6575V53.5832L246.013 46.8604V33.111C246.013 28.5883 247.085 25.1563 249.23 22.8145C251.374 20.4721 254.227 19.3015 257.767 19.3015C261.308 19.3015 263.836 20.3513 265.799 22.471C266.548 23.2788 267.093 24.268 267.559 25.3383L272.799 20.1087C272.313 19.3213 271.767 18.5946 271.14 17.9284L271.119 17.9891Z"
        fill="#283748"
      />
      <path d="M153.374 54.6331V49.5458L148.275 54.6331H153.374Z" fill="#283748" />
      <path
        d="M163.611 45.4471C165.391 48.5764 167.819 50.9789 170.894 52.6546C173.969 54.3304 177.469 55.1581 181.353 55.1581C185.238 55.1581 188.738 54.3304 191.813 52.6546C194.888 50.9789 197.316 48.5764 199.056 45.4471C200.796 42.3174 201.686 38.6428 201.686 34.403C201.686 30.1632 200.816 26.4891 199.056 23.3394C197.296 20.1898 194.888 17.7873 191.813 16.1115C190.701 15.5056 189.527 15.0617 188.313 14.6779L183.336 19.6445C184.995 19.8667 186.573 20.3513 188.05 21.1388C190.093 22.2289 191.753 23.8842 193.007 26.1252C194.261 28.3666 194.888 31.1325 194.888 34.4234C194.888 37.7142 194.261 40.4199 193.007 42.6609C191.753 44.9018 190.093 46.5572 188.05 47.6478C186.007 48.738 183.761 49.2629 181.353 49.2629C178.946 49.2629 176.7 48.7176 174.657 47.6478C172.614 46.5572 170.955 44.9018 169.701 42.6609C168.527 40.581 167.94 38.0577 167.839 35.0895L161.831 41.086C162.276 42.6405 162.842 44.094 163.591 45.4471H163.611Z"
        fill="#283748"
      />
      <path d="M355.949 14.1127V26.7113L362.666 19.988V14.1127H355.949Z" fill="#283748" />
      <path d="M245.992 46.6382L239.276 53.3615V54.6331H245.992V46.6382Z" fill="#283748" />
      <path
        d="M268.712 31.6779V54.6331H275.449V31.0923C275.449 26.5497 274.518 22.8548 272.677 20.0078L267.458 25.217C268.247 26.9936 268.692 29.1337 268.692 31.6779H268.712Z"
        fill="#283748"
      />
      <path
        d="M322.527 32.1218C322.527 28.73 321.758 25.6206 320.2 22.8145C318.643 20.0078 316.397 17.7669 313.463 16.0912C310.53 14.4154 307.091 13.5876 303.146 13.5876C299.2 13.5876 295.801 14.4154 292.787 16.0912C289.773 17.7669 287.426 20.1694 285.726 23.319C284.027 26.4687 283.178 30.1632 283.178 34.3831C283.178 38.6025 284.027 42.2975 285.726 45.4268C287.426 48.556 289.773 50.9585 292.787 52.6343C295.801 54.31 299.241 55.1382 303.146 55.1382C306.545 55.1382 309.599 54.5322 312.31 53.3009C315.021 52.069 317.227 50.3531 318.926 48.132C320.625 45.9114 321.718 43.408 322.223 40.6014H315.042C314.556 43.3672 313.201 45.5679 311.016 47.2034C308.81 48.8587 306.099 49.6665 302.842 49.6665C299.241 49.6665 296.186 48.5158 293.698 46.1938C291.209 43.8723 289.894 40.4199 289.732 35.776H322.203C322.406 34.7465 322.507 33.5146 322.507 32.0815L322.527 32.1218ZM289.752 32.5662C289.955 28.1444 291.31 24.7726 293.819 22.4914C296.327 20.2097 299.443 19.0589 303.146 19.0589C305.472 19.0589 307.617 19.5435 309.579 20.5328C311.542 21.522 313.1 23.0163 314.232 25.035C315.365 27.0543 315.851 29.5577 315.709 32.5662H289.752Z"
        fill="#283748"
      />
      <path
        d="M338.712 41.9341V19.9273H349.212V14.0923H338.712V3.97743H331.915V14.0923H325.845V19.9273H331.915V41.7725C331.915 44.8814 332.38 47.365 333.31 49.2828L338.813 43.7913C338.753 43.2261 338.712 42.6002 338.712 41.9341Z"
        fill="#283748"
      />
      <path
        d="M355.949 26.6099V54.6331H362.686V33.2929C362.686 28.8106 363.9 25.6002 366.307 23.662C368.715 21.7238 371.831 20.755 375.634 20.755H377.698V13.4463C374.137 13.4463 371.082 14.2136 368.533 15.768C365.964 17.3225 364.021 19.5033 362.686 22.3094V19.9069L355.949 26.6303V26.6099Z"
        fill="#283748"
      />
      <path
        d="M439.969 16.0912C436.955 17.7669 434.608 20.1694 432.909 23.319C431.209 26.4687 430.36 30.1632 430.36 34.403C430.36 36.8661 430.683 39.1274 431.27 41.2272L437.279 35.2312C437.279 34.9483 437.238 34.6858 437.238 34.403C437.238 31.1121 437.825 28.3463 439.018 26.1053C440.192 23.8644 441.77 22.2085 443.712 21.1184C445.655 20.0282 447.817 19.5033 450.188 19.5033C451.118 19.5033 451.987 19.5838 452.836 19.7255L457.814 14.7584C455.565 13.9914 453.081 13.5876 450.347 13.5876C446.442 13.5876 443.004 14.4358 439.99 16.0912H439.969Z"
        fill="#283748"
      />
      <path
        d="M334.989 51.6853C337.033 53.6643 340.128 54.6331 344.276 54.6331H349.313V48.738H345.166C342.799 48.738 341.14 48.2533 340.168 47.3043C339.44 46.5775 338.995 45.3865 338.813 43.7306L333.29 49.2425C333.756 50.2119 334.301 51.0396 334.989 51.7057V51.6853Z"
        fill="#283748"
      />
      <path
        d="M422.186 14.1127H415.449V24.4495C414.357 21.1586 412.455 18.514 409.724 16.5554C406.992 14.5969 403.654 13.6078 399.709 13.6078C396.108 13.6078 392.912 14.4358 390.099 16.1115C387.287 17.7873 385.082 20.1898 383.484 23.3394C381.885 26.4886 381.076 30.1836 381.076 34.403C381.076 38.6229 381.885 42.3174 383.484 45.4471C385.082 48.5764 387.287 50.9789 390.099 52.6343C392.912 54.31 396.108 55.1382 399.709 55.1382C403.654 55.1382 406.992 54.1484 409.724 52.1904C412.455 50.2119 414.377 47.5872 415.449 44.2963V54.6331H417.836L422.186 50.2924V14.0923V14.1127ZM413.689 42.1966C412.495 44.4375 410.857 46.1739 408.773 47.4052C406.669 48.6371 404.302 49.2425 401.631 49.2425C397.484 49.2425 394.166 47.9302 391.677 45.2856C389.189 42.6609 387.934 39.0265 387.934 34.403C387.934 29.7799 389.189 26.1456 391.677 23.5209C394.166 20.8962 397.484 19.5639 401.631 19.5639C404.302 19.5639 406.669 20.1694 408.773 21.3808C410.877 22.5923 412.516 24.3287 413.689 26.59C414.862 28.8508 415.469 31.4556 415.469 34.4234C415.469 37.3911 414.883 39.9755 413.689 42.2165V42.1966Z"
        fill="#283748"
      />
      <path
        d="M477.436 0V54.6331H484.172V36.4018L499.689 54.6331H508.128L489.498 34.2618L507.683 14.1127H499.47L484.172 31.7584V0H477.436Z"
        fill="#283748"
      />
      <path
        d="M458.361 21.9664C460.543 23.6218 461.898 25.8831 462.383 28.7907H469.488C468.695 24.1671 466.595 20.4726 463.196 17.7266C461.596 16.4347 459.777 15.4454 457.794 14.7589L452.815 19.7255C454.921 20.0685 456.782 20.7952 458.34 21.9868L458.361 21.9664Z"
        fill="#283748"
      />
      <path d="M422.186 54.6331V50.3128L417.836 54.6331H422.186Z" fill="#283748" />
      <path
        d="M432.909 45.4471C434.608 48.5764 436.955 50.9789 439.969 52.6546C442.984 54.3304 446.421 55.1581 450.326 55.1581C455.509 55.1581 459.797 53.785 463.196 51.0594C466.595 48.3338 468.695 44.619 469.488 39.9551H462.383C461.898 42.8627 460.543 45.1439 458.361 46.8196C456.153 48.4954 453.444 49.3236 450.188 49.3236C447.817 49.3236 445.655 48.7782 443.712 47.708C441.77 46.6178 440.192 44.9625 439.018 42.7215C437.946 40.6819 437.38 38.1785 437.279 35.251L431.27 41.2476C431.674 42.7414 432.201 44.1547 432.909 45.4471Z"
        fill="#283748"
      />
      <path
        d="M25.0869 1.11035C21.5664 1.11035 18.7139 3.9773 18.7139 7.49028V50.1512C18.7139 53.6642 21.5868 56.531 25.1068 56.531C28.6274 56.531 31.4998 53.6642 31.4998 50.1512V7.49028C31.4998 3.95707 28.6473 1.11035 25.1068 1.11035H25.0869Z"
        fill="#FFCD00"
      />
      <path
        d="M62.4726 7.11035C58.9521 7.11035 56.0996 9.9773 56.0996 13.4903V50.1512C56.0996 53.6642 58.9726 56.531 62.4926 56.531C66.0131 56.531 68.8856 53.6642 68.8856 50.1512V13.4903C68.8856 9.95707 66.0331 7.11035 62.4926 7.11035H62.4726Z"
        fill="#4795CD"
      />
      <path
        d="M6.39297 56.5311C9.91351 56.5311 12.7859 53.6841 12.7859 50.1512V35.1904C12.7859 31.6773 9.93344 28.8105 6.39297 28.8105C2.85251 28.8105 0 31.6575 0 35.1904V50.1512C0 53.6841 2.85251 56.5311 6.39297 56.5311Z"
        fill="#FF9524"
      />
      <path
        d="M81.1928 56.7205C84.7133 56.7205 87.5858 53.8736 87.5858 50.3407V32.3798C87.5858 28.8668 84.7332 26 81.1928 26C77.6523 26 74.7998 28.8469 74.7998 32.3798V50.3407C74.7998 53.8736 77.6523 56.7205 81.1928 56.7205Z"
        fill="#9659A1"
      />
      <path
        d="M43.7934 56.7205C47.3139 56.7205 50.1863 53.8736 50.1863 50.3407V22.3798C50.1863 18.8668 47.3338 16 43.7934 16C40.2529 16 37.4004 18.8469 37.4004 22.3798V50.3407C37.4004 53.8736 40.2529 56.7205 43.7934 56.7205Z"
        fill="#00B62F"
      />
    </svg>
  );
};
