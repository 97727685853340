import { useCallback, useState } from "react";
import { getSearchableDesignationText } from "../../util/getSearchableDesignationText";
import { Designations } from "./Designations";
import { SelectionFeature, SelectionType } from "src/js/stores/map/store";
import { SingleDesignation } from "react-migration/domains/constraints/typings/applicationTypes/SingleDesignation";

const FILTER_APPEAR_MINIMUM = 6;

interface DesignationsListProps {
  designations: SingleDesignation[];
  setDetailSelection: (selection: SelectionFeature | undefined) => void;
  truncateSubtitle?: boolean;
  cardName: string;
}

export function DesignationsList({
  designations,
  setDetailSelection,
  truncateSubtitle = false,
  cardName,
}: DesignationsListProps) {
  const [filterString, setFilterString] = useState("");
  const filteredDesignations = designations.filter((d) =>
    getSearchableDesignationText(filterString, d)
  );

  const setSelectedDesignation = useCallback(
    (id: string) => {
      // TODO: For now we don't actually need/know the geometry of the
      // selected designation, passing in a dummy point geometry.
      setDetailSelection(
        id
          ? {
              id,
              type: SelectionType.DESIGNATION,
            }
          : undefined
      );
    },
    [setDetailSelection]
  );

  return (
    <Designations
      filterable={designations.length >= FILTER_APPEAR_MINIMUM}
      filterString={filterString}
      setFilterString={setFilterString}
      designations={filteredDesignations}
      setSelectedDesignation={setSelectedDesignation}
      truncateSubtitle={truncateSubtitle}
      cardName={cardName}
    />
  );
}
