import { CardNumberElementProps } from "@stripe/react-stripe-js";
import { useMemo } from "react";
import { MastercardIcon } from "../components/cardBrandIcons/MastercardIcon";
import { VisaIcon } from "../components/cardBrandIcons/VisaIcon";
import { UnknownIcon } from "../components/cardBrandIcons/UnknownIcon";
import { AmexIcon } from "../components/cardBrandIcons/AmexIcon";
import { DiscoverIcon } from "../components/cardBrandIcons/DiscoverIcon";
import { DinersIcon } from "../components/cardBrandIcons/DinersIcons";
import { JcbIcon } from "../components/cardBrandIcons/JcbIcon";
import { UnionPayIcon } from "../components/cardBrandIcons/UnionPayIcon";

type Brand = Parameters<NonNullable<CardNumberElementProps["onChange"]>>[0]["brand"];

export const useCardBrands = (brand: Brand) => {
  return useMemo(() => {
    switch (brand) {
      case "mastercard":
        return <MastercardIcon />;
      case "visa":
        return <VisaIcon />;
      case "amex":
        return <AmexIcon />;
      case "discover":
        return <DiscoverIcon />;
      case "diners":
        return <DinersIcon />;
      case "jcb":
        return <JcbIcon />;
      case "unionpay":
        return <UnionPayIcon />;
      default:
        return <UnknownIcon />;
    }
  }, [brand]);
};
