import { ISourcingToolSearchFilter } from "react-migration/domains/search/typings/SourcingToolSpecificFilterTypes";
import { SearchType } from "react-migration/domains/search/constants/searchTypes";
import Feature from "src/js/stores/user/Feature";

export const OwnershipTypeFilter: ISourcingToolSearchFilter = {
  id: "ownership-type-id",
  type: "checkbox",
  isDefault: [Feature.ukLocationSearch],
  field: "sidebar.react.search_sidebar.ownership_type",
  must: "MATCH_ANY",
  mustOptions: ["MATCH_ANY", "MATCH_NONE"],
  updateFilterDialogIsVisible: false,
  searchTypes: [SearchType.SOURCING_TOOL_UK],
  values: [
    {
      key: "corporate_body",
      name: "Corporate Body",
      checked: true,
    },
    {
      key: "company",
      name: "Company",
      checked: true,
    },
    {
      key: "council",
      name: "Local Authority",
      checked: true,
    },
    {
      key: "housing_association",
      name: "Housing Association",
      checked: true,
    },
    {
      key: "private",
      name: "Privately Owned",
      checked: true,
    },
  ],
};
