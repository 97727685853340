export const PencilIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
      <g clipPath="url(#clip0_130_17350)">
        <path
          fill="currentColor"
          d="M15.728 9.686l-1.414-1.414L5 17.586V19h1.414l9.314-9.314zm1.414-1.414l1.414-1.414-1.414-1.414-1.414 1.414 1.414 1.414zM7.242 21H3v-4.243L16.435 3.322a1 1 0 011.414 0l2.829 2.83a1 1 0 010 1.413L7.243 21h-.001z"
        ></path>
      </g>
      <defs>
        <clipPath id="clip0_130_17350">
          <path fill="#fff" d="M0 0H24V24H0z"></path>
        </clipPath>
      </defs>
    </svg>
  );
};
