import { userStore } from "src/js/stores/user/store";
import { dateString, SupportedLocale } from "src/js/util/dates";

const locale = userStore.locale as SupportedLocale;

export const formatDate = (date: string) => {
  const isDateValid = date && !Number.isNaN(new Date(date).getTime());

  if (!isDateValid) {
    return "";
  }

  return dateString(date, locale);
};
