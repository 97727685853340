import { useTranslation } from "react-migration/lib/i18n/useTranslation";
import { SkeletonLoading } from "@landtechnologies/components";
import { LayerTypeSelectionHeaderProps } from "../../types";
import { usePlanningAppById } from "./usePlanningAppsById";

export function PlanningApplicationSelectionHeader({ selection }: LayerTypeSelectionHeaderProps) {
  const { t } = useTranslation();
  const [data, loading] = usePlanningAppById(selection?.id);

  if (loading)
    return (
      <div className="atlas-relative atlas-h-10" data-testid="designation-header-loading">
        <SkeletonLoading rows={1} showPadding={false} />
      </div>
    );

  return (
    <div key={selection?.id} className="atlas-flex atlas-flex-col atlas-items-start">
      <div className="atlas-flex atlas-flex-col atlas-items-stretch atlas-grow">
        <div className="atlas-text-xs atlas-font-semibold atlas-text-content-tertiary atlas-uppercase">
          {t("planning.planning_application_list.header")}
        </div>
        <div className="atlas-text-sm atlas-font-semibold atlas-text-content-primary">
          {data?.ref}
        </div>
      </div>
    </div>
  );
}
