export const RagStatusDescription = () => (
  <div className="atlas-space-y-6">
    <div className="atlas-space-y-2">
      <div className="atlas-font-semibold atlas-text-lg">Electricity North West</div>
      <div>Electricity North West does not provide a RAG status.</div>
    </div>

    <div className="atlas-space-y-2">
      <div className="atlas-font-semibold atlas-text-lg">Northern Powergrid</div>
      <div className="atlas-space-y-1">
        <div>
          <span className="atlas-font-semibold">Low: </span>No capacity available
        </div>
        <div>
          <span className="atlas-font-semibold">Medium: </span>
          {"< 2 mw available capacity"}
        </div>
        <div>
          <span className="atlas-font-semibold">High: </span>
          {"> 2 mw available capacity"}
        </div>
      </div>
    </div>

    <div className="atlas-space-y-2">
      <div className="atlas-font-semibold atlas-text-lg">Scottish Power Energy Networks</div>
      <div className="atlas-space-y-1">
        <div>
          <span className="atlas-font-semibold">Low: </span>At least one factor is close to its
          operational limit and so installation of most levels of Distributed Generation and a local
          connection is highly unlikely. It may also require extensive reinforcement works or given
          the lack of a local connection, require an extensive amount of sole user assets to
          facilitate such a connection.
        </div>
        <div>
          <span className="atlas-font-semibold">Medium: </span>At least one factor is nearing its
          operational limit and hence, depending on the nature of the application, network
          reinforcement may be required. However this can only be confirmed by detailed network
          analysis.
        </div>
        <div>
          <span className="atlas-font-semibold">High: </span>All operational factors are within
          tolerable limits and so opportunities may exist to connect additional Distributed
          Generation without reinforcing the network (subject to detailed studies).
        </div>
      </div>
    </div>
    <div className="atlas-space-y-2">
      <div className="atlas-font-semibold atlas-text-lg">
        Scottish & Southern Electricity Networks
      </div>
      <div>
        Scottish & Southern Electricity Networks does not provide a RAG status on its Contracted
        Demand map.
      </div>
      <div className="atlas-space-y-1">
        <div>
          <span className="atlas-font-semibold">Low: </span>Red status shows areas that are
          constrained.
        </div>
        <div>
          <span className="atlas-font-semibold">Medium: </span>Amber status shows areas that are
          partially constrained.
        </div>
        <div>
          <span className="atlas-font-semibold">High: </span>Green status shows areas that are
          unconstrained.
        </div>
      </div>
    </div>

    <div className="atlas-space-y-2">
      <div className="atlas-font-semibold atlas-text-lg">UK Power Networks</div>
      <div>UK Power Networks does not provide a RAG status.</div>
    </div>

    <div className="atlas-space-y-2">
      <div className="atlas-font-semibold atlas-text-lg">Western Power Distribution</div>

      <div className="atlas-space-y-1">
        <div className="atlas-font-semibold atlas-text-neutral-600">Primary substations</div>
        <div>
          <span className="atlas-font-semibold">Low: </span>
          {"< 10% capacity"}
        </div>
        <div>
          <span className="atlas-font-semibold">Medium: </span>10% - 20% capacity
        </div>
        <div>
          <span className="atlas-font-semibold">High: </span>
          {"> 20% capacity"}
        </div>
      </div>

      <div className="atlas-space-y-1">
        <div className="atlas-font-semibold atlas-text-neutral-600">Bulk supply points</div>
        <div>
          <span className="atlas-font-semibold">Low: </span>
          {"< 5% capacity"}
        </div>
        <div>
          <span className="atlas-font-semibold">Medium: </span>5% - 15% capacity
        </div>
        <div>
          <span className="atlas-font-semibold">High: </span>
          {"> 15% capacity"}
        </div>
      </div>
    </div>
  </div>
);
