import { Geometry } from "./Geometry";
import { OSMapPrint } from "./Map";
import { TitleDocument } from "./TitleDocument";
import { TeamUser } from "./User";

export type TransactionState = {
  status?: string;
  type?: string;
  expected_response_time?: Date;
};

export enum TransactionPurchaseType {
  TITLE_PLAN_DOCUMENT = "TitlePlanDocument",
  TITLE_REGISTER_DOCUMENT = "TitleRegisterDocument",
  OS_MAP_PRINT = "OSMapPrint",
}

export type TransactionPurchase = {
  reference?: string;
  type?: TransactionPurchaseType;
  title_document?: TitleDocument;
  map_print?: OSMapPrint;
  location?: Geometry;
  amount?: number;
};

export type Transaction = {
  id?: string;
  user?: TeamUser;
  purchase?: TransactionPurchase;
  state?: TransactionState;
  description?: string;
  amountInPence?: number;
  created?: Date;
};
