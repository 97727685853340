import type { Layer, Widget } from "@deck.gl/core";
import type { MapStore } from "src/js/stores/map/store";

// eslint-disable-next-line @typescript-eslint/ban-types
type DeckLoadingIndicatorAdapterWidgetProps = {};

/**
 * Used to set the loading state of deck.gl layers in valtio.
 */
export class DeckLoadingIndicatorAdapterWidget
  implements Widget<DeckLoadingIndicatorAdapterWidgetProps>
{
  id = "deckgl-loading-indicator-widget";
  props = {};
  mapStore: MapStore;

  constructor(params: { mapStore: MapStore }) {
    this.mapStore = params.mapStore;
  }

  setProps() {
    // noop - Required by Widget interface
  }

  onAdd() {
    // noop - Required by Widget interface
    return null;
  }

  onRemove() {
    this.mapStore.deckLoading = false;
  }

  onRedraw({ layers }: { layers: Layer[] }) {
    const { deckLoading } = this.mapStore;
    const isLoading = layers.some((layer) => !layer.isLoaded && layer.root.props.visible);

    if (deckLoading !== isLoading) {
      this.mapStore.deckLoading = isLoading;
    }
  }
}
