import { SearchStore, searchStore } from "../store";
import devLog from "src/js/util/devLog";
import { v4 } from "uuid";
import { defaultSearchTypeState } from "react-migration/domains/search/constants/defaultSearchTypeState";
import { getDefaultFilters } from "../../../../react-migration/domains/search/util/defaultFilters";
import { SearchType, searchTypeSet } from "react-migration/domains/search/constants/searchTypes";
import { SearchTypeState } from "react-migration/domains/search/typings/Search";

const createDefaults = (searchType: string): SearchTypeState => ({
  ...structuredClone(defaultSearchTypeState),
  selectedFilters: getDefaultFilters(searchType),
  searchId: v4(),
});

/**
 * Sets the currentSearchType and applies an upsert to the corresponding
 * key in the `types` object
 * - if the key doesn't exist - creates defaults and merges in any provided overrides
 * - if the key does exist - does nothing to `types[key]` unless overrides are provided
 * - if the key does exist - merged any overrides in to the existing `types[key]`
 *
 * @param searchType - The new search type to set.
 * @param overrides - Optional overrides to apply to the search type state.
 */
export function setCurrentSearchType(
  searchType?: string,
  overrides: Partial<SearchTypeState> = {}
) {
  if (!searchType || searchTypeSet.has(searchType as SearchType)) {
    searchStore.currentSearchType = (searchType ?? "") as SearchStore["currentSearchType"];
  }

  if (!searchType) {
    return devLog("setDefaultsForSearchType: a falsy searchType will have no effect");
  }

  if (searchStore.types[searchType] && !overrides) {
    return;
  }

  const previousValues = searchStore.types[searchType] ?? {};
  const defaults = createDefaults(searchType);
  const requiredFilters = defaults.selectedFilters.filter((a) => a.isRequired);
  const selectedFilters =
    overrides.selectedFilters ?? previousValues.selectedFilters ?? defaults.selectedFilters;
  // it is possible to set filters form elsewhere that don't include required filters because those
  // components should need to know about what filters are required. For those instances we have to
  // insert any missing required filters to the beginning of the selectedFilters array
  const missingRequiredFilters = requiredFilters.filter(
    (requiredFilter) =>
      !selectedFilters.some((selectedFilter) => selectedFilter.id === requiredFilter.id)
  );
  searchStore.types[searchType] = {
    ...defaults,
    // structuredClone doesn't copy getters/setters so have to set this manually
    get isClusteredParcelSearch() {
      return this.selectedFilters.some((filter) => filter.id === "lot-cluster-area-id");
    },
    ...previousValues,
    ...overrides,
    selectedFilters: [...missingRequiredFilters, ...selectedFilters],
  };
}
