import { Dropdown, DropdownItem, Notification } from "@landtechnologies/components";
import { useElements, useStripe } from "@stripe/react-stripe-js";
import React, { useMemo, useState } from "react";

import { PaymentMethod } from "react-migration/domains/constraints/typings/applicationTypes/StripeTypes";
import { LockIcon } from "../icons/LockIcon";
import { useTranslation } from "react-migration/lib/i18n/useTranslation";
import { routedClient } from "react-migration/lib/persistence/apollo/routedClient";
import { DocumentNode } from "@apollo/client";
import { AttemptChargeParams, Mode, PaymentResponse } from "../typings";
import GET_STRIPE_PEYMENT_METHODS from "../apollo/queries/stripePaymentMethods.gql";
import hasFeature from "src/js/stores/user/actions/hasFeature";
import clsx from "clsx";
import { StripeForm } from "./StripeForm";
import Feature from "src/js/stores/user/Feature";
import { Button } from "react-migration/components/Button";

type PaymentFormProps = {
  mode?: Mode;
  showTitle?: boolean;
  amount: number;
  paymentMethods: PaymentMethod;
  setPaymentComplete?: React.Dispatch<React.SetStateAction<boolean>>;
  attemptCharge: (params: AttemptChargeParams, onCompleted: (res: PaymentResponse) => void) => void;
  isFormInvalid: boolean;
  loading: boolean;
  queriesToInvalidate?: DocumentNode[];
  errorMessage?: string;
};

export const PaymentForm = ({
  amount,
  paymentMethods,
  setPaymentComplete,
  attemptCharge,
  isFormInvalid,
  loading,
  queriesToInvalidate = [],
  errorMessage,
  showTitle = true,
  mode = "light",
}: PaymentFormProps) => {
  const formatedStripePaymentMethods = useMemo(() => {
    if (!paymentMethods) return [];
    return paymentMethods.stripePaymentMethods.map((paymentMethod) => {
      return {
        label: `${
          paymentMethod.brand[0].toUpperCase() + paymentMethod.brand.substring(1)
        } ending in ${paymentMethod.last4} (expires ${paymentMethod.expires_month}/${
          paymentMethod.expires_year
        })`,
        value: paymentMethod.id,
      };
    });
  }, [paymentMethods]);
  const [newCardForm, setNewCardForm] = useState(formatedStripePaymentMethods.length === 0);
  const [paymentError, setPaymentError] = useState(errorMessage);
  const [is3DsecureStep, setIs3DsecureStep] = useState(false);
  const [isStripeFormValid, setIsStripeFormValid] = useState(false);

  const [cardType, setCardType] = useState(formatedStripePaymentMethods[0]?.value || "new");
  const elements = useElements();
  const stripe = useStripe();
  const { t } = useTranslation();

  const disableButton = (cardType === "new" && !isStripeFormValid) || isFormInvalid;

  const handleCardValueChange = (option: DropdownItem) => {
    setNewCardForm(option.value === "new");
    setCardType(option.value);
  };

  const handlePaymentCompleted = async (res: PaymentResponse) => {
    setPaymentError("");
    if (!stripe) return;
    setIs3DsecureStep(false);
    switch (res.status) {
      case "requires_action":
      case "requires_source_action": {
        setIs3DsecureStep(true);
        const response = await stripe.handleNextAction({ clientSecret: res.client_secret });
        if (response.error) {
          setIs3DsecureStep(false);
          setPaymentError(res.error_message || t("dashboard.card_details.error_could_not_process"));
        } else {
          attemptCharge(
            {
              amount: amount * 100,
              stripeCharge: { pending_intent_id: res.id },
            },
            handlePaymentCompleted
          );
        }
        break;
      }
      case "card_declined":
      case "expired_card":
      case "failed": {
        setPaymentError(res.error_message || t("dashboard.card_details.error_could_not_process"));
        break;
      }
      case "succeeded": {
        const invalidatedQueries = queriesToInvalidate;
        if (cardType === "new") {
          invalidatedQueries.push(GET_STRIPE_PEYMENT_METHODS);
        }
        routedClient.refetchQueries({ include: invalidatedQueries });
        setPaymentError("");
        setPaymentComplete && setPaymentComplete(true);
        break;
      }
      case "processing": {
        setPaymentError(t("dashboard.card_details..stuck_in_processing"));
        break;
      }
      default: {
        setPaymentError(t("dashboard.card_details.error_status", { status: res.status }));
        break;
      }
    }
  };

  const onSubmit = async () => {
    if (cardType !== "new") {
      attemptCharge(
        {
          amount: amount * 100,
          stripeCharge: { payment_method_id: cardType },
        },
        handlePaymentCompleted
      );
    } else {
      if (!stripe || !elements) return;
      const cardNumber = elements.getElement("cardNumber");
      if (!cardNumber) return;

      const res = await stripe.createPaymentMethod({
        type: "card",
        card: cardNumber,
      });

      const id = res.paymentMethod?.id || "";
      attemptCharge(
        {
          amount: amount * 100,
          stripeCharge: { payment_method_id: id },
        },
        handlePaymentCompleted
      );
    }
  };

  return (
    <div>
      {showTitle && (
        <div className="atlas-font-semibold atlas-text-base atlas-mb-2">
          {t("dashboard.topup.method")}
        </div>
      )}
      <div className="atlas-mb-4">
        <Dropdown
          label={t("dashboard.topup.choose_card")}
          placeholder={t("dashboard.topup.choose_card")}
          handleValueChange={handleCardValueChange}
          value={cardType}
          options={[
            ...formatedStripePaymentMethods,
            { label: t("dashboard.topup.add_new_card"), value: "new" },
          ]}
          disabled={loading}
        ></Dropdown>
      </div>

      {newCardForm && <StripeForm setIsStripeFormValid={setIsStripeFormValid} />}

      {paymentError && (
        <Notification.Error
          additionalClassName="atlas-mb-4"
          title={t("dashboard.topup.payment_failed")}
          message={paymentError}
          icon="info"
        />
      )}
      <Button
        type="button"
        onClick={onSubmit}
        data-testid="topup-pay-button"
        className="atlas-w-full atlas-mb-4"
        disabled={loading || disableButton || amount <= 0 || is3DsecureStep}
      >
        {t("dashboard.topup.amount", {
          amount: amount || "0",
          currency: hasFeature(Feature.usAccess) ? "$" : "£",
        })}
      </Button>
      <div
        className={clsx("atlas-flex atlas-gap-[6px]", mode === "light" && "atlas-text-neutral-600")}
      >
        <LockIcon />
        <p className="atlas-text-xs">{t("dashboard.topup.stripe_note")}</p>
      </div>
    </div>
  );
};
