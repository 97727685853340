import { ChangeEvent, useCallback } from "react";
import { debounce } from "lodash";
import { useTranslation } from "react-migration/lib/i18n/useTranslation";
import { SiteOverview } from "react-migration/domains/sites/typings/Site";
import { useUpdateSiteMutation } from "../SaveSiteButton/useUpdateSiteMutation/useUpdateSiteMutation";

export interface EditableSiteDescriptionProps {
  site: Pick<SiteOverview, "_id" | "notes">;
  startedSaving(): void;
  stoppedSaving({ source, success }: { source: string; success: boolean }): void;
}

export function EditableSiteDescription({
  site: { _id, notes },
  startedSaving,
  stoppedSaving,
}: EditableSiteDescriptionProps) {
  const { t } = useTranslation();
  const [updateSite] = useUpdateSiteMutation();

  const onChangeHandlerDebounced = useCallback(
    debounce(async (e: ChangeEvent<HTMLTextAreaElement>) => {
      startedSaving();
      await updateSite({
        variables: { updateData: { _id, notes: e.target.value } },
        onCompleted: () => stoppedSaving({ source: "Description", success: true }),
        onError: () => stoppedSaving({ source: "Description", success: true }),
      });
    }, 250),
    [_id, updateSite]
  );

  return (
    <textarea
      className="atlas-appearance-none atlas-px-3 atlas-py-2 atlas-text-md atlas-w-full atlas-border atlas-border-solid atlas-border-neutral-300 focus:atlas-border-border-action-focus atlas-rounded-md atlas-min-h-40"
      placeholder={t("sites.card.site_card.no_description")}
      onChange={onChangeHandlerDebounced}
      defaultValue={notes ?? ""}
    />
  );
}
