import { SelectionFeature } from "src/js/stores/map/store";
import { SelectionHandler } from "../types";
import { SELECTION_HANDLERS } from "./SelectionHandlers";
import { useMemo } from "react";

export function useSelectionHandler(selection: SelectionFeature): SelectionHandler {
  return useMemo(
    () => (selection?.type && SELECTION_HANDLERS[selection.type]) || {},
    [selection.type]
  );
}
