import { Feature, Geometries } from "@turf/helpers";
import booleanIntersects from "@turf/boolean-intersects";
import { booleanPointInPolygon, point, Position } from "../../../util/turf";
import { siteGeometryStore } from "../store";
import {
  bufferedIntersection,
  isPolygonOrMultiPolygon,
} from "react-migration/layouts/map/Multilayer/geometry_utilities";

export const getSitesContainingPoint = (position: Position) => {
  return getFeaturesAtPosition(siteGeometryStore.siteFeatures, position);
};

export function getFeaturesAtPosition<F extends Feature>(features: F[], position: Position) {
  return features.filter(
    (feature: Feature) =>
      isPolygonOrMultiPolygon(feature.geometry) &&
      booleanPointInPolygon(point(position), feature.geometry)
  );
}

export function getFeaturesIntersectingGeometry<F extends Feature>(
  features: F[],
  geometry: Geometries
) {
  const intersectionThreshold = -0.1;
  return features.filter((feature) => {
    const useSignificantIntersection = isPolygonOrMultiPolygon(geometry);
    return isPolygonOrMultiPolygon(feature.geometry) && useSignificantIntersection
      ? bufferedIntersection(geometry, feature.geometry, intersectionThreshold)
      : booleanIntersects(geometry, feature);
  });
}
