import ROUTES from "src/js/router/routes";
import { usePlanningAlertSubscriptionQuery } from "../hooks/usePlanningAlertSubscriptionQuery";
import { PlanningAlertsTable } from "./PlanningAlertsTable/PlanningAlertsTable";
import { router } from "src/js/router/react-router";
import { PlanningAlert } from "../../typings/PlanningAlert";
import cloneDeep from "lodash/cloneDeep";
import { Loading } from "@landtechnologies/components";
import { useTranslation } from "react-migration/lib/i18n/useTranslation";
import { NoData } from "./NoData/NoData";
import { NoDataPlanningSearchAlerts } from "./NoData/NoDataPlanningSearchAlerts";
import { getParamFromQueryParams } from "../util/helpers";

export const PlanningSearchAlerts = () => {
  const { t } = useTranslation();
  const email = getParamFromQueryParams("email");
  const { data, loading, fetchMore } = usePlanningAlertSubscriptionQuery({
    cursor: null,
    emailed_at: email,
    limit: 15,
  });

  const handleEditAlert = (planningAlert: PlanningAlert) => {
    router.push({
      name: ROUTES.LEGACY_SEARCH,
      query: {
        activeSearch: "planning",
        edit_alert: planningAlert._id,
      },
    });
  };

  if (!loading && !data?.alertSubscriptions.nodes.length) {
    return (
      <NoData title={t("dashboard.planning_alerts.no_alerts")}>
        <NoDataPlanningSearchAlerts />
      </NoData>
    );
  }

  const nextItems = () => {
    if (data?.alertSubscriptions.pageInfo.hasNextPage) {
      fetchMore({
        variables: {
          cursor: data?.alertSubscriptions.pageInfo.endCursor,
          emailed_at: email,
          limit: 15,
        },
        updateQuery: (old, { fetchMoreResult }) => {
          const ret = cloneDeep(old);
          const newResults = fetchMoreResult.alertSubscriptions;
          ret.alertSubscriptions.nodes = ret.alertSubscriptions.nodes.concat(newResults.nodes);
          ret.alertSubscriptions.pageInfo.hasNextPage = newResults.pageInfo.hasNextPage;
          ret.alertSubscriptions.pageInfo.endCursor = newResults.pageInfo.endCursor;

          return ret;
        },
      });
    }
  };
  return (
    <div className="atlas-pt-4 atlas-pb-2">
      {data?.alertSubscriptions.nodes.length && (
        <PlanningAlertsTable
          planningAlerts={data?.alertSubscriptions.nodes}
          onEdit={handleEditAlert}
          nextItems={nextItems}
        />
      )}

      {loading && <Loading />}
    </div>
  );
};
