import { useMutation } from "@apollo/client";

import { routedClient } from "react-migration/lib/persistence/apollo/routedClient";

import { StripePaymentResponse } from "../types/Stripe";
import TOPUP_BALANCE from "../apollo/mutations/topupBalance.gql";

export const useTopupBalanceMutation = () => {
  return useMutation<StripePaymentResponse>(TOPUP_BALANCE, {
    client: routedClient,
  });
};
