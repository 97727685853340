import { useMemo } from "react";
import { mapStore } from "src/js/stores/map/store";
import { useSnapshot } from "valtio";

// Thhis can hopefully be replaced in the future by searching for results by site
export function useClickRadius() {
  const { settings: mapSettings } = useSnapshot(mapStore);
  const { zoom } = mapSettings;

  const clickRadius: number = useMemo(() => {
    const zoomMap: { [key: number]: number } = {
      20: 2,
      19: 4,
      18: 8,
      17: 16,
      16: 32,
      15: 64,
    };
    return zoomMap[zoom] || 2;
  }, [zoom]);
  return clickRadius;
}
