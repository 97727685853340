import { useMemo } from "react";

import { Avatars } from "../Avatars";
import { SiteAssigneeOption } from "../../domains/sites/hooks/useSiteAssigneeOptions";

import { AddAvatarButton } from "./AddAvatarButton";

export interface AvatarSelectProps {
  selectedAvatars: string[];
  allAvatars: SiteAssigneeOption[];
  disabled?: boolean;
  onChange: (selectedAvatars: string[]) => void;
  placeholder?: string;
  truncateCount?: number;
}

export function AvatarsSelect({
  selectedAvatars,
  allAvatars,
  disabled = false,
  onChange,
  placeholder,
  truncateCount = 5,
}: AvatarSelectProps) {
  const [allAvatarItems, filteredAvatarItems] = useMemo(() => {
    const allAvatarItems = allAvatars.map(({ id, original }) => ({
      id,
      firstName: original.firstname,
      lastName: original.surname,
      selected: !!selectedAvatars.find((selectedId) => id === selectedId),
    }));
    const filteredAvatarItems = allAvatarItems.filter(({ selected }) => selected);

    return [allAvatarItems, filteredAvatarItems];
  }, [selectedAvatars, allAvatars]);

  return (
    <div className="atlas-flex atlas-items-center atlas-gap-2 atlas-relative atlas-px-2 atlas-py-0.5">
      <Avatars
        avatars={filteredAvatarItems}
        placeholder={placeholder}
        truncateCount={truncateCount}
      ></Avatars>
      <AddAvatarButton
        title="Assign user"
        subTitle="Add users to this site"
        avatars={allAvatarItems}
        disabled={disabled}
        onAddAvatar={(avatar) => {
          onChange([...selectedAvatars, avatar.id]);
        }}
        onRemoveAvatar={(avatar) => {
          onChange(selectedAvatars.filter((selectedId) => selectedId !== avatar.id));
        }}
        popoverSide="bottom"
      />
    </div>
  );
}
