import { PickingInfo } from "deck.gl";
import { OwnershipFeature } from "react-migration/domains/ownership/typings/Feature";

export function logNoPickedCoordinateError(clickResolver: string) {
  console.error(clickResolver + ": Clicked ownership title has no pick coordinate");
}

export function logMissingGeometryWarning(
  clickResolver: string,
  pickInfo: PickingInfo<OwnershipFeature>,
  titleNumber?: string
) {
  console.warn(
    clickResolver + ": Main ownership title has no geometry. Falling back to picked geometry.",
    {
      clickCoordinate: pickInfo.coordinate,
      titleNumber,
    }
  );
}

export function logNoGeometryError(
  clickResolver: string,
  pickInfo: PickingInfo<OwnershipFeature>,
  titleNumber?: string
) {
  console.error(clickResolver + ": Clicked ownership title has no geometry", {
    clickCoordinate: pickInfo.coordinate,
    titleNumber,
  });
}

export function logNoTitleNumberError(
  clickResolver: string,
  pickInfo: PickingInfo<OwnershipFeature>
) {
  console.error(clickResolver + ": Clicked ownership title has no id", {
    clickCoordinate: pickInfo.coordinate,
  });
}

export function logNoUnitsAtLocationError(
  clickResolver: string,
  pickInfo: PickingInfo<OwnershipFeature>
) {
  console.error(clickResolver + ": Clicked location has no ownership titles", {
    clickCoordinate: pickInfo.coordinate,
  });
}
