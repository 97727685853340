import { Button } from "react-migration/components/Button";
import { useTranslation } from "react-migration/lib/i18n/useTranslation";
import { logEventV2 } from "react-migration/lib/util/logEventV2";

export const SummaryGenerate = ({
  setShowSummary,
  cardName,
}: {
  setShowSummary: (v: boolean) => void;
  cardName: string;
}) => {
  const { t } = useTranslation();
  return (
    <div
      data-testid="generate-summary-wrapper"
      className="atlas-p-3 atlas-flex atlas-flex-col atlas-w-full atlas-items-center"
    >
      <Button
        data-testid="generate-summary-button"
        onClick={() => {
          setShowSummary(true);
          logEventV2({
            name: "Workbench card click",
            properties: {
              cardName: cardName,
              action: "generate",
              clickDetail: "Generate Summary",
            },
          });
        }}
        leadIcon="lt-sparkling-line"
      >
        {t("planning.ai.generate")}
      </Button>
    </div>
  );
};
