import Feature from "src/js/stores/user/Feature";
import { ConstraintsCategory } from "../../Constraints/types";
import { ConstraintsLayerType } from "../layer_types/ConstraintsLayerType";
import { Layer } from "../types";

export const REGEN_ZONES: Layer = {
  id: "regeneration_zones",
  layerType: ConstraintsLayerType,
  title: "Regeneration Zones",
  requiredFeatureAccess: [Feature.planningPolicyLayer],
  toggleable: true,
  description: "Areas designated for extensive regeneration within a Local Plan",
  hideByDefault: true,
  layerConfig: {
    rootCategory: ConstraintsCategory.DEVELOPMENT_SITES,
    categorySchema: [{ key: "area_action_plans" }, { key: "named_regeneration_area" }],
    statusSchema: [{ key: "adopted" }, { key: "draft", defaultValue: false }],
  },
};
