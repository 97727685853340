import { createContext, useContext, useState } from "react";
import { LayerTypeProviderProps } from "../../types";

interface DemographicsContextValue {
  selectedAttribute: string;
  setSelectedAttribute: (value: string) => void;
}

const DemographicsContext = createContext({} as DemographicsContextValue);

export const useDemographicsContext = () => useContext(DemographicsContext);

export const DemographicsProvider = (props: LayerTypeProviderProps) => {
  const [selectedAttribute, setSelectedAttribute] = useState<string>("output_areas");

  return (
    <DemographicsContext.Provider
      value={{
        selectedAttribute,
        setSelectedAttribute,
      }}
    >
      {props.children}
    </DemographicsContext.Provider>
  );
};
