import clsx from "clsx";
import { ComponentProps } from "react";
import hasBetaFeature from "src/js/stores/user/actions/hasBetaFeature";
import Feature from "src/js/stores/user/Feature";

export type NavbarContentCenterProps = ComponentProps<"div">;
export const NavbarContentCenter = ({ children, className }: NavbarContentCenterProps) => {
  return (
    <div
      data-testid="navbar-content-center"
      className={clsx(
        "atlas-flex atlas-flex-1 atlas-shrink atlas-px-2 atlas-justify-center atlas-items-center [&>*]:atlas-w-full [&>*>*]:atlas-w-full [&>*>*]:atlas-flex [&>*>*]:atlas-justify-center atlas-overflow-hidden",
        hasBetaFeature(Feature.mobileFullAccess) && "atlas-pl-0",
        className
      )}
    >
      {children}
    </div>
  );
};
