import { useAdditionalTransactionsByTransactionId } from "react-migration/domains/comparables/hooks/useAdditionalTransactionsByTransactionId";
import { AdditionalTransactions } from "./AdditionalTransactions";
import { RecordType } from "react-migration/domains/comparables/typings/Record";
import hasFeature from "src/js/stores/user/actions/hasFeature";
import Feature from "src/js/stores/user/Feature";

export function AdditionalLandTechTransactions({
  transaction,
}: Readonly<{
  transaction: RecordType;
}>) {
  const { data } = useAdditionalTransactionsByTransactionId({
    transactionId: transaction.transaction_id,
  });

  return (
    <AdditionalTransactions
      transactions={data?.map((prev) => ({
        value: prev.price,
        date: prev.date_of_transfer,
        currency: prev.currency as "GBP",
        source: hasFeature(Feature.usAccess) ? "" : "Land Registry",
      }))}
    />
  );
}
