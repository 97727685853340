import { SelectionHandler } from "../../../types";
import { UKPropertyInformationSelectionHeader } from "./UKPropertyInformationSelectionHeader";
import { UKPropertyInformationSelectionDetailView } from "./UKPropertyInformationSelectionDetailView";
import { UKPropertyInformationSelectionMapLayer } from "./UKPropertyInformationSelectionMapLayer/UKPropertyInformationSelectionMapLayer";
import { UKPropertyInformationSelectionTitle } from "./UKPropertyInformationSelectionTitle";

export const UKPropertyInformationSelectionHandler: SelectionHandler = {
  SelectionHeader: UKPropertyInformationSelectionHeader,
  SelectionDetailView: UKPropertyInformationSelectionDetailView,
  SelectionMapLayer: UKPropertyInformationSelectionMapLayer,
  SelectionTitle: UKPropertyInformationSelectionTitle,
};
