import { deviceUuid, logEvent } from "react-migration/lib/util/logEvent";
import { userStore } from "src/js/stores/user/store";
import queryString from "query-string";
import { ENVIRONMENT } from "util/environment";
import { useCallback, useEffect, useMemo, useState } from "react";
import Pusher from "pusher-js";
import axios from "axios";
import { DecisionModal } from "./components/DecisionModal";
import { KickedModal } from "./components/KickedModal";

enum StatusType {
  kickedOut = "kicked-out",
  kickedOthers = "kicked-others",
  notInitalized = " not-initalized",
  sharingAllowed = "sharing-allowed",
  decisionRequired = "decision-required",
  soleDevice = "sole-device",
}
type Event = {
  userId: string;
  from: string;
  app_js_version: string;
};

export const MultiDeviceBlockModal = () => {
  const user = userStore.user;
  const [myId, setMyId] = useState();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [sameUserOnOtherDevices, setSameUserOnOtherDevices] = useState<any[]>([]);
  const [status, setStatus] = useState<StatusType>(StatusType.notInitalized);

  const pusher = useMemo(() => {
    return new Pusher(ENVIRONMENT.PUSHER_APP_KEY + "" || "", {
      authTransport: "ajax",
      authEndpoint: ENVIRONMENT.ACCOUNTS_SERVICE_PRIVATE_API_URL + "/auth/v2/pusher",
      auth: {
        params: {
          device: deviceUuid(),
          app_js_version: "2021a",
        },
      },
      cluster: "eu",
    });
  }, []);

  const channelId = `presence-${user._account._id}`;
  const channel = pusher?.subscribe(channelId);

  useEffect(() => {
    if (sameUserOnOtherDevices?.some((u) => u.app_js_version === "2021a")) {
      setStatus(StatusType.decisionRequired);
    } else {
      setStatus(StatusType.soleDevice);
    }
  }, [sameUserOnOtherDevices]);

  useEffect(() => {
    if (status === StatusType.kickedOut) {
      pusher.unsubscribe(channelId);
      localStorage.setItem("lastLogoutReason", "kick");
      axios.delete(ENVIRONMENT.ACCOUNTS_SERVICE_PUBLIC_API_URL + "/auth/session"); // this deletes the session, but we don't redirect because although we want to invalidate the session we want to leave the logged in view still visible, with the modal
      logEvent("Multi-user blocked", { status });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);

  const onMembersChange = () => {
    const ret: unknown[] = [];
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    channel.members.each((m) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      if (m.info.user === user._id && m.id !== channel.members.me.id) {
        ret.push(m.info);
      }
    });
    setSameUserOnOtherDevices(ret);
  };

  const onSubscriptionSucceeded = () => {
    const ret: unknown[] = [];
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    channel.members.each((m) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      if (m.info.user === user._id && m.id !== channel.members.me.id) {
        ret.push(m.info);
      }
    });

    setSameUserOnOtherDevices(ret);
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    setMyId(channel.members.myID);
  };

  if (user?._id && user?._account?._id) {
    channel.unbind("pusher:subscription_succeeded");
    channel.unbind("pusher:member_removed");
    channel.unbind("pusher:member_added");
    channel.unbind("client-kick");

    channel.bind("pusher:subscription_succeeded", onSubscriptionSucceeded);
    channel.bind("pusher:member_removed", onMembersChange);
    channel.bind("pusher:member_added", onMembersChange);

    channel.bind("client-kick", (event: Event) => {
      if (event.userId !== user._id) {
        return; // not relevant
      } else if (event.from === myId) {
        return; // don't kick yourself!
      } else {
        setStatus(StatusType.kickedOut);
      }
    });
  }

  const triggerFct = channel.trigger.bind(channel);

  const login = () => {
    if (ENVIRONMENT.LOGIN_URL) window.location.href = ENVIRONMENT.LOGIN_URL;
  };

  const logout = () => {
    const url = queryString.parseUrl(ENVIRONMENT.LOGOUT_URL);
    url.query.reason = "self-kick to url";
    window.location.href = queryString.stringifyUrl(url);
  };

  const kick = useCallback(() => {
    if (myId && user) {
      triggerFct?.("client-kick", {
        userId: user?._id,
        from: myId,
        app_js_version: "2021a",
      });

      setStatus(StatusType.kickedOthers);
    }
  }, [user, myId, triggerFct]);

  return (
    <>
      {status === StatusType.decisionRequired && <DecisionModal logout={logout} kick={kick} />}
      {status === StatusType.kickedOut && <KickedModal login={login} />}
    </>
  );
};
