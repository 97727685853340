import { ISourcingToolSearchFilter } from "react-migration/domains/search/typings/SourcingToolSpecificFilterTypes";
import { searchTypeSet } from "react-migration/domains/search/constants/searchTypes";

export const SearchLocationFilter: ISourcingToolSearchFilter = {
  id: "search-location-id",
  isDefault: true,
  mustIsConfigurable: false,
  isRequired: true,
  type: "location",
  field: "sidebar.react.search_sidebar.location",
  must: "INTERSECT",
  location: {
    type: "Polygon",
    coordinates: [[[]]],
    viewport: true,
  },
  updateFilterDialogIsVisible: false,
  searchTypes: Array.from(searchTypeSet),
};
