import { QueryHookOptions, useQuery } from "@apollo/client";

import { routedClient } from "react-migration/lib/persistence/apollo";

import { Title } from "../../typings/Title";
import titlesOwner from "./titleOwner.gql";

export interface TitlesOwnerQueryData {
  title: Title;
}

export interface TitlesOwnerQueryVariables {
  title_no?: string;
}

/**
 * Takes title_no to return owner information
 * @param title_no: string
 * @returns QueryResult
 */
export function useTitleOwnerQuery(
  title_no?: string,
  options?: QueryHookOptions<TitlesOwnerQueryData, TitlesOwnerQueryVariables>
) {
  const { data, loading, ...queryResult } = useQuery<
    TitlesOwnerQueryData,
    TitlesOwnerQueryVariables
  >(titlesOwner, {
    ...options,
    skip: !title_no,
    variables: { title_no },
    client: routedClient,
  });

  return { titleOwner: data?.title || null, queryResult, loading };
}

export { titlesOwner };
