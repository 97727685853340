import { SelectionHandler } from "../../types";
import { UtilitySelectionDetailView } from "./UtilitySelectionDetailView";
import { UtilitySelectionHeader } from "./UtilitySelectionHeader";
import { UtilitySelectionTitle } from "./UtilitySelectionTitle";

export const UtilitySelectionHandler: SelectionHandler = {
  SelectionTitle: UtilitySelectionTitle,
  SelectionDetailView: UtilitySelectionDetailView,
  SelectionHeader: UtilitySelectionHeader,
};
