import React, { createContext, useEffect, useState } from "react";
import { LayerTypeProviderProps } from "../../../types";
import { noop } from "lodash";
import { useSnapshot } from "valtio";
import { isZoopla } from "react-migration/domains/comparables/util/isZoopla";
import { comparablesStore } from "src/js/stores/comparables";
import {
  IComparablesTransactionDTO,
  ZooplaComparableDTO,
} from "@landtechnologies/comparables-services";
import { RecordType } from "react-migration/domains/comparables/typings/Record";
import {
  ComparableDistributionHookResponse,
  useComparableDistributionQuery,
} from "react-migration/domains/comparables/hooks/useComparableDistributionQuery";
import {
  disabledEPCFields,
  disabledZooplaFields,
} from "react-migration/domains/comparables/util/DisabledFilters";
import { hiddenZooplaFields } from "react-migration/domains/comparables/util/hiddenFields";
import { syncStoreToLocalStorage } from "src/js/stores/comparables/effects";

type TransactionType = IComparablesTransactionDTO[] & ZooplaComparableDTO[];
export interface ComparablesContextType {
  transactions: RecordType[];
  setTransactions: React.Dispatch<React.SetStateAction<TransactionType>>;
  zooplaTransactions: ZooplaComparableDTO[];
  setZooplaTransactions: React.Dispatch<React.SetStateAction<TransactionType>>;
  distribution: ComparableDistributionHookResponse;
}

export const ComparablesLayerContext = createContext<ComparablesContextType>({
  transactions: [],
  setTransactions: noop,
  zooplaTransactions: [],
  setZooplaTransactions: noop,
  distribution: {
    loading: false,
    error: undefined,
    data: undefined,
  },
});

export function ComparablesLayerContextProvider({ children }: LayerTypeProviderProps) {
  const [transactions, setTransactions] = useState<TransactionType>([]);
  const [zooplaTransactions, setZooplaTransactions] = useState<TransactionType>([]);
  const { filters, priceMode } = useSnapshot(comparablesStore);
  const disabledFields = isZoopla(priceMode) ? disabledZooplaFields : disabledEPCFields;
  const hiddenFields = isZoopla(priceMode) ? hiddenZooplaFields : undefined;
  const distribution = useComparableDistributionQuery({
    filters,
    disabledFields,
    hiddenFields,
    field: priceMode,
  });
  useEffect(() => syncStoreToLocalStorage(), []);

  return (
    <ComparablesLayerContext.Provider
      value={{
        transactions,
        setTransactions,
        zooplaTransactions,
        setZooplaTransactions,
        distribution,
      }}
    >
      {children}
    </ComparablesLayerContext.Provider>
  );
}
