import { useState, useMemo } from "react";
import * as React from "react";
import { FirstPage } from "./FirstPage";
import { SecondPage } from "./SecondPage";
import classNames from "classnames";
import { Waypoint } from "react-waypoint";
import { Element } from "react-scroll";
import { Banner } from "react-migration/domains/sites/components/Banner/Banner";
import { useTranslation } from "react-migration/lib/i18n/useTranslation";
import { useSnapshot } from "valtio";
import hasFeature from "src/js/stores/user/actions/hasFeature";
import Feature from "src/js/stores/user/Feature";
import { letterStore } from "src/js/stores/letter/store";

interface LetterPreviewProps {
  showValues?: boolean;
  onScrollUpdate?: (pageId: number) => void;
  onBlockNext: (isBlocked: boolean) => void;
}

export const LetterPreview: React.FC<LetterPreviewProps> = ({
  showValues,
  onScrollUpdate,
  onBlockNext,
}) => {
  const usAccess = hasFeature(Feature.usAccess);
  const { t } = useTranslation();
  const { pageCount } = useSnapshot(letterStore);
  const [isLongPage1, setIsLongPage1] = useState(false);
  const [isLongPage2, setIsLongPage2] = useState(false);
  const onPage1HeightChange = (isLong: boolean) => {
    setIsLongPage1(isLong);
    onBlockNext?.(isLong || isLongPage2);
  };
  const onPage2HeightChange = (isLong: boolean) => {
    setIsLongPage2(isLong);
    onBlockNext?.(isLong || isLongPage1);
  };
  const onWaypointEnter = (pageId: number) => () => {
    onScrollUpdate?.(pageId);
  };
  const longContentErrorMessage = useMemo(
    () =>
      pageCount === 1
        ? "sites.letters.one_page_content_too_long"
        : "sites.letters.letter_content_too_long",
    [pageCount]
  );

  return (
    <Element
      className={classNames(
        "atlas-bg-background-light",
        "atlas-py-6",
        "atlas-px-12",
        "atlas-overflow-y-scroll",
        "atlas-h-full",
        "atlas-w-full"
      )}
      name="letter-preview-container"
      data-testid="letter-preview-container"
      id="letter-preview-container"
    >
      <Element name="page1">
        <Waypoint onEnter={onWaypointEnter(0)} onLeave={onWaypointEnter(1)} topOffset={"50%"}>
          <div>
            {isLongPage1 && (
              <div className="atlas-flex atlas-justify-center">
                <Banner
                  showIcon
                  variant="error"
                  message={t(longContentErrorMessage)}
                  className={classNames("atlas-mb-8", usAccess ? "atlas-w-letter" : "atlas-w-a4")}
                />
              </div>
            )}
            <FirstPage key={1} onHeightChange={onPage1HeightChange} showValues={showValues} />
          </div>
        </Waypoint>
      </Element>
      <Element name="page2">
        {pageCount > 1 && (
          <Waypoint onEnter={onWaypointEnter(1)} onLeave={onWaypointEnter(0)} bottomOffset={"50%"}>
            <div>
              {isLongPage2 && (
                <div className="atlas-flex atlas-justify-center">
                  <Banner
                    showIcon
                    variant="error"
                    message={t(longContentErrorMessage)}
                    className={classNames("atlas-mt-8", usAccess ? "atlas-w-letter" : "atlas-w-a4")}
                  />
                </div>
              )}
              <SecondPage key={2} onHeightChange={onPage2HeightChange} showValues={showValues} />
            </div>
          </Waypoint>
        )}
      </Element>
    </Element>
  );
};
