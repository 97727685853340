import { GeoJsonLayer } from "@deck.gl/layers";
import type { Feature, Point } from "@turf/helpers";
import { useMapLayer } from "react-migration/lib/map/useMapLayer";

import { LayerTypeMapLayerProps } from "../../types";
import {
  POINT_SELECTION_BUFFER_RADIUS_METERS,
  SELECTION_RADIUS_FILL_COLOUR,
  SELECTION_RADIUS_STROKE_COLOUR,
} from "./constants";
import { useId } from "react";
import { isPointSelectionType } from "./utils";

export function UKPropertyInformationMapLayer({ selection, visible }: LayerTypeMapLayerProps) {
  if (!visible || !isPointSelectionType(selection)) {
    return null;
  }
  const selectionFeature = selection?.feature;

  return <PropertyInformationRadiusMapLayer point={selectionFeature} />;
}

interface PropertyInformationRadiusMapLayerProps {
  point: Feature<Point> | undefined;
}

function PropertyInformationRadiusMapLayer({ point }: PropertyInformationRadiusMapLayerProps) {
  const id = `PROPERTY_INFORMATION_${useId()}`;

  useMapLayer(
    () => ({
      layer: new GeoJsonLayer({
        id,
        // @ts-expect-error deckgl types seem too restrictive here
        data: point,
        getFillColor: SELECTION_RADIUS_FILL_COLOUR,
        getLineColor: SELECTION_RADIUS_STROKE_COLOUR,
        stroked: true,
        lineWidthUnits: "pixels",
        pointRadiusUnits: "meters",
        getLineWidth: 3,
        getPointRadius: POINT_SELECTION_BUFFER_RADIUS_METERS,
      }),
      // Introduced to ensure selection geometry renders on-top of competing
      // map layers, but beneath bundle selection layers
      zOrder: 9999999,
    }),
    [id, point]
  );

  return null;
}
