import { Checkbox, CheckboxState } from "@landtechnologies/components";

import classNames from "classnames";

interface SelectOptionItemProps {
  id: string;
  name: string;
  color?: string;
  isSelected: boolean;
  onToggle: (id: string) => void;
}

export const SelectOptionItem = ({
  id,
  name,
  color,
  isSelected,
  onToggle,
}: SelectOptionItemProps) => {
  return (
    <div
      className={classNames(
        "atlas-cursor-pointer",
        "atlas-py-2",
        "atlas-px-2",
        "atlas-mx-2",
        "atlas-rounded",
        "atlas-flex",
        "atlas-flex-row",
        "atlas-gap-2",
        "atlas-items-center",
        "hover:atlas-bg-neutral-100"
      )}
      onClick={() => onToggle(id)}
      data-testid="select-filter-option-item"
    >
      <Checkbox
        data-testid="select-filter-option-item-checkbox"
        className="atlas-cursor-pointer"
        value={isSelected ? CheckboxState.CHECKED : CheckboxState.EMPTY}
        onChange={() => {
          return;
        }}
      />
      {color && (
        <div className="atlas-h-4 atlas-w-2 atlas-rounded" style={{ backgroundColor: color }} />
      )}
      {name}
    </div>
  );
};
