import { ConstraintsStore, ConstraintsStoreAction } from "./types";

export function constraintsStoreReducer(
  state: ConstraintsStore,
  action: ConstraintsStoreAction
): ConstraintsStore {
  switch (action.type) {
    case "SET_LAYER_CATEGORY_TREE":
      return {
        ...state,
        layers: {
          ...state.layers,
          [action.layerId]: {
            ...state.layers[action.layerId],
            categoryTree: action.categoryTree,
          },
        },
      };
    case "SET_LAYER_VISIBLE_CATEGORIES":
      return {
        ...state,
        layers: {
          ...state.layers,
          [action.layerId]: {
            ...state.layers[action.layerId],
            visibleCategories: action.visibleCategoryIds,
          },
        },
      };
    case "SET_LAYER_HIDDEN_DESIGNATION_ATTRIBUTES":
      return {
        ...state,
        layers: {
          ...state.layers,
          [action.layerId]: {
            ...state.layers[action.layerId],
            hiddenDesignationAttributes: action.hiddenDesignationAttributes,
          },
        },
      };
    case "SET_LAYER_HIDDEN_STATUSES":
      return {
        ...state,
        layers: {
          ...state.layers,
          [action.layerId]: {
            ...state.layers[action.layerId],
            hiddenStatuses: action.hiddenStatuses,
          },
        },
      };

    case "SET_LAYER_CONFIG_HASH":
      return {
        ...state,
        layers: {
          ...state.layers,
          [action.layerId]: {
            ...state.layers[action.layerId],
            layerConfigHash: action.layerConfigHash,
          },
        },
      };

    default:
      return state;
  }
}
