import { AlertResult } from "react-migration/domains/dashboard/typings/PlanningAlertResultsResponse";
import { PlanningResultActions } from "./PlanningResultActions";
import { useTranslation } from "react-migration/lib/i18n/useTranslation";
import { dateSemiFullString } from "src/js/util/dates";
type PlanningResultRowProps = {
  result: AlertResult;
};

export const PlanningResultRow = ({ result }: PlanningResultRowProps) => {
  const { t } = useTranslation();

  return (
    <div
      data-testid={`planning-result-row-${result._id}`}
      className="atlas-p-3 atlas-border atlas-border-t-0 atlas-border-neutral-200 atlas-flex atlas-flex-col"
    >
      <h3 className="atlas-font-semibold atlas-text-neutral-800 atlas-mb-[6px]">
        {result.alert_info.join(" ")}
      </h3>
      <div className="atlas-flex atlas-gap-1 atlas-flex-wrap atlas-mb-2">
        {result.result_item.labels.map((label) => (
          <div
            key={label.name}
            className="atlas-bg-blue-600 atlas-text-white atlas-rounded atlas-px-2 atlas-py-[2px]"
          >
            {label.name}
          </div>
        ))}
      </div>
      <p className="atlas-text-sm">{result.result_item.title}</p>
      <div className="atlas-mb-2">
        {result.result_item.address && (
          <div className="atlas-flex atlas-gap-1 atlas-text-sm">
            <span className="atlas-font-bold">{t("components.planning_record.address")}</span>
            <span>{result.result_item.address}</span>
          </div>
        )}
        {result.result_item.type && (
          <div className="atlas-flex atlas-gap-1 atlas-text-sm">
            <span className="atlas-font-bold">{t("components.planning_record.type")}</span>
            <span>{result.result_item.type}</span>
          </div>
        )}
        {result.result_item.decision && (
          <div className="atlas-flex atlas-gap-1 atlas-text-sm">
            <span className="atlas-font-bold">{t("components.planning_record.decision")}</span>
            <span>{result.result_item.decision}</span>
          </div>
        )}
        {result.result_item.applicant_name && (
          <div className="atlas-flex atlas-gap-1 atlas-text-sm">
            <span className="atlas-font-bold">{t("components.planning_record.applicant")}</span>
            <span>{result.result_item.applicant_name}</span>
          </div>
        )}
        {result.result_item.classification && (
          <div className="atlas-flex atlas-gap-1 atlas-text-sm">
            <span className="atlas-font-bold">
              {t("components.planning_record.classification")}
            </span>
            <span>{result.result_item.classification}</span>
          </div>
        )}
        {result.result_item.size && (
          <div className="atlas-flex atlas-gap-1 atlas-text-sm">
            <span className="atlas-font-bold">{t("components.planning_record.size")}</span>
            <span>{result.result_item.size}</span>
          </div>
        )}
      </div>
      <PlanningResultActions result={result} />
      <div className="atlas-flex atlas-justify-between atlas-items-baseline">
        <div className="atlas-flex atlas-gap-2 atlas-text-sm">
          <span className="atlas-text-neutral-500">{result.result_item.ref}</span>
          <span className="atlas-text-[#008000]">
            {t("dashboard.planning_alerts.application_decision", {
              decision: result.result_item.decision,
            })}
          </span>
        </div>
        <div className="atlas-text-neutral-500 atlas-text-xs">
          <span className="atlas-px-2 atlas-leading-[18px] atlas-border-r atlas-border-solid atlas-border-neutral-300">
            {t("dashboard.planning_alerts.alert_sent_date", {
              date: dateSemiFullString(result.alert_date),
            })}
          </span>
          <span className="atlas-py-1 atlas-px-2">
            {t("dashboard.planning_alerts.alert_received_date", {
              date: dateSemiFullString(result.result_item.date_received),
            })}
          </span>
        </div>
      </div>
    </div>
  );
};
