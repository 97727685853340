import { OutputAreaAttribute } from "./types";
import { formatDollar, formatNumber, formatPeople, formatPerSqMi } from "./utils";

export const UNEMPLOYMENT_ATTRS: OutputAreaAttribute[] = [
  { attributeKey: "population_unemployed_total", label: "Total" },
  { attributeKey: "population_unemployed_male", label: "Male" },
  { attributeKey: "population_unemployed_female", label: "Female" },
];

export const INCOME_ATTRS: OutputAreaAttribute[] = [
  { attributeKey: "median_household_income", label: "Median household", format: formatDollar },
  { attributeKey: "avg_household_income", label: "Average household", format: formatDollar },
];

export const POPULATION_ATTRS: OutputAreaAttribute[] = [
  { attributeKey: "population_density_per_sq_mile", label: "Density", format: formatPerSqMi },
  { attributeKey: "population", label: "Population", format: formatNumber },
  {
    attributeKey: "population_5_year_projection",
    label: "5 year projection",
    format: formatNumber,
  },
  { attributeKey: "population_percent_change_2010", label: "Change since 2010" },
];

export const AGE_ATTRS: OutputAreaAttribute[] = [
  { attributeKey: "median_age", label: "Median age" },
  { attributeKey: "population_under_18", label: "Under 18" },
  { attributeKey: "population_18_to_64", label: "18 to 64" },
  { attributeKey: "population_65_plus", label: "65 plus" },
];

export const HOUSING_UNITS_ATTRS: OutputAreaAttribute[] = [
  { attributeKey: "owner_households_median_value", label: "Median value", format: formatDollar },
  { attributeKey: "median_rent", label: "Median rent", format: formatDollar },
  { attributeKey: "housing_units", label: "Housing units", format: formatNumber },
  {
    attributeKey: "housing_units_owner_occupied_percent",
    label: "Owner occupied",
  },
  {
    attributeKey: "housing_units_owner_occupied",
    label: "Owner occupied units",
    format: formatNumber,
  },
  {
    attributeKey: "housing_units_renter_occupied_percent",
    label: "Renter occupied",
  },
  {
    attributeKey: "housing_units_renter_occupied",
    label: "Renter occupied units",
    format: formatNumber,
  },
  { attributeKey: "vacant_housing_units", label: "Vacant units", format: formatNumber },
];

export const HOUSEHOLD_ATTRS: OutputAreaAttribute[] = [
  { attributeKey: "median_household_size", label: "Median size", format: formatPeople },
  { attributeKey: "households", label: "Households", format: formatNumber },
  {
    attributeKey: "households_5_year_projection",
    label: "5 year projection",
    format: formatNumber,
  },
  { attributeKey: "households_percent_change_2010", label: "Change since 2010" },
  {
    attributeKey: "households_family_with_children_percent",
    label: "Families with children",
  },
];
