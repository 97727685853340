import { SOURCE_STATUSES } from "react-migration/domains/constraints/constants";

interface SourceStatusProps {
  status?: string;
}

export function SourceStatus({ status }: SourceStatusProps) {
  const sourceStatus = SOURCE_STATUSES.find((x) => x.id === status);
  if (!sourceStatus || status === "adopted") return null;

  return (
    <div className="atlas-rounded-full atlas-bg-orange-500 atlas-p-1 atlas-px-2 atlas-text-white atlas-text-3xs atlas-font-normal">
      {sourceStatus.display_name}
    </div>
  );
}
