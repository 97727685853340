import { useFormContext } from "react-hook-form";
import classNames from "classnames";
import { useTranslation } from "react-migration/lib/i18n/useTranslation";
import { TextInput } from "react-migration/components/TextInput";
import { TemplateFormData } from "../TemplateFormDataSchema";

export const SenderFormSection: React.FC = () => {
  const { t } = useTranslation();

  const titleClassNames = ["atlas-font-semibold", "atlas-text-sm", "atlas-leading-5"];

  const {
    register,
    formState: { errors },
  } = useFormContext<TemplateFormData>();

  return (
    <div className="atlas-mt-4" data-testid="sender-form">
      <p className={classNames(titleClassNames, "atlas-mb-1.5")} data-testid="sender-form-title">
        {t("sites.letters.sender_form.return_address")}
      </p>

      <p
        className={classNames(
          "atlas-font-normal",
          "atlas-text-xs",
          "atlas-leading-3",
          "atlas-mb-4"
        )}
      >
        {t("sites.letters.sender_form.needed_to_send_a_physical_letter")}
      </p>

      <TextInput
        data-testid="sender-form-name-input"
        label={t("sites.letters.sender_form.name")}
        {...register("senderName")}
        error={
          (errors?.senderName?.message && t(errors?.senderName?.message as string)) ?? undefined
        }
      />

      <TextInput
        data-testid="sender-form-address-line-1-input"
        label={t("sites.letters.sender_form.address_line_1")}
        {...register("senderAddressLine1")}
        error={
          (errors?.senderAddressLine1?.message &&
            t(errors?.senderAddressLine1?.message as string)) ??
          undefined
        }
      />

      <TextInput
        data-testid="sender-form-city-input"
        label={t("sites.letters.sender_form.city")}
        {...register("senderCity")}
        error={
          (errors?.senderCity?.message && t(errors?.senderCity?.message as string)) ?? undefined
        }
      />

      <TextInput
        data-testid="sender-form-province-input"
        label={t("sites.letters.sender_form.province")}
        {...register("senderProvince")}
        error={
          (errors?.senderProvince?.message && t(errors?.senderProvince?.message as string)) ??
          undefined
        }
      />

      <TextInput
        data-testid="sender-form-postcode-input"
        label={t("sites.letters.sender_form.postcode")}
        {...register("senderPostcode")}
        error={
          (errors?.senderPostcode?.message && t(errors?.senderPostcode?.message as string)) ??
          undefined
        }
      />
    </div>
  );
};
