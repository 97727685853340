import { useCallback, useEffect, useMemo } from "react";
import {
  CheckboxTree,
  CheckboxTreeChange,
  TreeStructure,
} from "react-migration/components/CheckboxTree";
import { ModalV2 } from "react-migration/components/ModalV2/ModalV2";
import { DesignationHatch } from "react-migration/domains/constraints/components/Hatch";
import { SourceStatusFilters } from "react-migration/layouts/map/Constraints/SourceStatusFilters";
import { useLayerStore } from "./ConstraintsContext/store";
import { LayerTypeControlPageProps } from "../../types";
import { AttributeFilter, ConstraintLayerConfig } from ".";
import { CATEGORY_ATTRIBUTES } from "react-migration/domains/constraints/designation/style/StyleMap";
import { GenericCategoryFilter } from "react-migration/layouts/map/Constraints/GenericCategoryFilter";
import {
  getDesignationFillColour,
  getDesignationLineColour,
} from "react-migration/domains/constraints/designation/style/accessors";
import { usePersistentToggleState } from "react-migration/components/CheckboxTree/usePersistentToggleState";
import { useConstraintsLayerTypeContext } from "./ConstraintsContext";
import clsx from "clsx";

export function ConstraintsControlPage({ layer, disabled }: LayerTypeControlPageProps) {
  const layerConfig = layer.layerConfig! as ConstraintLayerConfig;
  const { constraintsStore, dispatch } = useConstraintsLayerTypeContext();
  const [layerState, layerActions, layerSelectors] = useLayerStore(layer.id, {
    constraintsStore,
    dispatch,
  });
  const persistenceKey = `constraints-collapsed-categories-${layer.id}`;
  const [getCollapsed, setCollapsed] = usePersistentToggleState(persistenceKey);

  //TODO: This clears the old checkbox-tree-collapsed state (10/07/22) - give it a couple of weeks, and then remove this when you see it. MP
  useEffect(
    function cleanStaleLocalStorage() {
      const keysToRemove = Object.keys(localStorage).filter((key) =>
        key.startsWith("checkbox-tree-collapsed-")
      );

      for (const key of keysToRemove) {
        localStorage.removeItem(key);
      }
    },
    [persistenceKey]
  );

  const attributeProperties = CATEGORY_ATTRIBUTES.find((x) => x.id === layerConfig.rootCategory);

  const titleComponent = layerConfig.showTitle ? (
    <div className="atlas-font-semibold atlas-flex atlas-gap-x-1 atlas-items-center">
      <div>{attributeProperties?.displayName}</div>
      <ModalV2.Root>
        <ModalV2.Trigger>
          <i className="icon-lt-question-line atlas-text-lg atlas-text-content-tertiary" />
        </ModalV2.Trigger>
        <ModalV2.Body title={attributeProperties?.displayName}>
          {attributeProperties?.description}
        </ModalV2.Body>
      </ModalV2.Root>
    </div>
  ) : undefined;

  if (!layerState) return null;

  return (
    <>
      <div
        className={clsx("atlas-flex atlas-flex-col atlas-space-y-2", {
          "atlas-opacity-50": disabled,
        })}
      >
        <GenericCategoryFilter
          id={layerConfig.rootCategory}
          title={titleComponent}
          disabled={disabled}
          categorySchema={layerConfig.categorySchema}
          visibleCategoryIds={layerState.visibleCategories}
          categoryTree={layerSelectors.getCategoryTree()}
          setVisibleCategories={layerActions.setVisibleCategories}
          Hatch={layerConfig.disableHatch ? undefined : DesignationHatch}
          getCollapsed={getCollapsed}
          setCollapsed={setCollapsed}
        />
        {layerConfig.attributeFilters?.map((attributeFilter) => (
          <DesignationAttributeFilter
            key={attributeFilter.attribute}
            attributeFilter={attributeFilter}
            setHiddenDesignationAttributes={layerActions.setHiddenDesignationAttributes}
            hiddenDesignationAttributes={layerState.hiddenDesignationAttributes}
            layerConfig={layerConfig}
            disabled={disabled}
          />
        ))}
      </div>
      {layerConfig.statusSchema && (
        <>
          <div
            className={clsx("atlas-pt-2 atlas-pb-1 atlas-text-base", {
              "atlas-opacity-50": disabled,
            })}
          >
            Filters
          </div>
          <div className="atlas-px-2 atlas-py-3">
            <SourceStatusFilters
              statusSchema={layerConfig.statusSchema}
              hiddenStatuses={layerState.hiddenStatuses}
              setHiddenStatuses={layerActions.setHiddenStatuses}
              disabled={disabled}
            />
          </div>
        </>
      )}
    </>
  );
}

type DesignationAttributeFilterProps = {
  attributeFilter: AttributeFilter;
  layerConfig: ConstraintLayerConfig;
  disabled: boolean;
  hiddenDesignationAttributes: string[];
  setHiddenDesignationAttributes(attrIds: string[]): void;
};

const DesignationAttributeFilter = ({
  attributeFilter,
  layerConfig,
  disabled,
  hiddenDesignationAttributes,
  setHiddenDesignationAttributes,
}: DesignationAttributeFilterProps) => {
  // @todo This currently makes the assumption that all categories in this layer
  // share the same dynamic attribute styles
  const firstCategory = layerConfig.categorySchema.flatMap((c) => c.key)[0] || "";
  const { attribute, options } = attributeFilter;
  const attributeProperties = CATEGORY_ATTRIBUTES.find((x) => x.id === attribute);

  const { rootNode, treeSchema } = useMemo(() => {
    const rootNode: TreeStructure = {
      display_name: `${attribute}_filter-root-node`,
      id: `${attribute}_filter-root-node`,
      childNodes: options.map(({ value, display_name }) => {
        const fillColor = getDesignationFillColour({
          sub_category_id: firstCategory,
          designation_attributes: [
            {
              id: attribute,
              key: attribute,
              value: value,
            },
          ],
        });

        const lineColor = getDesignationLineColour({
          sub_category_id: firstCategory,
          designation_attributes: [
            {
              id: attribute,
              key: attribute,
              value: value,
            },
          ],
        });

        return {
          id: value,
          display_name: display_name || value,
          childNodes: [],
          fillColorOverride: fillColor,
          lineColorOverride: lineColor,
        };
      }),
    };

    const treeSchema = rootNode?.childNodes?.map((node) => ({ key: node.id })) || [];

    return { rootNode, treeSchema };
  }, [attribute, firstCategory, options]);

  const titleComponent = (
    <div className="atlas-font-semibold atlas-flex atlas-gap-x-1 atlas-items-center">
      <div>{attributeProperties?.displayName}</div>
      <ModalV2.Root>
        <ModalV2.Trigger>
          <i className="icon-lt-question-line atlas-text-lg atlas-text-content-tertiary" />
        </ModalV2.Trigger>
        <ModalV2.Body title={attributeProperties?.displayName}>
          {attributeProperties?.description}
        </ModalV2.Body>
      </ModalV2.Root>
    </div>
  );

  const visibleNodeIds = useMemo(
    () => [
      ...treeSchema
        .map((x) => x.key)
        .filter((value) => !hiddenDesignationAttributes.includes(value)),
      rootNode.id,
    ],
    [hiddenDesignationAttributes, rootNode.id, treeSchema]
  );

  const onChangeHandler = useCallback<CheckboxTreeChange>(
    ({ visibleNodeIds }) =>
      setHiddenDesignationAttributes(
        treeSchema.filter((node) => !visibleNodeIds.includes(node.key)).map((node) => node.key)
      ),
    [setHiddenDesignationAttributes, treeSchema]
  );

  return (
    <div className="">
      <CheckboxTree
        title={titleComponent}
        Hatch={DesignationHatch}
        treeStructure={rootNode}
        treeSchema={treeSchema}
        visibleNodeIds={visibleNodeIds}
        onChange={onChangeHandler}
        disabled={disabled}
      />
    </div>
  );
};
