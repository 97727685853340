import { ResponsiveLine } from "@nivo/line";
import { useMemo } from "react";
import { uniq } from "lodash";
import { useArrayToggle } from "react-migration/lib/util/useArrayToggle";
import { isDefined } from "react-migration/lib/util/isDefined";
import { getAttributeNumericValue } from "../../utils";
import { ValueFormatter, formatNumber, hasAnyRequiredAttribute } from "../utils";
import { OutputAreaAttribute } from "../types";
import { ChartTooltip } from "./ChartTooltip";
import { Legend } from "./Legend";
import { Color } from "d3-color";
import {
  SingleDesignation,
  SingleDesignationDesignationAttribute,
} from "react-migration/domains/constraints/typings/applicationTypes/SingleDesignation";

const CHART_HEIGHT = 200;
const TICK_SIZE = 5;
const TICK_PADDING = 5;
const TEXT_HEIGHT = 11;
const LABEL_PADDING = 12;

const CURVE = "linear"; //"natural";
const MAX_Y_TICKS = 5;
const MAX_X_TICKS = 10;
const ENABLE_POINTS_CUTOFF = 10;

type VariableDataPoint = OutputAreaAttribute & { value?: never; xValue: number };
type FixedDataPoint = {
  value: number;
  xValue: number;
  format?: ValueFormatter;
  attributeKey?: never;
};

export type DataPoint = VariableDataPoint | FixedDataPoint;

interface LinearTrendChartProps {
  outputAreas: SingleDesignation[];
  dataPoints: DataPoint[];
  labelY?: string;
  customRangeY?: [number | null, number | null];
  colorAccessor: (id: string) => Color;
}

export function LinearTrendChart({
  dataPoints,
  labelY,
  outputAreas,
  customRangeY,
  colorAccessor,
}: LinearTrendChartProps) {
  const format = dataPoints.find((point) => point.format)?.format ?? formatNumber;
  const variableDataPoints = dataPoints.filter((point) =>
    isDefined(point.attributeKey)
  ) as VariableDataPoint[];

  const [hiddenSeries, toggleSeriesById] = useArrayToggle();

  const series = useMemo(
    () =>
      uniq(outputAreas)
        .filter(isDefined)
        .filter((outputArea) => hasAnyRequiredAttribute(variableDataPoints, outputArea))
        .map(({ designation_attributes, id, display_name }) => ({
          id,
          display_name,
          color: colorAccessor(id),
          data: dataPoints
            .map((point) => ({
              x: point.xValue,
              y: getDataPointValue(point, designation_attributes),
            }))
            .filter(({ y }) => isDefined(y) || y === 0)
            .sort((a, b) => a.x - b.x),
        }))
        .filter(({ data }) => !!data.length),
    [outputAreas, variableDataPoints, colorAccessor, dataPoints]
  );

  const textLengthY = 25; // TODO: estimate this dynamically
  const textLengthX = 25; // TODO: estimate this dynamically

  const tickLengthY = TICK_SIZE + TICK_PADDING + textLengthY;
  const labelOffsetY = tickLengthY + LABEL_PADDING + TEXT_HEIGHT / 2;
  const marginLeft = tickLengthY + (labelY ? TEXT_HEIGHT + LABEL_PADDING : 0);

  const marginBottom = TICK_SIZE + TICK_PADDING + TEXT_HEIGHT;

  const marginTop = TEXT_HEIGHT / 2;
  const marginRight = textLengthX / 2;

  const totalHeight = marginTop + CHART_HEIGHT + marginBottom;

  return (
    <div className="atlas-flex atlas-flex-col atlas-gap-4">
      <div
        style={{
          height: `${totalHeight}px`,
        }}
      >
        <ResponsiveLine
          data={series.filter(({ id }) => !hiddenSeries.includes(id))}
          margin={{ bottom: marginBottom, left: marginLeft, top: marginTop, right: marginRight }}
          isInteractive={true}
          tooltip={({ point }) => (
            <ChartTooltip
              formattedValue={format(point.data.y as number)}
              color={point.serieColor}
            />
          )}
          curve={CURVE}
          xScale={{
            type: "linear",
            min: "auto",
            max: "auto",
          }}
          yScale={{
            min: customRangeY?.[0] ?? "auto",
            max: customRangeY?.[1] ?? "auto",
            type: "linear",
            nice: !customRangeY,
            stacked: false,
            reverse: false,
          }}
          axisTop={null}
          axisRight={null}
          axisBottom={{
            tickSize: TICK_SIZE,
            tickValues: Math.min(dataPoints.length + 2, MAX_X_TICKS),
          }}
          axisLeft={{
            tickSize: TICK_SIZE,
            tickPadding: TICK_PADDING,
            tickValues: MAX_Y_TICKS,
            legend: labelY,
            legendOffset: -labelOffsetY,
            legendPosition: "middle",
            format,
          }}
          colors={(d) => d.color.toString()}
          enableGridX={false}
          enablePoints={dataPoints.length < ENABLE_POINTS_CUTOFF}
          useMesh={true}
          theme={{
            axis: {
              ticks: { text: { fill: "#6d7482" } },
              domain: {
                line: {
                  stroke: "#e5e7eb",
                  strokeWidth: 2,
                },
              },
            },
          }}
          pointSize={6}
          pointColor={"white"}
          pointBorderWidth={2}
          pointBorderColor={{ from: "serieColor" }}
        />
      </div>

      <Legend
        hiddenSeries={hiddenSeries}
        series={series}
        toggleSeriesById={toggleSeriesById}
        paddingLeft={marginLeft}
      />
    </div>
  );
}
function getDataPointValue(
  dataPoint: DataPoint,
  designation_attributes: SingleDesignationDesignationAttribute[]
) {
  return dataPoint.attributeKey
    ? getAttributeNumericValue(designation_attributes, dataPoint.attributeKey)
    : dataPoint.value;
}
