import area from "@turf/area";
import buffer from "@turf/buffer";
import {
  Feature,
  Geometry,
  GeometryCollection,
  MultiPolygon,
  Polygon,
  Properties,
} from "@turf/helpers";
import intersect from "@turf/intersect";
import { getGeom } from "@turf/turf";
import { mean, orderBy } from "lodash";
import truncate from "@turf/truncate";
import { Nullable } from "src/js/types/Nullable";
import { SelectionGeometry } from "src/js/stores/map/store";
import length from "@turf/length";

type FeatureGeometries = Geometry | GeometryCollection | null | undefined;

const isPolygon = (geometry: FeatureGeometries): geometry is Polygon =>
  geometry?.type === "Polygon";

const isMultiPolygon = (geometry: FeatureGeometries): geometry is MultiPolygon =>
  geometry?.type === "MultiPolygon";

//turf seems to have a bug with some complex geometries with high precision, so we truncate the geometries to avoid it
//https://github.com/Turfjs/turf/issues/2048

export function safeIntersects<P = Properties>(
  poly1: Polygon | MultiPolygon,
  poly2: Polygon | MultiPolygon,
  options?: {
    properties?: P;
  }
): Feature<Polygon | MultiPolygon, P> | null {
  const truncateOpts = { precision: 8, coordinates: 2 };
  return intersect(truncate(poly1, truncateOpts), truncate(poly2, truncateOpts), options);
}

export const isPolygonOrMultiPolygon = (
  geometry: FeatureGeometries
): geometry is Polygon | MultiPolygon => isMultiPolygon(geometry) || isPolygon(geometry);

export function intersectionSignificance(
  geom1: Polygon | MultiPolygon | null,
  geom2: Polygon | MultiPolygon | null
) {
  if (geom1 === null || geom2 === null) return 0;
  const intersection = safeIntersects(geom1, geom2);

  if (!intersection) return 0;
  const intersectionArea = area(intersection);
  return mean([intersectionArea / area(geom1), intersectionArea / area(geom2)]);
}

export function bufferedIntersection(
  geom1: Polygon | MultiPolygon,
  geom2: Polygon | MultiPolygon,
  distance: number
): Feature<Polygon | MultiPolygon> | null {
  const intersection = safeIntersects(geom1, geom2);

  return intersection
    ? buffer(intersection, distance, {
        units: "meters",
      })
    : null;
}

export function intersectionSignificanceOrdering<T extends Geometry, P>(
  baseGeometry: T,
  items: P[],
  geometryAccessor: (item: P) => Polygon | MultiPolygon | null
) {
  if (!isPolygonOrMultiPolygon(baseGeometry)) return items;

  const sortedGeometries = orderBy(
    items,
    (item) => intersectionSignificance(baseGeometry, geometryAccessor(item)),
    ["desc"]
  );

  return sortedGeometries;
}

export function negativeBuffer(
  geometry: Nullable<SelectionGeometry>,
  maxRadiusInMeters = 1,
  /** A factor to scale geometry based on it's dimensions. */
  scale = 0.3
): Nullable<SelectionGeometry> {
  if (!isPolygonOrMultiPolygon(geometry)) return geometry;

  const proportionalBuffer = (area(geometry) / length(geometry, { units: "meters" })) * scale;

  return getGeom(
    buffer(geometry, -Math.min(proportionalBuffer, maxRadiusInMeters), { units: "meters" })
  );
}
