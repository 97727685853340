import Feature from "src/js/stores/user/Feature";
import hasFeature from "src/js/stores/user/actions/hasFeature";

export enum ReportSection {
  OWNERSHIP = "ownership",
  PLANNING = "planning",
  DESIGNATIONS = "designations",
  POWER = "power",
  COMPARABLES = "comparables",
  ZONING = "zoning",
  HAZARDS = "hazards",
  DEMOGRAPHICS = "demographics",
  PERMITS = "permits",
  BOUNDARIES_SATELLITE = "boundaries_satellite",
  BOUNDARIES_ROADMAP = "boundaries_roadmap",
}

export const getTogglesConfig = () => [
  {
    title: "sites.card.site_report.ownership",
    propertyName: ReportSection.OWNERSHIP,
    condition: true,
  },
  {
    title: "sites.card.site_report.planning",
    propertyName: ReportSection.PLANNING,
    condition: hasFeature(Feature.planningLayer),
  },
  {
    title: "sites.card.site_report.considerations",
    propertyName: ReportSection.DESIGNATIONS,
    condition: !hasFeature(Feature.usAccess),
  },
  {
    title: "sites.card.site_report.power",
    propertyName: ReportSection.POWER,
    condition: hasFeature(Feature.powerLayer),
  },
  {
    title: "sites.card.site_report.zoning",
    propertyName: ReportSection.ZONING,
    condition: hasFeature(Feature.usAccess),
  },
  {
    title: "sites.card.site_report.hazards",
    propertyName: ReportSection.HAZARDS,
    condition: hasFeature(Feature.usAccess),
  },
  {
    title: "sites.card.site_report.demographics",
    propertyName: ReportSection.DEMOGRAPHICS,
    condition: hasFeature(Feature.usAccess),
  },
  {
    title: "sites.card.site_report.permits",
    propertyName: ReportSection.PERMITS,
    condition: hasFeature(Feature.usAccess),
  },
  {
    title: "sites.card.site_report.comparables",
    propertyName: ReportSection.COMPARABLES,
    condition: true,
  },
];
