import { PlanningAlertsTable } from "./components/PlanningAlertsTable/PlanningAlertsTable";
import { Loading } from "@landtechnologies/components";
import ROUTES from "src/js/router/routes";
import { router } from "src/js/router/react-router";
import { useTranslation } from "react-migration/lib/i18n/useTranslation";
import { useSiteWithAlertsQuery } from "./hooks/useSiteWithAlertsQuery";
import { useMemo } from "react";
import { cloneDeep } from "lodash";
import { useSelectedAlertSubscriptionQuery } from "./hooks/useSelectedAlertSubscriptionQuery";
import { getParamFromQueryParams } from "./util/helpers";
import { usePlanningAlertResultsQuery } from "./hooks/usePlanningAlertResultsQuery";
import { PlanningAlert } from "../typings/PlanningAlert";
import { BackToAlerts } from "./components/BackToAlerts";
import { EmailAlerts } from "./components/EmailAlerts";
import { SiteHeader } from "./components/SavedSitesAlertsSection/SiteHeader";
import { PlanningResultsTable } from "./components/PlanningResultsTable";

type PlanningAlertDetailsProps = {
  alertId: string;
};
export const PlanningAlertDetails = ({ alertId }: PlanningAlertDetailsProps) => {
  const { t } = useTranslation();
  const { data, loading } = useSelectedAlertSubscriptionQuery({ alert_id: alertId });
  const email = getParamFromQueryParams("email");

  const { data: siteData, loading: isSiteLoading } = useSiteWithAlertsQuery({
    _id: data?.selectedAlertSubscription?.search?._site,
  });

  const {
    data: dataAlertsTable,
    loading: loadingAlertsTable,
    fetchMore,
  } = usePlanningAlertResultsQuery({
    cursor: null,
    emailed_at: email,
    limit: 15,
    subscription_id: alertId,
  });

  const handleWaypointEnter = () => {
    if (dataAlertsTable?.planningAlertResults.pageInfo.hasNextPage) {
      fetchMore({
        variables: {
          cursor: dataAlertsTable?.planningAlertResults.pageInfo.endCursor,
          emailed_at: email,
          limit: 15,
          subscription_id: alertId,
        },
        updateQuery: (old, { fetchMoreResult }) => {
          const ret = cloneDeep(old);
          const newResults = fetchMoreResult.planningAlertResults;

          ret.planningAlertResults.nodes = ret.planningAlertResults.nodes.concat(newResults.nodes);
          ret.planningAlertResults.pageInfo.hasNextPage = newResults.pageInfo.hasNextPage;
          ret.planningAlertResults.pageInfo.endCursor = newResults.pageInfo.endCursor;

          return ret;
        },
      });
    }
  };

  const results = useMemo(
    () =>
      siteData?.siteWithAlerts?.planning_alert_subscriptions.reduce(
        (acc, planningAlert) => acc + planningAlert.results_count,
        0
      ),
    [siteData]
  );

  const handleEdit = (planningAlert: PlanningAlert) => {
    if (planningAlert.search?._site) {
      router.push({
        name: ROUTES.SITES,
        query: {
          card_page: "summary",
          site_id: planningAlert.search._site,
        },
      });
    } else {
      router.push({
        name: ROUTES.LEGACY_SEARCH,
        query: {
          activeSearch: "planning",
          edit_alert: planningAlert._id,
        },
      });
    }
  };

  const showLoading =
    loading || !data || (data?.selectedAlertSubscription?.search?._site && isSiteLoading);

  return (
    <div data-testid="ownership-alerts">
      <h2 className="atlas-text-sm atlas-font-medium md:atlas-text-3xl atlas-mb-6">
        {t("dashboard.planning_alerts.planning_alerts")}
      </h2>
      <EmailAlerts />

      {showLoading ? (
        <Loading />
      ) : (
        <>
          <BackToAlerts planningAlert={data.selectedAlertSubscription} />
          {siteData?.siteWithAlerts?.title && (
            <SiteHeader
              _id={siteData?.siteWithAlerts?._id}
              title={siteData?.siteWithAlerts?.title}
              results={results}
            />
          )}
          <PlanningAlertsTable
            detailed
            planningAlerts={[data?.selectedAlertSubscription]}
            totalNumber={dataAlertsTable?.planningAlertResults?.totalCount}
            onEdit={handleEdit}
          />
          <PlanningResultsTable
            loading={loadingAlertsTable}
            data={dataAlertsTable}
            handleWaypointEnter={handleWaypointEnter}
          />
        </>
      )}
    </div>
  );
};
