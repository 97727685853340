import { identity } from "lodash";
import {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";
import { LogEventProps } from "react-migration/lib/util/logEvent";

export type TrackingAppender<
  T extends LogEventProps = LogEventProps,
  U extends LogEventProps = LogEventProps
> = (current: Partial<T> & U) => T & U;
type TrackingAppenders = Record<string, TrackingAppender>;

interface EventTrackingContextProps {
  bundleMeta: { bundleId: string; visibleLayerIds: string };
  getTrackingAppender: (key: string) => TrackingAppender;
  registerTrackingAppender: (key: string, appender: TrackingAppender) => void;
  unregisterTrackingAppender: (key: string) => void;
}

const EventTrackingContext = createContext<EventTrackingContextProps>(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  {} as any as EventTrackingContextProps
);

export const useEventTracking = () => useContext(EventTrackingContext);

const DEFAULT_TRACKING_APPENDER = identity;

interface EventTrackingProviderProps {
  bundleId: string;
  visibleLayerIds: string[];
}

export function EventTrackingProvider({
  bundleId,
  visibleLayerIds,
  children,
}: PropsWithChildren<EventTrackingProviderProps>) {
  const [trackingAppenders, setTrackingAppenders] = useState<TrackingAppenders>({});

  const getTrackingAppender = useCallback(
    (key: string) => {
      return trackingAppenders[key] || DEFAULT_TRACKING_APPENDER;
    },
    [trackingAppenders]
  );

  const registerTrackingAppender = useCallback((key: string, selector: TrackingAppender) => {
    setTrackingAppenders((prev) => ({ ...prev, [key]: selector }));
  }, []);

  const unregisterTrackingAppender = useCallback((key: string) => {
    setTrackingAppenders((prev) => {
      const newTrackingAppenders = { ...prev };
      delete newTrackingAppenders[key];
      return newTrackingAppenders;
    });
  }, []);

  const bundleMeta = useMemo(
    () => ({
      bundleId,
      visibleLayerIds: visibleLayerIds.sort().join(","),
    }),
    [bundleId, visibleLayerIds]
  );

  return (
    <EventTrackingContext.Provider
      value={{
        bundleMeta,
        getTrackingAppender,
        registerTrackingAppender,
        unregisterTrackingAppender,
      }}
    >
      {children}
    </EventTrackingContext.Provider>
  );
}
