import { Color } from "deck.gl";
import { ENVIRONMENT } from "src/js/util/environment";

export const OPACITY_20 = Math.ceil(255 * 0.2);
export const OPACITY_40 = Math.ceil(255 * 0.4);
export const OPACITY_60 = Math.ceil(255 * 0.6);
export const OPACITY_80 = Math.ceil(255 * 0.8);
export const OPACITY_90 = Math.ceil(255 * 0.9);
export const TRANSPARENT: Color = [0, 0, 0, 0];

const _Colour: Record<string, Color> = Object.fromEntries(
  [
    ["MAROON", [127, 29, 29]],
    ["RED", [225, 29, 72]],
    ["LIGHT_PINK", [253, 164, 175]],
    ["BROWN", [191, 111, 20]],
    ["ORANGE", [249, 115, 22]],
    ["APRICOT", [253, 186, 116]],
    ["OLIVE", [194, 194, 10]],
    ["YELLOW", [250, 204, 21]],
    ["CREAM", [254, 249, 195]],
    ["LIME", [163, 230, 53]],
    ["EMERALD", [6, 78, 59]],
    ["GREEN", [34, 197, 94]],
    ["MINT", [170, 255, 195]],
    ["CYAN", [34, 211, 238]],
    ["BLUE", [59, 130, 246]],
    ["PURPLE", [126, 34, 206]],
    ["LAVENDER", [216, 180, 254]],
    ["TEAL", [13, 148, 136]],
    ["MAGENTA", [239, 70, 231]],
    ["NAVY", [0, 0, 117]],
    ["BLACK", [0, 0, 0]],
    ["WHITE", [255, 255, 255]],
    ["GREY", [128, 128, 128]],
  ].flatMap(([colourName, colour]) => {
    return [
      [colourName, colour],
      [colourName + "_20", [...colour, OPACITY_20]],
      [colourName + "_40", [...colour, OPACITY_40]],
      [colourName + "_60", [...colour, OPACITY_60]],
      [colourName + "_80", [...colour, OPACITY_80]],
      [colourName + "_90", [...colour, OPACITY_90]],
    ];
  })
);

/**
 * Access pallette colours by name:
 *    Colour.GREEN
 * Add "_20" -> "_80" suffix to control opacity (in 20 increments):
 *    Colour.GREEN_20 // 20% opacity in deck.gl format (51)
 *    Colour.GREEN_80 // 80% opacity in deck.gl format (204)
 *
 * If requested colour is not in pallette a warning will be logged (only–
 * when developing locally) and transparent will be returned.
 */
export const Colour = new Proxy(_Colour, {
  get(colourMap, colourName) {
    if (!(colourName in colourMap)) {
      if (/local/i.test(ENVIRONMENT.APP_ENV))
        console.warn(
          `Designation colour "${colourName.toString()}" does not exist! Using transparent instead.`
        );

      return TRANSPARENT;
    }

    return colourMap[colourName as string];
  },
});
