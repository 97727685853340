import { useEffect } from "react";
import { hotjar } from "react-hotjar";
import { useSnapshot } from "valtio";
import { ENVIRONMENT } from "src/js/util/environment";
import { userStore } from "src/js/stores/user/store";

const HOTJAR_ID = 2865932;

export const HotJarSnippet = () => {
  const { user } = useSnapshot(userStore);
  useEffect(() => {
    if (ENVIRONMENT.APP_ENV === "production" && user._id) {
      hotjar.initialize(HOTJAR_ID, 6);
      if (hotjar.initialized()) {
        hotjar.identify(user._id, { email: user.email, licenses: user.licence_summary });
      }
    }
  }, [user]);
  return <></>;
};
