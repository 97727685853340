export enum TenureTypes {
  ANY = "Any",
  FREEHOLD = "F",
  LEASEHOLD = "L",
}

export enum TenureLabels {
  L = "comparables.property.tenure.leasehold",
  F = "comparables.property.tenure.freehold",
}

export type TenureShorthand = "F" | "L";
