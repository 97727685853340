/* eslint-disable @typescript-eslint/ban-ts-comment */

import { Color } from "@deck.gl/core";
import { SolidPolygonLayer, SolidPolygonLayerProps } from "@deck.gl/layers";
import type { Accessor, DefaultProps } from "@deck.gl/core";
import bbox from "@turf/bbox";
import type { BBox } from "@turf/helpers/lib/geojson";

export const defaultProps: DefaultProps<GradientPolygonLayerProps<GradientFeature>> = {
  getGradientStopColor: { type: "accessor", value: [0, 0, 0, 0] },
  getPolygonBounds: {
    type: "accessor",
    value(d: GradientFeature) {
      return bbox(d.geometry);
    },
  },
};

export type GradientFeature = GeoJSON.Feature<
  GeoJSON.Polygon | GeoJSON.MultiPolygon | GeoJSON.Point,
  {
    extent: string; // stringified GeoJSON.Polygon
  }
>;

export interface GradientPolygonLayerProps<D> extends SolidPolygonLayerProps<D> {
  getGradientStopColor: Accessor<D, Color>;
  getPolygonBounds?(d: D): BBox;
}

export class GradientPolygonLayer<
  D extends GradientFeature,
  P extends GradientPolygonLayerProps<D> = GradientPolygonLayerProps<D>
> extends SolidPolygonLayer<D, P> {
  static readonly componentName = "GradientPolygonLayer";
  static readonly defaultProps = defaultProps as DefaultProps<SolidPolygonLayerProps>;

  initializeState() {
    super.initializeState();

    this.getAttributeManager()?.add({
      polygonBounds: {
        size: 4,
        type: "float64",
        accessor: "getPolygonBounds",
      },
      gradientStop: {
        size: 4,
        type: "unorm8",
        accessor: "getGradientStopColor",
      },
    });
  }

  getShaders(vs: unknown) {
    // Ensure we don't overrwrite any shader injects from extensions
    const superShaders = super.getShaders(vs);
    const superInject = superShaders.inject || {};
    const { "vs:#decl": superVsDecl = "", "vs:DECKGL_FILTER_COLOR": superVsFilterColor = "" } =
      superInject;

    return {
      ...superShaders,
      inject: {
        ...superInject,

        "vs:#decl": `
          ${superVsDecl}

          in vec4 polygonBounds;
          in vec4 gradientStop;

          float lerp(float edge0, float edge1, float x) {
            return clamp((x - edge0) / (edge1 - edge0), 0.0, 1.0);
          }
        `,

        "vs:DECKGL_FILTER_COLOR": `
          ${superVsFilterColor}

          if (bool(gradientStop.a)) {
            vec3 nw = vec3(polygonBounds.xy, 0.0);
            vec3 se = vec3(polygonBounds.zw, 0.0);
            vec3 nwCommon = project_position(nw);
            vec3 seCommon = project_position(se);
            
            float yMin = nwCommon.y;
            float yMax = seCommon.y;
            float interpolatedY = lerp(yMin, yMax, geometry.position.y);

            // Only support vertical gradient currently
            vec2 gradientUV = vec2(0.0, interpolatedY);

            vec3 gradientStopTop = gradientStop.rgb;
            vec3 gradientStopBottom = color.rgb;
  
            color = vec4(
              mix(gradientStopTop, gradientStopBottom, gradientUV.y),
              color.a
            );
          } 
        `,
      },
    };
  }
}
