import { useDesignationsByGeometry } from "react-migration/domains/constraints/designation/hooks/useDesignationsByGeometry";
import { LayerTypeSelectionSidebarProps } from "../../types";
import { useDemographicSubCategory } from "./useDemographicSubCategory";
import { useSelectionSidebarLoading } from "../../Bundle/useSelectionSidebarLoadingState";
import { DesignationsList } from "react-migration/domains/constraints/designation/components/Designations/DesignationsList";
import { DemographicsLayerConfig } from "./DemographicsLayerType";
import { SelectionFeature, SelectionType } from "src/js/stores/map/store";
import { useDemographicsContext } from "./DemographicsProvider";
import { CollapsibleConsiderationsCard } from "../../Bundle/Workbench/CollapsibleConsiderationsCard";

export function DemographicsSelectionSidebar({
  selection,
  layer,
  cardExpanded,
  setDetailSelection,
  onLoadingChange,
}: LayerTypeSelectionSidebarProps) {
  const layerConfig = layer.layerConfig as DemographicsLayerConfig;

  const { selectedAttribute } = useDemographicsContext();

  const selectedStyleOption = layerConfig.attributes_style_options.find(
    (x) => x?.attribute == selectedAttribute
  );
  const subCategoryConditions =
    selectedStyleOption?.customConditions || layerConfig.subCategoryConditions;

  const { categoryId: rootCategory } = useDemographicSubCategory(subCategoryConditions);

  const { designations, loading } = useDesignationsByGeometry({
    geometry: selection.feature?.geometry || null,
    bufferMeters: 0,
    rootCategories: rootCategory ? [rootCategory] : [],
  });

  useSelectionSidebarLoading(onLoadingChange, loading);

  const onOutputAreaDetailSelection = (selectionFeature: SelectionFeature | undefined) => {
    setDetailSelection(
      selectionFeature && { ...selectionFeature, type: SelectionType.OUTPUT_AREA }
    );
  };

  if (!designations.length) return null;

  return (
    <CollapsibleConsiderationsCard
      heading={layer.title}
      name={layer.title}
      count={designations.length}
      defaultExpanded={cardExpanded}
    >
      <DesignationsList
        designations={designations}
        setDetailSelection={onOutputAreaDetailSelection}
        truncateSubtitle
        cardName={layer.title}
      />
    </CollapsibleConsiderationsCard>
  );
}
