import { Feature, Point, point, polygon } from "@turf/helpers";
import { ClickSelectionResolver } from "../../../types";
import { titleClickResolver } from "./titleClickResolver";
import { SelectionGeometry, SelectionType } from "src/js/stores/map/store";
import booleanIntersects from "@turf/boolean-intersects";
import { isPolygonOrMultiPolygon } from "../../../geometry_utilities";
import { MD5 } from "object-hash";

function findIntersectingParcel(titleGeometry: SelectionGeometry, pointOfInterest: Feature<Point>) {
  if (!isPolygonOrMultiPolygon(titleGeometry)) return null;

  const parcels =
    titleGeometry.type === "Polygon" ? [titleGeometry.coordinates] : titleGeometry.coordinates;

  for (const [id, p] of parcels.entries()) {
    const parcel = polygon(p);
    if (!booleanIntersects(pointOfInterest, parcel)) continue;

    return { parcel, id };
  }
}

export const parcelClickResolver: ClickSelectionResolver = async (pickInfo) => {
  if (!pickInfo.coordinate) return null;

  const titleSelection = await titleClickResolver(pickInfo);
  const titleGeometry = titleSelection?.feature?.geometry;

  // Valid title response
  if (!titleSelection || !titleGeometry) return null;

  if (titleGeometry.coordinates.length === 1) return titleSelection;

  const intersectingParcel = findIntersectingParcel(titleGeometry, point(pickInfo.coordinate!));

  if (!intersectingParcel) return null;

  return {
    type: SelectionType.OWNERSHIP_PARCEL,
    id: MD5(intersectingParcel.parcel),
    feature: intersectingParcel.parcel,
    titleNumber:
      titleSelection.type === SelectionType.OWNERSHIP_TITLE
        ? titleSelection.id
        : titleSelection.titleNumber,
  };
};
