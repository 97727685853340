import hasBetaFeature from "src/js/stores/user/actions/hasBetaFeature";
import hasFeature from "src/js/stores/user/actions/hasFeature";
import { isAnAvailableFilter } from "./isAnAvailableFilter";
import { ISearchFilter } from "../typings/Filters";

export function isDefaultFilter(searchType: string, filter: ISearchFilter): boolean {
  return (
    isAnAvailableFilter(searchType)(filter) &&
    (filter.isDefault === true ||
      (Array.isArray(filter.isDefault) &&
        filter.isDefault.every((feature) => hasFeature(feature) || hasBetaFeature(feature))))
  );
}
