import Feature from "src/js/stores/user/Feature";
import { SummaryLayerType } from "../layer_types/SummaryLayerType";
import { Layer } from "../types";

export const SUMMARY_LAYER: Layer = {
  id: "SUMMARY_LAYER",
  toggleable: false,
  title: "Constraints Summary",
  requiredFeatureAccess: [Feature.sitesLayer],
  description: "Summary of selected data",
  layerType: SummaryLayerType,
};
