import { useMemo, useRef, useState } from "react";
import { useEventListener } from "usehooks-ts";

import * as Popover from "@radix-ui/react-popover";

import { Icon, LabelItem, SearchIcon, TextInput } from "@landtechnologies/components";

import { Button } from "../Button";

import { TagSelectItem } from "./TagSelectItem";

export type PopoverSide = "top" | "right" | "bottom" | "left";

type AddLabelButtonProps = {
  title: string;
  suggestedLabels: LabelItem[];
  allLabels: LabelItem[];
  selectedLabels: LabelItem[];
  onAddLabel: (item: LabelItem) => void;
  onArchiveLabel: (item: LabelItem) => void;
  onUpdateLabel: ({ id, name }: { id: string; name: string }) => void;
  onCreateLabel: (name: string) => void;
  searchInputPlaceHolder: string;
  labelExistsText: string;
  popoverSide: PopoverSide;
};

export const AddTagButton = ({
  title,
  suggestedLabels,
  allLabels,
  selectedLabels,
  onAddLabel,
  onArchiveLabel,
  onUpdateLabel,
  onCreateLabel,
  searchInputPlaceHolder,
  labelExistsText,
  popoverSide,
}: AddLabelButtonProps) => {
  const [open, setOpen] = useState(false);
  const [newLabelText, setNewLabelText] = useState("");

  const filteredLabels = useMemo(
    () =>
      suggestedLabels.filter((label) =>
        label.name.toLowerCase().includes(newLabelText.toLowerCase())
      ),
    [suggestedLabels, newLabelText]
  );

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNewLabelText(e.target.value);
  };

  const isNewLabel = useMemo(
    () => !allLabels.find((label) => label.name === newLabelText),
    [allLabels, newLabelText]
  );

  const isSelectedLabel = useMemo(
    () => selectedLabels.find((label) => label.name === newLabelText),
    [newLabelText, selectedLabels]
  );

  const inputRef = useRef(null);

  useEventListener(
    "keyup",
    (e) => {
      if (inputRef.current) {
        if (e.key === "Enter") {
          if (newLabelText.length < 1) return;
          if (isNewLabel) onCreateLabel(newLabelText);
          else {
            const selectedLabel = suggestedLabels.find((label) => label.name === newLabelText);
            if (selectedLabel) onAddLabel(selectedLabel);
          }
        }
      }
    },
    inputRef
  );

  return (
    <Popover.Root open={open} onOpenChange={setOpen}>
      <Popover.Trigger asChild>
        <Button
          data-testid="add-label-button"
          variant="ghost"
          className={open ? "!atlas-bg-platformBlue-100" : ""}
        >
          <Icon size="sm" icon="icon-lt-add-line" extraClasses="atlas-pointer-events-none" />
        </Button>
      </Popover.Trigger>

      <Popover.Portal>
        <Popover.Content sideOffset={5} side={popoverSide} align="start" asChild>
          <div className="atlas-flex atlas-flex-col atlas-gap-y-2 atlas-p-3 atlas-overflow-hidden atlas-w-60 atlas-max-h-72 atlas-z-10 atlas-shadow-md atlas-rounded-lg atlas-bg-white atlas-border atlas-border-blueGrey-200">
            <div className="atlas-flex atlas-flex-col">
              <h1 className="atlas-text-sm atlas-font-normal atlas-text-content-primary atlas-cursor-default atlas-truncate">
                {title}
              </h1>
              <p className="atlas-text-xs atlas-text-neutral-5 atlas-cursor-default atlas-items-center">
                Help organise your site
              </p>
            </div>
            <TextInput
              ref={inputRef}
              onChange={handleInputChange}
              dataTestId="labels-search-input"
              value={newLabelText}
              placeholder={searchInputPlaceHolder}
              IconLeft={
                <div className="atlas-w-[16px] atlas-h-[16px] atlas-text-blueGrey-800">
                  <SearchIcon />
                </div>
              }
            />
            <div className="atlas-flex atlas-flex-col atlas-overflow-y-auto atlas-max-h-[150px] atlas-gap-[10px]">
              {isNewLabel && newLabelText.length > 0 && (
                <button
                  className="atlas-flex atlas-items-center atlas-justify-start"
                  onClick={() => onCreateLabel(newLabelText)}
                >
                  <Icon
                    size="xs"
                    icon="icon-lt-add-line"
                    extraClasses="atlas-text-platformBlue-700 atlas-pointer-events-none"
                  />
                  <span className="atlas-text-xs atlas-underline atlas-text-platformBlue-600">{`"${newLabelText}" or press enter`}</span>
                </button>
              )}
              {isSelectedLabel && newLabelText.length > 0 && (
                <div className="atlas-text-xs">{labelExistsText}</div>
              )}
              {filteredLabels.map((label) => (
                <TagSelectItem
                  key={label.id}
                  label={label}
                  onRowClick={() => onAddLabel(label)}
                  onDelete={() => onArchiveLabel(label)}
                  onUpdateLabel={onUpdateLabel}
                />
              ))}
            </div>
          </div>
        </Popover.Content>
      </Popover.Portal>
    </Popover.Root>
  );
};
