import GB from "./en-GB";
import US from "./en/US/en-US.json";
import constraints from "./en/US/constraints";
import comparables from "./en/US/comparables";
import search from "./en/US/sidebar";

// merge US on GB to have GB as a fallback
export default {
  ...GB,
  ...US,
  ...constraints,
  ...comparables,
  ...search,
};
