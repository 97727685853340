import { useMutation } from "@apollo/client";
import DELETE_USER_ADDRESS from "../apollo/mutation/deleteUserAddress.gql";
import GET_USERS_ADDRESSES from "../apollo/queries/userAddresses.gql";
import { DeleteAddressResponse } from "../typings/DeleteAddressResponse";
import { routedClient } from "react-migration/lib/persistence/apollo/routedClient";

type Variables = {
  address_id: string;
};

export const useDeleteUserAddressMutation = () => {
  return useMutation<DeleteAddressResponse, Variables>(DELETE_USER_ADDRESS, {
    client: routedClient,
    refetchQueries: [{ query: GET_USERS_ADDRESSES }],
  });
};
