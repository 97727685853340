import classNames from "classnames";

export const SummaryDivider = () => {
  return (
    <div
      className={classNames(
        "atlas-my-3",
        "atlas-border-b",
        "atlas-border-border-divider",
        "atlas-px-2"
      )}
    />
  );
};
