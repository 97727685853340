import { PlanningApplicationListItem } from "./PlanningApplicationListItem";
import { PlanningApplication } from "../../types";
import { SelectionFeature } from "src/js/stores/map/store";

interface PlanningApplicationListProps {
  planningApps: PlanningApplication[];
  setSelectedPlanningApplicationRef: (ref: string) => void;
  setSelectedAppealRef: (ref: string) => void;
  setDetailSelection?: (selection: SelectionFeature) => void;
}

export function PlanningApplicationList({
  planningApps,
  setSelectedPlanningApplicationRef,
  setDetailSelection,
}: PlanningApplicationListProps) {
  return (
    <div className="atlas-divide-y atlas-divide-neutral-200">
      {planningApps.map((planningApplication) => (
        <PlanningApplicationListItem
          key={planningApplication.id}
          planningApplication={planningApplication}
          setSelectedPlanningApplicationRef={setSelectedPlanningApplicationRef}
          setDetailSelection={setDetailSelection}
        />
      ))}
    </div>
  );
}
