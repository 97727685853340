import { SiteReportStoredLogo } from "react-migration/domains/sites/typings/SiteReportStoredLogo";

export async function convertFileToBase64(file: File): Promise<SiteReportStoredLogo> {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.onloadend = () => {
      resolve({
        data: fileReader.result as string,
        fileName: file.name,
      });
    };
    fileReader.onerror = reject;
    fileReader.readAsDataURL(file);
  });
}
