import { LayerType } from "../../types";
import { ComparablesSelectionSidebar } from "./ComparablesSelectionSidebar";
import { ComparablesMapLayer } from "./ComparablesMapLayer";
import { ComparablesControlPage } from "./ComparablesControlPage";
import { ComparablesLayerContextProvider } from "./ComparableLayerContext/ComparableLayerContext";
import { comparablesClickResolver } from "./comparablesClickResolver";
import { ComparablesPrintable } from "./ComparablesPrintable";

export const ComparablesLayerType: LayerType = {
  MapLayer: ComparablesMapLayer,
  SelectionSidebar: ComparablesSelectionSidebar,
  ControlPage: ComparablesControlPage,
  Provider: ComparablesLayerContextProvider,
  clickResolver: comparablesClickResolver,
  Printable: ComparablesPrintable,
};
