import { useQuery } from "@apollo/client";
import { routedClient } from "react-migration/lib/persistence/apollo/routedClient";

import GET_OWNERSHIP_VIEWS from "../apollo/queries/ownershipViews.gql";
import { OwnershipViewsResponse } from "../../typings/OwnershipViewsResponse";

type UseOwnershipViewsQueryParams = { page: number; perPage: number; fromDate: string };

export const useOwnershipViewsQuery = ({
  page,
  perPage,
  fromDate,
}: UseOwnershipViewsQueryParams) => {
  return useQuery<OwnershipViewsResponse>(GET_OWNERSHIP_VIEWS, {
    client: routedClient,
    variables: { page, perPage, fromDate },
    fetchPolicy: "cache-first",
  });
};
