import { useEffect } from "react";
import Feature from "src/js/stores/user/Feature";
import { router } from "src/js/router/react-router";
import ROUTES from "src/js/router/routes";
import hasFeature from "src/js/stores/user/actions/hasFeature";

export const useRedirectIfNoAccess = () => {
  useEffect(() => {
    if (!hasFeature(Feature.ownershipChangeAlerts)) {
      router.push({ name: ROUTES.DASHBOARD_DOCUMENTS });
    }
  }, []);
};
