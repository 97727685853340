import { NetworkStatus } from "@apollo/client";
import { useId, useMemo } from "react";
import { useTranslation } from "react-migration/lib/i18n/useTranslation";
import { SiteStage } from "react-migration/domains/sites/typings/SiteStage";
import { keyBy } from "lodash";
import { Select } from "../../../../components/Select/Select";
import { Button } from "react-migration/components/Button";

type StageId = string;

export interface StageSelectProps {
  value?: StageId;
  onChange: (id: StageId) => void;
  stages: SiteStage[];
  loading: boolean;
  networkStatus: NetworkStatus;
  size?: "xs" | "sm";
}

const NO_SELECTION = "NO_SELECTION";

export function StageSelect({
  value = NO_SELECTION,
  onChange,
  stages,
  networkStatus,
  loading,
  size = "sm",
}: StageSelectProps) {
  const { t } = useTranslation();
  const id = useId();
  const stagesById = useMemo(() => stages && keyBy(stages, "_id"), [stages]);
  const hasNoStages = networkStatus === NetworkStatus.ready && !stages?.length;

  return (
    <div
      className="atlas-flex atlas-flex-col atlas-gap-y-1 atlas-grow atlas-overflow-hidden"
      data-testid="stage-select"
    >
      <Select
        id={id}
        value={value}
        size={size}
        disabled={loading || hasNoStages}
        onChange={(e) => onChange(e.target.value)}
        data-testid="stage-select-dropdown"
      >
        <option value={NO_SELECTION} disabled hidden>
          {hasNoStages
            ? t("sites.save_site.save_site_dropdown.create_stage_prompt")
            : t("sites.save_site.save_site_dropdown.select_stage_prompt")}
        </option>
        {(stages || []).map(({ _id, name }) => (
          <option key={_id} value={_id}>
            {name} {stagesById[_id]?.hidden && t("sites.save_site.save_site_dropdown.hidden")}
          </option>
        ))}
      </Select>
    </div>
  );
}

interface StageSelectPopoverContentProps {
  selectedStageId?: string;
  stages?: SiteStage[];
  loading: boolean;
  networkStatus: NetworkStatus;
  saveHandler(): void;
  closeHandler(): void;
  setSelectedStageId(id?: string): void;
}

const EMPTY_ARRAY: SiteStage[] = [];

export function StageSelectPopoverContent({
  selectedStageId,
  stages = EMPTY_ARRAY,
  loading,
  networkStatus,
  setSelectedStageId,
  closeHandler,
  saveHandler,
}: StageSelectPopoverContentProps) {
  const { t } = useTranslation();

  return (
    <>
      <StageSelect
        size="xs"
        value={selectedStageId}
        onChange={setSelectedStageId}
        stages={stages}
        loading={loading}
        networkStatus={networkStatus}
      />
      <div className="atlas-flex atlas-justify-between">
        <Button onClick={closeHandler} variant="tertiary">
          {t("sidebar.react.saved_search_sidebar.cancel")}
        </Button>
        <Button variant="primary" onClick={saveHandler} data-testid="save-site-inner">
          {t("sidebar.react.saved_search_sidebar.save")}
        </Button>
      </div>
    </>
  );
}
