import { FC } from "react";
import { Icon } from "@landtechnologies/components";
import { useSnapshot } from "valtio";
import { Link } from "react-migration/components/Link";
import { createCompanySearch } from "src/js/view/search/ownership/ownershipSearch/createCompanySearch";
import { mapStore } from "src/js/stores/map/store";
import { Company } from "react-migration/domains/ownership/typings/Company";

export type SearchOwnershipLinkProps = SearchableCompany;

export const SearchOwnershipLink: FC<SearchOwnershipLinkProps> = (props) => {
  const { settings } = useSnapshot(mapStore);
  const { mapX, mapY, zoom } = settings;

  return (
    <Link
      to={{
        path: `/legacy-search/${zoom},${mapX},${mapY}/`,
        query: {
          activeSearch: "ownership",
          ownership_search: getOwnershipSearch(props),
        },
      }}
    >
      <Icon size="xs" icon="icon-lt-search-line" extraClasses="atlas-pointer-events-none" />
    </Link>
  );
};

type SearchableCompany = Pick<Company, "name" | "company_no">;
const getOwnershipSearch = (company: SearchableCompany) =>
  JSON.stringify(createCompanySearch(company));
