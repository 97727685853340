interface IconProps {
  className?: string;
}

export function CheckLineThick({ className }: IconProps) {
  return (
    <svg className={className} viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.41921 2.81249L4.99998 7.23219L2.70177 4.93398C2.60414 4.83635 2.44585 4.83635 2.34822 4.93398L1.64122 5.64098C1.59433 5.68787 1.56799 5.75145 1.56799 5.81776C1.56799 5.88406 1.59433 5.94765 1.64122 5.99453L4.82322 9.17653C4.92085 9.27417 5.07914 9.27417 5.17677 9.17653L10.4803 3.87303C10.5272 3.82614 10.5535 3.76253 10.5535 3.69621C10.5535 3.62989 10.5271 3.5663 10.4802 3.51942L9.77271 2.81242C9.67506 2.71484 9.51681 2.71487 9.41921 2.81249Z"
        fill="currentColor"
      />
    </svg>
  );
}

export function SubtractLineThick({ className }: IconProps) {
  return (
    <svg className={className} viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.5 5.25C2.36193 5.25 2.25 5.36193 2.25 5.5V6.5C2.25 6.63807 2.36193 6.75 2.5 6.75H9.5C9.63807 6.75 9.75 6.63807 9.75 6.5V5.5C9.75 5.36193 9.63807 5.25 9.5 5.25H2.5Z"
        fill="white"
      />
    </svg>
  );
}
