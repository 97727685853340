import { useState } from "react";

import * as Popover from "@radix-ui/react-popover";

import { Icon } from "@landtechnologies/components";

import { Button } from "../Button";
import { AvatarSelectItem, AvatarItem } from "./AvatarSelectItem";

export type PopoverSide = "top" | "right" | "bottom" | "left";

type AddAvatarButtonProps = {
  title: string;
  subTitle: string;
  avatars: AvatarItem[];
  disabled: boolean;
  onAddAvatar: (item: AvatarItem) => void;
  onRemoveAvatar: (item: AvatarItem) => void;
  popoverSide: PopoverSide;
};

export const AddAvatarButton = ({
  title,
  subTitle,
  avatars,
  disabled,
  onAddAvatar,
  onRemoveAvatar,
  popoverSide,
}: AddAvatarButtonProps) => {
  const [open, setOpen] = useState(false);

  return (
    <Popover.Root open={open} onOpenChange={setOpen}>
      <Popover.Trigger asChild>
        <Button
          data-testid="add-avatar-button"
          variant="ghost"
          className={open ? "!atlas-bg-platformBlue-100" : ""}
        >
          <Icon size="sm" icon="icon-lt-add-line" extraClasses="atlas-pointer-events-none" />
        </Button>
      </Popover.Trigger>

      <Popover.Portal>
        <Popover.Content sideOffset={5} side={popoverSide} align="start" asChild>
          <div className="atlas-flex atlas-flex-col atlas-gap-y-2 atlas-p-3 atlas-overflow-hidden atlas-w-60 atlas-max-h-72 atlas-z-10 atlas-shadow-md atlas-rounded-lg atlas-bg-white atlas-border atlas-border-blueGrey-200">
            <div className="atlas-flex atlas-flex-col">
              <h1 className="atlas-text-sm atlas-font-normal atlas-text-content-primary atlas-cursor-default atlas-truncate">
                {title}
              </h1>
              <p className="atlas-text-xs atlas-text-neutral-5 atlas-cursor-default atlas-items-center">
                {subTitle}
              </p>
            </div>
            <div className="atlas-flex atlas-flex-col atlas-overflow-y-auto atlas-max-h-[150px] atlas-gap-[10px]">
              {avatars.map((avatar) => (
                <AvatarSelectItem
                  key={avatar.id}
                  avatar={avatar}
                  disabled={disabled}
                  onRowClick={() => onAddAvatar(avatar)}
                  onDelete={() => onRemoveAvatar(avatar)}
                />
              ))}
            </div>
          </div>
        </Popover.Content>
      </Popover.Portal>
    </Popover.Root>
  );
};
