<template>
  <react-wrapper :component="Documents" />
</template>

<script>
import ReactWrapper from "view/wrappers/react-wrapper";
import { Documents } from "react-migration/domains/dashboard/documents/Documents";

export default {
  components: {
    ReactWrapper,
  },
  computed: {
    Documents() {
      return Documents;
    },
  },
};
</script>
