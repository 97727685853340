import { noop } from "lodash";
import React, {
  createContext,
  useContext,
  useReducer,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
} from "react";

export enum RenderQueueActionType {
  ADD_TO_QUEUE = "ADD_TO_QUEUE",
  START_RENDERING = "START_RENDERING",
  FINISH_RENDERING = "FINISH_RENDERING",
}

type RenderQueueState = {
  queue: string[];
  rendering: string[];
};

type RenderQueueAction =
  | { type: RenderQueueActionType.ADD_TO_QUEUE; id: string }
  | { type: RenderQueueActionType.START_RENDERING }
  | { type: RenderQueueActionType.FINISH_RENDERING; id: string };

const RenderQueueContext = createContext<
  | {
      isRendering: (id: string) => boolean;
      dispatch: React.Dispatch<RenderQueueAction>;
    }
  | undefined
>({
  isRendering: () => false,
  dispatch: noop,
});

const initialState: RenderQueueState = {
  queue: [],
  rendering: [],
};

const RENDERING_LIMIT = 5;

const reducer = (state: RenderQueueState, action: RenderQueueAction): RenderQueueState => {
  switch (action.type) {
    case RenderQueueActionType.ADD_TO_QUEUE:
      return {
        ...state,
        queue: [...state.queue, action.id],
      };

    case RenderQueueActionType.START_RENDERING: {
      const idsToRenderCount = RENDERING_LIMIT - state.rendering.length;
      const nextIds = state.queue.slice(0, idsToRenderCount);
      const newQueue = state.queue.slice(idsToRenderCount);
      return {
        ...state,
        queue: newQueue,
        rendering: [...state.rendering, ...nextIds],
      };
    }

    case RenderQueueActionType.FINISH_RENDERING: {
      const newRendering = state.rendering.filter((id) => id !== action.id);
      if (state.queue.length > 0) {
        const nextId = state.queue[0];
        return {
          ...state,
          rendering: [...newRendering, nextId],
          queue: state.queue.slice(1),
        };
      }
      return {
        ...state,
        rendering: newRendering,
      };
    }

    default:
      return state;
  }
};

type RenderQueueProviderProps = {
  children: ReactNode;
};

export const RenderQueueProvider: React.FC<RenderQueueProviderProps> = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const isRendering = useCallback(
    (id: string) => {
      return state.rendering.includes(id);
    },
    [state.rendering]
  );

  useEffect(() => {
    if (state.rendering.length < RENDERING_LIMIT && state.queue.length > 0) {
      dispatch({ type: RenderQueueActionType.START_RENDERING });
    }
  }, [state.queue.length, state.rendering.length]);

  const contextValue = useMemo(
    () => ({
      isRendering,
      dispatch,
    }),
    [isRendering, dispatch]
  );

  return <RenderQueueContext.Provider value={contextValue}>{children}</RenderQueueContext.Provider>;
};

export const useRenderQueue = () => {
  const context = useContext(RenderQueueContext);
  if (!context) {
    throw new Error("useRenderQueue must be used within a RenderQueueProvider");
  }
  return context;
};
