import { ISourcingToolSearchFilter } from "react-migration/domains/search/typings/SourcingToolSpecificFilterTypes";
import { agriculturalLandClassificationOptions } from "./agriculturalLandClassificationOptions";
import { SearchType } from "react-migration/domains/search/constants/searchTypes";

export const AgriculturalLandClassificationFilter: ISourcingToolSearchFilter = {
  id: "agricultural-land-classification-id",
  type: "checkbox",
  field: "sidebar.react.search_sidebar.agricultural_land_classifications",
  must: "MATCH_ANY",
  mustOptions: ["MATCH_ANY", "MATCH_NONE"],
  updateFilterDialogIsVisible: false,
  values: agriculturalLandClassificationOptions,
  searchTypes: [SearchType.SOURCING_TOOL_UK],
};
