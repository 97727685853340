import Feature from "src/js/stores/user/Feature";
import { OwnershipLayerType } from "../layer_types/OwnershipLayerType";
import { Layer } from "../types";

export const OWNERSHIP_LAYER_ID = "ownership";

export const OWNERSHIP_LAYER: Layer = {
  id: OWNERSHIP_LAYER_ID,
  layerType: OwnershipLayerType,
  title: "Ownership Boundaries",
  toggleable: true,
  requiredFeatureAccess: [Feature.ownershipLayer],
  description: "Ownership boundaries",
  layerConfig: {
    minZoom: 13,
  },
};
