import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import SITE_LETTER_TEMPLATES from "react-migration/domains/sites/apollo/sites-api/queries/siteLetterTemplates.gql";
import SITE_LETTER_IMAGE_DOWNLOAD_URL from "react-migration/domains/sites/apollo/sites-api/queries/siteLetterImageDownloadUrl.gql";
import CREATE_SITE_LETTER_TEMPLATE from "react-migration/domains/sites/apollo/sites-api/mutations/createSiteLetterTemplate.gql";
import { removeNullishObjectProperties } from "react-migration/lib/util/removeNullishObjectProperties";
import {
  LandTechEndpoints,
  routedClient,
} from "react-migration/lib/persistence/apollo/routedClient";
import { useSnapshot } from "valtio";
import { useCallback, useEffect, useState } from "react";
import { Letter, letterStore } from "src/js/stores/letter/store";
import { updateLetter, updatePageCount } from "src/js/stores/letter/actions";
import {
  CreateSiteLetterTemplateResponseData,
  SiteLetterImageDownloadUrlResponse,
  SiteLetterImageDownloadUrlVariables,
  SiteLetterTemplatesResponseData,
} from "../../typings/apollo";

const getFileFromDownloadUrl = async (imageUrl: string, fileName: string) => {
  const response = await fetch(imageUrl);
  const blob = await response.blob();
  return new File([blob], fileName, { type: blob.type });
};

export function useLoadLetterTemplate() {
  const [loading, setLoading] = useState(true);
  const { letter } = useSnapshot(letterStore);

  const { loading: siteLetterTemplateLoading, data: letterTemplateResponseData } =
    useQuery<SiteLetterTemplatesResponseData>(SITE_LETTER_TEMPLATES, {
      client: routedClient,
      context: {
        endpoint: LandTechEndpoints.Sites,
      },
      fetchPolicy: "no-cache",
    });

  const [getDownloadUrl] = useLazyQuery<
    SiteLetterImageDownloadUrlResponse,
    SiteLetterImageDownloadUrlVariables
  >(SITE_LETTER_IMAGE_DOWNLOAD_URL, {
    context: { endpoint: LandTechEndpoints.Sites },
    client: routedClient,
    fetchPolicy: "no-cache",
  });

  const formatLetterImage = useCallback(
    ({ s3ObjectKey, fieldName }: { s3ObjectKey: string; fieldName: string }) => {
      const process = async () => {
        const { data } = await getDownloadUrl({
          variables: {
            s3ObjectKey,
          },
        });
        const url = data?.siteLetterImage?.signedDownloadUrl;

        if (!url) {
          return;
        }

        const imageFile = await getFileFromDownloadUrl(url, `${fieldName}.jpg`);

        return {
          file: imageFile,
          url: url,
          s3ObjectKey,
        };
      };
      return process();
    },
    [getDownloadUrl]
  );

  const [createSiteLetterTemplate] = useMutation<CreateSiteLetterTemplateResponseData>(
    CREATE_SITE_LETTER_TEMPLATE,
    {
      variables: {
        siteLetterTemplateInput: {},
      },
      client: routedClient,
      context: {
        endpoint: LandTechEndpoints.Sites,
      },
      fetchPolicy: "no-cache",
    }
  );

  useEffect(() => {
    if (siteLetterTemplateLoading) {
      return;
    }

    (async () => {
      const letterUpdate: Partial<Letter> = {};

      if (!letterTemplateResponseData || letterTemplateResponseData.templates.length === 0) {
        await createSiteLetterTemplate();
      } else if (letterTemplateResponseData.templates[0]) {
        const template = letterTemplateResponseData.templates[0];
        letterUpdate.bodyText = template.body_text_page_1;
        letterUpdate.bodyTextPage2 = template.body_text_page_2;
        letterUpdate.greeting = template.greeting;
        letterUpdate.signOff = template.sign_off;
        letterUpdate.contactEmail = template.contact_email;
        letterUpdate.contactName = template.contact_name;
        letterUpdate.contactPhone = template.contact_phone;
        letterUpdate.signaturePage = template.signature_page;
        letterUpdate.showSiteBoundary = template.satellite_image_enabled;
        letterUpdate.vatRegistrationNumber = template.vat_registration_number;
        letterUpdate.companyRegistrationNumber = template.company_registration_number;
        letterUpdate.senderAddressLine1 = template.sender_address_line_1;
        letterUpdate.senderCity = template.sender_city;
        letterUpdate.senderName = template.sender_name;
        letterUpdate.senderPostcode = template.sender_post_code;
        letterUpdate.senderProvince = template.sender_province;

        const { company_logo_file_id } = template;
        if (company_logo_file_id) {
          letterUpdate.logo = await formatLetterImage({
            s3ObjectKey: company_logo_file_id,
            fieldName: "logo",
          });
        }
        if (template.signature_file_id) {
          letterUpdate.signature = await formatLetterImage({
            s3ObjectKey: template.signature_file_id,
            fieldName: "signature",
          });
        }

        const updateObject = removeNullishObjectProperties({
          ...letter,
          ...letterUpdate,
        });

        if (
          letterUpdate.signaturePage === 2 ||
          letterUpdate.bodyTextPage2 ||
          letterUpdate.showSiteBoundary
        ) {
          updatePageCount(2);
        }
        updateLetter(updateObject);
      }

      setLoading(false);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    siteLetterTemplateLoading,
    letterTemplateResponseData,
    letterTemplateResponseData?.templates,
    createSiteLetterTemplate,
    formatLetterImage,
  ]);

  return { letter, loading };
}
