export const appendRemoteScript = ({ url, type }: { url: string; type: string }) => {
  const script = document.createElement("script");
  script.src = url;
  script.type = type;
  script.async = true;
  document.body.appendChild(script);
};
export const appendRemoteStylesheet = ({ url }: { url: string }) => {
  const sheet = document.createElement("link");
  sheet.rel = "stylesheet";
  sheet.href = url;
  sheet.type = "text/css";
  document.body.appendChild(sheet);
};
