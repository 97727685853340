import { Button, Modal } from "@landtechnologies/components";
import { useTranslation } from "react-migration/lib/i18n/useTranslation";
import logSiteCardEvent from "src/js/view/sites/logSiteCardEvent";

type DNCModalProps = {
  onCancelClicked: () => void;
  onAcceptClicked: () => void;
};

export const DNCModal = ({ onCancelClicked, onAcceptClicked }: DNCModalProps) => {
  const { t } = useTranslation();

  const onCancelButtonClicked = () => {
    logSiteCardEvent({ name: "Skip Trace - DNC Registry Cancel Clicked" });
    onCancelClicked();
  };

  const onAcceptButtonClicked = () => {
    logSiteCardEvent({ name: "Skip Trace - DNC Registry Accept Clicked" });
    onAcceptClicked();
  };

  return (
    <Modal contentClasses="atlas-bg-background-white !atlas-min-w-[590px] atlas-rounded-lg">
      <div className="atlas-flex atlas-flex-col atlas-gap-y-4">
        <span className="atlas-font-semibold atlas-text-lg atlas-text-neutral-11">
          {t("sites.skip_tracing.dnc")}
        </span>
        <span className="atlas-text-sm atlas-leading-5 atlas-text-neutral-11">
          {t("sites.skip_tracing.dnc_description")}
        </span>
        <div className="atlas-flex atlas-justify-end atlas-align-bottom atlas-gap-x-3">
          <Button.Secondary onClick={onCancelButtonClicked}>
            {t("sites.skip_tracing.cancel")}
          </Button.Secondary>
          <Button.Primary onClick={onAcceptButtonClicked}>
            {t("sites.skip_tracing.accept")}
          </Button.Primary>
        </div>
      </div>
    </Modal>
  );
};
