import { useMapLayer } from "react-migration/lib/map/useMapLayer";
import { LayerTypeSelectionMapLayerProps } from "../../../../types";
import { usePropertyInformationByUPRN } from "../usePropertyInformationByUPRN";
import { UKPropertyInformationMapVisualisationLayer } from "./UKPropertyInformationMapVisualisationLayer";
import { point } from "@turf/helpers";

export function UKPropertyInformationSelectionMapLayer({
  selection,
}: LayerTypeSelectionMapLayerProps) {
  const selectedPropertyUPRN = selection.id;

  const { data } = usePropertyInformationByUPRN(selectedPropertyUPRN);
  const { propertyInformationByUPRN } = data || {};

  useMapLayer(() => {
    const data =
      propertyInformationByUPRN &&
      point(propertyInformationByUPRN.address.location.coordinates, {
        uprn: propertyInformationByUPRN.address.uprn,
      });

    return {
      layer: new UKPropertyInformationMapVisualisationLayer({
        data,
        selectedPropertyUPRN,
      }),
      zOrder: Infinity,
    };
  }, [propertyInformationByUPRN, selectedPropertyUPRN]);

  return null;
}
