import type { Deck } from "@deck.gl/core";
import { IconLayer, IconLayerProps } from "@deck.gl/layers";
import { CompositeLayer } from "@deck.gl/core";
import type { LtIconKey } from "react-migration/domains/constraints/designation/style/icons";
import { getFontIconLayerAtlasManager } from "./FontIconLayerAtlasManager";

export interface FontIconLayerProps {
  iconKeySet?: LtIconKey[];
}

export class FontIconLayer extends CompositeLayer<FontIconLayerProps> {
  renderLayers() {
    const fontIconLayerAtlasManager = getFontIconLayerAtlasManager({
      characterSet: this.props.iconKeySet ?? [],
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      deck: this.context.deck as Deck<any>,
    });
    const iconMapping = fontIconLayerAtlasManager.getFontIconMapping();
    const iconAtlas = fontIconLayerAtlasManager.getFontIconAtlas();

    return [
      new KeyedIconLayer(
        this.props as IconLayerProps,
        this.getSubLayerProps({
          id: "keyed-icon-layer",
          iconAtlas,
          iconMapping,
          sizeUnits: "pixels",
        })
      ),
    ];
  }
}

class KeyedIconLayer extends IconLayer {
  getShaders() {
    const superShaders = super.getShaders();

    return {
      ...superShaders,
      inject: {
        "fs:#main-end": `
          vec4 fuchsia = vec4(1.0, 0.0, 1.0, 1.0);
          vec4 white = vec4(1.0, 1.0, 1.0, 1.0);
          float tolerance = 1.0; // Tolerance for color similarity
          float distance = length(fragColor.rgb - fuchsia.rgb);
            
          if (distance < tolerance) {
            // Interpolate based on distance to smoothly transition
            float factor = distance / tolerance;
            fragColor = mix(vColor, white, factor);
          }
        `,
      },
    };
  }
}
