import { useMemo } from "react";
import { useTitleOwnerQuery } from "react-migration/domains/ownership/apollo/useTitleOwnerQuery";
import { enrichTitleWithOwnerNames } from "./useTitlesWithOwnerNamesQuery";

export function useSelectedTitle(selectedTitleNumber?: string) {
  const { titleOwner: selectedTitleResponse, loading: loadingTitleOwner } =
    useTitleOwnerQuery(selectedTitleNumber);

  const selectedTitle = useMemo(
    () => selectedTitleResponse && enrichTitleWithOwnerNames(selectedTitleResponse),
    [selectedTitleResponse]
  );

  return { selectedTitle, loadingSelectedTitle: loadingTitleOwner };
}
