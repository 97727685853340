import * as React from "react";
import classNames from "classnames";
import { useSnapshot } from "valtio";
import { Placeholder } from "./Placeholder";
import { useTranslation } from "react-migration/lib/i18n/useTranslation";
import { letterStore } from "src/js/stores/letter/store";

interface SignatureProps {
  showValues?: boolean;
}

export const Signature: React.FC<SignatureProps> = ({ showValues }) => {
  const {
    letter: { signOff, contactName, contactEmail, contactPhone, signature },
  } = useSnapshot(letterStore);
  const { t } = useTranslation();

  return (
    <div>
      <div className={classNames("atlas-mt-8")} data-testid="letter-preview-signoff">
        {signOff}
      </div>

      <div data-testid="letter-preview-signature">
        {showValues || signature?.url ? (
          <div
            className={classNames(
              "atlas-flex",
              "atlas-items-center",
              "atlas-justify-center",
              "atlas-h-[94px]",
              "atlas-w-[156px]",
              "atlas-my-2",
              "atlas-bg-contain",
              "atlas-overflow-hidden",
              !signature?.url && "atlas-hidden"
            )}
          >
            <img
              className={classNames("atlas-h-auto", "atlas-w-full", "atlas-object-center")}
              src={signature?.url}
            />
          </div>
        ) : (
          <Placeholder className={classNames("atlas-h-[94px]", "atlas-w-[156px]", "atlas-my-1")}>
            <div
              className={classNames(
                "atlas-flex",
                "atlas-flex-col",
                "atlas-items-center",
                "atlas-justify-center"
              )}
            >
              <i className="icon-lt-image-line atlas-text-3xl atlas-text-neutral-400" />
              {t("sites.letters.signature")}
            </div>
          </Placeholder>
        )}
      </div>

      <div className={classNames("atlas-mt-1")} data-testid="letter-preview-signature-contact">
        <span>{contactName}</span>
        <br />
        <span>{contactEmail}</span>
        <br />
        <span>{contactPhone}</span>
      </div>
    </div>
  );
};
