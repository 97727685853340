import {
  PlanningApplication,
  PlanningApplicationsSummariesRanked,
  rankTitleMap,
} from "react-migration/domains/planning/types";
import { findApplicationReferences } from "../../SelectionSidebar/findApplicationReferences";
import { splitTitleIntoParts } from "react-migration/domains/planning/components/PlanningApplicationDetail/TitleWithLinks";
import { SummaryType } from "react-migration/components/AIResponse/SummaryType";

export type PlanningApplicationsSummaryByGeometryType = {
  data?: {
    rankedSummaries: PlanningApplicationsSummariesRanked[];
  };
  referenceApplications?: PlanningApplication[];
};

export const formatSummary = ({
  data,
  referenceApplications = [],
}: PlanningApplicationsSummaryByGeometryType): SummaryType[] => {
  if (!data?.rankedSummaries?.length) {
    return [];
  }

  // Add rank title to summaries and remove empty classified and application summaries
  const rankedSummariesWithTitles = data?.rankedSummaries
    ?.map((classifiedSummary: PlanningApplicationsSummariesRanked) => ({
      rankTitle: rankTitleMap[classifiedSummary.rank],
      ...classifiedSummary,
    }))
    .filter(
      (rankedSummaries) =>
        rankedSummaries.classifiedSummaries?.length > 0 &&
        rankedSummaries.classifiedSummaries.filter(
          (classifiedSummary) => classifiedSummary.applicationsSummaries?.length > 0
        )
    );

  if (!rankedSummariesWithTitles?.length) {
    return [];
  }

  const formattedContent: SummaryType[] = [];

  rankedSummariesWithTitles.map((rankedSummary) => {
    // Only add rows if a classifiedSummary has at least one applicationsSummary
    if (
      rankedSummary.classifiedSummaries.filter(
        (classifiedSummary) => classifiedSummary.applicationsSummaries?.length > 0
      ).length !== 0
    ) {
      if (rankedSummary.rankTitle) {
        formattedContent.push({ type: "title", content: rankedSummary.rankTitle });
      }
      rankedSummary.classifiedSummaries.map((classifiedSummary) => {
        formattedContent.push({ type: "subtitle", content: classifiedSummary.classification });
        classifiedSummary.applicationsSummaries?.map((applicationSummary) => {
          const referenceMatches = findApplicationReferences(
            applicationSummary,
            referenceApplications
          );
          const summaryContentParts = splitTitleIntoParts(applicationSummary, referenceMatches);

          summaryContentParts.forEach((part) => {
            if (part.type === "anchor") {
              formattedContent.push({
                ...part,
                href: part.planningId,
              });
            } else {
              formattedContent.push(part);
            }
          });

          formattedContent.push({ type: "break", content: "" });
        });
      });
    }
  });

  return formattedContent;
};
