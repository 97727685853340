export const PROPRIETOR_GROUPS = {
  Company: [
    "Limited Company or Public Limited Company",
    "Limited Liability Partnership",
    "Unlimited Company",
  ],
  "Corporate Body": ["Corporate Body"],
  "Local/county authority": ["Local Authority", "County Council"],
  "Housing Association": [
    "Industrial and Provident Society (Company)",
    "Industrial and Provident Society (Corporate Body)",
    "Housing Association/Society (Company)",
    "Housing Association/Society (Corporate Body)",
    "Registered Society (Company)",
    "Community Benefit Society (Company)",
    "Registered Society (Corporate Body)",
    "Community Benefit Society (Corporate Body)",
    "Housing Association Registered Society (Company)",
    "Housing Association Registered Society (Corporate Body)",
    "Co-operative Society (Corporate Body)",
    "Housing Association Community Benefit Society (Company)",
    "Housing Association Co-operative Society (Company)",
    "Co-operative Society (Company)",
    "Housing Association Co-operative Society (Corporate Body)",
    "Housing Association Community Benefit Society (Corporate Body)",
  ],
  Government: ["Government"],
};
