import SITE_ASSIGNEE_OPTIONS from "react-migration/domains/sites/apollo/accounts-private-api/siteAssigneeOptions.gql";
import { LandTechEndpoints, routedClient } from "react-migration/lib/persistence/apollo";
import { useMemo } from "react";
import { useQuery } from "@apollo/client";
import { TeamMember } from "../typings/TeamMember";

export interface SiteAssigneeOption {
  id: string;
  name: string;
  original: TeamMember;
}

interface SiteAssigneeOptionsResponse {
  getTeamMembers: TeamMember[];
}

export function useSiteAssigneeOptions(): SiteAssigneeOption[] {
  const { data } = useQuery<SiteAssigneeOptionsResponse>(SITE_ASSIGNEE_OPTIONS, {
    client: routedClient,
    context: {
      endpoint: LandTechEndpoints.PrivateClient,
    },
  });

  const siteAssigneeOptions = useMemo(() => {
    return (
      data?.getTeamMembers?.map((teamMember) => ({
        id: teamMember._id,
        name: `${teamMember.firstname} ${teamMember.surname}`,
        original: teamMember,
      })) || []
    );
  }, [data?.getTeamMembers]);

  return siteAssigneeOptions;
}
