export default {
  "Amber Valley": {
    name: "Amber Valley Council",
    refName: "app reference",
    link: "https://www.ambervalley.gov.uk/environment-and-planning/planning/development-management/planning-applications/view-a-planning-application.aspx",
    prePaste: "Click through to 'Search by Application Reference Number'",
  },
  Broxbourne: {
    name: "Broxbourne Council",
    refName: "application number",
    link: "https://planning.broxbourne.gov.uk/Planning/lg/plansearch.page?org.apache.shale.dialog.DIALOG_NAME=gfplanningsearch&Param=lg.Planning",
    prePaste: null,
  },
  "East Staffordshire": {
    name: "East Staffordshire Council",
    refName: "application number",
    link: "http://www.eaststaffsbc.gov.uk/Northgate/PlanningExplorer/GeneralSearch.aspx",
    prePaste: null,
  },
  "Epping Forest": {
    name: "Epping Forest",
    refName: "app reference",
    link: "https://www.eppingforestdc.gov.uk/planning-and-building/search-planning-applications/",
    prePaste: null,
  },
  Erewash: {
    name: "Erewash Council",
    refName: "application reference ERE",
    link: "https://register.civicacx.co.uk/erewash/planning",
    prePaste: null,
  },
  Hackney: {
    name: "Hackney Council",
    refName: "application number",
    link: "https://planningapps.hackney.gov.uk/planning/index.html?fa=search",
    prePaste: null,
  },
  Haringey: {
    name: "Haringey Council",
    refName: "application ref",
    link: "https://publicregister.haringey.gov.uk/pr/s/register-view?c__r=Arcus_BE_Public_Register",
    prePaste: null,
  },
  "South Tyneside": {
    name: "South Tyneside Council",
    refName: "application number",
    link: "http://planning.southtyneside.info/Northgate/PlanningExplorer/ApplicationSearch.aspx",
    prePaste: null,
  },
  Tamworth: {
    name: "Tamworth Council",
    refName: "application number",
    link: "https://planning.tamworth.gov.uk/northgate/planningexplorer/generalsearch.aspx",
    prePaste: null,
  },
  Tandridge: {
    name: "Tandridge",
    refName: "application reference",
    link: "https://tdcplanningsearch.tandridge.gov.uk/",
    prePaste: "Select 'Application reference' in the search criteria",
  },
};
