export const PPSFT_COLOR_FILL_HEX = ["#FEECE2", "#FCB4B9", "#F768A1", "#C51C8A", "#7A0177"];

export const PPSFT_COLOR_FILL_RGB = [
  [170, 170, 170],
  [255, 236, 226],
  [253, 180, 185],
  [248, 104, 161],
  [197, 29, 138],
  [122, 0, 120],
];
export const PPSFT_COLOR_OUTLINE = {
  satellite: "#FFFFFF",
  hybrid: "#FFFFFF",
  roadmap: "#4B5563",
  "os-default": "#4B5563",
  terrain: "#4B5563",
};
