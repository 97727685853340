import { proxy } from "valtio/vanilla";
import { Bounds, Center, Orientation, PageSize, Scale, S3Asset } from "../../types/OsMapPrint";

export type OsMapPrintStore = {
  sku_id: string | null;
  print_id: string | null;
  site_id: string | null;
  saving: boolean;
  syncing: boolean;
  center: Center;
  orientation: Orientation;
  page_size: PageSize | null;
  scale: Scale | null;
  bounding_box_polygon: Bounds | null;
  imageLoading: boolean;
  imageURL: string | null;
  previewError: boolean;
  activeRequestAbort: AbortController["abort"] | null;
  s3_assets: S3Asset[] | null;
  step: "site_selection" | "payment" | "print_media_selection" | "complete";
};

export const getInitialOsMapPrintStore = () =>
  ({
    step: "site_selection",
    saving: false,
    syncing: false,
    imageLoading: false,
    previewError: false,
    orientation: "portrait",
    center: [0, 0],
    bounding_box_polygon: null,
    imageURL: null,
    site_id: null,
    sku_id: null,
    print_id: null,
    activeRequestAbort: null,
    page_size: null,
    scale: null,
    s3_assets: null,
  } as OsMapPrintStore);

export const osMapPrintStore = proxy<OsMapPrintStore>(getInitialOsMapPrintStore());
