export const UnknownIcon = () => {
  return (
    <svg height="100%" viewBox="0 0 32 21">
      <g fill="#515e80" fillRule="evenodd">
        <path
          d="M26.58 19H2.42A2.4 2.4 0 010 16.62V2.38A2.4 2.4 0 012.42 0h24.16A2.4 2.4 0 0129 2.38v14.25A2.4 2.4 0 0126.58 19zM10 5.83c0-.46-.35-.83-.78-.83H3.78c-.43 0-.78.37-.78.83v3.34c0 .46.35.83.78.83h5.44c.43 0 .78-.37.78-.83V5.83z"
          opacity="0.2"
          transform="translate(0 2)"
        ></path>
        <path
          d="M25 15h-3c-.65 0-1-.3-1-1s.35-1 1-1h3c.65 0 1 .3 1 1s-.35 1-1 1zm-6 0h-3c-.65 0-1-.3-1-1s.35-1 1-1h3c.65 0 1 .3 1 1s-.35 1-1 1zm-6 0h-3c-.65 0-1-.3-1-1s.35-1 1-1h3c.65 0 1 .3 1 1s-.35 1-1 1zm-6 0H4c-.65 0-1-.3-1-1s.35-1 1-1h3c.65 0 1 .3 1 1s-.35 1-1 1z"
          opacity="0.3"
          transform="translate(0 2)"
        ></path>
      </g>
    </svg>
  );
};
