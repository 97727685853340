import { useCallback, useEffect, useMemo } from "react";
import * as React from "react";
import { useSnapshot } from "valtio";

import { DeckGlClickEvent, mapStore } from "src/js/stores/map/store";
import type { ClickedLatLng } from "react-migration/lib/map/typings";

import { ComparablesHeatMapTileLayer } from "./ComparablesHeatMapLayer";

import { useMapLayer } from "react-migration/lib/map/useMapLayer";
import { createFilters, createZooplaTileFilters } from "../../util/Filters";
import { disabledEPCFields, disabledZooplaFields } from "../../util/DisabledFilters";
import { hiddenFieldsByFeature } from "../../util/hiddenFields";
import { isZoopla } from "../../util/isZoopla";
import { userStore } from "src/js/stores/user/store";
import { ComparablesFilterOptions } from "../../typings/Comparables";
import { AggregatedZooplaComparableTile } from "../../typings/AggregatedZooplaComparableTile";
import { ComparableTile } from "@landtechnologies/comparables-services";
import { CorePriceModes, PriceModes, ZooplaPriceModes } from "../../typings/PriceModes";
import { ComparablesLayer } from "./ComparablesLayer/ComparablesLayer";
import { ComparablesFilterFields } from "../Filters/Filters";
import { ZooplaLayer } from "./ComparablesLayer/ZooplaLayer";

interface MapLayerProps {
  /** distribution of percentile groups [p0: number, p5: number, p25: number p75: number p95: number, p100: number ] */
  distribution?: number[];
  /** describes settings for which (if any) transactions should be filtered */
  filters?: ComparablesFilterOptions;
  /** Minimum zoom level to display parcels (deck.gl value is 1 lower than google maps) */
  minZoom?: number;
  /** Maximum zoom level to display parcels (deck.gl value is 1 lower than google maps) */
  maxZoom?: number;
  /** Returns event and latlng when parcel is selected  */
  onClickParcel?: (event: DeckGlClickEvent, latLng: ClickedLatLng, clickedUPRNs?: string[]) => void;
  /** Returns feature and latlng when parcel is hovered  */
  onHoverParcel?: (
    d: AggregatedZooplaComparableTile | ComparableTile | ComparableTile["properties"],
    latLng: ClickedLatLng
  ) => void;
  mapMode?: "Scatter" | "Heatmap";
  priceMode: PriceModes;
  visible?: boolean;
  zOrder?: number;
}

const DEFAULT_MIN_ZOOM = 13;
const DEFAULT_MAX_ZOOM = 18;

/**
 * Component for displaying the Comparables layer on a map
 */
export const MapLayer: React.FC<MapLayerProps> = ({
  filters,
  minZoom = DEFAULT_MIN_ZOOM,
  maxZoom = DEFAULT_MAX_ZOOM,
  onClickParcel,
  onHoverParcel,
  mapMode,
  priceMode,
  distribution,
  visible = true,
  zOrder,
}) => {
  const { mapType } = useSnapshot(mapStore.settings);
  const { unit: unitPreference } = useSnapshot(userStore.user.settings);
  const zooplaLayer = isZoopla(priceMode);
  const filterFunctions = useMemo(() => {
    return zooplaLayer
      ? createZooplaTileFilters(
          filters,
          new Set<ComparablesFilterFields>([
            ...disabledZooplaFields,
            ...hiddenFieldsByFeature.flatMap((check) => (check[0]() ? Array.from(check[1]) : [])),
          ]),
          priceMode
        )
      : createFilters(
          filters,
          new Set([
            ...disabledEPCFields,
            ...hiddenFieldsByFeature.flatMap((check) => (check[0]() ? Array.from(check[1]) : [])),
          ])
        );
  }, [filters, priceMode, zooplaLayer]);

  const dataAttributeName = "data-test-comparables-map-status";
  const onViewportLoading = useCallback(
    () => document.body.setAttribute(dataAttributeName, "loading"),
    []
  );
  const onViewportLoaded = useCallback(
    () => document.body.setAttribute(dataAttributeName, "loaded"),
    []
  );
  useEffect(() => () => document.body.removeAttribute(dataAttributeName), []);

  const getLayer = () => {
    if (mapMode === "Heatmap") {
      return new ComparablesHeatMapTileLayer({
        filterFunctions: filterFunctions as ReturnType<typeof createFilters>,
        id: "comparables-heatmap-layer",
        minZoom,
        maxZoom,
        opacity: 0.75,
        onClick: ({ coordinate }, event) => {
          if (coordinate) {
            const [lng, lat] = coordinate;
            onClickParcel?.(event, { lat, lng });
          }
        },
        onHover: (pickInfo) => {
          if (pickInfo.object && pickInfo.coordinate) {
            const [lng, lat] = pickInfo.coordinate;
            onHoverParcel?.(pickInfo.object, {
              lat,
              lng,
            });
          }
        },
        onViewportLoaded,
        onViewportLoading,
        priceMode: priceMode as unknown as CorePriceModes, // TODO: won't support zoopla
        distribution,
        visible,
      });
    }

    if (isZoopla(priceMode)) {
      return new ZooplaLayer({
        filterFunctions: filterFunctions as ReturnType<typeof createZooplaTileFilters>,
        id: "zoopla-layer",
        minZoom,
        maxZoom,
        onClick: ({ coordinate, object }, event) => {
          if (coordinate) {
            const [lng, lat] = coordinate;
            onClickParcel?.(
              event,
              { lat, lng },
              zooplaLayer
                ? object.properties
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    .filter((tile: any) => tile.properties.uprn)
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    .map((tile: any) => String(tile.properties.uprn))
                : undefined
            );
          }
        },
        onHover: (pickInfo) => {
          if (pickInfo.object && pickInfo.coordinate) {
            const [lng, lat] = pickInfo.coordinate;
            onHoverParcel?.(pickInfo.object, {
              lat,
              lng,
            });
          }
        },
        onViewportLoaded,
        onViewportLoading,
        priceMode: priceMode as unknown as ZooplaPriceModes,
        unitPreference,
        visible,
      });
    }

    return new ComparablesLayer({
      filterFunctions: filterFunctions as ReturnType<typeof createFilters>,
      id: "comparables-layer",
      minZoom,
      maxZoom,
      onClick: ({ coordinate }, event) => {
        if (coordinate) {
          const [lng, lat] = coordinate;
          onClickParcel?.(event, { lat, lng });
        }
      },
      onHover: (pickInfo) => {
        if (pickInfo.object && pickInfo.coordinate) {
          const [lng, lat] = pickInfo.coordinate;
          onHoverParcel?.(pickInfo.object, {
            lat,
            lng,
          });
        }
      },
      onViewportLoaded,
      onViewportLoading,
      priceMode: priceMode as unknown as CorePriceModes,
      distribution,
      unitPreference,
      visible,
    });
  };

  useMapLayer(
    () => ({
      layer: getLayer(),
      zOrder,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      mapType,
      minZoom,
      maxZoom,
      onClickParcel,
      onHoverParcel,
      mapMode,
      filters,
      priceMode,
      distribution,
      unitPreference,
      visible,
      zOrder,
    ]
  );

  return null;
};
