import { SingleDesignation } from "react-migration/domains/constraints/typings/applicationTypes/SingleDesignation";

export const getSearchableDesignationText = (
  searchString: string,
  designation: SingleDesignation
) => {
  if (searchString === "") return true;
  const searchStringLower = searchString.toLowerCase();

  return (
    designation.display_name?.toLowerCase().includes(searchStringLower) ||
    designation?.source?.authority?.name?.toLowerCase().includes(searchStringLower) ||
    designation?.sub_category?.display_name?.toLowerCase().includes(searchStringLower)
  );
};
