import {
  buildSiteFiltersStoreMethods,
  siteFiltersStore,
} from "../../filters/SiteFiltersStore/store";

import { useMemo } from "react";

export const useFilters = () => {
  const { useSearchString, useAssignees, useSiteLabels, useLocalAuthorities, useStageIds } =
    buildSiteFiltersStoreMethods(siteFiltersStore);
  const searchString = useSearchString();
  const siteLabels = useSiteLabels();
  const assignees = useAssignees();
  const localAuthorities = useLocalAuthorities();
  const stageIds = useStageIds();

  const filters = useMemo(
    () => ({
      searchText: searchString,
      localAuthorities,
      siteLabels,
      assignees,
      stageIds,
    }),
    [assignees, localAuthorities, searchString, siteLabels, stageIds]
  );
  return filters;
};
