export enum PerUnitPriceModes {
  PRICE = "price",
  EP = "est_price",
}

export enum PerAreaPriceModes {
  PPSM = "price_per_square_metre",
  EPPSM = "est_price_per_square_metre",
}

export enum ZooplaPricePerUnitModes {
  RENT_ESTIMATE = "zoopla_rental_estimate",
  RENT_ASKING = "zoopla_rental_asking",
  VAL_ESTIMATE = "zoopla_valuation_current",
  VAL_LAST = "zoopla_valuation_per_unit_last",
}

export enum ZooplaPricePerAreaModes {
  RENT_ESTIMATE = "zoopla_rental_estimate_per_area",
  RENT_ASKING = "zoopla_rental_asking_per_area",
  VAL_ESTIMATE = "zoopla_valuation_current_per_area",
  VAL_LAST = "zoopla_valuation_per_area_last",
}

export type CorePriceModes = PerUnitPriceModes | PerAreaPriceModes;
export type ZooplaPriceModes = ZooplaPricePerAreaModes | ZooplaPricePerUnitModes;
export type AreaPriceModes = PerAreaPriceModes | ZooplaPricePerAreaModes;
export type UnitPriceModes = PerUnitPriceModes | ZooplaPricePerUnitModes;
export type PriceModes = CorePriceModes | ZooplaPriceModes;
