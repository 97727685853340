import { Icon } from "@landtechnologies/components";
import { FC } from "react";

export interface CompaniesHouseLinkProps {
  query: string;
}

export const CompaniesHouseLink: FC<CompaniesHouseLinkProps> = ({ query }) => (
  <a
    href={`https://beta.companieshouse.gov.uk/search?q=${encodeURIComponent(query)}`}
    className="atlas-cursor-pointer"
    target="_blank"
    rel="noreferrer"
  >
    <Icon size="xs" icon="icon-lt-external-link-line" extraClasses="atlas-pointer-events-none" />
  </a>
);
