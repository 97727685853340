import { isWithinInterval, subDays } from "date-fns";
import { User } from "src/js/stores/user/store";
import { TRIAL_ENDING_COOKIE } from "./cookies";

export function last3DaysOfTrial(user: User): boolean {
  if (
    !user.self_service_trial_state.active ||
    user.self_service_trial_state.automatic_payment ||
    !user.self_service_trial_state.end_date
  ) {
    return false;
  }
  const trialEndDate = user.self_service_trial_state.end_date;
  const today = new Date();

  const withinLast3Days = isWithinInterval(today, {
    start: subDays(new Date(trialEndDate), 3),
    end: new Date(trialEndDate),
  });
  return withinLast3Days;
}

export function showTrial(user: User) {
  const cookies = document.cookie;
  const trialCookie = cookies
    .split("; ")
    .map((cookie: string) => {
      const [name, value] = cookie.split("=");
      return { name, value };
    })
    .find((cookie: { name: string }) => cookie.name === TRIAL_ENDING_COOKIE);
  return last3DaysOfTrial(user) && !trialCookie;
}
