import { useMutation } from "@apollo/client";
import DELETE_PLANNING_ALERT_SUBSCRIPTION from "../apollo/mutations/deletePlanningAlertSubscription.gql";
import PLANNING_ALERT_SUBSCRIPTIONS from "../apollo/queries/planningAlertSubscriptions.gql";
import SITES_WITH_SUBSCRIPTIONS from "../apollo/queries/sitesWithSubscriptions.gql";
import {
  LandTechEndpoints,
  routedClient,
} from "react-migration/lib/persistence/apollo/routedClient";
import { DeletePlanningAlertSubscriptionResponse } from "../../typings/DeletePlanningAlertSubscriptionResponse";

export const useDeletePlanningAlertSubscriptionMutation = () => {
  return useMutation<DeletePlanningAlertSubscriptionResponse>(DELETE_PLANNING_ALERT_SUBSCRIPTION, {
    client: routedClient,
    context: {
      endpoint: LandTechEndpoints.PlanningService,
    },
    refetchQueries: [
      {
        query: PLANNING_ALERT_SUBSCRIPTIONS,
        variables: { cursor: null, emailed_at: null, limit: 15 },
      },
      {
        query: SITES_WITH_SUBSCRIPTIONS,
        variables: {
          alertsEmailedAt: null,
          cursor: null,
          hasSites: true,
          limit: 15,
          withPlanningAlertSubscriptionsOnly: true,
        },
      },
    ],
  });
};
