import { isNumber } from "lodash";
import { isDefined } from "react-migration/lib/util/isDefined";
import { getAttributeNumericValue, getAttributeValue } from "../../utils";
import { OutputAreaAttribute } from "../types";
import { ValueFormatter } from "../utils";
import { Color } from "d3-color";
import {
  SingleDesignation,
  SingleDesignationDesignationAttribute,
} from "react-migration/domains/constraints/typings/applicationTypes/SingleDesignation";

export interface AttributeSummaryTableProps {
  designation: SingleDesignation;
  comparativeDesignation?: SingleDesignation;
  attributes: OutputAreaAttribute[];
  colorAccessor: (id: string) => Color;
}

export function AttributeSummaryTable({
  designation,
  attributes,
  comparativeDesignation,
  colorAccessor,
}: AttributeSummaryTableProps) {
  const designationAttributes = designation.designation_attributes;
  const comparativeDesignationAttributes = comparativeDesignation?.designation_attributes;

  return attributes.map(({ attributeKey, label, format }) => {
    const designationAttrValue = getAttributeDisplayValue(
      designationAttributes,
      attributeKey,
      format
    );

    const comparativeDesignationAttrValue =
      comparativeDesignationAttributes &&
      getAttributeDisplayValue(comparativeDesignationAttributes, attributeKey, format);

    return (
      <div key={attributeKey} className="atlas-flex atlas-flex-col atlas-gap-y-1">
        <h2 className="atlas-text-xs atlas-font-semibold atlas-text-neutral-800">{label}</h2>
        <Row
          displayName={designation.display_name!}
          value={designationAttrValue ?? undefined}
          color={colorAccessor(designation.id)}
        />
        {isDefined(comparativeDesignation) && isDefined(comparativeDesignationAttrValue) && (
          <Row
            displayName={comparativeDesignation.display_name!}
            value={comparativeDesignationAttrValue}
            color={colorAccessor(comparativeDesignation.id)}
          />
        )}
      </div>
    );
  });
}

interface RowProps {
  displayName: string;
  value?: string;
  color: Color;
}

function Row({ displayName, value, color }: RowProps) {
  return (
    <div className="atlas-flex atlas-flex-row atlas-justify-between atlas-gap-x-1 atlas-items-start">
      <div
        className="atlas-shrink-0 atlas-truncate atlas-text-xs atlas-text-neutral-800 atlas-border-l-[3px] atlas-pl-1 atlas-border-solid"
        style={{
          borderLeftColor: color.toString(),
        }}
      >
        {displayName}
      </div>
      {isDefined(value) ? (
        <div className="atlas-text-xs atlas-text-right atlas-font-semibold">{value}</div>
      ) : (
        <div className="atlas-text-xs atlas-font-semibold atlas-text-neutral-400">-</div>
      )}
    </div>
  );
}

function getAttributeDisplayValue(
  designationAttributes: SingleDesignationDesignationAttribute[],
  attributeKey: string,
  format: ValueFormatter | undefined
) {
  const designationAttrValueNumeric = getAttributeNumericValue(designationAttributes, attributeKey);
  return format && isNumber(designationAttrValueNumeric)
    ? format(designationAttrValueNumeric)
    : getAttributeValue(designationAttributes, attributeKey);
}
