import Feature from "src/js/stores/user/Feature";
import { ConstraintsCategory } from "../../Constraints/types";
import { ConstraintsLayerType } from "../layer_types/ConstraintsLayerType";
import { Layer } from "../types";

export const ENVIRONMENTAL_PERMITS: Layer = {
  id: ConstraintsCategory.ENVIRONMENTAL_PERMITS,
  layerType: ConstraintsLayerType,
  title: "Environmental Permits",
  requiredFeatureAccess: [Feature.buildingPermits],
  description:
    "This layer shows Environmental Resource Permits, which authorize construction and operation of surface water management systems",
  toggleable: true,
  hideByDefault: true,
  layerConfig: {
    rootCategory: ConstraintsCategory.ENVIRONMENTAL_PERMITS,
    categorySchema: [
      { key: "environmental_permits_approved" },
      { key: "environmental_permits_pending" },
      { key: "environmental_permits_withdrawn" },
      { key: "environmental_permits_rejected" },
      { key: "environmental_permits_unknown" },
    ],
  },
};
