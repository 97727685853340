import { RecordType } from "react-migration/domains/comparables/typings/Record";
import { TenureLabels, TenureTypes } from "react-migration/domains/comparables/typings/Tenure";
import { Translation } from "react-migration/lib/typings";

export interface TenureTitleProps {
  t: Translation;
}
export const tenureTitle = ({ t }: TenureTitleProps) =>
  t("comparables.map.sidebar.value.record.tenure.title");

export interface TenureValueProps {
  t: Translation;
  record: RecordType;
}
export const tenureValue = ({ t, record }: TenureValueProps) => {
  if (record.country !== "GB") {
    return undefined;
  }
  if (record.tenure === TenureTypes.FREEHOLD) {
    return t(TenureLabels.F);
  }
  if (record.tenure === TenureTypes.LEASEHOLD) {
    return t(TenureLabels.L);
  }
  return t("comparables.unknown");
};
