import {
  DeprecatedTooltip,
  DisabledTextContentColor,
  Switch,
  Theme,
} from "@landtechnologies/components";

import { FC } from "react";
import { useTranslation } from "react-migration/lib/i18n/useTranslation";

export interface FilterNewBuildsProps {
  disabled?: boolean;
  showNewBuildOnly: boolean;
  setShowNewBuildOnly: (value: boolean) => void;
  theme?: Theme;
}

export const FilterNewBuilds: FC<FilterNewBuildsProps> = ({
  disabled,
  showNewBuildOnly,
  setShowNewBuildOnly,
  theme = Theme.Light,
}) => {
  const { t } = useTranslation();

  return (
    <div
      className="atlas-flex atlas-flex-row atlas-items-center atlas-mt-2"
      data-testid="filter-new-builds"
    >
      <div className="atlas-flex atlas-flex-wrap">
        <Switch
          disabled={disabled}
          size="small"
          label="New Builds Only"
          showLabel={false}
          id="new-builds-only"
          onToggle={() => setShowNewBuildOnly(!showNewBuildOnly)}
          value={showNewBuildOnly}
        />
      </div>
      <label
        htmlFor="new-builds-only"
        className={`atlas-pl-2 atlas-font-semibold ${
          disabled ? DisabledTextContentColor[theme] : ""
        }`}
      >
        <span className="atlas-pr-2">{t("comparables.map.sidebar.filters.filters.newbuild")}</span>
        <DeprecatedTooltip
          theme={Theme.Light}
          placement="right"
          title={t("comparables.map.sidebar.filters.filters.newbuild.tooltip")}
          disabled={false}
          delayMs={1000}
          widthClass="atlas-w-80"
        >
          <i className="icon-lt-information-line atlas-text-lg atlas-cursor-pointer atlas-text-content-info" />
        </DeprecatedTooltip>
      </label>
    </div>
  );
};
