import { LayerTypeSelectionSidebarProps } from "../../../types";
import { RelatedOwners } from "../OwnershipSelectionDetailView/Summary/RelatedOwners";
import { useCallback } from "react";
import { feature } from "@turf/helpers";
import { SelectionType, SelectionGeometry } from "src/js/stores/map/store";
import buffer from "@turf/buffer";
import { Nullable } from "src/js/types/Nullable";
import { useTitlesWithOwnerNamesQuery } from "../hooks/useTitlesWithOwnerNamesQuery";
import { useSelectionSidebarLoading } from "../../../Bundle/useSelectionSidebarLoadingState";
import { useTranslation } from "react-migration/lib/i18n/useTranslation";
import { InfoPopover } from "@landtechnologies/components";
import { OwnershipIntersectableGeometry } from "react-migration/domains/ownership/apollo/useTitlesByLocationQuery";
import { logEventV2 } from "react-migration/lib/util/logEventV2";

function coerceToOwnershipIntersectableGeometry(
  geometry: Nullable<SelectionGeometry>
): Nullable<OwnershipIntersectableGeometry> {
  if (geometry?.type !== "MultiLineString" && geometry?.type !== "LineString") return geometry;
  return buffer(geometry, 1, { units: "meters", steps: 1 }).geometry;
}

export function OwnershipSelectionSidebarUK({
  selection,
  setDetailSelection,
  onLoadingChange,
}: LayerTypeSelectionSidebarProps) {
  const location = coerceToOwnershipIntersectableGeometry(selection.feature?.geometry || null);
  const { titlesWithOwnerNames, loading } = useTitlesWithOwnerNamesQuery(location);

  useSelectionSidebarLoading(onLoadingChange, loading);

  const setSelectedOwner = useCallback(
    (name?: string) => {
      setDetailSelection?.(
        name
          ? {
              id: name,
              type: SelectionType.OWNER,
              feature: feature(location!),
            }
          : undefined
      );
      logEventV2({
        name: "Workbench card click",
        properties: {
          cardName: "Ownership",
          action: "goTo",
          clickDetail: "Title owner",
        },
      });
    },
    [location, setDetailSelection]
  );

  if (!location) return null;

  if (loading) return null;

  return (
    <RelatedOwners
      setSelectedOwner={setSelectedOwner}
      titlesWithOwnerNames={titlesWithOwnerNames}
      withCard
    />
  );
}

export function OwnershipSelectionSidebarHeaderUK() {
  const { t } = useTranslation();
  return (
    <>
      <h1 className="atlas-mr-1 atlas-text-sm atlas-font-semibold atlas-text-content-primary">
        {t("ownership.react.map.sidebar.title")}
      </h1>
      <InfoPopover size="xs">{t("ownership.titles_section.title_owners")}</InfoPopover>
    </>
  );
}
