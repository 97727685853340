import { SearchLocation } from "../SearchLocation";

export type LocationSearchFilterId = "search-location-id";

export enum SearchLocationFilterMust {
  INTERSECT = "INTERSECT",
  WITHIN = "WITHIN",
}


export type LocationFilterMustDTO = keyof typeof SearchLocationFilterMust;

export interface ILocationFilter {
  id: LocationSearchFilterId;
  location: SearchLocation;
  must: SearchLocationFilterMust;
  type: "location";
}

export type LocationFilterDTO = Omit<ILocationFilter, "must"> & { must: LocationFilterMustDTO };
