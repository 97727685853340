import { Nullable } from "src/js/types/Nullable";

export interface DeckMetrics {
  fps: number;
  setPropsTime: number;
  updateAttributesTime: number;
  framesRedrawn: number;
  pickTime: number;
  pickCount: number;
  gpuTime: number;
  gpuTimePerFrame: number;
  cpuTime: number;
  cpuTimePerFrame: number;
  bufferMemory: number;
  textureMemory: number;
  renderbufferMemory: number;
  gpuMemory: number;
}

type DeckMetricsToLog = DeckMetrics & { minFps: number; maxFps: number };

const AVERAGING_WINDOW_SECONDS = 60 * 10; // 10 minutes
const FIRST_METRICS_LOG_DELAY_MS = 10 * 1000; // log first metrics after 10 seconds
const METRICS_LOG_FREQUECY_MS = AVERAGING_WINDOW_SECONDS * 1000;

const metricsWindow: DeckMetrics[] = [];
let timeout: Nullable<ReturnType<typeof setTimeout>>;

export function trackMetrics(metricsDatum: DeckMetrics) {
  if (metricsWindow.length >= AVERAGING_WINDOW_SECONDS) {
    metricsWindow.shift();
  }

  // deck metrics uses getters, need to clone to avoid reference issues
  metricsWindow.push(structuredClone(metricsDatum));
}

export function initDeckMetricsTracking() {
  if (!timeout) {
    metricsWindow.length = 0;
    timeout = setTimeout(logMetrics, FIRST_METRICS_LOG_DELAY_MS);
  }
}

export function stopDeckMetricsTracking() {
  if (timeout) {
    clearTimeout(timeout);
    timeout = null;
    metricsWindow.length = 0;
  }
}

function logMetrics() {
  import("@datadog/browser-rum")
    .then(({ datadogRum }) => {
      const metrics = getAverageMetrics();

      if (metrics) {
        datadogRum.addAction("performance.deckgl", metrics);
      }

      timeout = setTimeout(logMetrics, METRICS_LOG_FREQUECY_MS);
    })
    .catch((error) => {
      console.error("Failed to log deck.gl metrics", error);
    });
}

function getAverageMetrics(): Nullable<DeckMetricsToLog> {
  if (!metricsWindow.length) return null;

  let minFps = metricsWindow[0].fps;
  let maxFps = metricsWindow[0].fps;

  const accumulated: DeckMetrics = metricsWindow.reduce((acc, curr) => {
    minFps = Math.min(minFps, curr.fps);
    maxFps = Math.max(maxFps, curr.fps);

    return {
      fps: curr.fps + acc.fps,
      setPropsTime: curr.setPropsTime + acc.setPropsTime,
      updateAttributesTime: curr.updateAttributesTime + acc.updateAttributesTime,
      framesRedrawn: curr.framesRedrawn + acc.framesRedrawn,
      pickTime: curr.pickTime + acc.pickTime,
      pickCount: curr.pickCount + acc.pickCount,
      gpuTime: curr.gpuTime + acc.gpuTime,
      gpuTimePerFrame: curr.gpuTimePerFrame + acc.gpuTimePerFrame,
      cpuTime: curr.cpuTime + acc.cpuTime,
      cpuTimePerFrame: curr.cpuTimePerFrame + acc.cpuTimePerFrame,
      bufferMemory: curr.bufferMemory + acc.bufferMemory,
      textureMemory: curr.textureMemory + acc.textureMemory,
      renderbufferMemory: curr.renderbufferMemory + acc.renderbufferMemory,
      gpuMemory: curr.gpuMemory + acc.gpuMemory,
    };
  });

  const averaged = Object.fromEntries(
    Object.entries(accumulated).map(([key, value]: [string, number]) => [
      key,
      value / metricsWindow.length,
    ])
  ) as unknown as DeckMetrics;

  return {
    minFps,
    maxFps,
    ...averaged,
  };
}
