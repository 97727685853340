import { SingleDesignation } from "react-migration/domains/constraints/typings/applicationTypes/SingleDesignation";
import { ResponsiveBar } from "@nivo/bar";
import { getAttributeNumericValue } from "../../utils";
import { isDefined } from "react-migration/lib/util/isDefined";
import { OutputAreaAttribute } from "../types";
import { ChartTooltip } from "./ChartTooltip";
import { Color } from "d3-color";

interface NationalDensityComparisonChartProps {
  chartedAttribute: OutputAreaAttribute;
  designation: SingleDesignation;
  comparativeDesignation?: SingleDesignation;
  countryDesignation: SingleDesignation;
  countryAttributes: OutputAreaAttribute[];
  colorAccessor: (id: string) => Color;
}

export function NationalDensityComparisonChart({
  chartedAttribute,
  designation,
  comparativeDesignation,
  countryDesignation,
  countryAttributes,
  colorAccessor,
}: NationalDensityComparisonChartProps) {
  const data = [
    {
      key: designation.display_name!,
      value: getAttributeNumericValue(
        designation.designation_attributes,
        chartedAttribute.attributeKey
      )!,
      color: colorAccessor(designation.id).toString(),
    },
    ...(comparativeDesignation
      ? [
          {
            key: comparativeDesignation.display_name!,
            value: getAttributeNumericValue(
              comparativeDesignation.designation_attributes,
              chartedAttribute.attributeKey
            )!,
            color: colorAccessor(comparativeDesignation.id).toString(),
          },
        ]
      : []),
    ...countryAttributes.map(({ label, attributeKey }) => ({
      key: label,
      color: colorAccessor(attributeKey).toString(),
      value: getAttributeNumericValue(countryDesignation.designation_attributes, attributeKey)!,
    })),
  ]
    .filter((x) => isDefined(x.key) && isDefined(x.value))
    .sort((a, b) => b.value - a.value);

  return (
    <div style={{ height: `${150}px` }}>
      <ResponsiveBar
        data={data}
        indexBy="key"
        layout="horizontal"
        margin={{ left: 130, bottom: 20 }}
        padding={0.2}
        isInteractive={true}
        tooltip={ChartTooltip}
        valueFormat={chartedAttribute.format}
        axisBottom={{
          tickSize: 0,
          tickPadding: 5,
          tickRotation: 0,
          truncateTickAt: 0,
          tickValues: 6,
        }}
        colors={(datum) => datum.data.color}
        theme={{
          axis: {
            ticks: { text: { fill: "#6d7482" } },
            domain: {
              line: {
                stroke: "#e5e7eb",
                strokeWidth: 2,
              },
            },
          },
        }}
        enableGridY={false}
        enableLabel={false}
        animate
      />
    </div>
  );
}
