import { mapStore } from "src/js/stores/map/store";

type Constructor = new (...args: any[]) => any;

/**
 * Takes an array of DeckLayer classes and returns an array of the IDs of the
 * instances of those classes in the map store.
 * @param DeckLayerClasses Array of deck layer classes
 * @returns Array of IDs of the instances of the classes in the map store
 */
export function getDeckLayerInstanceIds(DeckLayerClasses: Constructor[]) {
  return mapStore.deckLayers
    .map(({ layer }) => layer)
    .filter((layer) => DeckLayerClasses.some((Class) => layer instanceof Class))
    .map((layer) => layer.id);
}
