import Feature from "src/js/stores/user/Feature";
import { ConstraintsCategory } from "../../Constraints/types";
import { ConstraintsLayerType } from "../layer_types/ConstraintsLayerType";
import { Layer } from "../types";

export const ALLOCATIONS: Layer = {
  id: "allocations",
  layerType: ConstraintsLayerType,
  title: "Allocations",
  requiredFeatureAccess: [Feature.planningPolicyLayer],
  toggleable: true,
  description: "Suitability of land potentially available for housing development",
  hideByDefault: true,
  layerConfig: {
    rootCategory: ConstraintsCategory.DEVELOPMENT_SITES,
    categorySchema: [
      { key: "housing_outer" },
      { key: "residential_allocation" },
      { key: "mixed_use_allocation" },
      { key: "commercial_allocation" },
      { key: "employment_allocation" },
      { key: "community_allocation" },
      { key: "uncategorised_allocation" },
    ],
    statusSchema: [{ key: "adopted" }, { key: "draft", defaultValue: false }],
  },
};
