import { SelectionHandler } from "../../../types";
import { DesignationSelectionDetailView } from "../DesignationSelectionDetailView";
import { DesignationSelectionTitle } from "../DesignationSelectionTitle";
import { OwnershipDesignationSelectionHeader } from "./OwnershipDesignationSelectionHeader";

export const OwnershipDesignationSelectionHandler: SelectionHandler = {
  SelectionTitle: DesignationSelectionTitle,
  SelectionDetailView: DesignationSelectionDetailView,
  SelectionHeader: OwnershipDesignationSelectionHeader,
};
