import { zodResolver } from "@hookform/resolvers/zod";
import { useMemo } from "react";
import { FormProvider, useForm } from "react-hook-form";

import { TemplateValidationSchema, TemplateFormData } from "./TemplateFormDataSchema";

export interface TemplateFormProviderProps extends React.PropsWithChildren {
  data?: Partial<TemplateFormData>;
  hasSignature?: boolean;
}

export const TemplateFormProvider = ({ data, children }: TemplateFormProviderProps) => {
  const defaultValues = useMemo(
    () => ({
      logo: undefined,
      greeting: "",
      bodyText: "",
      signOff: "",
      signature: undefined,
      contactName: "",
      contactEmail: "",
      contactPhone: "",
      bodyTextPage2: "",
      signaturePage: 1,
      showSiteBoundary: false,
      vatRegistrationNumber: "",
      companyRegistrationNumber: "",
      senderAddressLine1: "",
      senderCity: "",
      senderName: "",
      senderPostcode: "",
      senderProvince: "",
      ...data,
    }),
    [data]
  );

  const methods = useForm<TemplateFormData>({
    mode: "onChange",
    resolver: zodResolver(TemplateValidationSchema),
    defaultValues,
  });

  return <FormProvider {...methods}>{children}</FormProvider>;
};
