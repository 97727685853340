import { subscribe } from "valtio/vanilla";
import { comparablesStore, localStorageKey } from "../store";

const excludeFromLocalStorage: string[] = [];

const syncAllowedStoreKeysToLocalStorage = () => {
  const storeToSave = Object.fromEntries(
    Object.entries(comparablesStore).filter((key) => !excludeFromLocalStorage.includes(key[0]))
  );
  localStorage.setItem(localStorageKey, JSON.stringify(storeToSave));
};
const syncStoreToLocalStorage = () => {
  subscribe(comparablesStore, syncAllowedStoreKeysToLocalStorage);
};

export { syncStoreToLocalStorage, syncAllowedStoreKeysToLocalStorage };
