import { createContext, useContext, useState } from "react";
import { LayerTypeProviderProps } from "../../types";

interface DrawingsLayerTypeContextProps {
  hoveredId?: string;
  setHoveredId(hoveredId: string | undefined): void;
}

const DrawingsLayerTypeContext = createContext({} as DrawingsLayerTypeContextProps);

export const useDrawingsLayerTypeContext = () => useContext(DrawingsLayerTypeContext);

export function DrawingsLayerTypeProvider({ children }: LayerTypeProviderProps) {
  const [hoveredId, setHoveredId] = useState<string>();

  return (
    <DrawingsLayerTypeContext.Provider value={{ hoveredId, setHoveredId }}>
      {children}
    </DrawingsLayerTypeContext.Provider>
  );
}
