import { useQuery } from "@apollo/client";
import { AccountResponse } from "react-migration/domains/constraints/typings/applicationTypes/Account";
import {
  LandTechEndpoints,
  routedClient,
} from "react-migration/lib/persistence/apollo/routedClient";
import GET_ACCOUNT from "../apollo/queries/account.gql";

export const getAccount = async () => {
  const res = await routedClient.query({
    query: GET_ACCOUNT,
    context: {
      endpoint: LandTechEndpoints.PrivateClient,
    },
  });

  return res.data;
};

export const useAccountQuery = () => {
  return useQuery<AccountResponse>(GET_ACCOUNT, {
    client: routedClient,
    fetchPolicy: "network-only",
    context: {
      endpoint: LandTechEndpoints.PrivateClient,
    },
  });
};
