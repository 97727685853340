import { Switch } from "@landtechnologies/components";
import { useTranslation } from "react-migration/lib/i18n/useTranslation";
import { getHideTitleDocPurchaseAddressPrompt } from "src/js/stores/user/actions/getSetting";
import saveUser from "src/js/stores/user/actions/saveUser";
import { updateHideTitleDocPurchaseAddressPrompt } from "src/js/stores/user/actions/updateSetting";

export const AddressConfirmationSwitch = () => {
  const { t } = useTranslation();

  const handleToggle = async () => {
    updateHideTitleDocPurchaseAddressPrompt(!getHideTitleDocPurchaseAddressPrompt());
    await saveUser();
  };
  return (
    <div className="atlas-flex atlas-items-center atlas-gap-2 atlas-mt-4">
      <div className="atlas-text-neutral-600">
        {t("dashboard.addresses.hide_address_confirmation")}
      </div>
      <Switch
        value={getHideTitleDocPurchaseAddressPrompt()}
        id="hide-address-confirmation-switch"
        label=""
        size="small"
        onToggle={handleToggle}
      />
    </div>
  );
};
