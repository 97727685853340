import { useQuery } from "@apollo/client";
import { routedClient } from "react-migration/lib/persistence/apollo";
import propertyInformationByUPRN from "./propertyInformationByUPRN.gql";

interface Dimensions {
  /** @deprecated - use metric equivalent */
  area_building: number;
  area_building_metric: number;
  area_floor_total_metric: number;
  /** @deprecated - use metric equivalent */
  area_gross: number;
  area_gross_metric: number;
  /** @deprecated - use metric equivalent */
  area_lot_width: number;
  area_lot_width_metric: number;
  /** @deprecated - use metric equivalent */
  area_lot: number;
  area_lot_metric: number;
  floor_area_ratio: number | null;
  coverage: number | null;
}

export type PropertyInformationType = {
  dimensions: Dimensions;
  address: {
    full_address: string;
    uprn: string;
  };
  year_built: number | null;
  vacant_lot: string | null;
};

interface QueryData {
  propertyInformationByUPRN: PropertyInformationType;
}

interface QueryVariables {
  uprn: string;
}

/**
 * Takes a title number (attom ID in US) and returns the relevant property information
 * @param uprn: string - A UPRN to identify the property (equivelant to title_no in the US)
 * @returns QueryResult
 */
export function usePropertyInformationByUPRNQuery(uprn?: string) {
  const query = useQuery<QueryData, QueryVariables>(propertyInformationByUPRN, {
    variables: { uprn: uprn! },
    skip: !uprn,
    client: routedClient,
  });

  return { propertyInformation: query.data?.propertyInformationByUPRN, ...query };
}
