import { useState } from "react";
import clsx from "classnames";

import { Button, Icon } from "@landtechnologies/components";

import { useTranslation } from "react-migration/lib/i18n/useTranslation";

import { DeleteSiteModal } from "./DeleteSiteModal";

export interface DeleteSiteButtonProps {
  siteId: string;
  classNames?: string | string[];
  onDelete?(): void;
}

export const DeleteSiteButton = ({ classNames, ...props }: DeleteSiteButtonProps) => {
  const { t } = useTranslation();
  const [deletingSite, setDeletingSite] = useState<boolean>(false);

  return (
    <>
      <Button.Secondary
        data-testid="delete-site"
        title="Delete"
        size="small"
        className={clsx("atlas-w-8 atlas-h-8", classNames)}
        onClick={() => {
          setDeletingSite(true);
        }}
      >
        <span className="atlas-sr-only">{t("sites.card.site_card.delete")}</span>
        <Icon icon="icon-lt-delete-bin-line" size="sm" cursor="pointer" />
      </Button.Secondary>
      {deletingSite && <DeleteSiteModal onCancel={() => setDeletingSite(false)} {...props} />}
    </>
  );
};
