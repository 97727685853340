import { Group, Search } from "./getDefaultSearch";
import { validateSearch } from "./validateSearch";

// WARNING - these types are just a guess from the JS code that getDefaultSearch was ported from
export interface Term {
  searches: Record<string, unknown[]>;
  label: string;
  or_set: Group[] | string;
}

export function addTermFilter(search: Search, term: Term) {
  // this accepts either suggestions or a raw string (see text_search.vue)
  search = validateSearch(search);
  let group: Group;

  if (!term) {
    return;
  } else if (term.searches) {
    group = {
      search_group_details: {
        id: term.label,
        name: term.label,
        isAggregate: true,
        orSet: term.or_set || null,
      },
      or: [],
    };
    Object.keys(term.searches)
      .filter((field) => Array.isArray(term.searches[field]))
      .forEach((field) => {
        term.searches[field].forEach((value) =>
          group.or?.push({
            value,
            field,
            matchType: "match_phrase",
          })
        );
      });
  } else {
    group = {
      search_group_details: {
        id: term.label,
        name: term.label,
        isAggregate: false,
        orSet: null,
      },
      or: [
        {
          value: term.label,
          matchType: "match_phrase",
          field: "all_text",
        },
      ],
    };
  }

  if (
    search.composite_terms.and?.some((s) =>
      s.or?.some((t) => t.id === group.search_group_details?.id)
    )
  ) {
    return; // group already present
  }

  let orSet: Group | undefined = undefined;
  if (group.search_group_details?.orSet) {
    orSet = search.composite_terms.and?.find((s) =>
      s.or?.some((t) => t.search_group_details?.orSet === group.search_group_details?.orSet)
    );
  }
  if (!orSet) {
    orSet = { or: [] };
    search.composite_terms.and?.push(orSet);
  }
  orSet.or?.push(group);
}
