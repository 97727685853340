import { useMutation } from "@apollo/client";
import { routedClient } from "react-migration/lib/persistence/apollo/routedClient";
import UPDATE_USER_ADDRESS from "../apollo/mutation/updateUserAddress.gql";
import GET_USERS_ADDRESSES from "../apollo/queries/userAddresses.gql";
import {
  UpdateUserAddressResponse,
  Address,
  UserAddressesResponse,
} from "../typings/UserAddressesResponse";

type Variables = {
  address?: {
    receiver_name: string;
    line1: string;
    line2: string;
    city: string;
    postcode: string;
    country: string;
  };
  as_default: boolean;
  address_id: string;
};

export const useUpdateUserAddressMutation = () => {
  return useMutation<UpdateUserAddressResponse, Variables>(UPDATE_USER_ADDRESS, {
    client: routedClient,
    update: (store, { data }) => {
      const original = store.readQuery<UserAddressesResponse>({ query: GET_USERS_ADDRESSES });
      const updatedAddresses = original?.userAddresses.map((address) => {
        if (address._id === data?.updateUserAddress?._id) {
          return { ...address, ...data.updateUserAddress };
        } else {
          const newAddress: Address = { ...address };
          newAddress.is_default = data?.updateUserAddress.is_default ? false : address.is_default;
          return newAddress;
        }
      });
      const updated = { userAddresses: updatedAddresses };
      store.writeQuery({ query: GET_USERS_ADDRESSES, data: updated });
    },
    optimisticResponse: (variables) => {
      return {
        updateUserAddress: {
          ...variables.address,
          _id: variables.address_id,
          is_default: variables.as_default,
          __typename: "UserAddress",
        },
      };
    },
  });
};
