import { useSiteById } from "react-migration/domains/sites/hooks/useSiteById";
import { useSitePaginationQuery } from "./useSitePaginationQuery";
import { SiteEdge } from "./types";

export function useSitePagination(siteId?: string) {
  const { site } = useSiteById(siteId);
  const stageId = site?._stage?._id;

  const { edges, count, loading } = useSitePaginationQuery({ siteId, stageId });
  const siteEdge = edges?.find((edge) => edge.node._id === siteId);

  const currentIndex = siteEdge?.index ?? -1;
  const previousSiteEdge = findSiteEdgeAtOffset(edges, currentIndex, -1);
  const nextSiteEdge = findSiteEdgeAtOffset(edges, currentIndex, 1);

  return {
    totalSites: count ?? 0,
    position: currentIndex + 1,
    disabled: loading,
    previousSiteEdge,
    nextSiteEdge,
  };
}

function findSiteEdgeAtOffset(edges: SiteEdge[], index: number, offset: number) {
  if (index < 0) return undefined;

  const needleIndex = index + offset;

  return edges.find((edge) => edge.index === needleIndex);
}
