import { useCallback, useState } from "react";

export function useArrayToggle<T = string>(initialValue: T[] = []) {
  const [array, setArray] = useState(initialValue);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const toggleValue = useCallback((value: T) => {
    setArray((array) =>
      array.includes(value) ? array.filter((item) => item !== value) : [...array, value]
    );
  }, []);

  return [array, toggleValue] as const;
}
