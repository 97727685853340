import React from "react";
import clsx from "clsx";
interface StripeInputWrapperProps {
  children: React.ReactNode;
  error: string;
  icon?: React.ReactNode;
  labelText: string;
}

export const StripeInputWrapper = ({
  children,
  error,
  icon,
  labelText,
}: StripeInputWrapperProps) => {
  const wrapperClasses = clsx(
    "atlas-border atlas-max-h-[32px] atlas-rounded atlas-px-3 atlas-py-[7px] atlas-bg-white atlas-rounded atlas-border-neutral-300 hover:atlas-border-neutral-500 focus-within:atlas-outline focus-within:atlas-border-blue-600 atlas-outline-1 atlas-outline-blue-600/[.10]",
    error && "atlas-border-red-700",
    icon && "atlas-grid atlas-grid-cols-[35px_1fr]"
  );
  return (
    <div className="atlas-mb-4">
      <label className="atlas-text-xs atlas-leading-5 atlas-pb-2 atlas-m-0 atlas-font-semibold">
        {labelText}
      </label>
      <div className={wrapperClasses}>
        {icon}
        {children}
      </div>
      {error && (
        <p className="atlas-text-xs atlas-leading-5 atlas-pt-1 atlas-m-0 atlas-font-normal atlas-text-red-700">
          {error}
        </p>
      )}
    </div>
  );
};
