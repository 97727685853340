import { Route } from "vue-router";
import { User } from "../stores/user/store";
import Feature from "../stores/user/Feature";
import ROUTES from "./routes";
import { logEventV2 } from "react-migration/lib/util/logEventV2";

export const logNavigation = (route: Route) => {
  logEventV2({
    name: "Navigation",
    properties: {
      route: route.name ? route.name.split(/[/?]/)[0] : "",
    },
  });
};

const publicRoutes = [ROUTES.PUBLIC_MAP, ROUTES.LOGOUT];

export const checkUser = ({ user, routeName = "" }: { user: User; routeName?: string | null }) => {
  if (routeName && publicRoutes.includes(routeName)) {
    return { authenticated: true, error: null };
  }
  if (!user || !user._id) {
    return { authenticated: false, error: null };
  }
  if (!user.features.includes(Feature.landInsightAccess)) {
    return { authenticated: false, error: "No LandInsight licence for user." };
  }
  return { authenticated: true, error: null };
};
