<template>
  <div class="atlas-flex atlas-flex-col atlas-h-[100vh] atlas-overflow-hidden">
    <NavbarLayout />
    <div class="letter-sending atlas-justify-between atlas-flex atlas-flex-row">
      <Letters />
    </div>
  </div>
</template>

<script>
import { NavbarLayout } from "react-migration/layouts/nav/NavbarLayout";
import { Letters } from "react-migration/domains/sites/letters/Letters";

export default {
  components: {
    Letters,
    NavbarLayout,
  },
  data: () => ({}),
};
</script>

<style lang="scss">
.letter-sending {
  height: calc(100vh - 52px);
  display: flex;
  flex-direction: row;
  @media (max-width: 640px) {
    flex-direction: column;
  }
}
</style>
