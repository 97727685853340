import { useMemo } from "react";

import { Tag, TagColor, TagProps } from "../Tag";

export interface TagItem extends TagProps {
  id?: string;
}

export interface TagsProps {
  tags: TagItem[];
  placeholder?: string;
  truncate?: boolean;
  truncateCount?: number;
}

export function Tags({
  tags,
  placeholder = "Add label",
  truncate = false,
  truncateCount = 2,
}: TagsProps) {
  const displayTags = useMemo(() => {
    return truncate ? tags.slice(0, truncateCount) : tags;
  }, [tags, truncate, truncateCount]);

  const remainingCount = useMemo(() => {
    return truncate ? tags.length - truncateCount : 0;
  }, [tags, truncate, truncateCount]);

  return (
    <div className="atlas-flex atlas-gap-1 atlas-items-center atlas-relative atlas-flex-wrap">
      {displayTags.length > 0 ? (
        displayTags
          .map(({ id, tag, color, onClose }, index) => (
            <Tag key={id ?? index} tag={tag} color={color} onClose={onClose} />
          ))
          .filter(Boolean)
      ) : (
        <span className="atlas-text-blueGrey-900 atlas-text-xs atlas-font-medium">
          {placeholder}
        </span>
      )}
      {remainingCount > 0 && (
        <Tag key="remaining" tag={`+${remainingCount}`} color={TagColor.LIGHT_GRAY} />
      )}
    </div>
  );
}
