import { DetailsList } from "react-migration/components/DetailsList";
import { PlanningApplication } from "react-migration/domains/planning/types";
import { useTranslation } from "react-migration/lib/i18n/useTranslation";
import { PLANNING_TEXT_COLOR_MAP, PlanningColor, getAppealColor } from "../../utils/colors";
import { protectedDateFormatter } from "../../utils/protectedDateFormatter";

export const AppealDetails = ({
  planningApplication,
}: {
  planningApplication: PlanningApplication;
}) => {
  const { t } = useTranslation();

  return (
    <section className="atlas-border-t atlas-border-neutral-300">
      <div className="atlas-px-2 atlas-py-3 atlas-space-2 atlas-space-y-2">
        <h1 className="atlas-text-sm atlas-flex atlas-text-neutral-800 atlas-mb-1">
          {t("planning.planning_application_list.planning_application_list.planning_appeal_title", {
            caseNumber: planningApplication.appeal_case_number,
          })}
        </h1>
        <DetailsList.Item label="planning.planning_application_list.planning_application_list.planning_appeal_details.type">
          {planningApplication.appeal_type_of_appeal
            ? planningApplication.appeal_type_of_appeal
            : "N/A"}
        </DetailsList.Item>
        <DetailsList.Item label="planning.planning_application_list.planning_application_list.planning_appeal_details.type_reason">
          {planningApplication.appeal_type_reason ? planningApplication.appeal_type_reason : "N/A"}
        </DetailsList.Item>
        <DetailsList.Item label="planning.planning_application_list.planning_application_list.planning_appeal_details.address">
          {planningApplication.appeal_type_reason ? planningApplication.appeal_type_reason : "N/A"}
        </DetailsList.Item>
        <DetailsList.Item label="planning.planning_application_list.planning_application_list.planning_appeal_details.decision">
          <span
            className={
              planningApplication.appeal_decision && planningApplication.appeal_decision_date
                ? PLANNING_TEXT_COLOR_MAP[
                    getAppealColor(planningApplication.appeal_decision) || PlanningColor.DEFAULT
                  ]
                : undefined
            }
          >
            {planningApplication.appeal_decision && planningApplication.appeal_decision_date
              ? `${planningApplication.appeal_decision} (${
                  planningApplication.appeal_decision_date &&
                  protectedDateFormatter(planningApplication.appeal_decision_date)
                })`
              : "N/A"}
          </span>
        </DetailsList.Item>
        <DetailsList.Item label="planning.planning_application_list.planning_application_list.planning_appeal_details.date_received">
          {planningApplication.appeal_received_date
            ? protectedDateFormatter(planningApplication.appeal_received_date)
            : "N/A"}
        </DetailsList.Item>
        <DetailsList.Item label="planning.planning_application_list.planning_application_list.planning_appeal_details.development_type">
          {planningApplication.appeal_development_type
            ? planningApplication.appeal_development_type
            : "N/A"}
        </DetailsList.Item>
        <DetailsList.Item label="planning.planning_application_list.planning_application_list.planning_appeal_details.inspector">
          {planningApplication.appeal_inspector_name
            ? planningApplication.appeal_inspector_name
            : "N/A"}
        </DetailsList.Item>
        <DetailsList.Item label="planning.planning_application_list.planning_application_list.planning_appeal_details.appellant">
          {planningApplication.appeal_appellant ? planningApplication.appeal_appellant : "N/A"}
        </DetailsList.Item>
        <div className="atlas-flex atlas-space-x-2 atlas-gap-2 atlas-mt-5 atlas-text-content-tertiary">
          {planningApplication.appeal_inspector_name && (
            <div className="atlas-text-xs atlas-font-regular">
              <span>
                {t("planning.planning_application_list.planning_application_detail_view.source")}
              </span>
              <span className="atlas-font-semibold">
                {t(
                  "planning.planning_application_list.planning_application_detail_view.planning_inspectorate"
                )}
              </span>
            </div>
          )}
          {planningApplication.appeal_updated_at && (
            <div className="atlas-text-xs atlas-font-regular text-neutral-500">
              {t(
                "planning.planning_application_list.planning_application_detail_view.record_last_checked"
              )}
              <span className="atlas-font-semibold detail">
                {protectedDateFormatter(planningApplication.appeal_updated_at)}
              </span>
            </div>
          )}
        </div>
      </div>
    </section>
  );
};
