export const SubstationDescription = () => (
  <div className="atlas-flex atlas-flex-col atlas-gap-y-4">
    <div>
      <div className="atlas-font-semibold">400/132 kV (Grid):</div>
      <div>
        Substation that generally receives 275kv or 400kv from the National Grid and reduces it down
        to 132kv.
      </div>
    </div>

    <div>
      <div className="atlas-font-semibold">132/33 kV (Bulk):</div>
      <div>Intermediate substation generally where 132kV is transformed down to 33kV.</div>
    </div>

    <div>
      <div className="atlas-font-semibold">33/11 kV (Primary):</div>
      <div>Intermediate substation generally where 33kV is transformed down to 11kV.</div>
    </div>

    <div>
      <div className="atlas-font-semibold">{"<11 kV (HV) (UKPN only):"}</div>
      <div>Intermediate substation generally where 11kV is transformed down to low voltage.</div>
    </div>

    <div className="atlas-italic atlas-text-neutral-700">
      * These categories reflect the most common types and voltages of DNO substations, but we
      recognise there may be unique variations within each DNO. Please check the CSV data exports
      for specific details.
    </div>
  </div>
);
