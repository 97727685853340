import { forwardRef } from "react";
import clsx from "clsx";
import * as Select from "@radix-ui/react-select";
import { Icon } from "@landtechnologies/components";
import { useTranslation } from "react-migration/lib/i18n/useTranslation";
import { StageDotIcon } from "./StageDotIcon";

type SiteStageSelectItemProps = Select.SelectItemProps & {
  color: string;
  hidden: boolean;
};

export const SiteStageSelectItem = forwardRef<HTMLDivElement, SiteStageSelectItemProps>(
  ({ color, children, className, hidden, ...props }, forwardedRef) => {
    const { t } = useTranslation();

    return (
      <Select.Item
        ref={forwardedRef}
        className={clsx(
          "atlas-relative atlas-flex atlas-justify-stretch atlas-gap-x-1 atlas-items-center atlas-h-8 atlas-select-none atlas-rounded-md atlas-px-2 atlas-text-sm atlas-leading-none atlas-text-content-primary atlas-cursor-pointer data-[highlighted]:atlas-bg-button-ghost-hover data-[highlighted]:atlas-text-content-link-hover data-[highlighted]:atlas-outline-none data-[checked]:atlas-text-content-action",
          className
        )}
        {...props}
      >
        <div className="atlas-flex atlas-gap-x-2 atlas-items-center atlas-overflow-hidden">
          <StageDotIcon color={color} />
          <span className="atlas-text-sm atlas-text-content-primary atlas-truncate">
            {children}
          </span>
        </div>
        {hidden && (
          <span className="atlas-text-neutral-5 atlas-text-nowrap atlas-justify-self-end">
            {t("sites.save_site.save_site_dropdown.hidden")}
          </span>
        )}
        <Select.ItemIndicator className="atlas-inline-flex atlas-w-[25px] atlas-items-center atlas-justify-center atlas-ml-auto">
          <Icon icon="icon-lt-check-line atlas-text-content-action" />
        </Select.ItemIndicator>
      </Select.Item>
    );
  }
);

SiteStageSelectItem.displayName = "SiteStageSelectItem";
