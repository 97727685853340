import classNames from "classnames";

import { useSnapshot } from "valtio";
import { useTranslation } from "react-migration/lib/i18n/useTranslation";

import { SummaryDivider } from "./components/SummaryDivider";
import { useEffect } from "react";
import { letterStore } from "src/js/stores/letter/store";

interface PhysicalSendSummarySectionProps {
  onTotalCostCalculated?: (totalCost: number) => void;
}

const LETTER_COST_IN_CREDITS = 1;

export const PhysicalSendSummaryCreditsSection: React.FC<PhysicalSendSummarySectionProps> = ({
  onTotalCostCalculated,
}) => {
  const { t } = useTranslation();

  const { selectedRecipientIds } = useSnapshot(letterStore);
  const quantityOfLetters = selectedRecipientIds?.length ?? 1;
  const totalCostInCredits = quantityOfLetters * LETTER_COST_IN_CREDITS;

  useEffect(() => {
    onTotalCostCalculated?.(totalCostInCredits);
  }, [onTotalCostCalculated, totalCostInCredits]);

  return (
    <div
      className={classNames("atlas-p-4", "atlas-bg-white", "atlas-rounded-b-lg")}
      data-testid="site-letter-payment-summary-summary-section"
    >
      <div data-testid="product-details">
        <span
          className={classNames(
            "atlas-font-semibold",
            "atlas-text-base",
            "atlas-text-content-primary"
          )}
        >
          {t("sites.letters.letter_summary")}
        </span>
        <div className={classNames("atlas-mt-4", "atlas-flex", "atlas-justify-between")}>
          <div className={classNames("atlas-flex", "atlas-font-medium")}>
            <span className="atlas-text-content-secondary">{quantityOfLetters}x</span>

            <span className={classNames("atlas-ml-2", "atlas-text-content-primary")}>
              {t("sites.letters.standard_letter")}
            </span>
          </div>
          <span className={classNames("atlas-flex", "atlas-text-content-primary")}>
            {t("sites.letters.nr_of_credits", { credit: 1 })}
          </span>
        </div>
      </div>

      <SummaryDivider />

      <div
        className={classNames(
          "atlas-mt-4",
          "atlas-flex",
          "atlas-justify-between",
          "atlas-font-semibold",
          "atlas-text-base",
          "atlas-leading-6",
          "atlas-text-content-primary"
        )}
        data-testid="total"
      >
        <span className="atlas-flex">{t("sites.letters.total_due")}</span>
        <span className="atlas-flex" data-testid="credits">
          {t("sites.letters.nr_of_credits", { credit: totalCostInCredits })}
        </span>
      </div>
    </div>
  );
};
