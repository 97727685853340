import { logEvent } from "react-migration/lib/util/logEvent";
import { comparablesStore } from "../store";
import { ComparablesState } from "react-migration/domains/comparables/typings/Comparables";

export const setFilters = (filters: ComparablesState["filters"]) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  comparablesStore.filters = filters;
};

export const setFiltersOpen = (filtersOpen: ComparablesState["filtersOpen"]) => {
  comparablesStore.filtersOpen = filtersOpen;
};

export const setMapMode = (mapMode: ComparablesState["mapMode"]) => {
  comparablesStore.mapMode = mapMode;
};

export const setPriceMode = (priceMode: ComparablesState["priceMode"]) => {
  comparablesStore.priceMode = priceMode;
};

export const setPriceType = (priceType: ComparablesState["priceType"]) => {
  comparablesStore.priceType = priceType;
};

export const setSmallUnitSize = (smallUnitSize: ComparablesState["smallUnitSize"]) => {
  comparablesStore.smallUnitSize = smallUnitSize;
  logEvent("Comparables Unit Size", { smallUnitSize, priceMode: comparablesStore.priceMode });
};

export const setIncNoFloorArea = (incNoFloorArea: ComparablesState["incNoFloorArea"]) => {
  comparablesStore.incNoFloorArea = incNoFloorArea;
  logEvent("Comparables Inc No Floor Area", {
    include: incNoFloorArea,
    priceMode: comparablesStore.priceMode,
  });
};

export const setIncludedResults = (includedResults: ComparablesState["includedResults"]) => {
  comparablesStore.includedResults = includedResults;
  logEvent("Comparables Inc No Floor Area", {
    include: includedResults,
    priceMode: comparablesStore.priceMode,
  });
};
