import { ISourcingToolSearchFilter } from "react-migration/domains/search/typings/SourcingToolSpecificFilterTypes";
import { SearchType } from "react-migration/domains/search/constants/searchTypes";

export const LotClusterAreaFilter: ISourcingToolSearchFilter = {
  id: "lot-cluster-area-id",
  type: "number-range",
  rangeType: "area",
  field: "sidebar.react.search_sidebar.parcel_size",
  isDefault: false,
  range: [null, null],
  rangeLimit: [0, null],
  updateFilterDialogIsVisible: false,
  isDisabled: true,
  searchTypes: [SearchType.SOURCING_TOOL_US_FL],
};
