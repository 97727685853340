import { ISourcingToolSearchFilter } from "react-migration/domains/search/typings/SourcingToolSpecificFilterTypes";
import { SearchType } from "react-migration/domains/search/constants/searchTypes";
import Feature from "src/js/stores/user/Feature";

export const TenureFilter: ISourcingToolSearchFilter = {
  id: "tenure-uk",
  type: "checkbox",
  must: "MATCH_ANY",
  field: "sidebar.react.search_sidebar.tenure_type",
  mustOptions: ["MATCH_ANY", "MATCH_NONE"],
  isDefault: [Feature.ukLocationSearch],
  updateFilterDialogIsVisible: false,
  searchTypes: [SearchType.SOURCING_TOOL_UK],
  values: [
    {
      key: "freehold",
      name: "Freehold",
      checked: true, //This is the default for now. We'll have to change this when introduce leasehold data
    },
    {
      key: "leasehold",
      name: "Leasehold",
    },
  ],
};
