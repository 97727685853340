import { useDesignationById } from "react-migration/domains/constraints/designation/hooks/useDesignationById";
import { useCategoryByIdQuery } from "../getCategoryByIdQuery";
import { ConstraintsCategory } from "react-migration/layouts/map/Constraints/types";
import { useMemo } from "react";
import { Category } from "react-migration/domains/constraints/typings/baseTypes/Category";
import { useSnapshot } from "valtio";
import { mapStore } from "src/js/stores/map/store";

type ZoomRestrictions = Record<string, [minZoom: number, maxZoom: number]>;

const ROOT_CATEGORY_ZOOM_RESTRICTIONS: ZoomRestrictions = {
  mining_and_geology: [0, 14],
};

type OutsideZoomRestrictions = boolean | "indeterminate";

export function useDesignationOutsideZoomRestrictions(selection: {
  id?: string;
}): OutsideZoomRestrictions {
  const { data: rootCategoryData } = useCategoryByIdQuery(ConstraintsCategory.ROOT);
  const { designation } = useDesignationById(selection.id);
  const { zoom } = useSnapshot(mapStore.settings);
  const deckZoom = zoom - 1;

  const subCategoryMap = useMemo(() => {
    const SubCategoryMap = new Map<string, Category>();

    function addCategoryToMapRecursive(category: Category) {
      SubCategoryMap.set(category.id, category);

      if (!category.categories?.length) return;

      for (const child of category.categories) {
        addCategoryToMapRecursive(child);
      }
    }

    if (rootCategoryData) addCategoryToMapRecursive(rootCategoryData.category);

    return SubCategoryMap;
  }, [rootCategoryData]);

  const isOutsideZoomRestrictions = useMemo<OutsideZoomRestrictions>(() => {
    if (!designation || !subCategoryMap) return "indeterminate";

    function checkZoomRestrictionsRecursive(category: Category): boolean {
      if (ROOT_CATEGORY_ZOOM_RESTRICTIONS[category.id]) {
        const [minZoom, maxZoom] = ROOT_CATEGORY_ZOOM_RESTRICTIONS[category.id];

        return deckZoom < minZoom || deckZoom > maxZoom;
      }

      if (!category.parent_id) return false;
      const parentCategory = subCategoryMap.get(category.parent_id);
      if (!parentCategory) return false;

      return checkZoomRestrictionsRecursive(parentCategory);
    }

    const subCategory = subCategoryMap.get(designation.sub_category_id);
    if (!subCategory) return "indeterminate";

    return checkZoomRestrictionsRecursive(subCategory);
  }, [deckZoom, designation, subCategoryMap]);

  return isOutsideZoomRestrictions;
}
