import { ISourcingToolSearchFilter } from "react-migration/domains/search/typings/SourcingToolSpecificFilterTypes";
import { SearchType } from "react-migration/domains/search/constants/searchTypes";

export const FlumFilter: ISourcingToolSearchFilter = {
  id: "flum-id",
  type: "checkbox",
  field: "sidebar.react.search_sidebar.flum",
  must: "MATCH_ANY",
  mustOptions: ["MATCH_ANY", "MATCH_NONE"],
  updateFilterDialogIsVisible: false,
  values: [
    { name: "Planned", key: "planned" },
    { name: "Special (Special)", key: "special" },
    { name: "Federal (Special)", key: "federal" },
    { name: "Institution (Special)", key: "institutional" },
    { name: "Recreation (Special)", key: "recreation" },
    { name: "Unknown (Special)", key: "unknown" },
    { name: "Native American Lands (Special)", key: "native_american_lands" },
    { name: "Water (Special)", key: "water" },
    { name: "Conservation (Special)", key: "conservation" },
    { name: "Transportation/Utilities (Special)", key: "transportation_utilities" },
    { name: "Mixed use (Mixed use)", key: "mixed_use" },
    {
      name: "Neighbourhood Activity Center (Mixed use)",
      key: "neighborhood_activity_center",
    },
    { name: "Downtown Urban Core (Mixed use)", key: "downtown_urban_core" },
    { name: "General (Mixed use)", key: "mixed_use_general" },
    { name: "Special Planning Area (Mixed use)", key: "special_planning_area" },
    { name: "Rural (Mixed use)", key: "mixed_use_rural" },
    { name: "Single Family (Residential)", key: "residential_single_family" },
    { name: "Two Family (Residential)", key: "residential_two_family" },
    { name: "Multi Family (Residential)", key: "residential_multi_family" },
    { name: "Neighbourhood (Commercial)", key: "commercial_neighborhood" },
    { name: "General (Commercial)", key: "commercial_general" },
    { name: "Core (Commercial)", key: "commercial_core" },
    { name: "Retail (Commercial)", key: "commercial_retail" },
    { name: "Special (Commercial)", key: "commercial_special" },
    { name: "Office (Commercial)", key: "commercial_office" },
    { name: "Light Industrial (Industrial)", key: "industrial_light" },
    { name: "Industrial (Industrial)", key: "industrial" },
  ],
  searchTypes: [SearchType.SOURCING_TOOL_US_FL],
};
