import Feature from "src/js/stores/user/Feature";
import { ConstraintsCategory } from "../../Constraints/types";
import { ConstraintsLayerType } from "../layer_types/ConstraintsLayerType";
import { Layer } from "../types";

export const SOILS_AND_GEOLOGY: Layer = {
  id: ConstraintsCategory.SOILS_AND_GEOLOGY,
  layerType: ConstraintsLayerType,
  hideByDefault: true,
  description: "Soil and geology data of importance to development",
  title: "Soil",
  requiredFeatureAccess: [Feature.geologyLayer],
  toggleable: true,
  layerConfig: {
    rootCategory: ConstraintsCategory.SOILS_AND_GEOLOGY,
    categorySchema: [{ key: "ssurgo" }, { key: "ssurgo_hydric" }],
  },
};
