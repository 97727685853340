import Feature from "src/js/stores/user/Feature";
import { ComparablesLayerType } from "../layer_types/ComparablesLayerType";
import { Layer } from "../types";

export const COMPARABLES_LAYER: Layer = {
  id: "COMPARABLES",
  layerType: ComparablesLayerType,
  title: "Comparables",
  requiredFeatureAccess: [Feature.pricepaidLayer],
  toggleable: true,
  description: "Explore sales prices",
};
