import { TitleOwnership } from "react-migration/domains/ownership/typings/Title";

export const UNKNOWN_OWNER = "UNKNOWN OWNER";

export const canonicalizeOwnerNames = (owners: TitleOwnership[] = []) => {
  const ownerNames = owners.map(canonicalizeOwnerName);

  return ownerNames.length ? ownerNames : [UNKNOWN_OWNER];
};

export const canonicalizeOwnerName = (owner?: TitleOwnership) => {
  return owner?.company
    ? owner.company?.name || `Company #${owner.company?.company_no}`
    : owner?.person?.name || UNKNOWN_OWNER;
};
