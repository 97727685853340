import React, { useEffect } from "react";
import { useSnapshot } from "valtio";
import { router } from "src/js/router/react-router";
import ROUTES from "src/js/router/routes";
import { LetterStep, letterStore } from "src/js/stores/letter/store";

import { ConfirmAndSendStep } from "./ConfirmAndSendStep/ConfirmAndSendStep";
import { DownloadLetterStep } from "./DownloadLetterStep/DownloadLetterStep";
import { LetterPreviewStep } from "./LetterPreviewStep";
import { OrderConfirmedStep } from "./OrderConfirmedStep";
import { SelectEditableRecipientsStep } from "./SelectEditableRecipientsStep";
import { TemplateEditorStep } from "./TemplateEditorStep";
import { siteStore } from "src/js/stores/site/store";

export const Letters = () => {
  const { siteCardId } = useSnapshot(siteStore);
  const { currentStep, selectedStageId } = useSnapshot(letterStore);

  useEffect(() => {
    if (!siteCardId && !selectedStageId) {
      router.push({
        name: ROUTES.SITES,
        query: {
          card_page: "letters",
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const ActiveLetterStep = () => {
    switch (currentStep) {
      case LetterStep.FinishAndDownload:
        return <DownloadLetterStep />;
      case LetterStep.SelectRecipients:
        return <SelectEditableRecipientsStep />;
      case LetterStep.TemplateEditor:
        return <TemplateEditorStep />;
      case LetterStep.PreviewLetter:
        return <LetterPreviewStep />;
      case LetterStep.ConfirmAndSend:
        return <ConfirmAndSendStep />;
      case LetterStep.OrderConfirmed:
        return <OrderConfirmedStep />;
      default:
        return <React.Fragment />;
    }
  };

  return (
    <div className="atlas-w-svw atlas-h-full">
      <ActiveLetterStep />
    </div>
  );
};
