import { Currency, formatToCurrency } from "react-migration/lib/util/numberFormat";
import { MeasurementSystem } from "src/js/stores/user/store";
import { SupportedLocale } from "src/js/util/dates";
import { Translation } from "react-migration/lib/typings";
import { pricePerUnitArea } from "react-migration/domains/comparables/util/Prices";
import { RecordType } from "../../../../typings/Record";

export interface PPUATitleProps {
  t: Translation;
  unitPreference: MeasurementSystem;
}
export const ppuaTitle = ({ t, unitPreference }: PPUATitleProps) =>
  t("comparables.map.sidebar.value.record.ppa.title", {
    unit:
      unitPreference === MeasurementSystem.METRIC
        ? t("comparables.sqm.alt")
        : t("comparables.sqft.alt"),
  });

export interface PPUATooltipProps {
  t: Translation;
}
export const ppuaTooltip = ({ t }: PPUATooltipProps) =>
  t("comparables.map.sidebar.value.record.ppa.tooltip");

export interface PPUAValueProps {
  t: Translation;
  record: RecordType;
  locale: SupportedLocale;
  unitPreference: MeasurementSystem;
}
export const ppuaValue = ({ t, record, locale, unitPreference }: PPUAValueProps) => {
  const area = record.total_floor_area ?? 0;

  const ppaSold = pricePerUnitArea({
    price: record.price || 0,
    area,
    unitPreference,
  });

  const ppaMarket = pricePerUnitArea({
    price: record.est_price || 0,
    area,
    unitPreference,
  });

  if (!(ppaSold > 0 || ppaMarket > 0)) {
    return undefined;
  }

  const soldValue =
    ppaSold > 0 &&
    t("comparables.map.sidebar.value.record.ppa.sold", {
      sold:
        formatToCurrency({
          amount: ppaSold,
          locale,
          currency: record.currency as Currency,
        }) || "-",
    });

  const marketValue =
    ppaMarket > 0 &&
    t("comparables.map.sidebar.value.record.ppa.market", {
      market:
        formatToCurrency({
          amount: ppaMarket,
          locale,
          currency: record.currency as Currency,
        }) || "-",
    });

  return soldValue ? `${soldValue} ${marketValue}` : `${marketValue}`;
};
