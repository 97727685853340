import { useMutation } from "@apollo/client";
import { routedClient } from "react-migration/lib/persistence/apollo/routedClient";
import { Position } from "geojson";
import { CreatedSite } from "../useSaveSiteMutation/useSaveSiteMutation";
import UPDATE_SITE from "../graphql/updateSite.gql";

interface SiteUpdateData {
  _id?: string;
  title?: string;
  notes?: string;
  site_labels?: { _id: string; name: string }[];
  _stage?: string | undefined;
  geometry?: { type: string; coordinates: Position[][] | Position[][][] };
  assignees?: string[];
}

export interface SiteUpdateResponse {
  updateSite: {
    site: CreatedSite;
  };
}

export interface UpdateSiteVariables {
  updateData: SiteUpdateData;
}

export const useUpdateSiteMutation = () => {
  return useMutation<SiteUpdateResponse, UpdateSiteVariables>(UPDATE_SITE, {
    client: routedClient,
  });
};
