import { SendOption } from "../../ConfirmAndSendStep/SendOptions";
import { DownloadSummarySection } from "./components/DownloadSummarySection";
import { PhysicalSendSummaryCreditsSection } from "./components/PhysicalSendSummaryCreditsSection";
import classNames from "classnames";
import { PaymentSummaryProps } from "./PaymentSummary";
import { CurrentBalanceCreditsSection } from "./components/CurrentBalanceCreditsSection";

export const PaymentSummaryUS = ({
  sendOption,
  onBalanceLoaded,
  onTotalCostCalculated,
}: PaymentSummaryProps) => {
  return (
    <div
      className={classNames(
        "atlas-w-[330px]",
        "atlas-h-fit",
        "atlas-border",
        "atlas-border-border-divider-subtle",
        "atlas-rounded-lg"
      )}
      data-testid="letter-payment-summary-wrapper"
    >
      <CurrentBalanceCreditsSection onBalanceLoaded={onBalanceLoaded} />

      {
        {
          [SendOption.Download]: <DownloadSummarySection />,
          [SendOption.Send]: (
            <PhysicalSendSummaryCreditsSection onTotalCostCalculated={onTotalCostCalculated} />
          ),
        }[sendOption]
      }
    </div>
  );
};
