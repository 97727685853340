import { useMemo } from "react";
import { ConstraintsCategory } from "react-migration/layouts/map/Constraints/types";
import { mapStore } from "src/js/stores/map/store";

import { useSnapshot } from "valtio";

export type SubCategoryCondition = {
  categoryId: ConstraintsCategory | null;
  alert?: string;
  maxZoom?: number;
};

export function useDemographicSubCategory(subCategoryCondition: SubCategoryCondition[]) {
  const { settings } = useSnapshot(mapStore);

  return useMemo(() => {
    const matchCondition = subCategoryCondition.find(
      ({ maxZoom = Infinity }) => settings.zoom <= maxZoom
    );

    return { categoryId: matchCondition?.categoryId || null, alert: matchCondition?.alert };
  }, [settings.zoom, subCategoryCondition]);
}
