import { getRegion } from "../stores/user/actions/getRegion";
import { ENVIRONMENT } from "util/environment";
import gql from "graphql-tag";
import { assignUser, captureException } from "./monitoring";
import axios from "axios";
import Feature from "../stores/user/Feature";
import {
  LandTechEndpoints,
  routedClient,
} from "react-migration/lib/persistence/apollo/routedClient";
import initialiseUserStore from "../stores/user/actions/initialiseUser";
import { updateUserLocale } from "../stores/user/actions/updateLocale";

const getGeofences = async (geofences) => {
  if (!geofences?.length) {
    return [];
  }
  const promises = geofences?.map(async (geometryUrl) => {
    try {
      const { data } = await axios.get(geometryUrl);
      return data;
    } catch (err) {
      console.error(err);
      return null;
    }
  });
  const geofenceGeometries = await Promise.all(promises);
  return geofenceGeometries.filter((geo) => geo);
};

async function getUser(accountServicePrivateApiApolloClient) {
  try {
    // We aim to get rid of the legacyExtraRestResp bit entirely, but for now we do still
    // need a few bits that are only available through the legacy route.
    // See notes further down the page here for some comments on how to get off the legacy stuff.
    const [graphqlResp, legacyExtraRestResp] = await Promise.all([
      accountServicePrivateApiApolloClient.query({
        query: gql`
          query {
            getSelf {
              _id
              email
              firstname
              surname
              telephone
              licence_summary
              features
              beta_features
              intercom_hash
              _organisation {
                name
              }
              has_overdue_invoices
              permissions
              stripe_managed_signup
              self_upgrade_options
              plan_name
              api_key
              self_service_trial_state {
                active
                end_date
                automatic_payment
              }
            }
          }
        `,
      }),
      fetch(`${ENVIRONMENT.API_URL}/user/get-legacy-bits`)
        .then((res) => {
          if (res.status === 401) {
            throw new Error("Unauthorized");
          }
          return res.text();
        })
        .then((jsonString) => JSON.parse(jsonString))
        .catch((err) => {
          captureException(err);
          return {
            code: "Invalid response",
            message: err.message,
          };
        }),
    ]);

    if (graphqlResp?.data?.getSelf && legacyExtraRestResp?.settings) {
      // maybe remove all the JSON.stringify from the permissions
      let permissionsString = graphqlResp.data.getSelf.permissions;
      if (typeof graphqlResp.data.getSelf.permissions !== "string") {
        permissionsString = JSON.stringify(graphqlResp.data.getSelf.permissions);
      }
      const permissions = JSON.parse(permissionsString || "{}");
      let uniqueGeofencesArray = [];
      if (permissions?.geofence) {
        const geofenceArray = permissions?.geofence.map(
          (geofenceObject) => geofenceObject.geometryUrl
        );

        const uniqueGeofences = new Set(geofenceArray);
        uniqueGeofencesArray = [...uniqueGeofences];
      }

      const hasUsAccess = graphqlResp.data.getSelf.features.includes(Feature.usAccess);
      if (hasUsAccess) {
        updateUserLocale("en-US");
      }

      return {
        ...graphqlResp.data.getSelf,
        permissions: {
          ...permissions,
          geofencesGeometries: await getGeofences(uniqueGeofencesArray),
        },
        region: getRegion(graphqlResp.data.getSelf),

        // these should be dealt with by a future accounts service approach to settings
        alert_notifications: legacyExtraRestResp.alert_notifications,
        settings: legacyExtraRestResp.settings,
        // this is needed by pusher, which definitely feels wrong
        _account: legacyExtraRestResp._account,
      };
    } else {
      return null;
    }
  } catch (e) {
    captureException(e);
    return null;
  }
}

export const initialiseUser = async (accountServicePrivateApiApolloClient) => {
  const user = await getUser(accountServicePrivateApiApolloClient);

  initialiseUserStore(user);
  assignUser(user);
};

export const refreshUser = async () => {
  if (ENVIRONMENT.APP_ENV !== "local") {
    return;
  }
  // the rest of this method _should_ get compiled away for production and staging builds as ENVIRONMENT.APP_ENV is a constant so this branch is always false
  try {
    // We aim to get rid of the legacyExtraRestResp bit entirely, but for now we do still
    // need a few bits that are only available through the legacy route.
    // See notes further down the page here for some comments on how to get off the legacy stuff.
    const [graphqlResp, legacyExtraRestResp] = await Promise.all([
      routedClient.query({
        query: gql`
          query {
            getSelf {
              _id
              email
              firstname
              surname
              telephone
              licence_summary
              features
              beta_features
              intercom_hash
              _organisation {
                name
              }
              has_overdue_invoices
              permissions
              stripe_managed_signup
              self_upgrade_options
              plan_name
              api_key
              self_service_trial_state {
                active
                end_date
                automatic_payment
              }
            }
          }
        `,
        context: {
          endpoint: LandTechEndpoints.PrivateClient,
        },
      }),
      fetch(`${ENVIRONMENT.API_URL}/user/get-legacy-bits`)
        .then((res) => {
          if (res.status === 401) {
            throw new Error("Unauthorized");
          }
          return res.text();
        })
        .then((jsonString) => JSON.parse(jsonString))
        .catch((err) => {
          captureException(err);
          return {
            code: "Invalid response",
            message: err.message,
          };
        }),
    ]);

    if (graphqlResp?.data?.getSelf && legacyExtraRestResp?.settings) {
      // maybe remove all the JSON.stringify from the permissions
      let permissionsString = graphqlResp.data.getSelf.permissions;
      if (typeof graphqlResp.data.getSelf.permissions !== "string") {
        permissionsString = JSON.stringify(graphqlResp.data.getSelf.permissions);
      }
      const permissions = JSON.parse(permissionsString || "{}");
      let uniqueGeofencesArray = [];
      if (permissions?.geofence) {
        const geofenceArray = permissions?.geofence.map(
          (geofenceObject) => geofenceObject.geometryUrl
        );

        const uniqueGeofences = new Set(geofenceArray);
        uniqueGeofencesArray = [...uniqueGeofences];
      }

      const hasUsAccess = graphqlResp.data.getSelf.features.includes(Feature.usAccess);
      if (hasUsAccess) {
        updateUserLocale("en-US");
      }

      // technically not initialising the user, but updating the user - should be fine and is only a dev-time concern
      initialiseUserStore({
        ...graphqlResp.data.getSelf,
        permissions: {
          ...permissions,
          geofencesGeometries: await getGeofences(uniqueGeofencesArray),
        },
        region: getRegion(graphqlResp.data.getSelf),

        // these should be dealt with by a future accounts service approach to settings
        alert_notifications: legacyExtraRestResp.alert_notifications,
        settings: legacyExtraRestResp.settings,
        // this is needed by pusher, which definitely feels wrong
        _account: legacyExtraRestResp._account,
      });
    } else {
      return null;
    }
  } catch (e) {
    captureException(e);
    return null;
  }
};
