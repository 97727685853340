import { routedClient } from "react-migration/lib/persistence/apollo/routedClient";
import CREATE_SITES_DRY_RUN from "../graphql/createSitesDryRun.gql";
import { useQuery } from "@apollo/client";
import { Site } from "../typings/Site";
import { CreateSitesDryRunResponse } from "../typings/CreateSitesDryRunRespone";

type useCreateSitesDryRunQueryParams = {
  shouldWait?: boolean;
  sites: Site[];
  onCompleted?: (value: CreateSitesDryRunResponse) => void;
  siteMatchType?: "EXACT" | "INTERSECTS_POINT";
};

export const useCreateSitesDryRunQuery = (args: useCreateSitesDryRunQueryParams) => {
  return useQuery<CreateSitesDryRunResponse>(CREATE_SITES_DRY_RUN, {
    client: routedClient,
    variables: { sites: args.sites, siteMatchType: args.siteMatchType },
    fetchPolicy: "network-only",
    onCompleted: args.onCompleted,
    skip: args.shouldWait,
  });
};
