import { feature } from "@turf/helpers";
import { SelectionType } from "src/js/stores/map/store";
import { cleanTypename } from "react-migration/lib/util/cleanTypename";
import type { SelectionResolver } from "../../types";
import { getSiteById } from "react-migration/domains/sites/hooks/useSiteById";

export const siteSelectionResolver: SelectionResolver = async (siteId) => {
  const site = await getSiteById(siteId);
  const siteClean = cleanTypename(site);

  if (!siteClean) return null;

  return {
    type: SelectionType.SITE,
    id: siteClean._id,
    feature: feature(siteClean.geometry),
  };
};
