import { ProgressBar, SizeClassName } from "@landtechnologies/components";
import { useTranslation } from "react-migration/lib/i18n/useTranslation";

type UsageRowProps = {
  title: string;
  children: React.ReactNode;
  progressBarColor?: "atlas-bg-lightGreen-500" | "atlas-bg-blue-600";
  used: number;
  cap: number;
  resetDate: string;
  unlimitedMessage?: string;
  isUnlimited: boolean;
};

export const UsageRow = ({
  title,
  children,
  progressBarColor = "atlas-bg-lightGreen-500",
  used,
  cap,
  resetDate,
  unlimitedMessage,
  isUnlimited,
}: UsageRowProps) => {
  const { t } = useTranslation();
  const left = cap - used;
  return (
    <>
      <p className="atlas-font-semibold atlas-text-lg atlas-mb-2 atlas-text-neutral-800">{title}</p>
      {isUnlimited && unlimitedMessage && (
        <p className="atlas-text-md atlas-font-semibold atlas-text-neutral-600 atlas-text-base">
          {unlimitedMessage}
        </p>
      )}
      {!isUnlimited && (
        <>
          <div className="atlas-flex atlas-gap-2 atlas-items-center atlas-mb-1">
            <div className="atlas-flex-grow atlas-max-w-[600px]">
              <ProgressBar
                rounded
                className={progressBarColor}
                size={SizeClassName.MLARGE}
                percentage={(100 * left) / cap}
              />
            </div>
            <div className="atlas-text-neutral-600">
              {t("dashboard.credit_summary.x_of_x_remaining", {
                left,
                cap,
              })}
            </div>
          </div>
          {resetDate && (
            <p className="atlas-text-sm atlas-text-neutral-600">
              {t("dashboard.credit_summary.resets_on_date", {
                date: resetDate,
              })}
            </p>
          )}
        </>
      )}
      <p className="atlas-text-md atlas-mb-6 atlas-text-neutral-600 atlas-text-base">{children}</p>
    </>
  );
};
