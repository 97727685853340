import { FC } from "react";
import { useSnapshot } from "valtio";
import { InfoList, Section } from "@landtechnologies/components";
import { MeasurementSystem } from "src/js/stores/user/store";
import { comparablesStore } from "src/js/stores/comparables";
import { SupportedLocale } from "src/js/util/dates";
import { ZooplaField } from "../../../typings/Zoopla";
import { RecordType } from "../../../typings/Record";
import { RecordActions } from "../RecordActions";
import { LandTechSection } from "./LandTechSection";
import { ZooplaSection } from "./ZooplaSection";
import { ZooplaPreview } from "./ZooplaPreview";

export interface RecordProps {
  record: RecordType;
  unitPreference: MeasurementSystem;
  locale: SupportedLocale;
  zooplaData?: ZooplaField[];
  zooplaDataLoading?: boolean;
}

export const Record: FC<RecordProps> = ({
  locale,
  record,
  unitPreference,
  zooplaData,
  zooplaDataLoading,
}) => {
  const { priceMode } = useSnapshot(comparablesStore);

  return (
    <Section data-testid="record">
      <InfoList>
        <InfoList.Item data-testid="record-item">
          <InfoList.ItemTitle>
            {record.address.split(", ").map((line: string) => (
              <div key={line} className="atlas-text-xs md:atlas-text-sm">
                {line}
              </div>
            ))}
          </InfoList.ItemTitle>
        </InfoList.Item>

        <ZooplaSection data={zooplaData} loading={zooplaDataLoading} record={record} />

        <ZooplaPreview data={zooplaData} loading={zooplaDataLoading} record={record} />

        <LandTechSection record={record} unitPreference={unitPreference} locale={locale} />
      </InfoList>
      <div className="atlas-p-2">
        <RecordActions record={record} priceMode={priceMode} />
      </div>
    </Section>
  );
};
