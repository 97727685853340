/* eslint-disable react/jsx-no-target-blank */
import { AnchorHTMLAttributes, ComponentType, FC, PropsWithChildren } from "react";
import { router } from "src/js/router/react-router";
import { Location } from "vue-router";

type AnchorProps = AnchorHTMLAttributes<HTMLAnchorElement>;

interface VueRouterLinkProps extends React.HtmlHTMLAttributes<HTMLAnchorElement> {
  to: Location;
  newTab?: boolean;
  AnchorComponent?: ComponentType<AnchorProps>;
}

const Anchor: FC<AnchorProps> = ({ children, ...props }) => <a {...props}>{children}</a>;

/* Support single page app routing using existing vue-router config. */
export function Link({
  children,
  to,
  newTab,
  className,
  AnchorComponent = Anchor,
  ...anchorProps
}: PropsWithChildren<VueRouterLinkProps>) {
  const handleClick = async (e: React.MouseEvent<HTMLAnchorElement>) => {
    if (!newTab) {
      e.preventDefault();
      await anchorProps.onClick?.(e);
      router.push(to);
    }
  };

  return (
    <AnchorComponent
      target={newTab ? "_blank" : "_self"}
      className={className}
      {...anchorProps}
      href={router.resolve(to).href}
      onClick={handleClick}
    >
      {children}
    </AnchorComponent>
  );
}
