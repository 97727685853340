import { useQuery } from "@apollo/client";
import { routedClient } from "react-migration/lib/persistence/apollo";
import ATTACHMENT_TAG from "react-migration/domains/sites/apollo/li-api/queries/siteAttachmentAntivirusScanStatus.gql";

export enum AntivirusScanStatuses {
  IN_PROGRESS = "IN PROGRESS",
  CLEAN = "CLEAN",
  INFECTED = "INFECTED",
  ERROR = "ERROR",
}

type siteAttachmentAntivirusScanStatusResponse = {
  siteAttachmentAntivirusScanStatus: {
    antivirusScanStatus: AntivirusScanStatuses;
  };
};

/**
 * Apollo hook which polls for the antivirus scan status of a site attachment at a specified interval
 */
export function usePollingSiteAttachmentAntivirusScanStatus(
  siteId: string | null,
  fileName: string,
  pollIntervalMs: number
) {
  if (!siteId) {
    throw new Error("Missing siteId!");
  }

  if (!fileName) {
    throw new Error("Missing fileName!");
  }

  const { loading, data } = useQuery<siteAttachmentAntivirusScanStatusResponse>(ATTACHMENT_TAG, {
    variables: { site_id: siteId, file_name: fileName },
    client: routedClient,
    pollInterval: pollIntervalMs,
  });

  const antivirusScanStatus = data?.siteAttachmentAntivirusScanStatus?.antivirusScanStatus;

  return {
    loading,
    antivirusScanStatus,
  };
}
