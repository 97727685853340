import { useMapLayer } from "react-migration/lib/map/useMapLayer";

import { LayerTypeMapLayerProps } from "../../types";
import { usePlanningAuthorityLayerTypeContext } from "./PlanningAuthorityContext";
import { PlanningAuthorityLayer } from "./PlanningAuthorityLayer";
import { useLayerStore } from "../ConstraintsLayerType/ConstraintsContext/store";
import { ConstraintsCategory } from "react-migration/layouts/map/Constraints/types";
import { buildConstraintsTileUrl } from "react-migration/domains/constraints/components/ConstraintLayer/ConstraintLayer";

export function PlanningAuthorityMapLayer({ zOrder, visible }: LayerTypeMapLayerProps) {
  const {
    presumptionInFavourFilter,
    localPlanAgeFilter,
    housingLandSupplyFilter,
    housingDeliveryTestFilter,
    dispatch,
    constraintsStore,
  } = usePlanningAuthorityLayerTypeContext();

  const [{ visibleCategories }] = useLayerStore(ConstraintsCategory.LOCAL_PLANNING_AUTHORITY, {
    dispatch,
    constraintsStore,
  });

  const mvtUrl = buildConstraintsTileUrl({
    rootCategory: ConstraintsCategory.LOCAL_PLANNING_AUTHORITY,
    designationAttributes: ["pif", "5yhs_met", "5yhs", "hdt21_meas", "adpt_dat"],
  });

  useMapLayer(
    () => ({
      zOrder,
      layer: new PlanningAuthorityLayer({
        id: "lpaLayer",
        data: mvtUrl,
        presumptionInFavourFilter,
        localPlanAgeFilter,
        housingLandSupplyFilter,
        housingDeliveryTestFilter,
        visible,
        visibleCategories,
      }),
    }),
    [
      mvtUrl,
      presumptionInFavourFilter,
      localPlanAgeFilter,
      housingLandSupplyFilter,
      housingDeliveryTestFilter,
      zOrder,
      visible,
      visibleCategories,
    ]
  );

  return <></>;
}
