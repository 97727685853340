import Vue from "vue";
import VueI18n from "vue-i18n";
import { defaultLanguage, languages as messages } from "./locales";

// We need to use the VueI18n package before initialising it.
Vue.use(VueI18n);

// set the local to the store so that react also updates the language

export const i18n = new VueI18n({
  locale: defaultLanguage,
  fallbackLocale: defaultLanguage,
  messages,
});
