import { uniqBy } from "lodash";
import { useMemo } from "react";
import { Title } from "react-migration/domains/ownership/typings/Title";
import {
  OwnershipIntersectableGeometry,
  useTitlesByLocationQuery,
} from "react-migration/domains/ownership/apollo/useTitlesByLocationQuery";
import { Nullable } from "src/js/types/Nullable";
import { isDefined } from "react-migration/lib/util/isDefined";
import { canonicalizeOwnerNames } from "../OwnershipSelectionDetailView/canonicalizeOwnerName";

export type TitleWithOwnerNames = Title & { ownerNames: string[] };

export function enrichTitleWithOwnerNames(title: Title): TitleWithOwnerNames {
  return { ...title, ownerNames: canonicalizeOwnerNames(title.owners) };
}
export function useTitlesWithOwnerNamesQuery(location?: Nullable<OwnershipIntersectableGeometry>) {
  const { titlesByLocation, loading } = useTitlesByLocationQuery(location);

  const titlesWithOwnerNames = useMemo(
    () =>
      uniqBy([...titlesByLocation], (title) => title?.title_no)
        .filter(isDefined)
        .map(enrichTitleWithOwnerNames),
    [titlesByLocation]
  );

  return {
    titlesWithOwnerNames,
    loading,
  };
}
