import { useQuery } from "@apollo/client";
import GET_SITES_WITH_SUBSCRIPTIONS from "../apollo/queries/sitesWithSubscriptions.gql";
import { routedClient } from "react-migration/lib/persistence/apollo/routedClient";
import { SitesWithAlertsSubscriptionResponse } from "../../typings/SitesWithAlertsSubscriptionResponse";

type UseSitesWithSubscriptionsQueryParams = {
  hasSites?: boolean;
  withPlanningAlertSubscriptionsOnly: boolean;
  alertsEmailedAt: string | null;
  cursor: string | null;
  limit: number;
};

export const useSitesWithSubscriptionsQuery = (args: UseSitesWithSubscriptionsQueryParams) => {
  return useQuery<SitesWithAlertsSubscriptionResponse, UseSitesWithSubscriptionsQueryParams>(
    GET_SITES_WITH_SUBSCRIPTIONS,
    {
      client: routedClient,
      variables: args,
      fetchPolicy: "network-only",
      notifyOnNetworkStatusChange: true,
    }
  );
};
