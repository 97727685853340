import { useMemo } from "react";
import { SkeletonLoading, useVariants } from "@landtechnologies/components";
import { useTranslation } from "react-migration/lib/i18n/useTranslation";
import { Theme, Tooltip } from "@landtechnologies/components";
import { RelatedOwners } from "./RelatedOwners";
import {
  TitleWithOwnerNames,
  useTitlesWithOwnerNamesQuery,
} from "../../hooks/useTitlesWithOwnerNamesQuery";
import { formatAddress } from "react-migration/domains/ownership/util/Formatters";
import { OwnershipIntersectableGeometry } from "react-migration/domains/ownership/apollo/useTitlesByLocationQuery";
import { Title } from "react-migration/domains/sites/typings/Title";
import { getGeometryMeasurements } from "react-migration/lib/util/getAreaAndPerimeter";
import { useSnapshot } from "valtio";
import { userStore } from "src/js/stores/user/store";
import { DataPanel } from "react-migration/components/DataPanel";
import { Divider } from "react-migration/components/Divider";
import cn from "clsx";

type SummaryProps = {
  setSelectedOwner(ownerName?: string): void;
  geometry: OwnershipIntersectableGeometry;
  selectedTitle: TitleWithOwnerNames;
};

export const Summary = ({ setSelectedOwner, geometry, selectedTitle }: SummaryProps) => {
  const { user } = useSnapshot(userStore);
  const { t } = useTranslation();

  const measurements = useMemo(() => {
    return geometry && getGeometryMeasurements(geometry, user.settings.unit);
  }, [geometry, user.settings.unit]);

  const [areaVariant, toggleAreaVariant] = useVariants(measurements?.areaVariants);
  const [perimeterVariant, togglePerimeterVariant] = useVariants(measurements?.perimeterVariants);

  const selectedTitleAddresses = selectedTitle.addresses?.map(formatAddress);

  return (
    <div className="atlas-bg-white atlas-flex atlas-flex-col atlas-gap-4">
      <div className="atlas-flex atlas-flex-col atlas-gap-3 atlas-px-2 atlas-pt-3">
        <div className="atlas-flex atlas-flex-col atlas-gap-2">
          <DataPanel.Grid>
            <DataPanel.Cell title={t("sidebar.owner.title")}>
              <span>{selectedTitle.title_no}</span>
            </DataPanel.Cell>
            <DataPanel.Cell title={t("sidebar.tenure.label")}>
              <Tenure tenure={selectedTitle.tenure} />
            </DataPanel.Cell>
            <DataPanel.Cell title={t("components.measure2D.area")}>
              <DataPanel.Button onClick={toggleAreaVariant}>{areaVariant}</DataPanel.Button>
            </DataPanel.Cell>
            <DataPanel.Cell title={t("components.measure2D.perimeter")}>
              <DataPanel.Button onClick={togglePerimeterVariant}>
                {perimeterVariant}
              </DataPanel.Button>
            </DataPanel.Cell>
          </DataPanel.Grid>
          <DataPanel.Grid columns={1}>
            <Owners owners={selectedTitle.ownerNames} setSelectedOwner={setSelectedOwner} />
          </DataPanel.Grid>
          <DataPanel.Grid columns={1}>
            <Address addresses={selectedTitleAddresses} />
          </DataPanel.Grid>
        </div>
      </div>
      <div className="atlas-px-2">
        <Divider />
      </div>
      <AllIntersectingOwnersSection
        setSelectedOwner={setSelectedOwner}
        selectedTitle={selectedTitle}
        geometry={geometry}
      />
    </div>
  );
};

type AllIntersectingOwnersSectionProps = Pick<
  SummaryProps,
  "setSelectedOwner" | "selectedTitle" | "geometry"
>;

function AllIntersectingOwnersSection({
  setSelectedOwner,
  selectedTitle,
  geometry,
}: AllIntersectingOwnersSectionProps) {
  const { titlesWithOwnerNames, loading } = useTitlesWithOwnerNamesQuery(geometry);

  if (!loading && !titlesWithOwnerNames?.length) return null;

  return (
    <div className="atlas-flex atlas-flex-col">
      <div className="atlas-flex atlas-gap-1 atlas-items-center atlas-px-2">
        <span className="atlas-text-xs atlas-text-blueGrey-950/50 atlas-m-0 atlas-block atlas-break-words">
          All intersecting owners
        </span>
      </div>

      {loading ? (
        <div
          className="atlas-relative atlas-h-[54px] atlas-m-2"
          data-testid="ownership-related-owners-header-loading"
        >
          <SkeletonLoading rows={1} showPadding={false} />
        </div>
      ) : (
        <RelatedOwners
          titlesWithOwnerNames={titlesWithOwnerNames}
          selectedTitleNo={selectedTitle.title_no}
          setSelectedOwner={setSelectedOwner}
        />
      )}
    </div>
  );
}

interface OwnersProps {
  owners?: string[];
  setSelectedOwner(ownerName: string): void;
}

function Owners({ owners, setSelectedOwner }: OwnersProps) {
  const { t } = useTranslation();

  if (!owners?.length)
    return (
      <DataPanel.Cell title={t("sidebar.owner.label")} variant="ghost">
        UNKNOWN
      </DataPanel.Cell>
    );

  const isSingleOwner = owners.length === 1;

  const ownersCopyString = owners.map((owner) => `${owner}\n`).join("");

  return (
    <DataPanel.Cell
      title={t("sidebar.owner.label")}
      titleTail={
        !isSingleOwner ? <DataPanel.Tails.Badge>{owners.length}</DataPanel.Tails.Badge> : undefined
      }
      variant="ghost"
      copyString={ownersCopyString}
    >
      <div className="atlas-flex atlas-flex-col">
        {owners.map((owner) => (
          <Tooltip.Wrapper key={owner} ignoreClick>
            <Tooltip.Trigger data-testid="tooltip-trigger" asChild>
              <DataPanel.Button onClick={() => setSelectedOwner(owner)}>
                <span
                  className={cn({
                    "atlas-line-clamp-2": isSingleOwner,
                    "atlas-line-clamp-1": !isSingleOwner,
                  })}
                >
                  {owner}
                </span>
              </DataPanel.Button>
            </Tooltip.Trigger>
            <Tooltip.Content
              sideOffset={0}
              showArrow={true}
              theme={Theme.Dark}
              align="start"
              side="bottom"
            >
              <div>{owner}</div>
            </Tooltip.Content>
          </Tooltip.Wrapper>
        ))}
      </div>
    </DataPanel.Cell>
  );
}

interface AddressProps {
  addresses?: string[];
}

function Address({ addresses }: AddressProps) {
  if (!addresses?.length)
    return (
      <DataPanel.Cell title="Address" variant="ghost">
        UNKNOWN
      </DataPanel.Cell>
    );

  const address = addresses.length === 1 ? addresses[0] : addresses.join(", ");

  return (
    <DataPanel.Cell title="Address" variant="ghost" copyString={address}>
      <Tooltip.Wrapper ignoreClick>
        <Tooltip.Trigger data-testid="tooltip-trigger" asChild>
          <div className="atlas-cursor-pointer atlas-line-clamp-2 atlas-float-left">{address}</div>
        </Tooltip.Trigger>

        <Tooltip.Content
          sideOffset={0}
          showArrow={true}
          theme={Theme.Dark}
          align="start"
          side="bottom"
        >
          <div className="atlas-flex atlas-flex-col atlas-gap-1">
            {addresses.map((address) => (
              <div key={address}>{address}</div>
            ))}
          </div>
        </Tooltip.Content>
      </Tooltip.Wrapper>
    </DataPanel.Cell>
  );
}

interface TenureProps {
  tenure?: Title["tenure"];
}

function Tenure({ tenure }: TenureProps) {
  const { t, checkKey } = useTranslation();

  const value =
    tenure && checkKey(`sidebar.tenure.label.${tenure.toLocaleLowerCase()}`)
      ? t(`sidebar.tenure.label.${tenure.toLocaleLowerCase()}`)
      : t(`sidebar.tenure.label.unknown`);

  return value;
}
