import { scaleSequential } from "d3-scale";
import * as scaleChromatic from "d3-scale-chromatic";
import { color } from "d3-color";
import { Color } from "deck.gl";
import { TRANSPARENT } from "react-migration/domains/constraints/designation/style/colours";
import { formatToCurrency } from "src/js/util/currency";
import { ConstraintsCategory } from "react-migration/layouts/map/Constraints/types";

const formatPercentageNormal = (val: number) => `${val * 100}%`;
const formatPercentage = (val: number) => `${val}%`;
const formatToUsd = (val: number) => formatToCurrency(val, "USD");

export type DemographicsStyleOverride = {
  fillColor: (value: number) => Color;
  lineColor: () => Color;
  labelFormatter?: (val: number) => string | undefined;
  ticks?: string[];
};

function createDeckGlInterpolator(colourInterpolator: (v: number) => string) {
  return (value: number) => {
    const rgbStr = colourInterpolator(value);
    const rgb = color(rgbStr)?.rgb();
    return [rgb?.r, rgb?.g, rgb?.b, 255 * 0.6] as Color;
  };
}

const interpolateBrBGDeckGl = createDeckGlInterpolator(scaleChromatic.interpolateBrBG);
const interpolateRdYlGnDeckGl = createDeckGlInterpolator(scaleChromatic.interpolateRdYlGn);
const interpolateGreensDeckGl = createDeckGlInterpolator(scaleChromatic.interpolateGreens);
const interpolateBluesDeckGl = createDeckGlInterpolator(scaleChromatic.interpolateBlues);

export type DemographicsStyles =
  | "decile_diverging_red_yellow_green"
  | "decile_sequential_green"
  | "decile_sequential_blue"
  | "default_percentage_0_to_10"
  | "default_percentage_normal_0_to_25"
  | "median_age"
  | UKDemographicsStyles
  | USDemographicsStyles;

type USDemographicsStyles =
  | "output_areas"
  | "population_density_per_sq_mile"
  | "median_household_income";

const US_DEMOGRAPHIC_STYLE_MAP: Partial<Record<USDemographicsStyles, DemographicsStyleOverride>> = {
  population_density_per_sq_mile: {
    fillColor: scaleSequential(interpolateBrBGDeckGl)
      .domain([100, 30_000])
      .clamp(true)
      .unknown(TRANSPARENT),
    lineColor: () => TRANSPARENT,
    labelFormatter: (val: number) => `${val}/sq. mi`,
  },
  median_household_income: {
    fillColor: scaleSequential(interpolateBrBGDeckGl)
      .domain([0, 250_000])
      .clamp(true)
      .unknown(TRANSPARENT),
    lineColor: () => TRANSPARENT,
    labelFormatter: formatToUsd,
  },
};

type UKDemographicsStyles = "deprivation_index" | "affordability_ratio";

const UK_DEMOGRAPHIC_STYLE_MAP: Partial<Record<UKDemographicsStyles, DemographicsStyleOverride>> = {
  deprivation_index: {
    fillColor: scaleSequential(interpolateRdYlGnDeckGl)
      .domain([1, 10])
      .clamp(true)
      .unknown(TRANSPARENT),
    lineColor: () => TRANSPARENT,
    ticks: ["More deprived", "Less deprived"],
  },
  affordability_ratio: {
    fillColor: scaleSequential(interpolateRdYlGnDeckGl)
      .domain([10, 1])
      .clamp(true)
      .unknown(TRANSPARENT),
    lineColor: () => TRANSPARENT,
    ticks: ["Less affordable", "More affordable"],
  },
};

export const DEMOGRAPHIC_STYLE_MAP: Partial<Record<DemographicsStyles, DemographicsStyleOverride>> =
  {
    median_age: {
      fillColor: scaleSequential(interpolateBrBGDeckGl)
        .domain([20, 60])
        .clamp(true)
        .unknown(TRANSPARENT),
      lineColor: () => TRANSPARENT,
    },
    default_percentage_0_to_10: {
      fillColor: scaleSequential(interpolateBrBGDeckGl)
        .domain([0, 10])
        .clamp(true)
        .unknown(TRANSPARENT),
      lineColor: () => TRANSPARENT,
      labelFormatter: formatPercentage,
    },
    default_percentage_normal_0_to_25: {
      fillColor: scaleSequential(interpolateBluesDeckGl)
        .domain([0, 0.25])
        .clamp(true)
        .unknown(TRANSPARENT),
      lineColor: () => TRANSPARENT,
      labelFormatter: formatPercentageNormal,
    },
    decile_diverging_red_yellow_green: {
      fillColor: scaleSequential(interpolateRdYlGnDeckGl)
        .domain([1, 10])
        .clamp(true)
        .unknown(TRANSPARENT),
      lineColor: () => TRANSPARENT,
      ticks: ["Lower", "Higher"],
    },
    decile_sequential_green: {
      fillColor: scaleSequential(interpolateGreensDeckGl)
        .domain([1, 10])
        .clamp(true)
        .unknown(TRANSPARENT),
      lineColor: () => TRANSPARENT,
      ticks: ["Lower", "Higher"],
    },
    decile_sequential_blue: {
      fillColor: scaleSequential(interpolateBluesDeckGl)
        .domain([1, 10])
        .clamp(true)
        .unknown(TRANSPARENT),
      lineColor: () => TRANSPARENT,
      ticks: ["Lower", "Higher"],
    },
    ...US_DEMOGRAPHIC_STYLE_MAP,
    ...UK_DEMOGRAPHIC_STYLE_MAP,
  };

export const US_DEMOGRAPHICS_CATEGORIES = [
  ConstraintsCategory.US_STATE_DEMOGRAPHICS,
  ConstraintsCategory.US_TRACT_DEMOGRAPHICS,
  ConstraintsCategory.US_COUNTY_DEMOGRAPHICS,
];

export const UK_DEMOGRAPHICS_CATEGORIES = [
  ConstraintsCategory.UK_DEMOGRAPHICS_COUNTRY,
  ConstraintsCategory.UK_DEMOGRAPHICS_REGION,
  ConstraintsCategory.UK_DEMOGRAPHICS_LAD,
  ConstraintsCategory.UK_DEMOGRAPHICS_MSOA,
  ConstraintsCategory.UK_DEMOGRAPHICS_LSOA,
];
