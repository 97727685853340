import { Route, RouteConfig } from "vue-router";

import Letters from "../../view/sites/letters.vue";
import ROUTES from "../routes";
import SiteReportRouterWrapper from "../../view/sites/SiteReportRouterWrapper.vue";
import SiteReportRouterWrapperV2 from "../../view/sites/SiteReportRouterWrapperV2.vue";
import SiteUtilityReportDownload from "../../view/sites/SiteUtilityReportDownload.vue";
import SkipTrace from "../../view/sites/skipTrace.vue";

const generateViewChangeRedirect = (route: Route) => {
  if (route.query.site_id && route.query.title_no) {
    return {
      name: ROUTES.SITES,
      query: {
        card_page: "ownership_layer",
        site_id: route.query.site_id,
        ownership_search: JSON.stringify({
          composite_terms: {
            and: [
              {
                or: [
                  {
                    search_group_details: {
                      id: route.query.title_no,
                      name: route.query.title_no,
                      isAggregate: true,
                      orSet: "Title",
                    },
                    or: [
                      {
                        value: route.query.title_no,
                        field: "title_no",
                        matchType: "match_phrase",
                      },
                    ],
                  },
                ],
              },
            ],
          },
        }),
      },
    };
  } else {
    return {
      name: ROUTES.MAP,
    };
  }
};

export const SitesRoutes: Array<RouteConfig> = [
  {
    path: "/sites",
    component: () =>
      import(
        /* webpackChunkName: 'sites' */
        /* webpackPrefetch: true */
        "../../view/sites/sites.vue"
      ),
    name: ROUTES.SITES,
  },
  {
    path: "/sites/view-change",
    redirect: generateViewChangeRedirect,
  },
  {
    path: "/sites/letters/create",
    component: Letters,
    name: ROUTES.LETTERS,
  },
  {
    path: "/utility/report/download",
    component: SiteUtilityReportDownload,
    name: ROUTES.SITE_UTILITY_REPORT,
  },
  {
    path: "/sites/letters/batches",
    component: () =>
      import(
        /* webpackChunkName: 'sites' */
        /* webpackPrefetch: true */
        "../../view/sites/sites.vue"
      ),
    name: ROUTES.LETTERS_BATCHES,
  },
  {
    path: "/sites/skip-tracing/create",
    component: SkipTrace,
    name: ROUTES.SKIP_TRACING_CREATE,
  },
  {
    path: "/sites/skip-tracing/:batchId/details",
    component: () => import("../../view/sites/sites.vue"),
    name: ROUTES.SKIP_TRACING_DETAILS,
  },
  {
    path: "/sites/skip-tracing",
    component: () =>
      import(
        /* webpackChunkName: 'sites' */
        /* webpackPrefetch: true */
        "../../view/sites/sites.vue"
      ),
    name: ROUTES.SKIP_TRACING,
  },
  {
    path: "/sites/contacts",
    component: () =>
      import(
        /* webpackChunkName: 'sites' */
        /* webpackPrefetch: true */
        "../../view/sites/sites.vue"
      ),
    name: ROUTES.CONTACTS,
  },
  {
    path: "/sites/site-report",
    component: SiteReportRouterWrapper,
    name: ROUTES.SITE_REPORT,
  },
  {
    path: "/sites/site-report-v2",
    component: SiteReportRouterWrapperV2,
    name: ROUTES.SITE_REPORT_V2,
  },
];
