import { Point } from "@turf/helpers";
import { DetailsList } from "react-migration/components/DetailsList";
import { Nullable } from "src/js/types/Nullable";
import { useTranslation } from "react-migration/lib/i18n/useTranslation";

type PointMeasurementsProps = {
  point?: Nullable<Point>;
};

export function PointMeasurements({ point }: Readonly<PointMeasurementsProps>) {
  const { t } = useTranslation();

  if (!point?.coordinates) return null;

  const [lng, lat] = point.coordinates;

  return (
    <DetailsList>
      <DetailsList.Item label={t("components.measure2D.latitude")}>
        <span className="atlas-font-mono">{lat.toFixed(8)}</span>
      </DetailsList.Item>
      <DetailsList.Item label={t("components.measure2D.longitude")}>
        <span className="atlas-font-mono">{lng.toFixed(8)}</span>
      </DetailsList.Item>
    </DetailsList>
  );
}
