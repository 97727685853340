import { useQuery } from "@apollo/client";
import { Nullable } from "src/js/types/Nullable";
import { routedClient } from "react-migration/lib/persistence/apollo";
import { useFeatureFlag } from "react-migration/lib/user/useFeatureFlag";
import { isDefined } from "react-migration/lib/util/isDefined";
import Feature from "src/js/stores/user/Feature";
import companyUltimateOwners from "./companyUltimateOwners.gql";

interface Owner {
  company_no: string;
  name: string;
  addresses: string[];
  is_foreign: boolean;
}

type OwningCompany = Nullable<Pick<Owner, "name" | "addresses" | "is_foreign">>;

export interface TitlesByLocationQueryData {
  companyUltimateOwners: Owner & {
    ultimate_owners: {
      parent_company: Owner & {
        parents: {
          parent_person: { name: string };
          parent_company: OwningCompany;
        }[];
      };
    }[];
  };
}

interface UltimateOwnerReturnType {
  company?: OwningCompany;
  ultimateOwner?: OwningCompany;
  loading: boolean;
}

export interface TitlesByLocationQueryVariables {
  company_no: string;
}

/**
 * Takes a company no and returns the ultimate owning company
 * @param company_no: string
 * @returns QueryResult
 */
export function useCompanyUltimateOwnersQuery(company_no?: string): UltimateOwnerReturnType {
  const hasUltimateOwner = useFeatureFlag(Feature.ultimateOwner);
  const { data, loading } = useQuery<TitlesByLocationQueryData, TitlesByLocationQueryVariables>(
    companyUltimateOwners,
    {
      variables: { company_no: company_no as string },
      skip: !company_no || !hasUltimateOwner,
      client: routedClient,
    }
  );

  const company = data?.companyUltimateOwners;
  const owners = company ? company.ultimate_owners : [];
  const parent = owners.find((o) => o.parent_company.company_no !== company_no)?.parent_company;
  let ultimateOwner: OwningCompany | undefined;

  if (parent) {
    const owningPerson = parent.parents.find((x) => x.parent_person)?.parent_person;
    const ownerName = owningPerson?.name;

    ultimateOwner = {
      ...parent,
      // Append the owner name to the address
      addresses: parent.addresses.map((a) => [ownerName, a].filter(isDefined).join(", ")),
    };
  }

  return { company, ultimateOwner, loading };
}
