import { useEffect, useRef, useState } from "react";
import * as React from "react";
import classNames from "classnames";
import hasFeature from "src/js/stores/user/actions/hasFeature";
import Feature from "src/js/stores/user/Feature";
import { useTranslation } from "react-migration/lib/i18n/useTranslation";
import { useSnapshot } from "valtio";
import { letterStore } from "src/js/stores/letter/store";
import { useActiveSiteBoundaryImageUrl } from "react-migration/domains/sites/hooks/useActiveSiteBoundaryImageUrl";
import { Placeholder } from "./LetterParts/Placeholder";
import { SecondPageContent } from "./LetterParts/SecondPageContent";
import { Signature } from "./LetterParts/Signature";
import { Footer } from "./LetterParts/Footer";

interface PageProps {
  onHeightChange?: (isLong: boolean) => void;
  showValues?: boolean;
}

const MAX_HEIGHT = 1123;

export const SecondPage: React.FC<PageProps> = ({ onHeightChange, showValues }) => {
  const { t } = useTranslation();
  const usAccess = hasFeature(Feature.usAccess);
  const {
    letter: { bodyTextPage2, signaturePage, showSiteBoundary },
  } = useSnapshot(letterStore);
  const [height, setHeight] = useState<number>();
  const letterPage = useRef<HTMLDivElement>(null);
  const { siteBoundaryImageUrl } = useActiveSiteBoundaryImageUrl();

  useEffect(() => {
    const newHeight = letterPage.current?.clientHeight;
    if (newHeight && newHeight !== height) {
      setHeight(newHeight);
      onHeightChange?.(newHeight > MAX_HEIGHT);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bodyTextPage2, signaturePage, showSiteBoundary, height]);

  const satelliteImageClassNames = [
    "atlas-flex",
    "atlas-flex-col",
    "atlas-items-center",
    "atlas-justify-center",
    "atlas-h-[330px]",
    "atlas-w-[420px]",
    "atlas-m-auto",
  ];

  return (
    <div
      className={classNames(
        "atlas-mt-8",
        "atlas-bg-white",
        "atlas-mx-auto",
        "atlas-drop-shadow-md",
        "atlas-font-['Arial']",
        "atlas-text-[11pt]",
        "atlas-leading-[15pt]",
        usAccess ? "atlas-p-[0.68in]" : "atlas-p-[20mm]",
        !usAccess && "atlas-pl-[22mm]",
        usAccess ? "atlas-min-h-letter" : "atlas-min-h-a4",
        usAccess ? "atlas-w-letter" : "atlas-w-a4"
      )}
      data-testid="letter-preview-second-page"
      ref={letterPage}
    >
      {showSiteBoundary && (
        <>
          {showValues && siteBoundaryImageUrl ? (
            <div className={classNames(satelliteImageClassNames)}>
              <img
                className={classNames("atlas-h-auto", "atlas-w-full", "atlas-object-contain")}
                src={siteBoundaryImageUrl}
              />
            </div>
          ) : (
            <Placeholder className={classNames(satelliteImageClassNames)}>
              <div
                className={classNames(
                  "atlas-flex",
                  "atlas-flex-col",
                  "atlas-items-center",
                  "atlas-justify-center"
                )}
              >
                <i className="icon-lt-image-line atlas-text-3xl atlas-text-neutral-400" />
                {t("sites.letters.satellite_image")}
              </div>
            </Placeholder>
          )}
        </>
      )}
      {bodyTextPage2 && <SecondPageContent />}
      {signaturePage === 2 && <Signature showValues={showValues} />}
      <Footer />
    </div>
  );
};
