import { noop } from "lodash";
import { getMemoryUsage } from "./getMemoryUsage";
import { ENVIRONMENT } from "./environment";

// 10 mins in milliseconds
const MEMORY_PUSH_FREQUECY = 600000;

let t: ReturnType<typeof setTimeout>;

export function trackMemoryUsage() {
  clearTimeout(t);
  const memory = getMemoryUsage();
  if (!memory) {
    return;
  }

  import("@datadog/browser-rum")
    .then(({ datadogRum }) => {
      const stats = {
        totalJSHeapSize: memory.totalJSHeapSize,
        usedJSHeapSize: memory.usedJSHeapSize,
        jsHeapSizeLimit: memory.jsHeapSizeLimit,
        percentageUsed: Number(((memory.usedJSHeapSize / memory.jsHeapSizeLimit) * 100).toFixed(2)),
      };
      if (ENVIRONMENT.APP_ENV === "local") {
        console.log(stats);
      }
      datadogRum.addAction("performance.memory", stats);

      t = setTimeout(trackMemoryUsage, MEMORY_PUSH_FREQUECY);
    })
    .catch(() => {
      t = setTimeout(noop, 15);
    });
}

export function initialiseMemoryUsageTracking() {
  if (!t) {
    trackMemoryUsage();
  }
}
