import { useId } from "react";
import { GeoJsonLayer, Color } from "deck.gl";
import { useMapLayer } from "react-migration/lib/map/useMapLayer";

import { noop } from "lodash";
import { equals } from "ramda";
import { Feature, Geometry } from "geojson";

type State = "idle" | "hovered";

interface Style {
  lineColor: Color;
  fillColor: Color;
}

export type StyleMap = Record<State, Style>;

// eslint-disable-next-line @typescript-eslint/ban-types
interface UseHoverableSelectionLayerProps<F extends {}, I, H> {
  features?: Feature<Geometry, F>[] | Feature<Geometry, F>;
  hoveredId: H;
  setHoveredId?(id: I): void;
  pickable: boolean;
  styleMap: StyleMap;
  idAccessor(f: GeoJSON.Feature<GeoJSON.Geometry, F>): I;
  isHovered?(featureId: I, hoveredId: H): boolean;
}

// eslint-disable-next-line @typescript-eslint/ban-types
export function useHoverableSelectionLayer<F extends {}, I = string | undefined, H = I>({
  features,
  hoveredId,
  pickable,
  styleMap,
  idAccessor,
  setHoveredId = noop,
  isHovered = equals,
}: UseHoverableSelectionLayerProps<F, I, H>) {
  const id = useId();

  useMapLayer(
    () => ({
      layer: new GeoJsonLayer<F>({
        data: features,
        id: `hoverable-layer-selection-${id}`,
        getLineColor: (feature) =>
          feature && isHovered(idAccessor(feature), hoveredId)
            ? styleMap["hovered"].lineColor
            : styleMap["idle"].lineColor,
        filled: true,
        stroked: true,
        getLineWidth: 3,
        getFillColor: (feature) =>
          feature && isHovered(idAccessor(feature), hoveredId)
            ? styleMap["hovered"].fillColor
            : styleMap["idle"].fillColor,
        lineWidthUnits: "pixels",
        pickable,
        updateTriggers: { getFillColor: [hoveredId], getLineColor: [hoveredId] },
        onHover: ({ object }) => setHoveredId(object && idAccessor(object)),
      }),
      // This is set to Infinity to ensure that the HoverableSelectionLayer is
      // rendered on-top of all over layers.
      zOrder: Infinity,
    }),
    [features, id, pickable, hoveredId, isHovered, idAccessor, styleMap, setHoveredId]
  );
}
