import { ENVIRONMENT } from "src/js/util/environment";
import { Nullable } from "src/js/types/Nullable";

import useSWR from "swr";

import { SelectionGeometry } from "src/js/stores/map/store";
import { negativeBuffer } from "react-migration/layouts/map/Multilayer/geometry_utilities";

import { PlanningApplicationsSummary } from "react-migration/domains/planning/types";

type PlanningApplicationFetcherArgs = [string, Nullable<SelectionGeometry>];
type PlanningApplicationSummaryFetcherResponse = Promise<PlanningApplicationsSummary>;

async function summaryFetcher([
  url,
  geometry,
]: PlanningApplicationFetcherArgs): PlanningApplicationSummaryFetcherResponse {
  const response = await fetch(url, {
    method: "POST",
    body: JSON.stringify({ geometry: negativeBuffer(geometry) }),
    headers: {
      "Content-Type": "application/json",
    },
    mode: "cors",
  });
  return await response.json();
}

export function usePlanningApplicationsSummaryByGeometry(
  geometry: Nullable<SelectionGeometry>,
  disableRevalidate = false
) {
  const { data, error, isLoading } = useSWR(
    [`${ENVIRONMENT.PLANNING_SERVICE_URL}/planning-applications/within/geometry/summary`, geometry],
    summaryFetcher,
    disableRevalidate
      ? { revalidateIfStale: false, revalidateOnFocus: false, revalidateOnReconnect: false }
      : {}
  );

  return { data: data, loading: isLoading, error: error ?? null };
}
