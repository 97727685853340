import { CardNumberElement, CardCvcElement, CardExpiryElement } from "@stripe/react-stripe-js";
import { useState, useEffect } from "react";
import { BrandComponent } from "./BrandComponent";
import { ErrorIcon } from "../components/cardBrandIcons/ErrorIcon";
import { StripeInputWrapper } from "./StripeInputWrapper";
import {
  StripeCardNumberElementChangeEvent,
  StripeCardCvcElementChangeEvent,
  StripeCardExpiryElementChangeEvent,
} from "@stripe/stripe-js/types/stripe-js";
import { useTranslation } from "react-migration/lib/i18n/useTranslation";

type Brand = StripeCardNumberElementChangeEvent["brand"];
type StripeFormProps = {
  setIsStripeFormValid: React.Dispatch<React.SetStateAction<boolean>>;
};

export const StripeForm = ({ setIsStripeFormValid }: StripeFormProps) => {
  const { t } = useTranslation();
  const [brand, setBrand] = useState<Brand>("unknown");
  const [cardNumberError, setCardNumberError] = useState<string>("");
  const [cardCvcError, setCardCvcError] = useState<string>("");
  const [cardExpiryError, setCardExpiryError] = useState<string>("");

  const [isCardNumberComplete, setIsCardNumberComplete] = useState(false);
  const [isCVCComplete, setIsCVCComplete] = useState(false);
  const [isCardExpiryComplete, setIsCardNExpiryComplete] = useState(false);

  useEffect(() => {
    setIsStripeFormValid(isCardNumberComplete && isCVCComplete && isCardExpiryComplete);
  }, [setIsStripeFormValid, isCardExpiryComplete, isCVCComplete, isCardNumberComplete]);

  const handleCardValueChange = (e: StripeCardNumberElementChangeEvent) => {
    setBrand(e.brand);
    setCardNumberError(e.error?.message || "");
    setIsCardNumberComplete(e.complete);
  };

  const handleCardCVCChange = (e: StripeCardCvcElementChangeEvent) => {
    setCardCvcError(e.error?.message || "");
    setIsCVCComplete(e.complete);
  };

  const handleCardExpiryChange = (e: StripeCardExpiryElementChangeEvent) => {
    setCardExpiryError(e.error?.message || "");
    setIsCardNExpiryComplete(e.complete);
  };

  return (
    <>
      <StripeInputWrapper
        labelText={t("dashboard.topup.card_number")}
        icon={
          <span className="atlas-max-h-[16px] atlas-block">
            {cardNumberError ? <ErrorIcon /> : <BrandComponent brand={brand} />}
          </span>
        }
        error={cardNumberError}
      >
        <CardNumberElement
          onChange={handleCardValueChange}
          options={{ placeholder: t("dashboard.topup.card_number") }}
        />
      </StripeInputWrapper>
      <div className="atlas-grid atlas-grid-cols-2 atlas-gap-2">
        <StripeInputWrapper
          labelText={t("dashboard.topup.expiration_date")}
          error={cardExpiryError}
        >
          <CardExpiryElement onChange={handleCardExpiryChange} />
        </StripeInputWrapper>
        <StripeInputWrapper labelText={t("dashboard.topup.security_code")} error={cardCvcError}>
          <CardCvcElement onChange={handleCardCVCChange} />
        </StripeInputWrapper>
      </div>
    </>
  );
};
