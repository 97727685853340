import { ModalV2 } from "react-migration/components/ModalV2/ModalV2";
import { useTranslation } from "react-migration/lib/i18n/useTranslation";
import { Button } from "react-migration/components/Button";

type DecisionModalProps = {
  logout: () => void;
  kick: () => void;
};
export const DecisionModal = ({ logout, kick }: DecisionModalProps) => {
  const { t } = useTranslation();

  return (
    <ModalV2.Root defaultOpen={true}>
      <ModalV2.Body
        title={t("modals.multi_device_block.logged_in_on_multiple_devices")}
        className="!atlas-z-[1000]"
        hideClose={true}
      >
        <div
          className="atlas-text-neutral-800 atlas-text-sm atlas-leading-normal"
          data-testid="decision-modal"
        >
          <p>{t("modals.multi_device_block.logged_in_on_multiple_devices_description")}</p>
          <p>
            {t("modals.multi_device_block.logged_in_on_multiple_devices_info")}{" "}
            <a
              href="https://support.landinsight.io/en/articles/2530601-account-blocked-multiple-users-faqs"
              target="_blank"
              rel="noopener noreferrer"
            >
              {t("modals.multi_device_block.learn_more")}
            </a>
          </p>
        </div>
        <div className="atlas-flex atlas-justify-end atlas-pt-4 atlas-gap-2">
          <Button variant="secondary" onClick={() => logout()}>
            {t("map.drawing.land_assembly.land_assembly_exit_warning.cancel")}
          </Button>
          <Button onClick={() => kick()}>
            {t("map.drawing.land_assembly.land_assembly_exit_warning.continue")}
          </Button>
        </div>
      </ModalV2.Body>
    </ModalV2.Root>
  );
};
