import { ScatterplotLayer as ScatterplotLayerOriginal } from "@deck.gl/layers";
import type { ScatterplotLayerProps } from "@deck.gl/layers";
import type { Color, DefaultProps } from "@deck.gl/core";

export interface GradientScatterplotLayerProps<D> extends ScatterplotLayerProps<D> {
  getPointGradientStop?: ((d: D) => Color) | Color;
}

export const defaultProps = {
  getPointGradientStop: { type: "accessor", value: [0, 0, 0, 0] },
};

export class GradientScatterplotLayer<
  D,
  P extends GradientScatterplotLayerProps<D> = GradientScatterplotLayerProps<D>
> extends ScatterplotLayerOriginal<D, P> {
  static readonly componentName = "LandTechScatterplotLayer";
  static readonly defaultProps = defaultProps as DefaultProps<ScatterplotLayerProps>;

  constructor(props: P, ...additionalProps: P[]) {
    // @ts-expect-error there's some partial vs. required type here that are difficult to reconcile
    super(props, ...additionalProps);
  }

  initializeState() {
    super.initializeState();

    this.getAttributeManager()?.addInstanced({
      instanceFillGradientStop: {
        size: this.props.colorFormat?.length,
        transition: true,
        type: "unorm8",
        accessor: "getPointGradientStop",
        defaultValue: [0, 0, 0, 0],
      },
    });
  }

  getShaders() {
    return {
      ...super.getShaders(),
      inject: {
        "vs:#decl": `
          in vec4 instanceFillGradientStop;
        `,
        "vs:#main-end": `
          if (bool(instanceFillGradientStop.a)) {
            if (geometry.uv.y < 0.0) {
              // bottom vertex
              vFillColor = instanceFillGradientStop;
            }
          }
        `,
        "vs:DECKGL_FILTER_GL_POSITION": `
          // Force z-index, in lieu of a way of doing this with deck.gl's own
          // getPosition accessor. It works fine when using the LAT_LNG
          // coordinate system, but when using CARTESIAN (via an MVTLayer's sub
          // layer) the third coordinate seems to be ignored.
          if(bool(geometry.worldPosition.z)){
            position.z = geometry.worldPosition.z;
          } 
        `,
      },
    };
  }
}
