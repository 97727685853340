import zod from "zod";

import { validatePostcode } from "../../letters/helpers/postcodeValidator";
import { isRegionValid } from "../../letters/helpers/isRegionValid";

export const selectedSingleRecipientValidationSchema = zod.object({
  isSelected: zod.literal(true),
  _id: zod.string(),
  title: zod.string(),
  fullName: zod.string().min(1, "form.error.required"),
  companyOwner: zod.string().optional(),
  addressLine1: zod.string().min(1, "form.error.required"),
  city: zod.string().min(1, "form.error.required"),
  region: zod.string().refine(isRegionValid, "form.error.required"),
  postCode: zod.string().refine(validatePostcode, "form.error.provide_valid_postcode"),
  siteTitle: zod.string().optional(),
  isGranted: zod.boolean(),
});

export const unselectedSingleRecipientValidationSchema = zod.object({
  isSelected: zod.literal(false),
  _id: zod.string(),
  title: zod.string(),
  fullName: zod.string(),
  companyOwner: zod.string().optional(),
  addressLine1: zod.string(),
  city: zod.string(),
  region: zod.string(),
  postCode: zod.string(),
  siteTitle: zod.string().optional(),
  isGranted: zod.boolean(),
});

export const singleRecipientValidationSchema = zod.union([
  selectedSingleRecipientValidationSchema,
  unselectedSingleRecipientValidationSchema,
]);

export type SingleRecipient = zod.infer<typeof singleRecipientValidationSchema>;
