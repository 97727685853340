import { formatToCurrency } from "src/js/util/currency";
import { Purchase, Transaction } from "../../typings/TransactionsResponse";

export const TYPE_STR = {
  TitleRegisterDocument: "Title Register",
  TitlePlanDocument: "Title Plan",
  OSMapPrint: "Map Print",
  LetterSending: "Letter Sending",
  Atkins: "Atkins",
};

export const formatTransactionURL = (purchase: Purchase) => {
  return (
    purchase && (purchase.title_document ? purchase.title_document.url : purchase?.map_print?.url)
  );
};

export const formatPurchaseType = (purchase: Purchase) => {
  return (purchase && TYPE_STR[purchase.type as keyof typeof TYPE_STR]) || "Other";
};

export const formatPurchaseAmount = (transaction: Transaction) => {
  let amount = 0;

  if (transaction?.amountInPence) {
    amount = transaction.amountInPence / 100;
  }
  if (transaction?.purchase?.map_print) {
    amount = -transaction.purchase.amount;
  }

  if (amount > 0) return `+${formatToCurrency(amount, "GBP")}`;
  if (amount < 0) return formatToCurrency(amount, "GBP") || "";
  return "N/A";
};

export const formatDescription = (transaction: Transaction) => {
  const { description, purchaseMethod } = transaction;
  if (!purchaseMethod) {
    return description;
  }
  return `${description} (${purchaseMethod})`;
};
