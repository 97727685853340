import { getSiteGeometry } from "../../../api/siteGeometry";
import { SiteClusterFeature, SiteFeature } from "src/js/api/siteGeometry.types";
import { getMapCenterClamped } from "../../map/util";
import { siteGeometryStore } from "../store";
import { abortSiteGeometryRequest } from "./abortSiteGeometryRequest";

export const refreshSiteGeometryStore = async (googleMap: google.maps.Map) => {
  const mapBounds = googleMap.getBounds();
  const mapCenter = getMapCenterClamped(googleMap);
  const mapZoom = googleMap.getZoom();

  const MINIMUM_MAP_ZOOM = 10;
  const zoomLevelUnderThreshold = (mapZoom ?? 0) < MINIMUM_MAP_ZOOM;

  if (zoomLevelUnderThreshold) {
    siteGeometryStore.siteFeatures = [];
    siteGeometryStore.boundsDistance = 0;
  }

  if (!mapBounds || !mapCenter || zoomLevelUnderThreshold) return;
  const radiusRatio = 0.65;

  const boundsDistance =
    google.maps.geometry.spherical.computeDistanceBetween(
      mapBounds.getSouthWest(),
      mapBounds.getNorthEast()
    ) * radiusRatio;

  const boundsCenter = [mapCenter.lat(), mapCenter.lng()] as [number, number];

  abortSiteGeometryRequest();
  const geoJson = await getSiteGeometry({
    boundsCenter,
    boundsDistance,
  });

  if (!geoJson) return;

  const siteClusterFeatures: SiteClusterFeature[] = [];
  const siteFeatures: SiteFeature[] = [];
  geoJson.features.forEach((feature) => {
    if (feature.properties?.type == "Site") {
      siteFeatures.push(feature as unknown as SiteFeature);
    } else if (feature.properties?.type == "SiteCluster") {
      siteClusterFeatures.push(feature as unknown as SiteClusterFeature);
    }
  });

  siteGeometryStore.clusters = siteClusterFeatures;
  siteGeometryStore.siteFeatures = siteFeatures;
  siteGeometryStore.boundsDistance = boundsDistance;
};
