export const agriculturalLandClassificationOptions = [
  {
    key: "grade_1",
    name: "Grade 1",
  },
  {
    key: "grade_2",
    name: "Grade 2",
  },
  {
    key: "grade_3a",
    name: "Grade 3a",
  },
  {
    key: "grade_3",
    name: "Grade 3",
  },
  {
    key: "grade_3b",
    name: "Grade 3b",
  },
  {
    key: "grade_4",
    name: "Grade 4",
  },
  {
    key: "grade_5",
    name: "Grade 5",
  },
  {
    key: "non_agricultural",
    name: "Non Agricultural",
  },
];
