import { Feature, MultiPolygon, Polygon, feature } from "@turf/helpers";
import union from "@turf/union";
import buffer from "@turf/buffer";
import {
  SelectionFeature,
  SelectionGeometry,
  SelectionIdentifier,
  SelectionType,
} from "src/js/stores/map/store";
import { Nullable } from "src/js/types/Nullable";
import { isDefined } from "react-migration/lib/util/isDefined";
import { isMatchingSelection } from "src/js/stores/map/util";
import { v4 as uuid } from "uuid";

const VALID_GEOMETRY_TYPES = ["Polygon", "MultiPolygon"];

// despite turf's typing, null is a valid value for `feature.geometry` [cit](https://datatracker.ietf.org/doc/html/rfc7946#section-3.2).
const NULL_FEATURE = feature(null as unknown as SelectionGeometry);

function isValidGeometry(
  selection?: Nullable<Feature>
): selection is Feature<Polygon | MultiPolygon> {
  if (!selection?.geometry || !selection?.properties) return false;
  if (!VALID_GEOMETRY_TYPES.includes(selection.geometry.type)) return false;

  return true;
}

const validLandAssemblySelectionTypes = [
  SelectionType.OWNERSHIP_TITLE,
  SelectionType.OWNERSHIP_PARCEL,
  SelectionType.SITE,
  SelectionType.OWNERSHIP_DESIGNATION,
];

export function isValidLandAssemblySelection(
  selection?: Nullable<SelectionFeature>
): selection is SelectionFeature & { id: string; feature: Feature<Polygon | MultiPolygon> } {
  if (!selection?.type || !selection.id) return false;

  return (
    validLandAssemblySelectionTypes.includes(selection.type) && isValidGeometry(selection.feature)
  );
}

export function isLandAssembly(
  selection?: Nullable<SelectionFeature>
): selection is SelectionFeature & {
  feature: Feature<Polygon | MultiPolygon>;
  subselections: Feature[];
} {
  return !!selection?.subselections;
}

export function deleteBySelectionIdentifier(
  selection: SelectionFeature,
  selectionIdentifier: SelectionIdentifier
) {
  return (
    createSelection(
      selection.subselections?.filter((s) => !isMatchingSelection(s, selectionIdentifier)) || []
    ) ?? createEmptyLandAssembly()
  );
}

export function createLandAssembly(
  selection?: SelectionFeature,
  existingSelection?: Nullable<SelectionFeature>
): SelectionFeature | null {
  if (!isValidLandAssemblySelection(selection)) return null;

  const createNewLandAssembly =
    !isLandAssembly(existingSelection) && !isValidLandAssemblySelection(existingSelection);

  if (createNewLandAssembly) {
    return createSelection([selection]);
  }

  const newSubselections = isLandAssembly(existingSelection)
    ? upsertFeature(existingSelection.subselections, selection)
    : upsertFeature([existingSelection], selection);

  return createSelection(newSubselections);
}

export function createEmptyLandAssembly(): SelectionFeature {
  return {
    id: uuid(),
    type: SelectionType.LAND_ASSEMBLY,
    feature: NULL_FEATURE,
    subselections: [],
    mergeSiteOnSave: true,
    hideConsiderations: true,
  };
}

function createSelection(subselections: SelectionFeature[]): SelectionFeature | null {
  if (!subselections.length) return null;

  const subSelectionUnion = union(...subselections.map((s) => s.feature as Feature<Polygon>))
    .geometry!;

  //We do this to ensure that the union geometry doesn't have any self-intersections, which can cause issues when saving the land assembly
  const unkinkedSubSelectionUnion = buffer(subSelectionUnion, 0, { units: "meters" });

  // TODO: types
  return {
    id: uuid(),
    type: SelectionType.LAND_ASSEMBLY,
    feature: unkinkedSubSelectionUnion,
    subselections,
    mergeSiteOnSave: true,
    hideConsiderations: true,
    savable: true,
  };
}

function serialiseSelectionIdentifier(selection: SelectionFeature) {
  return `${selection.type}:${selection.id}`;
}

function upsertFeature(existingSelections: SelectionFeature[], selection: SelectionFeature) {
  const serialisedSelectionIdentifier = serialiseSelectionIdentifier(selection);

  const landAssemblyFeaturesMap = new Map(
    existingSelections.filter(isDefined).map((s) => [serialiseSelectionIdentifier(s), s])
  );

  if (!landAssemblyFeaturesMap.has(serialisedSelectionIdentifier)) {
    landAssemblyFeaturesMap.set(serialisedSelectionIdentifier, selection);
  }

  return [...landAssemblyFeaturesMap.values()];
}
