import { Icon, twAtlasMerge } from "@landtechnologies/components";
import { HatchProps } from "react-migration/components/CheckboxTree";
import type { PatternMapping } from "react-migration/domains/constraints/designation/style/types";
import {
  getCategoryFillColour,
  getCategoryLineColour,
  getCategoryPatternMapping,
  getDesignationIconKeyAccessor,
} from "react-migration/domains/constraints/designation/style/accessors";
import patternConfig from "react-migration/domains/constraints/designation/style/pattern.json";
import patternImg from "react-migration/domains/constraints/designation/style/pattern.png";

type SpriteArrangement = [number, number];

function calculateMaskPosition(
  [spriteIndexX, spriteIndexY]: SpriteArrangement,
  [spritesInRow, spritesInColumn]: SpriteArrangement
) {
  const maskPosX = spritesInRow > 1 ? (spriteIndexX * 100) / (spritesInRow - 1) : 0;
  const maskPosY = spritesInColumn > 1 ? (spriteIndexY * 100) / (spritesInColumn - 1) : 0;

  return `${maskPosX}% ${maskPosY}%`;
}

function getFillPatternMaskCSS(patternMapping: PatternMapping): React.CSSProperties {
  const { arrangement: atlasArrangement } = patternConfig.meta;
  const { arrangement } = patternMapping;
  const maskImage = `url(${patternImg})`;

  const maskPosition = calculateMaskPosition(
    arrangement as SpriteArrangement,
    atlasArrangement as SpriteArrangement
  );

  const maskSize = `${atlasArrangement[0] * 100}% ${atlasArrangement[1] * 100}%`;

  return {
    maskImage,
    maskPosition,
    maskSize,
    WebkitMaskImage: maskImage,
    WebkitMaskPosition: maskPosition,
    WebkitMaskSize: maskSize,
    transform: "scale(1.3)",
  };
}

function getHatchAlpha(deckGlAlpha?: number) {
  const cssAlpha = (deckGlAlpha ?? 255) / 255;
  return cssAlpha && +((cssAlpha + 1) / 2).toFixed(2);
}

export const DesignationHatch = ({
  id,
  fillColorOverride,
  lineColorOverride,
}: Partial<HatchProps>) => {
  const fillColor = fillColorOverride ?? getCategoryFillColour(id);
  const backgroundColor = `rgba(${fillColor.slice(0, 3)}, ${getHatchAlpha(fillColor[3])})`;

  const borderColor = `rgb(${(
    lineColorOverride ??
    fillColorOverride ??
    getCategoryLineColour(id)
  ).slice(0, 3)})`;

  const patternMapping = getCategoryPatternMapping(id);
  const hasPattern = !!patternMapping;

  const iconKey = getDesignationIconKeyAccessor({
    sub_category_id: id!,
    designation_attributes: [],
  });
  const hasIcon = !!iconKey;

  return hasIcon ? (
    <span style={{ color: backgroundColor }}>
      <Icon icon={`icon-${iconKey}`} extraClasses="atlas-align-top" />
    </span>
  ) : (
    <Hatch
      fillColor={backgroundColor}
      borderColor={borderColor}
      borderWidth={hasPattern ? HatchBorderWidth.ONE : HatchBorderWidth.TWO}
      extraStyle={hasPattern ? getFillPatternMaskCSS(patternMapping) : undefined}
    />
  );
};

enum HatchBorderWidth {
  ONE = "1",
  TWO = "2",
}

interface BaseHatchProps {
  fillColor?: string;
  borderColor?: string;
  borderWidth?: `${HatchBorderWidth}`;
  extraStyle?: React.CSSProperties;
}

export const Hatch = ({
  fillColor = "white",
  extraStyle,
  borderColor,
  borderWidth = HatchBorderWidth.TWO,
}: BaseHatchProps) => {
  return (
    <div
      data-testid="hatch"
      style={{ borderColor: borderColor || fillColor }}
      className={twAtlasMerge(
        "atlas-h-5 atlas-w-5  atlas-overflow-hidden atlas-shrink-0 atlas-grow-0 atlas-shadow",
        borderWidth === HatchBorderWidth.ONE ? "atlas-border" : "atlas-border-2",
        "atlas-h-5 atlas-w-5 atlas-rounded"
      )}
    >
      <div
        className="atlas-w-full atlas-h-full"
        style={{
          backgroundColor: fillColor,
          ...extraStyle,
        }}
      />
    </div>
  );
};
