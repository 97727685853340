import { GeoJsonLayer, GeoJsonLayerProps } from "@deck.gl/layers";
import { CompositeLayer } from "@deck.gl/core";
import { Color } from "deck.gl";
import { MaskExtension } from "@deck.gl/extensions";
import { getPermissions } from "src/js/stores/user/actions/getPermissions";
import hasBetaFeature from "src/js/stores/user/actions/hasBetaFeature";
import UserFeature from "src/js/stores/user/Feature";

function hexToRGB(hex: string, alpha: number) {
  const r = parseInt(hex.slice(1, 3), 16);
  const g = parseInt(hex.slice(3, 5), 16);
  const b = parseInt(hex.slice(5, 7), 16);

  return [r, g, b, alpha];
}

export type Geometry = GeoJSON.Point | GeoJSON.MultiPoint | GeoJSON.Polygon | GeoJSON.MultiPolygon;

export type Feature = GeoJSON.Feature<Geometry>;

type SiteLayerProps = Omit<GeoJsonLayerProps<unknown>, "data" | "id"> & {
  features: Feature[];
  hexColor: string;
};

export class SiteLayer extends CompositeLayer<SiteLayerProps> {
  static componentName = "SiteLayer";

  constructor(props: SiteLayerProps) {
    super(props);
  }

  renderLayers() {
    const lineColor = hexToRGB(this.props.hexColor, 255);
    const geofenceGeometries = getPermissions()?.geofencesGeometries;
    const maskExtensions = [] as MaskExtension[];
    let maskId: string | undefined;

    if (geofenceGeometries?.length && !hasBetaFeature(UserFeature.disableGeofence)) {
      maskExtensions.push(new MaskExtension());
      maskId = "Geofence";
    }

    const layer = new GeoJsonLayer({
      id: "geojson-layer",
      data: this.props.features || [],
      extensions: maskExtensions,
      maskId,
      stroked: true,
      filled: false,
      extruded: false,
      getLineColor: lineColor as Color,
      getLineWidth: 3,
      lineWidthUnits: "pixels",
    });

    return [layer];
  }
}
