import { useState } from "react";
import clsx from "clsx";

import { SummaryCollapse } from "./SummaryCollapse";
import { AIResponse } from "react-migration/components/AIResponse/AIResponse";
import { FeatureVoting } from "react-migration/components/FeatureVoting/FeatureVoting";
import { useTranslation } from "react-migration/lib/i18n/useTranslation";
import { SelectionFeature, SelectionType } from "src/js/stores/map/store";
import { SummaryType } from "react-migration/components/AIResponse/SummaryType";

export const Summary = ({
  content,
  selection,
  setDetailSelection,
}: {
  content: SummaryType[];
  selection: SelectionFeature;
  setDetailSelection: (selection: SelectionFeature) => void;
}) => {
  const { t } = useTranslation();
  const [isExpanded, setIsExpanded] = useState(true);

  return (
    <>
      <div
        data-testid="summary-wrapper"
        className={clsx(
          "atlas-flex atlas-flex-col atlas-w-full atlas-transition-all atlas-ease-in-out atlas-duration-500",
          isExpanded
            ? "atlas-max-h-[2000px] atlas-overflow-y-auto"
            : "atlas-max-h-32 atlas-pb-8 atlas-overflow-hidden"
        )}
      >
        {/* Leverages the href data-href from the summary line to open the planning application details */}
        <div
          data-testid="summary-content-wrapper"
          className="atlas-px-1"
          onClick={({ target }) => {
            if (isAnchor(target)) {
              setDetailSelection({
                type: SelectionType.PLANNING_APPLICATION,
                id: target.dataset.href,
              });
            }
          }}
        >
          <AIResponse content={content} />
        </div>
        <FeatureVoting
          featureName="AI Planning Summary"
          message={t("ai.vote.accuracy.title")}
          className="atlas-pb-2 atlas-px-8"
          selection={selection}
        />
      </div>
      <SummaryCollapse isExpanded={isExpanded} setIsExpanded={setIsExpanded} />
    </>
  );
};

function isAnchor(element: EventTarget): element is HTMLAnchorElement {
  return element instanceof HTMLAnchorElement;
}
