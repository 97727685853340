import { ENVIRONMENT } from "src/js/util/environment";
import { Intercom } from "src/js/util/intercom";
import { ModalV2 } from "react-migration/components/ModalV2/ModalV2";
import { useTranslation } from "react-migration/lib/i18n/useTranslation";
import { Button } from "react-migration/components/Button";

export const LicenseBlockModal = () => {
  const { t } = useTranslation();

  return (
    <ModalV2.Root defaultOpen={true}>
      <ModalV2.Body
        title={t("nav.license_block_modal.title")}
        className="!atlas-z-[1000]"
        hideClose={true}
      >
        <div
          className="atlas-text-neutral-800 atlas-text-sm atlas-leading-normal"
          data-testid="kicked-modal"
        >
          <p>{t("nav.license_block_modal.message")}</p>
        </div>
        <div className="atlas-flex atlas-justify-end atlas-pt-4 atlas-gap-2">
          <Button variant="secondary" onClick={() => Intercom("show")}>
            {t("nav.license_block_modal.action.demo")}
          </Button>
          <Button
            onClick={() =>
              (window.location.href = `${ENVIRONMENT.ACCOUNTS_SERVICE_PRIVATE_API_URL}/customer-portal?flow=upgrade`)
            }
          >
            {t("nav.license_block_modal.action.upgrade")}
          </Button>
        </div>
      </ModalV2.Body>
    </ModalV2.Root>
  );
};
