import { ComponentProps } from "react";

export const NavbarContentRight = ({ children }: ComponentProps<"div">) => {
  return (
    <div
      data-testid="navbar-content-right"
      className="atlas-flex atlas-flex-row atlas-items-center atlas-justify-end atlas-mr-4 md:atlas-gap-2"
    >
      {children}
    </div>
  );
};
