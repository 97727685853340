import { useCallback } from "react";
import { useMutation } from "@apollo/client";
import UPDATE_SITE_LETTER_TEMPLATE from "react-migration/domains/sites/apollo/sites-api/mutations/updateSiteLetterTemplate.gql";
import {
  LandTechEndpoints,
  routedClient,
} from "react-migration/lib/persistence/apollo/routedClient";
import { useSnapshot } from "valtio";
import { Letter, letterStore } from "src/js/stores/letter/store";
import { updateLetter as updateLetterAction } from "src/js/stores/letter/actions";
import {
  SiteLetterTemplateInput,
  UpdateSiteLetterTemplateResponseData,
  UpdateSiteLetterTemplateVariables,
} from "../../typings/apollo";

const getLetterTemplateUpdate = (
  prevLetter: Letter,
  newLetter: Letter
): SiteLetterTemplateInput | null => {
  const templateFields: Partial<keyof Letter>[] = [
    "logo",
    "signature",
    "greeting",
    "bodyText",
    "bodyTextPage2",
    "signOff",
    "contactName",
    "contactEmail",
    "contactPhone",
    "signaturePage",
    "showSiteBoundary",
    "vatRegistrationNumber",
    "companyRegistrationNumber",
    "senderAddressLine1",
    "senderCity",
    "senderName",
    "senderPostcode",
    "senderProvince",
  ];

  const shouldUpdateTemplate = templateFields.some((field) => {
    if (field === "logo" || field === "signature") {
      return prevLetter[field]?.s3ObjectKey !== newLetter[field]?.s3ObjectKey;
    }
    return prevLetter[field] !== newLetter[field];
  });

  if (!shouldUpdateTemplate) {
    return null;
  }
  return {
    company_logo_file_id: newLetter.logo?.s3ObjectKey,
    signature_file_id: newLetter.signature?.s3ObjectKey,
    greeting: newLetter.greeting,
    body_text_page_1: newLetter.bodyText,
    body_text_page_2: newLetter.bodyTextPage2,
    sign_off: newLetter.signOff,
    contact_name: newLetter.contactName,
    contact_email: newLetter.contactEmail,
    contact_phone: newLetter.contactPhone,
    signature_page: newLetter.signaturePage,
    satellite_image_enabled: newLetter.showSiteBoundary,
    vat_registration_number: newLetter.vatRegistrationNumber,
    company_registration_number: newLetter.companyRegistrationNumber,
    sender_address_line_1: newLetter.senderAddressLine1,
    sender_city: newLetter.senderCity,
    sender_name: newLetter.senderName,
    sender_post_code: newLetter.senderPostcode,
    sender_province: newLetter.senderProvince,
  };
};

export function useUpdateLetter() {
  const { letter: storedLetter } = useSnapshot(letterStore);
  const [updateSiteLetterTemplate] = useMutation<
    UpdateSiteLetterTemplateResponseData,
    UpdateSiteLetterTemplateVariables
  >(UPDATE_SITE_LETTER_TEMPLATE, {
    client: routedClient,
    context: {
      endpoint: LandTechEndpoints.Sites,
    },
  });

  const updateLetter = useCallback(
    (letter: Letter) => {
      const siteLetterTemplateInput = getLetterTemplateUpdate(storedLetter, letter);
      if (siteLetterTemplateInput) {
        updateSiteLetterTemplate({
          variables: {
            siteLetterTemplateInput,
          },
        });
      }
      updateLetterAction(letter);
    },
    [storedLetter, updateSiteLetterTemplate]
  );

  return { updateLetter };
}
