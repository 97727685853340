import { Alert, Button, Loading } from "@landtechnologies/components";
import { useState } from "react";
import { useTitleOwnerQuery } from "react-migration/domains/ownership/apollo/useTitleOwnerQuery";
import { PropertyInfoDetailed } from "react-migration/domains/ownership/components/PropertyInfoDetailed/PropertyInfoDetailed";
import { PropertyInfoSummary } from "react-migration/domains/ownership/components/PropertyInfoSummary";
import { TitleOwner } from "react-migration/domains/ownership/components/TitleOwner";
import { useTranslation } from "react-migration/lib/i18n/useTranslation";
import { SelectionFeature } from "src/js/stores/map/store";

interface USOwnershipDetailPageProps {
  selection: SelectionFeature;
}

export function USOwnershipDetailPage({ selection }: USOwnershipDetailPageProps) {
  const { t } = useTranslation();
  const [viewDetails, setViewDetails] = useState(false);

  const { titleOwner: title, loading: titleLoading } = useTitleOwnerQuery(selection.id);

  if (titleLoading)
    return (
      <div className="atlas-mt-2 atlas-mb-6">
        <Loading />
      </div>
    );

  if (!title) {
    return (
      <div className="atlas-m-2">
        <Alert.Info>
          {t("ownership.react.map.sidebar.property_info.main_plot_not_found")}
        </Alert.Info>
      </div>
    );
  }

  return (
    <div>
      {!viewDetails ? (
        <div className="atlas-flex atlas-flex-col atlas-gap-y-3">
          <PropertyInfoSummary mainTitle={title} action={() => setViewDetails(true)} />
          <TitleOwner mainTitle={title} />
        </div>
      ) : (
        <div>
          <BackButton onClickHandler={() => setViewDetails(false)} />
          {title.id && (
            <div className="atlas-flex atlas-flex-col atlas-gap-y-3">
              <PropertyInfoDetailed propertyId={title.id} parcelId={title.parcel_identifier} />
            </div>
          )}
        </div>
      )}
    </div>
  );
}

function BackButton({ onClickHandler }: { onClickHandler: () => void }) {
  const { t } = useTranslation();
  return (
    <Button.Secondary className="atlas-m-2" onClick={onClickHandler}>
      <i className="icon-lt-arrow-left-s-line" />
      {t("ownership.react.map.sidebar.property_info.back_button")}
    </Button.Secondary>
  );
}
