import { TreeStructure } from "react-migration/components/CheckboxTree";

export const setCategoryTree = (layerId: string, categoryTree: TreeStructure) => ({
  type: "SET_LAYER_CATEGORY_TREE" as const,
  layerId,
  categoryTree,
});

export const setVisibleCategories = (layerId: string, visibleCategoryIds: string[]) => ({
  type: "SET_LAYER_VISIBLE_CATEGORIES" as const,
  layerId,
  visibleCategoryIds,
});

export const setHiddenStatuses = (layerId: string, hiddenStatuses: string[]) => ({
  type: "SET_LAYER_HIDDEN_STATUSES" as const,
  layerId,
  hiddenStatuses,
});

export const setLayerConfigHash = (layerId: string, layerConfigHash: string) => ({
  type: "SET_LAYER_CONFIG_HASH" as const,
  layerId,
  layerConfigHash,
});

export const setHiddenDesignationAttributes = (
  layerId: string,
  hiddenDesignationAttributes: string[]
) => ({
  type: "SET_LAYER_HIDDEN_DESIGNATION_ATTRIBUTES" as const,
  layerId,
  hiddenDesignationAttributes,
});
