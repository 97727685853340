import { useTranslation } from "react-migration/lib/i18n/useTranslation";
import cn from "classnames";
import { ParcelSizeRange } from "react-migration/layouts/map/Multilayer/layer_types/OwnershipLayerType/Context";
import { ParcelSize } from "./ParcelSize";
import { Unit } from "@landtechnologies/drawings-frontend/lib/types";
import { ParcelSizeRangeInput } from "./ParcelSizeRangeInput";

// OwnershipFilters Component
export type OwnershipFiltersProps = {
  setParcelSizeRange: React.Dispatch<ParcelSizeRange>;
  parcelSizeRange: ParcelSizeRange;
  unitType: Unit;
  disabled: boolean;
};

export function OwnershipFilters({
  unitType,
  parcelSizeRange,
  setParcelSizeRange,
  disabled,
}: OwnershipFiltersProps) {
  return (
    <div className="atlas-rounded atlas-flex-col">
      <div className="atlas-items-center atlas-flex atlas-justify-between atlas-w-full group">
        <Title disabled={disabled} />
        <div className="atlas-flex atlas-items-center">
          <ParcelSize parcelSizeRange={parcelSizeRange} unitType={unitType} />
        </div>
      </div>
      <ParcelSizeRangeInput
        disabled={disabled}
        unitType={unitType}
        setMetricParcelSizeRange={setParcelSizeRange}
        metricParcelSizeRange={parcelSizeRange}
      />
    </div>
  );
}

const Title = ({ disabled }: { disabled: boolean }) => {
  const { t } = useTranslation();
  return (
    <h4
      className={cn("atlas-text-sm atlas-font-medium atlas-py-2", {
        "atlas-opacity-50": disabled,
      })}
    >
      {t("map.toolbar.ownership_filters.parcel_size_filter")}
    </h4>
  );
};
