import { getGPUTier } from "detect-gpu";

const CPUSpecifications = {};

if (
  Object.keys(CPUSpecifications).length == 0 &&
  typeof HTMLCanvasElement.prototype.getContext === "function"
) {
  (async () => {
    const gpuTier = await getGPUTier();
    const webGL = await getGPUTier({ failIfMajorPerformanceCaveat: true });
    Object.assign(CPUSpecifications, gpuTier, {
      webGLEnabled: webGL.type !== "WEBGL_UNSUPPORTED",
    });
  })();
}

export function getDeviceSpecifications() {
  return CPUSpecifications;
}
