import { getGeom } from "@turf/turf";
import centerOfMass from "@turf/center-of-mass";
import { scaleLinear } from "d3-scale";
import type { Color } from "@deck.gl/core";
import { SelectionFeature } from "src/js/stores/map/store";
import { ENVIRONMENT } from "src/js/util/environment";
import { RoutingType } from "../types";
import { FILL_COLOUR_15, FILL_COLOUR_60 } from "./constants";

export const colourScale = scaleLinear<Color>()
  .domain([15, 60])
  .range([FILL_COLOUR_15, FILL_COLOUR_60])
  .clamp(true);

export function getIsochroneAPIUrl(selection: SelectionFeature, routingType: RoutingType) {
  const latLng = getGeom(centerOfMass(selection.feature)).coordinates.join(",");
  const contoursMinutes = encodeURIComponent([15, 30, 45, 60].join(","));

  return `https://api.mapbox.com/isochrone/v1/mapbox/${routingType}/${latLng}?contours_minutes=${contoursMinutes}&denoise=1&polygons=true&access_token=${ENVIRONMENT.MAPBOX_API_KEY}`;
}
