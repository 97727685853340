import { LandTechEndpoints, routedClient } from "react-migration/lib/persistence/apollo";
import { Category } from "react-migration/domains/constraints/typings/baseTypes/Category";
import { ConstraintsCategory } from "react-migration/layouts/map/Constraints/types";
import GET_CATEGORY from "./category.gql";
import { useQuery } from "@apollo/client";

export async function getCategoryByIdQuery(categoryId: ConstraintsCategory): Promise<Category> {
  const { data } = await routedClient.query<{ category: Category }>({
    query: GET_CATEGORY,
    variables: { id: categoryId },
    context: {
      endpoint: LandTechEndpoints.Constraints,
    },
  });

  return data.category;
}

export function useCategoryByIdQuery(categoryId: ConstraintsCategory) {
  return useQuery<{ category: Category }>(GET_CATEGORY, {
    variables: { id: categoryId },
    client: routedClient,
    context: {
      endpoint: LandTechEndpoints.Constraints,
    },
  });
}
