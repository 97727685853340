import { z } from "zod";
import { i18n } from "src/js/i18n";

export const addressSchema = z.object({
  country: z.string().min(1, { message: i18n.t("form.error.required_field").toString() }),
  name: z.string().min(1, { message: i18n.t("form.error.required_field").toString() }),
  line1: z.string().min(1, { message: i18n.t("form.error.required_field").toString() }),
  line2: z.string(),
  postcode: z.string().min(1, { message: i18n.t("form.error.required_field").toString() }),
  city: z.string().min(1, { message: i18n.t("form.error.required_field").toString() }),
});

export type FormFieldsAddress = z.infer<typeof addressSchema>;
