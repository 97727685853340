import type { SelectionHandler } from "../../types";
import { DesignationSelectionHeader } from "./DesignationSelectionHeader";
import { DesignationSelectionDetailView } from "./DesignationSelectionDetailView";
import { ConstraintsSelectionMapLayer } from "./ConstraintsSelectionMapLayer";
import { DesignationSelectionTitle } from "./DesignationSelectionTitle";
import { useDesignationOutsideZoomRestrictions } from "./useDesignationOutsideZoomRestrictions/useDesignationOutsideZoomRestrictions";

export const DesignationSelectionHandler: SelectionHandler = {
  SelectionTitle: DesignationSelectionTitle,
  SelectionHeader: DesignationSelectionHeader,
  SelectionDetailView: DesignationSelectionDetailView,
  SelectionMapLayer: ConstraintsSelectionMapLayer,
  useSelectionOutsideZoomRestriction: useDesignationOutsideZoomRestrictions,
};
