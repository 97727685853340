import { RawApplication, TypesDerived } from "react-migration/domains/planning/types";
import { PlanningApplicationLayerFilterProps } from "react-migration/domains/planning/map/layers/PlanningApplicationLayer/planningFilters";
import { inRangeInclusive } from "react-migration/lib/util/inRangeInclusive";

type Application = Omit<RawApplication, "location" | "references" | "type"> & {
  references: RawApplication["references"];
};

/**
 * Filter a list of applications returned from GraphQL using the same
 * getFilterValue/filterRange props used by the deck.gl layer – which usually
 * operate on GeoJSON features within the MVT. This is to avoid duplicating
 * filter logic.
 *
 * The only extra bit of logic ensures that an application will be shown if it:
 *   1. Conforms to the filters itself
 *   2. Is a descendent of an application which conforms to the filters
 *      - We want to show all related apps, even those outside the filters
 */
export function filterApplicationListByLayerFilters<TApplication extends Application = Application>(
  applications: TApplication[],
  { getFilterValue, filterRange }: PlanningApplicationLayerFilterProps
): TApplication[] {
  const applicationLookup = new Map(applications.map((a) => [a._id, a]));
  const withinFiltersLookup = new Map(applications.map((a) => [a._id, appWithinLayerFilters(a)]));

  function appWithinLayerFilters(app: TApplication) {
    const [isVisible, dateReceived, size] = getFilterValue(rawApplicationToFilterableFeature(app));
    const [isVisibleRange, ageRange, sizeRange] = filterRange;

    if (!inRangeInclusive(isVisible, isVisibleRange[0], isVisibleRange[1])) return false;
    if (!inRangeInclusive(dateReceived, ageRange[0], ageRange[1])) return false;
    if (!inRangeInclusive(size, sizeRange[0], sizeRange[1])) return false;

    return true;
  }

  function indirectlyWithinFiltersRecursive(
    currentApp: TApplication,
    checked: string[] = []
  ): boolean {
    if (withinFiltersLookup.get(currentApp._id)) return true;
    if (checked.includes(currentApp._id)) return false;

    return (currentApp.references || [])
      .map((r) => applicationLookup.get(r.planningId))
      .filter((a): a is TApplication => !!a)
      .map((a) => indirectlyWithinFiltersRecursive(a, [...checked, currentApp._id]))
      .includes(true);
  }

  const filteredApplications = applications.filter((a) => indirectlyWithinFiltersRecursive(a));

  return filteredApplications;
}

/* eslint-disable  @typescript-eslint/no-explicit-any */
function rawApplicationToFilterableFeature<TApplication extends Application>({
  classification,
  date_received,
  types_derived,
  is_minor,
  status_derived,
  size,
}: TApplication): any {
  return {
    properties: {
      classification: classification,
      size: size,
      is_minor: is_minor,
      status_derived: status_derived,
      date_received: new Date(date_received).valueOf(),
      is_uncategorised: !types_derived.length,
      is_eia: types_derived.includes(TypesDerived.EIA),
      is_tpo: types_derived.includes(TypesDerived.TPO),
      is_gpdr: types_derived.includes(TypesDerived.GPDR),
      is_outline: types_derived.includes(TypesDerived.OUTLINE),
      is_change_of_use: types_derived.includes(TypesDerived.CHANGE_OF_USE),
      is_listed_building: types_derived.includes(TypesDerived.LISTED_BUILDING),
      is_full_application: types_derived.includes(TypesDerived.FULL_APPLICATION),
      is_reserved_matters: types_derived.includes(TypesDerived.RESERVED_MATTERS),
      is_discharge_of_conditions: types_derived.includes(TypesDerived.DISCHARGE_OF_CONDITIONS),
    },
  };
}
