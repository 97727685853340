import {
  AddressGroup,
  PlanningApplication,
  PlanningClassification,
} from "react-migration/domains/planning/types";

export function applicationsByAddress(rawApplications: PlanningApplication[]): AddressGroup[] {
  const uniqueAddresses = new Set(rawApplications.map((a) => a.address).map(normalizeAddress));

  const sortedApplications = rawApplications.sort(sortByDateReceived);

  return getApplicationsPerAddress(uniqueAddresses, sortedApplications);
}

function getApplicationsPerAddress(
  uniqueAddresses: Set<string>,
  applications: PlanningApplication[]
) {
  const appsPerAddress = Array.from(uniqueAddresses).map((uniqueAddress) => {
    const appsWithThisAddress = applications.filter(
      (application: PlanningApplication) =>
        normalizeAddress(application.address as string) === uniqueAddress
    );

    return {
      address: appsWithThisAddress[0].address,
      applications: appsWithThisAddress,
      classifications: getGroupClassifications(appsWithThisAddress),
    };
  });

  return appsPerAddress;
}

export function normalizeAddress(rawAddress: string): string {
  return rawAddress
    .toLowerCase()
    .replace(/,|\.|'|\s/gi, "")
    .replace(/london.*/g, "london");
}

const sortByDateReceived = (app1: PlanningApplication, app2: PlanningApplication) =>
  new Date(app2.date_received as string).getTime() -
  new Date(app1.date_received as string).getTime();

export function getGroupClassifications(
  applications: PlanningApplication[]
): Set<PlanningClassification> {
  return new Set(
    applications.map((a) => a.classification).filter(isSomeEnum(PlanningClassification))
  );
}

type EnumHolder = { [s: string]: unknown } | ArrayLike<unknown>;
const isSomeEnum =
  <T extends EnumHolder>(e: T) =>
  (token: unknown): token is T[keyof T] =>
    Object.values(e).includes(token as T[keyof T]);
