import { Icon, InfoList, Section } from "@landtechnologies/components";
import { FC, useMemo } from "react";
import { useTranslation } from "react-migration/lib/i18n/useTranslation";
import { logEvent } from "react-migration/lib/util/logEvent";
import { SimpleTitleOwnership, Title } from "../../typings/Title";
import { getTitleOwners } from "react-migration/domains/ownership/helpers/getTitleOwners";
import { GlobalUltimateOwner, DomesticUltimateOwner } from "./GlobalUltimateOwner";
import { ENVIRONMENT } from "src/js/util/environment";
import { TextFilterMust } from "@landtechnologies/dtos";
import { encodeFiltersForQueryString } from "react-migration/domains/search/util/encodeFiltersForQueryString";
import Feature from "src/js/stores/user/Feature";
import hasFeature from "src/js/stores/user/actions/hasFeature";

const OwnerAttributeUnavailable = () => {
  const { t } = useTranslation();
  return (
    <InfoList.Item>
      <InfoList.ItemTitle>
        {t("sidebar.ownership_sidebar.title_owner_not_available")}
      </InfoList.ItemTitle>
      <InfoList.ItemValue>
        {t("sidebar.ownership_sidebar.title_owner_no_record")}
      </InfoList.ItemValue>
    </InfoList.Item>
  );
};

interface DisplayOwnerProps {
  name: string;
  address?: string;
}

const DisplayOwner = ({ name, address }: DisplayOwnerProps) => {
  if (name === "ATTRIBUTE_UNAVAILABLE") return <OwnerAttributeUnavailable />;

  return (
    <div className="atlas-flex atlas-items-center">
      <div className="atlas-flex-1">
        <div data-testid="owner-name">
          <span>{name}</span>
        </div>
        <div data-testid="owner-address">{address}</div>
      </div>
    </div>
  );
};

type CompanySearchLinkProps = {
  name: string;
  address: string;
};

const CompanySearchLink = ({ name, address }: CompanySearchLinkProps) => {
  const { t } = useTranslation();

  const contactInfoLink = `https://www.google.com/search?q=${name} ${address}`;
  const formattedContactInfoLink = contactInfoLink?.replace(/ /g, "+").replace(/[,&]/g, "");

  return formattedContactInfoLink ? (
    <a
      href={formattedContactInfoLink}
      target="_blank"
      rel="noreferrer noopener"
      onClick={() =>
        logEvent("Owner Name And Address Google Link Button Click", {
          link: formattedContactInfoLink,
        })
      }
    >
      {t("ownership.react.map.sidebar.title_owner.search_company_button")}
      <Icon
        size="sm"
        icon="icon-lt-external-link-line"
        cursor="pointer"
        extraClasses="atlas-pl-1"
      />
    </a>
  ) : null;
};
interface ExternalLinksProps {
  owner: SimpleTitleOwnership;
}

function ExternalLinks({ owner }: ExternalLinksProps) {
  const { t } = useTranslation();
  if (!isCorporateOwner(owner) && !owner.appraiser_link) return null;

  return (
    <div>
      <InfoList.ItemTitle>
        {t("ownership.react.map.sidebar.property_info.external_links")}
      </InfoList.ItemTitle>
      <InfoList.ItemValue>
        <div className="atlas-flex atlas-flex-col">
          {owner.appraiser_link && (
            <a target="_blank" rel="noreferrer noopener" href={owner.appraiser_link}>
              {t("ownership.react.map.sidebar.title_owner.ultimate_owner.appraiser_link")}
              <Icon
                size="sm"
                icon="icon-lt-external-link-line"
                cursor="pointer"
                extraClasses="atlas-pl-1"
              />
            </a>
          )}

          {isCorporateOwner(owner) && (
            <CompanySearchLink name={owner.name} address={owner.address} />
          )}
        </div>
      </InfoList.ItemValue>
    </div>
  );
}

interface OwnerPortforlioLinkProps {
  owner: SimpleTitleOwnership;
}

//Format baseUrl when we are running on a PR branch
function formatBaseAppUrl() {
  const url = window.location.href;
  const match = url.match(/(https:\/\/app\.staging\.land\.tech\/preview\/landinsight\/pr-\d+)/);

  if (match) {
    return match[1];
  }
  return ENVIRONMENT.APP_URL;
}

export function OwnerPortforlioLink({ owner }: OwnerPortforlioLinkProps) {
  const { t } = useTranslation();
  if (!owner.name) {
    return null;
  }

  const ownerPortfolioNameFilter = {
    id: "owner-portfolio-text-filter-id",
    field: "sidebar.react.search_sidebar.owner_portfolio",
    type: "text" as const,
    text: owner.name,
    must: TextFilterMust.CONTAIN,
  };

  const ownerPorfolioAddressFilter = {
    id: "owner-portfolio-address-text-filter-id",
    field: "sidebar.react.search_sidebar.owner_portfolio",
    type: "text" as const,
    text: owner.address,
    must: TextFilterMust.CONTAIN,
    hidden: true,
  };

  const search_filter = {
    id: "owner-portfolio-id",
    type: "compound" as const,
    filters: [ownerPortfolioNameFilter, ownerPorfolioAddressFilter],
    text: owner.name,
    must: "MATCH_ALL",
  };

  const appUrl = formatBaseAppUrl();
  const ownerPortforlioInfoLink = `${appUrl}/map/search/7,28.112148866968088,-80.55172575461283/portfolio?filters=${encodeFiltersForQueryString(
    [search_filter]
  )}&tab=results`;

  return owner.name ? (
    <a
      href={ownerPortforlioInfoLink}
      rel="noreferrer noopener"
      onClick={() =>
        logEvent("Owner Portforlio Link Button Click", {
          link: ownerPortforlioInfoLink,
        })
      }
    >
      {t("ownership.react.map.sidebar.title_owner.owner_portfolio_link")}
    </a>
  ) : null;
}

function isCorporateOwner(
  ultimateOwner: SimpleTitleOwnership
): ultimateOwner is SimpleTitleOwnership & { name: string; address: string; type: "COMPANY" } {
  return ultimateOwner.type === "COMPANY" && !!ultimateOwner.name && !!ultimateOwner.address;
}

interface TitleOwnerProps {
  mainTitle: Title;
}

export const TitleOwner: FC<TitleOwnerProps> = ({ mainTitle }) => {
  const { t } = useTranslation();

  const titleOwners = useMemo(() => getTitleOwners(mainTitle), [mainTitle]);

  const atLeastOneAvailableOwner = titleOwners.some(
    (owner) => owner?.name !== "ATTRIBUTE_UNAVAILABLE"
  );

  return (
    <Section>
      <InfoList data-testid="title-owner">
        <InfoList.Title>{t("sidebar.ownership_sidebar.ownership")}</InfoList.Title>
        <InfoList.Item>
          {atLeastOneAvailableOwner ? (
            <div className="atlas-flex atlas-flex-col atlas-gap-2">
              {titleOwners
                .filter((owner) => owner.name)
                .map((owner, i) => (
                  <Owner
                    key={owner.name!}
                    title={`${i >= 1 ? `${getNumberWithOrdinal(i + 1)} ` : ""}Owner`}
                    owner={owner}
                  />
                ))}
            </div>
          ) : (
            <InfoList.ItemTitle data-testid="no-owner-info">
              {t("ownership.react.map.sidebar.title_owner.no_owner_info")}
            </InfoList.ItemTitle>
          )}
        </InfoList.Item>
      </InfoList>
    </Section>
  );
};

function getNumberWithOrdinal(n: number) {
  const s = ["th", "st", "nd", "rd"],
    v = n % 100;
  return n + (s[(v - 20) % 10] || s[v] || s[0]);
}

interface OwnerProps {
  title: string;
  owner: SimpleTitleOwnership;
}

function Owner({ owner, title }: OwnerProps) {
  return (
    <div key={owner.name!} className="atlas-flex atlas-flex-col atlas-gap-2">
      <div className="atlas-font-bold atlas-text-sm">{title}</div>
      <DisplayOwner name={owner.name!} address={owner.address} />
      {!hasFeature(Feature.usAccess) && (
        <>
          <GlobalUltimateOwner ultimateOwner={owner.global_ultimate_owner} />
          <DomesticUltimateOwner ultimateOwner={owner.domestic_ultimate_owner} />
        </>
      )}
      <OwnerPortforlioLink owner={owner} />
      <ExternalLinks owner={owner} />
    </div>
  );
}
