import { Button } from "@landtechnologies/components";
import { useTranslation } from "react-migration/lib/i18n/useTranslation";
import { ENVIRONMENT } from "src/js/util/environment";

export const CurrentPlan = () => {
  const { t } = useTranslation();

  return (
    <div className="atlas-mb-6">
      <div>
        If you need invoices from before 2024 please email{" "}
        <a href="mailto:support@land.tech">accounts@land.tech</a> requesting the invoices you need
      </div>
      <br />
      <Button.Secondary
        onClick={() =>
          (window.location.href = `${ENVIRONMENT.ACCOUNTS_SERVICE_PRIVATE_API_URL}/customer-portal`)
        }
      >
        {t("dashboard.your_account_balance.view_invoices")}
      </Button.Secondary>
    </div>
  );
};
