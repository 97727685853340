export function HistoricTransactionTimelineItem({
  first,
  last,
}: Readonly<{
  first: boolean;
  last: boolean;
}>) {
  return (
    <div className="atlas-relative atlas-w-5 atlas-h-6 atlas-flex-shrink-0 atlas-flex-grow-0">
      {first ? null : (
        <div
          data-testid="timeline-item-head"
          className="atlas-bg-neutral-400 atlas-w-1 atlas-h-4 atlas-left-1/2 atlas-absolute -atlas-translate-x-1/2 atlas-top-1/2 -atlas-translate-y-full"
        ></div>
      )}
      {last ? null : (
        <div
          data-testid="timeline-item-tail"
          className="atlas-bg-neutral-400 atlas-w-1 atlas-h-4 atlas-left-1/2 atlas-absolute -atlas-translate-x-1/2 atlas-top-1/2"
        ></div>
      )}
      <div
        data-testid="timeline-item-dot"
        className="atlas-absolute atlas-w-3 atlas-rounded-md atlas-h-3 atlas-bg-neutral-400 atlas-left-1/2 atlas-top-1/2 -atlas-translate-x-1/2 -atlas-translate-y-1/2"
      ></div>
    </div>
  );
}
