import classNames from "classnames";
import { useMemo } from "react";
import * as React from "react";

export enum AvatarSize {
  XS = "xs",
  SM = "sm",
  MD = "md",
  LG = "lg",
}

export interface AvatarProps {
  content?: string;
  firstName?: string;
  lastName?: string;
  size?: AvatarSize;
}

const COLOR_CLASS_NAMES = [
  "atlas-bg-statusRed-500",
  "atlas-bg-statusAmber-500",
  "atlas-bg-statusGreen-500",
  "atlas-bg-platformBlue-500",
  "atlas-bg-[#F096D0]",
  "atlas-bg-[#29AD98]",
  "atlas-bg-[#D862E9]",
  "atlas-bg-[#E7AB30]",
  "atlas-bg-blueGrey-500",
  "atlas-bg-marketingPurple-500",
];

export const Avatar: React.FC<AvatarProps> = ({ firstName, lastName, size, content }) => {
  const initials =
    size === "xs" ? firstName?.[0] ?? "" : (firstName?.[0] ?? "") + (lastName?.[0] ?? "");

  const colourClassName = useMemo(() => {
    if (content || !firstName || !lastName) return "atlas-bg-neutral-300";

    const numberHash = [...firstName, ...lastName].reduce((a, b) => {
      a = (a << 5) - a + b.charCodeAt(0);
      return a & a;
    }, 0);
    return COLOR_CLASS_NAMES[Math.abs(numberHash % COLOR_CLASS_NAMES.length)];
  }, [firstName, lastName, content]);

  const sizeClassName = useMemo(() => {
    switch (size) {
      case "lg":
        return classNames("atlas-w-11", "atlas-h-11", "atlas-text-lg");
      case "md":
        return classNames("atlas-w-9", "atlas-h-9", "atlas-text-md");
      case "sm":
        return classNames("atlas-w-7", "atlas-h-7", "atlas-text-sm");
      case "xs":
      default:
        return classNames("atlas-w-5", "atlas-h-5", "atlas-text-xs");
    }
  }, [size]);

  return (
    <div
      className={classNames(
        sizeClassName,
        "atlas-block",
        "atlas-rounded-full",
        "atlas-overflow-hidden",
        "atlas-border-2",
        "atlas-border-white"
      )}
      data-testid="li-avatar-container"
    >
      <div
        className={classNames(
          colourClassName,
          "atlas-text-neutral-50",
          "atlas-uppercase",
          "atlas-w-full",
          "atlas-h-full",
          "atlas-flex",
          "atlas-justify-center",
          "atlas-items-center",
          "atlas-font-medium"
        )}
        data-testid="li-avatar"
      >
        {content ?? initials}
      </div>
    </div>
  );
};
