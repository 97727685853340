import { useQuery } from "@apollo/client";
import { UserAddressesResponse } from "../typings/UserAddressesResponse";
import GET_USERS_ADDRESSES from "../apollo/queries/userAddresses.gql";
import { routedClient } from "react-migration/lib/persistence/apollo/routedClient";

export const useUserAddressesQuery = () => {
  return useQuery<UserAddressesResponse>(GET_USERS_ADDRESSES, {
    client: routedClient,
  });
};
