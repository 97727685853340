import { useQuery } from "@apollo/client";
import { LandTechEndpoints, routedClient } from "react-migration/lib/persistence/apollo";
import ADDITIONAL_ZOOPLA_TRANSACTIONS from "../apollo/additionalZooplaTransactionsByUPRNs.gql";
import { ZooplaValuationHistoryItemDTO } from "@landtechnologies/comparables-services";
import { useMemo } from "react";

interface AdditionalZooplaTransactionsByUPRNHookArgs {
  uprn?: string;
}

type AdditionalZooplaTransactionsResponse = {
  zooplaComparableByUPRNs: {
    uprn: string;
    valuation?: {
      sale?: {
        history: ZooplaValuationHistoryItemDTO[];
      };
    };
  }[];
};
export function useAdditionalZooplaTransactionsByUPRN({
  uprn,
}: AdditionalZooplaTransactionsByUPRNHookArgs) {
  const { data, loading, error } = useQuery<AdditionalZooplaTransactionsResponse>(
    ADDITIONAL_ZOOPLA_TRANSACTIONS,
    {
      variables: { uprns: [uprn] },
      client: routedClient,
      context: {
        endpoint: LandTechEndpoints.Gateway,
      },
      skip: !uprn,
    }
  );

  return useMemo(
    () => ({
      data: loading ? undefined : data?.zooplaComparableByUPRNs?.[0],
      loading,
      error,
    }),
    [data, loading, error]
  );
}
