import clsx from "clsx";
import { FC } from "react";
import { ZooplaLogoLarge } from "react-migration/domains/comparables/img/ZooplaLogoLarge";
import { ZooplaListingsDTO } from "../../../../typings/Zoopla";
import { useTranslation } from "react-migration/lib/i18n/useTranslation";
import { Loading } from "@landtechnologies/components";
import hasFeature from "src/js/stores/user/actions/hasFeature";
import Feature from "src/js/stores/user/Feature";

interface ZooplaListingsProps {
  className?: string;
  zooplaListingsDataLoading?: boolean;
  zooplaListingsData?: ZooplaListingsDTO;
}

export const ZooplaListings: FC<ZooplaListingsProps> = ({
  className,
  zooplaListingsDataLoading,
  zooplaListingsData,
}) => {
  const { t } = useTranslation();

  if (!hasFeature(Feature.zooplaLightAccess) && !hasFeature(Feature.zooplaFullAccess)) {
    return null;
  }

  if (zooplaListingsDataLoading) {
    return (
      <div className={clsx(`atlas-flex`, className)}>
        <div className="atlas-inline-block atlas-text-3xs atlas-px-1">
          <Loading relativeSizing />
        </div>
      </div>
    );
  }

  if (!zooplaListingsDataLoading && zooplaListingsData?.listings?.latest?.url) {
    return (
      <div className={clsx("atlas-flex", className)}>
        <a
          href={zooplaListingsData.listings.latest.url}
          target="_blank"
          rel="noreferrer"
          className="atlas-w-40 atlas-px-1 atlas-font-semibold atlas-rounded atlas-shadow-sm atlas-cursor-pointer atlas-text-sm atlas-bg-background-white atlas-border atlas-border-solid atlas-border-neutral-400  atlas-shadow-secondary atlas-text-content-primary"
        >
          <i className="icon-lt-external-link-line atlas-px-1" />
          <ZooplaLogoLarge />
          &nbsp;
          {t("comparables.map.sidebar.zoopla.listings")}
        </a>
      </div>
    );
  }
  return null;
};
