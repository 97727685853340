import { ref } from "valtio";
import { mapStore as state } from "../store";
import type { DeckGLClickHandler, CallbackFunction, DeckLayer } from "../store";

function addDeckLayer(deckLayer: DeckLayer): void {
  state.deckLayers = [...state.deckLayers, ref(deckLayer)];
}

function upsertDeckLayer(deckLayer: DeckLayer) {
  const index = state.deckLayers.findIndex((l) => l.key === deckLayer.key);

  if (index === -1) {
    addDeckLayer(deckLayer);
  } else {
    const updatedDeckLayers = [...state.deckLayers];
    updatedDeckLayers.splice(index, 1, ref(deckLayer));
    state.deckLayers = updatedDeckLayers;
  }
}

function removeDeckLayer(key: string) {
  state.deckLayers = [...state.deckLayers.filter((l) => l.key !== key)];
  state.clickCallbacks = state.clickCallbacks.filter((l) => l.key !== key);
}

function addClickListener(callback: CallbackFunction) {
  state.clickCallbacks.push(callback);
}

function removeClickListener(key: string) {
  state.clickCallbacks = state.clickCallbacks.filter((l) => l.key !== key);
}

const onClickHandler: DeckGLClickHandler = (info, event) => {
  // @ts-expect-error Escape early if preventDefault has been called on this event.
  if (event.srcEvent.domEvent?.defaultPrevented) return;

  return state.clickCallbacks.forEach(({ callback }) => callback(info, event));
};

export {
  addDeckLayer,
  upsertDeckLayer,
  removeClickListener,
  removeDeckLayer,
  addClickListener,
  onClickHandler,
};
