import { LayerTypeSelectionDetailViewProps } from "../../types";
import { Section } from "@landtechnologies/components";
import { useUtilityById } from "./useUtilityById";
import { DetailsList } from "react-migration/components/DetailsList";
import { UtilityFeature } from "./types";
import { isDefined } from "react-migration/lib/util/isDefined";

type UtilityProperties = keyof UtilityFeature["properties"];

const ORDERED_DISPLAY_PROPERTIES: UtilityProperties[] = [
  "title",
  "UniqueID",
  "Provider",
  "Category",
  "SubType",
  "Voltage",
  "Rate",
  "Pressure",
  "Visibility",
  "Status",
  "PAS128Type",
  "IssueDate",
  "OrderID",
];

export function UtilitySelectionDetailView({
  selection,
}: Readonly<LayerTypeSelectionDetailViewProps>) {
  const selectedUtility = useUtilityById(selection.id);

  if (!selectedUtility) return null;

  return (
    <Section
      data-testid="site-details"
      bodyClasses="atlas-p-3 atlas-flex atlas-flex-col atlas-gap-6"
    >
      <DetailsList>
        {ORDERED_DISPLAY_PROPERTIES.map((key) => [key, selectedUtility.properties[key]])
          .filter(([, val]) => isDefined(val))
          .map(([key, value]) => (
            <DetailsList.Item key={key} label={key as string}>
              {value}
            </DetailsList.Item>
          ))}
      </DetailsList>
    </Section>
  );
}
