const _omitTypename = (key: string, value: unknown) => (key === "__typename" ? undefined : value);

/**
 * Takes the response from a GraphQL query and strips out any `__typename` props.
 * @param gqlResponse: Any JSON serialisable object or falsy value.
 * @returns Response cleaned of `__typename` fields. If falsy you just get that back.
 */
export function cleanTypename<T>(gqlResponse?: T): T | undefined {
  return gqlResponse && JSON.parse(JSON.stringify(gqlResponse), _omitTypename);
}
