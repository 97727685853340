import { ISourcingToolSearchFilter } from "react-migration/domains/search/typings/SourcingToolSpecificFilterTypes";
import { SearchType } from "react-migration/domains/search/constants/searchTypes";

export const CompanyStatusFilter: ISourcingToolSearchFilter = {
  id: "company-status-id",
  type: "checkbox",
  field: "sidebar.react.search_sidebar.company_status",
  updateFilterDialogIsVisible: false,
  searchTypes: [SearchType.SOURCING_TOOL_UK],
  must: "MATCH_ANY",
  mustOptions: ["MATCH_ANY", "MATCH_NONE"],
  values: [
    { name: "Active", key: "active" },
    { name: "Active - Proposal To Strike Off", key: "active_proposal_to_strike_off" },
    { name: "Administration Order", key: "administration_order" },
    { name: "Administrative Receiver", key: "administrative_receiver" },
    { name: "In Administration", key: "in_administration" },
    {
      name: "In Administration/Administrative Receiver",
      key: "in_administration_administrative_receiver",
    },
    { name: "In Administration/Receiver Manager", key: "in_administration_receiver_manager" },
    { name: "Liquidation", key: "liquidation" },
    {
      name: "Live But Receiver Manager On At Least One Charge",
      key: "live_but_receiver_manager_on_at_least_one_charge",
    },
    {
      name: "Receiver Manager / Administrative Receiver",
      key: "receiver_manager_administrative_receiver",
    },
    { name: "Receivership", key: "receivership" },
    { name: "Voluntary Arrangement", key: "voluntary_arrangement" },
    {
      name: "Voluntary Arrangement / Administrative Receiver",
      key: "voluntary_arrangement_administrative_receiver",
    },
    {
      name: "Voluntary Arrangement / Receiver Manager",
      key: "voluntary_arrangement_receiver_manager",
    },
  ],
};
