import { ISourcingToolSearchFilter } from "react-migration/domains/search/typings/SourcingToolSpecificFilterTypes";
import { SearchType } from "react-migration/domains/search/constants/searchTypes";

export const DevelopedAreaFilter: ISourcingToolSearchFilter = {
  id: "developed-area-id",
  type: "number-range",
  rangeType: "percentage",
  field: "sidebar.react.search_sidebar.developed_area",
  range: [null, null],
  rangeLimit: [0, 100],
  updateFilterDialogIsVisible: false,
  startInputPlaceholder: "0%",
  endInputPlaceholder: "100%",
  units: "%",
  displayMessages: {
    suffix: "percent",
  },
  searchTypes: [SearchType.SOURCING_TOOL_UK],
};
