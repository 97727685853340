import Feature from "src/js/stores/user/Feature";
import { ConstraintsCategory } from "../../Constraints/types";
import { ConstraintsLayerType } from "../layer_types/ConstraintsLayerType";
import { Layer } from "../types";

export const SPATIAL_RISK_MULTIPLIERS: Layer = {
  id: ConstraintsCategory.SPATIAL_RISK_MULTIPLIERS,
  layerType: ConstraintsLayerType,
  title: "BNG Spatial Risk Multipliers",
  requiredFeatureAccess: [Feature.planningPolicyLayer],
  toggleable: true,
  description:
    "Offsite mitigation for BNG will be more expensive for sites outside the development site's spatial risk multiplier areas",
  hideByDefault: true,
  layerConfig: {
    rootCategory: ConstraintsCategory.SPATIAL_RISK_MULTIPLIERS,
    categorySchema: [
      { key: "national_character_areas" },
      { key: "operational_catchments" },
      { key: "waterbody_catchments" },
      { key: "open_rivers" },
    ],
  },
};
