import { useMemo, useState, Children, PropsWithChildren } from "react";

type ExpandibleListProps = PropsWithChildren<{
  limit: number;
  showCount?: boolean;
  itemsName: string;
}>;

export function ExpandibleList({ limit, showCount, itemsName, children }: ExpandibleListProps) {
  const [expanded, setExpanded] = useState(false);

  const length = Children.count(children);
  const showExpandButton = length > limit;

  const visiblechildren = useMemo(
    () => Children.toArray(children).slice(0, expanded ? Infinity : limit),
    [children, expanded, limit]
  );

  return (
    <div className="atlas-flex atlas-flex-col atlas-divide-y atlas-divide-neutral-200 atlas-border atlas-border-neutral-300 atlas-rounded atlas-overflow-hidden">
      {visiblechildren}
      {showExpandButton && (
        <button
          className="atlas-border-solid atlas-p-2 atlas-text-xs atlas-bg-neutral-50 hover:atlas-bg-neutral-100 atlas-text-content-link hover:atlas-text-content-link-hover"
          onClick={() => setExpanded((v) => !v)}
        >
          {expanded
            ? `Show fewer ${itemsName}`
            : `Show all ${showCount ? `${length} ` : ""}${itemsName}`}
        </button>
      )}
    </div>
  );
}
