import { PropsWithChildren } from "react";
import { NavbarLogo } from "./components/NavbarLogo/NavbarLogo";
import { NavbarContentLeft } from "./components/NavbarContentLeft";
import { NavbarContentCenter } from "./components/NavbarContentCenter";
import { NavbarContentRight } from "./components/NavbarContentRight";
import { NavbarItem } from "./components/NavbarItem";

export function Navbar({ children }: PropsWithChildren) {
  return (
    <div
      data-testid="navbar"
      className="atlas-relative atlas-flex atlas-flex-row atlas-w-full atlas-z-50 atlas-bg-blueGrey-50 atlas-text-blueGrey-950 atlas-h-[52px]"
    >
      {children}
      <div className="atlas-absolute atlas-right-0 atlas-bottom-0 atlas-left-0 atlas-border-b atlas-border-b-blueGrey-300" />
    </div>
  );
}

Navbar.Wrapper = Navbar;
Navbar.Left = NavbarContentLeft;
Navbar.Center = NavbarContentCenter;
Navbar.Right = NavbarContentRight;
Navbar.Logo = NavbarLogo;
Navbar.Item = NavbarItem;
