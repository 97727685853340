import { proxy } from "valtio/vanilla";
import { defaultSearchTypeState } from "react-migration/domains/search/constants/defaultSearchTypeState";
import { SearchType } from "react-migration/domains/search/constants/searchTypes";
import { SearchTypeState } from "react-migration/domains/search/typings/Search";
import isLocationFilter from "react-migration/domains/search/util/refiners/isLocationFilter";
const defaultValues = structuredClone(defaultSearchTypeState);
export type SearchStore = SearchTypeState & {
  types: Record<string, SearchTypeState>;
  currentSearchType: SearchType | "";
};

export const defaultState: SearchStore = {
  currentSearchType: "",
  types: {},
  get savedResults() {
    return this.types[this.currentSearchType]?.savedResults ?? defaultValues.savedResults;
  },
  get isClusteredParcelSearch() {
    return (
      this.types[this.currentSearchType]?.isClusteredParcelSearch ??
      defaultValues.isClusteredParcelSearch
    );
  },
  get savedResultsError() {
    return this.types[this.currentSearchType]?.savedResultsError ?? defaultValues.savedResultsError;
  },
  get boundaryFilters() {
    return this.types[this.currentSearchType]?.boundaryFilters ?? defaultValues.boundaryFilters;
  },
  get searchId() {
    return this.types[this.currentSearchType]?.searchId ?? defaultValues.searchId;
  },
  get searchResultsLoading() {
    return (
      this.types[this.currentSearchType]?.searchResultsLoading ?? defaultValues.searchResultsLoading
    );
  },
  get searchResults() {
    return this.types[this.currentSearchType]?.searchResults ?? defaultValues.searchResults;
  },
  get searchResultsError() {
    return (
      this.types[this.currentSearchType]?.searchResultsError ?? defaultValues.searchResultsError
    );
  },
  get selectedFilters() {
    return this.types[this.currentSearchType]?.selectedFilters ?? defaultValues.selectedFilters;
  },
  get locationFilter() {
    return this.types[this.currentSearchType]?.selectedFilters.find(isLocationFilter);
  },
  get selectedItemsById() {
    return this.types[this.currentSearchType]?.selectedItemsById ?? defaultValues.selectedItemsById;
  },
  get searchOnMapChange() {
    return this.types[this.currentSearchType]?.searchOnMapChange ?? defaultValues.searchOnMapChange;
  },
  get activeTab() {
    return this.types[this.currentSearchType]?.activeTab ?? defaultValues.activeTab;
  },
  get searchToDelete() {
    return this.types[this.currentSearchType]?.searchToDelete ?? defaultValues.searchToDelete;
  },
  get lastRequest() {
    return this.types[this.currentSearchType]?.lastRequest ?? defaultValues.lastRequest;
  },
};
export const createSearchStore = () => defaultState;
const searchStore = proxy<SearchStore>(createSearchStore());

const localStorageKey = "searchState";
// const storedStateString = localStorage.getItem(localStorageKey);
// const previousSearchStore = storedStateString ? JSON.parse(storedStateString) : {};
// export const createSearchStore = () => Object.assign(defaultState, previousSearchStore);
// const searchStore = proxy<SearchStore>(createSearchStore());
export { searchStore, localStorageKey };
