import { Theme, Tooltip } from "@landtechnologies/components";
import { ValueFormatter } from "../utils";

interface BarSparklineProps {
  value: number;
  comparativeValue?: number;
  valueFormatter?: ValueFormatter;
}

export function BarSparkline({
  value,
  comparativeValue = Infinity,
  valueFormatter: formatter,
}: BarSparklineProps) {
  const showComparativeValue = isFinite(comparativeValue);
  const barHeight = 28;
  const borderRadius = 2;
  const midPointLineOverlap = 4;
  const domain = [0, Math.max(comparativeValue * 2, value * 1.1)];
  const valueBarPositionPc = `${Math.max(Math.min(100, (value / domain[1]) * 100), 0)}%`;
  const comparativeValueBarPositionPc = `${(comparativeValue / domain[1]) * 100}%`;
  const overlayText = formatter?.(value) ?? value.toString();
  const comparativeValueOverlayText = showComparativeValue
    ? formatter?.(comparativeValue) ?? comparativeValue.toString()
    : undefined;

  return (
    <Tooltip.Wrapper>
      <Tooltip.Trigger
        className="atlas-pointer-events-auto atlas-relative atlas-w-full"
        style={{ height: barHeight }}
        asChild
      >
        <div>
          <svg
            width="100%"
            height={barHeight}
            xmlns="http://www.w3.org/2000/svg"
            className="atlas-absolute atlas-top-0 atlas-left-0 atlas-w-full atlas-h-full atlas-overflow-visible"
          >
            <rect
              rx={borderRadius}
              x={0}
              y={0}
              width="100%"
              height={barHeight}
              fill="white"
              strokeWidth={2}
              stroke="#E5E7EB"
            />
            <rect
              rx={borderRadius}
              x={0}
              y={0}
              width={valueBarPositionPc}
              height={barHeight}
              fill="#BFDBFE"
              strokeWidth={2}
              stroke="#60A5FB"
              style={{ transition: "width 0.5s ease-in-out" }}
            />
            {showComparativeValue && (
              <line
                x1={comparativeValueBarPositionPc}
                x2={comparativeValueBarPositionPc}
                y1={-midPointLineOverlap}
                y2={barHeight + midPointLineOverlap}
                stroke="#F67216"
                strokeWidth={2}
                strokeDasharray="6 2"
              />
            )}
          </svg>
          <div className="atlas-absolute atlas-top-0 atlas-flex atlas-h-full atlas-items-center atlas-text-sm atlas-neutral-800 atlas-font-medium atlas-pl-[7px]">
            {overlayText}
          </div>
        </div>
      </Tooltip.Trigger>
      {showComparativeValue && (
        <Tooltip.Content align="center" side="top" theme={Theme.Dark} size="sm">
          <span>{comparativeValueOverlayText}</span>
        </Tooltip.Content>
      )}
    </Tooltip.Wrapper>
  );
}
