type MemoryUsage = {
  totalJSHeapSize: number;
  usedJSHeapSize: number;
  jsHeapSizeLimit: number;
};
type Performance = Window["performance"] & { memory?: MemoryUsage };

export function getMemoryUsage() {
  return (window.performance as Performance).memory;
}
