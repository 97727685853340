import { Button } from "@landtechnologies/components";
import classNames from "classnames";
import { Footer } from "react-migration/domains/sites/letters/components/Footer";
import { useTranslation } from "react-migration/lib/i18n/useTranslation";
import { useSkipTraceValidationError } from "../store/hooks/useSkipTraceValidationError";
import { resetSkipTracingStore } from "../store/store";
import { router } from "src/js/router/react-router";
import ROUTES from "src/js/router/routes";
import { updateCurrentStep } from "../store/actions/updateCurrentStep";
import { SkipTraceValidationError } from "./SkipTraceValidationError";
import { CreateSkipTraceOrderStep } from "react-migration/domains/sites/typings/SkipTraceOrderStep";
import { useParcelsLoading } from "../store/hooks/useParcelsLoading";
import logSiteCardEvent from "src/js/view/sites/logSiteCardEvent";

export const SelectSitesStepFooter = () => {
  const { t } = useTranslation();
  const skipTraceValidationError = useSkipTraceValidationError();
  const createSkipTraceOrderInProgress = useParcelsLoading();

  const onCancelClicked = async () => {
    await router.push({ name: ROUTES.SITES });
    logSiteCardEvent({ name: "Skip Trace - Selected Sites Cancel Clicked" });
    resetSkipTracingStore();
  };

  const onNextButtonClicked = () => {
    updateCurrentStep(CreateSkipTraceOrderStep.Summary);
    logSiteCardEvent({ name: "Skip Trace - Selected Sites Confirm Clicked" });
  };

  return (
    <Footer percentage={0} dataTestId="create-skip-trace-footer">
      <div
        className={classNames(
          "atlas-flex",
          "atlas-flex-grow",
          "atlas-justify-between",
          "atlas-h-9"
        )}
      >
        <Button.Secondary onClick={onCancelClicked}>
          {t("sites.skip_tracing.cancel")}
        </Button.Secondary>
        <div
          className={classNames(
            "atlas-flex",
            "atlas-flex-row",
            "atlas-gap-6",
            "atlas-items-center"
          )}
        >
          <SkipTraceValidationError errorMessage={skipTraceValidationError} />
          <Button.Primary
            onClick={onNextButtonClicked}
            disabled={!!skipTraceValidationError || createSkipTraceOrderInProgress}
          >
            {t("sites.skip_tracing.next_confirm_skiptrace")}
          </Button.Primary>
        </div>
      </div>
    </Footer>
  );
};
