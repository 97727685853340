import Feature from "src/js/stores/user/Feature";
import { ConstraintsCategory } from "../../Constraints/types";
import { ConstraintsLayerType } from "../layer_types/ConstraintsLayerType";
import { Layer } from "../types";

export const GREY_BELT: Layer = {
  id: ConstraintsCategory.GREY_BELT,
  layerType: ConstraintsLayerType,
  title: "Grey Belt & Declassified Green Belt",
  requiredFeatureAccess: [Feature.planningPolicyLayer],
  toggleable: true,
  label: { color: "BLUE", translationKey: "map.explore.label.new" },
  description:
    "Land that could become classified as grey belt and areas of green belt that have been declassified",
  hideByDefault: true,
  layerConfig: {
    intersectionThresholdMeters: -1,
    minZoom: 6,
    rootCategory: ConstraintsCategory.GREY_BELT,
    categorySchema: [
      { key: "grey_belt_land_most_favourable" },
      { key: "grey_belt_land_more_favourable" },
      { key: "grey_belt_land_average_favourability" },
      { key: "grey_belt_land_less_favourable" },
      { key: "grey_belt_land_least_favourable" },
      { key: "declassified_green_belt" },
    ],
  },
};
