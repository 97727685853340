<template>
  <div :class="className" ref="container" />
</template>

<script>
import { createElement } from "react";
import { createRoot } from "react-dom/client";

export default {
  inheritAttrs: false,
  props: {
    className: {
      type: String,
      required: false,
    },
    component: {
      type: [Function, Object],
      required: true,
    },
  },
  data() {
    return {
      reactRoot: null,
      textChildren: null,
    };
  },
  methods: {
    updateReactComponent() {
      this.reactRoot.render(createElement(this.component, this.$attrs, this.textChildren));
    },
    createTextChildren() {
      if (!this.$slots.default) return;
      return this.$slots.default.map((slot) => slot.text).filter(Boolean);
    },
  },
  mounted() {
    this.reactRoot = createRoot(this.$refs.container);
    this.textChildren = this.createTextChildren();
    this.updateReactComponent();
  },
  updated() {
    this.textChildren = this.createTextChildren();
  },
  destroyed() {
    this.reactRoot.unmount();
  },
  watch: {
    textChildren() {
      this.updateReactComponent();
    },
    $attrs: {
      deep: true,
      handler() {
        this.updateReactComponent();
      },
    },
  },
};
</script>
