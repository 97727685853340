import { HTMLProps } from "react";
import { Tooltip } from "@landtechnologies/components";

import hasFeature from "src/js/stores/user/actions/hasFeature";
import Feature from "src/js/stores/user/Feature";
import { Button } from "react-migration/components/Button";

import { ArrowRight } from "../../../Icons";

const QuickLinkWrapper = ({ children, ...rest }: HTMLProps<HTMLDivElement>) => {
  return (
    <div className="atlas-flex atlas-flex-col atlas-items-start" {...rest}>
      {children}
    </div>
  );
};

const QuickLinkLink = ({
  children,
  onClick,
  feature,
}: HTMLProps<HTMLButtonElement> & { feature?: Feature }) => {
  return (
    <>
      {(!feature || hasFeature(feature)) && (
        <Button className="atlas-text-platform-600" variant="ghost" onClick={onClick}>
          {children}
        </Button>
      )}
      {feature && !hasFeature(feature) && (
        <Tooltip.Wrapper delayDuration={0}>
          <Tooltip.Trigger
            className="atlas-pointer-events-auto"
            onClick={(event) => event.preventDefault()}
          >
            <Button className="atlas-text-platform-600" variant="ghost" disabled={true}>
              {children} <ArrowRight size={14} />
            </Button>
          </Tooltip.Trigger>
          <Tooltip.Content align="center" side="right" size="sm">
            Not available on your current plan
          </Tooltip.Content>
        </Tooltip.Wrapper>
      )}
    </>
  );
};

export const QuickLink = {
  Wrapper: QuickLinkWrapper,
  Link: QuickLinkLink,
};
