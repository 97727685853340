import { useSnapshot } from "valtio";
import { InfoList, Section, SkeletonLoading } from "@landtechnologies/components";
import { ZooplaComparableDTO } from "@landtechnologies/comparables-services";
import { getMeasurementSystemPreference } from "src/js/stores/user/actions/getSetting";
import { comparablesStore } from "src/js/stores/comparables";
import { SupportedLocale } from "src/js/util/dates";
import { userStore } from "src/js/stores/user/store";
import { useZooplaComparableByUPRN } from "react-migration/domains/comparables/hooks/useZooplaComparableByUPRN";
import { useComparableByUPRN } from "react-migration/domains/comparables/hooks/useComparableByUPRN";
import { writeZooplaAddress } from "react-migration/domains/comparables/util/BuildCSV";
import { formatZooplaData } from "react-migration/domains/comparables/components/Results/Record/helpers/formatZooplaData";
import { LandTechSection } from "react-migration/domains/comparables/components/Results/Record/LandTechSection";
import { useTranslation } from "react-migration/lib/i18n/useTranslation";
import { ZooplaSection } from "react-migration/domains/comparables/components/Results/Record/ZooplaSection";
import { RecordActions } from "react-migration/domains/comparables/components/Results/RecordActions";
import { LayerTypeSelectionDetailViewProps } from "../../types";
import { OtherComparablesSection } from "./OtherComparablesSection";

type ComparablesSelectionDetailViewInnerProps = Readonly<{ record: ZooplaComparableDTO }>;

function ComparablesSelectionDetailViewInner({ record }: ComparablesSelectionDetailViewInnerProps) {
  const { t } = useTranslation();
  const { priceMode } = useSnapshot(comparablesStore);
  const locale = userStore.locale as SupportedLocale;
  const unitPreference = getMeasurementSystemPreference();
  const { data, loading } = useComparableByUPRN({ uprn: record.uprn });

  if (loading) {
    return (
      <div className="atlas-relative">
        <SkeletonLoading rows={8} />;
      </div>
    );
  }

  return (
    <>
      <Section data-testid="record">
        <InfoList>
          <InfoList.Item data-testid="record-item">
            <InfoList.ItemTitle>
              <div className="atlas-flex">
                <div className="atlas-grow">
                  {writeZooplaAddress(record.location.address)
                    .split(", ")
                    .map((line: string) => (
                      <div key={line} className="atlas-text-xs md:atlas-text-sm">
                        {line}
                      </div>
                    ))}
                </div>
              </div>
            </InfoList.ItemTitle>
          </InfoList.Item>

          <ZooplaSection
            data={formatZooplaData({ data: record, locale, unitPreference, t })}
            loading={false}
            record={record}
          />
          {!!data && (
            <LandTechSection record={data} unitPreference={unitPreference} locale={locale} />
          )}
        </InfoList>
      </Section>
      {data ? (
        <div className="atlas-p-2">
          <RecordActions record={data} priceMode={priceMode} />
        </div>
      ) : null}
    </>
  );
}

export function HometrackComparableSelectionDetailView({
  selection,
  setDetailSelection,
}: Readonly<LayerTypeSelectionDetailViewProps>) {
  const { data, loading } = useZooplaComparableByUPRN({ uprn: selection.id });

  if (loading) {
    return (
      <div className="atlas-relative">
        <SkeletonLoading rows={8} />
      </div>
    );
  }

  if (!data) {
    return <div>Could not find record</div>;
  }

  return (
    <div className="atlas-flex atlas-flex-col atlas-gap-y-1 atlas-bg-white">
      <ComparablesSelectionDetailViewInner record={data} />

      <OtherComparablesSection selection={selection} onSelection={setDetailSelection} />
    </div>
  );
}
