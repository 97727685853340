import { generateNewIntl } from "./createIntl";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const translate = (key: string, values?: Record<string, React.ReactNode>): any => {
  const intl = generateNewIntl();

  return intl.formatMessage({ id: key }, values);
};

export const checkKey = (key: string) => {
  return !!generateNewIntl().messages[key];
};

const translationApi = {
  t: translate,
  checkKey,
};

export function useTranslation() {
  return translationApi;
}
