import { MVTLayerProps } from "@deck.gl/geo-layers/dist/mvt-layer/mvt-layer";
import { GeoJsonLayerProps } from "@deck.gl/layers";
import { GeoJsonLayer, MVTLayer, Color, AccessorFunction } from "deck.gl";
import { Feature, Geometry } from "geojson";
import { DesignationFeature } from "react-migration/domains/constraints/components/ConstraintLayer/DesignationLayer";
import { Colour, TRANSPARENT } from "react-migration/domains/constraints/designation/style/colours";

interface PlanningAuthorityLayerProps extends MVTLayerProps<PlanningAuthorityFeature> {
  presumptionInFavourFilter: boolean;
  localPlanAgeFilter: number | undefined;
  housingLandSupplyFilter: number | undefined;
  housingDeliveryTestFilter: number | undefined;
  visibleCategories: string[];
}

interface PlanningAuthorityFeatureProperties extends DesignationFeature {
  designation_attributes: string;
  sub_category_id: string;
  display_name: string;
}

type PlanningAuthorityFeature = GeoJSON.Feature<
  GeoJSON.Polygon,
  PlanningAuthorityFeatureProperties
>;

const noColour = Colour.ORANGE;
const yesColour = Colour.TEAL;
const unknownColour = Colour.GREY;
const fillOpacity = 0.6;

// export class PlanningAuthorityLayer extends MVTLayer<unknown> {
export class PlanningAuthorityLayer extends MVTLayer<
  PlanningAuthorityFeature,
  PlanningAuthorityLayerProps
> {
  private presumptionInFavourFilter: boolean;
  private localPlanAgeFilter: number | undefined;
  private housingLandSupplyFilter: number | undefined;
  private housingDeliveryTestFilter: number | undefined;
  private visibleCategories: string[];

  constructor(props: PlanningAuthorityLayerProps) {
    super(
      {
        binary: true,
        refinementStrategy: "no-overlap",
        renderSubLayers: (props) => this._renderSubLayers(props),
        minZoom: 6,
        maxZoom: 16,
      },
      // @ts-expect-error prop type fails after deck.gl update
      props as MVTLayerProps<PlanningAuthorityFeature>
    );

    this.presumptionInFavourFilter = props.presumptionInFavourFilter;
    this.localPlanAgeFilter = props.localPlanAgeFilter;
    this.housingLandSupplyFilter = props.housingLandSupplyFilter;
    this.housingDeliveryTestFilter = props.housingDeliveryTestFilter;
    this.visibleCategories = props.visibleCategories;
  }

  // @ts-expect-error prop type fails after deck.gl update
  async getTileData(props: PlanningAuthorityLayerProps) {
    // @ts-expect-error prop type fails after deck.gl update
    const r = await super.getTileData(props);
    return r;
  }

  _renderSubLayers(props: GeoJsonLayerProps<PlanningAuthorityFeatureProperties>) {
    // getFillColour has data, gets attrs, passes attrs into hasPif and matchesFilter
    // sets 1st 3 elements of colour array according to hasPif
    // sets opacity according to matchesFilter
    const getFillColour: AccessorFunction<
      Feature<Geometry, PlanningAuthorityFeatureProperties>,
      Color
    > = (data) => {
      if (
        !this.visibleCategories.includes(data.properties.sub_category_id) ||
        !this.presumptionInFavourFilter
      ) {
        return TRANSPARENT;
      }

      const designationAttributes = JSON.parse(data.properties.designation_attributes).reduce(
        (acc: Record<string, string>, curr: KeyValue) => {
          acc[curr.key] = curr.value;
          return acc;
        },
        {}
      );

      const pif = designationAttributes.pif;
      const pifYes = pif == "Yes" || pif.startsWith("Presumption applies");
      const colour = pifYes ? yesColour : pif == "No" ? noColour : unknownColour;

      const opacity = matchesFilter(designationAttributes) ? fillOpacity * 255 : 0;
      return [colour[0], colour[1], colour[2], opacity];
    };

    type KeyValue = { key: string; value: string };
    type PlanningAuthorityDesignationAttributes = {
      "5yhs": string;
      adpt_dat: string;
      hdt21_meas: string;
      pif: string;
    };

    const filterIsNotSet = (filterValue: number | undefined) =>
      filterValue === undefined || Number.isNaN(filterValue);

    const matchesFilter = (designationAttributes: PlanningAuthorityDesignationAttributes) => {
      try {
        const housingLandSupply = designationAttributes["5yhs"];
        const housingDeliveryTest = designationAttributes.hdt21_meas;

        const localPlanDate = new Date(designationAttributes.adpt_dat);
        const unixEpochConversion = 1000 * 60 * 60 * 24 * 365;
        const localPlanAge = (new Date().valueOf() - localPlanDate.valueOf()) / unixEpochConversion;

        const localPlanAgeTrue =
          filterIsNotSet(this.localPlanAgeFilter) || localPlanAge > this.localPlanAgeFilter!;

        const housinglandSupplyTrue =
          filterIsNotSet(this.housingLandSupplyFilter) ||
          Number(housingLandSupply) > this.housingLandSupplyFilter!;

        const housingDeliveryTestTrue =
          filterIsNotSet(this.housingDeliveryTestFilter) ||
          Number(housingDeliveryTest) * 100 > this.housingDeliveryTestFilter!;

        return housinglandSupplyTrue && localPlanAgeTrue && housingDeliveryTestTrue;
      } catch (err) {
        console.error(err);
        return false;
      }
    };

    const getLineColour: AccessorFunction<
      Feature<Geometry, PlanningAuthorityFeatureProperties>,
      Color
    > = (data) => {
      if (!this.visibleCategories.includes(data.properties.sub_category_id)) {
        return TRANSPARENT;
      }
      if (data.properties.sub_category_id === "local_planning_authority_former") {
        return Colour.BROWN;
      }
      if (data.properties.sub_category_id == "local_planning_authority_national_park") {
        return Colour.GREEN;
      }
      return Colour.BLUE;
    };

    return new GeoJsonLayer<PlanningAuthorityFeatureProperties>(props, {
      getFillColor: getFillColour,
      getLineColor: getLineColour,
      lineWidthUnits: "pixels",
      getLineWidth: 2,
      updateTriggers: {
        getFillColor: [
          this.presumptionInFavourFilter,
          this.housingLandSupplyFilter,
          this.housingDeliveryTestFilter,
          this.localPlanAgeFilter,
          this.visibleCategories,
        ],
        getLineColor: [this.visibleCategories],
      },
    });
  }
}
