import { ukPostcodeRegex, usPostcodeRegex } from "./postcodeRegex";
import Feature from "src/js/stores/user/Feature";
import hasFeature from "src/js/stores/user/actions/hasFeature";
import { AvailableCountryCodes } from "../../typings/CountryCode";

export const validatePostcode = (postcode: string) => {
  const isUsUser = hasFeature(Feature.usAccess);
  const regex = isUsUser ? usPostcodeRegex : ukPostcodeRegex;
  return regex.test(postcode);
};

export const getCountryCodeByPostCode = (postCode: string): AvailableCountryCodes | null => {
  if (ukPostcodeRegex.test(postCode)) return AvailableCountryCodes.UNITED_KINGDOM;
  if (usPostcodeRegex.test(postCode)) return AvailableCountryCodes.UNITED_STATES;
  return null;
};
