import React from "react";

export const VisaIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" height="100%" viewBox="0 0 24 16">
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g fillRule="nonzero" transform="translate(-80 -280)">
          <g transform="translate(40 200)">
            <g transform="translate(0 80)">
              <g transform="translate(40)">
                <rect
                  width="23.5"
                  height="15.5"
                  x="0.25"
                  y="0.25"
                  fill="#FFF"
                  stroke="#000"
                  strokeOpacity="0.2"
                  strokeWidth="0.5"
                  rx="2"
                ></rect>
                <path
                  fill="#1434CB"
                  d="M2.788 5.914A7.201 7.201 0 001 5.237l.028-.125h2.737c.371.013.672.125.77.519l.595 2.836.182.854 1.666-4.21h1.799l-2.674 6.167H4.304L2.788 5.914zm7.312 5.37H8.399l1.064-6.172h1.7L10.1 11.284zm6.167-6.021l-.232 1.333-.153-.066a3.054 3.054 0 00-1.268-.236c-.671 0-.972.269-.98.531 0 .29.365.48.96.762.98.44 1.435.979 1.428 1.681-.014 1.28-1.176 2.108-2.96 2.108-.764-.007-1.5-.158-1.898-.328l.238-1.386.224.099c.553.23.917.328 1.596.328.49 0 1.015-.19 1.022-.604 0-.27-.224-.466-.882-.769-.644-.295-1.505-.788-1.491-1.674C11.878 5.84 13.06 5 14.74 5c.658 0 1.19.138 1.526.263zm2.26 3.834h1.415c-.07-.308-.392-1.786-.392-1.786l-.12-.531c-.083.23-.23.604-.223.59l-.68 1.727zm2.1-3.985L22 11.284h-1.575s-.154-.71-.203-.926h-2.184l-.357.926h-1.785l2.527-5.66c.175-.4.483-.512.889-.512h1.316z"
                ></path>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
