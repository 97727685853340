import { ISourcingToolSearchFilter } from "react-migration/domains/search/typings/SourcingToolSpecificFilterTypes";
import { SearchType } from "react-migration/domains/search/constants/searchTypes";

export const TotalWetlandsCoverageUSFilter: ISourcingToolSearchFilter = {
  id: "us-total-wetlands-coverage-id",
  type: "number-range",
  rangeType: "percentage",
  field: "sidebar.react.search_sidebar.total_us_wetlands_coverage",
  must: "BE_WITHIN",
  mustOptions: ["BE_WITHIN"],
  updateFilterDialogIsVisible: false,
  searchTypes: [SearchType.SOURCING_TOOL_US_FL],
  rangeLimit: [0, 1],
  range: [0, 1],
  units: "%",
};
