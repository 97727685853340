import { AvailableCountryCodes } from "../typings/CountryCode";
import { PaperFormat } from "../typings/PaperFormat";

export function getPaperFormat(countryCode?: AvailableCountryCodes): PaperFormat {
  switch (countryCode) {
    case AvailableCountryCodes.UNITED_STATES:
      return PaperFormat.US_LETTER;
    case AvailableCountryCodes.UNITED_KINGDOM:
    default:
      return PaperFormat.A4;
  }
}
