import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { ENVIRONMENT } from "src/js/util/environment";
import { useStripePaymentMethodsQuery } from "./hooks/useStripePaymentMethodsQuery";
import { PaymentForm } from "./components";
import { Loading, Notification } from "@landtechnologies/components";
import { useTranslation } from "react-migration/lib/i18n/useTranslation";
import { DocumentNode } from "@apollo/client";
import { AttemptChargeParams, Mode, PaymentResponse } from "./typings";
import hasFeature from "src/js/stores/user/actions/hasFeature";
import Feature from "src/js/stores/user/Feature";

type StripePaymentProps = {
  showTitle?: boolean;
  amount: number;
  setPaymentComplete?: React.Dispatch<React.SetStateAction<boolean>>;
  attemptCharge: (params: AttemptChargeParams, onCompleted: (res: PaymentResponse) => void) => void;
  queriesToInvalidate?: DocumentNode[];
  errorMessage?: string;
  isFormInvalid: boolean;
  loading: boolean;
  mode?: Mode;
};

export const StripePayment = ({ loading, ...rest }: StripePaymentProps) => {
  const { t } = useTranslation();
  const { data: paymentMethods, loading: paymentsLoading } = useStripePaymentMethodsQuery();

  const stripeKey = hasFeature(Feature.usAccess)
    ? ENVIRONMENT.STRIPE_US_PUBLIC_KEY
    : ENVIRONMENT.STRIPE_PUBLIC_KEY;
  const stripePromise = loadStripe(stripeKey ?? "");

  if (paymentsLoading) {
    return <Loading />;
  }

  if (!paymentMethods) {
    return <Notification.Error title={t("dashboard.something_went_wrong")} icon="info" />;
  }

  return (
    <Elements stripe={stripePromise}>
      <PaymentForm {...rest} paymentMethods={paymentMethods} loading={paymentsLoading || loading} />
    </Elements>
  );
};

export default StripePayment;
