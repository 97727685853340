import { useCallback, useContext, useMemo } from "react";

import { Button } from "@landtechnologies/components";
import { SiteFiltersContext } from "../SiteFilters";
import classNames from "classnames";
import { useTranslation } from "react-migration/lib/i18n/useTranslation";

export interface ClearFiltersButtonProps {
  className?: string;
}

export const ClearFiltersButton = ({ className }: ClearFiltersButtonProps) => {
  const { t } = useTranslation();

  const {
    onFiltersCleared,
    resetSiteFiltersStore,
    useAssignees,
    useLocalAuthorities,
    useSearchString,
    useSiteLabels,
    useStageIds,
  } = useContext(SiteFiltersContext);

  const searchString = useSearchString();
  const assignees = useAssignees();
  const siteLabels = useSiteLabels();
  const localAuthorities = useLocalAuthorities();
  const stageIds = useStageIds();

  const showButton = useMemo(
    () =>
      !!searchString ||
      assignees.length > 0 ||
      siteLabels.length > 0 ||
      localAuthorities.length > 0 ||
      stageIds.length > 0,
    [assignees.length, localAuthorities.length, searchString, siteLabels.length, stageIds.length]
  );

  const clearFilters = useCallback(() => {
    resetSiteFiltersStore();
    onFiltersCleared();
  }, [onFiltersCleared, resetSiteFiltersStore]);

  return showButton ? (
    <Button.Borderless
      data-testid="clear-filters-button"
      className={classNames("atlas-text-content-action", className)}
      onClick={clearFilters}
    >
      {t("sites.pipeline.stages.filters.stages_filters.clear_filters")}
    </Button.Borderless>
  ) : null;
};
