import { useMemo } from "react";
import { FormProvider, useForm } from "react-hook-form";

import { zodResolver } from "@hookform/resolvers/zod";

import {
  SelectRecipientsFormData,
  selectRecipientsFormValidationSchema,
} from "./SelectRecipientsFormSchema";

export interface SelectRecipientsFormProviderProps extends React.PropsWithChildren {
  data?: SelectRecipientsFormData;
}

export const SelectRecipientsFormProvider: React.FC<SelectRecipientsFormProviderProps> = ({
  data,
  children,
}) => {
  const defaultValues = useMemo(() => {
    return (
      data ?? {
        recipients: [],
      }
    );
  }, [data]);

  const methods = useForm<SelectRecipientsFormData>({
    mode: "all",
    resolver: zodResolver(selectRecipientsFormValidationSchema),
    defaultValues,
    values: defaultValues,
  });

  return <FormProvider {...methods}>{children}</FormProvider>;
};
