import { endOfMonth, parse } from "date-fns";
import { DATEFORMAT } from "src/js/util/dates";
import { FilterDCSConditions } from "./FilterDCSConditions";
import { hiddenFieldsByFeature } from "./hiddenFields";
import { ComparablesFilterFields } from "../components/Filters/Filters";
import { ComparablesFilterOptions } from "../typings/Comparables";
import { IComparableSearchArgsGQL } from "@landtechnologies/comparables-services";

export function rangeFilter(min?: number, max?: number) {
  if (!min && !max) {
    return undefined;
  }
  return {
    from: min,
    to: max,
  };
}

export function buildCompsQuery(
  filters?: ComparablesFilterOptions,
  disabledFields?: Set<ComparablesFilterFields>,
  hiddenFields?: Set<ComparablesFilterFields>
): IComparableSearchArgsGQL {
  if (!filters) {
    return {};
  }
  const computedHiddenFields = hiddenFieldsByFeature.reduce((set, [hideCheck, fields]) => {
    if (hideCheck()) {
      return new Set([...set, ...fields]);
    }
    return set;
  }, hiddenFields || new Set<ComparablesFilterFields>());
  const exclude = (filter: ComparablesFilterFields): boolean =>
    (disabledFields?.has(filter) ?? false) || (computedHiddenFields?.has(filter) ?? false);

  return {
    sold_date_range:
      exclude("dateFrom") && exclude("dateTo")
        ? undefined
        : {
            from: exclude("dateFrom")
              ? undefined
              : parse(`01/${filters.dateFrom}`, DATEFORMAT, new Date()),
            to: exclude("dateTo")
              ? undefined
              : endOfMonth(parse(`01/${filters.dateTo}`, DATEFORMAT, new Date())),
          },
    bedrooms: exclude("bedrooms")
      ? undefined
      : rangeFilter(filters.bedrooms?.min, filters.bedrooms?.max),
    total_floor_area_range: exclude("floorArea")
      ? undefined
      : rangeFilter(filters.floorArea?.min, filters.floorArea?.max),
    tenure: exclude("tenure") || filters.tenure === "Any" ? undefined : filters.tenure,
    // there's currently no option in the UI to exclude new  builds. It's new builds only OR everything
    is_new_property: exclude("newBuildsOnly") || !filters.newBuildsOnly ? undefined : true,
    dcs_conditions: exclude("dcsConditions")
      ? undefined
      : FilterDCSConditions(filters.dcsConditions, disabledFields, hiddenFields)?.map(
          ({ designation, category, subcategory }) => ({
            designation,
            category,
            sub_category: subcategory,
          })
        ),
    year_built:
      (exclude("yearBuiltFrom") && exclude("yearBuiltTo")) ||
      (!filters.yearBuiltFrom && !filters.yearBuiltTo)
        ? undefined
        : {
            from:
              exclude("yearBuiltFrom") || !filters.yearBuiltFrom
                ? undefined
                : filters.yearBuiltFrom,
            to: exclude("yearBuiltTo") || !filters.yearBuiltTo ? undefined : filters.yearBuiltTo,
          },
  };
}
