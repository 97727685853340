import { Polygon, MultiPolygon } from "@turf/helpers";
import { cleanCoords } from "@turf/turf";
import booleanValid from "@turf/boolean-valid";
import buffer from "@turf/buffer";
import { SiteGeometry } from "../../typings/Site";

type ParsedSiteGeometry = SiteGeometry | undefined | false;

function fixInvalidPolygonBasedGeometry(geometry: Polygon | MultiPolygon): ParsedSiteGeometry {
  // Step 1 - Fix via `cleanCoords`
  console.warn("Invalid `SiteGeometry` fix via `cleanCoords`", geometry);
  const cleanedGeometry = cleanCoords(geometry);
  if (booleanValid(cleanedGeometry)) return cleanedGeometry;

  // Step 2 - Fix via `buffer`
  console.warn("Invalid `SiteGeometry` fix via `buffer`", geometry);
  const bufferedGeometry = buffer(geometry, 1, { units: "millimeters" }).geometry;
  if (booleanValid(bufferedGeometry)) return bufferedGeometry;

  // Step 3 - Unable to fix geometry - return false
  return false;
}

/**
 * `parseSiteGeometry` returns one of three values:
 *  - `undefined` if the geometry is not provided
 *  - `Geometry` if the geometry is valid / has been fixed
 *  - `false` if the geometry is invalid and cannot be fixed
 */

/**
 * Parses the given site geometry and returns a parsed site geometry.
 *
 * @param {SiteGeometry} [geometry] - The site geometry to parse.
 * @returns {ParsedSiteGeometry} `undefined` if the geometry is not provided.
 * `Geometry` if the geometry is valid / has been fixed. `false` if the geometry
 * is invalid and cannot be fixed.
 */
export function parseSiteGeometry(geometry?: SiteGeometry): ParsedSiteGeometry {
  if (!geometry) return undefined;
  if (booleanValid(geometry)) return geometry;

  switch (geometry.type) {
    case "Polygon":
    case "MultiPolygon": {
      return fixInvalidPolygonBasedGeometry(geometry);
    }

    default: {
      return false;
    }
  }
}
