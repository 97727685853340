import { useCallback } from "react";
import {
  SingleRecipient,
  singleRecipientValidationSchema,
} from "./SingleRecipientValidationSchema";
import { useFormContext } from "react-hook-form";
import { EditableTableCell } from "../../../../components/EditableTableCell";
import { SelectRecipientsFormData } from "./SelectRecipientsFormSchema";
import { RecipientCell } from "../../letters/components/RecipientsTable/components/RecipientCell";
import { kebabCase, snakeCase } from "lodash";
import { useTranslation } from "react-migration/lib/i18n/useTranslation";

type SelectRecipientsFormCellProps = {
  recipient: SingleRecipient;
  displayingProperty: keyof SingleRecipient;
  rowIndex: number;
};

export const SelectRecipientsFormCell = ({
  recipient,
  displayingProperty,
  rowIndex,
}: SelectRecipientsFormCellProps) => {
  const { t } = useTranslation();

  const {
    register,
    formState: { errors },
    resetField,
  } = useFormContext<SelectRecipientsFormData>();

  const getFieldError = useCallback(
    ({ isGranted }: SingleRecipient, rowIdx: number, field: keyof SingleRecipient) => {
      const error = errors.recipients?.[rowIdx]?.[field]?.message;
      if (!isGranted || !error) {
        return;
      }
      return t(error);
    },
    [errors.recipients, t]
  );

  const getFieldHelpText = useCallback(
    ({ isGranted }: SingleRecipient) => {
      return !isGranted && t("sites.letters.recipients.unavailable_for_letter_sending");
    },
    [t]
  );

  const getFieldHelpTooltipText = useCallback(
    (recipient: SingleRecipient) => {
      if (displayingProperty !== "fullName") return;

      const recipientIsValid = singleRecipientValidationSchema.safeParse(recipient);
      if (!recipient.isGranted) {
        return t("sites.letters.recipients.owner_details_unavailable");
      } else if (!recipientIsValid.success && recipient.fullName?.includes("Unknown")) {
        return t("sites.letters.recipients.no_title");
      } else if (!recipientIsValid.success && !recipient.fullName?.includes("Unknown")) {
        return t("sites.letters.recipients.incomplete");
      } else {
        return;
      }
    },
    [t, displayingProperty]
  );

  if (recipient.isGranted) {
    return (
      <EditableTableCell
        data-testid={`${kebabCase(displayingProperty)}-input-${rowIndex}`}
        label={t(`sites.letters.recipients.${snakeCase(displayingProperty)}`)}
        error={getFieldError(recipient, rowIndex, displayingProperty)}
        help={getFieldHelpText(recipient)}
        helpTooltip={getFieldHelpTooltipText(recipient)}
        className={"!atlas-bg-transparent !hover:atlas-bg-transparent"}
        onEscPressed={() => {
          resetField(`recipients.${rowIndex}.${displayingProperty}`);
        }}
        {...register(`recipients.${rowIndex}.${displayingProperty}`)}
      />
    );
  } else if (!recipient.isGranted && displayingProperty !== "fullName") {
    return (
      <div
        data-testid={`${kebabCase(displayingProperty)}-display-value-${rowIndex}`}
        className="atlas-p-6"
      >
        {recipient[displayingProperty]}
      </div>
    );
  } else {
    return <RecipientCell recipient={recipient} colKey={displayingProperty} />;
  }
};
