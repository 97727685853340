import { ISourcingToolSearchFilter } from "react-migration/domains/search/typings/SourcingToolSpecificFilterTypes";
import { sourcingToolSearchTypes } from "react-migration/domains/search/constants/searchTypes";
import { ENVIRONMENT } from "src/js/util/environment";

export const ProprietorNameFilter: ISourcingToolSearchFilter = {
  id: "proprietor-name",
  type: "text",
  field: "sidebar.react.search_sidebar.owner_name",
  text: "",
  must: "CONTAIN",
  mustOptions: ["CONTAIN", "NOT_CONTAIN"],
  updateFilterDialogIsVisible: false,
  searchTypes: sourcingToolSearchTypes,
  autoCompleteConfig: {
    basePath: `${ENVIRONMENT.MCSEARCH_SERVICE_URL}/autocomplete/{type}/{limit}/{searchText}`,
    variables: {
      type: `owner_name`,
      limit: 5,
    },
    searchVariableName: "searchText",
  },
};
