import { AppealStatus } from "../types";

export function getAppealStatus(decision: string) {
  switch (decision) {
    case "Notice Quashed":
    case "Quashed On Legal Grounds":
    case "Planning permission granted":
    case "Allowed in part":
    case "Split Decision":
    case "Allowed with Conditions":
    case "Allowed":
      return AppealStatus.PERMITTED;
    case "Notice upheld":
    case "Notice varied and upheld":
    case "Invalid":
    case "Dismissed":
    case "Turned Away":
      return AppealStatus.DISMISSED;
    case "Appeal Withdrawn":
      return AppealStatus.WITHDRAWN;
    default:
      return AppealStatus.UNKNOWN;
  }
}
