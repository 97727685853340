import {
  Switch,
  IconButton,
  Modal,
  Button,
  DeprecatedTooltip,
  Theme,
} from "@landtechnologies/components";
import { PlanningAlert } from "../../../typings/PlanningAlert";
import { dateString, SupportedLocale } from "util/dates";
import { i18n } from "src/js/i18n";
import ROUTES from "src/js/router/routes";
import { router } from "src/js/router/react-router";
import { useTranslation } from "react-migration/lib/i18n/useTranslation";
import { twMerge } from "tailwind-merge";
import { useUpdatePlanningAlertSubscriptionMutation } from "../../hooks/useUpdatePlanningAlertSubscriptionMutation";
import { useDeletePlanningAlertSubscriptionMutation } from "../../hooks/useDeletePlanningAlertSubscriptionMutation";
import { useMemo, useState } from "react";
import { filtersForSearch } from "src/js/util/planning_search";
import { getParamFromQueryParams } from "../../util/helpers";
import { handleRedirectToAlerts } from "../../util/redirects";
import { PencilIcon } from "react-migration/domains/dashboard/icons/PencilIcon";
import { DeleteIcon } from "react-migration/components/DeleteIcon";

type PlanningAlertRowProps = {
  planningAlert: PlanningAlert;
  onEdit: () => void;
  detailed?: boolean;
  totalNumber?: number;
};

export const PlanningAlertRow = ({
  planningAlert,
  onEdit,
  detailed,
  totalNumber,
}: PlanningAlertRowProps) => {
  const { t } = useTranslation();
  const [update] = useUpdatePlanningAlertSubscriptionMutation();
  const [deleteAlert] = useDeletePlanningAlertSubscriptionMutation();
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const email = getParamFromQueryParams("email");
  const filters = useMemo(() => {
    return filtersForSearch(planningAlert.search, {
      locale: i18n.locale,
    });
  }, [planningAlert]);
  const handleSwitch = async (e: React.ChangeEvent<HTMLInputElement>) => {
    e.stopPropagation();
    await update({
      variables: {
        _id: planningAlert._id,
        subscribed: !planningAlert.subscribed,
      },
    });
  };
  const handleOpenDeleteModal = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    setIsDeleteModalOpen(true);
  };

  const handleDelete = async () => {
    await deleteAlert({ variables: { _id: planningAlert._id } });
    setIsDeleteModalOpen(false);
    detailed && handleRedirectToAlerts(planningAlert);
  };

  const handleCancel = () => {
    setIsDeleteModalOpen(false);
  };

  const handleRowClick = () => {
    if (!detailed) {
      const params = {
        name: ROUTES.DASHBOARD_PLANNING_ALERT_DETAILS,
        params: {
          alert_id: planningAlert._id,
        },
        query: {},
      };
      if (email) params.query = { email: email };
      router.push(params);
    }
  };

  const handleEdit = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    onEdit();
  };

  const containerClasses = twMerge(
    "atlas-flex atlas-justify-between atlas-items-center atlas-border-b atlas-border-neutral-200 atlas-p-4 first:atlas-rounded-t",
    detailed && "atlas-bg-neutral-50",
    !detailed && "atlas-cursor-pointer last:atlas-rounded-b"
  );

  return (
    <>
      <div
        data-testid={`planning-alert-row-${planningAlert._id}`}
        onClick={handleRowClick}
        className={containerClasses}
      >
        <div>
          <div className="atlas-flex atlas-items-baseline">
            <div className="atlas-font-semibold atlas-text-base atlas-text-blue-600 atlas-mr-2">
              {planningAlert.name}
            </div>
            <div className="atlas-font-semibold atlas-text-neutral-600 atlas-mr-1">
              {detailed ? totalNumber : planningAlert.results_count}
            </div>
            <div className="atlas-text-neutral-600">{t("dashboard.planning_alerts.results")}</div>
          </div>
          <div className="atlas-text-xs atlas-text-neutral-600">
            {t("dashboard.planning_alerts.created", {
              date: dateString(planningAlert.created, i18n.locale as SupportedLocale),
            })}
          </div>
          {detailed && (
            <div>
              {filters.length ? (
                <div className="atlas-flex atlas-gap-2 atlas-flex-wrap atlas-mt-2">
                  {filters.map((filter) => (
                    <div
                      className="atlas-py-1 atlas-px-2 atlas-rounded atlas-text-white"
                      style={{ backgroundColor: filter.backgroundColor }}
                      key={filter.id}
                    >
                      {filter.text}
                    </div>
                  ))}
                </div>
              ) : (
                <div className="atlas-mt-2 atlas-font-semibold atlas-text-neutral-600">
                  {t("dashboard.planning_alerts.no_search_criteria")}
                </div>
              )}
            </div>
          )}
        </div>
        <div className="atlas-flex atlas-gap-4">
          <Switch
            id={planningAlert._id}
            onToggle={handleSwitch}
            label=""
            value={planningAlert.subscribed}
          />
          <DeprecatedTooltip
            theme={Theme.Dark}
            title={t("dashboard.planning_alerts.edit_alert_tooltip")}
            placement="bottom"
          >
            <IconButton action={handleEdit} dataTestId={`planning-alert-edit-${planningAlert._id}`}>
              <span className="atlas-text-neutral-500 hover:atlas-text-blue-600 ">
                <PencilIcon />
              </span>
            </IconButton>
          </DeprecatedTooltip>
          <DeprecatedTooltip
            theme={Theme.Dark}
            title={t("dashboard.planning_alerts.delete_alert_tooltip")}
            placement="bottom"
          >
            <IconButton
              action={handleOpenDeleteModal}
              dataTestId={`planning-alert-delete-${planningAlert._id}`}
            >
              <span className="atlas-text-neutral-500 hover:atlas-text-blue-600 ">
                <DeleteIcon />
              </span>
            </IconButton>
          </DeprecatedTooltip>
        </div>
      </div>
      {isDeleteModalOpen && (
        <div className="atlas-fixed ">
          <Modal
            description={t("dashboard.planning_alerts.delete_modal_title")}
            title={t("dashboard.planning_alerts.warning")}
          >
            <div className="atlas-flex atlas-gap-2 atlas-justify-end">
              <Button.Danger onClick={handleDelete}>
                {t("dashboard.planning_alerts.delete_alert_subscription")}
              </Button.Danger>
              <Button.Secondary onClick={handleCancel}>
                {t("dashboard.planning_alerts.cancel")}
              </Button.Secondary>
            </div>
          </Modal>
        </div>
      )}
    </>
  );
};
