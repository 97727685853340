import { RefObject, useEffect, useMemo, useRef, useState } from "react";

import { ReportSection } from "../../card/SiteReport/Sections";
import { SiteOverview } from "../../typings/Site";
import { useSiteById } from "../useSiteById";
import { router } from "src/js/router/react-router";
import ROUTES from "src/js/router/routes";
import { SiteReportStoredLogo } from "../../typings/SiteReportStoredLogo";

const REPORT_SECTION_SCROLL_ORDER = [
  ReportSection.COMPARABLES,
  ReportSection.DESIGNATIONS,
  ReportSection.PLANNING,
  ReportSection.OWNERSHIP,
];

type ReportLoadStatus = { [key in ReportSection]?: boolean };

export type SectionRefRecord = Record<ReportSection, HTMLDivElement | null>;

export type useSiteReportResult = {
  site?: SiteOverview;
  notes: string;
  onReportSectionLoaded: (reportName: ReportSection) => void;
  isVisible: (sectionName: ReportSection) => boolean;
  isDesignationReportVisible: boolean;
  visibleSections: ReportSection[];
  printViewClassNames: string;
  loading: boolean;
  logoUrl: string | null;
  navigateBackToSiteCard: () => void;
  reportSectionRefs?: RefObject<SectionRefRecord>;
};

export function useSiteReport(
  siteCardId: string | null,
  visibleReports: ReportSection[],
  trigger?: number
): useSiteReportResult {
  const params = useMemo(() => new URLSearchParams(document.location.search), []);
  const [notes, setNotes] = useState<string>(
    params.get("notes") ? String(params.get("notes")) : ""
  );
  const isPrintView = Boolean(params.get("isPrint"));

  const siteId = siteCardId ?? params.get("siteId");

  const { site } = useSiteById(siteId);
  useEffect(() => {
    if (!site?.notes) return;
    setNotes(site.notes);
  }, [site]);

  const visibleSections = useMemo(() => {
    if (isPrintView) {
      return (params.get("reports")?.split(",") ?? []) as ReportSection[];
    }
    return visibleReports;
  }, [isPrintView, params, visibleReports]);

  const [logoUrl, setLogoUrl] = useState<string | null>(null);

  const reportSectionRefs = useRef<SectionRefRecord>({} as SectionRefRecord);

  useEffect(() => {
    const storedLogo = localStorage.getItem(`${siteId}_logo`);
    const logoData = storedLogo ? (JSON.parse(storedLogo) as SiteReportStoredLogo).data : null;
    setLogoUrl(logoData);
  }, [trigger, siteId]);

  const [reportLoadStatus, setReportLoadStatus] = useState<ReportLoadStatus>({});

  const onReportSectionLoaded = (reportName: ReportSection) => {
    if (isPrintView && reportSectionRefs?.current[reportName]) {
      (reportSectionRefs?.current[reportName] as HTMLDivElement).scrollIntoView({
        behavior: "smooth",
        block: "end",
      });
    }

    setReportLoadStatus((prev) => {
      if (!Object.keys(prev).includes(reportName)) {
        return { ...prev, [reportName]: true };
      }
      return prev;
    });
  };

  const isVisible = (sectionName: ReportSection) => visibleSections.includes(sectionName);

  const isReportSectionReadyToPrint = (sectionName: ReportSection) =>
    !isVisible(sectionName) || reportLoadStatus[sectionName];

  // NOTE: we need to manually scroll up when all containers loaded,
  // otherwise the print view is not triggered due to too many opened deckgl layers
  useEffect(() => {
    const areReportSectionContainersReadyToPrint = REPORT_SECTION_SCROLL_ORDER.every(
      (sectionName) => isReportSectionReadyToPrint(sectionName)
    );

    if (isPrintView && areReportSectionContainersReadyToPrint) {
      REPORT_SECTION_SCROLL_ORDER.forEach((reportName) => {
        if (isVisible(reportName)) {
          (reportSectionRefs?.current[reportName] as HTMLDivElement).scrollIntoView({
            behavior: "smooth",
            block: "end",
          });
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPrintView, reportSectionRefs?.current, reportLoadStatus, isReportSectionReadyToPrint]);

  const isUSDesignationSectionsVisible = [
    ReportSection.ZONING,
    ReportSection.HAZARDS,
    ReportSection.DEMOGRAPHICS,
    ReportSection.PERMITS,
  ].some(isVisible);
  const isDesignationReportVisible =
    isVisible(ReportSection.DESIGNATIONS) ||
    isUSDesignationSectionsVisible ||
    isVisible(ReportSection.POWER);

  const isDesignationReadyToPrint =
    !isDesignationReportVisible || reportLoadStatus[ReportSection.DESIGNATIONS];

  const isPrintViewLoaded =
    !isPrintView ||
    (reportLoadStatus[ReportSection.BOUNDARIES_SATELLITE] &&
      reportLoadStatus[ReportSection.BOUNDARIES_ROADMAP] &&
      isReportSectionReadyToPrint(ReportSection.OWNERSHIP) &&
      isReportSectionReadyToPrint(ReportSection.PLANNING) &&
      isDesignationReadyToPrint &&
      isReportSectionReadyToPrint(ReportSection.COMPARABLES));

  useEffect(() => {
    if (isPrintView && isPrintViewLoaded) {
      window.print();
    }
  }, [isPrintViewLoaded, isPrintView]);

  const navigateBackToSiteCard = () => {
    router.push({
      name: ROUTES.SITES,
      query: {
        card_page: "site_report",
        site_id: siteId,
      },
    });
  };

  useEffect(() => {
    window.addEventListener("afterprint", navigateBackToSiteCard);

    return () => {
      window.removeEventListener("afterprint", navigateBackToSiteCard);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const printViewClassNames = isPrintView
    ? "atlas-invisible print:atlas-visible atlas-w-1/2 print:atlas-w-full"
    : "atlas-visible";

  return {
    site,
    notes,
    onReportSectionLoaded,
    isVisible,
    isDesignationReportVisible,
    visibleSections,
    printViewClassNames,
    loading: isPrintView && !isPrintViewLoaded,
    logoUrl,
    navigateBackToSiteCard,
    reportSectionRefs,
  };
}
