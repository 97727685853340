import { useQuery } from "@apollo/client";
import { useMemo, useRef } from "react";
import { LandTechEndpoints, routedClient } from "react-migration/lib/persistence/apollo";
import ZOOPLA_LISTINGS_BY_UPRNS from "../apollo/zooplaListingsByUPRNs.gql";
import { ZooplaListingsDTO } from "../typings/Zoopla";

interface ZooplaListingsHookResponse {
  uprns?: string[];
  loading: boolean;
  data?: ZooplaListingsDTO[];
}

interface ZooplaListingsResult {
  zooplaListingsByUPRNs: ZooplaListingsDTO[];
}

export function useZooplaListingsByUPRNs({
  uprns,
  skip,
}: {
  uprns?: string[];
  skip?: boolean;
}): ZooplaListingsHookResponse {
  const { loading, data } = useQuery<ZooplaListingsResult>(ZOOPLA_LISTINGS_BY_UPRNS, {
    variables: {
      uprns,
    },
    client: routedClient,
    skip: !uprns || skip,
    context: {
      endpoint: LandTechEndpoints.Gateway,
    },
  });

  const lastResult = useRef(data?.zooplaListingsByUPRNs);

  if (data !== lastResult.current) {
    lastResult.current = data?.zooplaListingsByUPRNs;
  }
  return useMemo(
    () => ({
      loading,
      data: lastResult.current,
      uprns,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [lastResult.current, loading]
  );
}
