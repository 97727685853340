import { useMutation } from "@apollo/client";
import { StripePaymentResponse } from "react-migration/domains/constraints/typings/applicationTypes/StripeTypes";
import { routedClient } from "react-migration/lib/persistence/apollo/routedClient";
import TOPUP_BALANCE from "../apollo/mutations/topupBalance.gql";

export const useTopupBalanceMutation = () => {
  return useMutation<StripePaymentResponse>(TOPUP_BALANCE, {
    client: routedClient,
  });
};
