import classNames from "classnames";
import { useMemo } from "react";
import { Recipient } from "react-migration/domains/sites/typings/Recipient";
import { useTranslation } from "react-migration/lib/i18n/useTranslation";
import { useSnapshot } from "valtio";
import Feature from "src/js/stores/user/Feature";
import hasFeature from "src/js/stores/user/actions/hasFeature";

import { Placeholder } from "./Placeholder";
import { letterStore } from "src/js/stores/letter/store";

const AddressLine = ({ value }: { value: string | undefined }) => {
  return (
    <>
      {value && (
        <>
          {value}
          <br />
        </>
      )}
    </>
  );
};

interface HeadingProps {
  showValues?: boolean;
}

export const Heading = ({ showValues }: HeadingProps) => {
  const usAccess = hasFeature(Feature.usAccess);
  const { t } = useTranslation();
  const {
    recipients,
    letter: { senderAddressLine1, senderCity, senderName, senderPostcode, senderProvince, logo },
    activeRecipientId,
  } = useSnapshot(letterStore);

  const { fullName, addressLine1, city, region, postCode } =
    recipients?.find((recipient: Recipient) => recipient._id === activeRecipientId) || {};

  const addressClassName = useMemo(() => {
    const a4ClassName = classNames("atlas-w-[90mm]", "atlas-h-[45mm]", "atlas-mt-[19mm]");
    const letterClassName = classNames("atlas-w-[3.25in]", "atlas-h-[2.3in]", "atlas-mt-[-0.28in]");
    return classNames("atlas-flex", usAccess ? letterClassName : a4ClassName);
  }, [usAccess]);

  const addressSection = useMemo(() => {
    return usAccess ? (
      <div
        className={classNames(
          addressClassName,
          "atlas-flex",
          "atlas-flex-col",
          "atlas-justify-between"
        )}
      >
        <div>
          <AddressLine value={senderName} />
          <AddressLine value={senderAddressLine1} />
          <AddressLine value={`${senderCity}, ${senderProvince} ${senderPostcode}`} />
        </div>
        <div>
          <AddressLine value={fullName} />
          <AddressLine value={addressLine1} />
          <AddressLine value={`${city}, ${region} ${postCode}`} />
        </div>
      </div>
    ) : (
      <div className={addressClassName}>
        <AddressLine value={fullName} />
        <AddressLine value={addressLine1} />
        <AddressLine value={city} />
        <AddressLine value={region} />
        <AddressLine value={postCode} />
      </div>
    );
  }, [
    addressClassName,
    addressLine1,
    city,
    fullName,
    postCode,
    region,
    senderAddressLine1,
    senderCity,
    senderName,
    senderPostcode,
    senderProvince,
    usAccess,
  ]);

  return (
    <div
      className={classNames("atlas-flex", "atlas-justify-between")}
      data-testid="letter-preview-heading"
    >
      {showValues ? (
        addressSection
      ) : (
        <Placeholder className={classNames(addressClassName)}>
          <div
            className={classNames(
              "atlas-flex",
              "atlas-flex-col",
              "atlas-items-center",
              "atlas-justify-center"
            )}
          >
            {t("sites.letters.recipient_address")}
          </div>
        </Placeholder>
      )}
      {showValues || logo?.url ? (
        <div
          className={classNames(
            "atlas-flex",
            "atlas-items-center",
            "atlas-justify-center",
            "atlas-h-[156px]",
            "atlas-w-[156px]",
            "atlas-bg-contain",
            "atlas-overflow-hidden"
          )}
        >
          <img
            className={classNames("atlas-h-auto", "atlas-w-full", "atlas-object-center")}
            src={logo?.url}
          />
        </div>
      ) : (
        <Placeholder className={classNames("atlas-h-[156px]", "atlas-w-[156px]")}>
          <div
            className={classNames(
              "atlas-flex",
              "atlas-flex-col",
              "atlas-items-center",
              "atlas-justify-center"
            )}
          >
            <i className="icon-lt-image-line atlas-text-5xl atlas-text-neutral-400" />
            {t("sites.letters.logo")}
          </div>
        </Placeholder>
      )}
    </div>
  );
};
