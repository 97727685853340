import classNames from "classnames";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-migration/lib/i18n/useTranslation";
import { TextInput } from "react-migration/components/TextInput";
import { labelClassNames } from "../style/labelClassNames";
import { AccordionAnimationContainer } from "./FormContent";
import { SenderFormSection } from "./SenderFormSection";

export const FooterSection = () => {
  const { t } = useTranslation();

  const { register } = useFormContext();

  return (
    <AccordionAnimationContainer keyName={"footer-section-content"}>
      <div data-testid="template-form-footer-accordion-content" className="atlas-px-3 atlas-pt-4">
        <TextInput
          label={t("sites.letters.letter_form.vat_registration_number_field.label")}
          labelClassName={classNames(labelClassNames)}
          {...register("vatRegistrationNumber")}
        />
        <TextInput
          label={t("sites.letters.letter_form.company_registration_number_field.label")}
          labelClassName={classNames(labelClassNames)}
          {...register("companyRegistrationNumber")}
        />
        <SenderFormSection />
      </div>
    </AccordionAnimationContainer>
  );
};
