import { useState, useCallback } from "react";
import { useMutation } from "@apollo/client";
import {
  LandTechEndpoints,
  routedClientWithoutTypenames,
} from "react-migration/lib/persistence/apollo";
import SEND_SITE_LETTERS from "react-migration/domains/sites/apollo/sites-api/mutations/sendSiteLetters.gql";
import { captureException } from "src/js/util/monitoring";
import { LetterEventName, logLetterEvent } from "../../letters/helpers/logLetterEvent";
import { toCreateLetterContentDTO } from "../../helpers/toCreateLetterContentDTO";
import { siteStore } from "src/js/stores/site/store";
import { useSnapshot } from "valtio";
import { letterStore } from "src/js/stores/letter/store";
import { SendLettersResponseData } from "../../typings/apollo";
import { SiteLetterInput } from "../../typings/Letter";
import { getPaperFormat } from "../../helpers/getPaperFormat";

export enum SendStatus {
  SENDING = "sending",
  SENT = "sent",
  ERROR = "error",
}

export type UseSendLettersHook = {
  sendStatus?: SendStatus | null;
  sendLetters: () => Promise<void>;
  clearSendStatus: () => void;
};

export function useSendLetters(): UseSendLettersHook {
  const { siteCardId } = useSnapshot(siteStore);
  const {
    recipients,
    selectedRecipientIds,
    selectedStageId,
    siteBoundaryImageUrlBySiteId,
    ...letterProps
  } = useSnapshot(letterStore);

  const [sendStatus, setSendStatus] = useState<SendStatus | null>(null);

  const [sendLettersRequest] = useMutation<SendLettersResponseData>(SEND_SITE_LETTERS, {
    client: routedClientWithoutTypenames,
    context: {
      endpoint: LandTechEndpoints.Sites,
    },
  });

  const clearSendStatus = () => setSendStatus(null);

  const sendLetters = useCallback(async () => {
    setSendStatus(SendStatus.SENDING);

    const distinctSiteIds = new Set();
    const siteLetterInputs: SiteLetterInput[] = (recipients ?? [])
      .filter((recipient) => selectedRecipientIds?.includes(recipient._id))
      .map((recipient) => {
        // side effect - naughty
        distinctSiteIds.add(recipient.siteId);
        const siteBoundaryImage = siteBoundaryImageUrlBySiteId[recipient.siteId];
        return toCreateLetterContentDTO(
          {
            ...letterProps,
            siteBoundaryImage,
          },
          recipient,
          {
            hideRecipientAddress: true,
            paperFormat: getPaperFormat(recipient.countryCode),
          }
        );
      });

    try {
      const { data } = await sendLettersRequest({
        variables: { siteLetterInputs },
      });
      setSendStatus(SendStatus.SENT);
      const siteLettersResponses = data?.sendSiteLetters;
      if (siteLettersResponses?.length) {
        logLetterEvent({
          name: LetterEventName.LETTERS_FORM_FINISH_AND_SEND_STEP_SENT_SUCCESSFULLY,
          context: {
            siteCount: distinctSiteIds.size,
            letterCount: siteLetterInputs.length,
          },
        });
      }
    } catch (error) {
      setSendStatus(SendStatus.ERROR);
      logLetterEvent({
        name: LetterEventName.LETTERS_FORM_FINISH_AND_SEND_STEP_SEND_FAILED,
        context: {
          siteCount: distinctSiteIds.size,
          letterCount: siteLetterInputs.length,
        },
      });
      captureException(error, {
        info: "Send letter failure",
        siteId: siteCardId,
        stageId: selectedStageId,
        letter: letterProps.letter,
        letterName: letterProps.letterName,
        pageCount: letterProps.pageCount,
      });
    }
  }, [
    recipients,
    selectedRecipientIds,
    siteBoundaryImageUrlBySiteId,
    letterProps,
    sendLettersRequest,
    siteCardId,
    selectedStageId,
  ]);

  return {
    sendStatus,
    clearSendStatus,
    sendLetters,
  };
}
