import { useCallback, useState } from "react";
import { ParcelSizeRange, useOwnershipLayerTypeContext } from "./Context";
import { OwnershipFilters } from "react-migration/domains/ownership/components/OwnershipFilters";
import { useSnapshot } from "valtio";
import { useTranslation } from "react-migration/lib/i18n/useTranslation";
import { Checkbox, CheckboxState, Switch } from "@landtechnologies/components";
import { OwnershipLegend } from "react-migration/domains/ownership/components/OwnershipLegend";
import { LayerTypeControlPageProps } from "../../types";
import clsx from "clsx";
import hasFeature from "src/js/stores/user/actions/hasFeature";
import Feature from "src/js/stores/user/Feature";
import { userStore } from "src/js/stores/user/store";
import { useControlTracking } from "../../ControlTrackingContext";
import { Button } from "react-migration/components/Button";

function useOwnershipControls() {
  const { debouncedTrackControlChange, trackControlChange } = useControlTracking();
  const {
    setParcelSizeRange,
    parcelSizeRange,
    setShowKnownOwners,
    showKnownOwners,
    tenureFilters,
  } = useOwnershipLayerTypeContext();

  const handleKnownOwnersChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      debouncedTrackControlChange({ filterName: "knownOwners", filterState: event.target.checked });
      setShowKnownOwners(event.target.checked);
    },
    [setShowKnownOwners, debouncedTrackControlChange]
  );

  const handleParcelSizeRangeChange = useCallback(
    (parcelSize: ParcelSizeRange) => {
      debouncedTrackControlChange({
        filterName: "parcelSizeRange",
        filterState: parcelSize,
      });
      setParcelSizeRange(parcelSize);
    },
    [setParcelSizeRange, debouncedTrackControlChange]
  );

  const handleShowLeaseholdChange = useCallback(
    (v: boolean) => {
      trackControlChange({
        filterName: "showLeaseholds",
        filterState: v,
      });
      tenureFilters.setShowLeaseholds(v);
    },
    [tenureFilters, trackControlChange]
  );

  const handleShowFreeholdChange = useCallback(
    (v: boolean) => {
      trackControlChange({
        filterName: "showFreeholds",
        filterState: v,
      });
      tenureFilters.setShowFreeholds(v);
    },
    [tenureFilters, trackControlChange]
  );

  return {
    parcelSizeRange,
    showKnownOwners,
    tenureFilters,
    handleKnownOwnersChange,
    handleParcelSizeRangeChange,
    handleShowLeaseholdChange,
    handleShowFreeholdChange,
  };
}

export function OwnershipControlPage({ disabled }: LayerTypeControlPageProps) {
  const { t } = useTranslation();
  const {
    parcelSizeRange,
    showKnownOwners,
    tenureFilters,
    handleKnownOwnersChange,
    handleParcelSizeRangeChange,
    handleShowLeaseholdChange,
    handleShowFreeholdChange,
  } = useOwnershipControls();

  const {
    user: {
      settings: { unit },
    },
  } = useSnapshot(userStore);

  const [showKnownOwnersModal, setShowKnownOwnersModal] = useState<boolean>(false);
  const isUSUser = hasFeature(Feature.usAccess);

  const showTenureFilters = !isUSUser;

  return (
    <div className="atlas-space-y-4">
      {showTenureFilters && (
        <>
          <div className="atlas-space-y-2">
            <div className="atlas-flex atlas-justify-between atlas-items-center atlas-pb-4 atlas-relative atlas-border-b atlas-border-neutral-3">
              <label
                htmlFor="known-owners"
                className={clsx(
                  "atlas-text-sm atlas-font-medium atlas-flex atlas-gap-x-1 atlas-items-center",
                  {
                    "atlas-opacity-50": disabled,
                  }
                )}
              >
                <span>
                  {t("ownership.react.map.sidebar.control_page.known_owners_toggle_label")}
                </span>
                <i
                  className="icon-lt-question-line atlas-text-lg atlas-text-content-tertiary atlas-ml-1 atlas-cursor-pointer"
                  onClick={() => setShowKnownOwnersModal(true)}
                />
              </label>
              <Switch
                label="Known Owners"
                id="known-owners"
                disabled={disabled}
                onToggle={handleKnownOwnersChange}
                value={showKnownOwners}
              />
            </div>
            <div className="atlas-relative atlas-space-y-2 atlas-pb-4 atlas-pt-2 atlas-border-b atlas-border-neutral-3">
              <div className="atlas-flex atlas-justify-between atlas-items-center">
                <label
                  htmlFor="freeholds"
                  className={clsx("atlas-block atlas-text-sm atlas-font-medium", {
                    "atlas-opacity-50": disabled,
                  })}
                >
                  {t("ownership.react.map.sidebar.control_page.freehold_toggle_label")}
                </label>
                <Checkbox
                  id="freeholds"
                  onChange={handleShowFreeholdChange}
                  value={tenureFilters.showFreeholds ? CheckboxState.CHECKED : CheckboxState.EMPTY}
                  disabled={disabled}
                />
              </div>
              <div className="atlas-flex atlas-justify-between atlas-items-center">
                <label
                  htmlFor="leaseholds"
                  className={clsx("atlas-block atlas-text-sm atlas-font-medium", {
                    "atlas-opacity-50": disabled,
                  })}
                >
                  {t("ownership.react.map.sidebar.control_page.leasehold_toggle_label")}
                </label>
                <Checkbox
                  id="leaseholds"
                  onChange={handleShowLeaseholdChange}
                  value={tenureFilters.showLeaseholds ? CheckboxState.CHECKED : CheckboxState.EMPTY}
                  disabled={disabled}
                />
              </div>
            </div>
          </div>
          <OwnershipLegend
            showModal={showKnownOwnersModal}
            setShowModal={setShowKnownOwnersModal}
          />
        </>
      )}
      <div className="atlas-space-y-2">
        {showTenureFilters && (
          <div className={clsx("atlas-text-base", { "atlas-opacity-50": disabled })}>Filters</div>
        )}
        <OwnershipFilters
          disabled={disabled}
          setParcelSizeRange={handleParcelSizeRangeChange}
          parcelSizeRange={parcelSizeRange}
          unitType={unit}
        />
      </div>
    </div>
  );
}
