import { FC } from "react";
import { TextSwitch } from "@landtechnologies/components";

import { useTranslation } from "react-migration/lib/i18n/useTranslation";
import { PriceTypes } from "../../../typings/PriceTypes";
import { MeasurementSystem } from "src/js/stores/user/store";

export interface PriceTypeProps {
  priceType: PriceTypes;
  setPriceType: (priceType: PriceTypes) => void;
  unitPreference: MeasurementSystem;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  eventTracking?: (values: { filterName: string; filterState: any }) => void;
}

export const PriceType: FC<PriceTypeProps> = ({
  priceType,
  setPriceType,
  unitPreference,
  eventTracking,
}) => {
  const { t } = useTranslation();

  return (
    <div className="atlas-flex atlas-gap-2 atlas-justify-between" data-testid="price-type">
      <h4 className="atlas-text-sm atlas-font-medium atlas-my-auto">
        {t("comparables.map.sidebar.filters.type.map.title")}
      </h4>
      <TextSwitch<PriceTypes>
        value={priceType}
        name="priceType"
        label={t("comparables.map.sidebar.filters.type.map.title")}
        widthClass={"atlas-min-w-[130px]"}
        onChange={(ev) => {
          eventTracking?.({ filterName: "priceType", filterState: ev });
          setPriceType(ev);
        }}
        options={[
          {
            label: t("comparables.map.sidebar.filters.type.map.per_area", {
              currency: t("units.currency.default_currency"),
              areaType: t(
                unitPreference === MeasurementSystem.METRIC
                  ? "comparables.map.sidebar.filters.mode.price.sqm.alt"
                  : "comparables.map.sidebar.filters.mode.price.sqft.alt"
              ),
            }),
            value: PriceTypes.PER_AREA,
          },
          {
            label: t("comparables.map.sidebar.filters.type.map.per_unit"),
            value: PriceTypes.PER_UNIT,
          },
        ]}
      />
    </div>
  );
};
