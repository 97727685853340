import { useMemo } from "react";
import { geometryCollection } from "@turf/helpers";
import { isDefined } from "react-migration/lib/util/isDefined";
import { uniqBy } from "lodash";
import { Feature, GeometryCollection } from "geojson";
import { Title } from "react-migration/domains/ownership/typings/Title";
import { useTitlesQuery } from "react-migration/domains/ownership/apollo/getTitleGeometryByTitleNo";

export function uniqueTitlesById<T extends { id?: string }>(titles: T[]) {
  return uniqBy(titles, (title) => title.id);
}

export type TitleFeature = Feature<GeometryCollection, Title>;

/**
 * Enrich titles with polygon data. Remove any duplicates from response.
 */
export function useTitleFeatures<T extends { id?: string }>(titles: T[]) {
  const titleNumbers = useMemo(() => titles.map((title) => title.id).filter(isDefined), [titles]);
  const { data: titlesResponse, ...response } = useTitlesQuery(titleNumbers);

  const titleFeatures = useMemo<TitleFeature[] | undefined>(() => {
    const uniqueTitles = titlesResponse?.titles && uniqueTitlesById(titlesResponse?.titles);

    const titlesById = Object.fromEntries(
      titles.filter((title) => !!title.id).map((title) => [title.id!, title])
    );

    return uniqueTitles
      ?.map(
        (title) =>
          title.polygons &&
          (geometryCollection(
            title.polygons.map((polygon) => polygon.geometry!),
            { ...title, ...(titlesById[title.id] || {}) }
          ) as TitleFeature)
      )
      .filter(isDefined);
  }, [titlesResponse?.titles, titles]);

  return { titleFeatures, ...response } as const;
}
