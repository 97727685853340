import type { UpdateParameters } from "@deck.gl/core";

import bbox from "@turf/bbox";
import type { BBox } from "@turf/helpers/lib/geojson";
import { DataFilterExtension, MaskExtension } from "@deck.gl/extensions";
import { MVTLayer } from "@deck.gl/geo-layers";
import type { MVTLayerProps } from "@deck.gl/geo-layers/dist/mvt-layer/mvt-layer";
import Feature from "src/js/stores/user/Feature";
import {
  colorScaleCommercial,
  colorScaleResidential,
  getFillColour,
  getPointRadius,
  getGradientStop,
} from "./accessors";
import { MAX_ZOOM, MIN_ZOOM, YESTERDAY_MS } from "./constants";
import { PlanningApplicationLayerFilterProps } from "./planningFilters";
import {
  GradientGeoJsonLayer,
  GradientGeoJsonLayerProps,
  GradientScatterplotLayerProps,
} from "react-migration/lib/map/layers/GradientGeoJsonLayer";
import { yearsAgoMs } from "react-migration/lib/util/yearsAgoMS";
import type {
  ClassAndTypeFilters,
  PlanningApplicationFeature,
  PlanningApplicationFeatureProps,
} from "react-migration/domains/planning/types";
import getPermissions from "src/js/stores/user/actions/getPermissions";
import hasBetaFeature from "src/js/stores/user/actions/hasBetaFeature";

const DEFAULT_TILE_EXTENT = 4096;

export type PlanningApplicationLayerProps =
  GradientScatterplotLayerProps<PlanningApplicationFeature> &
    ClassAndTypeFilters &
    PlanningApplicationLayerFilterProps & {
      isBoundaryActive: boolean;
      focussedApplicationId?: string;
    } & MVTLayerProps<PlanningApplicationFeatureProps>;

export class PlanningApplicationLayer extends MVTLayer<
  PlanningApplicationFeature,
  PlanningApplicationLayerProps
> {
  static componentName = "PlanningApplicationLayer";

  constructor(...props: PlanningApplicationLayerProps[]) {
    // @ts-expect-error incorrect type after updating deck.gl
    super(...props, {
      minZoom: MIN_ZOOM,
      maxZoom: MAX_ZOOM,
      binary: false,
      pickable: true,
      refinementStrategy: "no-overlap",
      // @ts-expect-error incorrect type after updating deck.gl
      renderSubLayers: (props) => this._renderSubLayers(props),
    } as MVTLayerProps<PlanningApplicationFeature>);
  }

  initializeState() {
    super.initializeState();

    // ensure colour scales are correct when layer is first mounted
    this.updateColourScales(this.props.maxYear);
  }

  updateColourScales(maxYear: number) {
    // TODO: These scales are effectful, they should be owned by the layer instance
    const colorScaleDomain = [yearsAgoMs(maxYear), YESTERDAY_MS];
    colorScaleCommercial.domain(colorScaleDomain);
    colorScaleResidential.domain(colorScaleDomain);
  }

  shouldUpdateState({ oldProps, props, changeFlags }: UpdateParameters<this>) {
    if (oldProps.maxYear !== props.maxYear) {
      this.updateColourScales(props.maxYear);
    }

    return Object.values(changeFlags).some(Boolean);
  }

  // @ts-expect-error incorrect type after updating deck.gl
  getTileData(loadProps) {
    // @ts-expect-error incorrect type after updating deck.gl
    return super.getTileData(loadProps).then(getPlanningDataByLayer);
  }

  _renderSubLayers(props: PlanningApplicationLayerProps & { data: PlanningApplicationFeature[] }) {
    const geofenceGeometries = getPermissions()?.geofencesGeometries;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const maskExtensions = [] as MaskExtension[];
    let maskId: string | undefined;

    if (geofenceGeometries?.length && !hasBetaFeature(Feature.disableGeofence)) {
      maskExtensions.push(new MaskExtension());
      maskId = "Geofence";
    }

    return [
      new PlanningApplicationGeoJsonLayer(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        props as any,
        {
          id: `${props.id}-GradientGeoJsonLayer`,
          maskId,
          binary: true,
          updateTriggers: {
            getFilterValue: [
              props.showCommercial,
              props.showResidential,
              props.showOther,
              props.planningTypes,
              props.focussedApplicationId,
              props.isBoundaryActive,
            ],
            getFillColor: [props.maxYear],
            getPointGradientStop: [props.maxYear],
            getGradientStopColor: [props.maxYear],
          },
          getFilterValue: addBoundaryViewFilter(
            props.isBoundaryActive,
            addFocusFilter(props.focussedApplicationId, props.getFilterValue)
          ),
          getPolygonBounds(d: PlanningApplicationFeature) {
            const [minX, maxY, maxX, minY] = bbox(JSON.parse(d.properties.extent)).map(
              (p) => p / DEFAULT_TILE_EXTENT
            );

            // Flip Y axis as tile coordinates origin in bottom left of tile
            return [minX, minY, maxX, maxY] as BBox;
          },
          extensions: [
            new DataFilterExtension({ filterSize: 3 }),
            ...maskExtensions,
            ...(props.extensions || []),
          ],
          // Use radius precalculated in `getTileData`
          getPointRadius: (d: EnrichedPlanningApplicationDot) => d.properties.__radius,
        } as unknown as GradientGeoJsonLayerProps<PlanningApplicationFeature>
      ),
    ];
  }
}

type EnrichedPlanningApplicationDot = PlanningApplicationFeature & {
  properties: {
    __radius: number;
  };
};

/**
 * Written in an imperative way to prevent additional iterations of tile data.
 */
function getPlanningDataByLayer(data: PlanningApplicationFeature[]) {
  const dots: EnrichedPlanningApplicationDot[] = [];
  const boundaries: PlanningApplicationFeature[] = [];

  for (const application of data) {
    if (application.properties.layerName === "dots") {
      dots.push({
        ...application,
        properties: { ...application.properties, __radius: getPointRadius(application) },
      });
    } else {
      boundaries.push(application);
    }
  }

  return [
    ...dots.sort((a, b) => b.properties.__radius - a.properties.__radius),
    ...boundaries,
  ] as const;
}

type GetFilterValue = (d: PlanningApplicationFeature) => [number, number, number];

/**
 * @param focussedId The id of the planning application that is focussed
 * @param originalFilter The original getFilterValue used by the deck.gl layer
 * @returns A new getFilterValue function that hides features if they aren't focussed
 */
function addFocusFilter(
  focussedId: string | undefined,
  originalFilter: GetFilterValue
): GetFilterValue {
  return (d) => {
    if (focussedId && d.properties.id !== focussedId) {
      // No need to apply other filters as we know this app isn't visible
      return [0, 0, 0];
    }

    return originalFilter(d);
  };
}

/**
 * @param isBoundaryActive Whether the boundary mode is active
 * @param originalFilter The original getFilterValue used by the deck.gl layer
 * @returns A new getFilterValue function that hides features based on the boundary mode
 */
function addBoundaryViewFilter(
  isBoundaryActive: boolean,
  originalFilter: GetFilterValue
): GetFilterValue {
  return (d) => {
    const filterValue = originalFilter(d);

    if (isBoundaryActive && d.geometry.type === "Point" && d.properties.has_boundary) {
      // hide points with boundaries
      return [0, 0, 0];
    } else if (
      !isBoundaryActive &&
      (d.geometry.type === "Polygon" || d.geometry.type === "MultiPolygon")
    ) {
      // hide boundaries if boundary mode isn't active
      return [0, 0, 0];
    }

    return filterValue;
  };
}

export class PlanningApplicationGeoJsonLayer extends GradientGeoJsonLayer<PlanningApplicationFeature> {
  constructor(...props: Partial<GradientGeoJsonLayerProps<PlanningApplicationFeature>>[]) {
    const [firstProps, ...restProps] = props;

    super(
      {
        ...firstProps,
        stroked: true,
        filled: true,
        lineWidthUnits: "pixels",
        getLineWidth: 2,
        polygonOpacity: 0.6,
        pointRadiusUnits: "pixels",
        getFillColor: getFillColour,
        getLineColor: [255, 255, 255, 255],
        getGradientStopColor: getGradientStop,
        getPointGradientStop: getGradientStop,
        getPointRadius,
      } as unknown as GradientGeoJsonLayerProps<PlanningApplicationFeature>,
      ...(restProps as GradientGeoJsonLayerProps<PlanningApplicationFeature>[])
    );
  }
}
