import { SingleDesignation } from "react-migration/domains/constraints/typings/applicationTypes/SingleDesignation";

function subCategory(designation: SingleDesignation) {
  return (designation.sub_category_id || "").toUpperCase();
}

function displayName(designation: SingleDesignation) {
  return (designation.display_name || "").toLocaleUpperCase();
}

export function sortDesignationsBySubCategory(
  designationA: SingleDesignation,
  designationB: SingleDesignation
) {
  if (subCategory(designationA) < subCategory(designationB)) return -1;
  if (subCategory(designationA) > subCategory(designationB)) return 1;
  return 0;
}

export function sortDesignationsByName(
  designationA: SingleDesignation,
  designationB: SingleDesignation
) {
  if (displayName(designationA) < displayName(designationB)) return -1;
  if (displayName(designationA) > displayName(designationB)) return 1;
  return 0;
}
