import { ChangeEvent, useCallback, useState } from "react";
import { debounce } from "lodash";
import clsx from "clsx";
import { SiteOverview } from "react-migration/domains/sites/typings/Site";
import { useTranslation } from "react-migration/lib/i18n/useTranslation";
import { useUpdateSiteMutation } from "../SaveSiteButton/useUpdateSiteMutation/useUpdateSiteMutation";

export interface EditableSiteTitleProps {
  site: Pick<SiteOverview, "_id" | "title">;
  startedSaving(): void;
  stoppedSaving(source: string, success: boolean): void;
  onBlur?(): void;
}

export function EditableSiteTitle({
  site: { _id, title },
  startedSaving,
  stoppedSaving,
  onBlur,
}: EditableSiteTitleProps) {
  const { t } = useTranslation();
  const [invalid, setInvalid] = useState(false);
  const [updateSite] = useUpdateSiteMutation();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onChangeHandlerDebounced = useCallback(
    debounce(async (e: ChangeEvent<HTMLInputElement>) => {
      const title = e.target.value;
      const valid = titleIsValid(title);

      setInvalid(!valid);

      if (valid) {
        startedSaving();
        await updateSite({
          variables: { updateData: { _id, title } },
          onError: (err) => {
            console.error("EditableSiteTitle:updateSite Error", err);
            stoppedSaving("Title", false);
          },
          onCompleted: () => stoppedSaving("Title", true),
        });
      }
    }, 500),
    [_id, updateSite, startedSaving, stoppedSaving]
  );

  return (
    <input
      type="text"
      maxLength={100}
      className={clsx(
        "atlas-appearance-none atlas-px-2 atlas-py-1 atlas-text-md atlas-w-full atlas-border atlas-border-solid atlas-border-neutral-300 focus:atlas-border-border-action-focus atlas-rounded-md atlas-text-sm",
        {
          "!atlas-border-border-negative focus:atlas-border-border-negative-focus": invalid,
        }
      )}
      placeholder={t("sites.card.site_card.no_title")}
      onChange={onChangeHandlerDebounced}
      data-testid="edit-site-title-input"
      defaultValue={title}
      onBlur={onBlur}
    />
  );
}

function titleIsValid(title: string) {
  return title.length;
}
