import { LandTechEndpoints, routedClient } from "react-migration/lib/persistence/apollo";

import Feature from "src/js/stores/user/Feature";
import SITE_LOCAL_AUTHORITY_OPTIONS from "react-migration/domains/sites/apollo/li-api/queries/siteLocalAuthorityOptions.gql";
import hasFeature from "src/js/stores/user/actions/hasFeature";
import { useMemo } from "react";
import { useQuery } from "@apollo/client";

export interface SiteLocalAuthorityOption {
  id: string;
  name: string;
}

interface LocalAuthorityOption {
  name: string;
}

interface SiteLocalAuthorityOptions {
  siteLocalAuthorityOptions: LocalAuthorityOption[];
}

export function useSiteLocalAuthorityOptions(): SiteLocalAuthorityOption[] {
  const isUsUser = hasFeature(Feature.usAccess);

  const { data } = useQuery<SiteLocalAuthorityOptions>(SITE_LOCAL_AUTHORITY_OPTIONS, {
    client: routedClient,
    context: { endpoint: LandTechEndpoints.Constraints },
  });

  const siteLocalAuthorityOptions = useMemo(
    () =>
      data?.siteLocalAuthorityOptions
        ?.filter((option) => {
          return isUsUser === /.* FL$/.test(option.name);
        })
        .map(({ name }) => ({
          id: name,
          name,
        })) || [],
    [data?.siteLocalAuthorityOptions, isUsUser]
  );

  return siteLocalAuthorityOptions;
}
