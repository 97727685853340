import { LayerTypeMapLayerProps } from "../../types";
import { useSnapshot } from "valtio";
import { MapLayer as ComparablesLayer } from "react-migration/domains/comparables/components/MapLayer/MapLayer";
import { useComparablesLayerContext } from "./ComparableLayerContext/useComparableLayerContext";
import { comparablesStore } from "src/js/stores/comparables";

export function ComparablesMapLayer({ visible, zOrder }: Readonly<LayerTypeMapLayerProps>) {
  const { priceMode, filters } = useSnapshot(comparablesStore);
  const { distribution } = useComparablesLayerContext();
  const deckGLAdjustedZoom = 13;

  return (
    <ComparablesLayer
      key="COMPARABLES"
      filters={filters}
      priceMode={priceMode}
      minZoom={deckGLAdjustedZoom}
      visible={visible}
      distribution={distribution.data}
      zOrder={zOrder}
    />
  );
}
