import { useTranslation } from "react-migration/lib/i18n/useTranslation";
import { Button } from "react-migration/components/Button";

type OverdueInvoiceBannerProps = {
  onClose: () => void;
};

export const OverdueInvoiceBanner = ({ onClose }: OverdueInvoiceBannerProps) => {
  const { t } = useTranslation();

  const handleClose = () => {
    onClose();
  };

  return (
    <div
      id="overdue-invoice-banner"
      className="atlas-bg-warning-5 atlas-absolute atlas-bottom-0 atlas-left-0 atlas-p-2 atlas-w-full atlas-z-[9999] atlas-text-warning-9"
      data-testid="overdue-invoice-banner"
    >
      <div className="atlas-flex atlas-text-center atlas-items-center">
        <div className="atlas-flex-1">
          <strong>
            {t(
              "nav.overdue_invoice_banner.your_invoice_is_overdue._pay_now_to_keep_your_landinsight_account_active"
            )}{" "}
          </strong>
          {t("nav.overdue_invoice_banner.payments_take_up_to")}
        </div>
        <Button
          variant="ghost"
          className="atlas-cursor-pointer hover:atlas-text-content-warning"
          onClick={handleClose}
        >
          <i className="icon-lt-close-line atlas-text-2xl" />
        </Button>
      </div>
    </div>
  );
};

OverdueInvoiceBanner.displayName = "OverdueInvoiceBanner";
