import { ISourcingToolSearchFilter } from "react-migration/domains/search/typings/SourcingToolSpecificFilterTypes";
import { SearchType } from "react-migration/domains/search/constants/searchTypes";

export const SiteAllocationsEmergingFilter: ISourcingToolSearchFilter = {
  id: "site-allocations-emerging-id",
  type: "checkbox",
  field: "sidebar.react.search_sidebar.site_allocations_emerging",
  must: "MATCH_ANY",
  mustOptions: ["MATCH_ANY", "MATCH_NONE"],
  updateFilterDialogIsVisible: false,
  searchTypes: [SearchType.SOURCING_TOOL_UK],
  values: [
    {
      key: "residential_allocation",
      name: "Residential Allocation",
    },
    {
      key: "mixed_use_allocation",
      name: "Mixed Use Allocation",
    },

    {
      key: "commercial_allocation",
      name: "Commercial Allocation",
    },
    {
      key: "employment_allocation",
      name: "Employment Allocation",
    },
    {
      key: "community_allocation",
      name: "Community Allocation",
    },
    {
      key: "uncategorised_allocation",
      name: "Uncategorised",
    },
  ],
};
