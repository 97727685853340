import { Feature, feature, Geometries } from "@turf/helpers";
import { DesignationFeature } from "../../components/ConstraintLayer/DesignationLayer";
import { SelectionFeature, SelectionType } from "src/js/stores/map/store";
import { designationGeometryByIdQuery } from "../hooks/useDesignationGeometryById";

export async function createDesignationSelection(
  designation: DesignationFeature,
  combineGeometries: (geometries: Geometries[]) => Feature<Geometries>
): Promise<SelectionFeature | null> {
  const { data } = await designationGeometryByIdQuery(designation.properties.designation_id);

  const geometries = data.designation.designation_geometries.map(({ geometry }) => geometry);
  const geometry = geometries && combineGeometries(geometries).geometry;

  if (!geometry) return null;

  return {
    type: SelectionType.DESIGNATION,
    id: designation.properties.designation_id,
    feature: feature(geometry),
  };
}
