import { createContext, useCallback, useContext, useMemo } from "react";
import { feature } from "@turf/helpers";
import { useLocalStorage } from "react-migration/lib/persistence/state/useLocalStorage";
import { createMultiPolygon } from "react-migration/domains/constraints/designation/util/createMultiPolygon";
import type { LayerTypeProviderProps } from "../../types";
import { Tenure } from "react-migration/domains/ownership/typings/Feature";
import { TenureType } from "react-migration/domains/ownership/typings/Tenure";
import { TitleFeature } from "./OwnershipSelectionDetailView/useTitleFeatures";
import { SelectionType } from "src/js/stores/map/store";

export interface ParcelSizeRange {
  from: number;
  to: number;
}

const INITIAL_PARCEL_SIZE_RANGE = {
  from: 0,
  to: Infinity,
};

const OwnershipLayerTypeContext = createContext({} as OwnershipLayerTypeContextProps);

export const useOwnershipLayerTypeContext = () => useContext(OwnershipLayerTypeContext);

export interface TenureFilters {
  setShowFreeholds: React.Dispatch<boolean>;
  showFreeholds: boolean;
  setShowLeaseholds: React.Dispatch<boolean>;
  showLeaseholds: boolean;
}
interface OwnershipLayerTypeContextProps {
  showKnownOwners: boolean;
  setShowKnownOwners: React.Dispatch<boolean>;
  parcelSizeRange: ParcelSizeRange;
  setParcelSizeRange: React.Dispatch<ParcelSizeRange>;
  tenureFilters: TenureFilters;
  featureIsVisible(title: { tenure?: Tenure | TenureType }): boolean;
  selectTitleFeature: (titleFeature: TitleFeature) => void;
}

export function OwnershipLayerTypeProvider({
  children,
  setSelection,
  setDetailSelection,
}: LayerTypeProviderProps) {
  const [parcelSizeRange, setParcelSizeRange] = useLocalStorage<ParcelSizeRange>(
    "ownership-parcelSizeRange",
    INITIAL_PARCEL_SIZE_RANGE
  );

  const [showKnownOwners, setShowKnownOwners] = useLocalStorage(
    "ownershipKnownOwnersActive",
    false
  );

  const [showFreeholds, setShowFreeholds] = useLocalStorage("ownershipFreeholdsActive", true);
  const [showLeaseholds, setShowLeaseholds] = useLocalStorage("onwershipLeaseholdsActive", true);

  const tenureFilters = useMemo(
    () => ({
      setShowFreeholds,
      showFreeholds,
      setShowLeaseholds,
      showLeaseholds,
    }),
    [setShowFreeholds, setShowLeaseholds, showFreeholds, showLeaseholds]
  );

  const featureIsVisible = useMemo(() => applyTenureFilters(tenureFilters), [tenureFilters]);

  const selectTitleFeature = useCallback(
    (titleFeature: TitleFeature) => {
      const { geometry } = createMultiPolygon(titleFeature.geometry?.geometries ?? []);
      setDetailSelection(undefined);
      setSelection({
        id: titleFeature.properties?.title_no,
        type: SelectionType.OWNERSHIP_TITLE,
        feature: feature(geometry!),
      });
    },
    [setSelection, setDetailSelection]
  );

  return (
    <OwnershipLayerTypeContext.Provider
      value={{
        showKnownOwners,
        parcelSizeRange,
        setParcelSizeRange,
        setShowKnownOwners,
        selectTitleFeature,
        tenureFilters,
        featureIsVisible,
      }}
    >
      {children}
    </OwnershipLayerTypeContext.Provider>
  );
}

export function applyTenureFilters(tenureFilters: TenureFilters) {
  return (title: { tenure?: Tenure | TenureType }) => {
    switch (title.tenure) {
      case "Freehold":
      case "FREEHOLD":
        return tenureFilters.showFreeholds;
      case "Leasehold":
      case "LEASEHOLD":
        return tenureFilters.showLeaseholds;
      default:
        return tenureFilters.showFreeholds || tenureFilters.showLeaseholds;
    }
  };
}
