import { ISourcingToolSearchFilter } from "react-migration/domains/search/typings/SourcingToolSpecificFilterTypes";
import { SearchType } from "react-migration/domains/search/constants/searchTypes";

export const LandAvailabilityAssessmentFilter: ISourcingToolSearchFilter = {
  id: "land-availability-assessment-id",
  type: "checkbox",
  field: "sidebar.react.search_sidebar.land_availability_assessment",
  must: "MATCH_ANY",
  mustOptions: ["MATCH_ANY", "MATCH_NONE"],
  updateFilterDialogIsVisible: false,
  searchTypes: [SearchType.SOURCING_TOOL_UK],
  values: [
    {
      key: "positive",
      name: "Positive",
    },
    {
      key: "neutral",
      name: "Neutral",
    },
    {
      key: "negative",
      name: "Negative",
    },
  ],
};
