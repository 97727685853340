import distance from "@turf/distance";
import { Position } from "geojson";
import { GenericMeasurments } from "react-migration/components/GenericMeasurements/GenericMeasurments";
import { GeometryMeasurements } from "react-migration/components/GeometryMeasurements";
import { getLengthVariants } from "react-migration/lib/util/getAreaAndPerimeter";
import { getMeasurementSystemPreference } from "src/js/stores/user/actions/getSetting";
import { AnnotationSpecProps } from "./types";

export function RectangleSpec({ drawing }: AnnotationSpecProps) {
  const unitPreference = getMeasurementSystemPreference();

  const coordinates = drawing.geometry.coordinates as Position[][];
  const heightInMeters =
    distance(coordinates[0][1], coordinates[0][5], {
      units: "kilometers",
    }) * 1000;

  const widthInMeters =
    distance(coordinates[0][3], coordinates[0][7], {
      units: "kilometers",
    }) * 1000;

  const heightVariants = getLengthVariants(heightInMeters, unitPreference);
  const lengthVariants = getLengthVariants(widthInMeters, unitPreference);

  return (
    <div className="atlas-flex atlas-flex-col atlas-gap-3">
      <GeometryMeasurements geometry={drawing.geometry} />
      <GenericMeasurments
        measurements={[
          { label: "Height", variants: heightVariants },
          { label: "Width", variants: lengthVariants },
        ]}
      />
    </div>
  );
}
