import { Stepper } from "@landtechnologies/components";
import { useMemo } from "react";
import { useTranslation } from "react-migration/lib/i18n/useTranslation";
import { LetterStep } from "src/js/stores/letter/store";
import Feature from "src/js/stores/user/Feature";
import hasFeature from "src/js/stores/user/actions/hasFeature";

export const LetterStepper = ({ currentStep }: { currentStep: LetterStep }) => {
  const { t } = useTranslation();
  const isPhysicalSendAvailable = hasFeature(Feature.physicalSend);

  const steps = useMemo(() => {
    if (!isPhysicalSendAvailable) {
      return [
        { key: LetterStep.SelectRecipients, label: t("sites.letters.select_recipients") },
        { key: LetterStep.TemplateEditor, label: t("sites.letters.create_letter") },
        { key: LetterStep.PreviewLetter, label: t("sites.letters.preview_and_download") },
      ];
    }
    return [
      { key: LetterStep.SelectRecipients, label: t("sites.letters.select_recipients") },
      { key: LetterStep.TemplateEditor, label: t("sites.letters.create_letter") },
      { key: LetterStep.PreviewLetter, label: t("sites.letters.preview_letter") },
      { key: LetterStep.ConfirmAndSend, label: t("sites.letters.confirm_and_send") },
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPhysicalSendAvailable]);

  return (
    <Stepper currentStep={currentStep} steps={steps} containerClassNames="!atlas-border-none" />
  );
};
