import { z } from "zod";

const PositionSchema = z.array(z.number());

const BBox2dSchema = z.tuple([z.number(), z.number(), z.number(), z.number()]);

const BBox3dSchema = z.tuple([
  z.number(),
  z.number(),
  z.number(),
  z.number(),
  z.number(),
  z.number(),
]);

const BBoxSchema = z.union([BBox2dSchema, BBox3dSchema]);

const GeoJSONObjectSchema = z.object({
  type: z.string(),
  bbox: BBoxSchema.optional(),
});

const GeometryTypesSchema = z.union([
  z.literal("Point"),
  z.literal("LineString"),
  z.literal("Polygon"),
  z.literal("MultiPoint"),
  z.literal("MultiLineString"),
  z.literal("MultiPolygon"),
]);

const GeometryObjectSchema = GeoJSONObjectSchema.extend({
  type: GeometryTypesSchema,
});

export const PointSchema = GeometryObjectSchema.extend({
  type: z.literal("Point"),
  coordinates: PositionSchema,
});

export const MultiPointSchema = GeometryObjectSchema.extend({
  type: z.literal("MultiPoint"),
  coordinates: z.array(PositionSchema),
});

export const LineStringSchema = GeometryObjectSchema.extend({
  type: z.literal("LineString"),
  coordinates: z.array(PositionSchema),
});

export const MultiLineStringSchema = GeometryObjectSchema.extend({
  type: z.literal("MultiLineString"),
  coordinates: z.array(z.array(PositionSchema)),
});

export const PolygonSchema = GeometryObjectSchema.extend({
  type: z.literal("Polygon"),
  coordinates: z.array(z.array(PositionSchema)),
});

export const MultiPolygonSchema = GeometryObjectSchema.extend({
  type: z.literal("MultiPolygon"),
  coordinates: z.array(z.array(z.array(PositionSchema))),
});
