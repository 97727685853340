import cn from "clsx";
import React from "react";

export enum TagColor {
  GREEN = "GREEN",
  GRAY = "GRAY",
  LIGHT_GRAY = "LIGHT_GRAY",
  RED = "RED",
  ORANGE = "ORANGE",
  DEFAULT = "DEFAULT",
}

export interface TagProps {
  tag: string;
  color?: TagColor;
  onClose?: React.MouseEventHandler<HTMLDivElement>;
}

export function Tag({ tag, color, onClose }: TagProps) {
  return (
    <div
      className={cn(
        "atlas-text-xs",
        "atlas-font-medium",
        "atlas-leading-4",
        "atlas-rounded",
        "atlas-h-5",
        "atlas-px-2",
        "atlas-py-0.5",
        {
          "atlas-bg-green-50 atlas-text-green-400": color === TagColor.GREEN,
          "atlas-bg-neutral-600 atlas-text-content-inverse-primary": color === TagColor.GRAY,
          "atlas-bg-blueGrey-200 atlas-text-blueGrey-950/50": color === TagColor.LIGHT_GRAY,
          "atlas-bg-red-50 atlas-text-red-500": color === TagColor.RED,
          "atlas-bg-orange-50 atlas-text-orange-500": color === TagColor.ORANGE,
          "atlas-bg-platformBlue-200 atlas-text-platformBlue-700": color === TagColor.DEFAULT,
          "atlas-bg-blue-50 atlas-text-neutral-600": !color,
        }
      )}
    >
      <div className="atlas-flex">
        {tag}
        {onClose && (
          <div onClick={onClose} className="atlas-cursor-pointer" data-testid="remove-label">
            <i className="icon-lt-close-line atlas-ml-1" />
          </div>
        )}
      </div>
    </div>
  );
}
