import { useQuery } from "@apollo/client";
import { SingleDesignation } from "react-migration/domains/constraints/typings/applicationTypes/SingleDesignation";
import DESIGNATION_INTERSECTIONS from "./designationIntersections.gql";
import {
  LandTechEndpoints,
  routedClient,
} from "react-migration/lib/persistence/apollo/routedClient";
import { ConstraintsCategory } from "react-migration/layouts/map/Constraints/types";

type DesignationIntersectionsProps = {
  id: string | undefined;
  bufferMeters: number | undefined;
  rootCategories: ConstraintsCategory[];
  skip?: boolean;
};

export function useDesignationIntersections({
  id,
  bufferMeters,
  rootCategories,
  skip,
}: DesignationIntersectionsProps) {
  const { data, ...rest } = useQuery<{ designation: SingleDesignation }>(
    DESIGNATION_INTERSECTIONS,
    {
      variables: {
        id,
        bufferMeters,
        rootCategories,
      },
      client: routedClient,
      context: {
        endpoint: LandTechEndpoints.Constraints,
      },
      skip: !id || skip,
    }
  );

  return { designations: data?.designation.intersecting_designations || [], ...rest };
}
