import { useQuery } from "@apollo/client";
import { useMemo, useRef } from "react";
import { LandTechEndpoints, routedClient } from "react-migration/lib/persistence/apollo";
import ZOOPLA_COMPARABLE_BY_UPRN from "../apollo/zooplaComparableByUPRN.gql";
import { ZooplaComparableDTO } from "@landtechnologies/comparables-services";

interface ZooplaComparableHookResponse {
  uprn?: string;
  loading: boolean;
  data?: ZooplaComparableDTO;
}

interface ZooplaComparableResult {
  rentComparableByUPRN: ZooplaComparableDTO;
}

export function useZooplaComparableByUPRN({
  uprn,
  skip,
}: {
  uprn?: string;
  skip?: boolean;
}): ZooplaComparableHookResponse {
  const { loading, data } = useQuery<ZooplaComparableResult>(ZOOPLA_COMPARABLE_BY_UPRN, {
    variables: {
      uprn,
    },
    client: routedClient,
    skip: !uprn || skip,
    context: {
      endpoint: LandTechEndpoints.Gateway,
    },
  });

  const lastResult = useRef(data?.rentComparableByUPRN);

  if (data !== lastResult.current) {
    lastResult.current = data?.rentComparableByUPRN;
  }
  return useMemo(
    () => ({
      loading,
      data: lastResult.current?.uprn === uprn ? lastResult.current : undefined,
      uprn,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [lastResult.current, loading]
  );
}
