import { routedClient } from "react-migration/lib/persistence/apollo";
import { useQuery } from "@apollo/client";
import gql from "graphql-tag";

export const LOCAL_AUTHORITIES = gql`
  query {
    authorities {
      name
      gss_code
    }
  }
`;

export type LocalAuthority = {
  name: string;
  gss_code: string;
};

export function useLocalAuthorities() {
  const { data, ...rest } = useQuery<{ authorities: LocalAuthority[] }>(LOCAL_AUTHORITIES, {
    client: routedClient,
  });

  return { localAuthorities: data?.authorities, rest };
}
