import { QueryResult, useQuery } from "@apollo/client";
import ATKINS_ORDER from "react-migration/domains/sites/apollo/sites-api/queries/atkinsOrder.gql";
import {
  LandTechEndpoints,
  routedClientWithoutTypenames,
} from "react-migration/lib/persistence/apollo/routedClient";

import { AtkinsOrderDTO } from "../typings/AtkinsOrderDTO";

export type AtkinsOrderQueryResponse = {
  atkinsOrder: AtkinsOrderDTO;
};

export type AtkinsOrderQueryVariables = {
  siteId?: string;
};

export type UseAtkinsOrderResult = QueryResult<AtkinsOrderQueryResponse, AtkinsOrderQueryVariables>;

export function useAtkinsOrder(variables: AtkinsOrderQueryVariables) {
  const response = useQuery<AtkinsOrderQueryResponse>(ATKINS_ORDER, {
    client: routedClientWithoutTypenames,
    context: {
      endpoint: LandTechEndpoints.Sites,
    },
    fetchPolicy: "cache-and-network", // we want to return from the cache for speed as this will be fine 99% of the time, but we also want to ensure we're getting updated signed urls in the background when we can
    variables: variables,
    skip: !variables.siteId,
  });
  return response;
}
