import { PropsWithChildren } from "react";
import classNames from "classnames";

export const FinalStepWrapper = ({ children }: PropsWithChildren) => {
  return (
    <div
      className={classNames(
        "atlas-h-full",
        "atlas-w-full",
        "atlas-flex",
        "atlas-flex-col",
        "atlas-items-center",
        "atlas-justify-center"
      )}
      data-testid="final-step-letter-wrapper"
    >
      {children}
    </div>
  );
};
