import { Divider, NumericInput, Switch } from "@landtechnologies/components";
import cn from "classnames";
import { useCallback } from "react";
import { CheckboxTree, CheckboxTreeChange } from "react-migration/components/CheckboxTree";
import { planningTypes } from "react-migration/domains/planning/constants";
import { PlanningStatus, planningStatuses } from "react-migration/domains/planning/types";
import { useTranslation } from "react-migration/lib/i18n/useTranslation";
import Feature from "src/js/stores/user/Feature";
import hasFeature from "src/js/stores/user/actions/hasFeature";
import { LayerTypeControlPageProps } from "../../types";
import { usePlanningApplicationsContext } from "./Context";
import { useControlTracking } from "../../ControlTrackingContext";
import clsx from "clsx";

export function PlanningApplicationsControlPage({ disabled }: LayerTypeControlPageProps) {
  const { debouncedTrackControlChange } = useControlTracking();
  const { t } = useTranslation();
  const { state, stateReducer } = usePlanningApplicationsContext();
  const hasPlanningFilters = hasFeature(Feature.planningFilters);

  const {
    showResidential,
    showCommercial,
    showOther,
    maxYear,
    minSize,
    showBoundaries,
    enabledStatuses,
    enabledTypes,
  } = state;

  const handleStatusChange = useCallback<CheckboxTreeChange>(
    ({ visibleNodeIds }) => {
      debouncedTrackControlChange({ filterName: "status", filterState: visibleNodeIds });

      return stateReducer({
        type: "SET_ENABLED_STATUSES",
        value: visibleNodeIds as PlanningStatus[],
      });
    },
    [stateReducer, debouncedTrackControlChange]
  );

  const handleTypeChange = useCallback<CheckboxTreeChange>(
    ({ visibleNodeIds }) => {
      debouncedTrackControlChange({ filterName: "type", filterState: visibleNodeIds });
      return stateReducer({
        type: "SET_ENABLED_TYPES",
        value: visibleNodeIds as PlanningStatus[],
      });
    },
    [stateReducer, debouncedTrackControlChange]
  );

  const handleShowBoundariesChange = useCallback(
    (ev: React.ChangeEvent<HTMLInputElement>) => {
      debouncedTrackControlChange({ filterName: "showBoundaries", filterState: ev.target.checked });
      stateReducer({ type: "SET_SHOW_BOUNDARIES", value: ev.currentTarget.checked });
    },
    [stateReducer, debouncedTrackControlChange]
  );

  const handleShowResidentialChange = useCallback(() => {
    debouncedTrackControlChange({ filterName: "showResidential", filterState: !showResidential });
    stateReducer({ type: "SET_RESIDENTIAL", value: !showResidential });
  }, [showResidential, stateReducer, debouncedTrackControlChange]);

  const handleShowCommercialChange = useCallback(() => {
    debouncedTrackControlChange({ filterName: "showCommercial", filterState: !showCommercial });
    stateReducer({ type: "SET_COMMERCIAL", value: !showCommercial });
  }, [showCommercial, stateReducer, debouncedTrackControlChange]);

  const handleShowOtherChange = useCallback(() => {
    debouncedTrackControlChange({ filterName: "showOther", filterState: !showOther });
    stateReducer({ type: "SET_OTHER", value: !showOther });
  }, [showOther, stateReducer, debouncedTrackControlChange]);

  const handleYearsChange = useCallback(
    (value: number) => {
      debouncedTrackControlChange({ filterName: "years", filterState: value });
      stateReducer({ type: "SET_YEARS", value });
    },
    [stateReducer, debouncedTrackControlChange]
  );

  const handleHomeCountChange = useCallback(
    (value: number) => {
      debouncedTrackControlChange({ filterName: "homeCount", filterState: value });
      stateReducer({ type: "SET_HOMES", value });
    },
    [stateReducer, debouncedTrackControlChange]
  );

  return (
    <div className="atlas-space-y-4">
      <div className="atlas-space-y-2">
        <div className="atlas-flex atlas-justify-between atlas-items-center atlas-pb-4 atlas-border-b atlas-border-neutral-3">
          <label
            htmlFor="planning-show-boundaries"
            className={cn("atlas-font-semibold", { "atlas-opacity-50": disabled })}
          >
            Show available boundaries
          </label>
          <div className="atlas-flex atlas-justify-end atlas-items-center">
            <Switch
              disabled={disabled}
              id="planning-show-boundaries"
              label="Show available boundaries"
              onToggle={handleShowBoundariesChange}
              value={showBoundaries}
            />
          </div>
        </div>
        <div className="atlas-space-y-2 atlas-pb-4 atlas-pt-2 atlas-border-b atlas-border-neutral-3">
          <div className="atlas-space-y-2">
            <LayerToggle
              disabled={disabled}
              name="Residential"
              color="#ff3665"
              enabled={showResidential}
              setEnabled={handleShowResidentialChange}
            />
            <LayerToggle
              disabled={disabled}
              name="Commercial"
              color="#8e36ff"
              enabled={showCommercial}
              setEnabled={handleShowCommercialChange}
            />
            <LayerToggle
              disabled={disabled}
              name="Other"
              color="#30b3df"
              enabled={showOther}
              setEnabled={handleShowOtherChange}
            />
          </div>
        </div>
      </div>

      <div className="atlas-space-y-2">
        <div className={clsx("atlas-text-base", { "atlas-opacity-50": disabled })}>Filters</div>
        <div className="atlas-flex-col atlas-space-y-3 atlas-text-sm atlas-pt-2 atlas-pb-4 atlas-border-b atlas-border-neutral-3">
          <div className="atlas-flex atlas-justify-between atlas-items-center">
            <div className={cn("atlas-font-semibold", { "atlas-opacity-50": disabled })}>
              Applications within the last{" "}
            </div>
            <div className="atlas-flex atlas-items-center atlas-gap-2">
              <NumericInput
                isDisabled={disabled}
                className="atlas-max-w-[80px]"
                size="small"
                value={maxYear}
                minValue={0}
                maxValue={35}
                onChange={handleYearsChange}
              />

              <div className={cn({ "atlas-opacity-50": disabled })}>years</div>
            </div>
          </div>

          <div className="atlas-flex atlas-justify-between atlas-items-center">
            <div className={cn("atlas-font-semibold", { "atlas-opacity-50": disabled })}>
              Number of homes
            </div>
            <div className="atlas-flex atlas-items-center atlas-gap-2">
              <NumericInput
                isDisabled={disabled}
                className="atlas-max-w-[65px]"
                size="small"
                value={minSize}
                minValue={0}
                onChange={handleHomeCountChange}
              />
              <div className={cn({ "atlas-opacity-50": disabled })}>or more</div>
            </div>
          </div>

          {hasPlanningFilters && (
            <div className={clsx({ "atlas-opacity-50": disabled })}>
              <Divider />
              <div>
                <h1 className="atlas-text-sm">{t("components.planning_record.status")}</h1>
                <CheckboxTree
                  treeStructure={statusFilters}
                  treeSchema={STATUS_SCHEMA}
                  visibleNodeIds={enabledStatuses}
                  onChange={handleStatusChange}
                  disabled={disabled}
                />
              </div>
              <Divider />
              <div>
                <h1 className="atlas-text-sm">{t("components.planning_record.type")}</h1>
                <CheckboxTree
                  treeStructure={typeFilters}
                  treeSchema={TYPES_SCHEMA}
                  visibleNodeIds={enabledTypes}
                  onChange={handleTypeChange}
                  disabled={disabled}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

const statusFilters = {
  id: "enabledStatuses",
  display_name: "Planning Statuses",
  childNodes: planningStatuses.map((status) => ({
    id: status.id,
    display_name: status.name,
    childNodes: [],
  })),
};
const STATUS_SCHEMA = statusFilters.childNodes.map((node) => ({ key: node.id }));
const typeFilters = {
  display_name: "Planning Types",
  id: "enabledTypes",
  childNodes: planningTypes.map((type) => ({
    id: type.id,
    display_name: type.name,
    childNodes: [],
  })),
};
const TYPES_SCHEMA = typeFilters.childNodes.map((node) => ({ key: node.id }));

type LayerToggleProps = {
  name: string;
  color: string;
  enabled: boolean;
  setEnabled: () => void;
  disabled?: boolean;
};
const LayerToggle = ({ name, color, enabled, setEnabled, disabled }: LayerToggleProps) => (
  <div className="atlas-flex atlas-justify-between">
    <div className="atlas-flex atlas-items-center atlas-space-x-2">
      <div
        style={{ backgroundColor: color }}
        className={`atlas-flex atlas-items-center atlas-justify-center atlas-rounded-full atlas-p-2 atlas-text-white atlas-h-6 atlas-w-6`}
      >
        <div>{name.substring(0, 1).toUpperCase()}</div>
      </div>
      <div className={cn("atlas-font-bold", { "atlas-opacity-50": disabled })}>{name}</div>
    </div>
    <Switch
      id={`planning-${name}`}
      label={name}
      disabled={disabled}
      onToggle={setEnabled}
      value={enabled}
    />
  </div>
);
