import { RouteConfig } from "vue-router";
import ROUTES from "../routes";
import { ENVIRONMENT } from "../../util/environment";

export const AccountRoutes: Array<RouteConfig> = [
  {
    path: "/account/invoices",
    beforeEnter() {
      window.location.href = `${ENVIRONMENT.YOUR_ACCOUNT_URL}/invoices`;
    },
  },
  {
    path: "/account/invoice/:invoiceId?",
    beforeEnter(route) {
      window.location.href = `${ENVIRONMENT.YOUR_ACCOUNT_URL}/invoice/${
        route.params.invoiceId ? route.params.invoiceId : route.query.invoice
      }`;
    },
  },
  {
    path: "/account/your-address",
    redirect: { name: ROUTES.DASHBOARD_YOUR_ADDRESS },
  },
  {
    path: "/account/documents",
    redirect: { name: ROUTES.DASHBOARD_DOCUMENTS },
  },
  {
    path: "/account",
    redirect: { name: ROUTES.DASHBOARD_TOPUP },
  },
  {
    path: "/account/transactions",
    redirect: { name: ROUTES.DASHBOARD_TRANSACTIONS },
  },
  {
    path: "/your-account",
    beforeEnter() {
      window.location.href = `${ENVIRONMENT.ACCOUNTS_SERVICE_PRIVATE_API_URL}/customer-portal`;
    },
  },
  {
    path: "/account/payments",
    beforeEnter() {
      window.location.href = `${ENVIRONMENT.ACCOUNTS_SERVICE_PRIVATE_API_URL}/customer-portal`;
    },
  },
  {
    path: "/account/upgrade",
    beforeEnter() {
      window.location.href = `${ENVIRONMENT.ACCOUNTS_SERVICE_PRIVATE_API_URL}/customer-portal?flow=upgrade`;
    },
  },
  {
    name: ROUTES.LOGOUT,
    path: `/logout:error?`,
    beforeEnter(route) {
      const url = new URL(ENVIRONMENT.LOGOUT_URL);
      url.searchParams.set("error", route.params.error);
      url.searchParams.set(
        "redirect",
        window.location.pathname.replace(ENVIRONMENT.BASE_URL || "", "") + window.location.search
      );
      window.location.assign(url);
    },
  },
];
