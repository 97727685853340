const setStore = (options, stores) => {
  if (options.store) {
    return typeof options.store === "function" ? options.store() : options.store;
  } else if (options.$store) {
    return options.$store;
  } else if (options.parent && options.parent.$store) {
    return options.parent.$store;
  } else if (stores) {
    return stores;
  }
};

const createComputed = (ctx, alias, key, store, computed, action) => {
  ctx.$options.computed = ctx.$options.computed ? ctx.$options.computed : {};
  if (!ctx.$options.computed[alias]) {
    ctx.$options.computed[alias] = {
      get() {
        if (computed) {
          return ctx.$store.getReactive(store, key);
        } else {
          return ctx.$store.getOriginal(store, key);
        }
      },
      set(value) {
        if (action && typeof action === "function") {
          action(value);
        } else {
          console.warn("We should be using an valtio action not updating directly here:" + alias);
        }
      },
      enumerable: true,
    };
  }
};
const shareState = (ctx, stores) => {
  const options = ctx.$options;
  ctx.$store = setStore(options, stores);

  const settings = options.valtio;
  if (settings?.state) {
    const state = settings.state;
    Object.keys(state)
      .filter((sk) => Array.isArray(state[sk]))
      .forEach((s) => {
        const keys = state[s];
        keys.forEach((key) => {
          const originalKey = typeof key == "object" ? key.key : key;
          const alias =
            typeof key == "object" && key.hasOwnProperty("alias") ? key.alias : originalKey;
          const computed =
            typeof key !== "object"
              ? true
              : key.hasOwnProperty("frozen")
              ? !(key.frozen === false)
              : true;
          let action = null;
          if (
            typeof key == "object" &&
            key.hasOwnProperty("action") &&
            typeof key.action == "function"
          ) {
            action = key.action;
          }
          createComputed(ctx, alias, originalKey, s, computed, action);
        });
      });
  }
};

export default {
  /**
   * Install valtio plugin
   *
   * @param {Vue} Vue
   * @param {Object} options
   */
  install: (vue, stores) => {
    vue.mixin({
      beforeCreate() {
        shareState(this, stores);
      },
    });
    vue.prototype.$valtio = stores;
    vue.$valtio = stores;
  },
};
