import { createContext, useContext, useState } from "react";
import { useSnapshot } from "valtio";
import { logEventV2 } from "react-migration/lib/util/logEventV2";
import { LayerTypeProviderProps } from "../../types";
import { RoutingTime, RoutingType } from "./types";
import { mapStore } from "src/js/stores/map/store";
import { useLocalStorage } from "react-migration/lib/persistence/state/useLocalStorage";
import { useMapCallback } from "react-migration/lib/map/useMapCallback";

interface IsochroneLayerTypeContextProps {
  routingType: RoutingType;
  setRoutingType(value: RoutingType): void;
  routingTime: RoutingTime;
  setRoutingTime(value: RoutingTime): void;
  fetching: boolean;
  setFetching(value: boolean): void;
}

const IsochroneLayerTypeContext = createContext({} as IsochroneLayerTypeContextProps);

export const useIsochroneLayerTypeContext = () => useContext(IsochroneLayerTypeContext);

export function IsochroneLayerTypeProvider({ children }: LayerTypeProviderProps) {
  const [fetching, setFetching] = useState(false);
  const { settings: mapSettings } = useSnapshot(mapStore);
  const { zoom } = mapSettings;

  const [routingType, setRoutingType] = useLocalStorage(
    getStorageKey("routing-type"),
    RoutingType.DrivingTraffic
  );

  const [routingTime, setRoutingTime] = useLocalStorage(
    getStorageKey("routing-time"),
    RoutingTime.M15
  );

  useMapCallback(
    ({ coordinate }) => {
      if (!coordinate) return;

      logEventV2({
        name: "Isochrone Layer Map click",
        properties: {
          lng: coordinate[0].toString(),
          lat: coordinate[1].toString(),
          zoom,
          routingType,
          routingTime,
        },
      });
    },
    [routingType, zoom, routingTime]
  );

  return (
    <IsochroneLayerTypeContext.Provider
      value={{ routingType, setRoutingType, routingTime, setRoutingTime, fetching, setFetching }}
    >
      {children}
    </IsochroneLayerTypeContext.Provider>
  );
}

const getStorageKey = (key: string) => `isochrone-layer__${key}`;
