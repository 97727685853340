import { PickingInfo } from "deck.gl";
import { SelectionType } from "src/js/stores/map/store";
import { ClickSelectionResolver, LayerType } from "../../../types";
import { landAssemblyClickResolver } from "../../../selectionUtils";
import { constraintClickResolver } from "../constraintClickResolver";
import { ConstraintsLayerType } from "../ConstraintsLayerType";
import { DesignationsSelectionSidebarConnected } from "react-migration/domains/constraints/designation/components/Designations/DesignationsSelectionSidebar";

export const OWNERSHIP_SCOTLAND_LAYER_ID = "ownership-scotland";

function isDesignationOwnershipClick(pickInfo: PickingInfo<unknown>) {
  //TODO: don't directly reference Scotland layer Id.
  return pickInfo.layer?.id.startsWith(OWNERSHIP_SCOTLAND_LAYER_ID);
}

const ownershipDesignationSelection: ClickSelectionResolver = async (pickInfo) => {
  if (!isDesignationOwnershipClick(pickInfo)) return null;

  const selectedFeature = await constraintClickResolver(pickInfo);

  return selectedFeature ? { ...selectedFeature, type: SelectionType.OWNERSHIP_DESIGNATION } : null;
};

/**
 * Represents the layer type for ownership constraints.
 * This is temporary to get Scottish Ownership over the line
 */
export const OwnershipDesignationLayerType: LayerType = {
  ...ConstraintsLayerType,
  SelectionSidebar: DesignationsSelectionSidebarConnected,
  clickResolver: ownershipDesignationSelection,
  landAssemblyClickResolver: landAssemblyClickResolver(ownershipDesignationSelection),
};
