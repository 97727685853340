import { useSnapshot } from "valtio";
import { feature } from "@turf/turf";
import { mapStore, SelectionFeature, SelectionType } from "src/js/stores/map/store";
import { geoJsonPolyToGoogleBounds } from "src/js/util/map_util";
import { PageControls } from "react-migration/components/PageControls";
import { SiteEdge, useSitePagination } from "./useSitePagination";

interface SitePageControlsProps {
  setSelection(selection: SelectionFeature): void;
  siteId?: string;
}

export const SitePageControls = ({ siteId, setSelection }: SitePageControlsProps) => {
  const { googleMap } = useSnapshot(mapStore);
  const { totalSites, position, previousSiteEdge, nextSiteEdge, disabled } =
    useSitePagination(siteId);

  const setSiteSelection = (edge?: SiteEdge) => {
    if (!edge) return;

    const { geometry, _id } = edge.node;

    googleMap?.fitBounds(geoJsonPolyToGoogleBounds(geometry));

    setSelection({
      type: SelectionType.SITE,
      id: _id,
      feature: feature(geometry),
      savable: false,
    });
  };

  if (!siteId || !position || !totalSites) return null;

  return (
    <PageControls
      onPreviousPage={() => setSiteSelection(previousSiteEdge)}
      onNextPage={() => setSiteSelection(nextSiteEdge)}
      page={position}
      totalPages={totalSites}
      disabled={disabled}
    />
  );
};
