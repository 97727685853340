import { PlanningAlert } from "../../../typings/PlanningAlert";
import { PlanningAlertRow } from "./PlanningAlertRow";
import { Waypoint } from "react-waypoint";
import { twMerge } from "tailwind-merge";

type PlanningAlertsTableProps = {
  planningAlerts: PlanningAlert[];
  onEdit: (alert: PlanningAlert) => void;
  nextItems?: () => void;
  detailed?: boolean;
  totalNumber?: number;
};

export const PlanningAlertsTable = ({
  planningAlerts,
  onEdit,
  nextItems,
  detailed,
  totalNumber,
}: PlanningAlertsTableProps) => {
  const handleWaypointEnter = () => {
    nextItems?.();
  };
  const tableClasses = twMerge(
    "atlas-border atlas-border-b-0 atlas-border-neutral-200 atlas-rounded-t",
    !detailed && "atlas-rounded-b"
  );
  return (
    <div data-testid="planning-alerts-table" className={tableClasses}>
      {planningAlerts.map((planningAlert) => (
        <PlanningAlertRow
          key={planningAlert._id}
          planningAlert={planningAlert}
          onEdit={() => onEdit(planningAlert)}
          detailed={detailed}
          totalNumber={totalNumber}
        />
      ))}
      {nextItems && <Waypoint onEnter={handleWaypointEnter} />}
    </div>
  );
};
