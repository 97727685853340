import Feature from "src/js/stores/user/Feature";
import { ConstraintsCategory } from "../../Constraints/types";
import { ConstraintsLayerType } from "../layer_types/ConstraintsLayerType";
import { Layer } from "../types";

export const IRREPLACEABLE_AREAS: Layer = {
  id: "irreplacable_areas",
  layerType: ConstraintsLayerType,
  title: "Irreplaceable Ancient Woodland",
  requiredFeatureAccess: [Feature.planningPolicyLayer],
  toggleable: true,
  description:
    "Recorded habitats that are classified as irreplaceable under Biodiversity Net Gain guidance",
  hideByDefault: true,
  layerConfig: {
    rootCategory: ConstraintsCategory.ANCIENT_WOODLAND,
    categorySchema: [{ key: "ancient_woodland" }],
  },
};
