import classNames from "classnames";

import { useTranslation } from "react-migration/lib/i18n/useTranslation";
import { useSnapshot } from "valtio";

import { ProductDetails } from "./components/ProductDetails";
import { letterStore } from "src/js/stores/letter/store";

export const DownloadSummarySection = () => {
  const { t } = useTranslation();

  const { selectedRecipientIds } = useSnapshot(letterStore);
  const quantityOfLetters = String(selectedRecipientIds?.length ?? 1);

  return (
    <div
      className={classNames("atlas-p-4", "atlas-bg-white", "atlas-rounded-b-lg")}
      data-testid="site-letter-payment-download-summary-section"
    >
      <ProductDetails unitPricePerLetter={t("sites.letters.free")} quantity={quantityOfLetters} />
    </div>
  );
};
