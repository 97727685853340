import type { GenericLayer, LayerType } from "../../types";
import { IsochroneMapLayer } from "./IsochroneMapLayer";
import { IsochroneControlPage } from "./IsochroneControlPage";
import { IsochroneLayerTypeProvider } from "./IsochroneContext";

export type IsochroneLayer = GenericLayer<typeof IsochroneLayerType>;

export const IsochroneLayerType: LayerType = {
  MapLayer: IsochroneMapLayer,
  ControlPage: IsochroneControlPage,
  Provider: IsochroneLayerTypeProvider,
};
