import { useQuery } from "@apollo/client";
import GET_PLANNING_ALERT_RESULTS from "../apollo/queries/planningAlertResults.gql";
import {
  LandTechEndpoints,
  routedClient,
} from "react-migration/lib/persistence/apollo/routedClient";
import { PlanningAlertResultResponse } from "../../typings/PlanningAlertResultsResponse";

type UsePlanningAlertResultsQueryParams = {
  emailed_at: string | null;
  limit: number;
  cursor: string | null;
  subscription_id: string;
};

export const usePlanningAlertResultsQuery = (args: UsePlanningAlertResultsQueryParams) => {
  return useQuery<PlanningAlertResultResponse>(GET_PLANNING_ALERT_RESULTS, {
    client: routedClient,
    variables: args,
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    context: {
      endpoint: LandTechEndpoints.PlanningService,
    },
  });
};
