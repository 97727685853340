import { Nullable } from "src/js/types/Nullable";

import { routedClient } from "react-migration/lib/persistence/apollo/routedClient";
import {
  OwnershipIntersectableGeometry,
  TitlesByLocationQueryData,
  TitlesByLocationQueryVariables,
} from "../useTitlesByLocationQuery";
import { titlePolyByLocation } from ".";
import { Title } from "react-migration/domains/ownership/typings/Title";

export const getTitlePolysByLocationQuery = async (
  location?: Nullable<OwnershipIntersectableGeometry>
) => {
  const { data } = await routedClient.query<
    TitlesByLocationQueryData,
    TitlesByLocationQueryVariables
  >({
    query: titlePolyByLocation,
    variables: { location: location! },
  });

  const titles: Title[] = data ? data.titlesByLocation : [];

  return titles;
};
