import { SearchFilterDTO } from "./SearchFilter";

export enum SearchFilterRequirement {
  ALL = "ALL",
  ANY = "ANY",
  NONE = "NONE",
}
export type SearchFilterRequirementDTO = keyof typeof SearchFilterRequirement;

export type SearchFilterConfigDTO = Partial<Record<SearchFilterRequirementDTO, SearchFilterDTO[]>>;
