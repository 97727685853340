import { ENVIRONMENT } from "../util/environment";

const getRequestInit = () => ({
  mode: "cors",
  headers: {
    "Content-Type": "application/json",
    "X-App-Version": ENVIRONMENT.APP_NPM_VERSION,
  },
});

export const fetchGet = (
  path: string,
  params: Record<string, string>,
  paramsOverride?: URLSearchParams
) => {
  const controller = new AbortController();
  const signal = controller.signal;

  let searchParams;
  if (paramsOverride) {
    searchParams = paramsOverride;
  } else {
    searchParams = new URLSearchParams(params);
  }

  return {
    abort: () => controller.abort(),

    //eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    result: fetch(`${ENVIRONMENT.API_URL}/${path.replace(/^\/+/, "")}?${searchParams.toString()}`, {
      ...getRequestInit(),
      method: "GET",
      signal,
    }),
  };
};

export const fetchPost = (path: string, body: unknown) => {
  const controller = new AbortController();
  const signal = controller.signal;

  return {
    abort: () => controller.abort(),
    //eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    result: fetch(`${ENVIRONMENT.API_URL}${path}`, {
      ...getRequestInit(),
      method: "POST",
      body: JSON.stringify(body),
      signal,
    }),
  };
};
