<template>
  <react-wrapper :component="Topup" />
</template>

<script>
import ReactWrapper from "view/wrappers/react-wrapper";
import { Topup } from "react-migration/domains/dashboard/topup/Topup";

export default {
  components: {
    ReactWrapper,
  },
  computed: {
    Topup() {
      return Topup;
    },
  },
};
</script>
