import { Position, Geometries, multiLineString } from "@turf/helpers";

export function createMultiLineString(geometries: Geometries[]) {
  const coordinates: Position[][] = [];

  geometries.forEach((g) => {
    if (g.type === "LineString") coordinates.push(g.coordinates);
    if (g.type === "MultiLineString")
      g.coordinates.forEach((position) => coordinates.push(position));
  });

  return multiLineString(coordinates);
}
