import { LayerTypeMapLayerProps } from "../../types";
import { useOwnershipLayerTypeContext } from "./Context";
import { OWNERSHIP_MIN_ZOOM } from "./constants";
import type { OwnershipLayer as OwnershipLayerType } from ".";
import { useCallback } from "react";
import hasFeature from "src/js/stores/user/actions/hasFeature";
import Feature from "src/js/stores/user/Feature";
import { OwnershipLayerComponent } from "react-migration/domains/ownership/components/map/OwnershipLayerComponent";
import { useSnapshot } from "valtio";
import { mapStore } from "src/js/stores/map/store";
import { OwnershipFeature } from "react-migration/domains/ownership/typings/Feature";

export function OwnershipMapLayer({ zOrder, layer, visible }: LayerTypeMapLayerProps) {
  const { parcelSizeRange, showKnownOwners, featureIsVisible } = useOwnershipLayerTypeContext();

  const isUSUser = hasFeature(Feature.usAccess);
  const hasUnlimitedOwnershipClicks = hasFeature(Feature.unlimitedOwnershipClicks);

  const minZoom = (layer as OwnershipLayerType).layerConfig?.minZoom || OWNERSHIP_MIN_ZOOM;
  const pickable =
    (layer as OwnershipLayerType).layerConfig?.pickable ?? hasUnlimitedOwnershipClicks;
  const displayKnownOwners = isUSUser ? false : showKnownOwners;

  const deckGLAdjustedZoom = minZoom - 1;
  const landAssembly = useSnapshot(mapStore).settings.landAssemblyMode;

  // TODO: investigate using sublayers instead of filtering features.
  const featureFilter = useCallback(
    (ownershipFeature: OwnershipFeature) => featureIsVisible(ownershipFeature.properties),
    [featureIsVisible]
  );

  return (
    <OwnershipLayerComponent
      key="OWNERSHIP"
      minZoom={deckGLAdjustedZoom}
      pickable={pickable}
      lineWidth={1}
      visible={visible}
      highlightOnHover={!landAssembly}
      parcelSizeMin={parcelSizeRange.from}
      parcelSizeMax={parcelSizeRange.to || Infinity}
      zOrder={zOrder}
      displayKnownOwners={visible && displayKnownOwners}
      fillOpacityMultiplier={0.6}
      featureFilter={featureFilter}
    />
  );
}
