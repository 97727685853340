/**
 * @deprecated use BaseMap and layer builders instead
 */

import "src/js/util/map_loader";

import { FC, useEffect, useRef, useState } from "react";

import { Deck, MapView } from "@deck.gl/core";
import { MapImageOverlay } from "../MapImageOverlay";

interface StaticMapProps {
  bounds: google.maps.LatLngBounds;
  siteLatLng: {
    lat: number;
    lng: number;
  };
  initialMapTypeId?: string;
  mapZoom?: number;
  onMapLoaded?: (isLoaded: boolean) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  layers: any[];
}

/**
 * Renders DeckGL layers ontop of a Google Maps Instance and then frees up the WebGL context.
 *
 * Use this if you need to render lots of maps at once that don't need to be interactive.
 *
 * Eg: In the Site Report
 *
 * 1) Initialise Google Map with provided options.
 * 1) Initialise a DeckGL context with the same viewport and zoom as the Google Map instance.
 * 1) Render provided layers into the DeckGL instance.
 * 1) Wait until layers are fully loaded and painted and then take a screenshot and save it as a data url into the client.
 * 1) Super impose the image as a Google Maps Overlay.
 * 1) Destroy the DeckGL (and thus the WebGL context) freeing up limits around the maximum amount of DeckGL contexts active at once.
 */
export const StaticMap: FC<StaticMapProps> = ({
  bounds,
  siteLatLng,
  initialMapTypeId,
  mapZoom,
  onMapLoaded,
  layers,
}) => {
  const mapDivRef = useRef<HTMLDivElement>(null);
  const [deck, setDeck] = useState<Deck<MapView>>();
  const [hasLoaded, setHasLoaded] = useState(false);

  useEffect(() => {
    if (!mapDivRef.current) return;

    const googleMap = new window.google.maps.Map(mapDivRef.current, {
      tilt: 0,
      heading: 0,
      zoom: mapZoom,
      mapTypeId: initialMapTypeId ?? "satellite",
      mapTypeControlOptions: {
        mapTypeIds: ["roadmap", "satellite"],
        style: google.maps.MapTypeControlStyle.DEFAULT,
        position: google.maps.ControlPosition.LEFT_BOTTOM,
      },
      draggable: false,
      zoomControl: false,
      scrollwheel: false,
      disableDoubleClickZoom: true,
      disableDefaultUI: true,
      keyboardShortcuts: false,
      maxZoom: 20,
      minZoom: 7,
      styles: [
        {
          featureType: "poi" as const,
          stylers: [{ visibility: "on" }],
        },
      ],
    });

    /*
     When we fit the map to the bounds of small sites then we lose a lot of context from the surrounding area.
     To ensure we get this context for small sites some consumers want to set a default zoom level.
     However, this default zoom level can sometimes be too restrictive and then we also lose context.
     This is especially relevant with very large or sites.
     So: 
     - First we fit the map to the requested bounds
     - If the zoom level after we fit the bounds is less zoomed than the maximum zoom then we keep the less zoomed in view
     - If the zoom level is too restricted after fitting the bounds, we set the map to the default zoom level

     This approach with a zoom_changed listener wouldn't work in a dynamic map because you wouldn't be able to zoom.
     You would have to calculate the zoom level from the bounds upfront and do the `setZoom` in the initial setup
    */
    googleMap.addListener("zoom_changed", () => {
      const currentZoom = googleMap.getZoom() ?? mapZoom;
      if (mapZoom && currentZoom && currentZoom > mapZoom) {
        googleMap.setZoom(mapZoom);
        googleMap.setCenter(siteLatLng);
      }
    });

    googleMap.fitBounds(bounds);

    googleMap.addListener("tilesloaded", () => {
      /*
        We must initialise DeckGL after the tiles have loaded so that we have access to google maps accessors such as getCenter().
        This is important because the deckGL image snapshot mush have the *exact* viewport and zoom of the google maps instace.
        We can most easily guarantee this by looking directly at the google maps accessors.
      */
      const deckGLZoom = googleMap.getZoom()! - 1; // quirk of DeckGL vs Google Maps is that their zoom levels are off by one
      const deckgl = new Deck<MapView>({
        width: googleMap.getDiv().offsetWidth,
        height: googleMap.getDiv().offsetHeight,
        initialViewState: {
          latitude: bounds.getCenter().lat(),
          longitude: bounds.getCenter().lng(),
          zoom: deckGLZoom,
        },
        layers,
        onAfterRender: ({ gl }) => {
          /*
           If all data has loaded within the deckGL layers 
           and we have finished painting (ie, afterRender)
           then it's likely that we have something worth screenshotting
          */
          const isLoaded = layers.every((layer) => layer.isLoaded);
          if (isLoaded) {
            setHasLoaded(true);
          }

          const dataUrl = (gl.canvas as HTMLCanvasElement).toDataURL("image/png");
          const overlay: MapImageOverlay = new MapImageOverlay(
            googleMap.getBounds() as google.maps.LatLngBounds,
            dataUrl
          );
          overlay.setMap(googleMap);
        },
      });
      setDeck(deckgl);

      return () => {
        deckgl.finalize();
      };
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bounds]);

  useEffect(() => {
    if (deck && hasLoaded) {
      deck.finalize();
      setDeck(undefined);
      onMapLoaded?.(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deck, hasLoaded]);

  return <div className="atlas-h-full atlas-rounded-sm" ref={mapDivRef} />;
};
