import { routedClient } from "react-migration/lib/persistence/apollo";
import selectedTitleGeometryQuery from "./selectedTitleGeometry.gql";
import titlesQuery from "./Titles.gql";
import { useQuery } from "@apollo/client/react";

interface TitleResponse {
  id: string;
  addresses: string[];
  polygons?: {
    geometry: GeoJSON.Polygon | GeoJSON.MultiPolygon;
  }[];
}

export interface SelectedTitleGeometryQueryData {
  title: TitleResponse;
}

export interface SelectedTitleGeometryQueryVariables {
  title_no?: string;
}

export const getTitleGeometryByTitleNo = async (title_no: string) => {
  const { data } = await routedClient.query<
    SelectedTitleGeometryQueryData,
    SelectedTitleGeometryQueryVariables
  >({
    query: selectedTitleGeometryQuery,
    variables: { title_no },
  });

  return data.title || null;
};

export interface TitlesQueryVariables {
  title_nos?: string[];
}

export interface TitlesQueryData {
  titles: TitleResponse[];
}

export function useTitlesQuery(titleNumbers: string[]) {
  return useQuery<TitlesQueryData, TitlesQueryVariables>(titlesQuery, {
    variables: { title_nos: titleNumbers },
    client: routedClient,
    skip: !titleNumbers.length,
  });
}
