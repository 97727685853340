<template>
  <div data-testid="site-utility-report-download-container" class="atlas-w-full atlas-h-full">
    <DownloadUtilityReport />
  </div>
</template>

<script>
import { DownloadUtilityReport } from "react-migration/domains/sites/utilityReport";

export default {
  components: {
    DownloadUtilityReport,
  },
  data: () => ({}),
};
</script>
