import { DesignationAttribute } from "react-migration/domains/constraints/typings/baseTypes/DesignationAttribute";
import { CategoryAttribute } from "../../../../domains/constraints/designation/style/StyleMap";

export type AttributeDataTypeRenderProps = {
  attribute: DesignationAttribute;
  schema?: CategoryAttribute;
};

function isLink(value: string) {
  return new RegExp(/^(https?:\/\/[^\s]+)/g).test(value);
}

export const AttributeDataTypeRender = ({ attribute, schema }: AttributeDataTypeRenderProps) => {
  if (!attribute.value) return null;

  if (schema && schema.dataType === "percentage") return <>{parseFloat(attribute.value) * 100}%</>;
  if (isLink(attribute.value.trim()))
    return (
      <a target="_blank" rel="noreferrer" href={attribute.value} key={attribute.value}>
        {attribute.value}
      </a>
    );

  return <>{attribute.value}</>;
};
