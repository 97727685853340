// Temporary polyfill to workaround where the vue router is currently used in some react files
export const router = {
  push: (route: string | Location) => {
    return window.APP?.$router?.push(route).catch(({ name }: { name: string }) => {
      if (name === "NavigationDuplicated") {
        return;
      }
    });
  },
  replace: (route: string | Location) => {
    return window.APP?.$router?.replace(route).catch(({ name }: { name: string }) => {
      if (name === "NavigationDuplicated") {
        return;
      }
    });
  },
  resolve: (route: string | Location) => {
    return window?.APP.$router?.resolve(route);
  },
};

export const redirectWithoutMapRefresh = ({ name, params, query }: WithoutRefreshParams) => {
  const { href } = router.resolve({
    name,
    params,
    query,
  });
  window.history.pushState({}, "", href);
};

type Dictionary<T> = { [key: string]: T };

type WithoutRefreshParams = {
  name?: string;
  params?: Dictionary<string>;
  query?: Dictionary<string>;
};

type Location = {
  name?: string;
  path?: string;
  hash?: string;
  query?: Dictionary<string | (string | null)[] | null | undefined>;
  params?: Dictionary<string>;
  append?: boolean;
  replace?: boolean;
};
