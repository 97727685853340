import { useCallback, useMemo } from "react";
import { MultiPolygon, Polygon } from "@turf/helpers";
import { Banner, InfoPopover } from "@landtechnologies/components";
import { SelectionFeature, SelectionType } from "src/js/stores/map/store";
import { useAccountQuery } from "react-migration/domains/dashboard/topup/hooks/useAccountQuery";
import { useTranslation } from "react-migration/lib/i18n/useTranslation";
import { TitleWithOwnerNames } from "../../hooks/useTitlesWithOwnerNamesQuery";
import { useSelectedTitle } from "../../hooks/useSelectedTitle";
import { LoadingPanel } from "../LoadingPanel";
import { Summary } from "../Summary";
import { logEventV2 } from "react-migration/lib/util/logEventV2";

interface UKOwnershipDetailPageProps {
  selection: SelectionFeature;
  selectedTitleNumber?: string;
  setDetailSelection?(selection?: SelectionFeature): void;
  hasUnlimitedOwnershipClicks: boolean;
}

export const UKOwnershipDetailPage = ({
  selection,
  selectedTitleNumber,
  setDetailSelection,
  hasUnlimitedOwnershipClicks,
}: UKOwnershipDetailPageProps) => {
  const { selectedTitle, loadingSelectedTitle } = useSelectedTitle(selectedTitleNumber);
  const { data } = useAccountQuery();
  const location = selection.feature!.geometry as Polygon | MultiPolygon;

  const ownershipViewsLimitReached = useMemo(
    () =>
      !hasUnlimitedOwnershipClicks &&
      !!data &&
      data.accountWithViews.ownership_views.cap <= data.accountWithViews.ownership_views.used,
    [data, hasUnlimitedOwnershipClicks]
  );

  const setSelectedOwner = useCallback(
    (name?: string) => {
      setDetailSelection?.(
        name
          ? {
              id: name,
              type: SelectionType.OWNER,
              feature: selection.feature,
              titleNumber: selectedTitleNumber,
            }
          : undefined
      );
      logEventV2({
        name: "Workbench card click",
        properties: {
          cardName: "Ownership",
          action: "goTo",
          clickDetail: "Title owner",
        },
      });
    },
    [selectedTitleNumber, selection.feature, setDetailSelection]
  );

  if (loadingSelectedTitle) {
    return <LoadingPanel />;
  }

  return (
    selectedTitle && (
      <TitleDetailLayout
        selectedTitle={selectedTitle}
        location={location}
        setSelectedOwner={setSelectedOwner}
        ownershipViewsLimitReached={ownershipViewsLimitReached}
      />
    )
  );
};

interface TitleDetailLayoutProps {
  selectedTitle: TitleWithOwnerNames;
  location: GeoJSON.MultiPolygon | GeoJSON.Polygon | GeoJSON.Point;
  setSelectedOwner(ownerName?: string): void;
  ownershipViewsLimitReached: boolean;
}

const TitleDetailLayout = ({
  selectedTitle,
  location,
  setSelectedOwner,
  ownershipViewsLimitReached,
}: TitleDetailLayoutProps) => {
  return (
    <>
      {ownershipViewsLimitReached && <OwnershipViewsLimitBanner />}
      <Summary
        geometry={location}
        setSelectedOwner={setSelectedOwner}
        selectedTitle={selectedTitle}
      />
    </>
  );
};

function OwnershipViewsLimitBanner() {
  const { t } = useTranslation();
  return (
    <Banner.Informative className="atlas-border-solid atlas-py-[2px] atlas-mx-2">
      <div className="atlas-flex atlas-justify-between atlas-items-center">
        <span className="atlas-text-xs atlas-text-neutral-600 atlas-font-normal">
          {t("sidebar.ownership_sidebar.reached_ownership_viewing_limit")}
        </span>
        <InfoPopover size="xs">
          {t("sidebar.ownership_sidebar.reached_ownership_viewing_limit_explanation")}
        </InfoPopover>
      </div>
    </Banner.Informative>
  );
}
