export function isWeekend() {
  const day = new Date().getDay();
  if (day === 0 || day === 6) {
    return true;
  } else if (day === 1) {
    const hours = new Date().getHours();
    return hours < 10; // before 10am (local time) on Monday
  } else return false;
}

export function isCurrentTimeBetween7amAnd11pm() {
  const now = new Date();

  const currentHour = now.getHours();
  const currentMinute = now.getMinutes();

  // Convert the current time to minutes since midnight
  const currentTimeInMinutes = currentHour * 60 + currentMinute;

  // Define the start time (7:00 AM) and end time (11:00 PM) in minutes since midnight
  const startTimeInMinutes = 7 * 60; // 7:00 AM
  const endTimeInMinutes = 23 * 60; // 11:00 PM

  // Check if the current time is within the range
  return currentTimeInMinutes >= startTimeInMinutes && currentTimeInMinutes < endTimeInMinutes;
}
