import { useMemo } from "react";
import * as DropdownMenu from "@radix-ui/react-dropdown-menu";
import { TenureType } from "react-migration/domains/ownership/typings/Tenure";
import { Title } from "react-migration/domains/ownership/typings/Title";
import { UNKNOWN_OWNER } from "../canonicalizeOwnerName";
import { DataPanel } from "react-migration/components/DataPanel";

export type TenureDropdownProps = {
  tenure: TenureType;
  owners: Record<string, Title[]>;
  setHoveredOwner(ownerName?: string): void;
  setSelectedOwner(ownerName?: string): void;
  selectedTitleNumber?: string;
};

export const TenureDropdown = ({
  tenure,
  owners,
  selectedTitleNumber,
  setSelectedOwner,
  setHoveredOwner,
}: TenureDropdownProps) => {
  const isFreehold = tenure === TenureType.FREEHOLD;
  const ownerEntries = useMemo(() => Object.entries(owners), [owners]);
  const isSingleOwner = ownerEntries.length === 1;

  const sortedOwners = useMemo(
    () =>
      [...ownerEntries]
        .sort(([ownerNameA], [ownerNameB]) => {
          return ownerNameA.toLowerCase() < ownerNameB.toLowerCase() ? -1 : 1;
        })
        .sort(([, titlesA]) => {
          return titlesA.find((t) => t.title_no === selectedTitleNumber) ? -1 : 1;
        })
        .sort(([ownerNameA], [ownerNameB]) => {
          return ownerNameA === UNKNOWN_OWNER ? 1 : ownerNameB === UNKNOWN_OWNER ? -1 : 0;
        }),
    [ownerEntries, selectedTitleNumber]
  );

  if (isSingleOwner) {
    return (
      <DataPanel.Cell title={tenureDisplayName(tenure, false)}>
        <DataPanel.Button
          onClick={() => setSelectedOwner(ownerEntries[0][0])}
          onMouseEnter={() => setHoveredOwner(ownerEntries[0][0])}
          onMouseLeave={() => setHoveredOwner()}
        >
          {isFreehold ? (
            <FreeholdDisplayName ownerName={sortedOwners[0][0]} />
          ) : (
            ownerEntries.length
          )}
        </DataPanel.Button>
      </DataPanel.Cell>
    );
  }

  return (
    <DropdownMenu.Root>
      <DataPanel.Cell title={tenureDisplayName(tenure, false)}>
        <DropdownMenu.Trigger asChild>
          <DataPanel.Button data-testid={`${tenure}_tenure-dropdown_trigger`}>
            {isFreehold ? (
              <FreeholdDisplayName ownerName={sortedOwners[0][0]} />
            ) : (
              ownerEntries.length
            )}
          </DataPanel.Button>
        </DropdownMenu.Trigger>
      </DataPanel.Cell>

      {ownerEntries.length > 1 && (
        <DropdownMenu.Portal>
          <DropdownMenu.Content
            sideOffset={5}
            collisionPadding={10}
            align="start"
            className="atlas-rounded atlas-max-w-[350px] atlas-bg-white atlas-divide-y atlas-divide-border-divider-subtle atlas-max-h-96 atlas-overflow-hidden atlas-overflow-y-auto atlas-z-[999] atlas-drop-shadow-xl"
          >
            {sortedOwners.map(([name, titles], i) => (
              <DropdownMenu.Item
                key={name}
                onMouseEnter={() => setHoveredOwner(name)}
                onMouseLeave={() => setHoveredOwner()}
                onClick={() => setSelectedOwner(name)}
                className="atlas-text-xs atlas-p-2 atlas-flex atlas-gap-2 atlas-justify-between atlas-font-semibold atlas-cursor-pointer hover:atlas-bg-neutral-100"
                data-testid={`tenure-dropdown_item-${i}`}
              >
                <div className="atlas-truncate">{displayName(name, titles, tenure)}</div>
              </DropdownMenu.Item>
            ))}
          </DropdownMenu.Content>
        </DropdownMenu.Portal>
      )}
    </DropdownMenu.Root>
  );
};

const displayName = (name: string, titles: Title[], tenure: TenureType) => {
  if (name !== UNKNOWN_OWNER) return name;
  return `OTHER ${tenureDisplayName(tenure, true)?.toUpperCase()}`;
};

const FreeholdDisplayName = ({ ownerName }: { ownerName: string }) => {
  return (
    <div className="atlas-flex atlas-gap-1">
      <div className="atlas-truncate">{ownerName}</div>
    </div>
  );
};

const tenureDisplayName = (tenure: TenureType, plural: boolean) => {
  if (tenure === TenureType.FREEHOLD) return plural ? "Freeholders" : "Freeholder";
  if (tenure === TenureType.LEASEHOLD) return plural ? "Leaseholders" : "Leaseholder";
  if (tenure === TenureType.UNKNOWN_OTHER) return plural ? "Unknown Tenures" : "Unknown Tenure";
};
