import { getPermissions } from "src/js/stores/user/actions/getPermissions";
import { booleanPointInPolygon, polygon, multiPolygon } from "@turf/turf";
import intersect from "@turf/intersect";
import { logEvent } from "react-migration/lib/util/logEvent";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Site = any;

interface Options {
  enableLogging?: boolean;
}

export const filterSitesInGeofence = (sites: Site[], { enableLogging }: Options = {}) => {
  const geofencesGeometries = getPermissions()?.geofencesGeometries || [];

  if (!geofencesGeometries.length) {
    return sites;
  }

  const filteredSites = sites.filter((site) => {
    return geofencesGeometries.some((geometry) => {
      if (!site.geometry) {
        return null;
      }
      if (site.geometry.type === "Point") {
        return booleanPointInPolygon(site.geometry.coordinates, geometry);
      }

      if (site.geometry.type === "Polygon") {
        const polygonT = polygon(site.geometry.coordinates);
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        return intersect(polygonT, geometry);
      }

      if (site.geometry.type === "MultiPolygon") {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        return intersect(multiPolygon(site.geometry.coordinates), geometry);
      }
    });
  });
  if (enableLogging) {
    if (filteredSites.length < sites.length) {
      const logMessage = `Attempt to save ${
        sites.length - filteredSites.length
      } that are not in geofence`;
      console.warn(logMessage);
      logEvent("AttemptSaveSiteOutsideOfGeofence", {
        logMessage,
      });
    }
  }
  return filteredSites;
};
