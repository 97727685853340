import { ENVIRONMENT } from "src/js/util/environment";

import { ApolloClient, HttpLink, InMemoryCache, from } from "@apollo/client";
import { BatchHttpLink } from "@apollo/client/link/batch-http";
import { ErrorResponse, onError } from "@apollo/client/link/error";
import { RetryLink } from "@apollo/client/link/retry";
import { datadogRum } from "@datadog/browser-rum";
//
import liApiFragementMatcher from "@gql/li-api/fragment-matcher.json";
import { StrictTypedTypePolicies } from "@gql/li-api/apollo-client-helpers";
import { relayStylePagination } from "@apollo/client/utilities";

export enum LandTechEndpoints {
  Constraints,
  PrivateClient,
  Sites,
  Gateway,
  BatchedGateway,
  PlanningService,
  Letters,
}

const errorLink = onError(({ networkError }: ErrorResponse) => {
  if (networkError && "statusCode" in networkError) {
    if (networkError.statusCode === 401) {
      datadogRum.addAction("graphql-error-logout", {
        errorLink: "routed",
      });
      window.location.assign(ENVIRONMENT.LOGIN_URL!);
    }
  }
});

const clientRouter = new RetryLink()
  .split(
    (operation) => {
      return operation.getContext().endpoint === LandTechEndpoints.Constraints;
    },
    new HttpLink({
      uri: `${ENVIRONMENT.CONSTRAINTS_API_URL}/graphql`,
      headers:
        ENVIRONMENT.APP_ENV === "local"
          ? {
              user_id: "something",
              features: "readConstraints",
            }
          : undefined,
    })
  )
  .split(
    (operation) => {
      return operation.getContext().endpoint === LandTechEndpoints.Gateway;
    },
    new HttpLink({
      uri: ENVIRONMENT.GRAPHQL_GATEWAY_URL,
    })
  )
  .split(
    (operation) => {
      return operation.getContext().endpoint === LandTechEndpoints.BatchedGateway;
    },
    new BatchHttpLink({
      uri: ENVIRONMENT.GRAPHQL_GATEWAY_URL,
    })
  )
  .split(
    (operation) => {
      return operation.getContext().endpoint === LandTechEndpoints.PrivateClient;
    },
    new HttpLink({
      uri: `${ENVIRONMENT.ACCOUNTS_SERVICE_PRIVATE_API_URL}/graphql`,
    })
  )
  .split(
    (operation) => {
      return operation.getContext().endpoint === LandTechEndpoints.Sites;
    },
    new HttpLink({
      uri: `${ENVIRONMENT.SITES_SERVICE_URL}/graphql`,
    })
  )
  .split(
    (operation) => {
      return operation.getContext().endpoint === LandTechEndpoints.Letters;
    },
    new HttpLink({
      uri: `${ENVIRONMENT.LETTERS_SERVICE_URL}/public/graphql`,
    })
  )
  .split(
    (operation) => {
      return operation.getContext().endpoint === LandTechEndpoints.PlanningService;
    },
    new HttpLink({
      uri: `${ENVIRONMENT.PLANNING_SERVICE_V2_URL_EXTERNAL}/graphql`,
    })
  );

const typePolicies: StrictTypedTypePolicies = {
  Query: {
    fields: {
      ownershipSearch: {
        keyArgs: ["search", "sort"],
        merge: true,
      },
      sites: relayStylePagination([
        "assignees",
        "includeHidden",
        "localAuthorities",
        "searchString",
        "siteIds",
        "siteLabels",
        "siteSort",
        "stageIds",
      ]),
    },
  },
};
const cache = new InMemoryCache({
  possibleTypes: {
    ...liApiFragementMatcher.possibleTypes,
  },
  typePolicies,
});

const LOG_FREQUENCY_MS = 1000 * 60 * 30;
function logCacheSize(cache: InMemoryCache) {
  import("@datadog/browser-rum")
    .then(({ datadogRum }) => {
      datadogRum.addAction("apollo-cache", {
        // @ts-expect-error data is marked as private but is accessible
        cacheSize: Object.keys(cache.data.data).length,
      });

      setTimeout(() => logCacheSize(cache), LOG_FREQUENCY_MS);
    })
    .catch((error) => {
      console.error("Failed to log deck.gl metrics", error);
    });
}

// trigger the first log after 1/6 of the frequency (5 minutes)
setTimeout(() => logCacheSize(cache), LOG_FREQUENCY_MS / 6);

export const routedClient = new ApolloClient({
  // Client Router or Fallback to LI
  link: from([
    errorLink,
    clientRouter,
    new HttpLink({
      uri: `${ENVIRONMENT.API_URL}/graphql`,
    }),
  ]),
  cache,
});

export const routedClientWithoutTypenames = new ApolloClient({
  // Client Router or Fallback to LI
  link: from([
    errorLink,
    clientRouter,
    new HttpLink({
      uri: `${ENVIRONMENT.API_URL}/graphql`,
    }),
  ]),
  cache: new InMemoryCache({ addTypename: false }),
});
