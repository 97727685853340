import { DrawingItem } from "@landtechnologies/drawings-frontend/lib/entities/DrawingItem";
import { drawingStore } from "../store";

export const updatedSelectedGeometry = (drawing: DrawingItem) => {
  const actions = drawingStore.reduxStore?.actions;
  const store = drawingStore.reduxStore?.store;
  if (!store || !actions) return;

  store.dispatch(actions.updateDrawing(drawing, true));
  store.dispatch(actions.clearSelected());
  store.dispatch(actions.setSelectedState(drawing.id));
};
