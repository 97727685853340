import { RecursiveTree } from "./types";

const EMPTY_DESCENDANTS: RecursiveTree<unknown>[] = [];

export const listDescendants = <U>(node: RecursiveTree<U>): string[] => {
  const subNodes = node.childNodes ?? EMPTY_DESCENDANTS;
  const flatChildren = subNodes.flatMap(listDescendants);
  return [...flatChildren, node.id];
};

export const getSubTree = <U>(node: RecursiveTree<U>, id: string): RecursiveTree<U> | null => {
  if (node.id === id) return node;
  const subNodes = node.childNodes ?? EMPTY_DESCENDANTS;
  return subNodes.reduce(
    (prev: RecursiveTree<U> | null, subNode: RecursiveTree<U>) => prev ?? getSubTree(subNode, id),
    null
  );
};
