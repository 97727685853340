import Feature from "src/js/stores/user/Feature";
import { ConstraintsCategory } from "../../Constraints/types";
import { Layer } from "./../types";
import { ConstraintsLayerType } from "../layer_types/ConstraintsLayerType/ConstraintsLayerType";

const LISTED_BUILDINGS_LAYER_ID = "LISTED_BUILDINGS_LAYER";

const LISTED_BUILDINGS_LAYER_BASE: Layer = {
  id: LISTED_BUILDINGS_LAYER_ID,
  layerType: ConstraintsLayerType,
  title: "Listed Buildings",
  requiredFeatureAccess: [Feature.propertyInformationLayer],
  description: "",
  toggleable: true,
  hideByDefault: true,
  layerConfig: {
    rootCategory: ConstraintsCategory.POLICY,
    layerRoots: ["heritage_land_listed_buildings_outer"],
    categorySchema: [{ key: "heritage_land_listed_buildings_outer" }],
  },
};

// Visible to all users
export const LISTED_BUILDINGS_LAYER_MAP = {
  ...LISTED_BUILDINGS_LAYER_BASE,
};

// Visible only to starter/pro via `listedBuildingsStandaloneLayer` feature
export const LISTED_BUILDINGS_LAYER_CONSIDERATIONS_LIST = {
  ...LISTED_BUILDINGS_LAYER_BASE,
  requiredFeatureAccess: [Feature.listedBuildingsStandaloneLayer],
};
