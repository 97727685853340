import { useMemo } from "react";
import { userStore } from "src/js/stores/user/store";
import { useSnapshot } from "valtio";
import * as Tabs from "../map/Multilayer/Bundle/Workbench/AssessmentTab/layersByTab";
import Feature from "src/js/stores/user/Feature";
import { Layer } from "../map/Multilayer/types";

type AssessmentTabs = Record<string, Layer[]>;

export function useAssessmentTabs(): AssessmentTabs {
  const { features } = useSnapshot(userStore.user);
  const isUS = features.includes(Feature.usAccess);

  return useMemo(() => {
    const tabs = isUS ? Tabs.US_ASSESSMENT_LAYER_BY_TAB : Tabs.UK_ASSESSMENT_LAYER_BY_TAB;

    return Object.fromEntries(
      Object.entries(tabs)
        .map(([key, layers]) => [
          key,
          layers.filter((layer) => layer.layerType.Printable && !layer.excludeFromReport),
        ])
        .filter(([_, layers]) => !!layers.length)
    );
  }, [isUS]);
}
