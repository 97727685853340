import { useMemo } from "react";
import { DesignationLayer } from "react-migration/domains/constraints/components/ConstraintLayer/DesignationLayer";
import { buildConstraintsTileUrl } from "react-migration/domains/constraints/components/ConstraintLayer/ConstraintLayer";
import { ConstraintLayerConfig } from "../types";
import { geoJsonPolyToGoogleBounds, getZoomLevelForBounds } from "src/js/util/map_util";
import buffer from "@turf/buffer";
import { SelectionFeature } from "src/js/stores/map/store";
import { centerOfMass } from "@turf/turf";
import { SelectionLayer } from "../../../Bundle/useSelectionMapLayer";
import { useConstraintsLayerTypeContext } from "../ConstraintsContext";
import { tileDesignationIsVisible } from "../ConstraintsContext/utils";
import { StaticMap } from "react-migration/domains/sites/card/Map/renderers/StaticMapReact";

interface PrintableMapLayerProps {
  id: string;
  selection: SelectionFeature;
  layerConfig: ConstraintLayerConfig;
  onLoaded: () => void;
}

export function PrintableMapLayer({
  layerConfig,
  id,
  selection,
  onLoaded,
}: PrintableMapLayerProps) {
  const { constraintsStore } = useConstraintsLayerTypeContext();
  const layerState = constraintsStore.layers[id];

  const selectionGeometry = selection.feature?.geometry;

  const bounds = useMemo(() => {
    if (!selectionGeometry) return null;

    return geoJsonPolyToGoogleBounds(buffer(selectionGeometry, 60, { units: "meters" }).geometry);
  }, [selectionGeometry]);

  const latLng = useMemo(() => {
    if (!selectionGeometry) return null;

    const center = centerOfMass(selectionGeometry);

    return { lat: center.geometry.coordinates[1], lng: center.geometry.coordinates[0] };
  }, [selectionGeometry]);

  const mapLayers = useMemo(() => {
    if (!selection || !layerState.visibleCategories.length) return null;

    return [
      new DesignationLayer({
        id: id,
        data: buildConstraintsTileUrl(layerConfig),
        featureIsVisible: (designation) => tileDesignationIsVisible(designation, layerState),
      }),
      new SelectionLayer({ selection }),
    ];
  }, [id, layerConfig, layerState, selection]);

  if (!bounds || !latLng || !mapLayers) return null;

  return (
    <StaticMap
      id={id}
      height={280}
      width={280}
      center={latLng}
      zoom={getZoomLevelForBounds(bounds, { width: 280, height: 280 })}
      layers={mapLayers}
      onReady={onLoaded}
    />
  );
}
