import { useTranslation } from "react-migration/lib/i18n/useTranslation";

export type PurchaseSucceededProps = {
  physical: boolean;
};
export function PurchaseSucceeded({ physical }: PurchaseSucceededProps) {
  const { t } = useTranslation();

  return (
    <div data-testid="purchase-succeeded" className="atlas-flex atlas-flex-col atlas-gap-4">
      <strong>{t("title_purchase.succeeded.title")}</strong>
      {physical && (
        <p data-testid="purchase-physical">
          <strong>{t("title_purchase.succeeded.physical")}</strong>
        </p>
      )}
    </div>
  );
}
