import { parseStringToNumber } from "react-migration/domains/constraints/designation/style/utils";
import { DesignationAttribute } from "react-migration/domains/constraints/typings/baseTypes/DesignationAttribute";

export function getAttributeNumericValue(
  designation_attributes: DesignationAttribute[] | undefined,
  attributeKey: string
) {
  const attributeValue = getAttributeValue(designation_attributes, attributeKey);
  try {
    return parseStringToNumber(attributeValue);
  } catch {
    return undefined;
  }
}

export function getAttributeValue(
  designation_attributes: DesignationAttribute[] | undefined,
  attributeKey: string
) {
  return designation_attributes?.find((a) => a.key === attributeKey)?.value;
}
