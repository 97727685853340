import { useTranslation } from "react-migration/lib/i18n/useTranslation";

import { Button } from "@landtechnologies/components";

import { Footer } from "../components/Footer";
import { DownloadLetter } from "./components/DownloadLetter";

export const DownloadLetterStep = () => {
  const { t } = useTranslation();

  return (
    <div
      data-testid="letters-download-letter-step"
      className="atlas-h-full atlas-w-full atlas-flex atlas-flex-col"
    >
      <DownloadLetter />

      <Footer percentage={100}>
        <div className="atlas-flex atlas-flex-grow atlas-justify-end atlas-invisible">
          <Button.Primary disabled>{t("sites.letters.back_to_site")}</Button.Primary>
        </div>
      </Footer>
    </div>
  );
};
