import { useCallback, useEffect, useId, useMemo } from "react";
import { useSnapshot } from "valtio";
import { mapStore } from "src/js/stores/map/store";
import { getSubTree, listDescendants } from "react-migration/components/CheckboxTree/utils";
import { DesignationFeatureProps } from "react-migration/domains/constraints/components/ConstraintLayer/DesignationLayer";
import { ConstraintsCategory } from "react-migration/layouts/map/Constraints/types";
import { ConstraintLayer } from "react-migration/domains/constraints/components";
import {
  getIconKeySet,
  getStyledAttributes,
} from "react-migration/domains/constraints/designation/style/accessors";
import { TrackingAppender, useEventTracking } from "../../Bundle/EventTrackingProvider";
import { ConstraintsLayerId, LAYER_ID_SEPARATOR, Layer, LayerTypeMapLayerProps } from "../../types";
import { useConstraintsLayerTypeContext } from "./ConstraintsContext/ConstraintsContext";
import { ConstraintLayerConfig, ConstraintsLayer } from "./types";
import { CONSTRAINTS_MIN_ZOOM } from "./constants";
import { tileDesignationIsVisible } from "./ConstraintsContext/utils";

const EMPTY_VISIBLE_CATEGORIES: string[] = [];

const useBuildLayerId = (layer: Layer): ConstraintsLayerId =>
  `${layer.id}${LAYER_ID_SEPARATOR}${useId()}`;

type ConstraintsAppender = {
  constraintsCategoriesVisible: string[];
  constraintsStatusHidden: string[];
};

export function ConstraintsMapLayer(props: LayerTypeMapLayerProps) {
  const layerId = props.layer.id;
  const layerConfig = props.layer.layerConfig as ConstraintLayerConfig;
  const { constraintsStore } = useConstraintsLayerTypeContext();
  const layerState = constraintsStore.layers[layerId];

  const { registerTrackingAppender } = useEventTracking();

  useEffect(() => {
    const appender: TrackingAppender<ConstraintsAppender> = (current) => {
      const categoriesInLayersSchema = layerConfig.categorySchema.map((x) => x.key) || [];

      const visibleCategoriesInLayerSchema = layerState.visibleCategories.filter((x) =>
        categoriesInLayersSchema.includes(x)
      ); // we only want the categories that are represented in the checkbox schema

      return {
        ...current,
        // We cannot send JSON stringified version of layerState fields max 255 chars, only arrays can bypass limit.
        constraintsCategoriesVisible: [
          ...(current.constraintsCategoriesVisible || []),
          ...visibleCategoriesInLayerSchema,
        ],
        constraintsStatusHidden: [
          ...new Set([...(current.constraintsStatusHidden || []), ...layerState.hiddenStatuses]),
        ],
      };
    };

    registerTrackingAppender?.(layerId, appender);
  }, [
    layerId,
    registerTrackingAppender,
    layerConfig.categorySchema,
    layerState.hiddenStatuses,
    layerState.visibleCategories,
  ]);

  if (layerConfig.layerRoots) {
    return <ExpandedConstraintsMapLayer {...props} />;
  } else {
    return <SingleConstraintsMapLayer {...props} />;
  }
}

function SingleConstraintsMapLayer({
  layer,
  zOrder,
  visible,
  detailSelection,
}: LayerTypeMapLayerProps) {
  const id = useBuildLayerId(layer);
  const { zoom: googleZoom } = useSnapshot(mapStore.settings);
  const { constraintsStore } = useConstraintsLayerTypeContext();
  const layerState = constraintsStore.layers[layer.id];
  const visibleCategories = layerState?.visibleCategories || EMPTY_VISIBLE_CATEGORIES;
  const layerConfig = layer.layerConfig as ConstraintLayerConfig;
  const showLabels = layerConfig.showConstraintLabels ?? false;
  const layerName = layerConfig.rootCategory;
  const minZoom = layerConfig.minZoom || CONSTRAINTS_MIN_ZOOM;
  const maxZoom = layerConfig.maxZoom;
  const maxVisibleZoom = layer.zoomConfig?.maxVisualisationZoom;
  const isBeyondVisibleZoom = maxVisibleZoom && googleZoom > maxVisibleZoom + 1;
  const key = layerName as string;
  const rootCategory: ConstraintsCategory = (layer as ConstraintsLayer)!.layerConfig!.rootCategory;
  const dedupePoints = layerConfig.dedupePoints;
  const filterAreaByZoom = layerConfig.filterAreaByZoom;
  const simplifyProfile = layerConfig.simplifyProfile;

  const [attributes, iconKeySet] = useMemo(() => {
    const subCategoryIds = layerConfig.categorySchema.map((c) => c.key);
    return [getStyledAttributes(subCategoryIds), getIconKeySet(subCategoryIds)];
  }, [layerConfig]);

  const featureIsVisible = useCallback(
    (designation: DesignationFeatureProps) => {
      if (detailSelection) return false;
      return tileDesignationIsVisible(designation, layerState);
    },
    [detailSelection, layerState]
  );

  if (!visible || !visibleCategories.length || isBeyondVisibleZoom) {
    return null;
  }

  return (
    <ConstraintLayer
      key={key}
      iconKeySet={iconKeySet}
      layerId={id}
      layerName={layerName}
      rootCategory={rootCategory}
      designationAttributes={attributes}
      minZoom={minZoom}
      maxZoom={maxZoom}
      featureIsVisible={featureIsVisible}
      showLabels={showLabels}
      dedupePoints={dedupePoints}
      filterAreaByZoom={filterAreaByZoom}
      zoomPointScale={layerConfig.zoomPointScale}
      zOrder={zOrder}
      simplifyProfile={simplifyProfile}
      collisionFilter={layerConfig.collisionFilter}
    />
  );
}

/**
 * Component that expands layer categories into unique `SingleConstraintsMapLayer`.
 *
 * Note: This is only used if layerConfig.layerRoots is defined.
 */
function ExpandedConstraintsMapLayer(props: LayerTypeMapLayerProps) {
  const { layer } = props;
  const { constraintsStore } = useConstraintsLayerTypeContext();
  const { visibleCategories, categoryTree } = constraintsStore.layers[layer.id];
  const layerConfig = layer.layerConfig as ConstraintLayerConfig;
  const layerRoots = useMemo(() => layerConfig.layerRoots || [], [layerConfig.layerRoots]);

  const layerConfigDictionary = useMemo(
    () =>
      Object.fromEntries(
        layerRoots.map((category) => [
          category,
          {
            ...layer,
            layerConfig: { ...layerConfig, rootCategory: category },
          },
        ])
      ),
    [layerRoots, layer, layerConfig]
  );

  const rootIncludesVisibleCategory = useCallback(
    (rootId: string) => {
      //Naive approach - allows visible root-layers to start rendering before the category tree is populated.
      if (categoryTree === null) return visibleCategories.includes(rootId);

      //Once category tree is loaded, descendents of hidden root-categories may load as well.
      const subTree = getSubTree(categoryTree, rootId);
      const descendents = subTree ? listDescendants(subTree) : [];
      return visibleCategories.some((visibleCategory) => descendents.includes(visibleCategory));
    },
    [categoryTree, visibleCategories]
  );

  return layerRoots
    .filter(rootIncludesVisibleCategory)
    .map((category, i) => (
      <SingleConstraintsMapLayer
        {...props}
        key={category}
        zOrder={props.zOrder - i * (0.99 / layerRoots.length)}
        layer={layerConfigDictionary[category]}
      />
    ));
}
