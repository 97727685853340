import { useQuery } from "@apollo/client";
import { PaymentMethod } from "react-migration/domains/constraints/typings/applicationTypes/StripeTypes";
import {
  LandTechEndpoints,
  routedClient,
} from "react-migration/lib/persistence/apollo/routedClient";
import GET_STRIPE_PAYMENT_METHODS from "../apollo/queries/stripePaymentMethods.gql";

export const useStripePaymentMethodsQuery = () => {
  return useQuery<PaymentMethod>(GET_STRIPE_PAYMENT_METHODS, {
    client: routedClient,
    context: {
      endpoint: LandTechEndpoints.PrivateClient,
    },
  });
};
