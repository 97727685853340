import { yearsAgoMs } from "react-migration/lib/util/yearsAgoMS";
import {
  ClassAndTypeFilters,
  PlanningApplicationFeatureProps,
  PlanningClassification,
  PlanningFilterKey,
  PlanningTypeFlagKey,
  PlanningTypesFilters,
} from "../../../types";
import { POINTLESSLY_LARGE_NUMBER } from "src/js/util/units_and_constants";

type BooleanFilterRange = [number, number];
type DateReceivedMsRange = [number, number];
type DwellingCountRange = [number, number];
type PlanningFilterRange = [BooleanFilterRange, DateReceivedMsRange, DwellingCountRange];

// Given a filterRange type, will give the return type of paired getFitlerValue
// method. This ensures that if the filterRange changes the getFilterValue
// signature will also be updated to match.
type GetFilterValueReturnType<R extends [unknown, unknown][]> = {
  [K in keyof R]: R[K] extends unknown[] ? R[K][0] : unknown;
};

// Describes the required subset of the planning app GeoJSON feature required to
// run the DeckGL filters. These filters are reused in the Vue layer which
// constructs a faux GeoJSON feature conforming to this type.
export interface FilterableFeature {
  properties: Omit<PlanningApplicationFeatureProps, "ref">;
}

export interface PlanningApplicationLayerFilterProps {
  filterRange: PlanningFilterRange;
  getFilterValue(d: FilterableFeature): GetFilterValueReturnType<PlanningFilterRange>;
}

// Map type filter keys to GeoJSON property flags
export const typePropertyKeyLookup: Record<PlanningFilterKey, PlanningTypeFlagKey> = {
  dischargeOfConditions: "is_discharge_of_conditions",
  fullApplication: "is_full_application",
  reservedMatters: "is_reserved_matters",
  listedBuildings: "is_listed_building",
  changeOfUse: "is_change_of_use",
  outline: "is_outline",
  gpdr: "is_gpdr",
  tpo: "is_tpo",
  eia: "is_eia",
  uncategorised: "is_uncategorised",
};

export function checkIsCommercial(d: FilterableFeature) {
  return d.properties.classification === "COMMERCIAL";
}

export function checkIsMixedUse(d: FilterableFeature) {
  return d.properties.classification === "MIXED_USE";
}

export function checkIsResidential(d: FilterableFeature) {
  return d.properties.classification === "RESIDENTIAL";
}

export function checkIsOther(d: FilterableFeature) {
  return (
    d.properties.classification === PlanningClassification.OTHER ||
    d.properties.classification === PlanningClassification.UNKNOWN
  );
}

export function checkIsMinor(d: FilterableFeature) {
  return d.properties.is_minor && d.properties.classification === "COMMERCIAL";
}

export function applyTypeFilters(
  application: FilterableFeature,
  planningFilters: PlanningTypesFilters
) {
  const filters = Object.entries(planningFilters) as [PlanningFilterKey, boolean][];

  for (const [filterKey, filterValue] of filters) {
    if (!filterValue) continue; // don't care about unticked filters

    const propertyKey = typePropertyKeyLookup[filterKey];

    // if datum has true for this ticked filter, we can jump out early
    if (application.properties[propertyKey]) return true;
  }

  return false;
}

export function applyClassAndTypeFilters(
  application: FilterableFeature,
  filters: ClassAndTypeFilters
) {
  const { showCommercial, showResidential, showOther, planningTypes, enabledStatuses } = filters;

  if (!showResidential && checkIsResidential(application)) return false;
  if (!showCommercial && checkIsCommercial(application)) return false;
  if (!showOther && checkIsOther(application)) return false;
  if (!(showResidential || showCommercial) && checkIsMixedUse(application)) return false;

  if (
    enabledStatuses &&
    application.properties.status_derived &&
    !enabledStatuses.includes(application.properties.status_derived)
  )
    return false;

  return applyTypeFilters(application, planningTypes);
}

export function createPlanningApplicationLayerFilters(
  filters: ClassAndTypeFilters
): PlanningApplicationLayerFilterProps {
  function getFilterValue(app: FilterableFeature): GetFilterValueReturnType<PlanningFilterRange> {
    const shouldBeHidden = filters.appToHide === app.properties.id;

    const insideVisiblePlanningApplicationIds =
      filters.visiblePlanningApplicationIds.length > 0
        ? filters.visiblePlanningApplicationIds.includes(app.properties.id)
        : true;

    const isVisible =
      insideVisiblePlanningApplicationIds &&
      !shouldBeHidden &&
      applyClassAndTypeFilters(app, filters);

    let { size } = app.properties;

    if (checkIsCommercial(app) || checkIsOther(app)) {
      // ignore size filter for commercial points
      size = 99999;
    }

    return [isVisible ? 1 : 0, app.properties.date_received, size];
  }

  const filterRange: PlanningFilterRange = [
    [1, 1],
    // TODO: We no longer need to filter by year on the client as this is now
    // handled on the backend.
    [yearsAgoMs(filters.maxYear), Date.now()],
    [filters.minSize, POINTLESSLY_LARGE_NUMBER],
  ];

  return { getFilterValue, filterRange };
}
