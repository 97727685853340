import { MultiPolygon, Polygon, union } from "src/js/util/turf";
import { Feature, Properties, feature } from "@turf/helpers";
import buffer from "@turf/buffer";

const cleanMergedPolygon = (polygonsToClean: Feature<Polygon | MultiPolygon, Properties>) => {
  if (polygonsToClean?.geometry?.type === "Polygon") {
    polygonsToClean.geometry.coordinates = [polygonsToClean.geometry?.coordinates[0]];
  } else if (polygonsToClean?.geometry?.type === "MultiPolygon") {
    polygonsToClean.geometry.coordinates = polygonsToClean.geometry?.coordinates.map((x) => [x[0]]);
  }
  return polygonsToClean;
};

export const mergeSiteGeometries = (
  siteA: Polygon | MultiPolygon,
  siteB: Polygon | MultiPolygon
) => {
  const sites = [feature(siteA), feature(siteB)] as Feature<Polygon>[];

  try {
    return cleanMergedPolygon(union(...sites));
  } catch {
    return cleanMergedPolygon(union(...sites.map(remedyInvalidSite)));
  }
};

/**
 * Remedy an invalid site geometry by buffering it out and then buffering it back in.
 * @param site - The site geometry to be remedied.
 * @returns The remedied site geometry.
 */
function remedyInvalidSite(site: Feature<Polygon, Properties>) {
  const siteBuffedOut = buffer(site, 0.1, { units: "centimeters" });
  return buffer(siteBuffedOut, -0.1, { units: "centimeters" }) as Feature<Polygon>;
}
