import { useQuery } from "@apollo/client";
import GET_SELECTED_ALERT_SUBSCRIPTION from "../apollo/queries/selectedAlertSubscription.gql";
import {
  LandTechEndpoints,
  routedClient,
} from "react-migration/lib/persistence/apollo/routedClient";
import { SelectedAlertSubscriptionResponse } from "../../typings/SelectedAlertSubscriptionResponse";

type UseSelectedAlertSubscriptionQueryParams = {
  alert_id: string;
};

export const useSelectedAlertSubscriptionQuery = (
  args: UseSelectedAlertSubscriptionQueryParams
) => {
  return useQuery<SelectedAlertSubscriptionResponse>(GET_SELECTED_ALERT_SUBSCRIPTION, {
    client: routedClient,
    variables: args,
    fetchPolicy: "network-only",
    context: {
      endpoint: LandTechEndpoints.PlanningService,
    },
  });
};
