import { esBoundsToGoogleBounds, googleBoundsToGeoJsonPoly } from "util/map_util";
import sortBy from "lodash/sortBy";

import LocationClusterer from "util/location_clusterer";
import google from "util/map_loader";

export default {
  getResultLatLngs(searchResults, resultLatLngGetter) {
    return searchResults
      .map((result) => {
        const coords = resultLatLngGetter(result);
        return coords && new google.maps.LatLng(coords[1], coords[0]);
      })
      .filter((result) => !!result);
  },
  getOutOfBoundsLatLngs(bounds, latLngs) {
    return latLngs.filter((p) => !bounds.contains(p));
  },
  getOutOfBoundsResults(bounds, searchResults, resultLatLngGetter) {
    return this.getOutOfBoundsLatLngs(
      bounds,
      this.getResultLatLngs(searchResults, resultLatLngGetter)
    );
  },
  zoomMapToFitLatLngs(map, latLngs) {
    const targetBounds = new google.maps.LatLngBounds();
    latLngs.forEach((latLng) => targetBounds.extend(latLng));
    map.setZoom(21);
    this.zoomMapToBounds(map, targetBounds);
    setTimeout(
      () =>
        // DM: why again?
        this.getOutOfBoundsLatLngs(map.getBounds(), latLngs).length &&
        this.zoomMapToFitLatLngs(map, latLngs),
      0
    );
  },
  zoomMapToBounds(map, bounds) {
    map.fitBounds(bounds);
    map.fitBounds(bounds); // DM: why twice?
  },
  clusterResults(zoom, searchResults, resultLatLngGetter) {
    const resultsToCluster = searchResults
      .map((result) => ({
        item: result,
        getPosition: () => {
          const coords = resultLatLngGetter(result);
          return coords && new google.maps.LatLng(coords[1], coords[0]);
        },
      }))
      .filter((toCluster) => toCluster.getPosition() != null);

    const maxZoom = 22;
    zoom = Math.min(maxZoom, zoom);
    const gridSize = Math.pow(2, maxZoom - zoom);
    const clusterMaker = new LocationClusterer(resultsToCluster, { gridSize });
    clusterMaker.redraw();
    return clusterMaker.getClusters().map((cluster) => {
      const location = cluster.getCenter();
      return {
        results: cluster.getMarkers().map((marker) => marker.item),
        location,
        id: `${zoom}|${location.lat()}|${location.lng()}`, // useful for keys in vue's v-for
      };
    });
  },
  convertBBGeoFilterToPolygonFilter(boundingBoxGeoFilter) {
    const filterType = this.getGeoFilterType(boundingBoxGeoFilter);
    if (filterType === "Radius") {
      throw new Error("Geo filter is a radius type. Cannot convert to polygon");
    } else if (filterType === "BoundingBox") {
      const googleBB = esBoundsToGoogleBounds(boundingBoxGeoFilter);
      const geoJsonPoly = googleBoundsToGeoJsonPoly(googleBB);

      return this.convertGeoJsonToPolygonGeoFilter(geoJsonPoly);
    } else {
      //Polygon
      return boundingBoxGeoFilter;
    }
  },
  convertPolygonGeoFilterToGeoJson(polygonGeoFilter) {
    const filterType = this.getGeoFilterType(polygonGeoFilter);
    if (filterType === "Polygon") {
      const coords = polygonGeoFilter.coordinates.map((point) => [point.lng, point.lat]);
      return {
        type: "Polygon",
        coordinates: [coords],
      };
    } else {
      throw new Error("Geo filter is not of a polygon type");
    }
  },
  convertGeoJsonToPolygonGeoFilter(geoJsonPoly) {
    if (geoJsonPoly.type === "MultiPolygon") {
      const toSinglePolygons = geoJsonPoly.coordinates.map((x) => x[0]);
      const latLngPoints = toSinglePolygons.map((x) =>
        x.map((p) => ({
          lat: p[1],
          lng: p[0],
        }))
      );
      const latLngCoordinates = [].concat(...latLngPoints);

      return {
        coordinates: latLngCoordinates,
      };
    } else if (geoJsonPoly.type === "Polygon") {
      return {
        coordinates: geoJsonPoly.coordinates[0].map((point) => ({
          lat: point[1],
          lng: point[0],
        })),
      };
    } else {
      throw new Error("geoJsonPoly filter is not of a polygon type");
    }
  },
  convertGeoJsonPointToRadiusFilter(geoJsonPoint, maxRadius, minRadius = 0) {
    return {
      min_dist: minRadius,
      max_dist: maxRadius,
      location: {
        lat: geoJsonPoint.coordinates[1],
        lng: geoJsonPoint.coordinates[0],
      },
    };
  },
  getGeoFilterType(geoFilter) {
    if (geoFilter.coordinates) {
      return "Polygon";
    } else if (geoFilter.location) {
      return "Radius";
    } else if (geoFilter.top_left) {
      return "BoundingBox";
    } else {
      throw new Error("Could not resolve type of es geo filter");
    }
  },
  Popover: {
    // eslint-disable-next-line no-unused-vars
    bind(el, binding, _) {
      el.setAttribute("data-toggle", "popover")
        .setAttribute("data-placement", binding.arg)
        .setAttribute("data-content", binding.value)
        .setAttribute("trigger", "hover")
        .popover();
    },
  },
  getHighlightedField(resultItem, highlights = [], fieldName) {
    const highlight = highlights && highlights.find((h) => h.fieldName === fieldName);
    const actualResult = resultItem[fieldName];
    if (!highlight) {
      return actualResult;
    } else if (Array.isArray(actualResult)) {
      return highlight.values.map((v, i) => (v ? v.highlightedValue : actualResult[i]));
    } else {
      return highlight.values[0].highlightedValue;
    }
  },
  highlightedFieldsByNameMap(highlights = [], fieldNameMap) {
    highlights = highlights
      .map((highlight) => {
        if (Array.isArray(highlight)) {
          highlight = highlight.find((arrHighlight) => arrHighlight != null);
        }
        const val = highlight.values.find((v) => v != null);
        return {
          value: val,
          fieldName: fieldNameMap[highlight.fieldName],
        };
      })
      .filter((highlight) => highlight.fieldName != null);

    return sortBy(highlights, (highlight) => highlight.value.highlightFragments.length).reverse();
  },
};
