import type { RecursiveTree, TreeSchema } from "../types";

export interface SortedTreeRecursiveProps {
  treeSchema: TreeSchema[];
}

export function sortedTreeRecursive<U>(
  tree: RecursiveTree<U>,
  props: SortedTreeRecursiveProps
): RecursiveTree<
  U & {
    sortWeight: number;
  }
> {
  const { treeSchema } = props;

  const childNodes = tree.childNodes.map((n) => sortedTreeRecursive(n, props));

  // If I am in the schema, use my ordering otherwise use my childs.
  // e.g. if schema is [green_belt, flood_risk_high] sort green_belt_outer before flood_risk_outer.
  const nodeIndex = treeSchema.findIndex(({ key }) => key == tree.id);
  const sortWeight =
    nodeIndex >= 0
      ? nodeIndex
      : childNodes.map(({ sortWeight }) => sortWeight).reduce((p, c, _, a) => p + c / a.length, 0);

  const sortedNodes = [...childNodes].sort((a, b) => (a.sortWeight > b.sortWeight ? 1 : -1));

  return {
    ...tree,
    childNodes: sortedNodes,
    sortWeight,
  };
}
