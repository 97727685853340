import { from, InMemoryCache } from "@apollo/client";
import { ApolloClient } from "@apollo/client/core";
import { BatchHttpLink } from "@apollo/client/link/batch-http";
import { HttpLink } from "@apollo/client/link/http";
import { onError } from "@apollo/client/link/error";
import VueApollo from "vue-apollo";
import ToastrMessages from "view/components/toastr_message";
import { datadogRum } from "@datadog/browser-rum";
import { ENVIRONMENT } from "./environment";

export const initialiseApolloProvider = ({
  accountsServicePrivateApiUrl,
  apiUrl,
  appEnv,
  constraintsApiUrl,
  graphqlGatewayUrl,
  loginUrl,
  planningServiceUrl,
}) => {
  const constraintsOptions =
    appEnv === "local"
      ? {
          headers: {
            user_id: "something",
            features: "readConstraints",
          },
        }
      : {};
  const errorLink = onError(({ graphQLErrors, networkError }) => {
    const messages = {
      ClientParseError: "Request could not be sent",
      ServerParseError: "Server unavailable - please try later",
      ServerError: "Server unavailable - please try later",
      Object: "Unable to sync data, please try again",
    };

    if (appEnv !== "production" && graphQLErrors) {
      graphQLErrors.forEach((err) => console.log(err));
    }
    if (networkError?.statusCode === 401) {
      datadogRum.addAction("graphql-error-logout", {
        errorLink: "apollo",
      });
      const url = new URL(loginUrl);
      url.searchParams.set(
        "redirect",
        window.location.pathname.replace(ENVIRONMENT.BASE_URL || "", "") + window.location.search
      );
      window.location.assign(url);
    } else if (networkError?.statusCode === 410) {
      if (window.confirm("Your application is out of date. Refresh the page now?")) {
        window.location.reload();
      }
    } else if (messages[networkError?.name]) {
      ToastrMessages.failMessage(messages[networkError.name] || messages.Object);
    }
  });
  const networkInterfaces = {
    api: new BatchHttpLink({
      uri: `${apiUrl}/graphql`,
    }),
    accountServicePrivateApi: new BatchHttpLink({
      uri: accountsServicePrivateApiUrl + "/graphql",
    }),
    gateway: new HttpLink({
      uri: graphqlGatewayUrl,
    }),
    planningService: new HttpLink({
      uri: planningServiceUrl + "/graphql",
    }),
  };

  const sharedClientConfig = {
    connectToDevTools: false,
    addTypename: true,
  };

  const apolloClient = new ApolloClient({
    link: from([errorLink, networkInterfaces.api]),
    cache: new InMemoryCache({
      possibleTypes: {
        SiteSource: [
          "SiteSourcePlanningSearch",
          "SiteSourceOwnershipSearch",
          "SiteSourcePropertySearch ",
          "SiteSourceDrawingTool ",
          "SiteSourceMobileApp ",
          "SiteSourcePlanningLayer ",
          "SiteSourcePlanningPolicyLayer ",
          "SiteSourceCompsLayer ",
          "SiteSourcePropertyInfoLayer ",
          "SiteSourcePlanningAlertResult",
          "SiteSourceOwnershipParcel",
          "SiteSourceOwnershipLayer",
          "SiteSourceZoningLayer",
          "SiteSourceDrawingTools",
        ],
        SiteTidbit: [
          "SiteTidbitComment",
          "SiteTidbitPlanningRecord",
          "SiteTidbitOwnershipRecord",
          "SiteTidbitPricepaidRecord",
          "SiteTidbitComparableTransaction",
          "SiteTidbitGDV",
          "SiteTidbitChecklist",
          "SiteTidbitAlarm",
          "SiteTidbitAlert",
          "SiteTidbitAttachment",
          "SiteTidbitTitleDocument",
          "SiteTidbitOSMapPrint",
        ],
        SiteActivity: [
          "SiteActivitySiteTidbit",
          "SiteActivitySiteAction",
          "SiteActivityFieldUpdate",
        ],
        Annotation: ["AnnotationRadius"],
        TitleDocument: ["TitleRegisterDocument", "TitlePlanDocument"],
      },
    }),
    ...sharedClientConfig,
  });

  const apolloConstraintsClient = new ApolloClient({
    link: from([
      errorLink,
      new HttpLink({
        uri: `${constraintsApiUrl}/graphql`,
        ...constraintsOptions,
      }),
    ]),
    cache: new InMemoryCache(),
    ...sharedClientConfig,
  });

  const apolloGatewayClient = new ApolloClient({
    link: from([errorLink, networkInterfaces.gateway]),
    cache: new InMemoryCache(),
    ...sharedClientConfig,
  });

  const accountServicePrivateClient = new ApolloClient({
    link: from([errorLink, networkInterfaces.accountServicePrivateApi]),
    cache: new InMemoryCache(),
    ...sharedClientConfig,
  });

  const planningServiceClient = new ApolloClient({
    link: from([errorLink, networkInterfaces.planningService]),
    cache: new InMemoryCache(),
    ...sharedClientConfig,
  });

  return new VueApollo({
    clients: {
      api: apolloClient,
      constraintsApi: apolloConstraintsClient,
      accountServicePrivateApi: accountServicePrivateClient,
      gateway: apolloGatewayClient,
      planningService: planningServiceClient,
    },
    defaultClient: apolloClient,
  });
};
