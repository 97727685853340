import { UserUploadedIcon } from "react-migration/domains/sites/components/Icons/UserUploadedIcon";
import { SiteStageSelect } from "react-migration/domains/sites/components/SiteStageSelect";
import { SiteSourceType } from "react-migration/domains/sites/typings/Site";
import { useTranslation } from "react-migration/lib/i18n/useTranslation";
import { useSiteById } from "react-migration/domains/sites/hooks/useSiteById";
import { LayerTypeSelectionHeaderProps } from "../../types";
import { SitePageControls } from "./SitePageControls";

export function SiteDetailHeader({ selection, setSelection }: LayerTypeSelectionHeaderProps) {
  const siteId = selection.id;
  const { t } = useTranslation();
  const { site } = useSiteById(siteId);

  return (
    <div className="atlas-flex atlas-flex-row atlas-items-start atlas-gap-x-2 atlas-w-full">
      <SiteStageSelect site={site} />
      <div className="atlas-flex atlas-flex-col atlas-items-stretch atlas-grow">
        <div className="atlas-text-xs atlas-font-semibold atlas-text-content-tertiary">
          {t("selection.site_title")}
        </div>
        <div
          className="atlas-text-sm atlas-font-semibold atlas-text-content-primary atlas-line-clamp-3"
          title={site?.title}
        >
          {site?.title}
        </div>
      </div>
      <div className="atlas-pt-1">
        <UserUploadedIcon
          showIcon={site?.source?.__typename == SiteSourceType.SiteSourceSitesUploader}
          className="atlas-self-center !atlas-px-[7px] !atlas-py-[5px]"
        />
      </div>
      <SitePageControls siteId={siteId} setSelection={setSelection} />
    </div>
  );
}
