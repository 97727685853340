import { memo } from "react";
import { SummaryLine } from "./SummaryLine";
import { SummaryType } from "./SummaryType";

type AIResponseType = {
  /** Content in a consistent format */
  content: SummaryType[];
  /** A delay that can be used to render other content after the AIResponse has fully rendered */
  delay?: number;
};

export const AIResponse = memo(({ content, delay = 0 }: AIResponseType) => {
  const TEXT_RENDER_SPEED = 5; // Number of ms per character
  return (
    <>
      {content.map((line, index) => {
        // Delays the render time of each line by
        const localDelay = delay;
        const renderDuration = line.content.length * TEXT_RENDER_SPEED;
        delay += renderDuration;
        return (
          <SummaryLine
            key={index}
            type={line.type}
            content={line.content}
            href={line.href}
            delay={localDelay}
            renderDuration={renderDuration}
          />
        );
      })}
    </>
  );
});

AIResponse.displayName = "AIResponse";
