import { ISourcingToolSearchFilter } from "react-migration/domains/search/typings/SourcingToolSpecificFilterTypes";
import { SearchType } from "react-migration/domains/search/constants/searchTypes";

export const RegenerationZonesEmergingFilter: ISourcingToolSearchFilter = {
  id: "regeneration-zones-emerging-id",
  type: "checkbox",
  field: "sidebar.react.search_sidebar.regeneration_zones_emerging",
  must: "MATCH_ANY",
  mustOptions: ["MATCH_ANY", "MATCH_NONE"],
  updateFilterDialogIsVisible: false,
  searchTypes: [SearchType.SOURCING_TOOL_UK],
  values: [
    {
      key: "area_action_plans",
      name: "Area Action Plans",
      checked: true,
    },
    {
      key: "named_regeneration_areas",
      name: "Named Regeneration Areas",
    },
  ],
};
