import { Color, hsl } from "d3-color";
import { useId } from "react";

interface LegendProps {
  paddingLeft: number;
  series: { id: string; color: Color; display_name?: string }[];
  hiddenSeries: string[];
  toggleSeriesById: (id: string) => void;
}

export function Legend({ hiddenSeries, series, toggleSeriesById, paddingLeft }: LegendProps) {
  const legendId = useId();

  return (
    <div className="atlas-flex atlas-flex-col atlas-gap-y-2 atlas-pb-2" style={{ paddingLeft }}>
      {series.map(({ id, color, display_name }) => {
        const labelId = `series-${id}-${legendId}`;

        return (
          <label
            key={id}
            htmlFor={labelId}
            className="atlas-flex atlas-gap-x-2 atlas-items-center atlas-cursor-pointer"
          >
            <input
              id={labelId}
              type="checkbox"
              checked={!hiddenSeries.includes(id)}
              onChange={() => toggleSeriesById(id)}
              style={{ accentColor: toAccentColour(color).toString() }}
            />

            <div className="atlas-text-xs atlas-text-neutral-600 atlas-truncate">
              {display_name}
            </div>
          </label>
        );
      })}
    </div>
  );
}
function toAccentColour(color: Color): Color {
  return hsl(color.formatHsl()).darker(0.3);
}
