import { useTranslation } from "react-migration/lib/i18n/useTranslation";
import { logEvent } from "react-migration/lib/util/logEvent";
import { Intercom } from "src/js/util/intercom";

export function OutOfHours() {
  const { t } = useTranslation();

  async function openHelp() {
    await logEvent("Open Intercom Widget", { from: "failed title purchase" });
    Intercom("show");
  }
  return (
    <div data-testid="pending-transaction" className="atlas-flex atlas-flex-col atlas-gap-4">
      <strong>{t("title_purchase.error.out_of_hours.title")}</strong>
      <p>{t("title_purchase.error.out_of_hours.message")}</p>
      <p>{t("title_purchase.error.out_of_hours.message_2")}</p>
      <p>
        {t("title_purchase.error.out_of_hours.message_3", {
          statusPage: (
            <a href="https://eservices.landregistry.gov.uk/status/message.html">
              {t("title_purchase.error.out_of_hours.status_page")}
            </a>
          ),
          twitterFeed: (
            <a href="https://twitter.com/lrtechsupport">
              {t("title_purchase.error.out_of_hours.twitter_feed")}
            </a>
          ),
          contactingUs: (
            <span className="atlas-cursor-pointer atlas-text-blue-600" onClick={openHelp}>
              {t("title_purchase.error.out_of_hours.contact")}
            </span>
          ),
          fromTheirWebsite: (
            <a href="https://eservices.landregistry.gov.uk/eservices/FindAProperty/view/QuickEnquiryInit.do">
              {t("title_purchase.error.out_of_hours.website")}
            </a>
          ),
        })}
      </p>
    </div>
  );
}
