import type { LayerTypeSelectionMapLayerProps } from "../../types";
import { ConstraintLayer } from "react-migration/domains/constraints/components";
import { SimplifyProfile } from "./types";
import { DesignationFeatureProps } from "react-migration/domains/constraints/components/ConstraintLayer/DesignationLayer";
import { useCallback, useMemo } from "react";
import { isDefined } from "react-migration/lib/util/isDefined";
import {
  getIconKeySet,
  getStyledAttributes,
} from "react-migration/domains/constraints/designation/style/accessors";
import { useDesignationById } from "react-migration/domains/constraints/designation/hooks/useDesignationById";

export function ConstraintsSelectionMapLayer({ selection }: LayerTypeSelectionMapLayerProps) {
  const { designation } = useDesignationById(selection.id);

  const featureIsVisible = useCallback(
    (d: DesignationFeatureProps) => d.designation_id === designation?.id,
    [designation]
  );

  const [attributes, iconKeySet] = useMemo(() => {
    const subCategoryIds = [designation?.sub_category_id].filter(isDefined);
    return [getStyledAttributes(subCategoryIds), getIconKeySet(subCategoryIds)];
  }, [designation]);

  if (!designation) return null;

  return (
    <ConstraintLayer
      rootCategory={designation.sub_category_id}
      showLabels={false}
      minZoom={0}
      zOrder={Infinity}
      layerId={`designation-${designation.id}`}
      featureIsVisible={featureIsVisible}
      dedupePoints={false}
      filterAreaByZoom={false}
      simplifyProfile={SimplifyProfile.LOW}
      layerName={designation.sub_category_id}
      designationAttributes={attributes}
      iconKeySet={iconKeySet}
    />
  );
}
