import { useMemo } from "react";
import { noop } from "lodash";
import buffer from "@turf/buffer";
import { PlanningApplicationDetailSection } from "react-migration/domains/planning/components/PlanningApplicationDetail";
import { PlanningApplicationList } from "react-migration/domains/planning/components/PlanningApplicationList";
import { PlanningApplication } from "react-migration/domains/planning/types";
import { useTranslation } from "react-migration/lib/i18n/useTranslation";
import { InfoPopover, SkeletonLoading } from "@landtechnologies/components";
import { LayerTypeSelectionDetailViewProps } from "../../../types";
import { Truncated } from "../../../Bundle/Workbench/Truncated";
import { usePlanningApplicationsByGeometry } from "../SelectionSidebar/usePlanningApplicationsByGeometry";
import { PlanningApplicationsByAddress } from "../PlanningApplicationsByAddress";
import { usePlanningAppById } from "../usePlanningAppsById";
import { usePlanningApplicationListBySelection } from "../SelectionSidebar/usePlanningApplicationListBySelection";
import { SubSectionHeader } from "react-migration/components/SubSectionHeader/SubSectionHeader";

/**
 * Hook responsible for fetching selected & related planning applications based
 * on an application id.
 *
 * TODO:
 * 1. Get all related applications (see `relatedApplications`) not just ones nearby.
 * 2. Get selected & related application in a single call ideally in Apollo Client
 */
function useRelatedApplications(planningApp?: PlanningApplication) {
  const buffedGeometry = useMemo(() => {
    const geometry =
      planningApp?.boundary ||
      (planningApp?.location && buffer(planningApp?.location, 100, { units: "meters" }).geometry);

    return geometry;
  }, [planningApp?.boundary, planningApp?.location]);

  const { data: planningApplications, loading } = usePlanningApplicationsByGeometry(
    buffedGeometry || null
  );

  const { relatedApplications, referencesToRelated } = useMemo(() => {
    const selectedPlanningApplication: PlanningApplication | undefined = planningApplications.find(
      (app) => app.id === planningApp?.id
    );

    const references = (selectedPlanningApplication?.references || []).map(
      (refs) => refs.planningId
    );

    const isReferenced = selectedPlanningApplication?.isReferenced || [];

    return {
      relatedApplications: planningApplications.filter((app) => {
        return references.includes(app.id ?? "") || isReferenced.includes(app.id ?? "");
      }),
      referencesToRelated: selectedPlanningApplication?.references || [],
    };
  }, [planningApp?.id, planningApplications]);

  return { relatedApplications, referencesToRelated, loading } as const;
}

export function PlanningApplicationSelectionDetailView({
  selection,
  isSummary,
  setDetailSelection,
}: LayerTypeSelectionDetailViewProps) {
  const showOtherPossibleSelections = isSummary;
  const [planningApp, isLoadingSelectedApp] = usePlanningAppById(selection?.id);

  const {
    relatedApplications,
    referencesToRelated,
    loading: isLoadingRelated,
  } = useRelatedApplications(planningApp);

  const selectedPlanningAppWithReferences = useMemo<PlanningApplication | null>(
    () => (planningApp ? { ...planningApp, references: referencesToRelated } : null),
    [planningApp, referencesToRelated]
  );

  if (isLoadingSelectedApp || !selectedPlanningAppWithReferences)
    return (
      <div
        className="atlas-relative atlas-h-28 atlas-bg-white atlas-border-8 atlas-border-white"
        data-testid="ownership-selection-header-loading"
      >
        <SkeletonLoading rows={2} showPadding={false} />
      </div>
    );

  return (
    <>
      <Truncated onSeeMoreInfo={() => setDetailSelection?.(selection)} isExpanded={!isSummary}>
        <PlanningApplicationDetailSection
          planningApplication={selectedPlanningAppWithReferences}
          setDetailSelection={setDetailSelection}
        />
        <div className="atlas-flex atlas-flex-col atlas-p-2 atlas-divide-y-2 atlas-divide-neutral-100 *:atlas-py-3 first:*:atlas-pt-0 last:*:atlas-pb-0">
          <RelatedApplicationsSection
            isLoading={isLoadingRelated}
            relatedApplications={relatedApplications}
            setDetailSelection={setDetailSelection}
          />
        </div>
      </Truncated>
      {showOtherPossibleSelections && (
        <OtherSelectedApplicationsSection
          selection={selection}
          setDetailSelection={setDetailSelection}
        />
      )}
    </>
  );
}

type RelatedApplicationsSectionProps = {
  isLoading: boolean;
  relatedApplications: PlanningApplication[] | null;
} & Pick<LayerTypeSelectionDetailViewProps, "setDetailSelection">;

const EMPTY_ARRAY: unknown[] = [];

function RelatedApplicationsSection({
  isLoading,
  relatedApplications,
  setDetailSelection,
}: RelatedApplicationsSectionProps) {
  const { t } = useTranslation();

  return (
    <section className="atlas-flex atlas-flex-col atlas-gap-y-2">
      <SubSectionHeader count={isLoading ? undefined : relatedApplications?.length}>
        {t("planning.planning_application_list.planning_application_list.related_applications")}
      </SubSectionHeader>
      {isLoading ? (
        <div className="atlas-relative atlas-h-[38px] atlas-bg-white">
          <SkeletonLoading rows={1} showPadding={false} />
        </div>
      ) : (
        !!relatedApplications?.length && (
          <div className="atlas-border atlas-border-border-divider atlas-rounded atlas-divide-border-divider atlas-divide-y">
            <PlanningApplicationList
              setDetailSelection={(selection) => setDetailSelection?.(selection)}
              planningApps={relatedApplications || EMPTY_ARRAY}
              setSelectedAppealRef={noop}
              setSelectedPlanningApplicationRef={noop}
            />
          </div>
        )
      )}
    </section>
  );
}

type OtherSelectedApplicationsSectionProps = Pick<
  LayerTypeSelectionDetailViewProps,
  "setDetailSelection" | "selection"
>;

function OtherSelectedApplicationsSection({
  selection,
  setDetailSelection,
}: OtherSelectedApplicationsSectionProps) {
  const { t } = useTranslation();
  const { filteredPlanningApplications, loading } = usePlanningApplicationListBySelection(
    selection,
    { filterSelectionFromList: true }
  );

  if (!filteredPlanningApplications.length) return null;

  return (
    <section className="atlas-flex atlas-flex-col atlas-gap-y-2 atlas-p-2">
      <SubSectionHeader count={filteredPlanningApplications.length}>
        Other visible planning applications
        <span className="atlas-mx-1">
          <InfoPopover size="xs">
            {t(
              "planning.planning_application_list.planning_application_detail_view.other_visible_tooltip"
            )}
          </InfoPopover>
        </span>
      </SubSectionHeader>
      <div>
        {loading ? (
          <div className="atlas-relative atlas-h-12 atlas-bg-white atlas-p-2">
            <SkeletonLoading rows={1} showPadding={false} />
          </div>
        ) : (
          <PlanningApplicationsByAddress
            planningApplications={filteredPlanningApplications}
            setDetailSelection={setDetailSelection}
          />
        )}
      </div>
    </section>
  );
}
