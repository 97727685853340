import { Device, Texture, TextureProps } from "@luma.gl/core";

// stolen from @luma.gl/api
export async function loadImage(
  url: string,
  opts?: { crossOrigin?: string }
): Promise<HTMLImageElement> {
  return new Promise((resolve, reject) => {
    try {
      const image = new Image();
      image.onload = () => resolve(image);
      image.onerror = () => reject(new Error(`Could not load image ${url}.`));
      image.crossOrigin = opts?.crossOrigin || "anonymous";
      image.src = url;
    } catch (error) {
      reject(error);
    }
  });
}

export interface CachedTexture {
  readonly cacheKey: string;
  readonly texture: Texture | Promise<Texture>;
  readonly referenceCount: number;
}

type TextureSourceURL = string;

// we assume the data prop will be provided as a URL to an image
// type StricterTexture2DProps = Omit<Texture2DProps, "data"> & { data: TextureSourceURL };

export class TextureCache {
  static cache: Record<TextureSourceURL, CachedTexture> = {};

  static create(
    device: Device,
    { data, ...props }: Omit<TextureProps, "data"> & { data: TextureSourceURL }
  ) {
    const cacheKey = data;
    let cachedTexture = this.cache[cacheKey];

    if (!cachedTexture) {
      cachedTexture = {
        cacheKey,
        texture: loadImage(data).then((image) => {
          const texture = device.createTexture({ ...props, data: image });

          // so subsequent hits on the cache don't need to await
          // @ts-expect-error: readonly is for the benefit of consumers of this class
          this.cache[cacheKey].texture = texture;

          return texture;
        }),
        referenceCount: 0,
      };

      this.cache[cacheKey] = cachedTexture;
    }

    // @ts-expect-error: readonly is for the benefit of consumers of this class
    cachedTexture.referenceCount++;

    return cachedTexture;
  }

  static async delete(cachedTexture: CachedTexture) {
    // @ts-expect-error: readonly is for the benefit of consumers of this class
    cachedTexture.referenceCount--;

    if (cachedTexture.texture instanceof Promise) {
      await cachedTexture.texture;
    }

    if (cachedTexture.referenceCount === 0 && cachedTexture.texture) {
      (cachedTexture.texture as Texture).destroy();
      delete this.cache[cachedTexture.cacheKey];
    }
  }
}
