function getMimeType(dataUrl: string) {
  const match = RegExp(/^data:(.*);base64,/).exec(dataUrl);
  return match ? match[1] : undefined;
}

export function convertBase64ToFile(base64DataString: string | null, fileName: string) {
  if (!base64DataString) return;

  const base64Data = base64DataString.split(",")[1];
  const mimeType = getMimeType(base64DataString);

  const binaryString = window.atob(base64Data);
  const binaryLen = binaryString.length;
  const bytes = new Uint8Array(binaryLen);

  for (let i = 0; i < binaryLen; i++) {
    bytes[i] = binaryString.charCodeAt(i);
  }

  const blob = new Blob([bytes], { type: mimeType });

  return new File([blob], fileName, { type: mimeType });
}
