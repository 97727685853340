import type { PickingInfo } from "deck.gl";
import { OwnershipFeature } from "react-migration/domains/ownership/typings/Feature";
import { ClickSelectionResolver } from "../../../types";
import { titleClickResolverUK } from "./titleClickResolverUK";
import { titleClickResolverUS } from "./titleClickResolverUS";
import { getDeckInstance } from "src/js/plugins/deck.gl/deckInstance";
import { getDeckLayerInstanceIds } from "react-migration/lib/map/getLayerInstances";
import { OwnershipLayer } from "react-migration/domains/ownership/components/map/OwnershipLayer";

export const OWNERSHIP_LAYER_NAME = "ownership";

export function isOwnershipFeaturePick(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  unknownPick: PickingInfo<any>
): unknownPick is PickingInfo<OwnershipFeature> {
  return unknownPick.object.properties?.layerName === OWNERSHIP_LAYER_NAME;
}

export const titleClickResolver: ClickSelectionResolver = async (pickInfo) => {
  const { x, y } = pickInfo;
  const layerIds = getDeckLayerInstanceIds([OwnershipLayer]);
  const deck = getDeckInstance();

  for (const pick of deck?.pickMultipleObjects({ x, y, layerIds }) || []) {
    if (isOwnershipFeaturePick(pick)) {
      const isUSClick = pick.object!.properties.country === "US";
      return isUSClick ? await titleClickResolverUS(pick) : await titleClickResolverUK(pick);
    }
  }

  return null;
};
