import { useMemo } from "react";
import { UtilityFeature } from "./types";
import { useUtilitiesLayerTypeContext } from "./UtilitiesContext";

export const useUtilityById = (id?: string): UtilityFeature | undefined => {
  const { utilitiesFeatures } = useUtilitiesLayerTypeContext();

  const utility = useMemo(() => {
    if (!utilitiesFeatures) return;

    return utilitiesFeatures.find((feature) => feature.properties.UniqueID === id);
  }, [utilitiesFeatures, id]);

  return utility;
};
