import gql from "graphql-tag";
import get from "lodash/get";
import set from "lodash/set";
import last from "lodash/last";
import cloneDeep from "lodash/cloneDeep";
import pick from "lodash/pick";

export function updateRecord({
  $apollo,
  updateData,
  resultTypename,
  updateTypename = `${resultTypename}Update`,
  mutationName = `update${resultTypename}`,
}) {
  const returnKeys = Object.keys(updateData).join(", ");
  return $apollo.mutate({
    mutation: gql`
        mutation ($updateData: ${updateTypename}!) {
          ${mutationName}(updateData: $updateData) {
            ${returnKeys}
          }
        }
      `,
    variables: {
      updateData,
    },
    optimisticResponse: {
      __typename: "Mutation",
      [mutationName]: Object.assign({}, updateData, {
        __typename: resultTypename,
      }),
    },
  });
}

export function paginateList({ smartQuery, connectionPath }) {
  // see docs:
  // https://docs.google.com/document/d/1H_HUdoC_P03-tb_mqN7uZ9S5DLPLeIqwymbkxjeTzwk/edit#heading=h.nsu57r7lbjmb
  // https://github.com/Akryum/vue-apollo#pagination-with-fetchmore
  // note that this does not return anything, it updates the smartQuery's data using fetchMore+updateQuery

  //NOTE: Paginated queries must be network only, else they will try to fetch from the cache first
  //Inherently, there is nothing wrong with this, but it means that the loading flag
  //on the query temporarily gets set to false, and thus we can make multiple requests
  //for the same page
  if (smartQuery.loading) {
    return Promise.resolve(smartQuery);
  }

  // find the cursor (if there is one to be found)...
  const connection =
    smartQuery.observer.last?.result && get(smartQuery.observer.last?.result?.data, connectionPath);
  const cursor = connection.pageInfo?.endCursor ?? last(connection.edges ?? [])?.cursor;

  if (connection?.pageInfo?.hasNextPage !== true) {
    return Promise.resolve(smartQuery); // no more pages!
  }

  return smartQuery.fetchMore({
    variables: {
      ...smartQuery.observer.variables,
      cursor,
    },
    updateQuery: (previousResult, { fetchMoreResult }) => {
      // Transform the previous result with new data
      const newConnection = get(fetchMoreResult, connectionPath);
      const oldConnection = get(previousResult, connectionPath);

      const toReturn = cloneDeep(previousResult); // DM: presumably this is so we dont overwrite any optimistic changes we've made?
      set(toReturn, connectionPath, Object.assign({}, newConnection));
      const connectionToSet = get(toReturn, connectionPath);

      if (newConnection.nodes != null) {
        // DM: could the oldConnection also have null?
        connectionToSet.nodes = [...oldConnection.nodes, ...newConnection.nodes];
      }
      if (newConnection.edges != null) {
        connectionToSet.edges = [...oldConnection.edges, ...newConnection.edges];
      }
      return toReturn;
    },
  });
}

export function readQuery({ store, $apolloQuery }) {
  return cloneDeep(store.readQuery(pick($apolloQuery.observer.options, "variables", "query")));
}

export function writeQuery({ store, $apolloQuery, data }) {
  return store.writeQuery(
    Object.assign(pick($apolloQuery.observer.options, "variables", "query"), {
      data,
    })
  );
}
