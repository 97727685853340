import { drawingStore } from "../store";

export const deleteDrawingBulk = (ids: string[]) => {
  const actions = drawingStore.reduxStore?.actions;
  const store = drawingStore.reduxStore?.store;
  if (!store || !actions) return false;

  store.dispatch(actions.deleteDrawingBulk(ids));
  return true;
};
