import Feature from "src/js/stores/user/Feature";
import hasFeature from "src/js/stores/user/actions/hasFeature";
import { ComparablesFilterFields } from "../components/Filters/Filters";

export const hiddenZooplaFields = new Set<ComparablesFilterFields>(["dcsConditions.Commercial"]);

export const hiddenFieldsByFeature: Array<[() => boolean, Set<ComparablesFilterFields>]> = [
  [() => hasFeature(Feature.usAccess), new Set(["newBuildsOnly", "tenure"])],
  [
    () => !hasFeature(Feature.zooplaFullAccess),
    new Set(["bedrooms", "yearBuiltFrom", "yearBuiltTo"]),
  ],
];
