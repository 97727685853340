import { proxy } from "valtio/vanilla";
import { defaultFilters } from "react-migration/domains/comparables/util/defaultFilters";
import { ComparablesState } from "react-migration/domains/comparables/typings/Comparables";
import { MapModes } from "react-migration/domains/comparables/typings/MapModes";
import { PerUnitPriceModes } from "react-migration/domains/comparables/typings/PriceModes";
import { PriceTypes } from "react-migration/domains/comparables/typings/PriceTypes";
import { CalculatorResultTypes } from "react-migration/domains/comparables/typings/ResultTypes";
export type ComparablesStore = {
  filters: ComparablesState["filters"];
  filtersOpen: ComparablesState["filtersOpen"];
  mapMode: ComparablesState["mapMode"];
  priceMode: ComparablesState["priceMode"];
  priceType: ComparablesState["priceType"];
  smallUnitSize: ComparablesState["smallUnitSize"];
  incNoFloorArea: ComparablesState["incNoFloorArea"];
  includedResults: ComparablesState["includedResults"];
};

const localStorageKey = "comparablesState";

const storedStateString = localStorage.getItem(localStorageKey);

const defaultState = {
  filters: defaultFilters,
  filtersOpen: true,
  mapMode: MapModes.SCATTER,
  priceMode: PerUnitPriceModes.PRICE,
  priceType: PriceTypes.PER_UNIT,
  smallUnitSize: false,
  incNoFloorArea: false,
  includedResults: CalculatorResultTypes.ALL,
};

const previousComparablesStore = storedStateString ? JSON.parse(storedStateString) : {};
export const createComparablesStore = () => Object.assign(defaultState, previousComparablesStore);

const comparablesStore = proxy<ComparablesStore>(createComparablesStore());

export { comparablesStore, localStorageKey };
