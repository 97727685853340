import Feature from "src/js/stores/user/Feature";
import { UKPropertyInformationLayerType } from "../layer_types/UKPropertyInformationLayerType";
import { Layer } from "../types";

export const UK_PROPERTY_INFORMATION_LAYER: Layer = {
  id: "PROPERTY_INFORMATION",
  layerType: UKPropertyInformationLayerType,
  requiredFeatureAccess: [Feature.propertyInformationLayer],
  title: "Property Information",
  description: "Use classes, building heights & more.",
  toggleable: true,
};
