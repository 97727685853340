import { DesignationsSelectionSidebarConnected } from "react-migration/domains/constraints/designation/components/Designations/DesignationsSelectionSidebar";
import type { LayerType } from "../../types";
import { ConstraintsMapLayer } from "./ConstraintsMapLayer";
import { ConstraintsControlPage } from "./ControlPage";
import { constraintClickResolver } from "./constraintClickResolver";
import { ConstraintsLayerTypeProvider } from "./ConstraintsContext";
import { CONSTRAINTS_LAYER_TYPE_ID } from "./constants";
import { ConstraintsPrintable } from "./ConstraintsPrintable";

export const ConstraintsLayerType: LayerType = {
  id: CONSTRAINTS_LAYER_TYPE_ID,
  Provider: ConstraintsLayerTypeProvider,
  MapLayer: ConstraintsMapLayer,
  ControlPage: ConstraintsControlPage,
  SelectionSidebar: DesignationsSelectionSidebarConnected,
  clickResolver: constraintClickResolver,
  Printable: ConstraintsPrintable,
};
