import { assocPath, path } from "ramda";
import { SelectionGeometry, SelectionType } from "src/js/stores/map/store";
import { ClickSelectionResolver } from "../../types";
import { PickingInfo } from "deck.gl";
import { Feature } from "geojson";
import { ComparableTile, ZooplaComparableTile } from "@landtechnologies/comparables-services";

export const ltId = path(["object", "properties", "transaction_id"]);
export const htId = path(["object", "properties", 0, "properties", "uprn"]);

const isLTCompsClick = (
  pickingInfo: PickingInfo<unknown>
): pickingInfo is PickingInfo<ComparableTile> =>
  pickingInfo.layer?.id === "comparables-layer" &&
  path(["object", "geometry", "type"], pickingInfo) === "Point";

const isHTCompsClick = (
  pickingInfo: PickingInfo
): pickingInfo is PickingInfo<
  Omit<ZooplaComparableTile, "properties"> & { properties: ZooplaComparableTile["properties"][] }
> =>
  pickingInfo.layer?.id === "zoopla-layer" &&
  path(["object", "geometry", "type"], pickingInfo) === "Point";

function makeCoordinatesSerialisable(feature: ComparableTile | ZooplaComparableTile) {
  return {
    ...feature,
    geometry: {
      ...feature.geometry,
      coordinates: feature.geometry.coordinates,
    },
  };
}

export const comparablesClickResolver: ClickSelectionResolver = (pickingInfo) => {
  if (isLTCompsClick(pickingInfo)) {
    return {
      id: ltId(pickingInfo) as string,
      type: SelectionType.LANDTECH_COMPARABLE_TRANSACTION,
      feature: makeCoordinatesSerialisable(
        assocPath(["properties", "radius"], 12, pickingInfo.object!)
      ) as Feature<SelectionGeometry, ComparableTile["properties"]>,
    };
  }

  if (isHTCompsClick(pickingInfo)) {
    return {
      id: String(htId(pickingInfo)),
      type: SelectionType.HOMETRACK_COMPARABLE_TRANSACTION,
      feature: makeCoordinatesSerialisable({
        ...pickingInfo.object!,
        properties: assocPath(["radius"], 12, pickingInfo.object?.properties?.[0] ?? {}),
      } as ZooplaComparableTile) as Feature<SelectionGeometry, ZooplaComparableTile["properties"]>,
    };
  }
  return null;
};
