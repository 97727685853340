import { ApolloError } from "@apollo/client";
import { Button, Loading } from "@landtechnologies/components";
import {
  PurchaseErrors,
  TransactionStatus,
} from "react-migration/domains/ownership/components/TransactionStatus";
import { PurchaseTitleDocumentResponse } from "../usePurchaseTitleDocuments/usePurchaseTitleDocuments";
import { useTranslation } from "react-migration/lib/i18n/useTranslation";

type PurchaseStatusType = {
  onCancel: () => void;
  transaction?: PurchaseTitleDocumentResponse;
  requestError?: ApolloError;
  loading: boolean;
  setSelectedStep: (v: number) => void;
};

export const PurchaseStatus = ({
  onCancel,
  transaction,
  requestError,
  loading,
  setSelectedStep,
}: PurchaseStatusType) => {
  const { t } = useTranslation();
  const INVALID_CREDENTIALS_ERROR_MESSAGE: PurchaseErrors = "invalid_credentials";
  return (
    <div
      data-testid="step-3-purchased"
      className="atlas-flex atlas-flex-col atlas-mx-4 atlas-h-full"
    >
      {loading ? (
        <div className="atlas-overflow-y-auto atlas-max-h-[400px] atlas-grow atlas-pt-20">
          <Loading />
        </div>
      ) : (
        <div className="atlas-flex atlas-flex-col atlas-overflow-y-auto atlas-h-[400px] atlas-justify-center">
          <TransactionStatus transaction={transaction} requestError={requestError} />
        </div>
      )}
      <div className="atlas-flex atlas-justify-end atlas-pt-4">
        {requestError?.message === INVALID_CREDENTIALS_ERROR_MESSAGE && (
          <Button.Secondary
            onClick={() => setSelectedStep(2)}
            data-testid="back-button"
            className="atlas-mr-2"
          >
            {t("title_purchase.back")}
          </Button.Secondary>
        )}
        <Button.Primary onClick={onCancel}>{t("title_purchase.close")}</Button.Primary>
      </div>
    </div>
  );
};
