import { RouteConfig } from "vue-router";
import ROUTES from "../routes";

export const PublicRoutes: Array<RouteConfig> = [
  {
    path: "/public/map/:z?,:x?,:y?",
    alias: ["/public/map"],
    component: () =>
      import(
        /* webpackChunkName: 'map' */
        /* webpackPreload: true */
        "../../view/public/Public.vue"
      ),
    name: ROUTES.PUBLIC_MAP,
  },
];
