import Feature from "src/js/stores/user/Feature";
import { ConstraintsCategory } from "../../Constraints/types";
import {
  DemographicsLayerConfig,
  DemographicsLayerType,
} from "../layer_types/DemographicsLayerType/DemographicsLayerType";
import { GenericLayer, Layer } from "../types";

export type DemographicsLayer = GenericLayer<DemographicsLayerConfig>;

export const DEMOGRAPHICS_US: Layer = {
  id: "us_demographics",
  layerType: DemographicsLayerType,
  hideByDefault: true,
  title: "Demographics",
  toggleable: true,
  requiredFeatureAccess: [Feature.demographics],
  description:
    "US Demographics boundaries are sourced from the United States Census Bureau and attributes by Attom. The data is available at 3 scales: state, county and tract. It covers the state of Florida.",
  layerConfig: {
    region: "US",
    subCategoryConditions: [
      { categoryId: ConstraintsCategory.US_STATE_DEMOGRAPHICS, maxZoom: 7 },
      { categoryId: ConstraintsCategory.US_COUNTY_DEMOGRAPHICS, maxZoom: 10 },
      { categoryId: ConstraintsCategory.US_TRACT_DEMOGRAPHICS },
    ],
    attributes_style_options: [
      {
        attribute: "median_age",
        style: "median_age",
        displayName: "Median Age",
      },
      {
        attribute: "population_density_per_sq_mile",
        style: "population_density_per_sq_mile",
        displayName: "Population density",
      },
      {
        attribute: "population_unemployed_total",
        style: "default_percentage_0_to_10",
        displayName: "Population unemployed total",
      },
      {
        attribute: "median_household_income",
        style: "median_household_income",
        displayName: "Average Household Income",
      },
    ],
  },
};
