import { area, feature, length } from "@turf/turf";
import { useTranslation } from "react-migration/lib/i18n/useTranslation";
import { measurementString } from "react-migration/lib/util/conversion";
import { LayerTypeSelectionPrintableSummaryProps } from "../../types";
import { useSiteById } from "react-migration/domains/sites/hooks/useSiteById";
import { useSnapshot } from "valtio";
import { MeasurementSystem, userStore } from "src/js/stores/user/store";
import hasFeature from "src/js/stores/user/actions/hasFeature";
import Feature from "src/js/stores/user/Feature";
import { StaticMapContainer } from "react-migration/domains/sites/card/Map/StaticMapContainer";
import { useEffect, useReducer } from "react";

const SUMMARY_MAPS = ["roadmap", "satellite"];

export function SitesSelectionReportSummary({
  selection,
  onLoaded,
}: LayerTypeSelectionPrintableSummaryProps) {
  const { site, loading } = useSiteById(selection.id);
  const { t } = useTranslation();
  const { unit } = useSnapshot(userStore.user.settings);

  const isImperial = unit === MeasurementSystem.IMPERIAL;

  const [mapLoaded, setMapLoaded] = useReducer(() => true, false);

  useEffect(() => {
    if (!loading && mapLoaded) {
      onLoaded();
    }
  }, [loading, mapLoaded, onLoaded]);

  if (!site) return null;

  return (
    <div>
      <h1
        className="atlas-text-xl atlas-font-semibold atlas-text-content-primary"
        title={site?.title}
      >
        {site?.title}
      </h1>
      <div className="atlas-flex atlas-mb-4 atlas-mt-4">
        <div className="atlas-w-1/3">
          <div className="atlas-mb-4">
            <h3>{t("sites.card.site_report.site_information")}</h3>
            {site.geometry && (
              <>
                <div>
                  {t("sites.card.site_report.area")}:{" "}
                  {measurementString(area(site.geometry), "hectares", isImperial)}
                </div>
                <div>
                  {t("sites.card.site_report.perimeter")}:{" "}
                  {measurementString(1000 * length(feature(site.geometry)), "m", isImperial)}
                </div>
              </>
            )}
            {hasFeature(Feature.propertyInformationLayer) && (
              <>
                <div>
                  {t("sites.card.site_report.authority")}: {site.local_authority}
                </div>
                <div>
                  {t("sites.card.site_report.ptal")}: {site.ptal_rating ?? "None"}
                </div>
              </>
            )}
          </div>

          {hasFeature(Feature.propertyInformationLayer) && (
            <div className="atlas-mb-4">
              <h3>{t("sites.card.site_report.property_information")}</h3>
              <div>
                {t("sites.card.site_report.avg_building_height")}:{" "}
                {measurementString(site.average_building_height?.rel_h_to_roof, "m", isImperial)}
              </div>
              <div>
                {t("sites.card.site_report.avg_building_height_total")}:{" "}
                {measurementString(site.average_building_height?.rel_h_max, "m", isImperial)}
              </div>
              <div>
                {t("sites.card.site_report.current_use")}: {site.use_classes_sep_2020.join(", ")}
              </div>
              <div>
                {t("sites.card.site_report.use_class")}: {site.use_classes.join(", ")}
              </div>
            </div>
          )}
        </div>
        <div className="atlas-w-2/3 atlas-flex [&>div]:atlas-mr-4 [&>div:last-child]:atlas-mr-0">
          {SUMMARY_MAPS.map((mapType) => (
            <div key={mapType} className="atlas-w-1/2 atlas-aspect-square">
              <StaticMapContainer
                siteId={site._id}
                mapId={`site-${mapType}`}
                initialMapTypeId={mapType}
                mapDimensions={{ height: 230, width: 230 }}
                onMapLoaded={setMapLoaded}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
