import { useMapLayer } from "react-migration/lib/map/useMapLayer";
import { multRGBAAlpha } from "react-migration/lib/util/changeRGBAAlpha";
import { SelectionFeature, SelectionGeometry, SelectionType } from "src/js/stores/map/store";
import { GeoJsonLayer } from "@deck.gl/layers";
import { Feature } from "@turf/helpers";
import { AccessorFunction, Color, CompositeLayer, LayerContext, UpdateParameters } from "deck.gl";
import {
  SELECTED_TITLE_FILL_COLOR,
  SELECTED_TITLE_LINE_COLOR,
} from "react-migration/domains/ownership/util/Colors";
import { SelectionMarkerLayer } from "../../SelectionMarker";

const getFillColor = (d: Feature<SelectionGeometry>) =>
  multRGBAAlpha(SELECTED_TITLE_FILL_COLOR, d.properties?.opacity ?? 1);

const getLineColor = (d: Feature<SelectionGeometry>) =>
  multRGBAAlpha(SELECTED_TITLE_LINE_COLOR, d.properties?.opacity ?? 1);

export function useSelectionMapLayer(selection: SelectionFeature) {
  useMapLayer(
    () => ({
      layer: new SelectionLayer({
        selection,
      }),
      // Introduced to ensure selection geometry renders on-top of competing
      // map layers
      zOrder: Infinity,
    }),
    [selection]
  );
}

interface SelectionLayerProps {
  selection: SelectionFeature;
}

interface SelectionLayerState {
  zoom: number;
}

export class SelectionLayer extends CompositeLayer<SelectionLayerProps> {
  // seems redundant, just used for type safety
  setState(state: SelectionLayerState) {
    super.setState(state);
  }

  getZoom() {
    return Math.round(this.context.viewport.zoom);
  }

  initializeState(context: LayerContext) {
    super.initializeState(context);

    this.setState({
      zoom: this.getZoom(),
    });
  }

  shouldUpdateState({ changeFlags }: UpdateParameters<this>) {
    const newZoomLevel = this.getZoom();

    if (newZoomLevel !== this.state.zoom) {
      this.setState({ zoom: newZoomLevel });
      return true;
    }

    return changeFlags.propsOrDataChanged;
  }

  renderLayers() {
    const zoom = this.getZoom();
    const { selection } = this.props;

    if (selection.type === SelectionType.POINT) {
      return [
        new SelectionMarkerLayer({
          ...this.getSubLayerProps({
            id: "bundle-selection__point-marker",
          }),
          data: [selection.feature],
        }),
      ];
    }

    const pointRadiusScale = selection?.__zoomPointScale?.(Math.floor(zoom)) ?? 1;
    let data = selection.feature;
    if (selection.__renderGeometry) {
      /**
       * Workaround for when the selection geometry (used for intersections)
       * doesn't match what needs to be rendered. e.g. Planning dots
       */
      data = selection.__renderGeometry;
    }

    return [
      new GeoJsonLayer({
        ...this.getSubLayerProps({
          id: "bundle-selection__geojson",
        }),
        data,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        getFillColor: getFillColor as AccessorFunction<Feature<GeoJSON.Geometry, any>, Color>,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        getLineColor: getLineColor as AccessorFunction<Feature<GeoJSON.Geometry, any>, Color>,
        stroked: true,
        getLineWidth: 3,
        lineWidthUnits: "pixels",
        pointRadiusUnits: "pixels",
        pointRadiusScale,
        getPointRadius: (d) => d.properties?.radius ?? 5,
      }),
    ];
  }
}
