import type { PickingInfo } from "deck.gl";
import { DesignationFeature } from "react-migration/domains/constraints/components/ConstraintLayer/DesignationLayer";
import { SelectionType } from "src/js/stores/map/store";
import { ClickSelectionResolver } from "../../types";
import type { DemographicsPickingInfo } from "./types";
import { UK_DEMOGRAPHICS_CATEGORIES, US_DEMOGRAPHICS_CATEGORIES } from "./constants";
import { createMultiPolygon } from "react-migration/domains/constraints/designation/util/createMultiPolygon";
import { createDesignationSelection } from "react-migration/domains/constraints/designation/util/createDesignationSelection";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function isDesignationFeature(o: any): o is DesignationFeature {
  return (
    !!o?.properties &&
    [...UK_DEMOGRAPHICS_CATEGORIES, ...US_DEMOGRAPHICS_CATEGORIES].includes(
      o.properties.sub_category_id
    )
  );
}

// The demographics map layer injects the selected attribute into the picking info
function isDemographicsPickInfo(info: PickingInfo<unknown>): info is DemographicsPickingInfo {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return !!(info as any)?.meta?.selectedAttribute;
}

function outputAreaIsSelectable(info: PickingInfo<unknown>) {
  return isDemographicsPickInfo(info) && info.meta.selectedAttribute !== "output_areas";
}

export const demographicsClickResolver: ClickSelectionResolver = async (
  info: PickingInfo<unknown>
) => {
  if (!isDesignationFeature(info.object)) return null;

  if (outputAreaIsSelectable(info)) {
    const designationSelection = await createDesignationSelection(info.object, createMultiPolygon);
    return {
      ...designationSelection,
      type: SelectionType.OUTPUT_AREA,
      savable: false,
      hideConsiderations: true,
    };
  }

  return null;
};
