import React from "react";
import { CardNumberElementProps } from "@stripe/react-stripe-js";
import { useCardBrands } from "../hooks/useCardBrands";
interface BrandComponentProps {
  brand: Parameters<NonNullable<CardNumberElementProps["onChange"]>>[0]["brand"];
}

export const BrandComponent = ({ brand }: BrandComponentProps) => {
  const BrandComponent = useCardBrands(brand);
  return <span> {BrandComponent}</span>;
};
