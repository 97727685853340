import { useCallback, useEffect, useState } from "react";
import { useIsMounted } from "usehooks-ts";

function useLoadingLayerIDs(visibleLayerIds: string[]) {
  const [loadingLayerIDs, setLoadingLayerIDs] = useState<string[]>([]);

  const setLayerLoadingState = useCallback((layerId: string, loading: boolean) => {
    setLoadingLayerIDs((prev) => {
      const newLoadingLayerIDs = loading
        ? [...prev, layerId]
        : prev.filter((loadingLayerId) => loadingLayerId !== layerId);

      return [...new Set(newLoadingLayerIDs)];
    });
  }, []);

  useEffect(
    () => setLoadingLayerIDs((ids) => ids.filter((id) => visibleLayerIds.includes(id))),
    [visibleLayerIds]
  );

  return [loadingLayerIDs, setLayerLoadingState] as const;
}

// TODO: Add comment
export function useSelectionSidebarLoading(
  onLoadingChange: (loading: boolean) => void,
  loading: boolean
) {
  useEffect(() => {
    onLoadingChange(loading);

    return () => onLoadingChange(false);
  }, [loading, onLoadingChange]);
}

export function useSelectionSidebarLoadingState(visibleLayerIds: string[]) {
  const isMounted = useIsMounted();

  const [selectionSidebarLoadingIds, setSelectionSidebarLoading] =
    useLoadingLayerIDs(visibleLayerIds);

  return {
    selectionSidebarIsLoading: isMounted() && !!selectionSidebarLoadingIds.length,
    selectionSidebarLoadingIds,
    setSelectionSidebarLoading,
  } as const;
}
