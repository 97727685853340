import { MeasurementSystem } from "src/js/stores/user/store";

import { PPSFT_COLOR_FILL_RGB } from "../../util/Colors";
import { pricePerUnitArea } from "../../util/Prices";

interface GetPriceColorProps {
  price: number;
  area: number;
  distribution?: number[];
  index?: number;
}

export const getTransactionPriceColor = ({
  area,
  price,
  distribution,
  index,
}: GetPriceColorProps) => {
  if (index) {
    return `rgb(${PPSFT_COLOR_FILL_RGB[index].join(",")})`;
  }

  const pricePerArea = pricePerUnitArea({
    area,
    price,
    unitPreference: MeasurementSystem.METRIC,
  });
  const colorIndex =
    pricePerArea && distribution
      ? distribution.findIndex((p, i, ps) => pricePerArea >= p && pricePerArea < ps[i + 1]) + 1
      : 0;
  return `rgb(${PPSFT_COLOR_FILL_RGB[colorIndex].join(",")})`;
};

export interface PPADotInterface {
  area: number;
  price: number;
  distribution?: number[];
  index?: number;
}
export const PPADot = ({ area, price, distribution, index }: PPADotInterface) => {
  return (
    <div
      className="atlas-w-4 atlas-h-4 atlas-rounded-full atlas-flex-none atlas-mr-1"
      style={{
        backgroundColor: getTransactionPriceColor({
          area,
          price,
          distribution,
          index,
        }),
      }}
      data-testid="ppa-dot"
    />
  );
};
