import React from "react";

export const JcbIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="23" height="16" viewBox="0 0 23 16">
      <g fill="none">
        <path
          fill="#047ab1"
          d="M.205 16h4.518c1.028 0 2.26-1.195 2.26-2.133V0H2.463C1.436 0 .205 1.195.205 3.2z"
        ></path>
        <path
          fill="#fff"
          d="M2.77 10.816a9.77 9.77 0 01-2.565-.341V9.3c.647.378 1.386.591 2.146.619.87 0 1.356-.576 1.356-1.365V5.333h2.146v3.222c0 1.258-.7 2.261-3.084 2.261z"
        ></path>
        <path
          fill="#d42d06"
          d="M8.112 16h4.517c1.028 0 2.26-1.195 2.26-2.133V0H10.37C9.343 0 8.11 1.195 8.11 3.2z"
        ></path>
        <path
          fill="#fff"
          d="M8.112 6.08c.655-.597 1.784-.97 3.614-.885.994.042 2.033.32 2.033.32v1.184a4.785 4.785 0 00-1.954-.608c-1.39-.118-2.236.618-2.236 1.909s.847 2.027 2.236 1.92a4.853 4.853 0 001.954-.619v1.174s-1.04.288-2.033.33c-1.83.086-2.96-.288-3.614-.885z"
        ></path>
        <path
          fill="#67b637"
          d="M16.018 16h4.518c1.028 0 2.259-1.195 2.259-2.133V0h-4.518c-1.028 0-2.26 1.195-2.26 3.2z"
        ></path>
        <path
          fill="#fff"
          d="M21.665 9.28c0 .853-.7 1.387-1.638 1.387h-4.01V5.333h3.649l.26.011c.824.043 1.434.501 1.434 1.29 0 .62-.418 1.153-1.174 1.28v.033c.835.053 1.48.554 1.48 1.333zm-2.88-3.104a1.087 1.087 0 00-.147-.01h-1.355v1.343h1.502a.667.667 0 00.52-.672c0-.362-.238-.608-.52-.661zm.17 2.176a1.028 1.028 0 00-.181-.01h-1.491v1.46h1.49l.181-.02c.283-.065.52-.342.52-.715 0-.374-.226-.64-.52-.715z"
        ></path>
      </g>
    </svg>
  );
};
