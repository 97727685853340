import { useCallback, useEffect, useMemo } from "react";
import { syncStoreToLocalStorage } from "src/js/stores/site/effects/syncStoreToLocalStorage";
import {
  clearSiteSiteReportConfiguration,
  setSiteSiteReportConfiguration,
} from "src/js/stores/site/actions/siteReportConfiguration";
import { SiteReportConfiguration, siteStore } from "src/js/stores/site/store";
import { useSnapshot } from "valtio";

const EMPTY_CONFIGURATION: SiteReportConfiguration = {};

export function useSiteReportConfiguration(siteId: string) {
  const { siteReportConfiguration } = useSnapshot(siteStore);

  useEffect(() => {
    syncStoreToLocalStorage();
  }, []);

  const setConfiguration = useCallback(
    (siteReportConfiguration: SiteReportConfiguration) =>
      setSiteSiteReportConfiguration(siteId, siteReportConfiguration),
    [siteId]
  );

  const clearConfiguration = useCallback(() => clearSiteSiteReportConfiguration(siteId), [siteId]);

  return [
    siteReportConfiguration[siteId] ?? EMPTY_CONFIGURATION,
    { setConfiguration, clearConfiguration },
  ] as const;
}

export const EMPTY_IDS: string[] = [];

export function useSiteReportConfigurationState(
  key: keyof SiteReportConfiguration,
  siteId: string,
  ids: string[]
) {
  const [reportConfiguration, { setConfiguration }] = useSiteReportConfiguration(siteId);

  const hiddenIds = useMemo(
    () => reportConfiguration[key] ?? EMPTY_IDS,
    [key, reportConfiguration]
  );

  const setVisible = useCallback(
    (excludedIds: string[]) => setConfiguration({ ...reportConfiguration, [key]: excludedIds }),
    [key, reportConfiguration, setConfiguration]
  );

  const toggleId = useCallback(
    (id: string) => {
      setVisible(
        hiddenIds.includes(id) ? hiddenIds.filter((hid) => hid !== id) : [...hiddenIds, id]
      );
    },
    [hiddenIds, setVisible]
  );

  const [allVisible, allHidden] = useMemo(
    () => [!hiddenIds.length, ids.every((id) => hiddenIds.includes(id))] as const,
    [hiddenIds, ids]
  );

  const toggleAll = useCallback(
    () => (allVisible ? setVisible(ids) : setVisible(EMPTY_IDS)),
    [allVisible, ids, setVisible]
  );

  return { hiddenIds, allVisible, allHidden, toggleId, toggleAll };
}
