import { DrawingItem } from "@landtechnologies/drawings-frontend/lib/entities/DrawingItem";
import { drawingStore } from "../store";

export const triggerDelete = (id: DrawingItem["id"]) => {
  const actions = drawingStore.reduxStore?.actions;
  const store = drawingStore.reduxStore?.store;
  if (!store || !actions) return;

  store.dispatch(actions.triggerDelete(id));
};
