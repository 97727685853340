import { SearchIcon } from "@landtechnologies/components";
import { useTranslation } from "react-migration/lib/i18n/useTranslation";

export const NoSearchResults = () => {
  const { t } = useTranslation();
  return (
    <div className="atlas-mt-[80px] atlas-h-full atlas-w-full atlas-flex atlas-flex-col atlas-items-center atlas-justify-center atlas-bg-background-white">
      <div className="atlas-h-14 atlas-w-14 atlas-flex atlas-items-center atlas-justify-center atlas-border-background-light atlas-bg-background-dark atlas-border-8 atlas-rounded-full">
        <div className="atlas-w-6 atlas-h-6">
          <SearchIcon />
        </div>
      </div>
      <div className="atlas-font-medium atlas-text-xl atlas-mt-5 atlas-text-content-primary">
        {t("dashboard.transactions.no_search_results")}
      </div>
      <div className="atlas-font-normal atlas-text-base atlas-mt-2 atlas-text-content-secondary">
        {t("dashboard.transactions.no_search_results_message")}
      </div>
    </div>
  );
};
