import Feature from "src/js/stores/user/Feature";
import { ConstraintsCategory } from "../../Constraints/types";
import { ConstraintsLayerType } from "../layer_types/ConstraintsLayerType";
import { Layer } from "../types";

export const SIGNIFICANT_CONSTRAINTS: Layer = {
  id: ConstraintsCategory.POLICY,
  layerType: ConstraintsLayerType,
  title: "Significant Constraints",
  description: "Could limit the type of development that can be done, or restrict use",
  toggleable: true,
  requiredFeatureAccess: [Feature.planningPolicyLayer],
  layerConfig: {
    rootCategory: ConstraintsCategory.POLICY,
    layerRoots: [
      "protected_areas_outer",
      "heritage_land_listed_buildings_outer",
      "flood_zones_outer",
      "conservation_areas_outer",
      "safe_guarding_outer",
      "employment_area_outer",
      "settlement_boundaries_outer",
      "tree_preservation_orders",
      "protected_views_outer",
      "green_belt_outer",
      "nutrient_neutrality",
      "water_neutrality",
      "national_landscape",
      "open_land_and_green_space",
      "risk_of_flooding_from_rivers_and_seas",
      "rights_of_way",
    ],
    categorySchema: [
      { key: "protected_areas_outer" },
      { key: "national_landscape" },
      { key: "heritage_land_listed_buildings_outer" },
      { key: "flood_zones_outer" },
      { key: "very_low_risk_of_flooding_from_rivers_and_seas" },
      { key: "low_risk_of_flooding_from_rivers_and_seas" },
      { key: "medium_risk_of_flooding_from_rivers_and_seas" },
      { key: "high_risk_of_flooding_from_rivers_and_seas" },
      { key: "conservation_areas_outer" },
      { key: "employment_area_outer" },
      { key: "settlement_boundaries_outer" },
      { key: "green_belt_outer" },
      { key: "nutrient_neutrality" },
      { key: "water_neutrality_offsetting_allowed" },
      { key: "water_neutrality_no_offsetting" },
      { key: "protected_views_outer" },
      { key: "safe_guarding_outer" },
      { key: "metropolitan_open_land_outer" },
      { key: "open_space_outer" },
      { key: "rights_of_way" },
      { key: "tree_preservation_orders" },
    ],
    statusSchema: [{ key: "adopted" }, { key: "draft", defaultValue: false }],
  },
};

export const SIGNIFICANT_CONSTRAINTS_POLICIES: Layer = {
  id: ConstraintsCategory.POLICY,
  layerType: ConstraintsLayerType,
  title: "Policy Constraints",
  description: "Could limit the type of development that can be done, or restrict use",
  toggleable: true,
  requiredFeatureAccess: [Feature.planningPolicyLayer],
  layerConfig: {
    rootCategory: ConstraintsCategory.POLICY,
    layerRoots: [
      "protected_areas_outer",
      "national_landscape",
      "heritage_land_listed_buildings_outer",
      "conservation_areas_outer",
      "employment_area_outer",
      "settlement_boundaries_outer",
      "green_belt_outer",
      "protected_views_outer",
      "safe_guarding_outer",
      "open_land_and_green_space",
      "rights_of_way",
      "tree_preservation_orders",
    ],
    categorySchema: [
      { key: "protected_areas_outer" },
      { key: "national_landscape" },
      { key: "heritage_land_listed_buildings_outer" },
      { key: "conservation_areas_outer" },
      { key: "employment_area_outer" },
      { key: "settlement_boundaries_outer" },
      { key: "green_belt_outer" },
      { key: "protected_views_outer" },
      { key: "safe_guarding_outer" },
      { key: "metropolitan_open_land_outer" },
      { key: "open_space_outer" },
      { key: "rights_of_way" },
      { key: "tree_preservation_orders" },
    ],
    statusSchema: [{ key: "adopted" }, { key: "draft", defaultValue: false }],
  },
};

export const SIGNIFICANT_CONSTRAINTS_ENVIRONMENTAL: Layer = {
  id: ConstraintsCategory.POLICY,
  layerType: ConstraintsLayerType,
  title: "Environmental Constraints",
  description: "Could limit the type of development that can be done, or restrict use",
  toggleable: true,
  requiredFeatureAccess: [Feature.planningPolicyLayer],
  layerConfig: {
    rootCategory: ConstraintsCategory.POLICY,
    layerRoots: [
      "flood_zones_outer",
      "risk_of_flooding_from_rivers_and_seas",
      "nutrient_neutrality",
      "water_neutrality",
    ],
    categorySchema: [
      { key: "flood_zones_outer" },
      { key: "very_low_risk_of_flooding_from_rivers_and_seas" },
      { key: "low_risk_of_flooding_from_rivers_and_seas" },
      { key: "medium_risk_of_flooding_from_rivers_and_seas" },
      { key: "high_risk_of_flooding_from_rivers_and_seas" },
      { key: "water_neutrality_offsetting_allowed" },
      { key: "water_neutrality_no_offsetting" },
      { key: "nutrient_neutrality" },
    ],
    statusSchema: [{ key: "adopted" }, { key: "draft", defaultValue: false }],
  },
};
