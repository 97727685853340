import GB from "./en/GB/en-GB.json";
import comparables from "./en/GB/comparables";
import constraints from "./en/GB/constraints";
import nav from "./en/GB/nav";
import ownership from "./en/GB/ownership";
import propertyInformation from "./en/GB/propertyInformation";
import search from "./en/GB/sidebar";
import sites from "./en/GB/sites";

export default {
  ...GB,
  ...constraints,
  ...comparables,
  ...nav,
  ...ownership,
  ...propertyInformation,
  ...search,
  ...sites,
};
