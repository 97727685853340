import { GeometryMeasurements } from "react-migration/components/GeometryMeasurements";
import { AnnotationSpecProps } from "./types";

export function PolygonSpec({ drawing }: AnnotationSpecProps) {
  return (
    <div>
      <GeometryMeasurements geometry={drawing.geometry} />
    </div>
  );
}
