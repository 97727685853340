import { ApolloError, useQuery } from "@apollo/client";
import { useMemo, useRef } from "react";
import { LandTechEndpoints, routedClient } from "react-migration/lib/persistence/apollo";
import COMPARABLE_DISTRIBUTION_QUERY from "../apollo/comparableDistribution.gql";
import { useSnapshot } from "valtio";
import { mapStore } from "src/js/stores/map/store";
import { boxACoversB, googleBoundsToEsBounds, overscan } from "src/js/util/map_util";
import { isZoopla } from "../util/isZoopla";
import { ComparablesFilterFields } from "../components/Filters/Filters";
import { ComparableDistribution, ComparablesFilterOptions } from "../typings/Comparables";
import { PriceModes } from "../typings/PriceModes";
import { buildCompsQuery } from "../util/BuildCompsQuery";

type ComparableDistributionHookArgs = {
  disabledFields?: Set<ComparablesFilterFields>;
  filters: ComparablesFilterOptions;
  hiddenFields?: Set<ComparablesFilterFields>;
  field?: PriceModes;
};

export interface ComparableDistributionHookResponse {
  loading: boolean;
  data?: number[];
  error: ApolloError | undefined;
}

export function formatResponse(data?: ComparableDistributionResult): number[] | undefined {
  if (!data?.comparableDistributionSearch) {
    return undefined;
  }

  return (
    Object.entries(data?.comparableDistributionSearch ?? {})
      .filter(([key]) => key !== "totalCount" && key !== "__typename")
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      .map(([_key, value]) => value)
  );
}

type ComparableDistributionResult = { comparableDistributionSearch: ComparableDistribution };

export function useComparableDistributionQuery({
  disabledFields,
  filters,
  field,
  hiddenFields,
}: ComparableDistributionHookArgs): ComparableDistributionHookResponse {
  const { mapBounds } = useSnapshot(mapStore.settings);
  const bboxPojo = googleBoundsToEsBounds(mapBounds);
  const overscannedRef = useRef<ReturnType<typeof overscan> | null>();

  const skip = isZoopla(field);

  if (!skip && !boxACoversB(overscannedRef.current, bboxPojo)) {
    overscannedRef.current = overscan(bboxPojo, 0.05);
  }

  const search = useMemo(
    () => ({
      ...buildCompsQuery(filters, disabledFields, hiddenFields),
      bounding_box_geo_filter: overscannedRef.current,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [filters, disabledFields, hiddenFields, overscannedRef.current]
  );

  const { loading, error, data } = useQuery<ComparableDistributionResult>(
    COMPARABLE_DISTRIBUTION_QUERY,
    {
      variables: {
        search,
        field,
      },
      client: routedClient,
      context: {
        endpoint: LandTechEndpoints.Gateway,
      },
      skip,
    }
  );

  const lastResult = useRef(data);

  // only update the distribution result if there is a result with enough data
  if (
    data !== lastResult.current &&
    !loading &&
    (data?.comparableDistributionSearch?.totalCount ?? 0) > 5
  ) {
    lastResult.current = data;
  }

  return useMemo(
    () => ({
      loading,
      data: !error ? formatResponse(lastResult.current) : undefined,
      error,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [error, loading, lastResult.current]
  );
}
