import { useMapLayer } from "react-migration/lib/map/useMapLayer";

import { CompositeDesignationLayer } from "./CompositeDesignationLayer";
import { ConstraintLayerProps } from "react-migration/domains/constraints/components/ConstraintLayer/ConstraintLayer";
import { SubCategoryCondition } from "./useDemographicSubCategory";

type CompositeDesignationLayerWrapperProps = Omit<ConstraintLayerProps, "rootCategory"> & {
  categoryConditions: SubCategoryCondition[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  pickInfoMeta?: any;
};

export function CompositeDesignationLayerWrapper({
  showLabels = false,
  iconKeySet: iconKeys,
  minZoom,
  zOrder,
  layerId,
  featureIsVisible,
  featureIsSelected,
  zoomPointScale,
  designationAttributes,
  dedupePoints,
  filterAreaByZoom,
  simplifyProfile,
  collisionFilter,
  styleAccessors,
  categoryConditions,
  pickInfoMeta,
}: CompositeDesignationLayerWrapperProps) {
  useMapLayer(
    () => ({
      zOrder,
      layer: new CompositeDesignationLayer({
        id: layerId,
        tileOptions: {
          designationAttributes,
          dedupePoints,
          filterAreaByZoom,
          simplifyProfile,
        },
        showLabels,
        collisionFilter: collisionFilter,
        visible: true,
        iconKeySet: iconKeys,
        minZoom,
        pickable: true,
        featureIsVisible,
        featureIsSelected,
        zoomPointScale,
        styleAccessors,
        categoryConditions,
        pickInfoMeta,
      }),
    }),
    [
      zOrder,
      layerId,
      designationAttributes,
      dedupePoints,
      filterAreaByZoom,
      simplifyProfile,
      showLabels,
      collisionFilter,
      iconKeys,
      minZoom,
      featureIsVisible,
      featureIsSelected,
      zoomPointScale,
      styleAccessors,
      categoryConditions,
      pickInfoMeta,
    ]
  );

  return null;
}
