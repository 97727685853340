import { CardTitle } from "react-migration/components/CardTitle";
import { useDesignationById } from "react-migration/domains/constraints/designation/hooks/useDesignationById";
import { useTranslation } from "react-migration/lib/i18n/useTranslation";
import { SelectionTitleProps } from "../../types";

export function DesignationSelectionTitle({ selection }: SelectionTitleProps) {
  const { t } = useTranslation();
  const { designation } = useDesignationById(selection.id);

  return (
    <CardTitle>
      {t("sidebar.selection.designation_title", {
        sub_category: designation?.sub_category?.display_name,
      })}
    </CardTitle>
  );
}
