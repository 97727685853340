import Feature from "src/js/stores/user/Feature";
import { ConstraintsCategory } from "../../Constraints/types";
import { ConstraintsLayerType } from "../layer_types/ConstraintsLayerType";
import { Layer } from "../types";

export const MINING_AND_GEOLOGY: Layer = {
  id: ConstraintsCategory.MINING_AND_GEOLOGY,
  layerType: ConstraintsLayerType,
  title: "Coal Mining",
  requiredFeatureAccess: [Feature.planningPolicyLayer],
  toggleable: true,
  description:
    "Areas and features of coal risk. Available at zoom level 14 or below and will not feature in the assessment panel on the right hand side to comply with The Coal Authority's licensing terms",
  hideByDefault: true,
  label: { color: "BLUE", translationKey: "map.explore.label.new" },
  zoomConfig: {
    // DO NOT REMOVE maxVisibleZoom. Required for licensing reasons
    maxVisualisationZoom: 14,
    maxConsiderationZoom: 14,
    // DO NOT REMOVE maxVisibleZoom. Required for licensing reasons
  },
  layerConfig: {
    rootCategory: ConstraintsCategory.MINING_AND_GEOLOGY,
    categorySchema: [
      { key: "mine_entries" },
      { key: "coal_development_high_risk_areas" },
      { key: "surface_mining" },
      { key: "past_shallow_coal_workings" },
      { key: "probable_shallow_coal_workings" },
      { key: "coal_mining_reporting_areas" },
    ],
  },
};
