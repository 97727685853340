import { useTranslation } from "react-migration/lib/i18n/useTranslation";
import { ReportIcon } from "./ReportIcon";
import React from "react";
import { logUtilitiesReportEvent } from "react-migration/domains/sites/components/SiteCard/ReportSidebar/logUtilitiesEvent";
import { Button } from "react-migration/components/Button";
import { logEventV2 } from "react-migration/lib/util/logEventV2";

type ReportSectionProps = {
  onButtonClick: () => void;
};

export const ReportSection: React.FC<ReportSectionProps> = ({ onButtonClick }) => {
  const { t } = useTranslation();

  const handleButtonClick = () => {
    logUtilitiesReportEvent({
      name: "Utilities Report - View Info Clicked",
    });
    logEventV2({
      name: "Workbench card click",
      properties: {
        cardName: "Utilities Report",
        action: "goTo",
        clickDetail: "View utilities report",
      },
    });
    onButtonClick();
  };

  return (
    <>
      <div className="atlas-flex atlas-gap-3 atlas-border-border-divider-subtle atlas-border atlas-p-2 atlas-rounded">
        <ReportIcon />
        <div className="atlas-w-32 atlas-grow">
          <div className="atlas-text-sm atlas-font-bold">
            {t("sites.card.site_card.utilities_report")}
          </div>
          <div className="atlas-text-xs">{t("sites.card.site_card.utilities_report_text")}</div>
        </div>
        <Button onClick={handleButtonClick} data-testid="utilities-report-view-info-button">
          {t("sites.card.site_card.view")}
        </Button>
      </div>
    </>
  );
};
