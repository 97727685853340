import { formattedDateRange, subtractTime } from "src/js/util/dates";
import { ComparablesFilterOptions } from "../typings/Comparables";

export const defaultFilters: ComparablesFilterOptions = {
  bedrooms: {
    min: undefined,
    max: undefined,
  },
  floorArea: {
    min: undefined,
    max: undefined,
  },
  dateFrom: formattedDateRange(subtractTime(new Date(), "months", 24)),
  dateTo: formattedDateRange(new Date()),
  designations: [],
  categories: [],
  subcategories: [],
  dcsConditions: undefined,
  tenure: "Any",
  newBuildsOnly: false,
  yearBuiltFrom: undefined,
  yearBuiltTo: undefined,
};
