import { useZooplaListingsByUPRN } from "react-migration/domains/comparables/hooks/useZooplaListingsByUPRN";
import Feature from "src/js/stores/user/Feature";
import hasFeature from "src/js/stores/user/actions/hasFeature";
import { ZooplaListings } from "./ZooplaListings";

/** Will fetch and show zoopla listings if user has access and a uprn is provided. If user has no access no data fetching is executed and the component will return null */
export function ZooplaListingsFromUPRN({
  className,
  uprn,
}: Readonly<{ className?: string; uprn?: string }>) {
  const forbidden = !hasFeature(Feature.zooplaLightAccess) && !hasFeature(Feature.zooplaFullAccess);
  const { loading, data } = useZooplaListingsByUPRN({
    uprn,
    skip: forbidden || !uprn,
  });

  if (forbidden) {
    return null;
  }

  return (
    <ZooplaListings
      className={className}
      zooplaListingsData={data}
      zooplaListingsDataLoading={loading}
    />
  );
}
