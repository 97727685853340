import REMOVE_LABEL from "../graphql/removeLabel.gql";
import { ApolloError, FetchResult, useMutation } from "@apollo/client";
import { SiteLabel } from "react-migration/domains/sites/typings/Site";
import { routedClient } from "react-migration/lib/persistence/apollo/routedClient";

interface RemoveLabelResponse {
  site: {
    _id: string;
    site_labels: SiteLabel[];
  };
}

interface RemoveLabelVariables {
  siteId: string;
  labelId: string;
}

export const useRemoveLabelMutation = () => {
  const [removeLabel, { loading, error }] = useMutation<RemoveLabelResponse>(REMOVE_LABEL, {
    client: routedClient,
  });

  const removeLabelMutation = async (
    variables: RemoveLabelVariables
  ): Promise<FetchResult<RemoveLabelResponse>> => {
    try {
      const result = await removeLabel({ variables });
      // Handle the result if needed
      return result;
    } catch (error) {
      // Handle the error if needed
      console.log(`Error removing label: ${error}`);
      throw error as ApolloError;
    }
  };

  return { removeLabelMutation, loading, error };
};
