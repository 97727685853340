import {
  PriceModes,
  ZooplaPriceModes,
  ZooplaPricePerAreaModes,
  ZooplaPricePerUnitModes,
} from "../typings/PriceModes";

export function isZoopla(x?: PriceModes): x is ZooplaPriceModes {
  return (
    x === ZooplaPricePerUnitModes.RENT_ESTIMATE ||
    x === ZooplaPricePerUnitModes.VAL_ESTIMATE ||
    x === ZooplaPricePerUnitModes.VAL_LAST ||
    x === ZooplaPricePerUnitModes.RENT_ASKING ||
    x === ZooplaPricePerAreaModes.RENT_ESTIMATE ||
    x === ZooplaPricePerAreaModes.VAL_ESTIMATE ||
    x === ZooplaPricePerAreaModes.VAL_LAST ||
    x === ZooplaPricePerAreaModes.RENT_ASKING
  );
}

export function isZooplaValuePrice(x?: PriceModes): x is ZooplaPriceModes {
  return (
    x === ZooplaPricePerUnitModes.VAL_ESTIMATE ||
    x === ZooplaPricePerUnitModes.VAL_LAST ||
    x === ZooplaPricePerAreaModes.VAL_ESTIMATE ||
    x === ZooplaPricePerAreaModes.VAL_LAST
  );
}
