import { useTranslation } from "react-migration/lib/i18n/useTranslation";
import { Button, RangeInput } from "@landtechnologies/components";
import { useMemo, useState } from "react";
import { area } from "src/js/util/units";
import { ParcelSizeRange } from "react-migration/layouts/map/Multilayer/layer_types/OwnershipLayerType/Context";
import { Unit } from "@landtechnologies/drawings-frontend/lib/types";

export type ParcelSizeRangeInputProps = {
  disabled: boolean;
  unitType: Unit;
  setMetricParcelSizeRange: React.Dispatch<ParcelSizeRange>;
  metricParcelSizeRange: ParcelSizeRange;
};

type UserInputRange = ParcelSizeRange & {
  unit: Unit;
};

export const STEP = 0.01;

const getParcelSizeUnitText = (unitType: string) =>
  area.unitString({
    unitType: unitType,
    small: false,
    longForm: false,
  });

const convertRange = (metricRange: ParcelSizeRange, toUnit: Unit) => {
  const metricToUnitConverter = area.converter({ unitType: toUnit, small: false });

  return {
    from: metricToUnitConverter(metricRange.from),
    to: metricToUnitConverter(metricRange.to),
  };
};

const maskInfiniteLimit = (range: ParcelSizeRange): ParcelSizeRange => ({
  from: range.from,
  to: range.to === Infinity ? 0 : range.to,
});

export const deriveValidRangeForFromValue = (currentRange: ParcelSizeRange, newFromVal: number) => {
  const newToVal = currentRange.to !== 0 ? Math.max(currentRange.to, newFromVal + STEP) : 0;
  return { from: Math.max(newFromVal, 0), to: newToVal };
};

export const deriveValidRangeForToValue = (currentRange: ParcelSizeRange, newToVal: number) => {
  const incrementingFromZero = currentRange.to === 0 && newToVal === 0 + STEP;
  return incrementingFromZero
    ? { from: currentRange.from, to: currentRange.from + STEP }
    : newToVal <= 0
    ? { from: currentRange.from, to: 0 }
    : { from: Math.min(currentRange.from, Math.max(newToVal - STEP, 0)), to: newToVal };
};

export function ParcelSizeRangeInput({
  disabled,
  unitType,
  setMetricParcelSizeRange,
  metricParcelSizeRange,
}: ParcelSizeRangeInputProps) {
  const { t } = useTranslation();

  const [userInputRange, setUserInputRange] = useState<UserInputRange>({
    ...convertRange(metricParcelSizeRange, unitType),
    unit: unitType,
  });

  const onInputRange = (range: UserInputRange) => {
    setUserInputRange(range);
    setMetricParcelSizeRange({
      from: area.parse(range.from, { unitType: range.unit, small: false }),
      to: area.parse(range.to, { unitType: range.unit, small: false }),
    });
  };

  const displayedRange = useMemo(() => {
    const range =
      unitType === userInputRange.unit
        ? userInputRange
        : convertRange(metricParcelSizeRange, unitType);
    return maskInfiniteLimit(range);
  }, [metricParcelSizeRange, unitType, userInputRange]);

  const onInputRangeFromValue = (newFromVal: number) => {
    const range = deriveValidRangeForFromValue(displayedRange, newFromVal);
    onInputRange({ ...range, unit: unitType });
  };

  const onInputRangeToValue = (newToVal: number) => {
    const range = deriveValidRangeForToValue(displayedRange, newToVal);
    onInputRange({ ...range, unit: unitType });
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onEnterPress = (e: any) => {
    if (e.key !== "Enter") return;

    const targetValue = e.target.value.trim();

    if (e.target.id === "start-input" && targetValue.length > 0)
      onInputRangeFromValue(Number(targetValue));

    if (e.target.id === "end-input" && targetValue.length > 0)
      onInputRangeToValue(Number(targetValue));
  };

  const onClearButtonPress = () => onInputRange({ from: 0, to: 0, unit: unitType });

  return (
    <div className="atlas-text-neutral-500 atlas-text-sm atlas-text-center atlas-flex">
      <RangeInput
        isDisabled={disabled}
        startInputIsDisabled={false}
        endInputIsDisabled={false}
        inputClassName="atlas-bg-background-white"
        className="atlas-justify-center atlas-py-2"
        units={getParcelSizeUnitText(unitType)}
        minValue={0}
        size="small"
        step={STEP}
        startInputPlaceholder="from"
        startInputValue={displayedRange.from}
        endInputPlaceholder="to"
        endInputValue={displayedRange.to}
        startInputOnChange={onInputRangeFromValue}
        endInputOnChange={onInputRangeToValue}
        startInputOnKeyDown={onEnterPress}
        endInputOnKeyDown={onEnterPress}
        data-testid="ownership-filters-range-input"
      />

      <Button.Secondary
        disabled={disabled}
        className="atlas-mx-1"
        size="small"
        onClick={onClearButtonPress}
      >
        {t("map.toolbar.ownership_filters.parcel_size_filter_clear")}
      </Button.Secondary>
    </div>
  );
}
