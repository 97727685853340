import { Theme } from "@landtechnologies/components";
import { RangeFilter } from "../RangeFilter";

import { FC } from "react";
import { useTranslation } from "react-migration/lib/i18n/useTranslation";
import { MeasurementSystem } from "src/js/stores/user/store";
import { ftsqToMsq, msqToFtsq } from "src/js/util/units_and_constants";

export interface FilterFloorAreaProps {
  disabled?: boolean;
  areaMin?: number;
  /** for empty input updates a value of NaN will be dispatched */
  setAreaMin: (value: number) => void;
  areaMax?: number;
  /** for empty input updates a value of NaN will be dispatched */
  setAreaMax: (value: number) => void;
  unitPreference: MeasurementSystem;
  theme?: Theme;
}

export const FilterFloorArea: FC<FilterFloorAreaProps> = ({
  areaMin,
  disabled,
  setAreaMin,
  areaMax,
  setAreaMax,
  unitPreference,
  theme,
}) => {
  const { t } = useTranslation();
  const metric = unitPreference === MeasurementSystem.METRIC;
  const UNITS = metric ? t("comparables.sqm.alt") : t("comparables.sqft.alt");

  return (
    <RangeFilter
      disabled={disabled}
      label={t("comparables.map.sidebar.filters.filters.area.label")}
      min={areaMin}
      setMin={setAreaMin}
      max={areaMax}
      setMax={setAreaMax}
      parse={unitPreference === MeasurementSystem.IMPERIAL ? ftsqToMsq : undefined}
      format={unitPreference === MeasurementSystem.IMPERIAL ? msqToFtsq : undefined}
      units={UNITS}
      data-testid="floor-area-filter"
      theme={theme}
    />
  );
};
