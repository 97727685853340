import { useTranslation } from "react-migration/lib/i18n/useTranslation";
import { SupportedLocale, dateString, Dateish } from "src/js/util/dates";
import { i18n } from "src/js/i18n";

export type PurchasePendingProps = {
  expectedProcessDate?: Dateish;
};

export function PurchasePending({ expectedProcessDate }: PurchasePendingProps) {
  const { t } = useTranslation();
  return (
    <div data-testid="pending-transaction" className="atlas-flex atlas-flex-col atlas-gap-4">
      {expectedProcessDate && (
        <strong>
          {t("title_purchase.pending.title.expected_date", {
            formattedExpectedDate: dateString(expectedProcessDate, i18n.locale as SupportedLocale),
          })}
        </strong>
      )}
      {!expectedProcessDate && <strong>{t("title_purchase.pending.title.no_date")}</strong>}
      <p>
        {t("title_purchase.pending.message", {
          transactions: <a href="dashboard/transactions">{t("title_purchase.pending.link")}</a>,
        })}
      </p>
    </div>
  );
}
