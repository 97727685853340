import { CompositeLayerProps } from "@deck.gl/core/dist/types/layer-props";
import { CompositeLayer, GeoJsonLayer, Color } from "deck.gl";

type PropertyFeatureProps = { uprn: string };

interface Props extends CompositeLayerProps {
  selectedPropertyUPRN?: string;
}

const ACTIVE_FILL: Color = [255, 218, 34, 255];
const DEFAULT_FILL: Color = [14, 165, 233, 255];

export class UKPropertyInformationMapVisualisationLayer extends CompositeLayer<Props> {
  renderLayers() {
    const { data, selectedPropertyUPRN } = this.props;

    return [
      new GeoJsonLayer<PropertyFeatureProps>({
        data: data as string,
        pointType: "circle",
        getPointRadius: 6,
        stroked: false,
        pointRadiusUnits: "pixels",
        getFillColor: (feature) =>
          feature.properties?.uprn === selectedPropertyUPRN ? ACTIVE_FILL : DEFAULT_FILL,
      }),
    ];
  }
}
