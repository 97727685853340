import { useMemo } from "react";
import * as actions from "./actions";
import {
  ConstraintsStore,
  ConstraintsStoreActionCreators,
  ConstraintsStoreDispatch,
} from "./types";

export type LayerActions = ReturnType<typeof useLayerStore>[1];

interface Options {
  constraintsStore: ConstraintsStore;
  dispatch: ConstraintsStoreDispatch;
}

export function useLayerStore(
  layerId: string,
  { constraintsStore = { layers: {} }, dispatch }: Options
) {
  const layerActions = useMemo(
    () => ({
      setCategoryTree: createLayerAction(layerId, dispatch, actions.setCategoryTree),
      setVisibleCategories: createLayerAction(layerId, dispatch, actions.setVisibleCategories),
      setHiddenStatuses: createLayerAction(layerId, dispatch, actions.setHiddenStatuses),
      setHiddenDesignationAttributes: createLayerAction(
        layerId,
        dispatch,
        actions.setHiddenDesignationAttributes
      ),
    }),
    [dispatch, layerId]
  );

  const layerSelectors = useMemo(
    () => ({
      getCategoryTree: () => constraintsStore.layers[layerId].categoryTree,
    }),
    [constraintsStore.layers, layerId]
  );

  return [constraintsStore.layers[layerId], layerActions, layerSelectors] as const;
}

/**
 * Allows for consumers of useLayerStore to dispatch layer based actions without needing to pass the layerId every time.
 *
 * @param layerId The layer ID which will be injected as the first arg or the passed action creator
 * @param dispatch The function which the created actions will be dispatched on
 * @param actionCreator The layer action creator to wrap, the first argument of which _must_ be the layerId
 * @returns A new – auto dispatchable – action creator whch no longer needs to have the layerId passed in
 */
function createLayerAction<A extends ConstraintsStoreActionCreators>(
  layerId: string,
  dispatch: ConstraintsStoreDispatch,
  actionCreator: A
) {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return (...args: ParametersExceptFirst<A>) => dispatch(actionCreator(layerId, ...args));
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type ParametersExceptFirst<F> = F extends (arg0: any, ...rest: infer R) => any ? R : never;
