import { ISourcingToolSearchFilter } from "react-migration/domains/search/typings/SourcingToolSpecificFilterTypes";
import { SearchType } from "react-migration/domains/search/constants/searchTypes";

export const AdoptedConstraintsFilter: ISourcingToolSearchFilter = {
  id: "adopted-constraints-id",
  type: "checkbox",
  field: "sidebar.react.search_sidebar.constraints_adopted",
  must: "MATCH_NONE",
  values: [],
  mustOptions: ["MATCH_ALL", "MATCH_NONE"],
  updateFilterDialogIsVisible: false,
  searchTypes: [SearchType.SOURCING_TOOL_UK],
};
