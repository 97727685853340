import union from "@turf/union";
import { Feature, Polygon } from "@turf/helpers";
import { Geometry } from "react-migration/domains/ownership/typings/Geometry";

/**
 * Takes an array of polygons and returns a merged polygon using turf union
 * @param geometries: Geometry[]
 * @returns Geometry
 */
export const generateMergedPolygon = (geometries: Geometry[]) => {
  const turfGeometries: Feature<Geometry>[] = geometries.map((geo) => {
    return {
      type: "Feature",
      geometry: {
        type: geo.type,
        coordinates: geo.coordinates,
      },
    } as Feature<Geometry>;
  });

  try {
    const mergedPolygon = union(...(turfGeometries as Feature<Polygon>[]));
    return {
      type: mergedPolygon.geometry?.type,
      coordinates: mergedPolygon.geometry?.coordinates,
    } as Geometry;
  } catch (error) {
    return {
      type: "Polygon",
      coordinates: [],
    } as Geometry;
  }
};
