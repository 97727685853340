import { Button } from "@landtechnologies/components";
import classNames from "classnames";
import { Footer } from "react-migration/domains/sites/letters/components/Footer";
import { useTranslation } from "react-migration/lib/i18n/useTranslation";
import { useSkipTraceValidationError } from "../../store/hooks/useSkipTraceValidationError";
import { updateCurrentStep } from "../../store/actions/updateCurrentStep";
import { SkipTraceValidationError } from "../SkipTraceValidationError";
import { CreateSkipTraceOrderStep } from "react-migration/domains/sites/typings/SkipTraceOrderStep";
import logSiteCardEvent from "src/js/view/sites/logSiteCardEvent";

export const SummaryStepFooter = ({
  onConfirmAndProcessButtonClicked,
}: {
  onConfirmAndProcessButtonClicked: () => void;
}) => {
  const { t } = useTranslation();
  const skipTraceValidationError = useSkipTraceValidationError();

  const onBackButtonClicked = async () => {
    updateCurrentStep(CreateSkipTraceOrderStep.SelectSites);
    logSiteCardEvent({ name: "Skip Trace - Summary Back Clicked" });
  };

  const onConfirmButtonClicked = () => {
    logSiteCardEvent({ name: "Skip Trace - Summary Confirm Clicked" });
    onConfirmAndProcessButtonClicked();
  };

  return (
    <Footer percentage={50} dataTestId="create-skip-trace-footer">
      <div
        className={classNames(
          "atlas-flex",
          "atlas-flex-grow",
          "atlas-justify-between",
          "atlas-h-9"
        )}
      >
        <Button.Secondary onClick={onBackButtonClicked}>
          {t("sites.skip_tracing.back")}
        </Button.Secondary>
        <div
          className={classNames(
            "atlas-flex",
            "atlas-flex-row",
            "atlas-gap-6",
            "atlas-items-center"
          )}
        >
          <SkipTraceValidationError errorMessage={skipTraceValidationError} />
          <Button.Primary onClick={onConfirmButtonClicked} disabled={!!skipTraceValidationError}>
            {t("sites.skip_tracing.confirm_and_process")}
          </Button.Primary>
        </div>
      </div>
    </Footer>
  );
};
