import { useAdditionalZooplaTransactionsByUPRN } from "react-migration/domains/comparables/hooks/useAdditionalZooplaTransactionsByUPRN";
import { AdditionalTransactions } from "./AdditionalTransactions";
import { RecordType } from "react-migration/domains/comparables/typings/Record";
import { useMemo } from "react";

export function AdditionalZooplaTransactions({
  transaction,
}: Readonly<{
  transaction: RecordType;
}>) {
  const { data } = useAdditionalZooplaTransactionsByUPRN({
    uprn: transaction.uprn,
  });

  const historicalTransactions = useMemo(() => {
    const history = data?.valuation?.sale?.history ?? [];

    return history.map((transaction) => ({
      value: transaction.value,
      date: transaction.valueDate,
      source: transaction.valueSource,
      currency: "GBP" as const,
    }));
  }, [data?.valuation?.sale?.history]);

  return <AdditionalTransactions transactions={historicalTransactions} />;
}
