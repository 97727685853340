import { useEffect, useMemo, useRef } from "react";
import { UtilityFeature } from "./types";
import { SelectionFeature, SelectionType } from "src/js/stores/map/store";
import { useAtkinsOrder } from "react-migration/domains/sites/hooks/useAtkinsOrder";
import { useFetch } from "usehooks-ts";
import intersect from "@turf/intersect";
import buffer from "@turf/buffer";
import { MOCK_BUFFER_METERS } from "./constants";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const usePrevious = (value: any) => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};

export const useSelectionUtilityData = (selection?: SelectionFeature): UtilityFeature[] => {
  const siteId = selection?.type === SelectionType.SITE ? selection?.id : undefined;
  const atkinsOrderResponse = useAtkinsOrder({ siteId });
  const signedMapUrl = atkinsOrderResponse.data?.atkinsOrder.signedMapUrl;

  const { data } = useFetch<{ features: UtilityFeature[] }>(signedMapUrl);

  const previousData = usePrevious(data);
  const previousSiteId = usePrevious(siteId);

  const displayedData = useMemo(() => {
    if (previousData === undefined && previousSiteId == siteId) {
      return data;
    } else if (data === undefined && previousSiteId == siteId) {
      return previousData;
    } else {
      return data;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, siteId]);

  return useMemo(() => {
    if (!displayedData || !selection?.feature || selection?.type !== SelectionType.SITE) return [];
    /**
     * Creates a buffer around the selected feature using a predefined distance
     * (MOCK_BUFFER_METERS). This is used to simulate the area that would be
     * covered in an actual Atkins utility report.
     **/
    const bufferedSelection = buffer(selection.feature, MOCK_BUFFER_METERS, { units: "meters" });
    if (!bufferedSelection) return [];

    return displayedData?.features.filter((feature) =>
      intersect(buffer(feature, 1, { units: "meters" }), bufferedSelection)
    ) as UtilityFeature[];
  }, [displayedData, selection]);
};
