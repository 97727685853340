import { useMutation } from "@apollo/client";
import TITLES_BY_LOCATION from "react-migration/domains/ownership/apollo/useTitlesByLocationQuery/titlesByLocation.gql";
import { routedClient } from "react-migration/lib/persistence/apollo/routedClient";
import PURCHASE_TITLE_DOCUMENTS from "./purchaseTitleDocuments.gql";

type Variables = {
  title_nos: string[];
  doc_type: string;
  address: {
    receiver_name: string;
    line1: string;
    line2: string;
    city: string;
    country: string;
    postcode: string;
  };
  landRegCredentials?: {
    username: string;
    password: string;
  };
};

export type PurchaseTitleDocumentResponse = {
  id: string;
  purchase: {
    reference: string;
    type: string;
    title_document: {
      _id: string;
      title_no: string;
      created: string;
      url: string;
    };
  };
  state: {
    status: string;
    type: string;
  };
  created: string;
};

export const usePurchaseTitleDocumentsMutation = () => {
  return useMutation<{ purchaseTitleDocuments: PurchaseTitleDocumentResponse[] }, Variables>(
    PURCHASE_TITLE_DOCUMENTS,
    {
      client: routedClient,
      refetchQueries: [TITLES_BY_LOCATION],
      onError: (e) => {
        console.log(e.message);
      },
    }
  );
};
