import { ModalV2 } from "react-migration/components/ModalV2/ModalV2";
import { useTranslation } from "react-migration/lib/i18n/useTranslation";
import { Button } from "react-migration/components/Button";

type KickedModalProps = {
  login: () => void;
};
export const KickedModal = ({ login }: KickedModalProps) => {
  const { t } = useTranslation();

  return (
    <ModalV2.Root defaultOpen={true}>
      <ModalV2.Body
        title={t("modals.multi_device_block.someone_else_has_logged_in_to_your_account")}
        className="!atlas-z-[1000]"
        hideClose={true}
      >
        <div
          className="atlas-text-neutral-800 atlas-text-sm atlas-leading-normal"
          data-testid="kicked-modal"
        >
          <p>{t("modals.multi_device_block.you_can_only_have_one_active_user")}</p>
          <p>
            {t("modals.multi_device_block.you_can_continue_to_use_landinsight_by_logging_back_in")}
          </p>
          <p>
            {t("modals.multi_device_block.why_am_i_seeing_this")}{" "}
            <a
              href="https://support.landinsight.io/en/articles/2530601-account-blocked-multiple-users-faqs"
              target="_blank"
              rel="noopener noreferrer"
            >
              {t("modals.multi_device_block.learn_more")}
            </a>
          </p>
        </div>
        <div className="atlas-flex atlas-justify-end atlas-pt-4 atlas-gap-2">
          <Button onClick={() => login()}>{t("modals.multi_device_block.log_in")}</Button>
        </div>
      </ModalV2.Body>
    </ModalV2.Root>
  );
};
