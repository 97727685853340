import { Cross2Icon } from "@radix-ui/react-icons";
import { useState } from "react";
import { Button, Modal } from "@landtechnologies/components";
import { useTranslation } from "react-migration/lib/i18n/useTranslation";
import Councils from "src/data/planning_app_council_instructions";
import { logEvent } from "react-migration/lib/util/logEvent";
import { ResultItem } from "react-migration/domains/dashboard/typings/PlanningAlertResultsResponse";

type ViewAppModalProps = {
  resultItem: ResultItem;
};

export const ViewApplicationButton = ({ resultItem }: ViewAppModalProps) => {
  const { t } = useTranslation();
  const [isViewAppModalOpen, setIsViewAppModalOpen] = useState(false);
  const council = Councils[resultItem.authority.name as keyof typeof Councils];

  const handleOpenViewAppModal = () => {
    if (council) {
      setIsViewAppModalOpen(true);
    } else {
      handleViewApplicationLink();
    }
  };

  const handleCloseModal = () => {
    setIsViewAppModalOpen(false);
  };

  const trackFullApplicationClick = () => {
    logEvent("Planning App council website link click");
  };

  const handleViewApplicationLink = () => {
    trackFullApplicationClick();
    window.open(resultItem.extract_url, "_blank");
  };

  return (
    <>
      <Button.Secondary onClick={handleOpenViewAppModal} size="small">
        <i className="icon-lt-external-link-line full-application-button__icon atlas-mr-1"></i>
        <span>{t("components.view_planning_app_button.view_application")}</span>
      </Button.Secondary>
      {isViewAppModalOpen && (
        <div className="atlas-fixed">
          <Modal title={t("components.view_planning_app_button.view_application")} description="">
            <button
              onClick={handleCloseModal}
              className="atlas-text-violet11 atlas-hover:bg-violet4 atlas-focus:shadow-violet7 atlas-absolute atlas-top-[10px] atlas-right-[10px] atlas-inline-flex atlas-h-[25px] atlas-w-[25px] atlas-appearance-none atlas-items-center atlas-justify-center atlas-rounded-full atlas-focus:shadow-[0_0_0_2px] atlas-focus:outline-none"
              aria-label="Close"
            >
              <Cross2Icon />
            </button>

            <div className="atlas-flex atlas-flex-col atlas-break-words">
              <div className="atlas-mb-2">
                {t(
                  "components.view_planning_app_button.easy_steps_to_access_the_applications_on_the",
                  {
                    councilName: council.name,
                  }
                )}
              </div>
              <div className="atlas-flex atlas-gap-1">
                <span>{t("components.view_planning_app_button.paste_the")}</span>
                <span className="atlas-font-bold">{resultItem.ref}</span>
              </div>
              <div className="atlas-mb-2">
                {t("components.view_planning_app_button.you_will_need_to_paste_this_on_the_portal")}
              </div>
              <ol className="atlas-list-inside">
                <li>
                  {t("components.view_planning_app_button.click_here_to_open_portal", {
                    hereLink: (
                      <a target="_blank" rel="noreferrer" href={council.link}>
                        {t("components.view_planning_app_button.here")}
                      </a>
                    ),
                  })}
                </li>
                {council.prePaste && <li>{council.prePaste}</li>}
                <li>
                  {t("components.view_planning_app_button.paste_the")}{" "}
                  <strong>{resultItem.ref}</strong>
                </li>
                <li>{t("components.view_planning_app_button.search")}</li>
              </ol>
            </div>
          </Modal>
        </div>
      )}
    </>
  );
};
