import { ComparablesFilterFields } from "../components/Filters/Filters";
import { DCSCondition } from "../typings/Comparables";

export function FilterDCSConditions(
  dcsConditions?: DCSCondition[],
  disabledFields?: Set<ComparablesFilterFields>,
  hiddenFields?: Set<ComparablesFilterFields>
): DCSCondition[] | undefined {
  const exclude = (field: ComparablesFilterFields): boolean =>
    (disabledFields?.has(field) ?? false) || (hiddenFields?.has(field) ?? false);

  // console.log(hiddenFields);
  return dcsConditions?.filter((condition) => {
    if (exclude("dcsConditions")) {
      return false;
    } else if (exclude("dcsConditions.Commercial") && condition.designation === "Commercial") {
      return false;
    } else if (exclude("dcsConditions.Other") && condition.designation === "Other") {
      return false;
    } else if (exclude("dcsConditions.Residential") && condition.designation === "Residential") {
      return false;
    }

    return true;
  });
}
