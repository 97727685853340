import { PickingInfo } from "deck.gl";
import { feature, point } from "@turf/helpers";
import { getGeom } from "@turf/turf";
import buffer from "@turf/buffer";
import { PlanningApplicationLayer } from "react-migration/domains/planning/map/layers/PlanningApplicationLayer/PlanningApplicationLayer";
import { fetchPlanningApplicationById } from "./usePlanningAppsById";
import { PlanningApplicationFeature } from "react-migration/domains/planning/types";
import { SelectionType, SelectionFeature } from "src/js/stores/map/store";

const POINT_BUFFER_RADIUS_METERS = 2;

function clickOriginatesFromPlanningLayer(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  info: PickingInfo<any>
): info is PickingInfo<PlanningApplicationFeature> {
  return info.layer instanceof PlanningApplicationLayer;
}
type PlanningClickResolver = (pickInfo: PickingInfo<unknown>) => Promise<SelectionFeature | null>;

export const planningApplicationsClickResolver: PlanningClickResolver = async (pickInfo) => {
  const planningFeature = pickInfo.object as PlanningApplicationFeature | null;
  if (!clickOriginatesFromPlanningLayer(pickInfo)) {
    return null;
  }

  if (!planningFeature) {
    return null;
  }
  const { layerName } = planningFeature.properties;

  try {
    switch (layerName) {
      case "dots":
        return makeDotSelection(planningFeature);
      case "boundaries":
        return makeBoundarySelection(planningFeature);
    }
  } catch (e) {
    console.error(`Failed to select planning application "${planningFeature.properties.id}".`, e);
    return null;
  }
};

export function makeDotSelection({
  geometry,
  properties,
}: PlanningApplicationFeature): SelectionFeature {
  const { id } = properties;
  const areaAroundPount = getGeom(
    buffer(geometry, POINT_BUFFER_RADIUS_METERS, { units: "meters" })
  );

  return {
    type: SelectionType.PLANNING_APPLICATION,
    id,
    feature: feature(areaAroundPount),
    savable: false,
    __renderGeometry: point(geometry.coordinates as [number, number], {
      // @ts-expect-error this radius is added by the PlanningApplicationLayer
      radius: properties.__radius,
    }),
  };
}

async function makeBoundarySelection({
  properties: { id },
}: PlanningApplicationFeature): Promise<SelectionFeature> {
  const { boundary } = await fetchPlanningApplicationById(id);

  if (!boundary) {
    throw new Error("Planning application boundary not found for feature on boundary layer.");
  }

  return {
    type: SelectionType.PLANNING_APPLICATION,
    id,
    feature: feature(boundary),
  };
}
