import { Button, Icon, Stepper } from "@landtechnologies/components";
import classNames from "classnames";
import { useTranslation } from "react-migration/lib/i18n/useTranslation";
import { CreateSkipTraceOrderStep } from "../../typings/SkipTraceOrderStep";
import logSiteCardEvent from "src/js/view/sites/logSiteCardEvent";

type CreateSkipTraceHeaderProps = {
  currentStep: CreateSkipTraceOrderStep;
  onBackToSitesClicked: () => void;
};

export const CreateSkipTraceHeader = ({
  currentStep = CreateSkipTraceOrderStep.SelectSites,
  onBackToSitesClicked,
}: CreateSkipTraceHeaderProps) => {
  const { t } = useTranslation();

  if (currentStep === CreateSkipTraceOrderStep.OrderError) {
    return null;
  }

  const steps = [
    { key: CreateSkipTraceOrderStep.SelectSites, label: "Select sites" },
    { key: CreateSkipTraceOrderStep.Summary, label: "Summary" },
  ];

  const onBackToSitesButtonClicked = () => {
    logSiteCardEvent({ name: "Skip Trace - Back To Sites" });
    onBackToSitesClicked();
  };

  return (
    <div
      data-testid="create-skip-trace-header"
      className={classNames(
        "atlas-flex",
        "atlas-w-full",
        "atlas-border-b",
        "atlas-border-border-divider-subtle",
        "atlas-px-5",
        "atlas-py-1",
        "atlas-grid",
        "atlas-grid-cols-5"
      )}
    >
      <div className="atlas-flex atlas-col-span-1">
        <Button.Borderless
          onClick={onBackToSitesButtonClicked}
          className="!atlas-shadow-none atlas-flex-none atlas-text-content-tertiary !atlas-px-0 atlas-gap-x-1"
        >
          <Icon icon="icon-lt-arrow-left-s-line" cursor={"pointer"} />
          {t("sites.skip_tracing.back_to_sites")}
        </Button.Borderless>
      </div>
      <div className="atlas-flex atlas-col-span-3">
        <Stepper currentStep={currentStep} steps={steps} containerClassNames="!atlas-border-none" />
      </div>
    </div>
  );
};
