import { useTranslation } from "react-migration/lib/i18n/useTranslation";

export function ComparableSelectionHeader() {
  const { t } = useTranslation();
  return (
    <h1 className="atlas-text-xs atlas-self-center atlas-font-semibold atlas-text-content-tertiary atlas-uppercase">
      {t("comparables.header")}
    </h1>
  );
}
